import type { MutationHookOptions } from "@apollo/client";
import type { DocumentNode } from "graphql";
import gql from "graphql-tag";

export const MUTATE_CLIENT_TRACING: DocumentNode = gql(`
    mutation mutateClientTracing(
        $url: String!
    ) {
        mutateClientTracing(
            url: $url
        ) {
            url
        }
    }
`);

export const getClientTracingGraphqlMutationOptions = (
  url: string
): MutationHookOptions => {
  const options: MutationHookOptions = {};
  options.variables = { url: url };
  return options;
};
