import React, { createContext, useContext } from "react";
import { ApplicationProfile, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getApplicationProfilesGraphqlMutationOptions, MUTATE_APPLICATION_PROFILE } from "./applicationProfileMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface ApplicationProfileMutationsContext {
    mutateApplicationProfile: (applicationProfile: ApplicationProfile, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void) => void;
}

const ApplicationProfileMutationsContext = createContext<ApplicationProfileMutationsContext>(null as unknown as ApplicationProfileMutationsContext);

export const ApplicationProfileMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [applicationProfileMutation] = useMutation(MUTATE_APPLICATION_PROFILE);

    const handleApplicationProfileMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        if (result.errors || !result.data) {
            onError(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateApplicationProfile.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
        if (tickets.length === 0 && 'id' in variables) {
            onTicketSubscribed(variables['id'], variables);
        }
    }

    const mutateApplicationProfile = (applicationProfile: ApplicationProfile, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        const options = getApplicationProfilesGraphqlMutationOptions(applicationProfile)
        applicationProfileMutation(options).then((result) => handleApplicationProfileMutationResult(result, options.variables, onTicketSubscribed, onError));
    }

    const applicationProfileMutationsContext: ApplicationProfileMutationsContext = {
        mutateApplicationProfile
    };

    return (
        <ApplicationProfileMutationsContext.Provider value={applicationProfileMutationsContext}>
            {children}
        </ApplicationProfileMutationsContext.Provider>
    );
}

export const useApplicationProfileMutationsContext = (): ApplicationProfileMutationsContext => {
    return useContext(ApplicationProfileMutationsContext);
}
