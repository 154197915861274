import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from "react";
import { useCompanyInfoQueriesContext } from "../../contexts/generalIntegrations/queries/companyInfoQueriesContext";
import { CompanyInfo } from "../../contracts/contracts";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Box } from '@mui/material';
import { stringEmpty, stringIsNumeric, stringNotEmpty } from '../../utils/stringTools';
import { SxProps } from "@mui/system";

type props = {
    companyInfoSearch: CompanyInfo | undefined;
    onSelectedCompanyInfoChange: (newSelectedCompanyInfo: CompanyInfo | undefined) => void;
    companyInfoFilter?: (companyInfos: CompanyInfo[]) => CompanyInfo[];
    label?: React.ReactNode;
    error?: boolean | undefined;
    textFieldProps?: TextFieldProps
    disabled?: boolean;
    helperText?: string;
    sx?: SxProps;
}

const SearchCompanyInfo: React.FC<props> = ({
    companyInfoSearch,
    onSelectedCompanyInfoChange,
    companyInfoFilter,
    label,
    error,
    textFieldProps,
    helperText,
    sx,
    disabled }) => {

    const languageContext = useLanguageContext();
    const companyInfoQueriesContext = useCompanyInfoQueriesContext();

    const [companyInfoValue, setCompanyInfoValue] = useState<CompanyInfo | null>(null);
    let companyInfos = companyInfoQueriesContext.fetchedCompanyInfos;
    if (companyInfoFilter) {
        companyInfos = companyInfoFilter(companyInfos);
    }

    const handleChange = (event: any, newCompanyInfoSearch: CompanyInfo | null) => {
        onSelectedCompanyInfoChange(newCompanyInfoSearch ?? undefined);
        setCompanyInfoValue(newCompanyInfoSearch ?? null);
    }

    useEffect(() => {
        let matchingCompanyInfo: CompanyInfo | undefined;
        if (stringNotEmpty(companyInfoSearch?.organizationNumber)) {
            matchingCompanyInfo = companyInfos.find(companyInfo => companyInfo.organizationNumber === companyInfoSearch?.organizationNumber);
            if (!companyInfoQueriesContext.loading && matchingCompanyInfo === undefined) {
                companyInfoQueriesContext.queryCompanyInfo({ organizationNumber: companyInfoSearch?.organizationNumber })
            }
        }
        setCompanyInfoValue(matchingCompanyInfo ?? null);
    }, [companyInfoSearch])

    useEffect(() => {
        if ((stringEmpty(companyInfoValue?.organizationNumber))
            && stringNotEmpty(companyInfoSearch?.organizationNumber)) {
            const matchingCompanyInfo = companyInfos.find(companyInfo => companyInfo.organizationNumber === companyInfoSearch?.organizationNumber);
            if (matchingCompanyInfo) {
                setCompanyInfoValue(matchingCompanyInfo);
            }
        }
    }, [companyInfoQueriesContext.fetchedCompanyInfos])

    const getCompanyInfoRenderLabel = (companyInfo: CompanyInfo): string => {
        let label = '';
        if (stringNotEmpty(companyInfo.name) && stringNotEmpty(companyInfo.organizationNumber)) {
            label = `${companyInfo.name} - ${companyInfo.organizationNumber}`;
        }
        else if (stringNotEmpty(companyInfo.organizationNumber)) {
            label = `${companyInfo.organizationNumber}`;
        }
        else if (stringNotEmpty(companyInfo.name)) {
            label = `${companyInfo.name}`;
        }
        if (companyInfo.notFound) {
            label += ` (${languageContext.getMessage('unknownCompany')})`;
        }
        return label;
    }

    return <Autocomplete
        sx={sx}
        disabled={disabled}
        options={companyInfos.filter(companyInfo => companyInfo.notFound !== true)}
        getOptionLabel={(companyInfo) => getCompanyInfoRenderLabel(companyInfo)}
        value={companyInfoValue}
        onChange={handleChange}
        onInputChange={(event, newInputValue) => {
            newInputValue = newInputValue.trim();
            if (newInputValue.length > 0 && !companyInfoQueriesContext.loading) {
                const companyInfoSearch: CompanyInfo = {};
                if (stringIsNumeric(newInputValue)) {
                    companyInfoSearch.organizationNumber = newInputValue;
                }
                else {
                    companyInfoSearch.name = newInputValue;
                }
                companyInfoQueriesContext.queryCompanyInfo(companyInfoSearch)
            }
        }}
        renderOption={(props, companyInfo) => (
            <Box component="li" {...props}>
                {getCompanyInfoRenderLabel(companyInfo)}
            </Box>
        )}
        renderInput={(params) => <TextField {...params}
            error={error || companyInfoValue?.notFound}
            label={label ?? languageContext.getMessage('companyRaw')}
            helperText={helperText}
            variant="outlined"
            {...textFieldProps} />}
    />
}

export default SearchCompanyInfo;
