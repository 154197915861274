import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, Project } from "../../../contracts/contracts";
import { cleanObject } from "../../../utils/objectTools";


export const MUTATE_PROJECT: DocumentNode = gql(`
    mutation mutateProject(
        $id: UUID
        $state: ActiveState 	
        $applicationProfileId: UUID!
        $name: String
        $address: ProjectAddressInput
        $projectDescription: String
        $projectState: ProjectState
        $hseScanningActivated: Boolean
        $parentProjectCompanyActivated: Boolean
        $phoneNumberFieldIsMandatoryOnApplicationProfile: Boolean
        $relativesFieldIsMandatoryOnApplicationProfile: Boolean
        $hseCardNumberFieldIsMandatoryOnApplicationProfile: Boolean
        $hseCertificatePictureUploadIsMandatory: Boolean
        $generalCertificatePictureUploadIsMandatory: Boolean
        $maxNumberOfRequestingMemberAccessTries: Int
        $minimumPercentageVerifiedInformations: Float
        $autoApproveProjectMembers: Boolean
        $enableCheckIn: Boolean
        $infoTechSettings: InfoTechSettingsInput
    ) {
        mutateProject(
            id: $id
            state: $state
            applicationProfileId: $applicationProfileId
            name: $name
            address: $address
            projectDescription: $projectDescription
            projectState: $projectState
            hseScanningActivated: $hseScanningActivated
            parentProjectCompanyActivated: $parentProjectCompanyActivated
            phoneNumberFieldIsMandatoryOnApplicationProfile: $phoneNumberFieldIsMandatoryOnApplicationProfile
            relativesFieldIsMandatoryOnApplicationProfile: $relativesFieldIsMandatoryOnApplicationProfile
            hseCardNumberFieldIsMandatoryOnApplicationProfile: $hseCardNumberFieldIsMandatoryOnApplicationProfile
            hseCertificatePictureUploadIsMandatory: $hseCertificatePictureUploadIsMandatory
            generalCertificatePictureUploadIsMandatory: $generalCertificatePictureUploadIsMandatory
            maxNumberOfRequestingMemberAccessTries: $maxNumberOfRequestingMemberAccessTries
            minimumPercentageVerifiedInformations: $minimumPercentageVerifiedInformations
            autoApproveProjectMembers: $autoApproveProjectMembers
            enableCheckIn: $enableCheckIn
            infoTechSettings: $infoTechSettings
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`);

export const getProjectsGraphqlMutationOptions = (project: Partial<Project>): QueryHookOptions => {
    project.state = project.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (project.id !== undefined && project.id !== null) options.variables['id'] = project.id;
    if (project.state !== undefined && project.state !== null) options.variables['state'] = project.state;
    if (project.applicationProfileId !== undefined && project.applicationProfileId !== null) options.variables['applicationProfileId'] = project.applicationProfileId;
    if (project.name !== undefined && project.name !== null) options.variables['name'] = project.name;
    if (project.address !== undefined && project.address !== null) options.variables['address'] = cleanObject(project.address);
    if (project.projectDescription !== undefined && project.projectDescription !== null) options.variables['projectDescription'] = project.projectDescription;
    if (project.projectState !== undefined && project.projectState !== null) options.variables['projectState'] = project.projectState;
    if (project.hseScanningActivated !== undefined && project.hseScanningActivated !== null) options.variables['hseScanningActivated'] = project.hseScanningActivated;
    if (project.parentProjectCompanyActivated !== undefined && project.parentProjectCompanyActivated !== null) options.variables['parentProjectCompanyActivated'] = project.parentProjectCompanyActivated;
    if (project.phoneNumberFieldIsMandatoryOnApplicationProfile !== undefined && project.phoneNumberFieldIsMandatoryOnApplicationProfile !== null) options.variables['phoneNumberFieldIsMandatoryOnApplicationProfile'] = project.phoneNumberFieldIsMandatoryOnApplicationProfile;
    if (project.relativesFieldIsMandatoryOnApplicationProfile !== undefined && project.relativesFieldIsMandatoryOnApplicationProfile !== null) options.variables['relativesFieldIsMandatoryOnApplicationProfile'] = project.relativesFieldIsMandatoryOnApplicationProfile;
    if (project.hseCardNumberFieldIsMandatoryOnApplicationProfile !== undefined && project.hseCardNumberFieldIsMandatoryOnApplicationProfile !== null) options.variables['hseCardNumberFieldIsMandatoryOnApplicationProfile'] = project.hseCardNumberFieldIsMandatoryOnApplicationProfile;
    if (project.hseCertificatePictureUploadIsMandatory !== undefined && project.hseCertificatePictureUploadIsMandatory !== null) options.variables['hseCertificatePictureUploadIsMandatory'] = project.hseCertificatePictureUploadIsMandatory;
    if (project.generalCertificatePictureUploadIsMandatory !== undefined && project.generalCertificatePictureUploadIsMandatory !== null) options.variables['generalCertificatePictureUploadIsMandatory'] = project.generalCertificatePictureUploadIsMandatory;
    if (project.maxNumberOfRequestingMemberAccessTries !== undefined && project.maxNumberOfRequestingMemberAccessTries !== null) options.variables['maxNumberOfRequestingMemberAccessTries'] = project.maxNumberOfRequestingMemberAccessTries;
    if (project.minimumPercentageVerifiedInformations !== undefined && project.minimumPercentageVerifiedInformations !== null) options.variables['minimumPercentageVerifiedInformations'] = project.minimumPercentageVerifiedInformations;
    if (project.autoApproveProjectMembers !== undefined && project.autoApproveProjectMembers !== null) options.variables['autoApproveProjectMembers'] = project.autoApproveProjectMembers;
    if (project.enableCheckIn !== undefined && project.enableCheckIn !== null) options.variables['enableCheckIn'] = project.enableCheckIn;
    if (project.infoTechSettings !== undefined && project.infoTechSettings !== null) options.variables['infoTechSettings'] = cleanObject(project.infoTechSettings);
    return options;
}
