import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { UserRole } from "../../../contracts/contracts";
import { cleanObject } from "../../../utils/objectTools";

export const QUERY_USERS: DocumentNode = gql(`
query users($clientId: String, $id: String, $roles: [RoleInput]) {
  users(clientId: $clientId, id: $id, roles: $roles) {
    id
    name
    email
    roles {
      id
      name
    }
  }
}
`);

export const QUERY_ROLES: DocumentNode = gql(`
query roles($clientId: String, $roleNames: [String]) {
  roles(clientId: $clientId, roleNames: $roleNames) {
    id
    name
  }
}
`);

export const getUsersGraphqlQueryOptions = (clientId: string, userRoles?: UserRole[]): QueryHookOptions => {
  const options: QueryHookOptions = {};
  options.variables = {};
  options.variables['clientId'] = clientId;
  if (userRoles !== undefined) options.variables['roles'] = cleanObject(userRoles);
  return options;
}

export const getRolesGraphqlQueryOptions = (clientId: string, userRoles?: UserRole[]): QueryHookOptions => {
  const options: QueryHookOptions = {};
  options.variables = {};
  options.variables['clientId'] = clientId;
  if (userRoles !== undefined) options.variables['roleNames'] = userRoles.map(userRole => userRole.name);
  return options;
}
