import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Certificate } from "../../../contracts/contracts";
import { getCertificatesGraphqlMutationOptions } from "../mutations/certificateMutations";

export const QUERY_CERTIFICATE: DocumentNode = gql`
  query certificates(
    $searchIndexStart: Int
    $searchIndexStop: Int
    $id: UUID
    $documentType: DocumentType
    $state: ActiveState
    $created: DateTime
    $applicationProfileId: UUID
    $projectMemberId: UUID
    $certificateType: CertificateType
    $title: String
    $certificateDescription: String
    $numberOfCertificateFiles: Int
    $certificateStatus: CertificateStatus
    $certificateId: String
    $certificateNumber: String
    $certificateUrl: String
    $isActive: Boolean
    $isRevoked: Boolean
  ) {
    certificates(
      searchIndexStart: $searchIndexStart
      searchIndexStop: $searchIndexStop
      id: $id
      documentType: $documentType
      state: $state
      created: $created
      applicationProfileId: $applicationProfileId
      projectMemberId: $projectMemberId
      certificateType: $certificateType
      title: $title
      certificateDescription: $certificateDescription
      numberOfCertificateFiles: $numberOfCertificateFiles
      certificateStatus: $certificateStatus
      certificateId: $certificateId
      certificateNumber: $certificateNumber
      certificateUrl: $certificateUrl
      isActive: $isActive
      isRevoked: $isRevoked
    ) {
      id
      documentType
      stateTime
      state
      created
      applicationProfileId
      projectMemberId
      certificateType
      title
      certificateDescription
      numberOfCertificateFiles
      certificateId
      certificateType
      certificateNumber
      certificateUrl
      certificateStatus
      isActive
      isRevoked
    }
  }
`;

export const getCertificatesGraphqlQueryOptions = (certificate: Partial<Certificate>): QueryHookOptions => {
  const options = getCertificatesGraphqlMutationOptions(certificate);
  options.variables = options.variables ?? {};
  if (certificate.searchIndexStart !== undefined) options.variables["searchIndexStart"] = certificate.searchIndexStart;
  if (certificate.searchIndexStop !== undefined) options.variables["searchIndexStop"] = certificate.searchIndexStop;
  return options;
};
