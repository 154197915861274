import NumberFormat from 'react-number-format';
import React from "react";
import { TextField } from '@mui/material';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  allowNegative?: boolean;
  fixedDecimalScale?: boolean;
  decimalScale?: number;
  thousandSeparator?: boolean | string;
  decimalSeparator?: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: "numberformat",
              value: values.value,
            },
          });
        }}
        isNumericString={true}
      />
    );
  }
);


type props = {
  value: number;
  label: string | React.ReactNode;
  onChange: (percentage: number) => void;
  disabled?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  allowNegative?: boolean;
  fixedDecimalScale?: boolean;
  decimalScale?: number;
  thousandSeparator?: boolean | string;
  decimalSeparator?: string;
}

const NumberFormatField: React.FC<props> = ({
  value,
  label,
  onChange,
  disabled,
  readOnly,
  fullWidth,
  allowNegative,
  fixedDecimalScale,
  decimalScale,
  thousandSeparator,
  decimalSeparator,
}) => {
  allowNegative = allowNegative ?? true;
  fixedDecimalScale = fixedDecimalScale ?? true;
  decimalScale = decimalScale ?? 2;
  thousandSeparator = thousandSeparator ?? '.';
  decimalSeparator = decimalSeparator ?? ',';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "numberformat") {
      const strValue = event.target.value.replaceAll(',', '.')
      value = parseFloat(strValue);
      if (!isNaN(value)) {
        onChange(value);
      }
    }
  };

  return (<>
    <TextField
      label={label}
      value={value}
      onChange={event => {
        handleChange(event as React.ChangeEvent<HTMLInputElement>);
      }}
      disabled={disabled}
      fullWidth={fullWidth}
      variant="standard"
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        inputProps: {
          allowNegative: allowNegative,
          fixedDecimalScale: fixedDecimalScale,
          decimalScale: decimalScale,
          thousandSeparator: thousandSeparator,
          decimalSeparator: decimalSeparator,
        },
        readOnly: readOnly,
      }}
    />
  </>
  );
}

export default NumberFormatField
