import { useEffect, useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@mui/material"
import { styled } from '@mui/material/styles';

import HSECardExampleImage from "../../data/pictures/hse_card_example.jpeg"
import HSECardQRCodeExampleImage from "../../data/pictures/hse_card_what_to_photo.png"
import HSECardPhotoExamplesImage from "../../data/pictures/hse_card_examples.png"
import { useLanguageContext } from "../../contexts/language/LanguageContext";

type Props = {
    openHseCardModal: () => void
    open: boolean
    onClose: () => void
}

const HseScannerInstruction = (props: Props) => {
    const { open, onClose, openHseCardModal } = props
    const [instructionsCompleted, setInstructionsCompleted] = useState(false);

    const languageContext = useLanguageContext()

    useEffect(() => {
        if (!open) setInstructionsCompleted(false)
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle id="form-dialog-title">{languageContext.getMessage('hseCardScanningInstructionTitle')}</DialogTitle>
            <DialogContentStyled>
                <InstructionContentWrapper>
                    <p><b>{languageContext.getMessage('instructionReadAttentively')}</b></p>
                    <p>
                        {languageContext.getMessage('scanningInstructionExplanation1')}
                    </p>
                    <img src={HSECardExampleImage} alt="Hse card image example" />
                    <p>
                        {languageContext.getMessage('scanningInstructionExplanation2')}
                    </p>
                    <img src={HSECardQRCodeExampleImage} alt="HSE card image with qr code example" />
                    <p>
                        <b>{languageContext.getMessage('example')}:</b>
                    </p>
                    <p>
                        {languageContext.getMessage('scanningInstructionExplanation3')}
                    </p>
                    <img src={HSECardPhotoExamplesImage} alt="HSE card image examples" />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={instructionsCompleted}
                                onChange={(e) => {
                                    setInstructionsCompleted(e.target.checked)
                                }} />
                        }
                        label={languageContext.getMessage('iHaveReadIntructions')} />
                </InstructionContentWrapper>
            </DialogContentStyled>
            <DialogActions>
                <Button variant="contained" disabled={!instructionsCompleted} onClick={() => openHseCardModal()}>{languageContext.getMessage('startScanningProcess')}</Button>
            </DialogActions>
        </Dialog>
    )
}

const DialogContentStyled = styled(DialogContent)`
    height: 90vh;

`

const InstructionContentWrapper = styled('div')`
    img {
        width: 100%;
    }
`

export default HseScannerInstruction