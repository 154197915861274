import React from 'react';
import { Box, CircularProgress, Fade, Grid } from "@mui/material";

type props = {
	loading: boolean,
	size?: number,
}

const inset: any = {
	position: 'absolute',
	left: 0,
	right: 0,
	bottom: 0,
	top: 0,
}

const LoadingWrapper: React.FC<props> = ({ children, loading, size }) => {
	return (
		<Box sx={{ position: 'relative' }}>
			<Box sx={{ position: 'relative', zIndex: 1, }}>{children}</Box>
			<Fade in={loading} mountOnEnter unmountOnExit>
				<Grid
					container
					alignItems="center"
					justifyContent="center"
					sx={{ ...inset, zIndex: 3, }}
				>
					<Box sx={{ bgcolor: 'background.paper', ...inset, zIndex: 2, opacity: 0.5 }} />
					<CircularProgress sx={{ position: 'relative', zIndex: 3 }} size={size} />
				</Grid>
			</Fade>
		</Box>
	)
}

export default LoadingWrapper;
