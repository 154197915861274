import React from 'react';

// material ui staff
import { Typography } from '@mui/material';


// views
import PageContentLayout from '../../component/layouts/PageContentLayout';
import { CertificateType } from '../../contracts/contracts';
import CertificateListView from '../../component/certificateComponents/CertificateListView';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { getCertificatePlaceholderText } from '../../utils/certificateTitleOptions';

const HseCardView: React.FC<{}> = () => {
    const languageContext = useLanguageContext();

    const getHseCardImagePlaceholder = (side: 'front' | 'back') => {
        if (side === 'front') {
            return getCertificatePlaceholderText(languageContext.getMessage('clickHere'), languageContext.getMessage('toUpload'), languageContext.getMessage('frontSide'), languageContext.getMessage('ofHseCard'))
        }

        if (side === 'back') {
            return getCertificatePlaceholderText(languageContext.getMessage('clickHere'), languageContext.getMessage('toUpload'), languageContext.getMessage('backSide'), languageContext.getMessage('ofHseCard'))
        }
    }

    return (
        <PageContentLayout>
                <>
                    <Typography variant="h5" style={{ marginTop: 10 }}>
                        {languageContext.getMessage('hseCard')}
                    </Typography>

                    <CertificateListView
                        cardScannerEnabled={true}
                        dialogTitle={languageContext.getMessage('hseCard')}
                        confirmRemoveDialogTitle={languageContext.getMessage('doYouWantToDeleteHseCard')}
                        certificateType={CertificateType.HSE}
                        placeholder={{
                            front: getHseCardImagePlaceholder('front'),
                            back: getHseCardImagePlaceholder('back'),
                        }}
                        frontSideRequired
                        backSideRequired
                        confirmationDialogContent={{
                            front: {
                                cancelButtonText: languageContext.getMessage("cancel"),
                                confirmButtonText: languageContext.getMessage('next'),
                                description: languageContext.getMessage('noHseCardUploaded'),
                                title: languageContext.getMessage('doYouWantToProceed'),
                            },
                            back: {
                                cancelButtonText: languageContext.getMessage("cancel"),
                                confirmButtonText: languageContext.getMessage('save'),
                                description: languageContext.getMessage('noHseCardUploaded'),
                                title: languageContext.getMessage('doYouWantToProceed'),
                            }
                        }}
                    />
                </>

        </PageContentLayout>
    );
}

export default HseCardView;
