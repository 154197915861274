import * as React from 'react';

import { ProjectCompany } from '../../contracts/contracts';
import CardActions from "@mui/material/CardActions";
import ReactMarkdown from 'react-markdown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Grid, Card, CardMedia, CardContent, Typography, ListItem, Box, IconButton, Stack } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { getProjectCompanyCompanyLevelDescription } from '../../utils/projectCompanyTools';
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type props = {
	projectCompany: ProjectCompany;
	onProjectCompanyDetailsClick?: (projectCompany: ProjectCompany) => void;
	onDeleteProjectCompany?: (projectCompany: ProjectCompany) => void;
	showDeleteButton?: boolean;
}

const ProjectCompanyListItem: React.FC<props> = ({ projectCompany, onProjectCompanyDetailsClick, onDeleteProjectCompany, showDeleteButton }) => {

	const languageContext = useLanguageContext();

	const onDeleteHandler = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();

		if (typeof onDeleteProjectCompany === 'function') {
			onDeleteProjectCompany(projectCompany)
		}
	}

	return (
		<Grid
			item key={projectCompany.id} xs={12} role="button"
			onClick={() => {
				if (!onProjectCompanyDetailsClick) {
					return;
				}
				onProjectCompanyDetailsClick(projectCompany);
			}}
		>
			<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', color: "text.secondary" }}>
				<CardContent sx={{ flexGrow: 1, p: 1, pb: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Stack spacing={1}>
						<Typography variant="body1" sx={{
							flexGrow: 1,
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap'
						}}>
							{`${projectCompany.name}`}
						</Typography>
						<Typography variant="body2" sx={{
							flexGrow: 1,
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap'
						}}>
							{`${getProjectCompanyCompanyLevelDescription(projectCompany, languageContext)}`}
						</Typography>
					</Stack>
					{showDeleteButton && (
						<IconButton onClick={onDeleteHandler} color="primary" aria-label="upload picture" component="span">
							<DeleteIcon />
						</IconButton>
					)}
				</CardContent>
				<CardContent sx={{ height: 40, flexGrow: 1, p: 1, pt: 0 }}>
					<ReactMarkdown 
						children={projectCompany.projectCompanyDescription ?? ''}
						components={{ p: ({children, ...props}) => <Typography 
							variant="body2" 
							sx={{
								height: '100%',
								overflow: 'hidden',
							}}
						>{children}</Typography>}}
					/>
				</CardContent>
				<CardActions sx={{ px: 1, pt: 0, pb: 1, paddingTop: '0px', display: 'flex', justifyContent: 'space-between' }} style={{ paddingTop: '0px' }}>
					<Typography variant="caption" sx={{
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap'
					}}>
						{projectCompany.organizationNumber}
					</Typography>
					<KeyboardArrowRightIcon />
				</CardActions>
			</Card>
		</Grid>
	);
}

export default ProjectCompanyListItem
