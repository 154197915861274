import React, { createContext, useContext, useEffect, useState } from "react";
import { Information } from "../../../contracts/contracts";
import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/react-hooks';
import { QUERY_INFORMATION, getInformationsGraphqlQueryOptions } from './informationQueries'
import { useAuthContext } from "../../auth/authContext";

export interface InformationQueriesContext {
    fetchedInformations: Array<Information>,
    queryInformations: (information: Information) => void,
    queryResponse: LazyQueryResult<any, OperationVariables>,
}

const InformationQueriesContext = createContext<InformationQueriesContext>(null as unknown as InformationQueriesContext);

export const InformationQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedInformations, setFetchedInformations] = useState<Array<Information>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_INFORMATION);

    const queryInformations = (information: Information): void => {
        const queryOptions = getInformationsGraphqlQueryOptions(information);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.informations) {
            const newFetchedInformations: Array<Information> = queryResponse.data.informations;
            setFetchedInformations(newFetchedInformations);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedInformations([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const informationQueriesContext: InformationQueriesContext = {
        fetchedInformations,
        queryInformations,
        queryResponse,
    };

    return (
        <InformationQueriesContext.Provider value={informationQueriesContext}>
            {children}
        </InformationQueriesContext.Provider>
    );
}

export const useInformationQueriesContext = (): InformationQueriesContext => {
    return useContext(InformationQueriesContext);
}
