import Compressor from 'compressorjs';

export const compressFiles = (
    files: File[],
    onSuccess: (results: File[]) => void,
    onError?: (err: Error) => void,
    maxSizeInMb?: number) => {

    const compressedFiles: (File)[] = [];
    files.forEach((file, index) => compressFile(file, (result) => {
        compressedFiles.push(result);
        if (index >= files.length - 1) {
            onSuccess(compressedFiles);
        }
    }, onError, maxSizeInMb));
}

export const compressFile = (
    file: File,
    onSuccess: (result: File) => void,
    onError?: (err: Error) => void,
    maxSizeInMb?: number) => {

    maxSizeInMb = maxSizeInMb ?? 0.2;
    const maxSize = maxSizeInMb * Math.pow(10, 6);
    const compressionRate = file.size && file.size > maxSize ? maxSize / file.size : 1.0;
    console.log('compressionRate', compressionRate);

    new Compressor(file, {
        quality: compressionRate,
        success: onSuccess,
        error: (err) => {
            if (onError) {
                onError(err);
            }
            else {
                handleCompressionToolsError(err);
            }
        },
    });
}

export const handleCompressionToolsError = (err: Error) => {
    console.error(err.message);
    alert(err.message);
}

export const isPictureFile = (base64File: string): boolean => {
    return base64File.startsWith('data:image/');
}