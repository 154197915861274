import React, { useEffect, useRef, useState } from 'react'
import { useLazyQuery } from "@apollo/react-hooks";

import { Typography, Box, typographyClasses, Grid, Checkbox, Stack, Button } from '@mui/material'
import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown'

// types
import { Information, InformationApproval, InformationApprovalType, InformationVerification, StorageFile } from '../../contracts/contracts'
import {
    getStorageFilesGraphqlQueryOptions,
    QUERY_STORAGE_FILES
} from "../../contexts/storageFile/queries/storageFileQueries";

// views
import ShowVimeoVideo from '../videoComponents/ShowVimeoVideo'
import { useInformationContext } from '../../contexts/information/informationContext';
import { useUrlContext } from '../../contexts/url/urlContext';
import BasicMenu from '../generalComponents/BasicMenu';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { getStorageFileFullKey, parseStorageFileToBase64Files } from '../../utils/fileTools';
import LoadingWrapper from '../loadingComponents/loadingWrapper';
import { SafetyFileViewer } from './SafetyFileViewer';
import { stringNotEmpty } from '../../utils/stringTools';

type Props = {
    information: Information | undefined;
    informationVerification?: InformationVerification | undefined;
    onVideoWatched?: () => void;
    onFileWatched?: () => void;
    onInformationApproval?: (areAnswersCorrect: boolean) => void;
}

const SafetyInformation: React.FC<Props> = ({ information, informationVerification, onVideoWatched, onFileWatched, onInformationApproval }) => {
    const languageContext = useLanguageContext();
    const informationContext = useInformationContext();
    const urlContext = useUrlContext();

    const [loadInformationFileQuery, queryInformationFileResponse] = useLazyQuery(QUERY_STORAGE_FILES);
    const [videoFailedMessage, setVideoFailedMessage] = useState<string | undefined>(undefined);

    const [base64Files, setBase64Files] = useState<string[]>([])

    const urlState = urlContext.getUrlState();
    const parentInformationId = informationContext.getParentInformationId(information);
    const relatedInformations = informationContext.getRelatedInformations(parentInformationId);

    const informationIdToShow = (urlState.informationIdToShow ?? undefined) as string | undefined;
    const informationToShow = informationIdToShow ? (relatedInformations.find(relatedInformation => relatedInformation.id === informationIdToShow) ?? information) : information;

    const [userAnswers, setUserAnswers] = useState<InformationApproval[]>([])

    const areAnswersCorrect = (information: Information, selectedUserAnswers: InformationApproval[]) => {
        const shouldBeApprovedAnswers = information?.informationApprovals?.filter(info => info.approval === InformationApprovalType.APPROVED) ?? [];
        const shouldBeNotApprovedAnswers = information?.informationApprovals?.filter(info => info.approval === InformationApprovalType.NOT_APPROVED) ?? [];

        let isCorrect = true;
        isCorrect = isCorrect && (shouldBeApprovedAnswers.filter(shouldBeApprovedAnswer =>
            selectedUserAnswers.some(selectedUserAnswer =>
                selectedUserAnswer.approvalDescription === shouldBeApprovedAnswer.approvalDescription &&
                selectedUserAnswer.approval === InformationApprovalType.APPROVED)).length === shouldBeApprovedAnswers.length);

        isCorrect = isCorrect && (shouldBeNotApprovedAnswers.filter(shouldBeNotApprovedAnswer =>
            !selectedUserAnswers.some(selectedUserAnswer =>
                selectedUserAnswer.approvalDescription === shouldBeNotApprovedAnswer.approvalDescription &&
                selectedUserAnswer.approval === InformationApprovalType.APPROVED)).length === shouldBeNotApprovedAnswers.length);
        return isCorrect
    }

    const isSelected = (currentInformationApproval: InformationApproval): boolean => {
        const existingAnswer = userAnswers.find(userAnswer => userAnswer.approvalDescription === currentInformationApproval.approvalDescription)
        return existingAnswer ? existingAnswer.approval === InformationApprovalType.APPROVED : false
    }

    const setRelatedInformationToShow = (relatedInformation: Information | undefined): boolean => {
        urlState.informationIdToShow = relatedInformation?.id;
        urlState.informationLanguageToShow = relatedInformation?.language;
        const urlQuery = urlState ? urlContext.buildUrlQuery({ ...urlState }) : '';
        urlContext.pushUrlQuery(urlQuery);
        if (relatedInformation?.id) {
            return true;
        }
        return false;
    }

    const onClickRelatedInformation = (index: number) => {
        if (relatedInformations.length > index) {
            const relatedInformation = relatedInformations[index];
            setRelatedInformationToShow(relatedInformation);
        }
    }

    const languageMenuItems: React.ReactNode[] = relatedInformations.map(relatedInformation => <>{relatedInformation.language ?? ''}</>);

    const videoUrl = (informationToShow?.videoUrl ?? '').trim();
    const videoUrlExists = videoUrl.length > 0;

    const informationFilename = (informationToShow?.informationFilename ?? '').trim();
    const informationFilenameExists = informationFilename.length > 0;

    const informationLanguageToShow = (urlState.informationLanguageToShow ?? '').trim();
    const showSelectInformationLanguage = stringNotEmpty(parentInformationId) && informationLanguageToShow.length === 0 && relatedInformations.length > 1;

    const currentInformationToShow = useRef<Information | undefined>(undefined);
    const currentInformationVerification = useRef<InformationVerification | undefined>(undefined);
    const currentshowSelectInformationLanguage = useRef<boolean | undefined>(undefined);
    useEffect(() => {
        if (currentInformationToShow.current?.id !== informationToShow?.id || 
            currentInformationVerification.current?.id !== informationVerification?.id || 
            currentshowSelectInformationLanguage.current !== showSelectInformationLanguage) {
            currentInformationToShow.current = { ...informationToShow };
            currentInformationVerification.current = { ...informationVerification };
            currentshowSelectInformationLanguage.current = showSelectInformationLanguage;
            const defaultVerified = !showSelectInformationLanguage && informationVerification?.verified;
            const resetUserAnswers = userAnswers.length === 0 || 
                                     currentInformationToShow.current?.id !== informationToShow?.id || 
                                     currentshowSelectInformationLanguage.current !== showSelectInformationLanguage;

            if (resetUserAnswers) {
                const defaultUserAnswers: InformationApproval[] = defaultVerified ? (information?.informationApprovals ?? []).map(approval => { return { ...approval } }) : [];
                setUserAnswers(defaultUserAnswers);
                if (onInformationApproval && informationToShow?.id) {
                    const defaultAreAnswersCorrect = defaultVerified ? true : (informationToShow?.informationApprovals?.length ?? 0) === 0;
                    onInformationApproval(defaultAreAnswersCorrect);
                }
            }
        }
        if (informationLanguageToShow.length > 0) {
            const matchingLanguageRelatedInformation = relatedInformations.find(relatedInformation => (relatedInformation.language ?? '').trim().toLowerCase() === informationLanguageToShow.toLowerCase());
            if (setRelatedInformationToShow(matchingLanguageRelatedInformation)) {
                return;
            }
        }
        if (!videoUrlExists && onVideoWatched) {
            onVideoWatched();
        }
        else {
            setVideoFailedMessage(undefined);
        }
        if (!informationFilenameExists && onFileWatched) {
            onFileWatched();
        }
    }, [informationToShow?.id, informationVerification?.id, showSelectInformationLanguage])

    useEffect(() => {
        if (informationFilenameExists) {
            const fileKey = getStorageFileFullKey(informationToShow?.id ?? '');
            loadInformationFileQuery(getStorageFilesGraphqlQueryOptions({ key: fileKey }))
        }
    }, [informationFilenameExists, informationToShow])

    useEffect(() => {
        if (queryInformationFileResponse.data) {
            const fileKey = getStorageFileFullKey(informationToShow?.id ?? '');
            const downloadedStorageFiles: StorageFile[] = queryInformationFileResponse.data.storageFiles;
            const [base64Files, base64ImageMetadata] = parseStorageFileToBase64Files(downloadedStorageFiles, fileKey);

            setBase64Files(base64Files)

            if (onFileWatched) {
                onFileWatched();
            }
        }
    }, [queryInformationFileResponse.data])

    if (showSelectInformationLanguage) {
        return <Stack direction={'column'} alignItems="center" spacing={2}>
            <Typography variant="h5">{languageContext.getMessage('selectLanguage')}</Typography>
            {languageMenuItems.map((languageMenuItem, index) => <Button key={`language-${index}`} onClick={() => onClickRelatedInformation(index)}>
                {languageMenuItem}
            </Button>)}
        </Stack>
    }

    return (
        <>
            {parentInformationId && relatedInformations.length > 1 && <BasicMenu
                buttonContent={informationToShow?.language ?? languageContext.getMessage('languages')}
                menuItems={languageMenuItems}
                onClickItem={(menuItem, index) => onClickRelatedInformation(index)}
                buttonProps={{ sx: { position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }, variant: 'outlined' }} />}
            {videoUrlExists && <Grid sx={{ minHeight: '320px', width: { xs: '100%', md: '50%' }, margin: 'auto' }}>
                {
                    videoFailedMessage !== undefined ? <Typography variant="h5">{`${languageContext.getMessage('videoFailedPlayingWithErrorMessage')}: ${videoFailedMessage}`}</Typography> :
                        <>
                            <ShowVimeoVideo
                                video={videoUrl}
                                autoplay={true}
                                controls={true}
                                showByline={false}
                                start={0}
                                responsive={true}
                                style={{ width: '100%', textAlign: 'center' }}
                                onError={(error) => {
                                    setVideoFailedMessage(error.message);
                                    if (typeof onVideoWatched === 'function') {
                                        onVideoWatched();
                                    }
                                }}
                                onEnd={() => {
                                    if (typeof onVideoWatched === 'function') {
                                        onVideoWatched();
                                    }
                                }}
                            />
                        </>
                }
            </Grid>}

            <Typography variant="h5" style={{ marginTop: 10, marginBottom: 20 }}>
                {informationToShow?.title || languageContext.getMessage('safetyInformation')}
            </Typography>
            <SafetyAgreementBox marginTop={1.5}>
                {informationFilenameExists && (
                    <LoadingWrapper loading={queryInformationFileResponse.loading}>
                        {queryInformationFileResponse.loading ? <PdfFrameLoadingPlaceholder /> : null}
                    </LoadingWrapper>
                )}
                {base64Files.map((base64File, idx) => (
                    <SafetyFileViewer key={idx} fileBase64={base64File} filename={information?.informationFilename} />
                ))}

                <ReactMarkdown 
                    components={{
                        p: ({ children, ...props}) => <Typography {...props}>{children}</Typography>
                    }} 
                    children={informationToShow?.information ?? ''}
                />
                {informationToShow?.informationApprovals?.map((informationApproval, index) =>
                    <Stack key={`approval-${index}`} direction="row" spacing={2}>
                        <Checkbox onChange={(event) => {
                            if (onInformationApproval) {
                                const existingAnswer = userAnswers.find(answer => informationApproval.approvalDescription === answer.approvalDescription)

                                let newAnswers: InformationApproval[] = []
                                if (existingAnswer) {
                                    newAnswers = userAnswers.map(answer => {
                                        if (informationApproval.approvalDescription === answer.approvalDescription) {
                                            answer.approval = event.target.checked ? InformationApprovalType.APPROVED : InformationApprovalType.NOT_APPROVED;
                                        }

                                        return answer
                                    })

                                } else {
                                    newAnswers = [
                                        ...userAnswers,
                                        { ...informationApproval, approval: event.target.checked ? InformationApprovalType.APPROVED : InformationApprovalType.NOT_APPROVED }
                                    ]
                                }

                                const areCorrect = areAnswersCorrect(informationToShow, newAnswers)
                                setUserAnswers(newAnswers)

                                onInformationApproval(areCorrect)
                            }
                        }} checked={isSelected(informationApproval)} />
                        
                        <ReactMarkdown 
                            components={{
                                p: ({ children, ...props}) => <Typography {...props}>{children}</Typography>
                            }} 
                            children={informationApproval.approvalDescription ?? ''}
                        />
                    </Stack>
                )}
            </SafetyAgreementBox>
        </>
    )
}

const SafetyAgreementBox = styled(Box)(({ theme }) => ({
    [`.${typographyClasses.root}`]: {
        fontSize: '0.8rem',

    },
    [`.${typographyClasses.root}+.${typographyClasses.root}`]: {
        marginTop: '10px',
    }
}))

const PdfFrameLoadingPlaceholder = styled('div')(() => ({
    width: '100%',
    height: '50vh',
}))



export default SafetyInformation