import { HealthStatus, ElementStatusEnum } from "../contracts/contracts";
import { Theme } from '@mui/material/styles';
import { PaletteColor } from "@mui/material/styles/createPalette";
import { ReactElement } from "react";
import { Error, InfoOutlined, CheckCircleOutline, ErrorOutlined, WarningOutlined, Info, Warning, CheckCircle, CancelOutlined, NotInterested, LocalShipping, Search, Build } from '@mui/icons-material';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import React from "react";
import { Chip } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export function getRenderedStatus(status: HealthStatus, theme: Theme): ReactElement {
    switch (status) {
        case HealthStatus.UNHEALTHY: return <Error style={{ color: theme.palette.error.main }} />;
        case HealthStatus.DEGRADED: return <Warning style={{ color: theme.palette.warning.main }} />;
        case HealthStatus.HEALTHY: return <CheckCircle style={{ color: theme.palette.success.main }} />;
        default: return <NotInterested />;
    }
}

export const getHealthStausColor = (status: HealthStatus, theme: Theme): PaletteColor => {
    switch (status) {
        case HealthStatus.HEALTHY: return theme.palette.success;
        case HealthStatus.DEGRADED: return theme.palette.warning;
        case HealthStatus.UNHEALTHY: return theme.palette.error;
        default: return theme.palette.success;
    }
}

export const getElementStausColor = (status: ElementStatusEnum, theme: Theme): PaletteColor => {
    switch (status) {
        case ElementStatusEnum.INFORMATION: return theme.palette.info;
        case ElementStatusEnum.WARNING: return theme.palette.warning;
        case ElementStatusEnum.CANCEL: return theme.palette.error;
        case ElementStatusEnum.SUCCESS: return theme.palette.success;
        case ElementStatusEnum.ALERT: return theme.palette.error;
        default: return theme.palette.success;
    }
}

export const getRenderedElementStatus = (
    status: ElementStatusEnum,
    theme: Theme,
    outlined: boolean = false,
    style: React.CSSProperties | undefined = undefined): ReactElement => {

    if (!style) {
        style = {}
    }

    if (!style.color) {
        style.color = getElementStausColor(status, theme).main;
    }

    if (outlined) {
        switch (status) {
            case ElementStatusEnum.INFORMATION: return <InfoOutlined style={style} />;
            case ElementStatusEnum.WARNING: return <WarningOutlined style={style} />;
            case ElementStatusEnum.CANCEL: return <CancelOutlined style={style} />;
            case ElementStatusEnum.ALERT: return <ErrorOutlined style={style} />;
            case ElementStatusEnum.SUCCESS: return <CheckCircleOutline style={style} />;
        }
    }

    switch (status) {
        case ElementStatusEnum.INFORMATION: return <Info style={style} />;
        case ElementStatusEnum.WARNING: return <Warning style={style} />;
        case ElementStatusEnum.CANCEL: return <CancelRoundedIcon style={style} />;
        case ElementStatusEnum.ALERT: return <ErrorRoundedIcon style={style} />;
        case ElementStatusEnum.SUCCESS: return <CheckCircle style={style} />;
    }
}

export const getRenderedRiskStatus = (
    row: number, col: number): ReactElement => {

    let value = row * col;
    let status: ElementStatusEnum = ElementStatusEnum.ALERT;

    if (value > 0 && value < 4) {
        status = ElementStatusEnum.SUCCESS;
    }
    else if (value > 3 && value < 9) {
        status = ElementStatusEnum.INFORMATION;
    }
    else if (value > 8 && value < 13) {
        status = ElementStatusEnum.CANCEL;
    }
    else if (value > 12 && value < 20) {
        status = ElementStatusEnum.WARNING;
    }

    switch (status) {
        case ElementStatusEnum.SUCCESS: return <Chip icon={<DoneIcon style={{ color: '#31d655' }} />}
            label="Akseptabel" variant="outlined" style={{ borderColor: '#31d655' }} />;
        case ElementStatusEnum.INFORMATION: return <Chip icon={<ErrorOutlineOutlinedIcon style={{ color: '#FFCE56' }} />}
            label="Lav" variant="outlined" style={{ borderColor: '#FFCE56' }} />;
        case ElementStatusEnum.CANCEL: return <Chip icon={<ErrorOutlineOutlinedIcon style={{ color: '#FFA500' }} />}
            label="Medium" variant="outlined" style={{ borderColor: '#FFA500' }} />;
        case ElementStatusEnum.WARNING: return <Chip icon={<ErrorOutlineOutlinedIcon style={{ color: '#FF4646' }} />}
            label="Høy" variant="outlined" style={{ borderColor: '#FF4646' }} />;
        case ElementStatusEnum.ALERT: return <Chip icon={<CancelOutlinedIcon style={{ color: '#FF0900' }} />}
            label="Ekstrem" variant="outlined" style={{ borderColor: '#FF0900' }} />;
    }
}

export const getMobileRenderedRiskStatus = (
    value: number): ReactElement => {

    let status: ElementStatusEnum = ElementStatusEnum.ALERT;
    if (value > 0 && value < 200000) {
        status = ElementStatusEnum.SUCCESS;
    }
    else if (value > 200000 && value < 300000) {
        status = ElementStatusEnum.INFORMATION;
    }
    else if (value > 300000 && value < 400000) {
        status = ElementStatusEnum.CANCEL;
    }
    else if (value > 400000 && value < 600000) {
        status = ElementStatusEnum.WARNING;
    }

    switch (status) {
        case ElementStatusEnum.SUCCESS: return <DoneIcon style={{ color: '#31d655' }} />
        case ElementStatusEnum.INFORMATION: return <ErrorOutlineOutlinedIcon style={{ color: '#FFCE56' }} />
        case ElementStatusEnum.CANCEL: return <ErrorOutlineOutlinedIcon style={{ color: '#FFA500' }} />
        case ElementStatusEnum.WARNING: return <ReportProblemOutlinedIcon style={{ color: '#FF4646' }} />
        case ElementStatusEnum.ALERT: return <CancelOutlinedIcon style={{ color: '#FF0900' }} />
    }
}