import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid, TextField, Autocomplete, CircularProgress } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { withTheme } from '@mui/styles';
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { ProjectCompanyTabs, useProjectCompanyContext } from "../../contexts/projectCompany/projectCompanyContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { ProjectCompany, DocumentType, ActiveState } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { Guid } from "../../utils/common-types";
import ProjectCompaniesList from "../../component/projectCompanyComponents/ProjectCompaniesList";


import AddIcon from '@mui/icons-material/Add';
import { useApplicationProfileContext } from "../../contexts/applicationProfile/applicationProfileContext";
import SaveDialog from "../../component/generalComponents/SaveDialog";
import ConfirmDeleteDialog from "../../component/confirmComponents/ConfirmDeleteDialog";
import FabButton from "../../component/generalComponents/FabButton";
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import EditProjectCompanyDetails from "../../component/projectCompanyComponents/EditProjectCompanyDetails";
import { getProjectCompanyCompanyLevelDescription } from "../../utils/projectCompanyTools";

const ProjectCompaniesView: React.FC<{}> = () => {

    const [openNewCompanyDialog, setOpenNewCompanyDialog] = useState<boolean>(false)
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false)

    const newProjectCompanyRef = useRef<ProjectCompany>({})
    const projectCompanyToRemoveRef = useRef<ProjectCompany>({})

    const urlContext = useUrlContext();
    const languageContext = useLanguageContext();
    const ticketContext = useTicketContext();
    const projectCompanyContext = useProjectCompanyContext();
    const applicationProfileContext = useApplicationProfileContext();
    const menuContext = useMenuContext()

    const urlState = urlContext.getUrlState();
    const projectId = urlState.selectedProjectId;
    const [search, setSearch] = React.useState<string | ProjectCompany | null>(null);

    const projectCompanySearch = projectCompanyContext.getProjectCompanySearch();
    projectCompanySearch.projectId = projectId;

    const allProjectCompanies = projectCompanyContext.getProjectCompanies();

    const loading = projectCompanyContext.loadingProjectCompanies;

    const defaultSplitChar = ';;'
    const populateProjectCompanyWithProprietarySearch = (projectCompany: ProjectCompany, companyNames: string[], companyLevelDescriptions: string[]): ProjectCompany => {
        companyNames.push(projectCompany.name ?? '');
        const companyLevelDescription = getProjectCompanyCompanyLevelDescription(projectCompany, languageContext);
        companyLevelDescriptions.push(companyLevelDescription);
        projectCompany.proprietarySearch = `${(projectCompany.name ?? '').toLowerCase()}${defaultSplitChar}${companyLevelDescription}`;
        return projectCompany;
    }

    const [projectCompanies, searchValues] = useMemo(() => {
        if (projectCompanyContext.loadingProjectCompanies) {
            return [allProjectCompanies, []];
        }
        const companyNames: string[] = [];
        const companyLevelDescriptions: string[] = [];
        const projectCompanies = allProjectCompanies.map(projectMember => populateProjectCompanyWithProprietarySearch(projectMember, companyNames, companyLevelDescriptions));
        const searchValues = companyNames.concat(companyLevelDescriptions);
        const uniqueSearchValues: string[] = [];
        searchValues.forEach(searchValue => {
            if (searchValue.trim().length > 0 && uniqueSearchValues.findIndex(uniqueSearchValue => searchValue === uniqueSearchValue) < 0) {
                uniqueSearchValues.push(searchValue);
            }
        });
        return [projectCompanies, uniqueSearchValues];
    }, [allProjectCompanies]);

    const filteredProjectCompanies = useMemo(() => {
        if (!search) return projectCompanies;
        if (typeof search === "string") return search.trim().length === 0 ? projectCompanies : projectCompanies.filter(projectCompany => projectCompany?.proprietarySearch?.toLowerCase().includes(search.toLowerCase()));
        if (typeof search === "object") return projectCompanies.filter(projectCompany => projectCompany.id === search.id);
        return [];
    }, [projectCompanies, search]);

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([DocumentType.PROJECT_COMPANY]);
        projectCompanyContext.searchProjectCompanies(projectCompanySearch);
        // applicationProfileContext.searchApplicationProfiles(applicationProfileSearch);
    }, [projectId]);

    const updateUrl = (route: ApplicationRoute, urlState?: Dictionary<string | number | Date>) => {
        const urlQuery = urlState ? urlContext.buildUrlQuery(urlState) : '';

        urlContext.pushUrlQuery(urlQuery, route.route)
    }

    const onDeleteProjectCompany = (company: ProjectCompany) => {
        projectCompanyToRemoveRef.current = { ...company };
        setOpenConfirmDeleteDialog(true)
    }

    const onCloseConfirmDeleteDialog = async (confirmDelete: boolean) => {
        if (confirmDelete && projectCompanyToRemoveRef.current) {
            await removeProjectCompany(projectCompanyToRemoveRef.current)
        }

        setOpenConfirmDeleteDialog(false)
    }

    const removeProjectCompany = async (projectCompany: ProjectCompany) => {
        const projectCompanyToRemove: ProjectCompany = { ...projectCompany };
        projectCompanyToRemove.state = ActiveState.INACTIVE;
        await projectCompanyContext.mutateProjectCompany(projectCompanyToRemove)
    }

    const addNewProjectCompany = async () => {
        if (newProjectCompanyRef.current.organizationNumber) {
            const newCompany = { ...newProjectCompanyRef.current };
            newCompany.state = ActiveState.ACTIVE;
            newCompany.projectId = projectId;

            await projectCompanyContext.mutateProjectCompany(newCompany);
        }

        setOpenNewCompanyDialog(false)
    }

    const onProjectCompanyDetailsClick = (projectCompany: ProjectCompany | undefined) => {
        if (projectCompany?.id) {
            const route = menuContext.getApplicationRouteById(ApplicationRouteId.ProjectCompanyDetails)
            const formattedRoute = { ...route, route: route.route.replace(':companyId', projectCompany.id) }

            updateUrl(formattedRoute)
        }
    }

    return (
        <>
            <Grid container spacing={1} flexWrap='nowrap' flexDirection='column' flexBasis='100%' >
                <>
                    <Grid item xs={12}>
                        <Autocomplete
                            sx={{ mb: 2 }}
                            freeSolo
                            onInputChange={(e, value) => setSearch(value)}
                            onChange={(e, value) => setSearch(value)}
                            getOptionLabel={option => option}
                            options={searchValues}
                            renderInput={(params) => loading ? <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 56 }}><CircularProgress /></Grid> : <TextField {...params} label={languageContext.getMessage("search")} />}
                        />
                        <ProjectCompaniesList projectCompanies={filteredProjectCompanies} onProjectCompanyDetailsClick={onProjectCompanyDetailsClick}
                            onDeleteProjectCompany={onDeleteProjectCompany}
                            showDeleteButton={true}
                        />
                    </Grid>

                    {projectId && (
                        <FabButton
                            sx={{ zIndex: 5 }}
                            onClick={() => setOpenNewCompanyDialog(true)}
                            icon={<AddIcon />}
                            label={languageContext.getMessage("add")}
                        />
                    )}

                </>
            </Grid>

            <ConfirmDeleteDialog
                title={languageContext.getMessage("doYouWantToDeleteSafetyInformation")}
                open={openConfirmDeleteDialog}
                onClose={onCloseConfirmDeleteDialog}
            />

            <SaveDialog
                title={languageContext.getMessage("newCompany")}
                open={openNewCompanyDialog}
                onClose={() => {
                    setOpenNewCompanyDialog(false)
                    newProjectCompanyRef.current = {}
                }}
                onSave={async () => await addNewProjectCompany()}
            >
                <EditProjectCompanyDetails open={openNewCompanyDialog} company={{projectId: projectId}} onCompanyChange={(company) => {
                    newProjectCompanyRef.current = { ...company };
                }} />
            </SaveDialog>
        </>
    );
}

export default withTheme(ProjectCompaniesView);
