import React, { createContext, useContext, useEffect, useState } from "react";
import { Information, DocumentType, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface InformationSubscriptionsContext {
    subscribedInformations: Array<Information>,
}

const InformationSubscriptionsContext = createContext<InformationSubscriptionsContext>(null as unknown as InformationSubscriptionsContext);

export const InformationSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedInformations, setSubscribedInformations] = useState<Array<Information>>([]);

    const updateSubscribedInformations = (stateDocuments: StateDocument[]) => {
        const subscribedInformations = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.INFORMATION && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as Information);
        setSubscribedInformations(subscribedInformations);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedInformations([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedInformations(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const informationContext: InformationSubscriptionsContext = {
        subscribedInformations,
    };

    return (
        <InformationSubscriptionsContext.Provider value={informationContext}>
            {children}
        </InformationSubscriptionsContext.Provider>
    );
}

export const useInformationSubscriptionsContext = (): InformationSubscriptionsContext => {
    return useContext(InformationSubscriptionsContext);
}
