import React, { createContext, useContext } from "react";
import { InformationVerification, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getInformationVerificationsGraphqlMutationOptions, MUTATE_INFORMATION_VERIFICATION } from "./informationVerificationMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface InformationVerificationMutationsContext {
    mutateInformationVerification: (informationVerification: InformationVerification, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void) => void;
}

const InformationVerificationMutationsContext = createContext<InformationVerificationMutationsContext>(null as unknown as InformationVerificationMutationsContext);

export const InformationVerificationMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [informationVerificationMutation] = useMutation(MUTATE_INFORMATION_VERIFICATION);

    const handleInformationVerificationMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        if (result.errors || !result.data) {
            throw new Error(result.errors?.toString() ?? '');
        }
        const tickets: Array<Ticket> = result.data.mutateInformationVerification.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
        if (tickets.length === 0 && 'id' in variables) {
            onTicketSubscribed(variables['id'], variables);
        }
    }

    const mutateInformationVerification = (informationVerification: InformationVerification, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        const options = getInformationVerificationsGraphqlMutationOptions(informationVerification)
        informationVerificationMutation(options).then((result) => handleInformationVerificationMutationResult(result, options.variables, onTicketSubscribed, onError));
    }

    const informationVerificationMutationsContext: InformationVerificationMutationsContext = {
        mutateInformationVerification
    };

    return (
        <InformationVerificationMutationsContext.Provider value={informationVerificationMutationsContext}>
            {children}
        </InformationVerificationMutationsContext.Provider>
    );
}

export const useInformationVerificationMutationsContext = (): InformationVerificationMutationsContext => {
    return useContext(InformationVerificationMutationsContext);
}
