import { Box, Button, Theme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Add, Remove } from "@mui/icons-material";
import React from "react";
import { GeneralDatePicker } from "./GeneralDatePicker";

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

type props = {
    label: string;
    date?: Date;
    monthPicker?: boolean;
    onChange?: (date: Date | undefined) => void;
    onAccept?: (date: Date | undefined) => void;
    onPopDate?: () => void;
    onPushDate?: () => void;
    popDateDisabled?: boolean;
    pushDateDisabled?: boolean;
    readOnly?: boolean;
}

export const DatePickerWithPushAndPop: React.FC<props> = ({
    label,
    date,
    monthPicker,
    onChange, 
    onAccept,
    onPopDate, 
    onPushDate, 
    popDateDisabled, 
    pushDateDisabled, 
    readOnly}) => {
 
    const classes = useStyles();
    
    return <> 
        <Box display="flex" flexDirection="row">
            <Box>
                <GeneralDatePicker
                    label={label}
                    date={date}
                    readOnly={readOnly}
                    monthPicker={monthPicker}
                    onChange={newDate => {
                    if (onChange) {
                        onChange(newDate)
                    }
                    }} 
                    onAccept={newDate => {
                        if (onAccept) {
                          onAccept(newDate)
                        }
                      }} 
                    />
            </Box>
            <Box display="flex" flexDirection="row">
                <Box>
                    <Button
                        color="inherit"
                        size="small"
                        className={classes.button}
                        style={{paddingRight: '8px'}}
                        startIcon={<Remove />}
                        onClick={onPopDate} 
                        disabled={popDateDisabled || readOnly}>
                    </Button>
                </Box>
                <Box>
                    <Button
                        color="inherit"
                        size="small"
                        className={classes.button}
                        startIcon={<Add />}
                        onClick={onPushDate} 
                        disabled={pushDateDisabled || readOnly}>
                    </Button>
                </Box>
            </Box>
        </Box>
    </>
}