import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { StorageDocument, ActiveState, Ticket } from "../../../contracts/contracts"; 

export const QUERY_STORAGE_DOCUMENT: DocumentNode = gql(`
    query stateDocuments(
            $id: UUID
            $documentType: DocumentType
            $state: ActiveState
            $tickets: TicketsInput
        ) {
        stateDocuments(
            id: $id
            documentType: $documentType
            state: $state
            tickets: $tickets
        )  {
            id
            documentType
            stateTime
            state
            created
            changed
            changedBy
            content
        }
    }
`);

export const getStorageDocumentsGraphqlQueryOptions = (storageDocument: Partial<StorageDocument>): QueryHookOptions => {
    storageDocument.state = storageDocument.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (storageDocument.id !== undefined) options.variables['id'] = storageDocument.id
    if (storageDocument.documentType !== undefined) options.variables['documentType'] = storageDocument.documentType
    if (storageDocument.state !== undefined) options.variables['state'] = storageDocument.state

    return options;
}

export const getStorageDocumentsGraphqlQueryOptionsWithTickets = (tickets: Partial<Ticket>[]): QueryHookOptions => {
    const options: QueryHookOptions = {};
    options.variables = {};
    options.variables['tickets'] = {'tickets': tickets.map(ticket => {
        return {
            'parentDocumentId': ticket.parentDocumentId,
            'parentDocumentType': ticket.parentDocumentType,
        }
    })}
    return options;
}
