import { FC } from "react";
import { CompanyInfo } from "../../contracts/contracts";
import { TextFieldProps } from "@mui/material";
import SearchCompanyInfo from "./SearchCompanyInfo";

type props = {
    companyInfoSearch?: CompanyInfo;
    parentCompanyInfoSearch?: CompanyInfo;
    onSelectedCompanyInfoChange: (newSelectedCompanyInfo: CompanyInfo | undefined) => void;
    onSelectedParentCompanyInfoChange: (newSelectedCompanyInfo: CompanyInfo | undefined) => void;
    error?: boolean;
    secondError?: boolean;
    label?: React.ReactNode;
    secondLabel?: React.ReactNode;
    textFieldProps?: TextFieldProps;
    secondFieldProps?: TextFieldProps;
    parentCompanyEnabled?: boolean;
}
const SearchCompanyView: FC<props> = ({
    companyInfoSearch,
    parentCompanyInfoSearch,
    onSelectedCompanyInfoChange,
    onSelectedParentCompanyInfoChange,
    error,
    secondError,
    label,
    secondLabel,
    textFieldProps,
    secondFieldProps,
    parentCompanyEnabled,
}) => {

    return (
        <>
            <SearchCompanyInfo
                companyInfoSearch={companyInfoSearch}
                onSelectedCompanyInfoChange={onSelectedCompanyInfoChange}
                error={error}
                label={label}
                textFieldProps={textFieldProps}
            />
            {parentCompanyEnabled && (
            <SearchCompanyInfo
                sx={{ marginTop: 1 }}
                companyInfoSearch={parentCompanyInfoSearch}
                onSelectedCompanyInfoChange={onSelectedParentCompanyInfoChange}
                error={secondError}
                label={secondLabel}
                textFieldProps={secondFieldProps}
            />
            )}
        </>
    )
}

export default SearchCompanyView;