import React, { useEffect, useState } from 'react';

// material ui staff
import { Grid, Box, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

// contexts
import { useLanguageContext } from '../../contexts/language/LanguageContext';


// views
import { Close } from '@mui/icons-material';

type Props = {
    open: boolean;
    title: string;
    onSave: () => void;
    onClose: () => void;
    saveIsDisabled?: boolean;
}

const SaveDialog: React.FC<Props> = ({ title, onSave, onClose, open, saveIsDisabled, children }) => {
    const languageContext = useLanguageContext();

    const onSaveHandler = () => {
        if (typeof onSave === 'function') {
            onSave()
        }
    }

    const onCloseHandler = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xs'} 
            open={open}
            onClose={onCloseHandler}
            >
            <DialogTitle>
                {title}
            <IconButton onClick={onCloseHandler} title={languageContext.getMessage('cancel')} sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}>
                <Close />
            </IconButton>
            </DialogTitle>

            <DialogContent>
                {children}
            </DialogContent>

            <DialogActions>
                <Button onClick={onSaveHandler} disabled={saveIsDisabled} color="primary" variant="contained">
                    {languageContext.getMessage('save')}
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default SaveDialog;