import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { StateDocument, ActiveState, Ticket } from "../../../contracts/contracts"; 

export const QUERY_STATE_DOCUMENT: DocumentNode = gql(`
    query stateDocuments(
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $tickets: TicketsInput
    ) {
    stateDocuments(
        id: $id
        documentType: $documentType
        state: $state
        tickets: $tickets
    )  {
        id
        documentType
        stateTime
        state
        created
        changed
        changedBy
        content
    }
}
`);

export const getStateDocumentsGraphqlQueryOptions = (stateDocument: Partial<StateDocument>): QueryHookOptions => {
    stateDocument.state = stateDocument.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (stateDocument.id !== undefined) options.variables['id'] = stateDocument.id
    if (stateDocument.documentType !== undefined) options.variables['documentType'] = stateDocument.documentType
    if (stateDocument.state !== undefined) options.variables['state'] = stateDocument.state

    return options;
}

export const getStateDocumentsGraphqlQueryOptionsWithTickets = (tickets: Partial<Ticket>[]): QueryHookOptions => {
    const options: QueryHookOptions = {};
    options.variables = {};
    options.variables['tickets'] = {'tickets': tickets.map(ticket => {
        return {
            'parentDocumentId': ticket.parentDocumentId,
            'parentDocumentType': ticket.parentDocumentType,
        }
    })}
    return options;
}
