import React, { useEffect, useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { Theme, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Checkbox, styled } from '@mui/material';
import { makeStyles, withTheme } from '@mui/styles';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { ProjectMember } from "../../contracts/contracts";

const useStyles = makeStyles((theme: Theme) => ({
  rowCellRoot: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  paper: {
    padding: '1em'
  },
  divider: {
    marginBottom: '1em'
  },
  tableCellHeader: {
    minWidth: theme.spacing(20),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export interface NotificationSettingsProps {
}

const NotificationSettings: React.FC<NotificationSettingsProps> = (props: NotificationSettingsProps) => {

  const classes = useStyles();
  const languageContext = useLanguageContext();
  const projectMemberContext = useProjectMemberContext();

  const selectedProjectMember = projectMemberContext.getSelectedProjectMember(true);

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [editingAll, setEditingAll] = useState<boolean>(false);
  const [editedProjectMember, setEditedProjectMember] = useState<ProjectMember>({});
  
  useEffect(() => {
    if (!selectedProjectMember?.id) {
      return;
    }
    setEditedProjectMember({
      ...selectedProjectMember,
      notificationSettings: { ...selectedProjectMember.notificationSettings }
    })
  }, [selectedProjectMember]);

  const handleUpdateNotificationSettings = async (): Promise<void> => {
    if (!isChanged) {
      return;
    }
    setIsChanged(false);
    if (!editedProjectMember.id) {
      return;
    }
    const mutatedProjectMember = { ...editedProjectMember };
    await projectMemberContext.mutateProjectMember(mutatedProjectMember);
  }

  const handleEditingAllButton = async () => {
    if (editingAll) {
      await handleUpdateNotificationSettings();
    }
    setEditingAll(!editingAll);
  }

  return (<>
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHeadStyled>
          <TableRow>
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={async () => await handleEditingAllButton()}>
                {editingAll ? <SaveIcon /> : <EditIcon />}
              </IconButton>
            </TableCell>
            <TableCell>{languageContext.getMessage('newsFeed')}</TableCell>
          </TableRow>
        </TableHeadStyled>
        <TableBody>
          <React.Fragment>
            <TableRow className={classes.rowCellRoot}>
              <TableCell>
                {languageContext.getMessage('email')}
              </TableCell>
              <TableCell>
                <Checkbox
                  disabled={!editingAll}
                  checked={!editedProjectMember.notificationSettings?.disableEmailNotificationFromNewsFeed}
                  onChange={event => {
                    editedProjectMember.notificationSettings = editedProjectMember.notificationSettings ?? {};
                    editedProjectMember.notificationSettings.disableEmailNotificationFromNewsFeed = !event.target.checked;
                    setEditedProjectMember({ ...editedProjectMember });
                    setIsChanged(true);
                  }} />
              </TableCell>
            </TableRow>
          </React.Fragment>
        </TableBody>
      </Table>
    </TableContainer>
  </>);
}

const TableHeadStyled = styled(TableHead)`
  th {
    font-weight: 600;
  }
`

export default withTheme(NotificationSettings)