import React, { createContext, useContext, useEffect, useState } from "react";
import { ProjectCompany, DocumentType, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface ProjectCompanySubscriptionsContext {
    subscribedProjectCompanies: Array<ProjectCompany>,
}

const ProjectCompanySubscriptionsContext = createContext<ProjectCompanySubscriptionsContext>(null as unknown as ProjectCompanySubscriptionsContext);

export const ProjectCompanySubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedProjectCompanies, setSubscribedProjectCompanies] = useState<Array<ProjectCompany>>([]);

    const updateSubscribedProjectCompanies = (stateDocuments: StateDocument[]) => {
        const subscribedProjectCompanies = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.PROJECT_COMPANY && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as ProjectCompany);
        setSubscribedProjectCompanies(subscribedProjectCompanies);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedProjectCompanies([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedProjectCompanies(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectCompanyContext: ProjectCompanySubscriptionsContext = {
        subscribedProjectCompanies,
    };

    return (
        <ProjectCompanySubscriptionsContext.Provider value={projectCompanyContext}>
            {children}
        </ProjectCompanySubscriptionsContext.Provider>
    );
}

export const useProjectCompanySubscriptionsContext = (): ProjectCompanySubscriptionsContext => {
    return useContext(ProjectCompanySubscriptionsContext);
}
