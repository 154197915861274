import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React from "react";
import { InfoTechAccessGroup, InfoTechSettings } from "../../contracts/contracts";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Box, CircularProgress } from '@mui/material';
import { stringNotEmpty } from '../../utils/stringTools';
import { SxProps } from "@mui/system";
import { useQueryInfoTechAccessGroups } from '../../hooks/useQueryInfoTechAccessGroups';

type props = {
    infoTechApiKey: string | undefined;
    infoTechProjectId: string | undefined;
    infoTechSettings: InfoTechSettings | undefined;
    onSelectedInfoTechSettingsChange: (newSelectedInfoTechSettings: InfoTechSettings | undefined) => void;
    label?: React.ReactNode;
    error?: boolean | undefined;
    textFieldProps?: TextFieldProps
    disabled?: boolean;
    helperText?: string;
    sx?: SxProps;
}

export const getInfoTechSettingsRenderLabel = (infoTechAccessGroup: InfoTechAccessGroup): string => {
    let label = '';
    if (stringNotEmpty(infoTechAccessGroup.accessGroupName)) {
        label = `${infoTechAccessGroup.accessGroupName}`;
    }
    else if (stringNotEmpty(infoTechAccessGroup.accessGroupId)) {
        label = `${infoTechAccessGroup.accessGroupId}`;
    }
    return label;
}

const SearchInfoTechAccessGroups: React.FC<props> = ({
    infoTechApiKey,
    infoTechProjectId,
    infoTechSettings,
    onSelectedInfoTechSettingsChange,
    label,
    error,
    textFieldProps,
    helperText,
    sx,
    disabled }) => {

    const languageContext = useLanguageContext();
    const [fetchedInfoTechAccessGroups, loading] = useQueryInfoTechAccessGroups(infoTechApiKey, infoTechProjectId);

    const handleChange = (event: any, newSelectedInfoTechAccessGroup: InfoTechAccessGroup | null) => {
        onSelectedInfoTechSettingsChange({
            ...infoTechSettings,
            infoTechAccessGroupId: newSelectedInfoTechAccessGroup?.accessGroupId,
            infoTechAccessGroupName: newSelectedInfoTechAccessGroup?.accessGroupName,
        });
    }

    return <Autocomplete
        sx={sx}
        disabled={disabled}
        options={fetchedInfoTechAccessGroups}
        getOptionLabel={(infoTechAccessGroup) => getInfoTechSettingsRenderLabel(infoTechAccessGroup)}
        value={{
            accessGroupId: infoTechSettings?.infoTechAccessGroupId,
            accessGroupName: infoTechSettings?.infoTechAccessGroupName,
        }}
        onChange={handleChange}
        renderOption={(props, accessGroup) => (
            <Box component="li" {...props}>
                {loading ? <CircularProgress size={10} /> : getInfoTechSettingsRenderLabel(accessGroup)}
            </Box>
        )}
        renderInput={(params) => <TextField {...params}
            error={error}
            label={label ?? languageContext.getMessage('accessGroup')}
            helperText={helperText ?? ''}
            variant="outlined"
            {...textFieldProps} />}
    />
}

export default SearchInfoTechAccessGroups;
