import React, { createContext, useContext } from "react";
import { useAuthContext } from "../auth/authContext";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { ApolloLink, DefaultOptions } from "@apollo/react-hooks";
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { GraphqlAuthSettingsContextProvider } from "./graphqlAuthSettingsContext";
import { useBrowserContext } from "../browserContext/browserContext";

export interface GraphqlContext {
}

const GraphqlContext = createContext<GraphqlContext>(null as unknown as GraphqlContext);

export const GraphqlContextProvider: React.FC<{}> = ({ children }) => {

    const browserContext = useBrowserContext();
    const authContext = useAuthContext();

    const authLink = setContext((_, { headers }) => {
        const accessToken = authContext.jwtAccessToken();
        return {
          headers: {
            ...headers,
            authorization: accessToken ? accessToken : "",
            tenant: browserContext.getTenantName(),
          }
        }
    });

    const httpLink = new HttpLink({
        uri: '/graphql'
    });

    const authHttpLink = ApolloLink.from([authLink, httpLink])

    const getWebsocketUrl = (): string => {
        const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
        const hostname = window.location.hostname;
        const port = window.location.port;
        const urlPath = '/graphql';
        const websocketUrl = `${wsProtocol}://${hostname}:${port}${urlPath}`;
        return websocketUrl;
    }

    const wsLink = new WebSocketLink({
        uri: getWebsocketUrl(),
        options: {
            reconnect: true,
            timeout: 30000
        }
    });

    const splitLink = split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        authHttpLink,
      );

    const defaultApolloClientOptions: DefaultOptions = {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
    }

    const cache = new InMemoryCache({});

    const client = new ApolloClient({
        link: splitLink,
        cache: cache,
        defaultOptions: defaultApolloClientOptions
    })

    const value = {
    };

    return (
        <ApolloProvider client={client}>
            <GraphqlContext.Provider value={value}>
                <GraphqlAuthSettingsContextProvider>
                    {children}
                </GraphqlAuthSettingsContextProvider>
            </GraphqlContext.Provider>
        </ApolloProvider>
    );
}

export const useGraphqlContext = (): GraphqlContext => {
    return useContext(GraphqlContext);
}
