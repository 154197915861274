import * as React from "react";
import MuiAlert, { AlertProps } from '@mui/material/Alert';

export const AlertComponent = React.forwardRef<HTMLDivElement, AlertProps>(function AlertComponent(
    props,
    ref,
) {
    return (
        <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    )
});
