import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, Link, MenuItem, Select, Stack, Switch, TextField } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useLazyQuery } from "@apollo/react-hooks";
import { useInformationContext } from "../../contexts/information/informationContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { Information, RoleType, InformationAccess, ActiveState, InformationApprovalType, InformationApproval, StorageFile } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { navigateToExternalUrl } from "../../utils/urlTools";
import BasicMenu from "../generalComponents/BasicMenu";
import ConfirmDeleteDialog from "../confirmComponents/ConfirmDeleteDialog";
import { QUERY_STORAGE_FILES, getStorageFilesGraphqlQueryOptions } from "../../contexts/storageFile/queries/storageFileQueries";
import { getStorageFileFullKey } from "../../utils/fileTools";
import { checkIfDuplicateApprovalDescriptions, checkInformationIsValid } from "../../utils/informationTools";

type Props = {
    information: Information | undefined;
    onInformationChange: (information: Information) => void;
    onInformationForceSaveForPreview?: () => Promise<Information | undefined>;
}

const EditInformationDetails: React.FC<Props> = ({ information, onInformationChange, onInformationForceSaveForPreview }) => {

    const [editedInformationDetails, setEditedInformationDetails] = useState<Information>({});
    const [editedInformationIsValid, setEditedInformationIsValid] = useState<boolean>(true);

    const languageContext = useLanguageContext();
    const urlContext = useUrlContext();
    const menuContext = useMenuContext();
    const projectMemberContext = useProjectMemberContext();
    const informationContext = useInformationContext();

    const urlState = urlContext.getUrlState();
    const defaultInformationApproval: InformationApproval = { approval: InformationApprovalType.APPROVED };
    const allParentInformationsForProject = informationContext.getProjectParentInformations(editedInformationDetails.projectId);

    const [loadInformationFileQuery, queryInformationFileResponse] = useLazyQuery(QUERY_STORAGE_FILES);
    const [existingInformationFiles, setExistingInformationFiles] = useState<StorageFile[]>([]);
    const informationFilesExist = existingInformationFiles.length > 0 || editedInformationDetails.file;
    const checkInformationFileExists = (informationToCheck: Information | undefined) => {
        if (!informationToCheck?.id) {
            return;
        }
        const informationFileKey = getStorageFileFullKey(informationToCheck.id ?? '');
        loadInformationFileQuery(getStorageFilesGraphqlQueryOptions({ searchKey: informationFileKey }));
    };

    useEffect(() => {
        const storageFiles: StorageFile[] = queryInformationFileResponse.data?.storageFiles ?? [];
        setExistingInformationFiles(storageFiles);
    }, [queryInformationFileResponse.data]);

    useEffect(() => {
        checkInformationFileExists(information);
        const newInformation = { ...information, file: undefined };
        if (!newInformation?.id) {
            newInformation.informationApprovals = newInformation.informationApprovals ?? [];
            if (newInformation.informationApprovals.length === 0) {
                newInformation.informationApprovals.push({ ...defaultInformationApproval });
            }
        }
        setEditedInformationDetails(newInformation);
    }, [information])

    useEffect(() => {
        if (typeof onInformationChange === 'function') {
            onInformationChange(editedInformationDetails);
        }
        const informationIsValid = checkInformationIsValid(editedInformationDetails);
        setEditedInformationIsValid(informationIsValid);
    }, [editedInformationDetails])

    const updateUrl = (route: ApplicationRoute, urlState?: Dictionary<string | number | Date>) => {
        const urlQuery = urlState ? urlContext.buildUrlQuery(urlState) : '';

        urlContext.pushUrlQuery(urlQuery, route.route)
    }

    const onPreviewInformation = async () => {
        if (onInformationForceSaveForPreview) {
            await onInformationForceSaveForPreview();
            if (information?.id) {
                const route = menuContext.getApplicationRouteById(ApplicationRouteId.SafetyInformationPreview);
                const formattedRoute = { ...route, route: route.route.replace(':informationId', information.id) }
                updateUrl(formattedRoute, { ...urlState });
            }
        }
    }

    const parentInformationId = informationContext.getParentInformationId(information);
    const parentInformation = informationContext.getInformation(parentInformationId);
    const relatedInformations = informationContext.getRelatedInformations(parentInformationId);
    const isParentInformation = informationContext.isParentInformation(information, parentInformationId);

    const onClickRelatedInformation = async (index: number) => {
        if (relatedInformations.length > index && onInformationForceSaveForPreview) {
            const relatedInformation = relatedInformations[index];
            await onInformationForceSaveForPreview();
            if (relatedInformation?.id) {
                const route = menuContext.getApplicationRouteById(ApplicationRouteId.SafetyInformationDetails);
                const formattedRoute = { ...route, route: route.route.replace(':informationId', relatedInformation.id) }
                updateUrl(formattedRoute, { ...urlState });
            }
        }
    }

    const addChildInformation = async () => {
        if (parentInformationId && parentInformation) {
            const childInformation: Information = { ...parentInformation };
            childInformation.id = undefined;
            childInformation.informationId = parentInformationId;
            childInformation.language = `<${languageContext.getMessage('language')}>`;
            childInformation.informationFilename = '';
            childInformation.file = undefined;
            childInformation.information = '';
            const documentId = await informationContext.mutateInformation(childInformation);
            if (documentId) {
                const route = menuContext.getApplicationRouteById(ApplicationRouteId.SafetyInformationDetails);
                const formattedRoute = { ...route, route: route.route.replace(':informationId', documentId) }
                updateUrl(formattedRoute, { ...urlState })
            }
        }
    }

    const onDeleteInformation = async () => {
        const informationsToDelete: Information[] = isParentInformation ? relatedInformations : (information ? [information] : []);
        informationsToDelete.forEach(async informationToDelete => {
            if (!informationToDelete?.id) {
                return;
            }
            informationToDelete.state = ActiveState.INACTIVE;
            await informationContext.mutateInformation(informationToDelete);
        })

        if (isParentInformation) {
            const route = menuContext.getApplicationRouteById(ApplicationRouteId.SafetyInformations);
            const formattedRoute = { ...route }
            updateUrl(formattedRoute, { ...urlState })
        }
        else {
            const route = menuContext.getApplicationRouteById(ApplicationRouteId.SafetyInformationDetails);
            const formattedRoute = { ...route, route: route.route.replace(':informationId', parentInformationId ?? '') }
            updateUrl(formattedRoute, { ...urlState })
        }
    }

    const getLanguageMenuItem = (relatedInformation: Information): string => {
        return relatedInformation.id === parentInformationId ? `${(relatedInformation.language ?? '')} (${languageContext.getMessage('mainLanguage')})` : relatedInformation.language ?? '';
    }

    const languageMenuItems: React.ReactNode[] = relatedInformations.map(relatedInformation => <React.Fragment key={relatedInformation.id}>{getLanguageMenuItem(relatedInformation)}</React.Fragment>).concat([<>
        <Button variant="contained" onClick={async () => await addChildInformation()}><AddIcon fontSize="medium" /></Button>
    </>]);

    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
    const handleOnClickDelete = () => {
        setOpenConfirmDeleteDialog(true);
    };

    const handleOnCloseConfirmDeleteDialog = async (deleteIsConfirmed: boolean) => {
        if (deleteIsConfirmed) {
            await onDeleteInformation();
        }
        setOpenConfirmDeleteDialog(false);
    }

    const onFileChangeHandler = (e: any) => {
        e.preventDefault();
        const file: File = e.target.files[0];
        editedInformationDetails.informationFilename = file.name;
        editedInformationDetails.file = file;
        const newInformationDetails: Information = { ...editedInformationDetails }
        setEditedInformationDetails(newInformationDetails);
    }

    const validVideoHost = 'https://vimeo.com/';
    const videoUrl = (editedInformationDetails.videoUrl ?? '').trim();
    const videoUrlIsInvalid = videoUrl.length > 0 && !videoUrl.startsWith(validVideoHost)
    const isCreatingNewInformation = information?.id === undefined;
    const hasWriteAccessToSelectedProject = isCreatingNewInformation || (information?.projectId && projectMemberContext.hasProjectAccess(RoleType.WRITER, information?.projectId));
    return (
        <>
            <ConfirmDeleteDialog
                title={languageContext.getMessage('doYouWantToDeleteSafetyInformation')}
                open={openConfirmDeleteDialog}
                onClose={async (deleteIsConfirmed) => await handleOnCloseConfirmDeleteDialog(deleteIsConfirmed)}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} sx={{ marginBottom: 3, marginLeft: 1 }}>
                        <Stack direction="column" spacing={2}>
                            <FormGroup>
                                <FormControlLabel control={<Switch
                                    checked={editedInformationDetails.informationAccess === InformationAccess.PUBLIC}
                                    onChange={event => {
                                        const newInformationAccess = event.target.checked ? InformationAccess.PUBLIC : InformationAccess.PRIVATE;
                                        const newInformationDetails: Information = { ...editedInformationDetails, informationAccess: newInformationAccess }
                                        setEditedInformationDetails(newInformationDetails);
                                    }} />} label={languageContext.getMessage('publish')} />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Switch
                                    checked={editedInformationDetails.informationAccess === InformationAccess.PUBLIC_FOR_MEMBERS || editedInformationDetails.informationAccess === InformationAccess.PUBLIC}
                                    onChange={event => {
                                        const newInformationAccess = event.target.checked ? InformationAccess.PUBLIC_FOR_MEMBERS : InformationAccess.PRIVATE;
                                        const newInformationDetails: Information = { ...editedInformationDetails, informationAccess: newInformationAccess }
                                        setEditedInformationDetails(newInformationDetails);
                                    }} />} label={languageContext.getMessage('publishToMembers')} />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Switch
                                    checked={editedInformationDetails.informationApprovalsQuizActivated}
                                    onChange={event => {
                                        const newInformationDetails: Information = { ...editedInformationDetails, informationApprovalsQuizActivated: event.target.checked }
                                        setEditedInformationDetails(newInformationDetails);
                                    }} />} label={languageContext.getMessage('quiz')} />
                            </FormGroup>
                        </Stack>
                        {parentInformationId && <Stack direction="column" spacing={2}>
                            <BasicMenu
                                buttonContent={languageContext.getMessage('languages')}
                                menuItems={languageMenuItems}
                                onClickItem={async (menuItem, index) => await onClickRelatedInformation(index)}
                                buttonProps={{ variant: 'outlined' }} />
                            <IconButton onClick={handleOnClickDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </Stack>}
                    </Stack>

                    <FormControl fullWidth sx={{ marginBottom: 3 }}>
                        <InputLabel>{languageContext.getMessage('position')}</InputLabel>
                        <Select
                            value={editedInformationDetails.orderPosition ?? allParentInformationsForProject.length}
                            label={languageContext.getMessage('position')}
                            onChange={(event) => {
                                const newInformationDetails: Information = { ...editedInformationDetails, orderPosition: event.target.value as number }
                                setEditedInformationDetails(newInformationDetails);
                            }}
                        >
                            {allParentInformationsForProject.map((information, index) =>
                                <MenuItem key={information.id} value={index}>{index + 1}</MenuItem>)}
                            {!editedInformationDetails.id && <MenuItem value={allParentInformationsForProject.length}>{allParentInformationsForProject.length + 1}</MenuItem>}
                        </Select>
                    </FormControl>
                    <TextField
                        label={languageContext.getMessage('language')}
                        variant="outlined"
                        disabled={!hasWriteAccessToSelectedProject}
                        value={editedInformationDetails.language ?? ''}
                        onChange={(event) => {
                            const newInformationDetails: Information = { ...editedInformationDetails, language: event.target.value as string }
                            setEditedInformationDetails(newInformationDetails);
                        }}
                        fullWidth
                        sx={{ marginBottom: 3 }}
                    />
                    <TextField
                        label={languageContext.getMessage('title')}
                        variant="outlined"
                        disabled={!hasWriteAccessToSelectedProject}
                        value={editedInformationDetails.title ?? ''}
                        onChange={(event) => {
                            const newInformationDetails: Information = { ...editedInformationDetails, title: event.target.value as string }
                            setEditedInformationDetails(newInformationDetails);
                        }}
                        fullWidth
                        sx={{ marginBottom: 3 }}
                    />
                    <TextField
                        error={videoUrlIsInvalid}
                        label={languageContext.getMessage('videoUrl')}
                        variant="outlined"
                        helperText={videoUrlIsInvalid ? `${languageContext.getMessage('theVideoMustBeHostedOn')}${validVideoHost}` : undefined}
                        disabled={!hasWriteAccessToSelectedProject}
                        value={editedInformationDetails.videoUrl ?? ''}
                        onChange={(event) => {
                            const videoUrl = (event.target.value as string).trim();
                            const newInformationDetails: Information = { ...editedInformationDetails, videoUrl: videoUrl }
                            setEditedInformationDetails(newInformationDetails);
                        }}
                        fullWidth
                        sx={{ marginBottom: 3 }}
                    />

                    <Stack direction="row" spacing={2} sx={{ marginBottom: 3 }}>
                        <label htmlFor="icon-button-file">
                            <input style={{ display: 'none' }} disabled={!hasWriteAccessToSelectedProject} accept="*" id="icon-button-file" type="file" onChange={onFileChangeHandler} />
                            <Stack direction="row" spacing={1}>
                                <IconButton disabled={!hasWriteAccessToSelectedProject} aria-label="upload file" component="span" style={{ marginTop: 10 }}>
                                    <FileUploadIcon />
                                </IconButton>
                                <IconButton disabled={!hasWriteAccessToSelectedProject || !informationFilesExist} style={{ marginTop: 10 }} onClick={() => {
                                    const newInformationDetails: Information = {
                                        ...editedInformationDetails,
                                        informationFilename: '',
                                        file: undefined
                                    }
                                    setExistingInformationFiles([]);
                                    setEditedInformationDetails(newInformationDetails);
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        </label>
                        <TextField
                            label={languageContext.getMessage('filename')}
                            disabled={!hasWriteAccessToSelectedProject || !informationFilesExist}
                            value={editedInformationDetails?.informationFilename ?? ''}
                            onChange={(event) => {
                                editedInformationDetails.informationFilename = event.target.value as string;
                                const newInformationDetails: Information = { ...editedInformationDetails }
                                setEditedInformationDetails(newInformationDetails);
                            }}
                            fullWidth
                        />
                    </Stack>

                    <TextField
                        label={languageContext.getMessage('safetyInformation')}
                        helperText={
                            <Link onClick={() => {
                                navigateToExternalUrl('https://www.markdownguide.org/basic-syntax/');
                            }}>
                                {languageContext.getMessage('markdownSupported')}
                            </Link>}
                        disabled={!hasWriteAccessToSelectedProject}
                        multiline
                        minRows={4}
                        maxRows={20}
                        value={editedInformationDetails.information ?? ''}
                        onChange={(event) => {
                            const newInformationDetails: Information = { ...editedInformationDetails, information: event.target.value as string }
                            setEditedInformationDetails(newInformationDetails);
                        }}
                        fullWidth
                        sx={{ marginBottom: 3 }}
                    />

                    {(editedInformationDetails.informationApprovals ?? []).map((informationApproval, index) =>
                        <Stack key={`approval-${index}`} direction="row" spacing={2} sx={{ marginBottom: 3 }}>
                            <Checkbox
                                checked={editedInformationDetails?.informationApprovals?.[index].approval === InformationApprovalType.APPROVED}
                                style={{ marginBottom: 20 }}
                                onChange={(e) => {
                                    editedInformationDetails.informationApprovals = editedInformationDetails.informationApprovals ?? [];
                                    editedInformationDetails.informationApprovals[index].approval = e.target.checked ? InformationApprovalType.APPROVED : InformationApprovalType.NOT_APPROVED

                                    const newInformationDetails: Information = { ...editedInformationDetails };
                                    setEditedInformationDetails(newInformationDetails);
                                }}
                            />
                            <IconButton style={{ marginBottom: 20, marginLeft: 0 }} onClick={() => {
                                editedInformationDetails.informationApprovals = editedInformationDetails.informationApprovals ?? [];
                                editedInformationDetails.informationApprovals.splice(index, 1);
                                const newInformationDetails: Information = { ...editedInformationDetails };
                                setEditedInformationDetails(newInformationDetails);
                            }}>
                                <DeleteIcon />
                            </IconButton>
                            <TextField
                                label={languageContext.getMessage('informationApproval')}
                                helperText={
                                    checkIfDuplicateApprovalDescriptions(editedInformationDetails, informationApproval) ?
                                        languageContext.getMessage('duplicateInformationApprovalWarning') :
                                        <Link onClick={() => {
                                            navigateToExternalUrl('https://www.markdownguide.org/basic-syntax/');
                                        }}>
                                            {languageContext.getMessage('markdownSupported')}
                                        </Link>}
                                disabled={!hasWriteAccessToSelectedProject}
                                multiline
                                minRows={1}
                                maxRows={20}
                                value={informationApproval?.approvalDescription ?? ''}
                                onChange={(event) => {
                                    editedInformationDetails.informationApprovals = editedInformationDetails.informationApprovals ?? [];
                                    editedInformationDetails.informationApprovals[index].approvalDescription = event.target.value as string;
                                    const newInformationDetails: Information = { ...editedInformationDetails }
                                    setEditedInformationDetails(newInformationDetails);
                                }}
                                error={checkIfDuplicateApprovalDescriptions(editedInformationDetails, informationApproval)}
                                fullWidth
                            />
                        </Stack>)}

                    <Stack direction="row" spacing={2} sx={{ marginBottom: 3 }}>
                        <IconButton style={{ marginTop: 10 }} onClick={() => {
                            editedInformationDetails.informationApprovals = editedInformationDetails.informationApprovals ?? [];
                            editedInformationDetails.informationApprovals.push({ ...defaultInformationApproval });
                            const newInformationDetails: Information = { ...editedInformationDetails };
                            setEditedInformationDetails(newInformationDetails);
                        }}>
                            <AddIcon />
                        </IconButton>
                    </Stack>
                </Grid>

                {information?.id && (
                    <Grid item xs={12}>
                        <Button sx={{ width: '100%' }} disabled={!editedInformationIsValid}
                            onClick={async () => await onPreviewInformation()}>{`${languageContext.getMessage('save')} & ${languageContext.getMessage('preview')}`}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </>
    )
}

export default EditInformationDetails