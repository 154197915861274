import { Grid, Link, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectCompanyContext } from "../../contexts/projectCompany/projectCompanyContext";
import { ProjectCompany } from "../../contracts/contracts";
import { getEmptyGuid } from "../../utils/guidTools";
import { navigateToExternalUrl } from "../../utils/urlTools";
import SearchCompanyInfo from "../companyInfoComponents/SearchCompanyInfo";
import SelectProjectCompany from "./SelectProjectCompany";
import SelectProjectCompanyGroup from "./SelectProjectCompanyGroup";
import { useProjectContext } from "../../contexts/project/projectContext";
import SearchInfoTechAccessGroups from "../infoTechComponents/SearchInfoTechAccessGroups";

type Props = {
    company: ProjectCompany | undefined;
    onCompanyChange?: (company: ProjectCompany) => void;
    open?: boolean;
}

const EditProjectCompanyDetails: React.FC<Props> = ({ company, onCompanyChange, open }) => {

    const [editedCompanyDetails, setEditedCompanyDetails] = useState<ProjectCompany>({});
    const languageContext = useLanguageContext();
    const projectCompanyContext = useProjectCompanyContext();
    const projectContext = useProjectContext();

    const filterParentProjectCompanies = (baseCompany: ProjectCompany, companies: ProjectCompany[]): ProjectCompany[] => companies.filter(parentProjectCompany =>
        parentProjectCompany.parentProjectCompanyId !== editedCompanyDetails.id &&
        parentProjectCompany.id !== editedCompanyDetails.id);

    const project = projectContext.getProject(company?.projectId);
    const possibleParentProjectCompanies = filterParentProjectCompanies(editedCompanyDetails, projectCompanyContext.getProjectCompanies());
    const parentProjectCompany = projectCompanyContext.getProjectCompany(editedCompanyDetails?.parentProjectCompanyId);

    useEffect(() => {
        const newEditedCompany: ProjectCompany = { ...company };
        if (!newEditedCompany.id && !newEditedCompany.parentProjectCompanyId) {
            const defaultParentProjectCompany = filterParentProjectCompanies(newEditedCompany, projectCompanyContext.getProjectCompanies()).find(projectCompany => projectCompany.projectCompanyLevel === 0);
            newEditedCompany.parentProjectCompanyId = defaultParentProjectCompany?.id;
        }
        setEditedCompanyDetails(newEditedCompany);
    }, [company, open])

    useEffect(() => {
        if (typeof onCompanyChange === 'function') {
            onCompanyChange(editedCompanyDetails);
        }
    }, [editedCompanyDetails])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SearchCompanyInfo
                    companyInfoSearch={{ organizationNumber: editedCompanyDetails.organizationNumber }}
                    onSelectedCompanyInfoChange={(companyInfo) => {
                        const newCompanyDetails: ProjectCompany = {
                            ...editedCompanyDetails,
                            name: companyInfo?.name ?? '',
                            organizationNumber: companyInfo?.organizationNumber ?? ''
                        }
                        setEditedCompanyDetails(newCompanyDetails);
                    }}
                    error={!editedCompanyDetails.organizationNumber || editedCompanyDetails.organizationNumber.trim().length === 0}
                    textFieldProps={{
                        fullWidth: true,
                    }}
                />
            </Grid>
            {(possibleParentProjectCompanies.length > 0 || parentProjectCompany?.id) && <Grid item xs={12}>
                <SelectProjectCompany
                    selectedProjectCompany={parentProjectCompany}
                    projectCompanyFilter={parentProjectCompanies => possibleParentProjectCompanies}
                    onSelectedProjectCompanyChange={(newParentProjectCompany) => {
                        const newCompanyDetails: ProjectCompany = {
                            ...editedCompanyDetails,
                            parentProjectCompanyId: newParentProjectCompany?.id ?? getEmptyGuid(),
                        }
                        setEditedCompanyDetails(newCompanyDetails);
                    }}
                    label={languageContext.getMessage('workingFor')}
                />
            </Grid>}
            <Grid item xs={12}>
                <SelectProjectCompanyGroup
                    selectedProjectCompanyGroup={editedCompanyDetails.projectCompanyGroup}
                    onSelectedProjectCompanyGroupChange={newProjectCompanyGroup => {
                        const newCompanyDetails: ProjectCompany = {
                            ...editedCompanyDetails,
                            projectCompanyGroup: newProjectCompanyGroup ?? '',
                        }
                        setEditedCompanyDetails(newCompanyDetails);
                    }}
                />
            </Grid>
            {project?.infoTechSettings?.enabled && <Grid item xs={12}>
                <SearchInfoTechAccessGroups
                    infoTechApiKey={project?.infoTechSettings?.infoTechApiKey}
                    infoTechProjectId={project?.infoTechSettings?.infoTechProjectId}
                    infoTechSettings={editedCompanyDetails.infoTechSettings}
                    onSelectedInfoTechSettingsChange={(newSelectedInfoTechSettings) => {
                        setEditedCompanyDetails({
                            ...editedCompanyDetails, 
                            infoTechSettings: newSelectedInfoTechSettings,
                        });
                    }}
                />
            </Grid>}
            <Grid item xs={12}>
                <TextField
                    label={languageContext.getMessage('name')}
                    variant="outlined"
                    value={editedCompanyDetails.projectCompanyContactName ?? ''}
                    onChange={(event) => {
                        const newCompanyDetails: ProjectCompany = {
                            ...editedCompanyDetails,
                            projectCompanyContactName: event.target.value as string,
                        }
                        setEditedCompanyDetails(newCompanyDetails);
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={languageContext.getMessage('email')}
                    variant="outlined"
                    value={editedCompanyDetails.projectCompanyContactEmail ?? ''}
                    onChange={(event) => {
                        const newCompanyDetails: ProjectCompany = {
                            ...editedCompanyDetails,
                            projectCompanyContactEmail: event.target.value as string,
                        }
                        setEditedCompanyDetails(newCompanyDetails);
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={languageContext.getMessage('phoneNumber')}
                    variant="outlined"
                    value={editedCompanyDetails.projectCompanyContactPhoneNumber ?? ''}
                    onChange={(event) => {
                        const newCompanyDetails: ProjectCompany = {
                            ...editedCompanyDetails,
                            projectCompanyContactPhoneNumber: event.target.value as string,
                        }
                        setEditedCompanyDetails(newCompanyDetails);
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={languageContext.getMessage('description')}
                    helperText={
                        <Link onClick={() => {
                            navigateToExternalUrl('https://www.markdownguide.org/basic-syntax/');
                        }}>
                            {languageContext.getMessage('markdownSupported')}
                        </Link>}
                    multiline
                    minRows={4}
                    maxRows={20}
                    value={editedCompanyDetails.projectCompanyDescription}
                    onChange={(event) => {
                        const newCompanyDetails: ProjectCompany = { ...editedCompanyDetails, projectCompanyDescription: event.target.value as string }
                        setEditedCompanyDetails(newCompanyDetails);
                    }}
                    fullWidth
                />
            </Grid>
        </Grid>
    )
}

export default EditProjectCompanyDetails