import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Settings } from "../../../contracts/contracts";
import { getSettingsGraphqlMutationOptions } from "../mutations/settingsMutations";

export const QUERY_SETTINGS: DocumentNode = gql(`
    query settings(
        $searchIndexStart: Int
        $searchIndexStop: Int
        $id: UUID
        $state: ActiveState
        $projectId: UUID
    ) {
        settings(
            searchIndexStart: $searchIndexStart
            searchIndexStop: $searchIndexStop
            id: $id
            state: $state
            projectId: $projectId
        ) {
            id
            documentType
            stateTime
            state
            created
        }
    }
`);

export const getSettingsGraphqlQueryOptions = (settings: Partial<Settings>): QueryHookOptions => {
    const options = getSettingsGraphqlMutationOptions(settings);
    options.variables = options.variables ?? {};
    if (settings.searchIndexStart !== undefined) options.variables['searchIndexStart'] = settings.searchIndexStart;
    if (settings.searchIndexStop !== undefined) options.variables['searchIndexStop'] = settings.searchIndexStop;
    return options;
}
