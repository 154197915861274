import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, StateDocument, StateDocumentEvents, DocumentType } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_STATE_DOCUMENT_EVENTS, getStateDocumentEventsGraphqlQueryOptions } from './stateDocumentEventsQueries'
import { useAuthContext } from "../../auth/authContext";
import { useTicketSubscriptionsContext } from "../../ticket/subscriptions/ticketSubscriptionsContext";

export interface StateDocumentEventsQueriesContext {
    fetchedStateDocumentEvents: Array<StateDocumentEvents>,
    getEvents: (documentId: string, documentType: DocumentType) => void,
}

const StateDocumentEventsQueriesContext = createContext<StateDocumentEventsQueriesContext>(null as unknown as StateDocumentEventsQueriesContext);

export const StateDocumentEventsQueriesContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const ticketSubscriptionsContext = useTicketSubscriptionsContext();
    const [fetchedStateDocumentEvents, setFetchedStateDocumentEvents] = useState<Array<StateDocumentEvents>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_STATE_DOCUMENT_EVENTS);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);

    const [stateDoc, setStateDoc] = useState<StateDocument>();

    const queryStateDocumentEvents = (): void => {
        if (isWaitingForResponse) {
            return;
        }
        if (stateDoc?.id === undefined) {
            return;
        }
        const queryOptions = getStateDocumentEventsGraphqlQueryOptions(stateDoc);
        setIsWaitingForResponse(true);
        loadQuery(queryOptions);
    }

    const getEvents = (documentId: string, documentType: DocumentType): void => {
        if (documentId && documentType && stateDoc?.id !== documentId) {
            let newStateDoc: StateDocument = {
                documentType: documentType,
                state: ActiveState.ACTIVE,
                id: documentId,
            }; 
            setStateDoc({...newStateDoc});
        }
    }


    useEffect(() => {
        if (!isWaitingForResponse && stateDoc?.id) {
            queryStateDocumentEvents();
        }
    }, [stateDoc]);

    useEffect(() => {
        if (stateDoc?.id && ticketSubscriptionsContext.subscribedTickets.findIndex(ticket => ticket.parentDocumentId === stateDoc?.id) >= 0) {
            queryStateDocumentEvents();
        };
    }, [ticketSubscriptionsContext.subscribedTickets]);

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.stateDocumentEvents) {
            const newFetchedStateDocumentEvents: Array<StateDocumentEvents> = queryResponse.data.stateDocumentEvents;
            setFetchedStateDocumentEvents(newFetchedStateDocumentEvents);
        }
        setIsWaitingForResponse(false);
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedStateDocumentEvents([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const stateDocumentEventsQueriesContext: StateDocumentEventsQueriesContext = {
        fetchedStateDocumentEvents,
        getEvents,
    };

    return (
        <StateDocumentEventsQueriesContext.Provider value={stateDocumentEventsQueriesContext}>
            {children}
        </StateDocumentEventsQueriesContext.Provider>
    );
}

export const useStateDocumentEventsQueriesContext = (): StateDocumentEventsQueriesContext => {
    return useContext(StateDocumentEventsQueriesContext);
}
