import React, { createContext, useContext, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client/react/hooks";
import { useLocation } from "react-router-dom";
import {
  getClientTracingGraphqlMutationOptions,
  MUTATE_CLIENT_TRACING,
} from "./clientTracingMutations";
import { useBrowserContext } from "../browserContext/browserContext";
import { checkIfTokenIsExpired, jwtAccessToken } from "../auth/authTools";

export interface TracingHandlerContext {}

const TracingHandlerContext = createContext<TracingHandlerContext>(
  null as unknown as TracingHandlerContext
);

type Props = {
  children?: React.ReactNode;
};

export const TracingHandlerContextProvider: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const browserContext = useBrowserContext();
  const sendingClientTracingRef = useRef<boolean>(false);
  const [clientTracingMutation] = useMutation(MUTATE_CLIENT_TRACING);

  const mutateClientTracing = (): void => {
    const url = window.location.href;
    const accessToken = jwtAccessToken();
    const tokenIsExpired = checkIfTokenIsExpired(accessToken);
    if (
      sendingClientTracingRef.current ||
      !browserContext.isTenantNameAvailable() ||
      tokenIsExpired
    ) {
      return;
    }
    const options = getClientTracingGraphqlMutationOptions(url);
    sendingClientTracingRef.current = true;
    clientTracingMutation(options)
      .catch((reason) => console.error("Failed to send client tracing", reason))
      .finally(() => {
        sendingClientTracingRef.current = false;
      });
  };

  useEffect(() => {
    if (location.pathname) {
      console.log("tracing", location.pathname);
      mutateClientTracing();
    }
  }, [location]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("tracing triggered", location.pathname);
      mutateClientTracing();
    }, 1000*60*3);
    return () => clearInterval(interval);
}, []);

  const contextValue: TracingHandlerContext = {};

  return (
    <TracingHandlerContext.Provider value={contextValue}>{children}</TracingHandlerContext.Provider>
  );
};

export const useTracingHandlerContext = (): TracingHandlerContext => {
  return useContext(TracingHandlerContext);
};
