import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

// material ui staff
import { Button, Step, StepLabel, Stepper, Typography, stepClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

// contexts
import { useApplicationProfileContext } from '../../contexts/applicationProfile/applicationProfileContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import { useInformationContext } from '../../contexts/information/informationContext';
import { useTicketContext } from '../../contexts/ticket/ticketContext';
import { useUrlContext } from '../../contexts/url/urlContext';

// types
import { ActiveState, ApplicationProfile, CertificateType, DocumentType, ProjectMember, MemberAccess, MemberState, InformationAccess } from '../../contracts/contracts';
import { LanguageKey } from "../../contexts/language/interfaces";
import { Dictionary } from '../../global-types';

import PageContentLayout from '../../component/layouts/PageContentLayout';
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from '../../contexts/menu/menuContext';
import MyProfileInfo from '../../component/applicationProfileComponents/MyProfileInfo';
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import CertificateListView from '../../component/certificateComponents/CertificateListView';
import SearchCompanyView from '../../component/companyInfoComponents/SearchCompanyView';
import { useCertificateContext } from '../../contexts/certificate/certificateContext';
import { ConfirmationDialog } from '../../component/dialogs/ConfirmationDialog';
import { updateApplicationProfileAndUserDetails } from '../../utils/applicationProfileTools';
import { useUserRoleContext } from '../../contexts/userRole/userRoleContext';
import { useAuthContext } from '../../contexts/auth/authContext';
import { getCertificatePlaceholderText } from '../../utils/certificateTitleOptions';
import { useCompanyInfoQueriesContext } from '../../contexts/generalIntegrations/queries/companyInfoQueriesContext';
import { getNumberOfAgreedInformations } from '../../utils/informationTools';
import { useInformationVerificationContext } from '../../contexts/informationVerification/informationVerificationContext';

enum StepTitles {
    myInfo = "My Info",
    company = "Company",
    hseCard = "HSE card",
    certificates = "Certificates"
}

type InfoStep = {
    title: StepTitles;
    languageKey: LanguageKey;
}

const STEPS: InfoStep[] = [
    { title: StepTitles.hseCard, languageKey: "hseCard" },
    { title: StepTitles.myInfo, languageKey: "myInfo" },
    { title: StepTitles.company, languageKey: "companyRaw" },
    { title: StepTitles.certificates, languageKey: "certificates" },
]

const JoinRegisterInformationView: React.FC<{}> = () => {

    const [activeStep, setActiveStep] = useState(0);
    const [companyOrganizationNumber, setCompanyOrganizationNumber] = useState('');
    const [parentCompanyOrganizationNumber, setParentCompanyOrganizationNumber] = useState('');
    const [isHseCardEmptyDialog, setIsHseCardEmptyDialog] = useState<boolean>(false);
    const [isCertificateEmptyDialog, setIsCertificateEmptyDialog] = useState<boolean>(false);
    const [editedApplicationProfile, setEditedApplicationProfile] = useState<ApplicationProfile>();

    const { params } = useRouteMatch<any>()
    const { projectId } = params

    const urlContext = useUrlContext();
    const menuContext = useMenuContext()
    const languageContext = useLanguageContext();

    const userRoleContext = useUserRoleContext();
    const authContext = useAuthContext();
    const ticketContext = useTicketContext();
    const projectContext = useProjectContext();
    const applicationProfileContext = useApplicationProfileContext();
    const projectMemberContext = useProjectMemberContext()
    const informationContext = useInformationContext();
    const informationVerificationContext = useInformationVerificationContext();
    const certificateContext = useCertificateContext();
    const companyInfoQueriesContext = useCompanyInfoQueriesContext();
    const hseCertificates = certificateContext.getCertificates().filter(certificate => certificate.certificateType === CertificateType.HSE && certificate.state === ActiveState.ACTIVE);
    const certificates = certificateContext.getCertificates().filter(certificate => certificate.certificateType === CertificateType.GENERAL && certificate.state === ActiveState.ACTIVE);

    const applicationProfileSearch = applicationProfileContext.getApplicationProfileSearch();
    const projectSearch = projectContext.getProjectSearch();
    const informationSearch = informationContext.getInformationSearch();
    projectSearch.id = projectId
    informationSearch.projectId = projectId;

    const project = projectContext.getProject(projectId)
    const applicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser();
    const projectMember = projectMemberContext.getProjectMembers()?.find(projectMember => projectMember.projectId === projectId && projectMember.applicationProfileId === applicationProfile?.id)

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([
            DocumentType.PROJECT,
            DocumentType.APPLICATION_PROFILE,
            DocumentType.INFORMATION_VERIFICATION]);

        projectContext.searchProjects(projectSearch);
        applicationProfileContext.searchApplicationProfiles(applicationProfileSearch);
        informationContext.searchInformations(informationSearch);
    }, [urlContext.currentLocation])
    

    useEffect(() => {
        setEditedApplicationProfile(applicationProfile)

        if (applicationProfile?.id) {
            const projectMemberSearch = projectMemberContext.getProjectMemberSearch()
            projectMemberSearch.applicationProfileId = applicationProfile.id

            projectMemberContext.searchProjectMembers(projectMemberSearch);
        }
    }, [applicationProfile])

    useEffect(() => {
        if (projectId && applicationProfile?.id) {

            ticketContext.setDocumentTypesToWatch([
                DocumentType.INFORMATION_VERIFICATION
            ])

            const informationVerificationSearch = informationVerificationContext.getInformationVerificationSearch()
            informationVerificationSearch.projectId = projectId
            informationVerificationSearch.applicationProfileId = applicationProfile?.id
            informationVerificationContext.searchInformationVerifications(informationVerificationSearch)
        }
    }, [projectId, applicationProfile?.id])

    useEffect(() => {
        setCompanyOrganizationNumber(projectMember?.organizationNumber ?? (applicationProfile?.organizationNumber ?? ''));
        setParentCompanyOrganizationNumber(projectMember?.parentOrganizationNumber ?? '');
    }, [projectMember, applicationProfile])

    const updateUrl = (route: ApplicationRoute, urlState?: Dictionary<string | number | Date>) => {
        const urlQuery = urlState ? urlContext.buildUrlQuery(urlState) : '';

        urlContext.pushUrlQuery(urlQuery, route.route)
    }

    const onProfileChange = useCallback((changedApplicationProfile: ApplicationProfile) => {
        if (changedApplicationProfile?.id) {
            setEditedApplicationProfile(changedApplicationProfile)
        }
    }, [])

    const saveApplicationProfile = async () => {
        if (editedApplicationProfile) {
            await updateApplicationProfileAndUserDetails(applicationProfile,
                editedApplicationProfile,
                applicationProfileContext,
                userRoleContext,
                authContext);
        }
    };

    const getRelevantInformations = () => informationContext.getProjectParentInformations(projectId).filter(information => information.informationAccess === InformationAccess.PUBLIC || information.informationAccess === InformationAccess.PUBLIC_FOR_MEMBERS);
    const getRelevantInformationVerifications = () => informationVerificationContext.getInformationVerifications().filter(infoVerification => infoVerification.projectId === projectId && infoVerification.applicationProfileId === applicationProfile?.id)

    const saveProjectMember = async () => {
        if (companyOrganizationNumber && applicationProfile?.id) {

            let projectMemberToSave: ProjectMember = {}
            const informations = getRelevantInformations();
            const defaultMemberAccess = informations.length > 0 ? MemberAccess.PUBLIC : MemberAccess.REQUESTING_MEMBER_ACCESS;

            // if exists - just update organization number
            if (projectMember) {
                projectMemberToSave = {
                    ...projectMember,
                    applicationProfileId: applicationProfile.id,
                    projectId: projectId,
                    state: ActiveState.ACTIVE,
                    memberState: MemberState.ACTIVE,
                    organizationNumber: companyOrganizationNumber,
                    parentOrganizationNumber: parentCompanyOrganizationNumber ?? undefined,
                }
            } else {
                projectMemberToSave = {
                    applicationProfileId: applicationProfile.id,
                    projectId: projectId,
                    state: ActiveState.ACTIVE,
                    memberState: MemberState.ACTIVE,
                    memberAccess: defaultMemberAccess,
                    organizationNumber: companyOrganizationNumber,
                    parentOrganizationNumber: parentCompanyOrganizationNumber ?? undefined,
                }
            }

            if ((applicationProfile.organizationNumber ?? '').trim().length === 0) {
                applicationProfile.organizationNumber = companyOrganizationNumber;
                onProfileChange({ ...applicationProfile });
                await saveApplicationProfile();
            }

            if (projectMemberToSave.memberAccess === MemberAccess.PUBLIC) {
                projectMemberToSave.memberAccess = defaultMemberAccess;
            }

            await projectMemberContext.mutateProjectMember(projectMemberToSave);
        }
    }

    const saveHSECard = async () => {

    }
    const saveCertificates = async () => {

    }

    const stepAction = async (step: InfoStep): Promise<void> => {
        switch (step.title) {
            case StepTitles.myInfo:
                await saveApplicationProfile();
                break;
            case StepTitles.company:
                await saveProjectMember();
                break;
            case StepTitles.hseCard:
                await saveHSECard()
                break;
            case StepTitles.certificates:
                await saveCertificates()
                break;
        }
    }

    const onBack = () => {
        if (activeStep === 0) {
            const route = menuContext.getApplicationRouteById(ApplicationRouteId.Join)
            const formattedRoute = { ...route, route: route.route.replace(':projectId', projectId) }
            updateUrl(formattedRoute);
            return;
        }

        setActiveStep((step) => step - 1);
    }

    const handleLastStep = () => {
        const informations = getRelevantInformations();
        const informationVerifications = getRelevantInformationVerifications();
        const numberOfAgreedInformations = getNumberOfAgreedInformations(applicationProfile, informationVerifications, informations);
        let route = menuContext.getApplicationRouteById(ApplicationRouteId.Join);
        if (projectMember?.memberAccess !== MemberAccess.DENIED_MEMBER_ACCESS && informations.length > numberOfAgreedInformations) {
            route = menuContext.getApplicationRouteById(ApplicationRouteId.Information);
        }
        const formattedRoute = { ...route, route: route.route.replace(':projectId', projectId) };
        updateUrl(formattedRoute);
    }

    const onCertificateDialogConfirm = async () => {
        await stepAction(STEPS[activeStep]);

        const newStep = activeStep + 1
        if (newStep === STEPS.length) {
            handleLastStep();
            return;
        }

        setActiveStep(newStep);
    }

    const onNext = async () => {
        if (STEPS[activeStep].title === StepTitles.hseCard && project?.hseScanningActivated && hseCertificates.length === 0) {
            setIsHseCardEmptyDialog(true);
            setActiveStep(activeStep);
            return;
        }

        if (STEPS[activeStep].title === StepTitles.hseCard && !project?.hseScanningActivated && hseCertificates.filter(cert => cert.numberOfCertificateFiles !== null).length === 0) {
            setIsHseCardEmptyDialog(true);
            setActiveStep(activeStep);
            return;
        }

        if (STEPS[activeStep].title === StepTitles.certificates && certificates.length === 0) {
            setIsCertificateEmptyDialog(true);
            setActiveStep(activeStep);
            return;
        }

        await stepAction(STEPS[activeStep]);

        const newStep = activeStep + 1
        if (newStep === STEPS.length) {
            handleLastStep();
            return;
        }

        setActiveStep(newStep);
    }

    const matchingCompanyInfo = companyInfoQueriesContext.fetchedCompanyInfos.find(companyInfo => (companyInfo.organizationNumber ?? '').trim() === companyOrganizationNumber.trim());
    const companyOrganizationNumberError = (matchingCompanyInfo?.organizationNumber ?? '').trim().length === 0 || matchingCompanyInfo?.notFound;
    const getCurrentStepComponent = (step: InfoStep) => {
        switch (step.title) {
            case StepTitles.myInfo:
                return <MyProfileInfo applicationProfile={applicationProfile} onProfileChange={onProfileChange} project={project} />;

            case StepTitles.company:
                return <SearchCompanyView
                    companyInfoSearch={{ organizationNumber: companyOrganizationNumber }}
                    parentCompanyInfoSearch={{ organizationNumber: parentCompanyOrganizationNumber }}
                    onSelectedCompanyInfoChange={(companyInfo) => setCompanyOrganizationNumber(companyInfo?.organizationNumber ?? '')}
                    onSelectedParentCompanyInfoChange={(companyInfo) => setParentCompanyOrganizationNumber(companyInfo?.organizationNumber ?? '')}
                    error={companyOrganizationNumberError}
                    label={languageContext.getMessage('employer')}
                    secondLabel={languageContext.getMessage('yourEmployerWorksOnBehalfOf')}
                    textFieldProps={{ helperText: languageContext.getMessage('selectEmployerCompanyHelperText') }}
                    secondFieldProps={{ helperText: languageContext.getMessage('selectWorkingCompanyHelperText') }}
                    parentCompanyEnabled={project?.parentProjectCompanyActivated}
                />

            case StepTitles.hseCard:
                const getHseCardImagePlaceholder = (side: 'front' | 'back') => {
                    if (side === 'front') {
                        return getCertificatePlaceholderText(languageContext.getMessage('clickHere'), languageContext.getMessage('toUpload'), languageContext.getMessage('frontSide'), languageContext.getMessage('ofHseCard'))
                    }

                    if (side === 'back') {
                        return getCertificatePlaceholderText(languageContext.getMessage('clickHere'), languageContext.getMessage('toUpload'), languageContext.getMessage('backSide'), languageContext.getMessage('ofHseCard'))
                    }
                }

                return <CertificateListView
                    cardScannerEnabled={project?.hseScanningActivated}
                    dialogTitle={languageContext.getMessage('hseCard')}
                    confirmRemoveDialogTitle={languageContext.getMessage('doYouWantToDeleteHseCard')}
                    certificateType={CertificateType.HSE}
                    placeholder={{
                        front: getHseCardImagePlaceholder('front'),
                        back: getHseCardImagePlaceholder('back')
                    }}
                    frontSideRequired
                    backSideRequired
                    confirmationDialogContent={{
                        front: {
                            cancelButtonText: languageContext.getMessage("cancel"),
                            confirmButtonText: languageContext.getMessage('next'),
                            description: languageContext.getMessage('noHseCardUploaded'),
                            title: languageContext.getMessage('doYouWantToProceed'),
                        },
                        back: {
                            cancelButtonText: languageContext.getMessage("cancel"),
                            confirmButtonText: languageContext.getMessage('save'),
                            description: languageContext.getMessage('noHseCardUploaded'),
                            title: languageContext.getMessage('doYouWantToProceed'),
                        }
                    }}
                />;
            case StepTitles.certificates:

                const getCertificateImagePlaceholder = (side: 'front' | 'back') => {
                    if (side === 'front') {
                        return getCertificatePlaceholderText(languageContext.getMessage('clickHere'), languageContext.getMessage('toUpload'), languageContext.getMessage('frontSide'), languageContext.getMessage('ofCertificate'))
                    }

                    if (side === 'back') {
                        return getCertificatePlaceholderText(languageContext.getMessage('clickHere'), languageContext.getMessage('toUpload'), languageContext.getMessage('backSide'), languageContext.getMessage('ofCertificate'))
                    }
                }

                return <CertificateListView
                    dialogTitle={languageContext.getMessage('certificate')}
                    confirmRemoveDialogTitle={languageContext.getMessage('doYouWantToDeleteCertificate')}
                    certificateType={CertificateType.GENERAL}
                    placeholder={{
                        front: getCertificateImagePlaceholder('front'),
                        back: getCertificateImagePlaceholder('back'),
                    }}
                    frontSideRequired
                    backSideRequired
                    confirmationDialogContent={{
                        front: {
                            cancelButtonText: languageContext.getMessage("cancel"),
                            confirmButtonText: languageContext.getMessage('next'),
                            description: languageContext.getMessage('noCertificateUploaded'),
                            title: languageContext.getMessage('doYouWantToProceed'),
                        },
                        back: {
                            cancelButtonText: languageContext.getMessage("cancel"),
                            confirmButtonText: languageContext.getMessage('save'),
                            description: languageContext.getMessage('noCertificateUploaded'),
                            title: languageContext.getMessage('doYouWantToProceed'),
                        }
                    }}
                />;
        }
    }

    const isNextButtonDisabled = (step: InfoStep) => {
        switch (step.title) {
            case StepTitles.company:
                return !!companyOrganizationNumberError;

            case StepTitles.myInfo:
                if (project && editedApplicationProfile) {

                    if (!(editedApplicationProfile.email ?? '').includes('@') || (editedApplicationProfile.name ?? '').trim().length === 0)
                        return true

                    if (project.hseCardNumberFieldIsMandatoryOnApplicationProfile && !editedApplicationProfile.hseCardNumberVerified)
                        return true

                    if (project.phoneNumberFieldIsMandatoryOnApplicationProfile && !editedApplicationProfile.phoneNumber)
                        return true

                    if (project.relativesFieldIsMandatoryOnApplicationProfile && editedApplicationProfile.relatives?.every(relative => !relative.name?.length || !relative.phoneNumber?.length))
                        return true
                }

                return false

            default:
                return false
        }
    }
    const BottomNavigation = (
        <>
            <Button variant='contained' onClick={onBack}>{languageContext.getMessage('back')}</Button>
            <Button variant='contained' disabled={isNextButtonDisabled(STEPS[activeStep])} onClick={async () => await onNext()}>{languageContext.getMessage('next')}</Button>
        </>
    )

    return (
        <PageContentLayout bottomNavigation={BottomNavigation}>
            <>
                <ConfirmationDialog
                    open={isHseCardEmptyDialog}
                    title={languageContext.getMessage('hseCardNotAdded')}
                    description={project?.hseCertificatePictureUploadIsMandatory ? languageContext.getMessage('mandatoryHseCardNotAddedDescription') : languageContext.getMessage('hseCardNotAddedDescription')}
                    onClose={async () => setIsHseCardEmptyDialog(false)}
                    onConfirm={project?.hseCertificatePictureUploadIsMandatory ? undefined : onCertificateDialogConfirm}
                    cancelButtonText={project?.hseCertificatePictureUploadIsMandatory ? languageContext.getMessage("confirm") : undefined}
                />
                <ConfirmationDialog
                    open={isCertificateEmptyDialog}
                    title={languageContext.getMessage('certificateNotAdded')}
                    description={project?.generalCertificatePictureUploadIsMandatory ? languageContext.getMessage('mandatoryCertificateNotAddedDescription') : languageContext.getMessage('certificateNotAddedDescription')}
                    onClose={async () => setIsCertificateEmptyDialog(false)}
                    onConfirm={project?.generalCertificatePictureUploadIsMandatory ? undefined : onCertificateDialogConfirm}
                    cancelButtonText={project?.generalCertificatePictureUploadIsMandatory ? languageContext.getMessage("confirm") : undefined}
                />
                <Typography variant="h5" style={{ marginTop: 10, marginBottom: 20 }}>
                    {languageContext.getMessage('registerInformation')}
                </Typography>
                <RegistrationStepper activeStep={activeStep} alternativeLabel sx={{ marginBottom: 2.5 }}>
                    {STEPS.map(step => {
                        return (
                            <Step key={step.title}>
                                <StepLabel>{languageContext.getMessage(step.languageKey) ?? step.title}</StepLabel>
                            </Step>
                        )
                    })}
                </RegistrationStepper>

                {getCurrentStepComponent(STEPS[activeStep])}
            </>
        </PageContentLayout>
    )
}

export default JoinRegisterInformationView

const RegistrationStepper = styled(Stepper)(({ theme }) => ({
    [`.${stepClasses.root}`]: {
        padding: '0px'
    }
}))