import { MemberAccess } from "../../../contracts/contracts";

export const noMap = {
    ok: 'OK',
    in: 'i',
    about: 'Om',
    account: 'Konto',
    accountType: 'Kontotype',
    accountBudget: 'Kontobudsjett',
    accountDetails: 'Kontodetaljer',
    accountGroup: 'Kontogruppe',
    accountInvoicedAmount: 'Fakturert: Konto',
    accountList: 'Kontoliste',
    accountNumber: 'Kontonummer',
    accountProcurement: 'Kontoinnkjøp',
    accounts: 'Kontoer',
    accountTransactionList: 'Transaksjoner',
    action: 'Handling',
    accessControl: 'Tilgangsstyring',
    ACTIVE: 'Aktiv',
    publish: 'Publiser',
    publishToMembers: 'Publiser til medlemmer',
    addAccount: 'Legg til konto',
    addInvoice: 'Legg til faktura',
    addContract: 'Legg til kontrakt',
    adjustment: 'Justering',
    adjustments: 'Justeringer',
    accumulated: 'Akkumulerte',
    all: 'Alle',
    amount: 'Beløp',
    assetFreeDays: 'Ledige Dager',
    assetCondition: 'Tilstandsgrad',
    assetQuantity: 'Antall',
    averageProjectCost: 'Gjennomsnittlig prosjekt kost',
    quantity: 'Antall',
    qunatityShort: 'Antall',
    analytics: 'Oversikt',
    analysis: 'Analyse',
    analyticsView: 'Dette er Oversikt',
    and: 'og',
    expectedDate: 'Forventet Dato',
    answeredDate: 'Svar Dato',
    approved_plural: 'Godkjente',
    approved: 'Godkjent',
    approvedCost: 'Godkjent kostnad',
    ASSISTANT_PROJECT_MANAGER: 'Prosjektlederassistent',
    accrual: 'Periodisering',
    asset: 'Eiendel',
    assetType: 'Eiendelstype',
    assets: 'Eiendeler',
    assetDetails: 'Eiendelsdetaljer',
    activation: 'Aktivering',
    accumulatedAccruedPosted: 'Akkumulert Påløpt bokført',
    receivedRegistered: 'Mottaksregistrert',
    revisedForecastIsAboveAccountForecast: 'Revidert prognose er over konto prognosen',
    revisedForecastIsAboveConstructionAdministrationCosts: 'Revidert prognose er over byggeadministrasjonskostnader',
    producedNotBilled: 'Produsert - ikke fakturert',
    accumulatedProduced: 'Akkumulert produsert verdi',
    constructionCosts: 'Rigg, byggekostnader og prosjektering',
    constructionStart: 'Byggestart',
    administrationCosts: 'Administrasjonskostnader',
    producedPercentageOfForecast: 'Produsert % av prognose',
    sumProducedValue: 'Sum produsert verdi',
    surcharge: 'Påslag',
    balance: 'Disponibelt',
    billed: 'Fakturert',
    budget: 'Budsjett',
    burndown: 'Nedjusteringer',
    cancel: 'Avbryt',
    change: 'Endre',
    changeOrder: 'Endringsordre',
    changeOrderBilled: 'Kostnad',
    changeOrderBudget: 'Endringsordrebudsjett',
    changeOrderProcurement: 'Endringsordreinnkjøp',
    changeOrderShort: 'EM',
    changeOrders: 'Endringsordre',
    quantityChangeOrders: 'Antall Endringsordre',
    accrualAndOperations: 'Periodisering',
    categories: 'Kategorier',
    changes: 'Endringer',
    clear: 'Tøm',
    close: 'Lukk',
    position: 'Posisjon',
    columnActorCount: 'Aktører',
    columnName: 'Navn',
    columnOwnerCount: 'Eiere',
    columnStatus: 'Status',
    condition: 'Tilstand',
    comment: 'Kommentar',
    comments: 'Kommentarer',
    completion: 'Ferdigstillelse',
    company: 'OnSite AS',
    companyRaw: 'Selskap',
    companiesRaw: 'Selskaper',
    newCompany: 'Nytt selskap',
    editCompany: 'Rediger selskap',
    noCompany: 'Ingen selskap',
    companySite: 'https://onsite.no/',
    workingFor: 'Jobber for',
    employer: 'Arbeidsgiver',
    organizationNumber: 'Organisasjonsnummer',
    followingCompany: 'Følger selskap',
    followCompany: 'Følg selskap',
    joinCompany: 'Bli med i selskap',
    companyDetails: 'Selskapsdetaljer',
    consequence: 'Konsekvens',
    booking: 'Bestilling',
    bookingType: 'Bestillingstype',
    bookingDetails: 'Bestillingsdetaljer',
    bookings: 'Bestillinger',
    contract: 'Kontrakt',
    contractDetails: 'Kontrakt Detaljer',
    contractBudget: 'Kontraksbudsjett',
    contractor: 'Entreprenør',
    contractOrChange: 'Kontrakt/Endring',
    contractProcurement: 'Kontraktsinnkjøp',
    contracts: 'Kontrakter',
    contractCosts: 'Kontraktskostnader',
    constructionAdministrationBudget: 'Budsjett for byggeadministrasjon',
    cost: 'Kostnad',
    costs: 'Kostnader',
    costRequirements: 'Kostnadskrav',
    created: 'Opprettet',
    crew: 'Bemanning',
    crewList: 'Bemanningsplan',
    currentLanguage: 'Norsk',
    confirm: 'Bekreft',
    dark: 'Mørk',
    date: 'dato',
    days: 'Dager',
    dailyStorage: 'Daglig lagring',
    delete: 'Slett',
    deliveryDate: 'Leverings Dato',
    description: 'Beskrivelse',
    detailed: 'Detaljert',
    details: 'Detaljer',
    scannedDate: 'Skannet Dato',
    voucherDate: 'Bilagsdato',
    doYouWantToDeleteProject: 'Vil du slette prosjektet?',
    doYouWantToDeleteCompany: 'Vil du slette organisasjonen?',
    doYouWantToDeleteAsset: 'Vil du slette ressursen?',
    doYouWantToDeleteContainer: 'Vil du slette containeren?',
    doYouWantToDeleteContract: 'Vil du slette kontrakten?',
    doYouWantToDeleteProcurement: 'Vil du slette innkjøpet?',
    doYouWantToDeleteDisposal: 'Vil du slette avhendingen?',
    doYouWantToDeleteBooking: 'Vil du slette bestillingen?',
    doYouWantToDeleteElement: 'Vil du slette elementet?',
    doYouWantToSaveProject: 'Vil du lagre prosjektet?',
    doYouWantToSaveCompany: 'Vil du lagre organisasjonen?',
    doYouWantToSaveAsset: 'Vil du lagre ressursen?',
    doYouWantToSaveContainer: 'Vil du lagre containeren?',
    doYouWantToSaveContract: 'Vil du lagre kontrakten?',
    doYouWantToSaveProcurement: 'Vil du lagre innkjøpet?',
    doYouWantToSaveDisposal: 'Vil du lagre avhendingen?',
    doYouWantToSaveBooking: 'Vil du lagre bestillingen?',
    doYouWantToSaveElement: 'Vil du lagre elementet?',
    doYouWantToSaveSettings: 'Vil du lagre innstillingene?',
    dueDate: 'Forfall',
    interimAccountedDate: 'Kontert dato',
    interimAccountedAmount: 'Kontert beløp',
    energy: 'Energi',
    email: 'Epost',
    edit: 'Rediger',
    enableProgressConsequence: 'Aktiver fremdriftskonsekvens',
    externalName: 'Leverandør',
    expectation: 'Forventning',
    enabled: 'Aktivert',
    projects: 'Prosjekter',
    finished: 'Fullført',
    final: 'Endelig',
    finalForecast: 'Sluttprognose',
    forecastReport: 'Prognose Rapport',
    forecast: 'Prognose',
    forecasts: 'Prognoser',
    changesInForecastLastMonth: 'Endringer i sluttprognosen sist måned',
    from: 'Fra',
    fromDate: 'Fra dato',
    group: 'Gruppe',
    general: 'Generelt',
    global: 'Global',
    handling: 'Håndtering',
    hello: 'Hello',
    home: 'Hjem',
    homeView: 'Dette er Hjem',
    HSE_MANAGER: 'HMS ansvarlig',
    id: 'Id',
    INACTIVE: 'Inaktiv',
    interim: 'Interim',
    incoming: 'Innkommende',
    includeContractProcurementInForecast: "Bruk kontraktsinnkjøp i prognose?",
    warningContractProcurementIsLargerThenBudget: "Kontraktsinnkjøpet er større enn kontraktsbudsjettet, og vil derfor bli brukt i prognosen.",
    invoice: 'Faktura',
    invoiceComplete: 'Behandlet',
    invoicedAmount: 'Fakturert beløp',
    invoicedAmountIsGreaterThanProcurment: 'Fakturert beløp er større en innkjøp',
    invoiceIsPartOfAnotherInvoice: 'Fakturaen er en del av en annen faktura',
    invoiceIsSplitIntoMultipleInvoicesAndCanNotBeEdited: 'Fakturaen er delt opp i flere faktura og kan ikke bli redigert',
    invoiceMissingComment: 'Ubehandlet',
    invoiceNumber: 'Fakturanummer',
    invoices: 'Fakturaer',
    importAccounts: 'Importer kontoer',
    inTotal: 'Totalt',
    info: 'Info',
    ITB_MANAGER: 'ITB-ansvarlig',
    landlord: 'Byggherre',
    landlordShort: 'BH',
    language: 'Språk',
    languages: 'Språk',
    mainLanguage: 'Hovedspråk',
    last: 'Siste',
    light: 'Lys',
    list: 'Liste',
    listHeading: 'Eiendomsliste',
    listView: 'Dette er Liste',
    login: 'Logg inn',
    loginWith: 'Logg inn med',
    loginWithEmail: 'Logg inn med epost',
    logout: 'Logg ut',
    management: 'Ledelse',
    max: 'Max',
    missingComment: 'Mangler kommentar',
    month: 'Måned',
    monthly: 'Månedlig',
    monthlySalary: 'Månedlige Lønnskostnader',
    monthlyPercentage: 'Månedlig Prosentandel',
    aMonth: 'Måned',
    name: 'Navn',
    netAccountBudget: 'Netto Kontobudsjett',
    networkError: 'Nettverksfeil: Vi har problemer med å koble til server',
    projectType: 'Prosjekttype',
    project: 'Prosjekt',
    projectDetails: 'Prosjektdetaljer',
    projectDepot: 'Depot',
    projectDepotDetails: 'Depot detaljer',
    projectCustomerLocation: 'Kundelokasjon',
    projectCustomerLocationDetails: 'Kundelokasjonsdetaljer',
    new: 'Ny',
    newProject: 'Nytt Prosjekt',
    newProjectDepot: 'Nytt Depot',
    newProjectCustomerLocation: 'Ny Kundelokasjon',
    newAsset: 'Ny Ressurs',
    newContract: 'Ny Kontrakt',
    newProcurement: 'Nytt Innkjøp',
    newDisposal: 'Ny Avhending',
    newBooking: 'Ny Bestilling',
    editProject: 'Rediger Prosjekt',
    editProjectDepot: 'Rediger Depot',
    editProjectCustomerLocation: 'Rediger Kundelokasjon',
    editAsset: 'Rediger Ressurs',
    editContract: 'Rediger Kontract',
    editProcurement: 'Rediger Innkjøp',
    editDisposal: 'Rediger Avhending',
    editBooking: 'Rediger Bestilling',
    none: 'Ingen',
    showNonInterimAccountedInvoices: "Det finnes fakturaer som ikke er kontert!",
    showInterimAccountedInvoices: "Vis fakturaer som er kontert",
    notApproved: 'Avvist',
    notProcessed: 'Uavklart',
    notSelected: 'Ikke valgt',
    notSpecified: 'Ikke spesifisert',
    selectProject: 'Velg prosjekt',
    selectTenant: 'Tast inn din organisasjon',
    selectLanguage: 'Velg språk',
    wrongTenant: 'Organisasjon eksisterer ikke!',
    reader: 'Leser',
    writer: 'Skriver',
    admin: 'Admin',
    owner: 'Eier',
    normal: 'Normal',
    OPPORTUNITY: 'Mulighet',
    offHire: 'Av hyre',
    opportunityAdjustments: 'Mulighetsjusteringer',
    outgoing: 'Utgående',
    overview: 'Oversikt',
    payments: 'Betalinger',
    pickupDate: 'Hentedato',
    probability: 'Sannsynlighet',
    PROBABILITY: 'Sannsynlighet',
    procurement: 'Innkjøp',
    procurements: 'Innkjøp',
    productName: 'OnSite',
    profile: 'Min profil',
    projectInformation: 'Prosjektinformasjon',
    projectList: 'Prosjektliste',
    projectStart: 'Prosjektstart',
    projectEnd: 'Prosjektslutt',
    projectName: 'Prosjektnavn',
    projectNumber: 'Prosjektnummer',
    projectCode: 'Prosjektkode',
    projectCodes: 'Prosjektkoder',
    accountCode: 'Kontokode',
    accountCodes: 'Kontokoder',
    filename: 'Filnavn',
    property: 'Eiendom',
    progressConsequence: 'Fremdriftskonsekvens',
    per: 'per',
    percentage: 'Prosent',
    percentShare: 'Prosentandel',
    receivedDate: 'Mottatt Dato',
    sentDate: 'Sendt Dato',
    remainingAccountBudget: 'Rest. kontobudsjett',
    remainingAmount: 'Gjenstående beløp',
    remainingCost: 'Ufakturert beløp',
    remainingContractBudget: 'Rest. Kontraksbudsjett',
    remainingContractProcurement: 'Rest. kontraktsinnkjøp',
    remainingProcurement: 'Rest. innkjøp',
    repairCost: 'Reperasjons Kost',
    reset: 'Nullstill',
    revised: 'Revidert',
    revisedForecast: 'Revidert prognose',
    revisedAccountBudget: 'Revidert kontobudsjett',
    RISK: 'Risiko',
    riskAdjustments: 'Risikojusteringer',
    role: 'Rolle',
    salary: 'Lønn',
    save: 'Lagre',
    saveInvoice: 'Lagre faktura',
    settings: 'Innstillinger',
    signedInAs: 'Pålogget som:',
    signIn: 'Logg inn',
    signOut: 'Logg ut',
    security: 'Sikkerhet',
    myAccount: 'Konto',
    SITE_MANAGER: 'Prosjektleder',
    splitInvoice: 'Del faktura',
    starting: 'fra',
    state: 'Status',
    level: 'Ledd',
    mainContractor: 'Hovedentreprenør',
    mainContractorShort: 'Hovedentreprenør',
    mainContractorCosts: 'Hovedentreprenørkostnader',
    subContractor: 'Underentreprenør',
    subContractorShort: 'UE',
    subContractorCosts: 'Underentreprenørkostnader',
    subProjects: 'Underprosjekter',
    sum: 'Sum',
    sumChangeOrderCost: 'Kostnad: endringsordre',
    sumChangeOrderInvoicedAmount: 'Fakturert: endringsordre',
    sumContractBudget: 'Kontraktsbudsjett',
    sumContractProcurement: 'Kontraktsinnkjøp',
    sumContractInvoicedAmount: 'Fakturert: kontrakt',
    sumChangeOrderRemainingCost: 'Ufakturert: endringsordre',
    sumRemainingContractBudget: 'Rest. kontraktsbudsjett',
    contractCostScope: 'Kontraktsomfang',
    contractType: 'Kontraktstype',
    sumExpenseForecasts: 'Sum utgiftsposter',
    sumContractCostScope: 'Sum Kontraktsomfang',
    IncurredCosts: 'Påløpte kostnader',
    supplier: 'Leverandør',
    simulating: 'Simulerer',
    totalSurcharge: 'Totalt Påslag',
    theme: 'Tema',
    this: 'Denne',
    time: 'Tid',
    title: 'Tittel',
    to: 'Til',
    toDate: 'Til dato',
    today: 'I dag',
    toggle: 'Toggle',
    totalCosts: 'Totalkostnader',
    transactions: 'Transaksjoner',
    type: 'Type',
    uploadImage: 'Last opp bilde',
    uploadContract: 'Last opp kontrakt',
    downloadImage: 'Last ned bilde',
    downloadContract: 'Last ned kontrakt',
    uncertainty: 'Usikkerhet',
    certificate: 'Sertifikat',
    certificateInputHelperText: 'Skriv tittel på sertifikat, eller velg fra nedtrekksmenyen.',
    certificateListInputHelperText: 'Klikk på knappen nedenfor for å legge til sertifikater eller kompetansebevis.',
    certificateListInputHelperTextHseCard: 'Klikk på knappen for å legge til HMS-kort',
    unkown: 'Ukjent',
    unprocessed: 'Ubehandlet',
    unforeseen: 'Uforutsett',
    selectEmployerCompanyHelperText: 'Begynn og skriv for å finne selskapet du jobber for.',
    selectWorkingCompanyHelperText: 'Begynn og skriv for å finne selskapet din arbeidsgiver jobber for.',
    yourEmployerWorksOnBehalfOf: 'Din arbeidsgiver utfører arbeid på vegne av',
    adjustmentsRisksAndUnforeseen: 'Uforutsett og Justeringer',
    up: 'Opp',
    expenseItem: 'Utgiftspost',
    remainsToDistribute: 'Gjenstår å fordele',
    turntime: 'Turntime',
    triangulation: 'Triangulasjon',
    newExpectedChange: 'Nytt forventet tillegg',
    expectedChange: 'Forventet tillegg',
    expectedPostedExpense: 'Forventet kostnad',
    expectedAccumulatedPostedExpense: 'Forventet akkumulert kostnad',
    actuallyPostedExpense: 'Faktisk påløpt',
    actuallyAccumulatedPostedExpense: 'Faktisk akkumulert påløpt',
    variance: 'Varians',
    usedWithForecast: 'Med i prognose',
    useRemainingAmount: 'Bruk gjenstående beløp',
    username: 'Brukernavn',
    value: 'Verdi',
    week: 'Uken',
    with: 'med',
    yes: 'Ja',
    yesterday: 'I går',
    next: 'Neste',
    back: 'Tilbake',
    region: 'Region',
    country: 'Land',
    city: 'By',
    postcode: 'Postnummer',
    area: 'Område',
    location: 'Lokasjon',
    address: 'Adresse',
    street: 'Gate',
    contact: 'Kontakt',
    capabilities: 'Muligheter',
    code: 'Kode',
    disposal: 'Avhending',
    disposals: 'Avhendinger',
    dropOffCharges: 'Avleveringsgebyrer',
    lessorDetails: 'Utleier Detaljer',
    contractReference: 'Kontraktsreferanse',
    reference: 'Referanse',
    quota: 'Kvote',
    onHireQuota: 'Innleiekvote',
    offHireQuota: 'Avleveringskvote',
    prefixSeries: 'Prefiks-serier',
    discountedResidual: 'Rabatterte rester',
    isoCode: 'Iso Kode',
    specifications: 'Spesifikasjoner',
    onHireReference: 'Leiereferanse',
    perDiemRate: 'Per Diem Rate',
    damageProtectionPlan: 'Skadesikringsplan',
    containerNumber: 'Containernummer',
    containerNumbers: 'Containernummere',
    containerFlow: 'Container Flyt',
    containerBookingFlow: 'Container Bestillingsflyt',
    uploadContainerNumbers: 'Last opp containernummere',
    downloadContainerNumbers: 'Last ned containernummere',
    fleetComposition: 'Flåte sammensetning',
    acceptance: 'Akseptanse',
    csc: 'CSC',
    swap: 'SWAP',
    bookingNumber: 'Bestillingsnummer',
    origin: 'Origin',
    destination: 'Destinasjon',
    customer: 'Kunde',
    emptyMovement: 'Tom Bevegelse',
    maintenanceAndRepair: 'Vedlikehold og reparasjon',
    container: 'Kontainer',
    assign: 'Tildel',
    release: 'Frigjør',
    complete: 'Fullfør',
    cleanse: 'Rens',
    issueProcurement: 'Tildel Innkjøp',
    issueDisposal: 'Tildel Avhending',
    QUANTITIES: 'Mengder',
    PROJECT_CHIEF: 'Prosjektsjef',
    PROJECT_MANAGER: 'Prosjektleder',
    PROJECT_MANAGER_ASSISTANT: 'Prosjektlederassistent',
    CONSTRUCTION_MANAGER: 'Prosjektleder',
    OPERATIONS_MANAGER: 'Driftsleder',
    PROJECT_SECRETARY: 'Prosjektsekretær',
    ENVIRONMENTAL_MANAGER: 'Miljøleder',
    HEALTH_SAFETY_AND_ENVIRONMENT_LEADER: 'HMS-leder',
    ENGINEERING_MANAGER: 'Prosjekteringsleder',
    TECHNICAL_ENGINEERING_MANAGER: 'Teknisk Prosjekteringsleder',
    INTEGRATED_TECHNICAL_BUILDING_INSTALLATIONS_RESPONSIBLE: 'ITB-ansvarlig',
    CONTROLLER: 'Controller',
    PURCHASER: 'Innkjøper',
    OTHER: 'Annet',
    NEW_BOOKING:'Ny Bestilling',
    ASSIGN_ASSETS:'Tildel Containere',
    ASSETS_ASSIGNED:'Containere Tildelt',
    NO_ASSETS_ASSIGNED: 'Ingen Containere Tildelt',
    ASSETS_PARTLY_ASSIGNED:'Containere Delvis Tildelt',
    GATE_OUT:'Gate Out',
    BOOKING_COMPLETE:'Bestilling Ferdigstilt',
    bookingSource: 'Bestilling Kilde',
    bookingTimeline: 'Bestilling Tidslinje',
    maxGrossWeight: 'Max Vekt',
    assetStatus: 'Container Status',
    ON_ASSIGNMENT: 'I bruk',
    IN_DEPOT: 'I Depot',
    MAINTENANCE_AND_REPAIR:'Reperasjon',
    availableDate: 'Neste tilgjengelig',
    currentLocation: 'Nåværende Plassering',
    recentBookings: 'Nylige Bestillinger',
    containerStatus: 'Container Status',
    DEPOT: 'Depot',
    TERMINAL: 'Terminal',
    SHIPPERS_POOL: 'Shippers Pool',
    TRIANGULATION: 'Triangulering',
    CUSTOMER_LOCATION: 'Kunde Lokasjon',
    containers: 'Containere',
    containerDetails: 'Container Detaljer',
    assignedContainers: 'Tildelte Containere',
    newContainer: 'Ny Container',
    editContainer: 'Rediger Container',
    newQuota: 'Ny Kvote',
    PAPER_GRADE: 'Paper Grade',
    CARGO_WORTHY: 'Cargo Worthy',
    FOOD_GRADE: 'Food Grade',
    containerForecast: 'Container Prognose',
    connectedProjects: 'Tilknyttede Prosjekt',
    coordinates: 'Kordinater',
    latitude: 'Breddegrad',
    longitude: 'Lengdegrad',
    ASSIGNED: 'Tildelt',
    UNCHECKED: 'Ikke Sjekket',
    IN_TRANSIT_FULL: 'Transit Full',
    IN_TRANSIT_EMPTY: 'Transit Tom',
    IN_REPAIR: 'Reperasjon',
    READY_FOR_CARGO: 'Klar for Lasting',
    currency: 'Valuta',
    exchangeRate: 'Vekslingsrate',
    mainCurrency: 'Hovedvaluta',
    lifetimeCostGeneration: 'Livssyklus Kostnad',
    lifetimeValueGeneration: 'Livssyklus Verdi',
    myInfo: 'Min Info',
    myProjects: 'Mine Prosjekter',
    allProjects: 'Alle Prosjekter',
    certificates: 'Sertifikater',
    diplomas: 'Diplomer',
    phoneNumber: 'Telefonnummer',
    relativePhoneNumber: 'Pårørendes Telefonnummer',
    relativeName: 'Pårørendes Navn',
    managerPhoneNumber: 'Leders Telefonnummer',
    managerName: 'Leders Navn',
    welcomeProject: 'Velkommen til',
    registerInformation: 'Registrer informasjon',
    safetyInstructions: 'Sikkerhetsinstruksjoner',
    safetyAgreement: 'Sikkerhetsavtale',
    safetyVideo: 'Sikkerhetsvideo',
    videoUrl: 'Video Url',
    theVideoMustBeHostedOn: 'Videoen må hostes fra ',
    newSafetyInformation: 'Ny sikkerhetsinformasjon',
    watchSafetyInformations: 'Se informasjon',
    informationHasBeenSavedInfo: 'Informasjonen din er lagret. Neste steg er å se nødvendig sikkerhetsinformasjon for å bli medlem av prosjektet.',
    informationHasBeenSeenInfoPart: 'Gratulerer! Du har nå vært igjennom nødvendig sikkerhetsinformasjon for å bli medlem av prosjektet.',
    informationHasBeenSeenInfoPartWithQuiz: 'Gratulerer! Du fikk {0} av {1} spørsmål riktige, og har nå vært igjennom nødvendig sikkerhetsinformasjon for å bli medlem av prosjektet.',
    contactAdministrationToRegisterHmsCard: 'Henvend deg til byggeplassadministrasjonen for å registrere HMS-kortet ditt.\n\n Vi sees!',
    contactAdministrationForMoreInformation: 'Kontakt prosjektadministrasjonen for nærmere informasjon.',
    youHadXOutOfQuestionsCorrect: 'Du fikk {0} av {1} spørsmål riktige{2}',
    informationApproval: 'Godkjenning',
    toTheProject: 'Til prosjektet',
    register: 'Registrer',
    search: 'Søk',
    signedInAsProjectMember: 'Du har logget på som prosjektmedlem.',
    goThroughSteps: 'Hei! \n\nFør du blir medlem av {0} må du registrere informasjon om deg selv, samt gjennomgå nødvendig sikkerhetsinstruksjon for prosjektet.',
    goThroughStepsWithQuizInfo: 'Helse, miljø og sikkerhet er vår høyeste prioritet! Derfor må alle nye prosjektmedlemmer bestå en quiz basert på sikkerhetsinstruksjonen. Ta deg god tid og følg nøye med på informasjonen som blir forelagt deg. Du må ha {0} av {1} riktige svar for å bestå Quizen. \n\nLykke til!',
    hseCardNumberNotFound: 'HMS-kort nummer ikke funnet',
    hseCard: 'HMS-kort',
    hseCardNumber: 'HMS-kort nummer',
    hseCardNotAdded: 'HMS-kort ikke lagt til',
    hseCardNotAddedDescription: "Du har ikke lastet opp noe HMS-kort. Har du fått en bekreftelse på at HMS-kortet er bestilt, trykk avbryt og last opp bilde av bekreftelsen. Jeg bekrefter herved at jeg vil informere prosjektledelsen om at jeg ikke har noe HMS-kort.",
    mandatoryHseCardNotAddedDescription: "Det er obligatorisk å laste opp HMS-kortet. Har du fått en bekreftelse på at HMS-kortet er bestilt, trykk konfirmer og last opp bilde av bekreftelsen.",
    certificateNotAdded: 'Sertifikat ikke lagt til',
    certificateNotAddedDescription: 'Du har ikke lastet opp noen sertifikater. Jeg bekrefter herved at jeg vil utføre arbeid som ikke krever noen form for sertifikater eller kompetansebevis.',
    mandatoryCertificateNotAddedDescription: 'Det er obligatorisk å laste opp dine sertifikater.',
    uploadPictureOfFrontHseCard: "Upload picture of the HSE card's front side.",
    uploadPictureOfBackHseCard: "Upload picture of the HSE card's back side.",
    uploadPictureOfFrontAndBackHseCard: 'Last opp bilde av HMS-kortets framside og bakside.',
    uploadPictureOfFrontCertificateCard: 'Last opp bilde av sertifikatets framside.',
    uploadPictureOfBackCertificateCard: 'Last opp bilde av sertifikatets bakside.',
    uploadPictureOfFrontAndBackCertificateCard: 'Last opp bilde av sertifikatets framside og bakside.',
    joinProject: 'Bli medlem',
    followingProject: 'Følger',
    followProject: 'Følg',
    unfollowProject: 'Avfølg',
    newsFeed: 'Nyhetsfeed',
    feed: 'Feed',
    message: 'Beskjed',
    messages: 'Meldinger',
    safetyInformation: 'Sikkerhetsinformasjon',
    safetyInformationInMenu: 'Sikkerhets informasjon',
    member: 'Medlem',
    membersRaw: 'Medlemmer',
    membersFound: 'medlemmer funnet',
    pendingApproval: 'Avventer godkjenning',
    [MemberAccess.REQUESTING_MEMBER_ACCESS]: 'Ønsker tilgang',
    [MemberAccess.PUBLIC]: 'Offentlig',
    [MemberAccess.MEMBER]: 'Medlem',
    approve: 'Aksepter',
    deny: 'Avslå',
    denied: 'Avslått',
    newNewsFeed: 'ny melding',
    editNewsFeed: 'redigere melding',
    doYouWantToDeleteNewsFeed: 'Vil du slette nyhetsfeed?',
    doYouWantToSaveNewsFeed: "Vil du lagre nyhetsfeed",
    doYouWantToDeleteProjectMember: "Vil du avslutte prosjektmedlemskapet?",
    doYouWantToDeleteSafetyInformation: 'Vil du slette sikkerhetsinformasjonen?',
    doYouWantToDeleteHseCard: 'Vil du slette HMS kortet?',
    doYouWantToDeleteCertificate: 'Vil du slette sertifikatet?',
    add: "Legg til",
    emptyInformationVerifications: "Du har ingen diplom ennå!",
    newsFeedAccessLevel: 'Tilgangsnivå',
    newsFeedAccessLevelPublic: 'Offentlig',
    newsFeedAccessLevelPrivate: 'Privat',
    inactive: 'Inaktiv',
    preview: 'Forhåndsvisning',
    hitEnter: 'Trykk Enter',
    show: 'Vis',
    hide: 'Skjul',
    myself: 'Meg',
    notification: 'Notifisering',
    noSafetyInformationAvailable: 'Det er ingen sikkerhetsinformasjon tilgjengelig forr dette prosjektet ennå. Kom tilbake senere eller kontakt administrator for hjelp.',
    markdownSupported: 'Markdown støttes!',
    accessDenied: 'Nektet tilgang',
    videoFailedPlayingWithErrorMessage: 'Avspilling av video mislyktes med feilmelding',
    clickToUploadImage: 'Klikk her for å laste opp et bilde',
    addAttachment: 'Legg til vedlegg',
    hseCardMissingTooltip: 'HMS kort mangler',
    failedSafetyInformationQuizTooltip: 'Feilet sikkerhetsinformasjon',
    certificateMissingTooltip: 'Sertifikat mangler',
    manualUserTooltip: 'Denne brukeren er manuelt opprettet',
    addNewMember: 'Nytt medlem',
    companyFieldRequired: 'Selskapsfelt er obligatorisk',
    nameFieldRequired: 'Navnefelt er obligatorisk',
    fileSizeError: 'Filstørrelsen må være mindre enn 100 mb.',
    fileDeletedMessage: 'Filen ble slettet',
    cardScanSuccessMessage: 'Du har skannet HMS-kortet ditt',
    cameraNotAvailable: 'Kameratillatelse nektet eller ikke tilgjengelig',
    hseCardScanner: 'HMS-kortskanner',
    failedToScan: 'Kunne ikke skanne?',
    cardNumber: 'Kortnummer',
    cardIsActiveTooltip: 'HMS-kortet er aktivt',
    cardIsUnactiveTooltip: 'HMS-kortet er inaktivt',
    enableHseCardScanner: 'Aktiver HMS-kortskanner',
    addHseCardImageMessage: 'Klikk på knappen nedenfor for å legge til HMS-kort.',
    instructionReadAttentively: 'Vennligst les instruksjonene nøye.',
    hseCardNumberInputInstructionTitle: 'HMS-kortnummer',
    hseCardNumberInputInstructionExplanation1: 'Finn kortnummeret øverst til venstre på HMS-kortet.',
    hseCardScanningInstructionTitle: 'Skanningsinstruksjon av HMS-kort',
    scanningInstructionExplanation1: 'Ta et bilde av QR-koden på HMS-kortet ditt eller last opp et bilde av QR-koden din.',
    scanningInstructionExplanation2: 'Sørg for å KUN ta bilde av QR-koden. Alternativt kan du velge et bilde av QR-koden din fra ditt bilde galleri.',
    scanningInstructionExplanation3: 'Bildet av QR-koden må være i god kvalitet. Ikke uskarpt!',
    example: 'Eksempel',
    startScanningProcess: 'Start skanneprosessen',
    iHaveReadIntructions: 'Jeg har lest instruksjonene, og er klar til å laste opp QR-koden min.',
    advancedSettings: 'Avanserte innstillinger',
    phoneIsRequired: 'Telefonnummer-feltet er obligatorisk',
    relativesIsRequired: 'Pårørende-feltet er obligatorisk',
    hseIsRequired: 'Felt for HMS-kortnummer er obligatorisk',
    hsePictureIsRequired: 'Obligatorisk opplasting av HMS-kort',
    certificatePictureIsRequired: 'Obligatorisk opplasting av sertifikatet',
    enableParentCompany: 'Arbeidstakere gir informasjon om deres arbeidsgivers entreprenør.',
    noHseCardUploaded: 'Du har ikke lastet opp forsiden/baksiden av HMS kortet ditt! Er du sikker på at du vil fortsette?',
    noCertificateUploaded: 'Du har ikke lastet opp forsiden/baksiden av sertifikatet ditt! Er du sikker på at du vil fortsette?',
    doYouWantToProceed: 'Vil du fortsette?',
    clickHere: 'Klikk her',
    registerHere: 'Registrer her',
    toUpload: 'for å laste opp',
    backSide: 'baksiden',
    frontSide: 'forsiden',
    ofHseCard: 'av HMS-kortet ditt',
    ofCertificate: 'av sertifikatet ditt',
    duplicateInformationApprovalWarning: 'Dupliserte godkjenninger støttes ikke.',
    newUser: 'Ny bruker',
    existingUser: 'Eksisterende bruker',
    downloadUserProfileInfo: 'Last ned profilinformasjon',
    memberInvitedMessage: 'Medlem ble invitert',
    inviteMember: 'Inviter medlem',
    emailFieldRequired: 'Epost er påkrevd',
    invite: 'Inviter',
    uploadedByProjectAdministrator: 'Lastet opp av prosjektadministrator.',
    unableToScanQrCode: "Kunne ikke skanne bildet med QR koden. Prøv å gi et bedre bilde av QR-koden, og sørg for at bildet er skarpt med QR-koden i fokus!",
    quiz: 'Quiz',
    quizSettings: 'Quiz innstillinger',
    deniedAcccessToProject: 'Du har blitt nektet tilgang til prosjektet.',
    projectMemberFailedTheSafetyInformationQuestions: 'Du fikk {0} av {1} svar riktige på Quizen, og du må ha {2} av {3} riktige svar for å bestå. Du har dessverre ikke bestått testen for å bli medlem av prosjektet.',
    youHaveXTriesLeft: 'Du har {0} forsøk igjen.',
    youHaveUsedAllOfYourQuizTries: ', og du har brukt opp alle forsøkene dine for å bestå quizen.',
    tryAgain: 'Prøv igjen',
    maxQuizTries: 'Maximalt antall prøvelser',
    minQuizPercentage: 'Minimal prosent riktige svar',
    infoTech: 'InfoTech',
    apiKey: 'API nøkkel',
    accessGroup: 'Tilgangsgruppe',
    registeredAccessGroup: 'Registrert tilgangsgruppe',
    autoApproveProjectMembers: 'Auto godkjenn prosjektmedlemmer',
    deleteAttachedFile: 'Slett vedlagt fil',
    areYouSureYouWantToDeleteFile: 'Er du sikker på at du vil slette {0} filen?',
    checkIn: 'Sjekk in',
    checkOut: 'Sjekk ut',
    checkedIn: 'Sjekket in',
    checkedOut: 'Sjekket ut',
    copiedToClipboard: 'Kopiert til utklippstavlen',
    enableCheckIn: 'Aktiver sjekk inn',
    showPresence: 'Vis tilstedeværelse',
    presence: 'Tilstedeværelse',
    invalid: 'Ugyldig',
    verifying: 'Verifiserer',
    notes: 'Notater',
    unknownCompany: 'Ukjent selskap',
    hseCardIsRevokedOrInvalid: 'HMS-kortet er utløpt eller ugyldig.',
};
