import {  ThemeProvider } from '@mui/material/styles';
import { Theme } from '@mui/material'
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import React, { createContext, useContext, useState } from "react";
import DarkTheme from './themes/DarkTheme';
import LightTheme from './themes/LightTheme';

export enum ThemeEnum {
    LIGHT = 'light',
    DARK = 'dark',
}

export interface ThemeContext {
    getThemeId: () => ThemeEnum,
    getTheme: () => Theme,
    getThemeIcon: () => JSX.Element,
    setThemeId: (newThemeId: ThemeEnum) => void,
    toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContext>(null as unknown as ThemeContext);

const getThemeByEnum = (themeId: ThemeEnum): Theme => {
    if (themeId === ThemeEnum.LIGHT) {
        return LightTheme;
    }
    else if (themeId === ThemeEnum.DARK) {
        return DarkTheme;
    }
    else {
        return LightTheme;
    }
}

const getThemeIconByEnum = (themeId: ThemeEnum): JSX.Element => {
    if (themeId === ThemeEnum.LIGHT) {
        return <Brightness4Icon />;
    }
    else if (themeId === ThemeEnum.DARK) {
        return <Brightness7Icon />;
    }
    else {
        return <Brightness4Icon />;
    }
}

export const ThemeContextProvider : React.FC<{}> = ({ children }) => {

    const localStorageThemeSettingId = 'settings.themeId';
    let defaultTheme = (localStorage.getItem(localStorageThemeSettingId) as ThemeEnum) ?? ThemeEnum.LIGHT;
    const [themeId, _setThemeId] = useState<ThemeEnum>(defaultTheme);

    const getThemeId = (): ThemeEnum => {
        return themeId;
    }

    const getTheme = (): Theme => {
        return getThemeByEnum(getThemeId());
    }

    const getThemeIcon = (): JSX.Element => {
        return getThemeIconByEnum(themeId);
    }

    const setThemeId = (newThemeId: ThemeEnum): void => {
        localStorage.setItem(localStorageThemeSettingId, newThemeId);
        _setThemeId(newThemeId);
    }

    const toggleTheme = (): void => {
        if (themeId === ThemeEnum.DARK) {
            setThemeId(ThemeEnum.LIGHT);
        }
        else if (themeId === ThemeEnum.LIGHT) {
            setThemeId(ThemeEnum.DARK);
        }
        else {
            setThemeId(ThemeEnum.LIGHT);
        }
    }

    const themeContext: ThemeContext = {
        getThemeId,
        getTheme,
        getThemeIcon,
        setThemeId,
        toggleTheme
    };

    const theme = getTheme();
    return (
        <ThemeContext.Provider value={themeContext}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
}

export const useThemeContext = (): ThemeContext => {
    return useContext(ThemeContext);
}
