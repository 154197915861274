import { Theme, useMediaQuery, useTheme } from "@mui/material";
import { createContext, useContext, useLayoutEffect, useState } from "react";

export interface WidthContext {
    isMobileScreen: () => boolean;
    width: number;
    height: number;
};

const WidthContext = createContext<WidthContext>(null as unknown as WidthContext);

export const WidthContextProvider: React.FC<{}> = ({ children }) => {

    const theme: Theme = useTheme();

    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const [width, height] = size;

    const isMobileScreen = (): boolean => {
        return width < theme.breakpoints.values.md;
    }

    const value = {
        isMobileScreen,
        width,
        height,
    };

    return (
        <WidthContext.Provider value={value}>
            {children}
        </WidthContext.Provider>
    );
};

export const useWidthContext = (): WidthContext => {
    return useContext(WidthContext);
}
