export const sumOfDigitsFromString = (str: string): number => {
    let sum = 0;
    for (var i = 0; i < str.length; i++) {
        if (/[0-9]/.test(str[i])) {
            sum += parseInt(str[i])
        }
    }
    return sum;
}

export const capitalizeString = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export const alphaNumericString = (value: string): string => {
    return value.replace(/[^a-z0-9]/gi, '');
}

export const stringNotEmpty = (value: string | undefined): boolean => {
    return (value?.trim().length ?? 0) > 0;
}

export const stringEmpty = (value: string | undefined): boolean => {
    return !stringNotEmpty(value);
}

export const getFirstCapitalizedLetter = (value: string | undefined) => {
    return value?.charAt(0).toUpperCase();
}

export const stringIsNumeric = (value: string | undefined): boolean => {
    return value ? /^\d+$/.test(value) : false;
}

export const stringFormat = (value: string, args: any[]): string => {
    return value.replace(/{([0-9]+)}/g, function (match, index) {
        return typeof args[index] == 'undefined' ? match : args[index];
    });
};

export const getLimitedString = (value: string, limit: number): string => {
    return value.length > limit ? value.substring(0, limit) + "..." : value;
}