import { Grid } from "@mui/material";
import React from "react";
import ApplicationMenuMobile from "./ApplicationMenuMobile";
import ApplicationMenuDesktop from "./ApplicationMenuDesktop";

const ApplicationDrawer: React.FC<{ className: string }> = (props) => {
	return (
		<Grid
			container
			direction={'row'}
			wrap="nowrap"
			className={props.className}
		>
			<ApplicationMenuMobile />
			<ApplicationMenuDesktop />
			<Grid
				component="main"
				item
				container
				direction="column"
				sx={{
					padding: '1em',
					paddingBottom: '3em',
					flexGrow: 1,
					flexShrink: 1,
					flexWrap: "nowrap",
					overflowY: "auto",
				}}>
				{props.children}
			</Grid>
		</Grid>
	);
}

export default ApplicationDrawer;
