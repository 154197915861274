import { useEffect, useState } from "react";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_COMPANY_INFO, getCompanyInfoGraphqlQueryOptions } from "../../contexts/generalIntegrations/queries/generalIntegrationsQueries";
import { CompanyInfo } from "../../contracts/contracts";
import { stringNotEmpty } from "../../utils/stringTools";
import { useCompanyInfoQueriesContext } from "../../contexts/generalIntegrations/queries/companyInfoQueriesContext";

type returnValue = [
    matchingCompanyInfo: CompanyInfo | undefined,
];

export function useCompanyInfoLoader(
    organizationNumber: string | undefined, 
    skipLoading?: boolean): returnValue {
    const [fetchedCompanyInfos, setFetchedCompanyInfos] = useState<CompanyInfo[]>([]);
    const [loadCompanyInfoQuery, queryCompanyInfoResponse] = useLazyQuery(QUERY_COMPANY_INFO);
    const companyInfoQueriesContext = useCompanyInfoQueriesContext();
    const matchingCompanyInfo = fetchedCompanyInfos.find(companyInfo => companyInfo.organizationNumber === organizationNumber);

    useEffect(() => {
        if (!skipLoading && stringNotEmpty(organizationNumber)) {
            const matchingCachedCompanyInfos = companyInfoQueriesContext.cachedCompanyInfos.filter(companyInfo => companyInfo.organizationNumber === organizationNumber);
            if (matchingCachedCompanyInfos.length > 0) {
                setFetchedCompanyInfos(matchingCachedCompanyInfos);
            }
            else if (!queryCompanyInfoResponse.loading && matchingCompanyInfo === undefined) {
                const queryOptions = getCompanyInfoGraphqlQueryOptions({ organizationNumber: organizationNumber });
                loadCompanyInfoQuery(queryOptions);
            }
        }
    }, [organizationNumber])

    useEffect(() => {
        if (queryCompanyInfoResponse.data && queryCompanyInfoResponse.data.companyInfos) {
            const newFetchedCompanyInfos: CompanyInfo[] = queryCompanyInfoResponse.data.companyInfos;
            setFetchedCompanyInfos(newFetchedCompanyInfos);
            companyInfoQueriesContext.appendCompanyInfosToCache(newFetchedCompanyInfos);
        }
    }, [queryCompanyInfoResponse.data]);

    return [matchingCompanyInfo];
}
