import { Certificate, CertificateType } from "../contracts/contracts";
import hse_card_placeholder_front from '../data/pictures/hse_card_placeholder_front.png'
import hse_card_placeholder_back from '../data/pictures/hse_card_placeholder_back.png'
import { LanguageContext } from "../contexts/language/interfaces";

export const getCertificateTitleOptions = (certificateType: CertificateType | undefined): string[] => {
    if (certificateType === CertificateType.HSE) {
        return [
            'HSE Card',
        ]
    }
    if (certificateType === CertificateType.GENERAL) {
        return [
            'Bolt pistol', 
            'Nail gun',
            'Angle grinder',
            'Pegging machine',
            'Cutting and cleaving saw',
            'Chainsaw',
            'Concrete cutting',
            'Hot works course',
            'Hooking course',
            'Welding course',
            'Lift training',
            'SHE course',
            'Licenced crane driver',
            'Licenced scaffold builder',
            'Licenced scaffolds inspector',
            'Asbestos removal licence',
            'Scaffolding user course',
        ]
    }
    
    return [];
}

export const getCertificatePlaceholderImage = (certificate: Certificate, 
                                               alternatives: 'Front' | 'Back', 
                                               showPlaceHolderImage: boolean = false): string => {
    if (certificate.certificateType === CertificateType.HSE) {
        if (alternatives.toLowerCase() === 'Front'.toLowerCase()) {
            return showPlaceHolderImage ? hse_card_placeholder_front : '';
        }
        if (alternatives.toLowerCase() === 'Back'.toLowerCase()) {
            return showPlaceHolderImage ? hse_card_placeholder_back : '';
        }
    }
    
    return '';
}

export const getCertificatePlaceholderText = (text1: string, text2: string, text3: string, text4: string) => {
    return (
        <>
                <span style={{ textTransform: 'uppercase', textDecoration: 'underline'}}>{text1}</span><br />
                {text2}<br />
                <span style={{ textTransform: 'uppercase'}}>{text3}</span><br />
                {text4}
            </>
    )

}

export const getCertificateHelperText = (certificateType: CertificateType, alternatives: 'Front' | 'Back' | 'FrontAndBack', languageContext: LanguageContext): string => {
    if (certificateType === CertificateType.HSE) {
        if (alternatives.toLowerCase() === 'Front'.toLowerCase()) {
            return languageContext.getMessage('uploadPictureOfFrontHseCard');
        }
        if (alternatives.toLowerCase() === 'Back'.toLowerCase()) {
            return languageContext.getMessage('uploadPictureOfBackHseCard');
        }
        if (alternatives.toLowerCase() === 'FrontAndBack'.toLowerCase()) {
            return languageContext.getMessage('uploadPictureOfFrontAndBackHseCard');
        }
    }
    
    if (alternatives.toLowerCase() === 'Front'.toLowerCase()) {
        return languageContext.getMessage('uploadPictureOfFrontCertificateCard');
    }
    if (alternatives.toLowerCase() === 'Back'.toLowerCase()) {
        return languageContext.getMessage('uploadPictureOfBackCertificateCard');
    }
    if (alternatives.toLowerCase() === 'FrontAndBack'.toLowerCase()) {
        return languageContext.getMessage('uploadPictureOfFrontAndBackCertificateCard');
    }
    
    return languageContext.getMessage('certificateInputHelperText');
}

export const getCertificateListHelperText = (certificateType: CertificateType | undefined, languageContext: LanguageContext, scannerEnabled?: boolean): string => {   
    if (certificateType === CertificateType.HSE) {
        return scannerEnabled ? languageContext.getMessage('certificateListInputHelperTextHseCard') : languageContext.getMessage('addHseCardImageMessage');
    }
    return languageContext.getMessage('certificateListInputHelperText');
}