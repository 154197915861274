import { Divider, List } from "@mui/material";
import React from "react";
import { useAuthContext } from "../../contexts/auth/authContext";
import { useMenuContext } from "../../contexts/menu/menuContext";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import ApplicationMenuItem from "./ApplicationMenuItem";

const ApplicationMenuList: React.FC = () => {
	const menuContext = useMenuContext();
	const authContext = useAuthContext();
	const projectMemberContext = useProjectMemberContext();

	return (
		<>
			<List dense>
				{menuContext.applicationRoutes.filter(applicationRoute => applicationRoute && !applicationRoute.hidden && !applicationRoute.hideFromNavigation && authContext.authorized(applicationRoute, projectMemberContext))
					.map((applicationRoute, index) => <ApplicationMenuItem key={applicationRoute.id} applicationRoute={applicationRoute} />)}
			</List>
			<Divider style={{ backgroundColor: '#B0BEC5' }}/>
		</>
	)
}

export default ApplicationMenuList;
