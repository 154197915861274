import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Warning } from "@mui/icons-material/";
import React from "react";
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import { useWidthContext } from "../../contexts/WidthContext";

const ApplicationMenuItem: React.FC<{ applicationRoute: ApplicationRoute }> = ({
		applicationRoute,
}) => {
	const isMobileScreen = useWidthContext().isMobileScreen();
	const menuContext = useMenuContext();

	const handleOnClickMenu = (id: ApplicationRouteId) => {
		const applicationRoute = menuContext.getApplicationRouteById(id);
		menuContext.setSelectedApplicationRoute(id, applicationRoute.params ?? null);
		if (isMobileScreen) {
			menuContext.setFullMenuVisible(!menuContext.fullMenuVisible);
		}
	}

	return (
		<ListItem
			button
			key={applicationRoute.id}
			selected={applicationRoute.id === menuContext.selectedApplicationRoute.id}
			onClick={() => handleOnClickMenu(applicationRoute.id)}
		>
			<ListItemIcon>{applicationRoute.icon ?? <Warning/>}</ListItemIcon>
			<ListItemText primary={applicationRoute.menuTitle}/>
		</ListItem>
	)
}

export default ApplicationMenuItem;
