import * as React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

type Props = {
    menuItems: React.ReactNode[],
    onClickItem: (menuItem: React.ReactNode, index: number) => void,
    buttonContent: React.ReactNode,
    buttonProps?: ButtonProps,
    menuProps?: MenuProps,
}

const BasicMenu: React.FC<Props> = ({
    menuItems,
    onClickItem,
    buttonContent,
    buttonProps,
    menuProps,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                onClick={handleClick}
                {...buttonProps}
            >
                {buttonContent}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                {...menuProps}
            >
                {menuItems.map((menuItem, index) => <MenuItem key={`menu-item_${index}`} onClick={() => {
                    if (onClickItem) {
                        onClickItem(menuItem, index);
                    }
                    handleClose();
                }}>{menuItem}</MenuItem>)}
            </Menu>
        </div>
    );
}

export default BasicMenu;