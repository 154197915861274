import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React from "react";
import { InfoTechProject, InfoTechSettings } from "../../contracts/contracts";
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Box, CircularProgress } from '@mui/material';
import { stringNotEmpty } from '../../utils/stringTools';
import { SxProps } from "@mui/system";
import { useQueryInfoTechProjects } from '../../hooks/useQueryInfoTechProjects';

type props = {
    infoTechApiKey: string | undefined;
    infoTechSettings: InfoTechSettings | undefined;
    onSelectedInfoTechSettingsChange: (newSelectedInfoTechSettings: InfoTechSettings | undefined) => void;
    label?: React.ReactNode;
    error?: boolean | undefined;
    textFieldProps?: TextFieldProps
    disabled?: boolean;
    helperText?: string;
    sx?: SxProps;
}

const SearchInfoTechProjects: React.FC<props> = ({
    infoTechApiKey,
    infoTechSettings,
    onSelectedInfoTechSettingsChange,
    label,
    error,
    textFieldProps,
    helperText,
    sx,
    disabled }) => {

    const languageContext = useLanguageContext();
    const [fetchedInfoTechProjects, loading] = useQueryInfoTechProjects(infoTechApiKey);

    const handleChange = (event: any, newSelectedInfoTechProject: InfoTechProject | null) => {
        onSelectedInfoTechSettingsChange({
            ...infoTechSettings,
            infoTechProjectId: newSelectedInfoTechProject?.projectId,
            infoTechProjectDescription: newSelectedInfoTechProject?.projectDescription,
        });
    }

    const getInfoTechSettingsRenderLabel = (infoTechProject: InfoTechProject): string => {
        let label = '';
        if (stringNotEmpty(infoTechProject.projectDescription)) {
            label = `${infoTechProject.projectDescription}`;
        }
        else if (stringNotEmpty(infoTechProject.projectId)) {
            label = `${infoTechProject.projectId}`;
        }
        return label;
    }

    return <Autocomplete
        sx={sx}
        disabled={disabled}
        options={fetchedInfoTechProjects}
        getOptionLabel={(infoTechProject) => getInfoTechSettingsRenderLabel(infoTechProject)}
        value={{
            projectId: infoTechSettings?.infoTechProjectId, 
            projectDescription: infoTechSettings?.infoTechProjectDescription,
        }}
        onChange={handleChange}
        renderOption={(props, infoTechProject) => (
            <Box component="li" {...props}>
                {loading ? <CircularProgress size={10} /> : getInfoTechSettingsRenderLabel(infoTechProject)}
            </Box>
        )}
        renderInput={(params) => <TextField {...params}
            error={error}
            label={label ?? languageContext.getMessage('project')}
            helperText={helperText ?? ''}
            variant="standard"
            {...textFieldProps} />}
    />
}

export default SearchInfoTechProjects;
