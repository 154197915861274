import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, Information } from "../../../contracts/contracts";
import { cleanObject } from "../../../utils/objectTools";

export const MUTATE_INFORMATION: DocumentNode = gql(`
    mutation mutateInformation(
        $id: UUID
        $state: ActiveState
        $projectId: UUID!
        $informationId: UUID
        $informationAccess: InformationAccess
        $orderPosition: Int
        $title: String
        $information: String
        $videoUrl: String
        $informationFilename: String
        $language: String
        $informationApprovalsQuizActivated: Boolean
        $informationApprovals: [InformationApprovalInput]
    ) {
        mutateInformation(
            id: $id
            state: $state
            projectId: $projectId
            informationId: $informationId
            informationAccess: $informationAccess
            orderPosition: $orderPosition
            title: $title
            information: $information
            videoUrl: $videoUrl
            informationFilename: $informationFilename
            language: $language
            informationApprovalsQuizActivated: $informationApprovalsQuizActivated
            informationApprovals: $informationApprovals
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`);

export const getInformationsGraphqlMutationOptions = (information: Partial<Information>): QueryHookOptions => {
    information.state = information.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (information.id !== undefined && information.id !== null) options.variables['id'] = information.id;
    if (information.state !== undefined && information.state !== null) options.variables['state'] = information.state;
    if (information.projectId !== undefined && information.projectId !== null) options.variables['projectId'] = information.projectId;
    if (information.informationId !== undefined && information.informationId !== null) options.variables['informationId'] = information.informationId;
    if (information.informationAccess !== undefined && information.informationAccess !== null) options.variables['informationAccess'] = information.informationAccess;
    if (information.orderPosition !== undefined && information.orderPosition !== null) options.variables['orderPosition'] = information.orderPosition;
    if (information.title !== undefined && information.title !== null) options.variables['title'] = information.title;
    if (information.information !== undefined && information.information !== null) options.variables['information'] = information.information;
    if (information.videoUrl !== undefined && information.videoUrl !== null) options.variables['videoUrl'] = information.videoUrl;
    if (information.informationFilename !== undefined && information.informationFilename !== null) options.variables['informationFilename'] = information.informationFilename;
    if (information.language !== undefined && information.language !== null) options.variables['language'] = information.language;
    if (information.informationApprovalsQuizActivated !== undefined && information.informationApprovalsQuizActivated !== null) options.variables['informationApprovalsQuizActivated'] = information.informationApprovalsQuizActivated;
    if (information.informationApprovals !== undefined && information.informationApprovals !== null) options.variables['informationApprovals'] = cleanObject(information.informationApprovals);
    return options;
}
