
import React, { useState } from "react";
import { Theme, Grid, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { makeStyles, withTheme } from '@mui/styles';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { ThemeEnum } from '../../contexts/theme/ThemeContext';
import { useThemeContext } from '../../contexts/theme/ThemeContext';
import { getLanguageAlternatives, getLanguageIconFlagWithTitle } from "../../utils/languageTools";
import { useApplicationProfileContext } from "../../contexts/applicationProfile/applicationProfileContext";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: '1em'
    },
    divider: {
        marginBottom: '1em'
    },
}));

export interface ThemeSettingsProps {
}

const GeneralSettings: React.FC<ThemeSettingsProps> = (props: ThemeSettingsProps) => {

    const classes = useStyles();
    const languageContext = useLanguageContext();
    const applicationProfileContext = useApplicationProfileContext();
    const themeContext = useThemeContext();
    const [openLanguageSelection, setOpenLanguageSelection] = useState<boolean>(false);

    return (<>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={2}>
                <FormControl variant="standard" fullWidth={true}>
                    <InputLabel>{languageContext.getMessage('language')}</InputLabel>
                    <Select
                        value={languageContext.getLanguage()}
                        variant="standard"
                        fullWidth={true}
                        readOnly={false}
                        renderValue={language => getLanguageIconFlagWithTitle(language)}
                        open={openLanguageSelection}
                        onClick={() => setOpenLanguageSelection(!openLanguageSelection)}
                    >
                        {getLanguageAlternatives(async language => {
                            await applicationProfileContext.setApplicationLanguage(language);
                            setOpenLanguageSelection(false);
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <FormControl variant="standard" fullWidth={true}>
                    <InputLabel>{languageContext.getMessage('theme')}</InputLabel>
                    <Select
                        value={themeContext.getThemeId()}
                        variant="standard"
                        onChange={(event) => {
                            if (event.target.value) {
                                let themeChoice = event.target.value as ThemeEnum;
                                themeContext.setThemeId(themeChoice)
                            }
                        }}
                        fullWidth={true}
                        readOnly={false}
                    >
                        <MenuItem value={ThemeEnum.LIGHT}>{languageContext.getMessage('light')}</MenuItem>
                        <MenuItem value={ThemeEnum.DARK}>{languageContext.getMessage('dark')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>

    </>);
}

export default withTheme(GeneralSettings)