import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, InformationVerification } from "../../../contracts/contracts";
import { cleanObject } from "../../../utils/objectTools";

export const MUTATE_INFORMATION_VERIFICATION: DocumentNode = gql(`
    mutation mutateInformationVerification(
        $id: UUID
        $state: ActiveState
        $informationId: UUID!
        $applicationProfileId: UUID!
        $verified: Boolean
        $agreed: Boolean
        $informationApprovals: [InformationApprovalInput]
    ) {
        mutateInformationVerification(
            id: $id
            state: $state
            informationId: $informationId
            applicationProfileId: $applicationProfileId
            verified: $verified
            agreed: $agreed
            informationApprovals: $informationApprovals
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`);

export const getInformationVerificationsGraphqlMutationOptions = (informationVerification: Partial<InformationVerification>): QueryHookOptions => {
    informationVerification.state = informationVerification.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (informationVerification.id !== undefined && informationVerification.id !== null) options.variables['id'] = informationVerification.id;
    if (informationVerification.state !== undefined && informationVerification.state !== null) options.variables['state'] = informationVerification.state;
    if (informationVerification.informationId !== undefined && informationVerification.informationId !== null) options.variables['informationId'] = informationVerification.informationId;
    if (informationVerification.applicationProfileId !== undefined && informationVerification.applicationProfileId !== null) options.variables['applicationProfileId'] = informationVerification.applicationProfileId;
    if (informationVerification.verified !== undefined && informationVerification.verified !== null) options.variables['verified'] = informationVerification.verified;
    if (informationVerification.agreed !== undefined && informationVerification.agreed !== null) options.variables['agreed'] = informationVerification.agreed;
    if (informationVerification.informationApprovals !== undefined && informationVerification.informationApprovals !== null) options.variables['informationApprovals'] = cleanObject(informationVerification.informationApprovals);
    return options;
}
