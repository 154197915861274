import * as React from 'react';

import { ProjectCompany } from '../../contracts/contracts';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Divider, Typography, Autocomplete, TextField, Grid, Box } from '@mui/material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import ProjectCompanyListItem from './ProjectCompanyListItem';

type props = {
    projectCompanies: ProjectCompany[];
    onProjectCompanyDetailsClick?: (projectCompany: ProjectCompany) => void;
    onDeleteProjectCompany?: (projectCompany: ProjectCompany) => void;
    showDeleteButton?: boolean;
}

const ProjectCompaniesList: React.FC<props> = ({
    projectCompanies,
    onProjectCompanyDetailsClick,
    onDeleteProjectCompany,
    showDeleteButton,
}) => {
    const languageContext = useLanguageContext();

    return (<>
       <Box sx={{ display: 'flex', alignItems: 'center'}}>
            <CorporateFareIcon />
            <Typography sx={{ ml: 1 }} variant="h5">{languageContext.getMessage('companiesRaw')}</Typography>
       </Box>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Grid spacing={2} container>
            {projectCompanies.map(projectCompany => <ProjectCompanyListItem key={projectCompany.id} projectCompany={projectCompany} onProjectCompanyDetailsClick={onProjectCompanyDetailsClick}
            onDeleteProjectCompany={onDeleteProjectCompany} showDeleteButton={showDeleteButton} />)}
        </Grid>
    </>);
}

export default ProjectCompaniesList
