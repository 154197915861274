export const getRandomBool = (): boolean => {
    return Math.round(Math.random()) === 1;
}

export const getRandomNumber = (min: number = 100, max: number = 10000, mightBeNegative: boolean = false): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    let value = Math.floor(Math.random() * (max - min + 1)) + min;
    if (mightBeNegative && getRandomBool()) {
        value *= -1;
    }

    return value;
}

export const roundTo = (value: number, to: number) => {
    return Math.round(value / to) * to;
}

export const getRandomGuid = (): string => {
    var S4 = () => {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export const getRandomInt = (max: number): number => {
    return Math.floor(Math.random() * Math.floor(max));
}

export const getRandomIntMinMax = (min: number, max: number): number => {
    return Math.floor(min) + Math.floor(Math.random() * Math.floor(max));
}

export const getRandomElement = (array: Array<any>): any => {
    return array[getRandomInt(array.length)];
}

export const getRandomColor = (brightness: number, rgbNumber: number = -1): string => {
    // Six levels of brightness from 0 to 5, 0 being the darkest
    let rgb = [];
    if (rgbNumber < 0) {
        rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
    }
    else {
        rgb = [(rgbNumber / 2) % 256, (rgbNumber * 2) % 256, (rgbNumber * 2) % 256];
    }
    let mix = [brightness * 51, brightness * 51, brightness * 51]; //51 => 255/5
    let mixedrgb = [rgb[0] + mix[0], rgb[1] + mix[1], rgb[2] + mix[2]].map(function (x) { return Math.round(x / 2.0) })
    return "rgb(" + mixedrgb.join(",") + ")";
}

// https://www.color-hex.com/color-palettes/
export const ColorArray: string[] = ['#4d96fb', '#53aff4', '#70baff', '#98ceff', '#b1ddf9', '#ab9fe1', '#9489db', '#839ee7', '#96dbea', '#64eee3']

export const getRandomColorFromString = (rgbString: string): string => {
    let rgbNumber = 0
    for (let i = 0; i < rgbString.length; i++) {
        rgbNumber += rgbString.charCodeAt(i);
    }
    rgbNumber = rgbNumber % ColorArray.length;
    return ColorArray[rgbNumber];
}

export const checkIfBothPropertiesAreUndefined = (prop1: any, prop2: any): boolean => {
    return (prop1 !== undefined && prop2 !== undefined) || (prop1 === undefined && prop2 === undefined);
}

export const getPageSizeOptions = (maxSize: number): number[] => {
    const options = new Array<number>();
    options.push(1);
    if (!maxSize || maxSize < 0) {
        return options;
    }
    let option = Math.min(5, maxSize);
    while (option <= maxSize) {
        options.push(option);
        option *= 2;
    }
    if (options[options.length-1] < maxSize) {
        options.push(maxSize);
    }
    return options;
}

export const resolveDefaultPageSize = (pageSizeOptions: number[], defaultMaxPageSize?: number): number => {
    const maxPageSize = defaultMaxPageSize ?? 25;
    let newPageSize = pageSizeOptions[pageSizeOptions.length-1];
    if (newPageSize > maxPageSize) {
        newPageSize = pageSizeOptions.find(pageSize => pageSize >= maxPageSize) ?? maxPageSize;
    }
    return newPageSize;
}

export function notUndefined<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}