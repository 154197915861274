import React from "react";
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { CalendarPickerView } from "@mui/x-date-pickers/internals/models";

type props = {
    label: string;
    date?: Date;
    monthPicker?: boolean;
    onChange?: (date: Date | undefined) => void;
    onAccept?: (date: Date | undefined) => void;
    readOnly?: boolean;
}

export const GeneralDatePicker: React.FC<props> = ({
    label,
    date,
    monthPicker,
    onChange,
    onAccept,
    readOnly,
}) => {

    monthPicker = monthPicker ?? false;

    let dateValue = date === undefined ? null : date;
    const languageContext = useLanguageContext();

    let format = "dd.MM.yyyy";
    let views: CalendarPickerView[] | undefined = undefined;
    let openTo: CalendarPickerView | undefined = undefined;
    if (monthPicker) {
        format = "MM/yyyy"
        openTo = "month";
        views = ["year", "month"]
    }
    return <MobileDatePicker
            label={label}
            showToolbar={true}
            openTo={openTo}
            views={views}
            value={dateValue}
            readOnly={readOnly}
            onChange={(newDate: any) => {
                if (!onChange) {
                    return;
                }
                if (newDate) {
                    if (newDate.toString() !== 'Invalid Date') {
                        onChange(new Date(newDate.getTime()))
                    }
                }
                else {
                    onChange(undefined)
                }
            }}
            onAccept={(newDate) => {
                if (!onAccept) {
                    return;
                }
                if (newDate) {
                    if (newDate.toString() !== 'Invalid Date') {
                        onAccept(new Date(newDate.getTime()))
                    }
                } else {
                    onAccept(undefined)
                }
            }}
            componentsProps={{
                actionBar: {
                    actions: [
                        'clear',
                        'today',
                        'cancel', 
                        'accept', 
                    ]
                }
            }}
            renderInput={(params) => <TextField {...params} variant={"standard"} fullWidth />}
            inputFormat={format}/>
}