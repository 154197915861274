import { IconButton, Typography } from "@mui/material"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { PDFViewer } from "../common/PDFViewer"
import ReactMarkdown from "react-markdown"
import { downloadBase64File } from "../../utils/fileTools"

type SafetyFileViewerProps = {
    fileBase64: string;
    filename: string | undefined;
}

export const SafetyFileViewer = (props: SafetyFileViewerProps) => {
    const { fileBase64, filename } = props

    const isPDF = fileBase64.indexOf('application/pdf') >= 0

    return <>
        {isPDF && <PDFViewer fileBase64={fileBase64} />}
        <IconButton
            onClick={() => downloadBase64File(undefined, fileBase64, filename ?? '')}>
            <Typography style={{ marginRight: 10 }}>
                <ReactMarkdown>{filename ? `#${isPDF ? '#' : ''} ${filename}` : ''}</ReactMarkdown>
            </Typography>
            <FileDownloadIcon fontSize={isPDF ? 'medium' : 'large'} />
        </IconButton>
    </>
}