import React, { useEffect, useRef, useState } from "react";
import { useRouteMatch } from 'react-router';
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { ActiveState, DocumentType, Information, RoleType, InformationAccess } from "../../contracts/contracts";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useInformationContext } from "../../contexts/information/informationContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import PageContentLayout from "../../component/layouts/PageContentLayout";
import EditInformationDetails from "../../component/informationComponents/EditInformationDetails";
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import { Dictionary } from "../../global-types";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { checkInformationIsValid } from "../../utils/informationTools";

const SafetyInformationDetailsView: React.FC<{}> = () => {

    const editedInformation = useRef<Information>({});
    const [editedInformationIsValid, setEditedInformationIsValid] = useState<boolean>(true);
    const { params } = useRouteMatch<any>()
    const { informationId } = params

    const projectMemberContext = useProjectMemberContext();
    const urlContext = useUrlContext();
    const urlState = urlContext.getUrlState();

    const languageContext = useLanguageContext();
    const menuContext = useMenuContext();

    const informationContext = useInformationContext()
    const ticketContext = useTicketContext();

    const informationSearch = informationContext.getInformationSearch();
    informationSearch.projectId = projectMemberContext.getSelectedProjectMember()?.projectId;

    const information = informationContext.getInformation(informationId)

    const informationLoading = informationContext.loadingInformations

    const isLoading = informationLoading
    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([DocumentType.APPLICATION_PROFILE]);

        informationContext.searchInformations(informationSearch);
    }, [urlContext.currentLocation])

    const onInformationChange = (changedInformation: Information) => {
        editedInformation.current = { ...changedInformation };
        const informationIsValid = checkInformationIsValid(editedInformation.current);
        setEditedInformationIsValid(informationIsValid);
    }

    const updateUrl = (route: ApplicationRoute, urlState?: Dictionary<string | number | Date>) => {
        const urlQuery = urlState ? urlContext.buildUrlQuery(urlState) : '';

        urlContext.pushUrlQuery(urlQuery, route.route)
    }

    const onBack = () => {
        let route = menuContext.getApplicationRouteById(ApplicationRouteId.SafetyInformations);
        updateUrl(route, { selectedProjectId: urlState.selectedProjectId })
    }

    const checkAndSwapInformationPositions = async (mutatedInformation: Information, allParentInformationsForProject: Information[]) => {
        const newOrderPosition = mutatedInformation.orderPosition;
        const oldOrderPosition = allParentInformationsForProject.find(p => p.id === (mutatedInformation.informationId ?? mutatedInformation.id))?.orderPosition;
        if (newOrderPosition !== undefined && oldOrderPosition !== undefined && oldOrderPosition !== newOrderPosition) {
            if (newOrderPosition > oldOrderPosition) {
                await Promise.all(allParentInformationsForProject.map(async (_information) => {
                    const currentPosition = _information.orderPosition;
                    if (currentPosition !== undefined && currentPosition > oldOrderPosition && currentPosition <= newOrderPosition) {
                        _information.orderPosition = _information.orderPosition !== undefined ? _information.orderPosition - 1 : undefined;
                        await informationContext.mutateInformation(_information);
                        return _information;
                    }
                }))
            } else {
                await Promise.all(allParentInformationsForProject.map(async (_information) => {
                    const currentPosition = _information.orderPosition;
                    if (currentPosition !== undefined && currentPosition >= newOrderPosition && currentPosition < oldOrderPosition) {
                        _information.orderPosition = _information.orderPosition !== undefined ? _information.orderPosition + 1 : undefined;
                        await informationContext.mutateInformation(_information);
                        return _information;
                    }
                }))
            }
        }
    }

    const onSave = async (): Promise<Information | undefined> => {
        if (editedInformation.current.id) {
            const mutatedInformation: Information = { ...editedInformation.current };
            const allParentInformationsForProject = informationContext.getProjectParentInformations(mutatedInformation.projectId);
            mutatedInformation.state = mutatedInformation.state ?? ActiveState.ACTIVE;

            await checkAndSwapInformationPositions(mutatedInformation, allParentInformationsForProject);

            mutatedInformation.orderPosition = mutatedInformation.orderPosition ?? allParentInformationsForProject.length;
            mutatedInformation.informationAccess = mutatedInformation.informationAccess ?? InformationAccess.PRIVATE;
            setIsSaving(true);
            const documentId = await informationContext.mutateInformation(mutatedInformation);
            mutatedInformation.id = documentId;
            const parentInformationId = informationContext.getParentInformationId(information);
            const relatedInformations = informationContext.getRelatedInformations(parentInformationId);
            relatedInformations.forEach(async relatedInformation => {
                if (relatedInformation?.id && relatedInformation.id !== mutatedInformation.id) {
                    relatedInformation.orderPosition = mutatedInformation.orderPosition;
                    relatedInformation.informationAccess = mutatedInformation.informationAccess;
                    await informationContext.mutateInformation(relatedInformation);
                }
            })
            if ((mutatedInformation.informationFilename ?? '').trim().length === 0) {
                await informationContext.deleteInformationFile(mutatedInformation);
            }
            if (mutatedInformation.file && (mutatedInformation.informationFilename ?? '').trim().length > 0) {
                await informationContext.saveInformationFile(mutatedInformation, mutatedInformation.file);
            }
            setIsSaving(false);
            return mutatedInformation;
        }
        return undefined;
    }

    const hasWriteAccessToSelectedProject = information?.projectId && projectMemberContext.hasProjectAccess(RoleType.WRITER, information?.projectId);
    const BottomNavigation = (
        <>
            <Button variant='contained' onClick={onBack}>{languageContext.getMessage('back')}</Button>
            {hasWriteAccessToSelectedProject && <Button variant='contained' disabled={isLoading || isSaving || !editedInformationIsValid} onClick={async () => await onSave()}>{languageContext.getMessage('save')}</Button>}
        </>
    )

    return (
        <PageContentLayout bottomNavigation={BottomNavigation}>
            <Typography variant="h5" style={{ marginTop: 10, marginBottom: 20 }}>
                {languageContext.getMessage('safetyInformation')}
            </Typography>
            {
                isLoading || isSaving ? <Grid container justifyContent='center'><CircularProgress/></Grid> : 
                (
                    <EditInformationDetails information={information} onInformationChange={onInformationChange} onInformationForceSaveForPreview={onSave}/>
                )
            }
        </PageContentLayout>
    );
}

export default SafetyInformationDetailsView;
