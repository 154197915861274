import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import React from "react";
import { Project } from "../../contracts/contracts";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";


import { SnackbarComponent } from "../toastComponent/SnackbarComponent";

type props = {
    readonly open: boolean;
    readonly onClose: () => void;
    project: Project | undefined;
}

const ProjectMemberInviteUserDialog: React.FC<props> = ({ open, onClose, project }) => {
    const projectId = project?.id

    const projectMemberContext = useProjectMemberContext()
    const languageContext = useLanguageContext();

    const [isSuccessInvited, setIsSuccessInvited] = React.useState<boolean>(false);
    const [emails, setEmails] = React.useState<string[]>([]);

    const setUniqueEmails = (emails: string[]) => {
        setEmails(emails.filter((value, index, array) => array.indexOf(value) === index && value.includes('@')));
    }

    const handleCloseButtonClick = () => {
        setEmails([]);
        onClose();
    }

    const handleOnInviteClick = async () => {
        if (!projectId) {
            return;
        }
        await projectMemberContext.inviteProjectMember(projectId, emails);
        setIsSuccessInvited(true);
        handleCloseButtonClick();
    }

    return (
        <>
            <SnackbarComponent
                open={isSuccessInvited}
                onClose={() => setIsSuccessInvited(false)}
            >
                {languageContext.getMessage('memberInvitedMessage')}
            </SnackbarComponent>
            <Dialog
                open={open}
                onClose={handleCloseButtonClick}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="form-dialog-title">{languageContext.getMessage("inviteMember")}</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        multiple
                        freeSolo
                        limitTags={-1}
                        fullWidth
                        id="multiple-limit-tags"
                        options={emails}
                        size="medium"
                        value={emails}
                        renderInput={(params) => <TextField 
                            {...params} 
                            label={languageContext.getMessage('email')} 
                            variant="outlined"
                            required={true}
                            error={emails.length === 0}
                            helperText={emails.length === 0 ? languageContext.getMessage('emailFieldRequired') : ''} />}
                        onChange={(event, newValues) => {
                            setUniqueEmails(newValues);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseButtonClick} color="primary">
                        {languageContext.getMessage('cancel')}
                    </Button>
                    <Button disabled={emails.length === 0} onClick={handleOnInviteClick} color="primary" variant="contained">
                        {languageContext.getMessage('invite')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ProjectMemberInviteUserDialog;
