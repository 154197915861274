import { Grid, ImageListItem, ImageListItemBar } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useStorageFileMutationsContext } from '../../contexts/storageFile/mutations/storageFileMutationsContext';
import { Project, RoleType, DocumentType, ProjectState } from '../../contracts/contracts';
import { getStorageFileFullKey, onUploadFiles } from '../../utils/fileTools';
import FileSelect from '../generalComponents/FileSelect';
import { ImageList } from '@mui/material'
import LoadingWrapper from "../loadingComponents/loadingWrapper";
import default_image_placeholder from "../../data/pictures/image_placeholder.png";
import { useStoredFilesByFileKey } from "../../hooks/useStoredFilesByFileKey";
import { useBrowserContext } from '../../contexts/browserContext/browserContext';
import { getDocumentRole } from '../../contexts/userRole/userRoleTools';
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import { compressFiles } from '../../utils/compressionTools';
import { useStorageFileContext } from '../../contexts/storageFile/storageFileContext';

export type ImageTile = {
	image: string;
	base64Image: string | undefined;
	title: string;
	cols: number;
	showUploadImageButton: boolean;
}

type props = {
	project?: Project | undefined;
}

const ProjectDetailTopBar: React.FC<props> = ({ project }) => {
	const languageContext = useLanguageContext();
	const storageFileContext = useStorageFileContext();
	const storageMutationContext = useStorageFileMutationsContext();
	const projectMemberContext = useProjectMemberContext();
	const projectContext = useProjectContext();

	const fileKey = getStorageFileFullKey(project?.id ?? '');
	const browserContext = useBrowserContext();

	const [base64Images, setBase64Images] = useState<string[]>([]);
	const [base64ProjectImages, base64ProjectImagesMetadata, imageLoading] = useStoredFilesByFileKey(fileKey, base64Images, setBase64Images);
	const projectPictureEditRoles = [getDocumentRole(DocumentType.PROJECT, project?.id ?? '', RoleType.WRITER)]
	const projectPictureReadRoles = [browserContext.getTenantRole()]

	const projectTitle = projectContext.getProjectTitleWithProjectState(project);

	return (<>
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<ImageList sx={{ m: 0, '& img': { maxHeight: 150 } }} >
					<ImageListItem cols={3}>
						<LoadingWrapper loading={storageMutationContext.loading || imageLoading}>
							<img src={(base64ProjectImages.length > 0 ? base64ProjectImages[0] : undefined) ?? default_image_placeholder} alt={project?.name} style={{
								height: 'auto',
								width: '100%',
								objectFit: 'cover'
							}}
							/>
							{project?.id && projectMemberContext.hasProjectAccess(RoleType.WRITER, project?.id) &&
								<ImageListItemBar
									sx={{
										background:
											'linear-gradient(to bottom, rgba(0,0,0,0) 0%, ' +
											'rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)',
									}}
									actionIcon={
										<FileSelect
											inputId={`fileSelect_${project?.id}`}
											onSelectedFiles={newImageFiles => compressFiles(newImageFiles, compressedFiles => onUploadFiles(
												storageFileContext, 
												storageMutationContext,
												compressedFiles,
												fileKey,
												projectPictureEditRoles,
												projectPictureReadRoles,
												tileImages => {
													if (tileImages.length > 0) {
														setBase64Images(tileImages)
													}
												}))}
											buttonTitle={languageContext.getMessage('uploadImage')}
											fileAcceptance={'image/*'} />
									} />}
						</LoadingWrapper>
					</ImageListItem>
				</ImageList>
			</Grid>
			<Grid item xs={12}>
				<Typography gutterBottom variant="h4" sx={{ mt: 1 }} style={{
					display: 'flex',
					justifyContent: 'center',
					textAlign: 'center'
				}}>
					{projectTitle}
				</Typography>
			</Grid>
		</Grid>
	</>);
}

export default ProjectDetailTopBar
