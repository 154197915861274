import React, { useState } from 'react';
import { NewsFeed, NewsFeedAccessLevel } from "../../contracts/contracts";
import { ListItemButton, ListItemAvatar, ListItemText } from "@mui/material";
import ApplicationProfileAvatar from "../applicationProfileComponents/applicationProfileAvatar";
import { getStorageFileFullKey } from "../../utils/fileTools";
import { useStoredFilesByFileKey } from "../../hooks/useStoredFilesByFileKey";
import LockIcon from '@mui/icons-material/Lock';

type props = {
	newsFeed?: NewsFeed,
	onTeaserClick?: (newsFeed?: NewsFeed) => void;
}

const NewsFeedTeaser: React.FC<props> = ({ newsFeed, onTeaserClick }) => {
	const fileKey = getStorageFileFullKey(newsFeed?.applicationProfileId ?? '');
    const [base64Images, setBase64Images] = useState<string[]>([]);
	const imageSrces = useStoredFilesByFileKey(fileKey, base64Images, setBase64Images)[0];

	return (
		<ListItemButton onClick={() => onTeaserClick ? onTeaserClick(newsFeed) : null} sx={{ position: 'relative' }}>
			<ListItemAvatar>
				<ApplicationProfileAvatar
				  src={imageSrces.length > 0 ? imageSrces[0] : undefined}
				  userName={newsFeed?.applicationProfileName}
				  id={newsFeed?.applicationProfileId}
				/>
			</ListItemAvatar>
			<ListItemText primary={newsFeed?.applicationProfileName} secondary={newsFeed?.title}/>
			{newsFeed?.accessLevel === NewsFeedAccessLevel.PRIVATE && (
				<LockIcon sx={{ position: 'absolute', top: 10, right: 10 }} color={'primary'}/>
			)}
		</ListItemButton>
	)
}

export default NewsFeedTeaser;
