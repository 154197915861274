import React, { createContext, useContext } from "react";
import { Settings, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getSettingsGraphqlMutationOptions, MUTATE_SETTINGS } from "./settingsMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface SettingsMutationsContext {
    mutateSettings: (settings: Settings, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void) => void;
}

const SettingsMutationsContext = createContext<SettingsMutationsContext>(null as unknown as SettingsMutationsContext);

export const SettingsMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [settingsMutation] = useMutation(MUTATE_SETTINGS);

    const handleSettingsMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        if (result.errors || !result.data) {
            onError(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateSettings.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
        if (tickets.length === 0 && 'id' in variables) {
            onTicketSubscribed(variables['id'], variables);
        }
    }

    const mutateSettings = (settings: Settings, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        const options = getSettingsGraphqlMutationOptions(settings)
        settingsMutation(options).then((result) => handleSettingsMutationResult(result, options.variables, onTicketSubscribed, onError));
    }

    const settingsMutationsContext: SettingsMutationsContext = {
        mutateSettings
    };

    return (
        <SettingsMutationsContext.Provider value={settingsMutationsContext}>
            {children}
        </SettingsMutationsContext.Provider>
    );
}

export const useSettingsMutationsContext = (): SettingsMutationsContext => {
    return useContext(SettingsMutationsContext);
}
