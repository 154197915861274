import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/react-hooks";
import { ProjectMemberCheckedInEvent } from "../contracts/contracts"
import { QUERY_PROJECT_MEMBER_CHECKED_IN_EVENT, getProjectMemberCheckedInEventsGraphqlQueryOptions } from "../contexts/projectMember/queries/projectMemberQueries";
import { Guid } from "../utils/common-types";

export const useQueryProjectMemberCheckedInEvents = (enabled: boolean, 
                                                     projectId: Guid | undefined, 
                                                     fromTimestamp: Date | undefined, 
                                                     toTimestamp: Date | undefined, 
                                                     isCheckedIn?: boolean | undefined, 
                                                     projectMemberId?: Guid | undefined): [ProjectMemberCheckedInEvent[], boolean] => {
    const [lastQueriedProjectId, setLastQueriedProjectId] = useState<Guid | undefined>(undefined);
    const [lastQueriedFromTimestamp, setLastQueriedFromTimestamp] = useState<Date | undefined>(undefined);
    const [lastQueriedToTimestamp, setLastQueriedToTimestamp] = useState<Date | undefined>(undefined);
    const [lastQueriedIsCheckedIn, setLastQueriedIsCheckedIn] = useState<boolean | undefined>(undefined);
    const [lastQueriedProjectMemberId, setLastQueriedProjectMemberId] = useState<Guid | undefined>(undefined);
    const [fetchedProjectMemberCheckedInEvents, setFetchedProjectMemberCheckedInEvents] = useState<ProjectMemberCheckedInEvent[]>([]);
    const [queryProjectMemberCheckedInEvent, queryProjectMemberCheckedInEventResponse] = useLazyQuery(QUERY_PROJECT_MEMBER_CHECKED_IN_EVENT);

    useEffect(() => {
        if (!enabled || queryProjectMemberCheckedInEventResponse.loading || !projectId || !fromTimestamp || !toTimestamp) {
            return;
        }
        if ((projectId && projectId !== lastQueriedProjectId) ||
            (fromTimestamp && fromTimestamp !== lastQueriedFromTimestamp) || 
            (toTimestamp && toTimestamp !== lastQueriedToTimestamp) || 
            (isCheckedIn !== lastQueriedIsCheckedIn) || 
            (projectMemberId !== lastQueriedProjectMemberId)) {
            setLastQueriedProjectId(projectId);
            setLastQueriedFromTimestamp(fromTimestamp);
            setLastQueriedToTimestamp(toTimestamp);
            setLastQueriedIsCheckedIn(isCheckedIn);
            setLastQueriedProjectMemberId(projectMemberId);
            const queryOptions = getProjectMemberCheckedInEventsGraphqlQueryOptions(projectId, fromTimestamp, toTimestamp, isCheckedIn, projectMemberId);
            queryProjectMemberCheckedInEvent(queryOptions);
        }
    }, [projectId, fromTimestamp, toTimestamp, isCheckedIn, projectMemberId, queryProjectMemberCheckedInEventResponse.loading])

    useEffect(() => {
        if (queryProjectMemberCheckedInEventResponse.data && queryProjectMemberCheckedInEventResponse.data.projectMemberCheckedInEvents) {
            const newFetchedProjectMemberCheckedInEvent: ProjectMemberCheckedInEvent[] = queryProjectMemberCheckedInEventResponse.data.projectMemberCheckedInEvents;
            newFetchedProjectMemberCheckedInEvent.forEach(event => {
                event.timestamp = new Date(event.timestamp ?? new Date(0));
            });
            setFetchedProjectMemberCheckedInEvents(newFetchedProjectMemberCheckedInEvent.slice());
        }
    }, [queryProjectMemberCheckedInEventResponse.data]);

    useEffect(() => {
        if (queryProjectMemberCheckedInEventResponse.error) {
            console.error(queryProjectMemberCheckedInEventResponse.error);
        }
    }, [queryProjectMemberCheckedInEventResponse.error]);

    return [fetchedProjectMemberCheckedInEvents, queryProjectMemberCheckedInEventResponse.loading];
}