import React, { createContext, useContext, useEffect, useState } from "react";
import { Settings } from "../../../contracts/contracts";
import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/react-hooks';
import { QUERY_SETTINGS, getSettingsGraphqlQueryOptions } from './settingsQueries'
import { useAuthContext } from "../../auth/authContext";

export interface SettingsQueriesContext {
    fetchedSettings: Array<Settings>,
    querySettings: (settings: Settings) => void,
    queryResponse: LazyQueryResult<any, OperationVariables>,
}

const SettingsQueriesContext = createContext<SettingsQueriesContext>(null as unknown as SettingsQueriesContext);

export const SettingsQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedSettings, setFetchedSettings] = useState<Array<Settings>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_SETTINGS);

    const querySettings = (settings: Settings): void => {
        const queryOptions = getSettingsGraphqlQueryOptions(settings);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.settings) {
            const newFetchedSettings: Array<Settings> = queryResponse.data.settings;
            setFetchedSettings(newFetchedSettings);
        }
    }, [queryResponse.data]);
    
    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedSettings([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const settingsQueriesContext: SettingsQueriesContext = {
        fetchedSettings,
        querySettings,
        queryResponse,
    };

    return (
        <SettingsQueriesContext.Provider value={settingsQueriesContext}>
            {children}
        </SettingsQueriesContext.Provider>
    );
}

export const useSettingsQueriesContext = (): SettingsQueriesContext => {
    return useContext(SettingsQueriesContext);
}
