import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ApplicationProfile } from "../../../contracts/contracts";
import { getApplicationProfilesGraphqlMutationOptions } from "../mutations/applicationProfileMutations";

export const QUERY_APPLICATION_PROFILE: DocumentNode = gql(`
   query applicationProfiles(
        $searchIndexStart: Int
        $searchIndexStop: Int
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $userId: UUID
        $name: String
        $email: String
      	$phoneNumber: String
        $selectedApplicationLanguage: String
        $relatives: [RelativeInfoInput]
        $languages: [String]
        $organizationNumber: String
        $hseCardNumber: String
    ) {
        applicationProfiles(
            searchIndexStart: $searchIndexStart
            searchIndexStop: $searchIndexStop
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            userId: $userId
            name: $name
            email: $email
            phoneNumber: $phoneNumber
            selectedApplicationLanguage: $selectedApplicationLanguage
            relatives: $relatives
            languages: $languages
            organizationNumber: $organizationNumber
            hseCardNumber: $hseCardNumber
        ) {
            id
            documentType
            stateTime
            state
            created
            userId
            name
            email
            phoneNumber
            selectedApplicationLanguage
            relatives {
                name
                phoneNumber
            }
            languages
            organizationNumber
            hseCardNumber
        }
    }
`);

export const getApplicationProfilesGraphqlQueryOptions = (applicationProfile: Partial<ApplicationProfile>): QueryHookOptions => {
    const options = getApplicationProfilesGraphqlMutationOptions(applicationProfile)
    options.variables = options.variables ?? {};
    if (applicationProfile.searchIndexStart !== undefined) options.variables['searchIndexStart'] = applicationProfile.searchIndexStart;
    if (applicationProfile.searchIndexStop !== undefined) options.variables['searchIndexStop'] = applicationProfile.searchIndexStop;
    return options;
}
