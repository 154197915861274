import { DialogActions } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useLanguageContext } from '../../contexts/language/LanguageContext';

const useStyles = makeStyles((theme: Theme) => ({
}));

export interface Props {
    title?: string,
    open: boolean;
    onClose: (saveIsConfirmed: boolean) => Promise<void>;
}

const ConfirmSaveDialog: React.FC<Props> = ({
    title,
    open,
    onClose
}) => {
    const classes = useStyles();
    const languageContext = useLanguageContext();

    title = title ?? languageContext.getMessage('doYouWantToSaveElement');

    const handleOnClickSave = () => {
        return onClose(true);
    };

    const handleOnClickClose = () => {
        return onClose(false);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            onClose={async (event, reason) => {
                if (reason !== 'backdropClick') {
                  await handleOnClickClose();
                }
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogActions>
                <Button onClick={handleOnClickSave} color="primary" variant="contained">
                    {languageContext.getMessage('save')}
                </Button>
                <Button onClick={handleOnClickClose} color="primary" variant="contained" autoFocus>
                    {languageContext.getMessage('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmSaveDialog;