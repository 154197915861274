import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, Information, RoleType, DocumentType, StorageFile } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { getStorageFileFullKey, onUploadFiles } from "../../utils/fileTools";
import { guidIsNullOrEmpty } from "../../utils/guidTools";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useBrowserContext } from "../browserContext/browserContext";
import { useLanguageContext } from "../language/LanguageContext";
import { useStorageFileMutationsContext } from "../storageFile/mutations/storageFileMutationsContext";
import { useUrlContext } from "../url/urlContext";
import { getDocumentRole } from "../userRole/userRoleTools";
import { InformationMutationsContextProvider, useInformationMutationsContext } from "./mutations/informationMutationsContext";
import { InformationQueriesContextContext, useInformationQueriesContext } from "./queries/informationQueriesContext";
import { InformationSubscriptionsContextProvider, useInformationSubscriptionsContext } from "./subscriptions/informationSubscriptionsContext";
import { useStorageFileContext } from "../storageFile/storageFileContext";

export interface InformationContext {
    getInformationSearch: () => Information,
    searchInformations: (informationSearch: Information) => void,
    getInformations: () => Information[],
    getInformation: (id: Guid | undefined) => (Information | undefined),
    getProjectParentInformations: (projectId: Guid | undefined) => Array<Information>,
    getParentInformationId: (information: Information | undefined) => Guid | undefined;
    isParentInformation: (information: Information | undefined, parentInformationId: Guid | undefined) => boolean;
    getRelatedInformations: (parentInformationId: Guid | undefined) => Information[];
    mutateInformation: (information: Information) => Promise<Guid | undefined>,
    saveInformationFile: (information: Information, file: any) => Promise<string[]>,
    deleteInformationFile: (information: Information) => Promise<void>,
    loadingInformations: boolean,
}

const InformationContext = createContext<InformationContext>(null as unknown as InformationContext);

export const sortInformationByDate = (a: Information, b: Information) => {
    if ((a.created ?? "") < (b.created ?? "")) { return -1; }
    if ((a.created ?? "") > (b.created ?? "")) { return 1; }
    return 0;
}

export const sortInformationByOrderPosition = (a: Information, b: Information) => {
    if ((a.orderPosition ?? 0) < (b.orderPosition ?? 0)) { return -1; }
    if ((a.orderPosition ?? 0) > (b.orderPosition ?? 0)) { return 1; }
    return 0;
}

export enum InformationTabs {
    all = "all",
    details = "details",
}

export const InformationContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <InformationMutationsContextProvider>
            <InformationQueriesContextContext>
                <InformationSubscriptionsContextProvider>
                    <InformationSubContextProvider>
                        {children}
                    </InformationSubContextProvider>
                </InformationSubscriptionsContextProvider>
            </InformationQueriesContextContext>
        </InformationMutationsContextProvider>
    );
}

export const InformationSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const browserContext = useBrowserContext();
    const storageFileContext = useStorageFileContext();
    const storageFileMutationContext = useStorageFileMutationsContext();

    const informationMutationsContext = useInformationMutationsContext();
    const informationQueriesContext = useInformationQueriesContext();
    const informationSubscriptionsContext = useInformationSubscriptionsContext();

    const [currentInformationSearch, setCurrentInformationSearch] = useState<Information | undefined>(undefined);
    const [informations, setInformations] = useState<Information[]>([]);
    const maxInformationsToFetchAtOnce = 100;


    const mergeInformations = (oldInformations: Array<Information>, newInformations: Array<Information>): Array<Information> => {
        const updatedInformations = oldInformations.slice();
        if (!newInformations) {
            console.error(`Received undefined set of Informations: ${newInformations}`);
            return [];
        }
        newInformations.forEach(newInformation => {
            newInformation.created = new Date(newInformation.created ?? 0);

            const index = updatedInformations.findIndex(information => information.id === newInformation.id);
            if (index >= 0) {
                if (newInformation.state === ActiveState.ACTIVE) {
                    updatedInformations[index] = newInformation;
                }
                else {
                    updatedInformations.splice(index, 1);
                }
            } else {
                if (newInformation.state === ActiveState.ACTIVE) {
                    updatedInformations.push(newInformation);
                }
            }
        });
        return updatedInformations.sort(sortInformationByDate).sort(sortInformationByOrderPosition);
    }


    const getInformationSearch = (): Information => {
        const urlState = urlContext.getUrlState();
        return {
        }
    }

    const searchInformations = (informationSearch: Information): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(informationSearch, currentInformationSearch);
        matched = matched && informationSearch?.id === currentInformationSearch?.id;
        matched = matched && informationSearch?.informationId === currentInformationSearch?.informationId;
        matched = matched && informationSearch?.projectId === currentInformationSearch?.projectId;
        if (!matched) {
            informationSearch.searchIndexStart = 0;
            informationSearch.searchIndexStop = maxInformationsToFetchAtOnce;
            setCurrentInformationSearch(informationSearch);
            setInformations([]);
        }
    }

    const getInformations = (): Array<Information> => {
        return informations
    }

    const getProjectParentInformations = (projectId: Guid | undefined): Array<Information> => {
        return informations.filter(projectInformation =>
            projectInformation.projectId === projectId &&
            informationContext.isParentInformation(projectInformation, projectInformation.informationId));
    }

    const getInformation = (id: Guid | undefined) => {
        return id === undefined ? undefined : informations.find(information => information.id === id);
    }

    const getParentInformationId = (information: Information | undefined): Guid | undefined => {
        const parentInformationId = information?.informationId ?? information?.id;
        return parentInformationId;
    }

    const isParentInformation = (information: Information | undefined, parentInformationId: Guid | undefined): boolean => {
        const isParentInformation = guidIsNullOrEmpty(parentInformationId) || information?.id === parentInformationId;
        return isParentInformation;
    }

    const getRelatedInformations = (parentInformationId: Guid | undefined): Array<Information> => {
        const childInformations = parentInformationId ? getInformations().filter(childInformation => childInformation.informationId && childInformation.informationId === parentInformationId) : [];
        const parentInformation = parentInformationId ? getInformation(parentInformationId) : undefined;
        const relatedInformations = parentInformation ? [parentInformation].concat(childInformations.map(childInformation => childInformation)) : [];
        return relatedInformations;
    }

    const mutateInformation = (information: Information): Promise<Guid | undefined> => {
        return new Promise((resolve, reject) => informationMutationsContext.mutateInformation(information, (documentId, variables) => {
            information = { ...information, ...variables };
            information.id = documentId;
            setInformations(mergeInformations(informations, [information]));
            resolve(documentId);
        }, (errors) => {
            reject(errors);
        }));
    }

    const saveInformationFile = (information: Information, file: any): Promise<string[]> => {
        const fileKey = getStorageFileFullKey(information.id ?? '');
        const fileEditRoles = [getDocumentRole(DocumentType.PROJECT, information?.projectId ?? '', RoleType.WRITER)]
        const fileReadRoles = [browserContext.getTenantRole()]
        return new Promise((resolve, reject) => {
            onUploadFiles(storageFileContext, storageFileMutationContext, [file], fileKey, fileEditRoles, fileReadRoles, base64Images => {
                resolve(base64Images);
            }, reason => reject(reason));
        });
    }

    const deleteInformationFile = (information: Information): Promise<void> => {
        const fileKey = getStorageFileFullKey(information.id ?? '');
        const storageFileToDelete: StorageFile = {
            delete: true,
            key: fileKey,
        }
        return new Promise((resolve, reject) => storageFileMutationContext.mutateStorageFile(storageFileToDelete).then(() => {
            storageFileContext.popStorageFilesFromCache([storageFileToDelete]);
            resolve();
        }).catch(reason => reject(reason)));
    }

    useEffect(() => {
        if (currentInformationSearch) {
            informationQueriesContext.queryInformations(currentInformationSearch);
        }
    }, [currentInformationSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setInformations([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setInformations(mergeInformations(informations, informationQueriesContext.fetchedInformations));
        if (informationQueriesContext.fetchedInformations.length >= maxInformationsToFetchAtOnce && currentInformationSearch) {
            currentInformationSearch.searchIndexStart = (currentInformationSearch.searchIndexStart ?? 0) + informationQueriesContext.fetchedInformations.length;
            currentInformationSearch.searchIndexStop = currentInformationSearch.searchIndexStart + maxInformationsToFetchAtOnce;
            setCurrentInformationSearch({ ...currentInformationSearch });
        }
    }, [informationQueriesContext.fetchedInformations]);

    useEffect(() => {
        setInformations(mergeInformations(informations, informationSubscriptionsContext.subscribedInformations));
    }, [informationSubscriptionsContext.subscribedInformations]);

    const informationContext = {
        getInformationSearch,
        searchInformations,
        getInformations,
        getInformation,
        getProjectParentInformations,
        getParentInformationId,
        isParentInformation,
        getRelatedInformations,
        mutateInformation,
        saveInformationFile,
        deleteInformationFile,
        loadingInformations: informationQueriesContext.queryResponse.loading,
    };

    return (
        <InformationContext.Provider value={informationContext}>
            {children}
        </InformationContext.Provider>
    );
}

export const useInformationContext = (): InformationContext => {
    return useContext(InformationContext);
}
