import React, { createContext, useContext, useEffect, useState } from "react";
import { NewsFeed } from "../../../contracts/contracts";
import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/react-hooks';
import { QUERY_NEWS_FEED, getNewsFeedsGraphqlQueryOptions } from './newsFeedQueries'
import { useAuthContext } from "../../auth/authContext";

export interface NewsFeedQueriesContext {
    fetchedNewsFeeds: Array<NewsFeed>,
    queryNewsFeeds: (newsFeed: NewsFeed) => void,
    queryResponse: LazyQueryResult<any, OperationVariables>,
}

const NewsFeedQueriesContext = createContext<NewsFeedQueriesContext>(null as unknown as NewsFeedQueriesContext);

export const NewsFeedQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedNewsFeeds, setFetchedNewsFeeds] = useState<Array<NewsFeed>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_NEWS_FEED);

    const queryNewsFeeds = (newsFeed: NewsFeed): void => {
        const queryOptions = getNewsFeedsGraphqlQueryOptions(newsFeed);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.newsFeeds) {
            const newFetchedNewsFeeds: Array<NewsFeed> = queryResponse.data.newsFeeds;
            setFetchedNewsFeeds(newFetchedNewsFeeds);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedNewsFeeds([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const newsFeedQueriesContext: NewsFeedQueriesContext = {
        fetchedNewsFeeds,
        queryNewsFeeds,
        queryResponse,
    };

    return (
        <NewsFeedQueriesContext.Provider value={newsFeedQueriesContext}>
            {children}
        </NewsFeedQueriesContext.Provider>
    );
}

export const useNewsFeedQueriesContext = (): NewsFeedQueriesContext => {
    return useContext(NewsFeedQueriesContext);
}
