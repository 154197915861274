import { useEffect, useState } from "react"
import { convertImageToBase64 } from "../utils/fileTools";
import company_logo from '../data/companyLogo.png'

export const useCompanyLogo = (): string | undefined => {
    const [companyLogoInlineImage, setCompanyLogoInlineImage] = useState<string>();

    useEffect(() => {
        if (companyLogoInlineImage) {
            return;
        }
        convertImageToBase64(company_logo, (dataUrl) => {
            setCompanyLogoInlineImage(dataUrl);
        });
    }, []);

    return companyLogoInlineImage;
}