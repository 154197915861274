import { Box, Divider, ListItemIcon } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import React, { useState } from 'react';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useUserContext } from '../../contexts/user/UserContext';
import { useAuthContext } from '../../contexts/auth/authContext';
import { navigateToExternalUrl } from '../../utils/urlTools';
import { ApplicationRouteId, useMenuContext } from '../../contexts/menu/menuContext';
import { useUrlContext } from '../../contexts/url/urlContext';
import {
    ApplicationProfileTabs,
    useApplicationProfileContext
} from '../../contexts/applicationProfile/applicationProfileContext';
import { Dictionary } from '../../global-types';
import ApplicationProfileAvatar from "../applicationProfileComponents/applicationProfileAvatar";
import { getStorageFileFullKey } from "../../utils/fileTools";
import { useStoredFilesByFileKey } from "../../hooks/useStoredFilesByFileKey";

const UserMenu: React.FC<{}> = () => {
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const menuContext = useMenuContext();
    const urlContext = useUrlContext();
    const applicationProfileContext = useApplicationProfileContext();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOnClickSignIn = () => {
        authContext.login();
    };

    const handleOnClickSignOut = () => {
        authContext.logout();
        handleCloseMenu();
    };

    const applicationProfileRoute = menuContext.getApplicationRouteById(ApplicationRouteId.Profile);
    const updateUrlStateToApplicationProfileView = (): void => {
        const newUrlState = {
            ...{ 'tab': ApplicationProfileTabs.all }
        }
        const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
        urlContext.pushUrlQuery(urlQuery, applicationProfileRoute.route);
    }

    const settingsRoute = menuContext.getApplicationRouteById(ApplicationRouteId.Settings);
    const updateUrlStateToSettingsView = (): void => {
        urlContext.pushUrlQuery('', settingsRoute.route);
    }

    const handleOnClickProfile = () => {
        updateUrlStateToApplicationProfileView();
        handleCloseMenu();
    };

    const handleOnClickSettings = () => {
        updateUrlStateToSettingsView();
        handleCloseMenu();
    };

    const handleOnClickAccount = () => {
        if (authContext.authSettings) {
            navigateToExternalUrl(authContext.authSettings.accountSettingsUrl ?? '');
        }
        handleCloseMenu();
    };

    const loggedInApplicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser();

    const fileKey = getStorageFileFullKey(loggedInApplicationProfile?.id ?? '');
    const [base64Images, setBase64Images] = useState<string[]>([]);
    const imageSrces = useStoredFilesByFileKey(fileKey, base64Images, setBase64Images)[0];

    return (
        <>
            {authContext.authenticated === false ? (
                <Button disabled={!authContext.authReady} variant="contained" color="primary" onClick={handleOnClickSignIn}>{languageContext.getMessage('signIn')}</Button>
            ) : (
                <>
                    <Button onClick={handleClickMenu}>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            {loggedInApplicationProfile?.name}
                        </Box>
                        <ApplicationProfileAvatar
                            sx={{ marginLeft: '10px', width: '1.5em', height: '1.5em' }}
                            src={imageSrces.length > 0 ? imageSrces[0] : undefined}
                            userName={loggedInApplicationProfile?.name}
                            id={loggedInApplicationProfile?.id}
                        />
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        // getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}>

                        <MenuItem onClick={handleOnClickProfile}>
                            <ListItemIcon>
                                <AccountCircleIcon fontSize="small" />
                            </ListItemIcon>
                            {languageContext.getMessage('profile')}
                        </MenuItem>
                        <MenuItem onClick={handleOnClickAccount}>
                            <ListItemIcon>
                                <ManageAccountsIcon fontSize="small" />
                            </ListItemIcon>
                            {languageContext.getMessage('myAccount')}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleOnClickSettings}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            {languageContext.getMessage('settings')}
                        </MenuItem>
                        <MenuItem onClick={handleOnClickSignOut}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            {languageContext.getMessage('signOut')}
                        </MenuItem>
                    </Menu>
                </>
            )}
        </>
    );
}

export default UserMenu;
