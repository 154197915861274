import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

// material UI staff    
import { Button, Grid, CircularProgress } from '@mui/material';

// contexts
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useInformationContext } from '../../contexts/information/informationContext';
import { useTicketContext } from '../../contexts/ticket/ticketContext';

import { DocumentType, InformationVerification } from '../../contracts/contracts';

import PageContentLayout from '../../component/layouts/PageContentLayout';
import SafetyInformation from '../../component/informationComponents/SafetyInformation';
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from '../../contexts/menu/menuContext';
import { useUrlContext } from '../../contexts/url/urlContext';
import { Dictionary } from '../../global-types';
import { Guid } from '../../utils/common-types';
import { useInformationVerificationContext } from '../../contexts/informationVerification/informationVerificationContext';
import { useApplicationProfileContext } from '../../contexts/applicationProfile/applicationProfileContext';

const SafetyInformationDemoView: React.FC<{}> = () => {
    const { params } = useRouteMatch<any>()
    const [isApproved, setIsApproved] = useState<boolean>(false);
    const { informationId } = params

    const languageContext = useLanguageContext();

    const ticketContext = useTicketContext();
    const informationContext = useInformationContext();
    const informationVerificationContext = useInformationVerificationContext();
    const projectMemberContext = useProjectMemberContext();
    const applicationProfileContext = useApplicationProfileContext();
    const menuContext = useMenuContext();
    const urlContext = useUrlContext();

    const urlState = urlContext.getUrlState();
    const applicationProfileId = urlState.applicationProfileId ?? applicationProfileContext.getApplicationProfileForLoggedInUser()?.id;

    const informationVerificationId: Guid | undefined = urlState.informationVerificationId
    const [demoInformationVerification, setDemoInformationVerification] = useState<InformationVerification>({});

    const applicationProfileSearch = applicationProfileContext.getApplicationProfileSearch();

    const informationSearch = informationContext.getInformationSearch();
    informationSearch.projectId = projectMemberContext.getSelectedProjectMember()?.projectId;

    const informationVerificationSearch = informationVerificationContext.getInformationVerificationSearch();
    informationVerificationSearch.projectId = urlState.selectedProjectId;
    informationVerificationSearch.applicationProfileId = applicationProfileId;

    const information = informationContext.getInformation(informationId)

    const informationLoading = informationContext.loadingInformations
    const isLoading = informationLoading

    const informationVerifications = informationVerificationContext.getInformationVerifications();

    useEffect(() => {
        const index = informationVerifications.findIndex(informationVerification => informationVerification.id === informationVerificationId);
        if (index >= 0) {
            setDemoInformationVerification(informationVerifications[index]);
        }
        else if (demoInformationVerification.id !== undefined) {
            setDemoInformationVerification({});
        }
    }, [informationVerifications])

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([
            DocumentType.INFORMATION,
            DocumentType.INFORMATION_VERIFICATION,
            DocumentType.APPLICATION_PROFILE]);
        informationContext.searchInformations(informationSearch);
        informationVerificationContext.searchInformationVerifications(informationVerificationSearch);
        applicationProfileContext.searchApplicationProfiles(applicationProfileSearch);
    }, [urlContext.currentLocation, informationId, informationVerificationId])

    const updateUrl = (route: ApplicationRoute, urlState?: Dictionary<string | number | Date>) => {
        const urlQuery = urlState ? urlContext.buildUrlQuery({...urlState}) : '';
        urlContext.pushUrlQuery(urlQuery, route.route)
    }

    const onBack = () => {
        if (informationVerificationId) {
            const route = menuContext.getApplicationRouteById(ApplicationRouteId.Diplomas)
            const formattedRoute = { ...route }
            updateUrl(formattedRoute, urlState)
        }
        else if (information?.id) {
            const route = menuContext.getApplicationRouteById(ApplicationRouteId.SafetyInformationDetails)
            const formattedRoute = { ...route, route: route.route.replace(':informationId', information.id) }
            updateUrl(formattedRoute)
        }
    }

    const BottomNavigation = (
        <>
            <Button variant='contained' onClick={onBack}>{languageContext.getMessage('back')}</Button>
            <Button variant='contained' disabled={isLoading || !isApproved} onClick={onBack}>{languageContext.getMessage('approved')}</Button>
        </>
    )

    return (
        <PageContentLayout bottomNavigation={isLoading ? null : BottomNavigation}>
            {
                isLoading ? <Grid container justifyContent='center'><CircularProgress /></Grid> :
                    (
                        <>
                            {information && (
                                <SafetyInformation
                                    key={information.id}
                                    information={information}
                                    informationVerification={demoInformationVerification}
                                    onInformationApproval={(areAnswersCorrect) => {
                                        setIsApproved(areAnswersCorrect);
                                    }} />
                            )}
                        </>
                    )
            }
        </PageContentLayout>
    )
}

export default SafetyInformationDemoView