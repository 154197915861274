import React, { createContext, useContext, useEffect, useState } from "react";
import { ProjectCompany } from "../../../contracts/contracts";
import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/react-hooks';
import { QUERY_PROJECT_COMPANY, getProjectCompaniesGraphqlQueryOptions } from './projectCompanyQueries'
import { useAuthContext } from "../../auth/authContext";

export interface ProjectCompanyQueriesContext {
    fetchedProjectCompanies: Array<ProjectCompany>,
    queryProjectCompanies: (projectCompany: ProjectCompany) => void,
    queryResponse: LazyQueryResult<any, OperationVariables>,
}

const ProjectCompanyQueriesContext = createContext<ProjectCompanyQueriesContext>(null as unknown as ProjectCompanyQueriesContext);

export const ProjectCompanyQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedProjectCompanies, setFetchedProjectCompanies] = useState<Array<ProjectCompany>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_PROJECT_COMPANY);

    const queryProjectCompanies = (projectCompany: ProjectCompany): void => {
        const queryOptions = getProjectCompaniesGraphqlQueryOptions(projectCompany);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.projectCompanies) {
            const newFetchedProjectCompanies: Array<ProjectCompany> = queryResponse.data.projectCompanies;
            setFetchedProjectCompanies(newFetchedProjectCompanies);
        }
    }, [queryResponse.data]);
    
    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedProjectCompanies([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const projectCompanyQueriesContext: ProjectCompanyQueriesContext = {
        fetchedProjectCompanies,
        queryProjectCompanies,
        queryResponse,
    };

    return (
        <ProjectCompanyQueriesContext.Provider value={projectCompanyQueriesContext}>
            {children}
        </ProjectCompanyQueriesContext.Provider>
    );
}

export const useProjectCompanyQueriesContext = (): ProjectCompanyQueriesContext => {
    return useContext(ProjectCompanyQueriesContext);
}
