import { Grid } from '@mui/material';
import React from 'react';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { GeneralDatePicker } from './GeneralDatePicker';
import { DatePickerWithPushAndPop } from './DatePickerWithPushAndPop';

type props = {
  startDate?: Date;
  endDate?: Date;
  monthPicker?: boolean;
  onChange?: (startDate?: Date | undefined, endDate?: Date | undefined) => void;
  onAccept?: (startDate?: Date | undefined, endDate?: Date | undefined) => void;
  onPopStartDate?: () => void;
  onPushStartDate?: () => void;
  onPopEndDate?: () => void;
  onPushEndDate?: () => void;
  popStartDateDisabled?: boolean;
  pushStartDateDisabled?: boolean;
  popEndDateDisabled?: boolean;
  pushEndDateDisabled?: boolean;
  extraComponents?: any;
  center?: boolean;
  readOnly?: boolean;
}

const DateRangePicker: React.FC<props> = ({
  startDate,
  endDate,
  monthPicker,
  onChange,  
  onAccept,
  onPopStartDate, 
  onPushStartDate, 
  onPopEndDate, 
  onPushEndDate, 
  popStartDateDisabled, 
  pushStartDateDisabled, 
  popEndDateDisabled, 
  pushEndDateDisabled,
  extraComponents,
  center,
  readOnly
}) => {

  const languageContext = useLanguageContext();
  
  return (
    <Grid container direction="row" justifyContent={center ? "center" : undefined} spacing={3}>
      <Grid item xs={12} sm="auto" paddingTop={"0 !important"}>
          {!(onPopStartDate || onPushStartDate) && 
            <GeneralDatePicker
              label={languageContext.getMessage('from')}
              date={startDate}
              readOnly={readOnly}
              monthPicker={monthPicker}
              onChange={newStartDate => {
                if (onChange) {
                  onChange(newStartDate, endDate)
                }
              }}
              onAccept={newStartDate => {
                if (onAccept) {
                  onAccept(newStartDate, endDate)
                }
              }} 
              />
          }
          {(onPopStartDate || onPushStartDate) && 
            <DatePickerWithPushAndPop
              label={languageContext.getMessage('from')}
              date={startDate}
              readOnly={readOnly}
              monthPicker={monthPicker}
              onChange={newStartDate => {
                if (onChange) {
                  onChange(newStartDate, endDate)
                }
              }} 
              onAccept={newStartDate => {
                if (onAccept) {
                  onAccept(newStartDate, endDate)
                }
              }} 
              onPopDate={onPopStartDate}
              onPushDate={onPushStartDate}
              popDateDisabled={popStartDateDisabled}
              pushDateDisabled={pushStartDateDisabled}/>
          }
      </Grid>
      <Grid item xs={12} sm="auto" paddingTop={"0 !important"}>
          {!(onPopEndDate || onPushEndDate) && 
            <GeneralDatePicker
              label={languageContext.getMessage('to')}
              date={endDate}
              readOnly={readOnly}
              monthPicker={monthPicker}
              onChange={newEndDate => {
                if (onChange) {
                  onChange(startDate, newEndDate)
                }
              }} 
              onAccept={newEndDate => {
                if (onAccept) {
                  onAccept(startDate, newEndDate)
                }
              }} 
              />
          }
          {(onPopEndDate || onPushEndDate) && 
            <DatePickerWithPushAndPop
              label={languageContext.getMessage('to')}
              date={endDate}
              readOnly={readOnly}
              monthPicker={monthPicker}
              onChange={newEndDate => {
                if (onChange) {
                  onChange(startDate, newEndDate)
                }
              }} 
              onAccept={newEndDate => {
                if (onAccept) {
                  onAccept(startDate, newEndDate)
                }
              }} 
              onPopDate={onPopEndDate}
              onPushDate={onPushEndDate}
              popDateDisabled={popEndDateDisabled}
              pushDateDisabled={pushEndDateDisabled}/>
          }
      </Grid>
      {extraComponents !== undefined && <>{extraComponents}</>}
    </Grid>
  );
}

export default DateRangePicker;