import * as React from 'react';

import { Project } from '../../contracts/contracts';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Divider, Typography, Grid } from '@mui/material';
import ProjectsCard from './ProjectsCard';

type props = {
    projects: Project[];
    onProjectDetails?: (project: Project) => void;
    onJoinProject: (project?: Project) => void,
}

const ProjectsImageList: React.FC<props> = ({
    projects,
    onProjectDetails,
    onJoinProject,
}) => {
    const languageContext = useLanguageContext();

    return (<>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Grid spacing={2} container>
            {projects.map(project => <ProjectsCard onJoinProject={onJoinProject} key={project.id} project={project} onProjectDetails={onProjectDetails} />)}
        </Grid>
    </>);
}

export default ProjectsImageList
