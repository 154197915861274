import { LanguageMap } from "../interfaces";
import { MemberAccess } from "../../../contracts/contracts";

export const plMap: LanguageMap = {
    ok: 'OK',
    in: 'w',
    about: 'dookoła',
    account: 'Account',
    accountType: 'Account Type',
    accountBudget: 'Account budget',
    accountDetails: 'Account details',
    accountGroup: 'Account group',
    accountInvoicedAmount: 'Invoiced amount: Account',
    accountList: 'Accounts',
    accountNumber: 'Account number',
    accountProcurement: 'Account procurement',
    accounts: 'Accounts',
    accountTransactionList: 'Transactions',
    action: 'Akcja',
    accessControl: 'Kontrola dostępu',
    ACTIVE: 'Aktywny',
    publish: 'Publikować',
    publishToMembers: 'Opublikuj dla członków',
    addAccount: 'Add Account',
    addInvoice: 'Add Invoice',
    addContract: 'Add Contract',
    adjustment: 'Adjustment',
    adjustments: 'Adjustments',
    accumulated: 'Accumulated',
    all: 'Wszystko',
    amount: 'Ilość',
    assetFreeDays: 'Asset Free Days',
    assetCondition: 'Asset Condition',
    quantity: 'Quantity',
    qunatityShort: 'QTY',
    analytics: 'Analytics',
    analysis: 'Analysis',
    analyticsView: 'This is analytics',
    and: 'i',
    expectedDate: 'Expected Date',
    answeredDate: 'Answered Date',
    approved_plural: 'Zatwierdzony',
    approved: 'Zatwierdzony',
    approvedCost: 'Approved cost',
    ASSISTANT_PROJECT_MANAGER: 'Assistant project manager',
    accrual: 'Accrual',
    asset: 'Asset',
    assetType: 'Asset Type',
    assets: 'Assets',
    assetQuantity: 'Asset Qty',
    assetDetails: 'Asset Details',
    activation: 'Activation',
    accumulatedAccruedPosted: 'Accumulated Accrued Posted',
    averageProjectCost: 'Average Project Cost',
    receivedRegistered: 'Received - Registered',
    revisedForecastIsAboveAccountForecast: 'Revised forecast is above account forecast',
    revisedForecastIsAboveConstructionAdministrationCosts: 'Revised forecast is above construction administration budget',
    producedNotBilled: 'Produced - Not Billed',
    accumulatedProduced: 'Accumulated Produced Value',
    constructionCosts: 'Construction Costs',
    constructionStart: 'Construction Start',
    administrationCosts: 'Administration Costs',
    producedPercentageOfForecast: 'Produced % of Forecast',
    sumProducedValue: 'Sum Produced Value',
    surcharge: 'surcharge',
    balance: 'Balance',
    billed: 'Billed',
    budget: 'Budget',
    burndown: 'Burndown',
    categories: 'Kategorie',
    cancel: 'Anulować',
    change: 'Reszta',
    changeOrder: 'Change Order',
    changeOrderBilled: 'Cost',
    changeOrderBudget: 'Change order budget',
    changeOrderProcurement: 'Change order procurement',
    changeOrderShort: 'CO',
    changeOrders: 'Change Orders',
    quantityChangeOrders: 'Change Order Quantity',
    accrualAndOperations: 'Accrual And Operations',
    changes: 'Zmiany',
    clear: 'Jasny',
    close: 'Blisko',
    position: 'Position',
    columnActorCount: 'Actors',
    columnName: 'Imię',
    columnOwnerCount: 'Właściciele',
    columnStatus: 'Status',
    condition: 'Condition',
    comment: 'Komentarz',
    comments: 'Uwagi',
    completion: 'Completion',
    company: 'OnSite AS',
    companyRaw: 'Spółka',
    companiesRaw: 'Firmy',
    newCompany: 'Nowa firma',
    editCompany: 'Edytuj firmę',
    noCompany: 'Brak firmy',
    companySite: 'https://onsite.no/',
    workingFor: 'pracować dla',
    employer: 'Pracodawca',
    organizationNumber: 'Numer organizacji',
    followingCompany: 'Following Company',
    followCompany: 'Obserwuj firmę',
    joinCompany: 'Dołącz do firmy',
    companyDetails: 'Szczegóły firmy',
    consequence: 'Consequence',
    booking: 'Booking',
    bookingType: 'Booking Type',
    bookingDetails: 'Booking Details',
    bookings: 'Bookings',
    contract: 'Contract',
    contractDetails: 'Contract Details',
    contractBudget: 'Contract Budget',
    contractor: 'Contractor',
    contractOrChange: 'Contract/Change',
    contractProcurement: 'Contract procurement',
    contracts: 'Contracts',
    contractCosts: 'Contract Costs',
    contractType: 'Contract Type',
    constructionAdministrationBudget: 'Construction Administration Budget',
    cost: 'Cost',
    costs: 'Costs',
    costRequirements: 'Cost Requirements',
    created: 'Utworzony',
    crew: 'Załoga',
    crewList: 'Spis załogi',
    currentLanguage: 'język angielski',
    confirm: 'Potwierdzać',
    dark: 'Ciemny',
    date: 'Data',
    days: 'Dni',
    dailyStorage: 'Daily Storage',
    delete: 'Usunąć',
    deliveryDate: 'Delivery Date',
    description: 'Opis',
    detailed: 'Szczegółowe',
    details: 'Detale',
    scannedDate: 'Scanned Date',
    voucherDate: 'Voucher Date',
    doYouWantToDeleteProject: 'Czy chcesz usunąć projekt?',
    doYouWantToDeleteCompany: 'Czy chcesz usunąć firmę?',
    doYouWantToDeleteAsset: 'Do you want to delete the asset?',
    doYouWantToDeleteContainer: 'Do you want to delete the container?',
    doYouWantToDeleteContract: 'Do you want to delete the contract?',
    doYouWantToDeleteProcurement: 'Do you want to delete the procurement?',
    doYouWantToDeleteDisposal: 'Do you want to delete the disposal?',
    doYouWantToDeleteBooking: 'Czy chcesz usunąć rezerwację?',
    doYouWantToDeleteElement: 'Czy chcesz usunąć element?',
    doYouWantToSaveProject: 'Chcesz zapisać projekt?',
    doYouWantToSaveCompany: 'Chcesz uratować firmę?',
    doYouWantToSaveAsset: 'Do you want to save the asset?',
    doYouWantToSaveContainer: 'Do you want to save the container?',
    doYouWantToSaveContract: 'Do you want to save the contract?',
    doYouWantToSaveProcurement: 'Do you want to save the procurement?',
    doYouWantToSaveDisposal: 'Do you want to save the disposal?',
    doYouWantToSaveBooking: 'Czy chcesz zapisać rezerwację?',
    doYouWantToSaveElement: 'Chcesz ocalić element?',
    doYouWantToSaveSettings: 'Czy chcesz zapisać ustawienia?',
    dueDate: 'Due Date',
    interimAccountedDate: 'Accounted date',
    interimAccountedAmount: 'Accounted amount',
    email: 'Email',
    edit: 'Edytować',
    enableProgressConsequence: 'Enable progress consequence',
    energy: 'energy',
    externalName: 'External name',
    expectation: 'Expectation',
    enabled: 'Enabled',
    projects: 'Projektowanie',
    finished: 'Finished',
    final: 'Final',
    finalForecast: 'Final Forecast',
    forecastReport: 'Forecast Report',
    forecast: 'Forecast',
    forecasts: 'Forecasts',
    changesInForecastLastMonth: 'Changes in forecast from last month',
    from: 'od',
    fromDate: 'Od daty',
    group: 'Grupa',
    general: 'Ogólny',
    global: 'Światowy',
    handling: 'Obsługiwanie',
    hello: 'cześć',
    home: 'Dom',
    homeView: 'To jest dom',
    HSE_MANAGER: 'HSE manager',
    id: 'Id',
    INACTIVE: 'inactive',
    info: 'Info',
    interim: 'Interim',
    incoming: 'Incoming',
    includeContractProcurementInForecast: "Use contract procurement in forecast?",
    warningContractProcurementIsLargerThenBudget: "Contract procurement is larger then contract budget, and will be used to estimate the forecast.",
    invoice: 'Invoice',
    invoiceComplete: 'Complete',
    invoicedAmount: 'Invoiced amount',
    invoicedAmountIsGreaterThanProcurment: 'Invoiced amount is greater than procurement',
    invoiceIsPartOfAnotherInvoice: 'Invoice is part of another invoice',
    invoiceIsSplitIntoMultipleInvoicesAndCanNotBeEdited: 'Invoice is split into multiple invoices and can not be edited',
    invoiceMissingComment: 'Niekompletny',
    invoiceNumber: 'Invoice number',
    invoices: 'Invoices',
    importAccounts: 'Import accounts',
    ITB_MANAGER: 'ITB responsible',
    landlord: 'Landlord',
    landlordShort: 'Landlord',
    language: 'Język',
    languages: 'Języki',
    mainLanguage: 'Główny język',
    last: 'Trwać',
    light: 'Lekki',
    list: 'List',
    listHeading: 'Case List',
    listView: 'This is list',
    inTotal: 'In total',
    login: 'Zaloguj się',
    loginWith: 'Zaloguj się za pomocą',
    loginWithEmail: 'Zaloguj się za pomocą adresu e-mail',
    logout: 'Wyloguj się',
    management: 'Kierownictwo',
    max: 'Max',
    missingComment: 'Brak komentarza',
    month: 'Month',
    monthly: 'Monthly',
    monthlySalary: 'Monthly Salary Costs',
    monthlyPercentage: 'Monthly Percentage',
    aMonth: 'Miesiąc',
    name: 'Imię',
    netAccountBudget: 'Net Account Budget',
    networkError: 'Błąd sieci: nie można skontaktować się z serwerem',
    projectType: 'typ projektu',
    project: 'Projekt',
    projectDetails: 'Szczegóły Projektu',
    projectDepot: 'Depot',
    projectDepotDetails: 'Depot Details',
    projectCustomerLocation: 'Customer Location',
    projectCustomerLocationDetails: 'Customer Location Details',
    new: 'Nowy',
    newProject: 'Nowy projekt',
    newProjectDepot: 'New Depot',
    newProjectCustomerLocation: 'New Customer Location',
    newAsset: 'New Asset',
    newContract: 'New contract',
    newProcurement: 'New Procurement',
    newDisposal: 'New Disposal',
    newBooking: 'New booking',
    editProject: 'Edit Project',
    editProjectDepot: 'Edit Depot',
    editProjectCustomerLocation: 'Edit Customer Location',
    editAsset: 'Edit Asset',
    editContract: 'Edit contract',
    editProcurement: 'Edit Procurement',
    editDisposal: 'Edit Disposal',
    editBooking: 'Edit booking',
    none: 'Nic',
    showNonInterimAccountedInvoices: "Non processed invoices exists!",
    showInterimAccountedInvoices: "Show processed invoices",
    notApproved: 'Niezatwierdzony',
    notProcessed: 'Nieprzetworzony',
    notSelected: 'Nie zaznaczone',
    notSpecified: 'Nieokreślony',
    selectProject: 'Wybierz projekt',
    selectTenant: 'Wpisz swoją organizację',
    selectLanguage: 'Wybierz język',
    wrongTenant: 'Organizacja nie istnieje!',
    reader: 'Czytelnik',
    writer: 'Pisarz',
    admin: 'Admin',
    owner: 'Właściciel',
    normal: 'Normalna',
    OPPORTUNITY: 'Opportunity',
    offHire: 'Offhire',
    opportunityAdjustments: 'Opportunity Adjustments',
    outgoing: 'Outgoing',
    overview: 'Overview',
    payments: 'Payments',
    pickupDate: 'Pickup Date',
    probability: 'Probability',
    PROBABILITY: 'Probability',
    procurement: 'Procurement',
    procurements: 'Procurements',
    productName: 'OnSite',
    profile: 'Profil',
    projectInformation: 'Informacje o projekcie',
    projectList: 'Projektowanie',
    projectStart: 'Project start',
    projectEnd: 'Project end',
    projectName: 'Project name',
    projectNumber: 'Project number',
    projectCode: 'Project code',
    projectCodes: 'Project codes',
    accountCode: 'Account code',
    accountCodes: 'Account codes',
    filename: 'Nazwa pliku',
    property: 'Property',
    progressConsequence: 'Progress Consequence',
    per: 'per',
    percentage: 'Percentage',
    percentShare: 'Percent Share',
    receivedDate: 'Received Date',
    sentDate: 'Sent Date',
    remainingAccountBudget: 'Remaining account budget',
    remainingAmount: 'Remaining amount',
    remainingCost: 'Remaining billed',
    remainingContractBudget: 'Remaining contract budget',
    remainingContractProcurement: 'Remainig Contract procurement',
    remainingProcurement: 'Remaining procurement',
    repairCost: 'Repair Cost',
    reset: 'Resetowanie',
    revised: 'Revised',
    revisedForecast: 'Revised Forecast',
    revisedAccountBudget: 'Revised Account Budget',
    RISK: 'Risk',
    riskAdjustments: 'Risk Adjustments',
    role: 'Role',
    salary: 'Salary',
    save: 'Zapisać',
    saveInvoice: 'Save Invoice',
    settings: 'Ustawienia',
    signedInAs: 'Zalogowany jako:',
    signIn: 'Zaloguj się',
    signOut: 'Wyloguj się',
    security: 'Bezpieczeństwo',
    myAccount: 'Rachunek',
    SITE_MANAGER: 'Site manager',
    splitInvoice: 'Split invoice',
    starting: 'Starting',
    state: 'State',
    level: 'Poziom',
    mainContractor: 'Główny wykonawca',
    mainContractorShort: 'Główny wykonawca',
    mainContractorCosts: 'Koszty głównego wykonawcy',
    subContractor: 'Poddostawca',
    subContractorShort: 'Poddostawca',
    subContractorCosts: 'Koszty podwykonawcy',
    subProjects: 'Sub projects',
    sum: 'Sum',
    sumChangeOrderCost: 'Cost: change order',
    sumChangeOrderInvoicedAmount: 'Invoiced amount: change order',
    sumContractBudget: 'Sum contract budget',
    sumContractInvoicedAmount: 'Invoiced amount: contract',
    sumContractProcurement: 'Sum contract procurement',
    sumChangeOrderRemainingCost: 'Remaining invoice amount: change order',
    sumRemainingContractBudget: 'Sum remaining contract budget',
    contractCostScope: 'Total contract procurement',
    sumExpenseForecasts: 'Sum expenses',
    sumContractCostScope: 'Sum contract cost scope',
    IncurredCosts: 'Incurred costs',
    simulating: 'Simulating',
    totalSurcharge: 'Total Surcharge',
    supplier: 'Supplier',
    theme: 'Theme',
    this: 'This',
    time: 'Czas',
    title: 'Tytuł',
    to: 'To',
    toDate: 'To date',
    today: 'Today',
    toggle: 'Toggle',
    totalCosts: 'Total costs',
    transactions: 'Transactions',
    type: 'Type',
    uploadImage: 'Upload Image',
    uploadContract: 'Upload Contract',
    downloadImage: 'Download Image',
    downloadContract: 'Download Contract',
    uncertainty: 'Uncertainty',
    certificate: 'Certyfikat',
    certificateInputHelperText: 'Wpisz tytuł certyfikatu lub wybierz tytuł z menu rozwijanego.',
    certificateListInputHelperText: 'Kliknij poniższy przycisk, aby dodać certyfikaty kwalifikacji.',
    certificateListInputHelperTextHseCard: 'Kliknij przycisk, aby dodać kartę HSE',
    unkown: 'Unkown',
    unprocessed: 'Incomplete',
    unforeseen: 'Unforeseen',
    selectEmployerCompanyHelperText: 'Zacznij pisać, aby znaleźć firmę, dla której pracujesz.',
    selectWorkingCompanyHelperText: 'Zacznij pisać, aby znaleźć firmę, dla której pracuje Twój pracodawca.',
    yourEmployerWorksOnBehalfOf: 'Twój pracodawca wykonuje pracę w imieniu',
    adjustmentsRisksAndUnforeseen: 'Unforeseen and Adjustments',
    up: 'Up',
    expenseItem: 'Expense Item',
    remainsToDistribute: 'Remains to distribute',
    turntime: 'Turntime',
    triangulation: 'Triangulation',
    newExpectedChange: 'New Expected Change',
    expectedChange: 'Expected Change',
    expectedPostedExpense: 'Expected posted expense',
    expectedAccumulatedPostedExpense: 'Expected accumulated posted expense',
    actuallyPostedExpense: 'Actually posted expense',
    actuallyAccumulatedPostedExpense: 'Actually accumulated posted expense',
    usedWithForecast: 'Used with forecast',
    useRemainingAmount: 'Use remaining amount',
    username: 'Nazwa użytkownika',
    value: 'Value',
    variance: 'Variance',
    week: 'Week',
    with: 'with',
    yes: 'TAk',
    yesterday: 'Yesterday',
    next: 'Następny',
    back: 'Plecy',
    region: 'Region',
    country: 'Country',
    city: 'City',
    postcode: 'Postcode',
    area: 'Area',
    location: 'Location',
    address: 'Address',
    street: 'Street',
    contact: 'Contact',
    capabilities: 'Capabilities',
    code: 'Code',
    disposal: 'Disposal',
    disposals: 'Disposals',
    dropOffCharges: 'Drop Off Charges',
    lessorDetails: 'Lessor Details',
    contractReference: 'Contract Reference',
    reference: 'Reference',
    quota: 'Quota',
    onHireQuota: 'On Hire Quota',
    offHireQuota: 'Off Hire Quota',
    prefixSeries: 'Prefix Series',
    discountedResidual: 'Discounted Residual',
    isoCode: 'Iso Code',
    specifications: 'Specifications',
    onHireReference: 'On Hire Reference',
    perDiemRate: 'Per Diem Rate',
    damageProtectionPlan: 'Damage Protection Plan',
    containerNumber: 'Container Number',
    containerNumbers: 'Container Numbers',
    containerFlow: 'Container Flow',
    containerBookingFlow: 'Container Booking Flow',
    uploadContainerNumbers: 'Upload Container Numbers',
    downloadContainerNumbers: 'Download Container Numbers',
    fleetComposition: 'Fleet Composition',
    acceptance: 'Acceptance',
    csc: 'CSC',
    swap: 'SWAP',
    bookingNumber: 'Booking Number',
    origin: 'Origin',
    destination: 'Destination',
    customer: 'Customer',
    emptyMovement: 'Empty Movement',
    maintenanceAndRepair: 'Maintenance And Repair',
    container: 'Container',
    assign: 'Assign',
    release: 'Release',
    complete: 'Complete',
    cleanse: 'Cleanse',
    issueProcurement: 'Issue Procurement',
    issueDisposal: 'Issue Disposal',
    QUANTITIES: 'Quantities',
    PROJECT_CHIEF: 'Project Leader',
    PROJECT_MANAGER: 'Project Manager',
    PROJECT_MANAGER_ASSISTANT: 'Project Manager Assistant',
    CONSTRUCTION_MANAGER: 'Construction Manager',
    OPERATIONS_MANAGER: 'Operations Manager',
    PROJECT_SECRETARY: 'Project Secretary',
    ENVIRONMENTAL_MANAGER: 'Environmental Manager',
    HEALTH_SAFETY_AND_ENVIRONMENT_LEADER: 'HSE-leader',
    ENGINEERING_MANAGER: 'Engineering Manager',
    TECHNICAL_ENGINEERING_MANAGER: 'Technical Engineering Leader',
    INTEGRATED_TECHNICAL_BUILDING_INSTALLATIONS_RESPONSIBLE: 'ITB-responsible',
    CONTROLLER: 'Controller',
    PURCHASER: 'Purchaser',
    OTHER: 'Inny',
    NEW_BOOKING:'New Booking',
    ASSIGN_ASSETS:'Assign Containers',
    ASSETS_ASSIGNED:'Containers Assigned',
    NO_ASSETS_ASSIGNED: 'No Containers Assigned',
    ASSETS_PARTLY_ASSIGNED:'Containers Partly Assigned',
    GATE_OUT:'Gate Out',
    BOOKING_COMPLETE:'Booking Complete',
    bookingSource: 'Booking Source',
    bookingTimeline: 'Booking Timeline',
    maxGrossWeight: 'Max Gross Weight',
    assetStatus: 'Container Status',
    ON_ASSIGNMENT: 'On Assignment',
    IN_DEPOT: 'In Depot',
    MAINTENANCE_AND_REPAIR:'Maintenance and Repair',
    availableDate: 'Next Available',
    currentLocation: 'Current Location',
    recentBookings: 'Recent Bookings',
    containerStatus: 'Container Status',
    DEPOT: 'Depot',
    TERMINAL: 'Terminal',
    SHIPPERS_POOL: 'Shippers Pool',
    TRIANGULATION: 'Triangulation',
    CUSTOMER_LOCATION: 'Customer Location',
    containers: 'Containers',
    containerDetails: 'Container Details',
    assignedContainers: 'Assigned Containers',
    newContainer: 'New Container',
    editContainer: 'Edit Container',
    newQuota: 'New Quota',
    PAPER_GRADE: 'Paper Grade',
    CARGO_WORTHY: 'Cargo Worthy',
    FOOD_GRADE: 'Food Grade',
    containerForecast: 'Container Forecast',
    connectedProjects: 'Connected Projects',
    coordinates: 'Coordinates',
    latitude: 'Latitude',
    longitude: 'Longitude',
    ASSIGNED: 'Assigned',
    UNCHECKED: 'Unchecked',
    IN_TRANSIT_FULL: 'In Transit Full',
    IN_TRANSIT_EMPTY: 'In Transit Empty',
    IN_REPAIR: 'In Repair',
    READY_FOR_CARGO: 'Ready For Cargo',
    currency: 'Currency',
    exchangeRate: 'Exchange Rate',
    mainCurrency: 'Main Currency',
    lifetimeCostGeneration: 'Lifetime Cost Generation',
    lifetimeValueGeneration: 'Lifetime Value Generation',
    myInfo: 'Moje informacje',
    myProjects: 'Moje projekty',
    allProjects: 'Wszystkie projekty',
    certificates: 'Certyfikaty',
    diplomas: 'Dyplomy',
    phoneNumber: 'Numer telefonu',
    relativePhoneNumber: 'Numer telefonu Krewnego',
    relativeName: 'Imię Krewnego',
    managerPhoneNumber: 'Numer telefonu kierownika',
    managerName: 'Nazwa menedżera',
    welcomeProject: 'Witamy w',
    registerInformation: 'Rejestracja informacji',
    safetyInstructions: 'Instrukcja bezpieczeństwa',
    safetyAgreement: 'Umowa bezpieczeństwa',
    safetyVideo: 'Wideo bezpieczeństwa',
    videoUrl: 'URL wideo',
    theVideoMustBeHostedOn: 'Film musi być hostowany w dniu ',
    newSafetyInformation: 'Nowe informacje dotyczące bezpieczeństwa',
    watchSafetyInformations: 'Zobacz informacje',
    informationHasBeenSavedInfo: 'Twoja informacja została zapisana. Następnym krokiem jest zapoznanie się z niezbędnymi informacjami dotyczącymi bezpieczeństwa, aby zostać członkiem projektu.',
    informationHasBeenSeenInfoPart: 'Gratulacje! Przeszedłeś przez niezbędne informacje dotyczące bezpieczeństwa, aby zostać członkiem projektu.',
    informationHasBeenSeenInfoPartWithQuiz: 'Gratulacje! Poprawnie odpowiedziałeś na {0} z {1} pytań i zapoznałeś się z niezbędnymi informacjami dotyczącymi bezpieczeństwa, aby zostać członkiem projektu.',
    contactAdministrationToRegisterHmsCard: 'Skontaktuj się z administracją budowy, aby zarejestrować swoją kartę HMS. \n\nDo zobaczenia!',
    contactAdministrationForMoreInformation: 'Aby uzyskać więcej informacji, skontaktuj się z administracją projektu.',
    youHadXOutOfQuestionsCorrect: 'Poprawnie odpowiedziałeś na {0} z {1} pytań{2}',
    informationApproval: 'Aprobata',
    toTheProject: 'Do projektu',
    register: 'Zarejestrować',
    search: 'Szukaj',
    signedInAsProjectMember: 'Zalogowałeś się jako członek projektu.',
    goThroughSteps: 'Cześć! \n\nZanim zostaniesz członkiem {0}, musisz zarejestrować informacje o sobie, a także zapoznać się z niezbędnymi instrukcjami bezpieczeństwa dotyczącymi projektu.',
    goThroughStepsWithQuizInfo: 'Zdrowie, środowisko i bezpieczeństwo są dla nas najwyższym priorytetem! Dlatego wszyscy nowi członkowie projektu muszą zdać quiz oparty na instrukcjach bezpieczeństwa. Nie spiesz się i zwracaj szczególną uwagę na przedstawiane Ci informacje. Aby zaliczyć quiz, musisz mieć {0} z {1} poprawnych odpowiedzi. \n\nPowodzenia!',
    hseCardNumberNotFound: 'Nie znaleziono numeru karty HSE',
    hseCard: 'Karta bezpieczeństwa',
    hseCardNumber: 'Numer karty bezpieczeństwa',
    hseCardNotAdded: 'Karta HSE nie została dodana',
    hseCardNotAddedDescription: "Nie przesłano żadnej karty HSE. Jeśli otrzymałeś potwierdzenie zamówienia karty HSE, naciśnij anuluj i prześlij jej zdjęcie. Niniejszym potwierdzam, że poinformuję kierownictwo obiektu, że nie posiadam obecnie żadnej karty HSE.",
    mandatoryHseCardNotAddedDescription: "Załadowanie karty HMS jest obowiązkowe. Jeżeli otrzymałeś potwierdzenie zamówienia karty HMS, naciśnij przycisk Potwierdź i prześlij zdjęcie potwierdzenia.",
    certificateNotAdded: 'Certyfikat nie został dodany',
    certificateNotAddedDescription: 'Nie przesłano żadnych certyfikatów. Niniejszym potwierdzam, że będę wykonywać pracę niewymagającą żadnych zaświadczeń ani kwalifikacji.',
    mandatoryCertificateNotAddedDescription: 'Przesłanie certyfikatów jest obowiązkowe.',
    uploadPictureOfFrontHseCard: "Upload picture of the HSE card's front side.",
    uploadPictureOfBackHseCard: "Upload picture of the HSE card's back side.",
    uploadPictureOfFrontAndBackHseCard: 'Prześlij zdjęcie przedniej i tylnej strony karty bezpieczeństwa.',
    uploadPictureOfFrontCertificateCard: 'Prześlij zdjęcie przedniej strony certyfikatu.',
    uploadPictureOfBackCertificateCard: 'Prześlij zdjęcie tylnej strony certyfikatów.',
    uploadPictureOfFrontAndBackCertificateCard: 'Prześlij zdjęcie atestów z przodu iz tyłu.',
    joinProject: 'Przystąp',
    followingProject: 'Następny',
    followProject: 'Podążać',
    unfollowProject: 'Przestań obserwować',
    newsFeed: 'kanał informacyjny',
    feed: 'informacyjny',
    message: 'Wiadomość',
    messages: 'Wiadomości',
    safetyInformation: 'Informacje dotyczące bezpieczeństwa',
    safetyInformationInMenu: 'Informacje dotyczące bezpieczeństwa',
    member: 'Członek',
    membersRaw: 'Członkowie',
    membersFound: 'członków znalezionych',
    pendingApproval: 'Oczekuje na zatwierdzenie',
    [MemberAccess.REQUESTING_MEMBER_ACCESS]: 'Poproś o dostęp',
    [MemberAccess.PUBLIC]: 'Publiczny',
    [MemberAccess.MEMBER]: 'Członek',
    approve: 'Zatwierdzić',
    deny: 'Zaprzeczyć',
    denied: 'Odmówiono',
    newNewsFeed: 'Nowa wiadomość',
    editNewsFeed: 'Edytuj wiadomość',
    doYouWantToDeleteNewsFeed: 'Czy chcesz zapisać kanał wiadomości?',
    doYouWantToSaveNewsFeed: 'Do you want to save news feed',
    doYouWantToDeleteProjectMember: "Czy chcesz anulować członkostwo w projekcie?",
    doYouWantToDeleteSafetyInformation: 'Czy chcesz usunąć informacje dotyczące bezpieczeństwa?',
    doYouWantToDeleteHseCard: 'Czy na pewno chcesz usunąć kartę bezpieczeństwa?',
    doYouWantToDeleteCertificate: 'Czy na pewno chcesz usunąć certyfikat?',
    add: "Dodaj",
    emptyInformationVerifications: "Nie masz jeszcze żadnych dyplomów!",
    newsFeedAccessLevel: 'Poziom dostępu',
    newsFeedAccessLevelPublic: 'Publiczny',
    newsFeedAccessLevelPrivate: 'Prywatny',
    inactive: 'Nieaktywny',
    preview: 'Zapowiedź',
    hitEnter: 'Wciśnij Enter',
    show: 'Pokazać',
    hide: 'Ukrywać',
    myself: 'Ja',
    notification: 'Powiadomienie',
    noSafetyInformationAvailable: 'Nie ma jeszcze informacji o bezpieczeństwie dla tego projektu, spróbuj wrócić później lub poproś administratora o pomoc.',
    markdownSupported: 'Obsługiwane przeceny!',
    accessDenied: 'Brak dostępu',
    videoFailedPlayingWithErrorMessage: 'Nie udało się odtworzyć filmu z komunikatem o błędzie',
    clickToUploadImage: 'Kliknij tutaj, aby przesłać zdjęcie',
    addAttachment: 'Dodać załącznik',
    hseCardMissingTooltip: 'Brak karty HSE',
    failedSafetyInformationQuizTooltip: 'Informacje dotyczące bezpieczeństwa nie powiodły się',
    certificateMissingTooltip: 'Brak certyfikatu',
    manualUserTooltip: 'Ten użytkownik jest tworzony ręcznie',
    addNewMember: 'Nowy członek',
    companyFieldRequired: 'Pole firmy jest wymagane',
    nameFieldRequired: 'Pole nazwy jest wymagane',
    fileSizeError: 'Rozmiar pliku musi być mniejszy niż 100 MB.',
    fileDeletedMessage: 'Plik został pomyślnie usunięty',
    cardScanSuccessMessage: 'Pomyślnie zeskanowałeś swoją kartę HSE',
    cameraNotAvailable: 'Odmowa dostępu do aparatu lub brak uprawnień',
    hseCardScanner: 'Skaner kart HSE',
    failedToScan: 'Skanowanie nie powiodło się?',
    cardNumber: 'Numer karty',
    cardIsActiveTooltip: 'Karta HSE jest aktywna',
    cardIsUnactiveTooltip: 'Karta HSE jest nieaktywna',
    enableHseCardScanner: 'Włącz skaner kart HSE',
    addHseCardImageMessage: 'Kliknij przycisk poniżej, aby dodać kartę HSE.',
    instructionReadAttentively: 'Prosimy o uważne przeczytanie instrukcji.',
    hseCardNumberInputInstructionTitle: 'Instrukcja dotycząca numeru karty HSE',
    hseCardNumberInputInstructionExplanation1: 'Znajdź numer karty w lewym górnym rogu karty HSE.',
    hseCardScanningInstructionTitle: 'Instrukcja skanowania karty HSE',
    scanningInstructionExplanation1: 'Zrób zdjęcie kodu QR na swojej karcie HSE lub prześlij zdjęcie swojego kodu QR.',
    scanningInstructionExplanation2: 'Pamiętaj, aby zrobić zdjęcie TYLKO kodu QR. Możesz też wybrać zdjęcie swojego kodu QR z galerii.',
    scanningInstructionExplanation3: 'Zdjęcie kodu QR musi być dobrej jakości. Nie rozmyte!',
    example: 'Przykład',
    startScanningProcess: 'Rozpocznij proces skanowania',
    iHaveReadIntructions: 'Przeczytałem instrukcje i jestem gotowy do przesłania mojego kodu QR.',
    advancedSettings: 'Zaawansowane ustawienia',
    phoneIsRequired: 'Pole Numer telefonu jest wymagane',
    relativesIsRequired: 'Pole krewni jest wymagane',
    hseIsRequired: 'Pole Numer karty HSE jest wymagane',
    hsePictureIsRequired: 'Obowiązkowe przesłanie karty HMS',
    certificatePictureIsRequired: 'Obowiązkowe przesłanie certyfikatu',
    enableParentCompany: 'Pracownicy przekazują informacje o swoich pracodawcach zleceniobiorcy.',
    noHseCardUploaded: 'Nie załadowałeś przedniej/tylnej strony swojej karty HSE! Czy na pewno chcesz kontynuować?',
    noCertificateUploaded: 'Nie załadowałeś przedniej/tylnej strony swojego certyfikatu! Czy na pewno chcesz kontynuować?',
    doYouWantToProceed: 'Czy chcesz kontynuować?',
    clickHere: 'Kliknij tutaj',
    registerHere: 'Rejestracja tutaj',
    toUpload: 'przesłać',
    backSide: 'tylna strona',
    frontSide: 'przednia strona',
    ofHseCard: 'swojej karty HSE',
    ofCertificate: 'swojego certyfikatu',
    duplicateInformationApprovalWarning: 'Duplikaty zatwierdzeń nie są obsługiwane.',
    newUser: 'Nowy użytkownik',
    existingUser: 'Istniejący użytkownik',
    downloadUserProfileInfo: 'Pobierz informacje o profilu',
    memberInvitedMessage: 'Członek został zaproszony',
    inviteMember: 'Zaproś członka',
    emailFieldRequired: 'Email jest wymagany',
    invite: 'Zapraszać',
    uploadedByProjectAdministrator: 'Przesłane przez administratora projektu.',
    unableToScanQrCode: "Nie można zeskanować kodu QR z dostarczonego zdjęcia. Postaraj się zapewnić lepszą jakość obrazu kodu QR, upewnij się też, że nie jest rozmyty i kod QR jest wystarczająco duży!",
    quiz: 'Kartkówka',
    quizSettings: 'Ustawienia quizu',
    deniedAcccessToProject: 'Odmówiono Ci dostępu do projektu.',
    projectMemberFailedTheSafetyInformationQuestions: 'Masz {0} z {1} poprawnych odpowiedzi w quizie, a aby zaliczyć quiz, musisz mieć {2} z {3} poprawnych odpowiedzi. Niestety, nie zdałeś egzaminu, aby zostać członkiem projektu.',
    youHaveXTriesLeft: 'Pozostało Ci {0} prób.',
    youHaveUsedAllOfYourQuizTries: ', i wykorzystałeś wszystkie swoje próby zdania quizu.',
    tryAgain: 'Spróbuj ponownie',
    maxQuizTries: 'Maksymalna liczba prób',
    minQuizPercentage: 'Minimalny procent poprawnych odpowiedzi',
    infoTech: 'InfoTech',
    apiKey: 'Klucz API',
    accessGroup: 'Grupa dostępu',
    registeredAccessGroup: 'Zarejestrowana grupa dostępu',
    autoApproveProjectMembers: 'Automatyczne zatwierdzanie członków projektu',
    deleteAttachedFile: 'Usuń załączony plik',
    areYouSureYouWantToDeleteFile: 'Czy na pewno chcesz usunąć plik {0}?',
    checkIn: 'Zamelduj się',
    checkOut: 'Wymelduj się',
    checkedIn: 'Zakwaterowany',
    checkedOut: 'Wyrejestrowany',
    copiedToClipboard: 'Skopiowano do schowka',
    enableCheckIn: 'Włącz zameldowanie',
    showPresence: 'Pokaż obecność',
    presence: 'Obecność',
    invalid: 'Nieprawidłowy',
    verifying: 'Weryfikacja',
    notes: 'Notatki',
    unknownCompany: 'Nieznana firma',
    hseCardIsRevokedOrInvalid: 'Karta HSE została unieważniona lub nieważna.',
};