import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { styled } from '@mui/material/styles';

import HSECardNumberExampleImage from "../../data/pictures/hse_card_number_example.jpeg"
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useEffect, useState } from "react";
import { useQueryHSECardInfo } from "../../hooks/useQueryHSECardInfo";
import { HSECardInfo } from "../../contracts/contracts";

type Props = {
    open: boolean
    openAsCheckingHSECardNumber: boolean;
    onClose: (fetchedHSECardInfo: HSECardInfo | undefined) => void
    hseCardNumber: string | undefined;
}

const HseCardNumberInputInstructions = (props: Props) => {
    const { open, openAsCheckingHSECardNumber, onClose, hseCardNumber } = props
    const [editedHseCardNumber, setEditedHseCardNumber] = useState<string | undefined>(undefined);
    const [scannedHSECardId, setScannedHSECardId] = useState<string | undefined>(undefined);
    const [initialFetchedHSECardInfo, setInitialFetchedHSECardInfo] = useState<HSECardInfo | undefined>(undefined);
    const [fetchedHSECardInfo, loadingHSECardInfo] = useQueryHSECardInfo(scannedHSECardId, undefined, initialFetchedHSECardInfo);

    const languageContext = useLanguageContext()

    const hseCardNumberNotSet = !editedHseCardNumber || editedHseCardNumber.trim().length === 0;
    const hseCardIsRevokedOrInvalid = !fetchedHSECardInfo.isActive && fetchedHSECardInfo.isRevoked;

    let errorHelperText = fetchedHSECardInfo.error ? fetchedHSECardInfo.errorInfo : undefined;
    if (errorHelperText === undefined && hseCardIsRevokedOrInvalid) {
        errorHelperText = languageContext.getMessage('hseCardIsRevokedOrInvalid');
    }

    useEffect(() => {
        if (openAsCheckingHSECardNumber) {
            if (hseCardNumber && hseCardNumber.trim().length > 0) {
                setScannedHSECardId(hseCardNumber);
            }
            else {
                onClose(undefined);
            }
        }
    }, [openAsCheckingHSECardNumber])

    useEffect(() => {
        if (open) {
            setEditedHseCardNumber(hseCardNumber);
        }
    }, [hseCardNumber, open]);

    useEffect(() => {
        const hseCardNumberIsInvalid = fetchedHSECardInfo.error || hseCardIsRevokedOrInvalid;
        const openAsCheckingHSECardNumberVerification = (fetchedHSECardInfo.error || hseCardNumberIsInvalid) && openAsCheckingHSECardNumber;
        const hseCardNumberIsValid = fetchedHSECardInfo.cardNumber && !hseCardNumberIsInvalid;
        if ((open || openAsCheckingHSECardNumber) &&
            !loadingHSECardInfo &&
            (openAsCheckingHSECardNumberVerification || hseCardNumberIsValid) &&
            scannedHSECardId &&
            scannedHSECardId.trim().length > 0) {
            setScannedHSECardId(undefined);
            onClose({ ...fetchedHSECardInfo });
            setInitialFetchedHSECardInfo({});
        }
    }, [onClose,
        fetchedHSECardInfo,
        fetchedHSECardInfo.cardNumber,
        loadingHSECardInfo,
        scannedHSECardId,
        open,
        openAsCheckingHSECardNumber, 
        hseCardIsRevokedOrInvalid]);

    return (
        <Dialog
            open={open && !openAsCheckingHSECardNumber}
            onClose={() => onClose(undefined)}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle id="form-dialog-title">{languageContext.getMessage('hseCardNumberInputInstructionTitle')}</DialogTitle>
            <DialogContentStyled>
                <InstructionContentWrapper>
                    <p><b>{languageContext.getMessage('instructionReadAttentively')}</b></p>
                    <p>
                        {languageContext.getMessage('hseCardNumberInputInstructionExplanation1')}
                    </p>
                    <img src={HSECardNumberExampleImage} alt="Hse card number image example" />
                    <TextField
                        style={{ marginTop: '1em' }}
                        label={languageContext.getMessage('hseCardNumber')}
                        variant="outlined"
                        value={editedHseCardNumber ?? ''}
                        disabled={loadingHSECardInfo}
                        required={true}
                        onChange={(event) => {
                            const newEditedHseCardNumber = event.target.value as string;
                            setEditedHseCardNumber(newEditedHseCardNumber);
                        }}
                        fullWidth
                        error={hseCardNumberNotSet || hseCardIsRevokedOrInvalid || fetchedHSECardInfo.error}
                        helperText={errorHelperText}
                    />
                </InstructionContentWrapper>
            </DialogContentStyled>
            <DialogActions>
                <Container sx={{ display: 'flex', justifyContent: 'space-between', flexBasis: 'unset', padding: 0 }}>
                    <Button variant="contained"
                        disabled={loadingHSECardInfo}
                        onClick={() => onClose(undefined)}>
                        {languageContext.getMessage('cancel')}
                    </Button>
                    <Button variant="contained"
                        disabled={loadingHSECardInfo}
                        onClick={() => {
                            setScannedHSECardId(undefined);
                            onClose({ cardNumber: '' });
                            setInitialFetchedHSECardInfo({});
                        }}>
                        {languageContext.getMessage('reset')}
                    </Button>
                    <Button variant="contained"
                        disabled={hseCardNumberNotSet || loadingHSECardInfo}
                        onClick={() => {
                            setInitialFetchedHSECardInfo({});
                            setScannedHSECardId(editedHseCardNumber)
                        }}>
                        {languageContext.getMessage('confirm')}
                    </Button>
                </Container>
            </DialogActions>
        </Dialog>
    )
}

const DialogContentStyled = styled(DialogContent)`
    height: 54vh;
`

const InstructionContentWrapper = styled('div')`
    img {
        width: 100%;
    }
`

export default HseCardNumberInputInstructions