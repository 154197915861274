import jwtDecode, { JwtPayload } from "jwt-decode";
import { ApplicationRoute } from "../menu/menuContext";
import { ProjectMemberContext } from "../projectMember/projectMemberContext";
import { localStorageAccessTokenKey, localStorageIdTokenKey } from "./interfaces";

export const generalAuthorizedCheck = (
    authenticated: boolean, 
    insecure: boolean, 
    applicationRoute: ApplicationRoute, 
    projectMemberContext: ProjectMemberContext): boolean => {
    return (authenticated || insecure) 
            && (applicationRoute.accessRole === undefined || projectMemberContext.hasProjectAccess(applicationRoute.accessRole));
}

export const jwtAccessToken = (): string | undefined => {
  return localStorage.getItem(localStorageAccessTokenKey) ?? undefined;
};

export const jwtIdToken = (): string | undefined => {
  return localStorage.getItem(localStorageIdTokenKey) ?? undefined;
};

export const checkIfTokenIsExpired = (token: string | undefined): boolean => {
  if (!token || token.trim().length === 0) {
    return true;
  }
  let isExpired = false;
  let decodedToken = jwtDecode<JwtPayload>(token);
  let dateNow = new Date();

  if ((decodedToken?.exp ?? 0) < dateNow.getTime() / 1000) {
    isExpired = true;
  }
  return isExpired;
};