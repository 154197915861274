import React, { createContext, useContext, useEffect, useState } from "react";
import { Settings, DocumentType, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface SettingsSubscriptionsContext {
    subscribedSettings: Array<Settings>,
}

const SettingsSubscriptionsContext = createContext<SettingsSubscriptionsContext>(null as unknown as SettingsSubscriptionsContext);

export const SettingsSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedSettings, setSubscribedSettings] = useState<Array<Settings>>([]);

    const updateSubscribedSettings = (stateDocuments: StateDocument[]) => {
        const subscribedSettings = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.SETTINGS && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as Settings);
        setSubscribedSettings(subscribedSettings);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedSettings([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedSettings(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: SettingsSubscriptionsContext = {
        subscribedSettings,
    };

    return (
        <SettingsSubscriptionsContext.Provider value={projectContext}>
            {children}
        </SettingsSubscriptionsContext.Provider>
    );
}

export const useSettingsSubscriptionsContext = (): SettingsSubscriptionsContext => {
    return useContext(SettingsSubscriptionsContext);
}
