import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, ProjectMember } from "../../../contracts/contracts";
import { cleanObject } from "../../../utils/objectTools";
import { Guid } from "../../../utils/common-types";


export const MUTATE_PROJECT_MEMBER: DocumentNode = gql(`
    mutation mutateProjectMember(
        $id: UUID
        $state: ActiveState 	
        $applicationProfileId: UUID!
      	$projectId: UUID!
      	$organizationNumber: String
        $parentOrganizationNumber: String
      	$memberAccess: MemberAccess
        $memberState: MemberState
        $isCheckedIn: Boolean
        $noCertificateHseCardConfirmed: Boolean
        $noCertificateGeneralCardConfirmed: Boolean
        $temporaryApplicationProfileName: String
        $temporaryApplicationProfileEmail: String
        $temporaryApplicationProfilePhoneNumber: String
        $temporaryApplicationProfileHseCardNumber: String
        $notificationSettings: NotificationSettingsInput
        $noRequestingMemberAccessTries: Int
        $failedSafetyInformationQuiz: Boolean
        $infoTechSettings: InfoTechSettingsInput
        $notes: String
    ) {
        mutateProjectMember(
            id: $id
            state: $state
            applicationProfileId: $applicationProfileId
            projectId: $projectId
            organizationNumber: $organizationNumber
            parentOrganizationNumber: $parentOrganizationNumber
            memberAccess: $memberAccess
            memberState: $memberState
            isCheckedIn: $isCheckedIn
            noCertificateHseCardConfirmed: $noCertificateHseCardConfirmed
            noCertificateGeneralCardConfirmed: $noCertificateGeneralCardConfirmed
            temporaryApplicationProfileName: $temporaryApplicationProfileName
            temporaryApplicationProfileEmail: $temporaryApplicationProfileEmail
            temporaryApplicationProfilePhoneNumber: $temporaryApplicationProfilePhoneNumber
            temporaryApplicationProfileHseCardNumber: $temporaryApplicationProfileHseCardNumber
            notificationSettings: $notificationSettings
            noRequestingMemberAccessTries: $noRequestingMemberAccessTries
            failedSafetyInformationQuiz: $failedSafetyInformationQuiz
            infoTechSettings: $infoTechSettings
            notes: $notes
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`);


export const INVITE_PROJECT_MEMBER: DocumentNode = gql(`
    mutation inviteProjectMember(
        $projectId: UUID! 	
        $emails: [String]!
    ) {
        inviteProjectMember(
            projectId: $projectId
            emails: $emails
        ) {
            emails
        }
    }
`);

export const getProjectMembersGraphqlMutationOptions = (projectMember: Partial<ProjectMember>): QueryHookOptions => {
    projectMember.state = projectMember.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (projectMember.id !== undefined && projectMember.id !== null) options.variables['id'] = projectMember.id;
    if (projectMember.state !== undefined && projectMember.state !== null) options.variables['state'] = projectMember.state;
    if (projectMember.applicationProfileId !== undefined && projectMember.applicationProfileId !== null) options.variables['applicationProfileId'] = projectMember.applicationProfileId;
    if (projectMember.projectId !== undefined && projectMember.projectId !== null) options.variables['projectId'] = projectMember.projectId;
    if (projectMember.organizationNumber !== undefined && projectMember.organizationNumber !== null) options.variables['organizationNumber'] = projectMember.organizationNumber;
    if (projectMember.parentOrganizationNumber !== undefined && projectMember.parentOrganizationNumber !== null) options.variables['parentOrganizationNumber'] = projectMember.parentOrganizationNumber;
    if (projectMember.memberAccess !== undefined && projectMember.memberAccess !== null) options.variables['memberAccess'] = projectMember.memberAccess;
    if (projectMember.memberState !== undefined && projectMember.memberState !== null) options.variables['memberState'] = projectMember.memberState;
    if (projectMember.isCheckedIn !== undefined && projectMember.isCheckedIn !== null) options.variables['isCheckedIn'] = projectMember.isCheckedIn;
    if (projectMember.noCertificateHseCardConfirmed !== undefined && projectMember.noCertificateHseCardConfirmed !== null) options.variables['noCertificateHseCardConfirmed'] = projectMember.noCertificateHseCardConfirmed;
    if (projectMember.noCertificateGeneralCardConfirmed !== undefined && projectMember.noCertificateGeneralCardConfirmed !== null) options.variables['noCertificateGeneralCardConfirmed'] = projectMember.noCertificateGeneralCardConfirmed;
    if (projectMember.temporaryApplicationProfileName !== undefined && projectMember.temporaryApplicationProfileName !== null) options.variables['temporaryApplicationProfileName'] = projectMember.temporaryApplicationProfileName;
    if (projectMember.temporaryApplicationProfileEmail !== undefined && projectMember.temporaryApplicationProfileEmail !== null) options.variables['temporaryApplicationProfileEmail'] = projectMember.temporaryApplicationProfileEmail;
    if (projectMember.temporaryApplicationProfilePhoneNumber !== undefined && projectMember.temporaryApplicationProfilePhoneNumber !== null) options.variables['temporaryApplicationProfilePhoneNumber'] = projectMember.temporaryApplicationProfilePhoneNumber;
    if (projectMember.temporaryApplicationProfileHseCardNumber !== undefined && projectMember.temporaryApplicationProfileHseCardNumber !== null) options.variables['temporaryApplicationProfileHseCardNumber'] = projectMember.temporaryApplicationProfileHseCardNumber;
    if (projectMember.notificationSettings !== undefined && projectMember.notificationSettings !== null) options.variables['notificationSettings'] = cleanObject(projectMember.notificationSettings);
    if (projectMember.noRequestingMemberAccessTries !== undefined && projectMember.noRequestingMemberAccessTries !== null) options.variables['noRequestingMemberAccessTries'] = projectMember.noRequestingMemberAccessTries;
    if (projectMember.failedSafetyInformationQuiz !== undefined && projectMember.failedSafetyInformationQuiz !== null) options.variables['failedSafetyInformationQuiz'] = projectMember.failedSafetyInformationQuiz;
    if (projectMember.infoTechSettings !== undefined && projectMember.infoTechSettings !== null) options.variables['infoTechSettings'] = cleanObject(projectMember.infoTechSettings);
    if (projectMember.notes !== undefined && projectMember.notes !== null) options.variables['notes'] = projectMember.notes;
    return options;
}

export const getInviteProjectMembersGraphqlMutationOptions = (projectId: Guid, emails: string[]): QueryHookOptions => {
    const options: QueryHookOptions = {};
    options.variables = {'projectId': projectId, 'emails': emails};
    return options;
}