import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { StorageFile } from "../../../contracts/contracts";

export const MUTATE_STORAGE_FILE: DocumentNode = gql(`
mutation storageFile($delete: Boolean, 
                     $id: UUID, 
                     $key: String, 
                     $roles: [String],
                     $readRoles: [String],
                     $content: String,
                     $metaData: String){
    mutateStorageFile(delete: $delete, 
                      id: $id, 
                      key: $key, 
                      roles: $roles,
                      readRoles: $readRoles,
                      content: $content,
                      metaData: $metaData) {
        files {
            id
            key
            roles
            readRoles
            changed
            changedBy
            content
            metaData
        }
    }
}
`);

export const getStorageFileGraphqlMutationOptions = (storageFile: StorageFile): QueryHookOptions => {
    const options: QueryHookOptions = {}
    options.variables = {};
    if (storageFile.delete !== undefined) options.variables['delete'] = storageFile.delete
    if (storageFile.id !== undefined) options.variables['id'] = storageFile.id
    if (storageFile.key !== undefined) options.variables['key'] = storageFile.key
    if (storageFile.roles !== undefined) options.variables['roles'] = storageFile.roles
    if (storageFile.readRoles !== undefined) options.variables['readRoles'] = storageFile.readRoles
    if (storageFile.content !== undefined) options.variables['content'] = storageFile.content
    if (storageFile.metaData !== undefined) options.variables['metaData'] = storageFile.metaData

    return options;
}
