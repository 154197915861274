import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { NewsFeed } from "../../../contracts/contracts";
import { getNewsFeedsGraphqlMutationOptions } from "../mutations/newsFeedMutations";

export const QUERY_NEWS_FEED: DocumentNode = gql(`
    query newsFeeds(
        $searchIndexStart: Int
        $searchIndexStop: Int
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $applicationProfileId: UUID
        $projectId: UUID
        $title: String
        $newsFeedDescription: String
        $accessLevel: NewsFeedAccessLevel
        $applicationProfileUserId: UUID
        $applicationProfileName: String
        $applicationProfileEmail: String
    ) {
        newsFeeds(
            searchIndexStart: $searchIndexStart
            searchIndexStop: $searchIndexStop
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            applicationProfileId: $applicationProfileId
            projectId: $projectId
            title: $title
            newsFeedDescription: $newsFeedDescription
            accessLevel: $accessLevel
            applicationProfileUserId: $applicationProfileUserId
            applicationProfileName: $applicationProfileName
            applicationProfileEmail: $applicationProfileEmail
        ) {
            id
            documentType
            stateTime
            state
            created
            applicationProfileId
            projectId
            title 
            newsFeedDescription  
            accessLevel  
            applicationProfileUserId
            applicationProfileName
            applicationProfileEmail
        }
    }
`);

export const getNewsFeedsGraphqlQueryOptions = (newsFeed: Partial<NewsFeed>): QueryHookOptions => {
    const options = getNewsFeedsGraphqlMutationOptions(newsFeed)
    options.variables = options.variables ?? {};
    if (newsFeed.searchIndexStart !== undefined) options.variables['searchIndexStart'] = newsFeed.searchIndexStart;
    if (newsFeed.searchIndexStop !== undefined) options.variables['searchIndexStop'] = newsFeed.searchIndexStop;
    if (newsFeed.applicationProfileUserId !== undefined) options.variables['applicationProfileUserId'] = newsFeed.applicationProfileUserId;
    if (newsFeed.applicationProfileName !== undefined) options.variables['applicationProfileName'] = newsFeed.applicationProfileName;
    if (newsFeed.applicationProfileEmail !== undefined) options.variables['applicationProfileEmail'] = newsFeed.applicationProfileEmail;
    return options;
}
