import { Grow, Paper } from "@mui/material";
import React from "react";
import { useMenuContext } from "../../contexts/menu/menuContext";
import { useWidthContext } from "../../contexts/WidthContext";
import ApplicationMenuList from "./ApplicationMenuList";

const ApplicationMenuMobile: React.FC = ( ) => {
	const isMobileScreen = useWidthContext().isMobileScreen();
	const menuContext = useMenuContext();

	return isMobileScreen ? (
		<Grow in={menuContext.fullMenuVisible} mountOnEnter unmountOnExit>
			<Paper
				sx={{
					flexShrink: { md: 0 },
					backgroundColor: '#455A64 !important',
					color: 'white !important',
					position: 'fixed',
					top: 65,
					bottom: 0,
					left: 0,
					right: 0,
					zIndex: 5,
					transformOrigin: '50% 0 0'
				}}
				elevation={2}
				square={true}
			>
				<ApplicationMenuList />
			</Paper>
		</Grow>
	) : null
}

export default ApplicationMenuMobile;
