import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ProjectMember } from "../../../contracts/contracts";
import { getProjectMembersGraphqlMutationOptions } from "../mutations/projectMemberMutations";
import { Guid } from "../../../utils/common-types";
import { getEndOfDay, getStartOfDay } from "../../../utils/dateTools";

export const QUERY_PROJECT_MEMBER: DocumentNode = gql(`
    query projectMembers(
        $searchIndexStart: Int
        $searchIndexStop: Int
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $applicationProfileId: UUID
        $projectId: UUID
        $organizationNumber: String
        $parentOrganizationNumber: String
        $memberAccess: MemberAccess
        $memberState: MemberState
        $isCheckedIn: Boolean
        $lastCheckedIn: DateTime
        $noCertificateHseCardConfirmed: Boolean
        $noCertificateGeneralCardConfirmed: Boolean
        $temporaryApplicationProfileName: String
        $temporaryApplicationProfileEmail: String
        $temporaryApplicationProfilePhoneNumber: String
        $temporaryApplicationProfileHseCardNumber: String
        $notificationSettings: NotificationSettingsInput
        $projectName: String
        $projectState: ProjectState
        $projectActiveState: ActiveState
        $applicationProfileUserId: UUID
        $applicationProfileName: String
        $applicationProfileEmail: String
        $applicationProfileHseCardNumber: String
        $applicationProfilePhoneNumber: String
        $certificateHseCardIsAvailable: Boolean
        $certificateGeneralCardIsAvailable: Boolean
        $projectCompanyRelationVerified: Boolean
        $projectCompanyId: UUID
        $projectCompanyName: String
        $parentProjectCompanyId: UUID
        $parentProjectCompanyName: String
        $noRequestingMemberAccessTries: Int
        $failedSafetyInformationQuiz: Boolean
        $infoTechSettings: InfoTechSettingsInput
        $lastRegisteredInfoTechSettings: InfoTechSettingsInput
        $notes: String
    ) {
        projectMembers(
            searchIndexStart: $searchIndexStart
            searchIndexStop: $searchIndexStop
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            applicationProfileId: $applicationProfileId
            projectId: $projectId
            organizationNumber: $organizationNumber
            parentOrganizationNumber: $parentOrganizationNumber
            memberAccess: $memberAccess
            memberState: $memberState
            isCheckedIn: $isCheckedIn
            lastCheckedIn: $lastCheckedIn
            noCertificateHseCardConfirmed: $noCertificateHseCardConfirmed
            noCertificateGeneralCardConfirmed: $noCertificateGeneralCardConfirmed
            temporaryApplicationProfileName: $temporaryApplicationProfileName
            temporaryApplicationProfileEmail: $temporaryApplicationProfileEmail
            temporaryApplicationProfilePhoneNumber: $temporaryApplicationProfilePhoneNumber
            temporaryApplicationProfileHseCardNumber: $temporaryApplicationProfileHseCardNumber
            notificationSettings: $notificationSettings
            projectName: $projectName
            projectState: $projectState
            projectActiveState: $projectActiveState
            applicationProfileUserId: $applicationProfileUserId
            applicationProfileName: $applicationProfileName
            applicationProfileEmail: $applicationProfileEmail
            applicationProfilePhoneNumber: $applicationProfilePhoneNumber
            applicationProfileHseCardNumber: $applicationProfileHseCardNumber
            certificateHseCardIsAvailable: $certificateHseCardIsAvailable
            certificateGeneralCardIsAvailable: $certificateGeneralCardIsAvailable
            projectCompanyRelationVerified: $projectCompanyRelationVerified
            projectCompanyId: $projectCompanyId
            projectCompanyName: $projectCompanyName
            parentProjectCompanyId: $parentProjectCompanyId
            parentProjectCompanyName: $parentProjectCompanyName
            noRequestingMemberAccessTries: $noRequestingMemberAccessTries
            failedSafetyInformationQuiz: $failedSafetyInformationQuiz
            infoTechSettings: $infoTechSettings
            lastRegisteredInfoTechSettings: $lastRegisteredInfoTechSettings
            notes: $notes
        ) {
            id
            documentType
            stateTime
            state
            created
            applicationProfileId
            projectId
            organizationNumber
            parentOrganizationNumber
            memberAccess
            memberState
            isCheckedIn
            lastCheckedIn
            noCertificateHseCardConfirmed
            noCertificateGeneralCardConfirmed
            temporaryApplicationProfileName
            temporaryApplicationProfileEmail
            temporaryApplicationProfilePhoneNumber
            temporaryApplicationProfileHseCardNumber
            notificationSettings {
                disableEmailNotificationFromNewsFeed
            }
            projectName
            projectState
            projectActiveState
            applicationProfileUserId
            applicationProfileName
            applicationProfileEmail
            applicationProfilePhoneNumber
            applicationProfileHseCardNumber
            certificateHseCardIsAvailable
            certificateGeneralCardIsAvailable
            projectCompanyRelationVerified
            projectCompanyId
            projectCompanyName
            parentProjectCompanyId
            parentProjectCompanyName
            noRequestingMemberAccessTries
            failedSafetyInformationQuiz
            infoTechSettings {
                enabled
                infoTechApiKey
                infoTechProjectId
                infoTechProjectDescription
                infoTechAccessGroupId
                infoTechAccessGroupName
                infoTechCardId
            }
            lastRegisteredInfoTechSettings {
                enabled
                infoTechApiKey
                infoTechProjectId
                infoTechProjectDescription
                infoTechAccessGroupId
                infoTechAccessGroupName
                infoTechCardId
            }
            notes
        }
    }
`);

export const QUERY_PROJECT_MEMBER_CHECKED_IN_EVENT: DocumentNode = gql(`
    query projectMemberCheckedInEvents(
        $projectId: UUID!
        $fromTimestamp: DateTime!
        $toTimestamp: DateTime!
        $isCheckedIn: Boolean
        $projectMemberId: UUID
    ) {
        projectMemberCheckedInEvents(
            projectId: $projectId
            fromTimestamp: $fromTimestamp
            toTimestamp: $toTimestamp
            isCheckedIn: $isCheckedIn
            projectMemberId: $projectMemberId
        ) {
            id
            projectMemberId
            applicationProfileId
            projectId
            timestamp
            isCheckedIn
        }
    }
`);

export const getProjectMembersGraphqlQueryOptions = (projectMember: Partial<ProjectMember>): QueryHookOptions => {
    const options = getProjectMembersGraphqlMutationOptions(projectMember)
    options.variables = options.variables ?? {};
    if (projectMember.searchIndexStart !== undefined) options.variables['searchIndexStart'] = projectMember.searchIndexStart;
    if (projectMember.searchIndexStop !== undefined) options.variables['searchIndexStop'] = projectMember.searchIndexStop;
    if (projectMember.projectName !== undefined) options.variables['projectName'] = projectMember.projectName;
    if (projectMember.projectState !== undefined) options.variables['projectState'] = projectMember.projectState;
    if (projectMember.projectActiveState !== undefined) options.variables['projectActiveState'] = projectMember.projectActiveState;
    if (projectMember.applicationProfileUserId !== undefined) options.variables['applicationProfileUserId'] = projectMember.applicationProfileUserId;
    if (projectMember.applicationProfileName !== undefined) options.variables['applicationProfileName'] = projectMember.applicationProfileName;
    if (projectMember.applicationProfileEmail !== undefined) options.variables['applicationProfileEmail'] = projectMember.applicationProfileEmail;
    if (projectMember.applicationProfilePhoneNumber !== undefined) options.variables['applicationProfilePhoneNumber'] = projectMember.applicationProfilePhoneNumber;
    if (projectMember.applicationProfileHseCardNumber !== undefined) options.variables['applicationProfileHseCardNumber'] = projectMember.applicationProfileHseCardNumber;
    if (projectMember.projectCompanyRelationVerified !== undefined) options.variables['projectCompanyRelationVerified'] = projectMember.projectCompanyRelationVerified;
    if (projectMember.certificateHseCardIsAvailable !== undefined) options.variables['certificateHseCardIsAvailable'] = projectMember.certificateHseCardIsAvailable;
    if (projectMember.certificateGeneralCardIsAvailable !== undefined) options.variables['certificateGeneralCardIsAvailable'] = projectMember.certificateGeneralCardIsAvailable;
    if (projectMember.projectCompanyId !== undefined) options.variables['projectCompanyId'] = projectMember.projectCompanyId;
    if (projectMember.projectCompanyName !== undefined) options.variables['projectCompanyName'] = projectMember.projectCompanyName;
    if (projectMember.parentProjectCompanyId !== undefined) options.variables['parentProjectCompanyId'] = projectMember.parentProjectCompanyId;
    if (projectMember.parentProjectCompanyName !== undefined) options.variables['parentProjectCompanyName'] = projectMember.parentProjectCompanyName;
    return options;
}

export const getProjectMemberCheckedInEventsGraphqlQueryOptions = (projectId: Guid, 
                                                                   fromTimestamp: Date, 
                                                                   toTimestamp: Date, 
                                                                   isCheckedIn?: boolean | undefined, 
                                                                   projectMemberId?: Guid | undefined): QueryHookOptions => {
    const options: QueryHookOptions = {};
    options.variables = {};
    options.variables['projectId'] = projectId;
    options.variables['fromTimestamp'] = getStartOfDay(fromTimestamp).toISOString();
    options.variables['toTimestamp'] = getEndOfDay(toTimestamp).toISOString();
    if (isCheckedIn !== undefined) options.variables['isCheckedIn'] = isCheckedIn;
    if (projectMemberId !== undefined) options.variables['projectMemberId'] = projectMemberId;
    return options;
}
