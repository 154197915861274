import queryString from 'query-string';
import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Dictionary } from '../../global-types';

export interface UrlContext {
    currentLocation: any,
    getUrlQuery: () => string;
    getUrlState: () => any;
    buildUrlQuery: (state: Dictionary<string | number | Date | undefined | any>) => string;
    pushUrlQuery: (urlQuery: string, locationOverride?: string) => void;
    replaceUrlQuery: (urlQuery: string, locationOverride?: string) => void;
}

const UrlContext = createContext<UrlContext>(null as unknown as UrlContext);

export const UrlContextProvider: React.FC<{}> = ({ children }) => {

    const history = useHistory();
    const location = useLocation();

    const [currentLocation, setCurrentLocation] = useState(location);

    useEffect(() => {
        history.listen(newLocation => {
            setCurrentLocation(newLocation);
        });
    }, [])

    const getUrlQuery = (): string => {
        return currentLocation.search;
    }

    const getUrlState = (): any => {
        return queryString.parse(currentLocation.search);
    }

    const buildUrlQuery = (state: Dictionary<string | number | Date | undefined | any>): string => {

        if (!state) return '';

        let queryParts = [];

        for (let p in state) {
            if (!(state as Object).hasOwnProperty(p)) continue;

            if (state[p] === null || state[p] === undefined) continue;

            const value = state[p];

            let valueString = `${value}`; //HACK(ljo): A better way of converting value to string should exist.

            if (value instanceof Date) {
                valueString = value.toISOString();
            } 
            else if (typeof value === 'object') {
                continue;
            }

            if (value) {
                queryParts.push(`${p}=${valueString}`);
            }
        }

        if (queryParts.length <= 0) return '';

        return '?' + queryParts.join('&');
    }

    const pushUrlQuery = (urlQuery: string, locationOverride?: string) => {
        locationOverride = locationOverride ?? history.location.pathname;
        history.push(`${locationOverride}${urlQuery}`);
    }

    const replaceUrlQuery = (urlQuery: string, locationOverride?: string) => {
        locationOverride = locationOverride ?? history.location.pathname;
        history.replace(`${locationOverride}${urlQuery}`);
    }

    const value = {
        currentLocation,
        getUrlQuery,
        getUrlState,
        buildUrlQuery,
        pushUrlQuery,
        replaceUrlQuery,
    };

    return (
        <UrlContext.Provider value={value}>
            {children}
        </UrlContext.Provider>
    );
}

export const useUrlContext = (): UrlContext => {
    return useContext(UrlContext);
}