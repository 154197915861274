import React from 'react';
import { format, parseISO } from 'date-fns'

import { Information, InformationVerification } from "../../contracts/contracts";
import { Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import diploma_placeholder from '../../data/pictures/certificate.png'

type props = {
	informationVerification: InformationVerification;
	onClick: (informationVerification: InformationVerification, information?: Information) => void;
}

const InformationVerificationTeaser: React.FC<props> = ({ informationVerification, onClick }) => {
	return (
		<ListItem>
			<ListItemButton sx={{ p: 0 }} onClick={() => onClick(informationVerification)}>
				<ListItemAvatar>
					<Avatar src={diploma_placeholder} />
				</ListItemAvatar>
				<ListItemText
					primary={informationVerification?.informationTitle}
					secondary={
						<Typography
							sx={{  textAlign: 'right' }}
							variant="body2"
							color="text.secondary"
						>
							{format(parseISO(informationVerification?.stateTime ?? ''), 'MM/dd/yyyy')}
						</Typography>
					}
				/>
			</ListItemButton>
		</ListItem>
	);
}

export default InformationVerificationTeaser;
