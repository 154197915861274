import { Button, Fab, IconButton, Theme, Tooltip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import PublishIcon from '@mui/icons-material/Publish';
import React, { ReactNode, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: "none",
    },
    fileSelect: {
        color: theme.palette.primary.light,
    },
}));


type props = {
    inputId: string;
    onSelectedFiles?: (files: File[]) => void;
    buttonTitle?: string;
    fileAcceptance?: string;
    useRegularButton?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    isAttach?: boolean;
}

const FileSelect: React.FC<props> = ({
    inputId,
    onSelectedFiles,
    buttonTitle,
    fileAcceptance,
    useRegularButton,
    disabled,
    multiple,
    isAttach,
}) => {

    const classes = useStyles();
    const [inputKey, setInputKey] = useState<React.Key>(Date.now().toString());

    const onFileSelected = (event: any) => {
        const fileList: FileList = event.target.files;
        const files: File[] = [];
        for (let i = 0; i < fileList.length; i++) {
            if (fileList.item(i)) {
                files.push(fileList.item(i) as File);
            }
        }
        if (onSelectedFiles && files.length > 0) {
            onSelectedFiles(files);
        }
    };

    return (
        <>
            <input
                accept={fileAcceptance ?? ''}
                className={classes.input}
                id={inputId}
                type="file"
                key={inputKey}
                onChange={onFileSelected}
                multiple={multiple}
            />
            {!useRegularButton && <Tooltip title={buttonTitle ?? ''}>
                <label htmlFor={inputId}>
                    <IconButton
                        className={classes.fileSelect}
                        color="primary"
                        component="span"
                        disabled={disabled}
                        onClick={() => setInputKey(Date.now().toString())}
                    >
                        { !isAttach ? (
                          <PublishIcon fontSize="large" />
                        ) : null }
                    </IconButton>
                </label>
            </Tooltip>}
            {useRegularButton && <label htmlFor={inputId}>
                <Button
                    fullWidth
                    color="primary"
                    component="span"
                    variant="contained"
                    disabled={disabled}
                    onClick={() => setInputKey(Date.now().toString())}
                    startIcon={<PublishIcon fontSize="large" />}>
                    {buttonTitle}
                </Button>
            </label>}
        </>
    );
}

export default FileSelect;
