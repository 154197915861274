import React, { createContext, useContext } from "react";
import { ProjectMember, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getProjectMembersGraphqlMutationOptions, MUTATE_PROJECT_MEMBER } from "./projectMemberMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface ProjectMemberMutationsContext {
    mutateProjectMember: (projectMember: ProjectMember, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void) => void;
}

const ProjectMemberMutationsContext = createContext<ProjectMemberMutationsContext>(null as unknown as ProjectMemberMutationsContext);

export const ProjectMemberMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [projectMemberMutation] = useMutation(MUTATE_PROJECT_MEMBER);

    const handleProjectMemberMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        if (result.errors || !result.data) {
            onError(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateProjectMember.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
        if (tickets.length === 0 && 'id' in variables) {
            onTicketSubscribed(variables['id'], variables);
        }
        onError("No ticket or document id received");
    }

    const mutateProjectMember = (projectMember: ProjectMember, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        const options = getProjectMembersGraphqlMutationOptions(projectMember)
        projectMemberMutation(options).then((result) => handleProjectMemberMutationResult(result, options.variables, onTicketSubscribed, onError));
    }

    const projectMemberMutationsContext: ProjectMemberMutationsContext = {
        mutateProjectMember
    };

    return (
        <ProjectMemberMutationsContext.Provider value={projectMemberMutationsContext}>
            {children}
        </ProjectMemberMutationsContext.Provider>
    );
}

export const useProjectMemberMutationsContext = (): ProjectMemberMutationsContext => {
    return useContext(ProjectMemberMutationsContext);
}
