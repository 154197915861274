import { saveAs } from 'file-saver';

import { StorageFileMutationsContext } from "../contexts/storageFile/mutations/storageFileMutationsContext";
import { StorageFile } from "../contracts/contracts";
import { Guid } from "./common-types";
import { StorageFileContext } from '../contexts/storageFile/storageFileContext';

export function convertImageToBase64(imageUrl: string, callback: (dataUrl: string) => void) {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx?.drawImage(image, 0, 0);
        const dataUrl = canvas.toDataURL();
        callback && callback(dataUrl);
    }
    image.src = imageUrl;
}

export function downloadBase64File(contentType: string | undefined, base64Data: string, filename: string) {
    const blob = base64toBlob(base64Data, contentType);
    saveAs(blob, filename);
}

export const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result ?? '') as string);
    reader.onerror = error => reject(error);
});

export const fileToString = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve((reader.result ?? '') as string);
    reader.onerror = error => reject(error);
});

export const base64toBlob = (data: string, type?: string) => {
    const base64Prefix = ';base64,';
    const prefixIndex = data.indexOf(base64Prefix);
    const base64PrefixPart = prefixIndex >= 0 ? data.slice(0, prefixIndex) : '';
    const base64WithoutPrefix = prefixIndex >= 0 ? data.slice(prefixIndex + base64Prefix.length) : data;
    var mimeString = base64PrefixPart.indexOf(':') >= 0 ? base64PrefixPart.slice(base64PrefixPart.indexOf(':') + 1) : undefined;
    type = type ?? mimeString;

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    const out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: type ?? "text/plain;charset=utf-8" });
};

export const getStorageFileFullKey = (documentId: Guid, fileKey?: string): string => {
    return `onsite_project_${documentId}${fileKey ? `_${fileKey}` : ''}`;
}

export const parseStorageFileToBase64Files = (storageFiles: StorageFile[], key: string | undefined): [string[], string[]] => {
    const matchingStorageFile = storageFiles.find(storageFile => storageFile.key === key);
    const base64Images: string[] = matchingStorageFile?.content ? matchingStorageFile.content.split(';;') : [];
    const base64ImagesMetadata: string[] = matchingStorageFile?.metaData ? matchingStorageFile.metaData.split(';;') : [];
    return [base64Images, base64ImagesMetadata];
}

export async function onUploadFiles(
    storageFileContext: StorageFileContext,
    storageMutationContext: StorageFileMutationsContext,
    newFiles: File[] | string[],
    key: string | undefined,
    roles: string[],
    readRoles: string[],
    onMutatedStorageFile?: (base64Images: string[]) => void,
    onError?: (reason: any) => void,
    metaData?: string | string[]) {
    if (!key) {
        return;
    }
    if (Array.isArray(metaData)) {
        metaData = metaData.join(';;');
    }
    const base64ImageFiles: string[] = [];
    for (let i = 0; i < newFiles.length; i++) {
        const newFile = newFiles[i];
        if (typeof newFile === 'string') {
            base64ImageFiles.push(newFile);
        }
        else {
            base64ImageFiles.push(await toBase64(newFile));
        }
    }
    const storageFile: StorageFile = {
        key: key,
        roles: roles,
        readRoles: readRoles,
        content: base64ImageFiles.join(';;'),
        metaData,
    }
    await storageMutationContext.mutateStorageFile(storageFile).then(storageFiles => {
        storageFileContext.appendStorageFilesToCache(storageFiles);
        if (onMutatedStorageFile) {
            onMutatedStorageFile(parseStorageFileToBase64Files(storageFiles, key)[0]);
        }
    }).catch(reason => {
        if (onError) {
            onError(reason);
        }
    });
}

export async function onUploadFile(
    storageFileContext: StorageFileContext,
    storageMutationContext: StorageFileMutationsContext,
    newFiles: string,
    key: string | undefined,
    roles: string[],
    readRoles: string[],
    fileName?: string,
    onMutatedStorageFile?: (base64Images: string[]) => void) {
    if (!key) {
        return;
    }
    const base64ImageFiles: string[] = [];
    for (let i = 0; i < newFiles.length; i++) {
        const newFile = newFiles[i];
        if (typeof newFile === 'string') {
            base64ImageFiles.push(newFile);
        }
        else {
            base64ImageFiles.push(await toBase64(newFile));
        }
    }
    const storageFile: StorageFile = {
        key: key,
        roles: roles,
        readRoles: readRoles,
        content: newFiles,
        metaData: fileName ?? undefined
    }
    await storageMutationContext.mutateStorageFile(storageFile).then(storageFiles => {
        storageFileContext.appendStorageFilesToCache(storageFiles);
        if (onMutatedStorageFile) {
            onMutatedStorageFile(parseStorageFileToBase64Files(storageFiles, key)[0]);
        }
    });
}
