
import React, { createContext, useContext, useState } from "react";
import { StorageFile } from "../../contracts/contracts";
import { StorageFileMutationsContextProvider } from "./mutations/storageFileMutationsContext";

export interface StorageFileContext {
    appendStorageFilesToCache: (storageFiles: StorageFile[]) => void;
    popStorageFilesFromCache: (storageFiles: StorageFile[]) => void;
    getStorageFileFromCache: (fileKey: string) => StorageFile | undefined;
}

const StorageFileContext = createContext<StorageFileContext>(null as unknown as StorageFileContext);

export const StorageFileContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <StorageFileMutationsContextProvider>
            <StorageFileSubContextProvider>
                {children}
            </StorageFileSubContextProvider>
        </StorageFileMutationsContextProvider>
    );
}

export const StorageFileSubContextProvider: React.FC<{}> = ({ children }) => {

    const [cachedStorageFiles, setCachedStorageFiles] = useState<StorageFile[]>([]);

    const appendStorageFilesToCache = (storageFiles: StorageFile[]) => {
        const updatedStorageFilesCache = cachedStorageFiles.slice();
        storageFiles.forEach(storageFile => {
            const index = updatedStorageFilesCache.findIndex(cachedStorageFile => cachedStorageFile.key === storageFile.key);
            if (index >= 0) {
                updatedStorageFilesCache[index] = storageFile;
            } else {
                updatedStorageFilesCache.push(storageFile);
            }
        });
        setCachedStorageFiles(updatedStorageFilesCache);
    }

    const popStorageFilesFromCache = (storageFiles: StorageFile[]) => {
        const updatedStorageFilesCache = cachedStorageFiles.slice();
        storageFiles.forEach(storageFile => {
            const index = updatedStorageFilesCache.findIndex(cachedStorageFile => cachedStorageFile.key === storageFile.key);
            if (index >= 0) {
                updatedStorageFilesCache.splice(index, 1);
            }
        });
        setCachedStorageFiles(updatedStorageFilesCache);
    }

    const getStorageFileFromCache = (fileKey: string): StorageFile | undefined => {
        return cachedStorageFiles.find(cachedStorageFile => cachedStorageFile.key === fileKey);
    }
    
    const storageFileContext: StorageFileContext = {
        appendStorageFilesToCache,
        popStorageFilesFromCache,
        getStorageFileFromCache,
    };

    return (
        <StorageFileContext.Provider value={storageFileContext}>
            {children}
        </StorageFileContext.Provider>
    );
}

export const useStorageFileContext = (): StorageFileContext => {
    return useContext(StorageFileContext);
}