import React, { useEffect, useState } from 'react';
import { Grid, Box, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Autocomplete, Stack, CircularProgress, Typography } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Close } from '@mui/icons-material';
import { compressFile, isPictureFile } from '../../utils/compressionTools';
import { downloadBase64File } from '../../utils/fileTools';
import { getLimitedString } from '../../utils/stringTools';

type Props = {
    open: boolean;
    title: string;
    onSave: (image: { file: any, filename: string | undefined, title: string, goBack: boolean }) => Promise<void>;
    onClose: () => void;
    readonly?: boolean;
    imageStep?: number;
    defaultImageTitle?: string;
    defaultImage?: any;
    defaultImageFilename?: string;
    imageTitleOptions?: string[];
    saveButtonTitle?: string;
    backButtonTitle?: string;
    helperText?: string;
    isSavingImages?: boolean;
    placeholder?: {
        front: React.ReactNode | string;
        back: React.ReactNode | string;
    }
}

const AddImageDialog: React.FC<Props> = ({
    title,
    onSave,
    onClose,
    open,
    readonly,
    imageStep,
    defaultImageTitle,
    defaultImage,
    defaultImageFilename,
    imageTitleOptions,
    saveButtonTitle,
    backButtonTitle,
    helperText,
    isSavingImages,
    placeholder,
}) => {
    const [selectedImage, setSelectedImage] = useState<{ imagePreviewUrl?: string, imageFilename?: string, file?: any }>({});
    const [imageTitle, setImageTitle] = useState<string>('')
    const [readingFile, setReadingFile] = useState<boolean>(false);
    imageTitleOptions = imageTitleOptions ?? [];

    useEffect(() => {
        setImageTitle(defaultImageTitle ?? '');
        setSelectedImage({ imagePreviewUrl: defaultImage, imageFilename: defaultImageFilename, file: undefined });
    }, [defaultImageTitle, defaultImage, defaultImageFilename, imageStep])

    const languageContext = useLanguageContext();

    const customPlaceholer = placeholder ? imageStep === 0 ? placeholder.front : placeholder.back : languageContext.getMessage('clickToUploadImage')

    const onImageChangeHandler = (e: any) => {
        e.preventDefault();

        const reader = new FileReader();
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const handleFile = (fileResult: File) => {
            reader.onloadend = () => {
                setReadingFile(false);
                setSelectedImage({
                    file: fileResult,
                    imagePreviewUrl: reader.result as string,
                    imageFilename: fileResult.name,
                });
            }
            reader.readAsDataURL(fileResult);
        }

        setReadingFile(true);
        compressFile(file, (result) => {
            handleFile(result);
        }, (error) => {
            handleFile(file);
        });
    }

    const onSaveHandler = async (goBack: boolean) => {
        if (typeof onSave === 'function') {
            await onSave({ file: selectedImage.file, filename: selectedImage.imageFilename, title: imageTitle, goBack: goBack ?? false })
        }
    }

    const onCloseHandler = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    }

    const acceptedFiles = 'image/*,application/pdf';

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            onClose={onCloseHandler}
        >
            <DialogTitle>
                {title}
                {isSavingImages && <CircularProgress style={{ marginLeft: 10 }} size={25} />}
                <IconButton onClick={onCloseHandler} title={languageContext.getMessage('cancel')} sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}>
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    <Grid container sx={{ flexWrap: 'nowrap', paddingTop: '7px', alignItems: 'center', justifyContent: 'space-between' }}>
                        {imageTitleOptions.length > 0 ?
                            <Autocomplete
                                options={imageTitleOptions}
                                getOptionLabel={(imageTitleOption) => imageTitleOption}
                                freeSolo={true}
                                value={imageTitle ?? {}}
                                fullWidth
                                disabled={readonly}
                                onInputChange={(event: any, newImageTitle: string | null) => setImageTitle(newImageTitle ?? '')}
                                renderInput={(params) => <TextField {...params}
                                    label={languageContext.getMessage('title')}
                                    variant="standard" helperText={readonly ? undefined : helperText} />}
                            /> : <TextField
                                label={languageContext.getMessage('title')}
                                variant="standard"
                                value={imageTitle}
                                onChange={(e) => setImageTitle(e.target.value)}
                                disabled={readonly}
                                helperText={readonly ? undefined : helperText}
                                fullWidth
                            />}

                        <label htmlFor="icon-button-file">
                            <input style={{ display: 'none' }} disabled={readonly || readingFile} accept={acceptedFiles} id="icon-button-file" type="file" onChange={onImageChangeHandler} />
                            <IconButton color="primary" disabled={readonly || readingFile} aria-label="upload picture" component="span">
                                <PhotoCamera />
                            </IconButton>
                        </label>
                    </Grid>

                    <Grid item xs={12} padding='0' marginTop='10px'>
                        <label htmlFor="icon-button-file-on-image">
                            <input style={{ display: 'none' }} disabled={readonly || readingFile} accept={acceptedFiles} id="icon-button-file-on-image" type="file" onChange={onImageChangeHandler} />
                            <Box sx={{ position: 'relative', textAlign: 'center', maxHeight: '200px', height: '200px', bgcolor: 'background.default' }}>
                                {selectedImage?.imagePreviewUrl && isPictureFile(selectedImage?.imagePreviewUrl)
                                    ? <img style={{ height: '100%', maxWidth: '100%' }} src={selectedImage.imagePreviewUrl} />
                                    : <div style={{
                                        margin: 0,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        msTransform: 'translate(-50%, -50%)',
                                        transform: 'translate(-50%, -50%)',
                                    }}>
                                        <Stack direction={'column'} alignItems='center' spacing={2}>
                                            {selectedImage?.imagePreviewUrl ? <>
                                                <Typography sx={{ textAlign: 'center', fontWeight: 700, fontSize: '1.5rem' }}>
                                                    {getLimitedString(selectedImage.imageFilename ?? imageTitle ?? '', 40)}
                                                </Typography>
                                                <IconButton
                                                    onClick={() => downloadBase64File(undefined, selectedImage.imagePreviewUrl ?? '', selectedImage.imageFilename ?? imageTitle ?? '')}>
                                                    <FileDownloadIcon fontSize={'large'} />
                                                </IconButton>
                                            </> : <>
                                                <PhotoCamera fontSize='large' />
                                                <div>{customPlaceholer}</div>
                                            </>}
                                        </Stack>
                                    </div>
                                }
                            </Box>
                        </label>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={async () => await onSaveHandler(true)} disabled={readonly || readingFile} color="primary" variant="contained">
                    {backButtonTitle ?? languageContext.getMessage('cancel')}
                </Button>
                <Button onClick={async () => await onSaveHandler(false)} disabled={readonly || readingFile} color="primary" variant="contained">
                    {saveButtonTitle ?? languageContext.getMessage('save')}
                </Button>
            </DialogActions>
        </Dialog >

    );
}

export default AddImageDialog;