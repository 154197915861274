import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from '@apollo/react-hooks';
import { ActiveState, ApplicationProfile, MemberAccess, Certificate, MemberState, Project, ProjectMember, RoleType, InformationVerification, ProjectMemberCheckedInEvent } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { Guid } from "../../utils/common-types";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useProjectContext, userHasGlobalProjectAccess } from "../project/projectContext";
import { useApplicationProfileContext } from "../applicationProfile/applicationProfileContext";
import { useAuthContext } from "../auth/authContext";
import { useUrlContext } from "../url/urlContext";
import { checkProjectAccess } from "../userRole/userRoleTools";
import { ProjectMemberMutationsContextProvider, useProjectMemberMutationsContext } from "./mutations/projectMemberMutationsContext";
import { ProjectMemberQueriesContextContext, useProjectMemberQueriesContext } from "./queries/projectMemberQueriesContext";
import { ProjectMemberSubscriptionsContextProvider, useProjectMemberSubscriptionsContext } from "./subscriptions/projectMemberSubscriptionsContext";
import { projectMemberTemplateAlternatives, queryTemplateEngineToProduceProjectMemberReport } from "./ProjectMemberExportTools";
import { useTemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";
import { useLanguageContext } from "../language/LanguageContext";
import { guidIsNullOrEmpty } from "../../utils/guidTools";
import { INVITE_PROJECT_MEMBER, getInviteProjectMembersGraphqlMutationOptions } from "./mutations/projectMemberMutations";
import { projectMemberCheckedInEventsTemplateAlternatives, queryTemplateEngineToProduceProjectMemberCheckedInEventsReport } from "./ProjectMemberCheckedInEventsExportTools";



export interface ProjectMemberContext {
    getProjectMemberSearch: () => ProjectMember,
    searchProjectMembers: (projectMemberSearch: ProjectMember) => void,
    getProjectMembers: () => ProjectMember[],
    getProjectMembersForLoggedInUser: () => ProjectMember[],
    getProjectMember: (id: Guid | undefined) => (ProjectMember | undefined),
    getSelectedProjectMember: (noGlobalAccess?: boolean) => (ProjectMember | undefined),
    setSelectedProjectMember: (projectId: Guid | undefined, cleanUrlState?: boolean | undefined) => void,
    addTemporaryProjectMember: (projectMember: ProjectMember) => void,
    mutateProjectMember: (projectMember: ProjectMember) => Promise<Guid | undefined>,
    hasProjectAccess: (roleType: RoleType, projectId?: Guid) => boolean,
    downloadProjectMemberReport: (companyLogo: string, applicationProfile: ApplicationProfile, projectMember: ProjectMember, certificates: Certificate[], informationVerifications: InformationVerification[], exportType?: projectMemberTemplateAlternatives) => void,
    downloadProjectMemberCheckedInEventsReport: (companyLogo: string, project: Project, projectMembers: ProjectMember[], projectMemberCheckedInEvents: ProjectMemberCheckedInEvent[], exportType?: projectMemberCheckedInEventsTemplateAlternatives) => void,
    loadingProjectMembers: boolean,
    getGlobalProjectName: (project: Project) => string;
    inviteProjectMember: (projectId: Guid, emails: string[]) => Promise<void>;
}

const ProjectMemberContext = createContext<ProjectMemberContext>(null as unknown as ProjectMemberContext);

export const sortProjectMemberByDate = (a: ProjectMember, b: ProjectMember) => {
    if ((a.created ?? "") < (b.created ?? "")) { return 1; }
    if ((a.created ?? "") > (b.created ?? "")) { return -1; }
    return 0;
}

export enum ProjectMemberTabs {
    all = "all",
    details = "details",
    join = "join",
    joinRegister = "join-register",
    joinInstruction = "join-instruction",
    joinAgreement = "join-agreement",
}

export const ProjectMemberContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <ProjectMemberMutationsContextProvider>
            <ProjectMemberQueriesContextContext>
                <ProjectMemberSubscriptionsContextProvider>
                    <ProjectMemberSubContextProvider>
                        {children}
                    </ProjectMemberSubContextProvider>
                </ProjectMemberSubscriptionsContextProvider>
            </ProjectMemberQueriesContextContext>
        </ProjectMemberMutationsContextProvider>
    );
}

export const ProjectMemberSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();

    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const projectContext = useProjectContext();
    const applicationProfileContext = useApplicationProfileContext();
    const projectMemberMutationsContext = useProjectMemberMutationsContext();
    const projectMemberQueriesContext = useProjectMemberQueriesContext();
    const projectMemberSubscriptionsContext = useProjectMemberSubscriptionsContext();

    const urlState = urlContext.getUrlState();
    const [selectedProjectId, setSelectedProjectId] = useState<Guid | undefined>(urlState.selectedProjectId ?? undefined);
    const [currentProjectMemberSearch, setCurrentProjectMemberSearch] = useState<ProjectMember | undefined>(undefined);
    const [projectMembers, setProjectMembers] = useState<ProjectMember[]>([]);
    const maxProjectMembersToFetchAtOnce = 100;

    const [inviteProjectMemberMutation] = useMutation(INVITE_PROJECT_MEMBER);

    const getGlobalProjectName = (project: Project): string => {
        return `(Global) ${project.name ?? project.id}`;
    }

    const replaceUrlState = (newProjectId: Guid | undefined, cleanUrlState?: boolean): void => {
        let newUrlState = { ...urlState, ...{ 'selectedProjectId': newProjectId } }
        if (cleanUrlState) {
            newUrlState = { 'selectedProjectId': newProjectId }
        }
        const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
        urlContext.replaceUrlQuery(urlQuery);
    }

    const mergeProjectMembers = (oldProjectMembers: Array<ProjectMember>, newProjectMembers: Array<ProjectMember>): Array<ProjectMember> => {
        const updatedProjectMembers = oldProjectMembers.slice();
        if (!newProjectMembers) {
            console.error(`Received undefined set of ProjectMembers: ${newProjectMembers}`);
            return [];
        }
        const applicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser();
        let shouldRefreshToken = false;
        newProjectMembers.forEach(newProjectMember => {
            newProjectMember.created = new Date(newProjectMember.created ?? 0);
            newProjectMember.lastCheckedIn = new Date(newProjectMember.lastCheckedIn ?? 0);

            if (applicationProfile?.id && newProjectMember.applicationProfileId === applicationProfile.id) {
                shouldRefreshToken = true;
            }

            const index = updatedProjectMembers.findIndex(projectMember =>
                projectMember.id === newProjectMember.id ||
                (!projectMember.id &&
                    projectMember.applicationProfileId === newProjectMember.applicationProfileId &&
                    projectMember.projectId === newProjectMember.projectId));
            if (index >= 0) {
                if (newProjectMember.state === ActiveState.ACTIVE && newProjectMember.projectActiveState === ActiveState.ACTIVE) {
                    updatedProjectMembers[index] = newProjectMember;
                }
                else {
                    updatedProjectMembers.splice(index, 1);
                }
            } else {
                if (newProjectMember.state === ActiveState.ACTIVE && newProjectMember.projectActiveState === ActiveState.ACTIVE) {
                    updatedProjectMembers.push(newProjectMember);
                }
            }
        });
        if (shouldRefreshToken) {
            authContext.refreshToken(60 * 60);
        }
        return updatedProjectMembers.sort(sortProjectMemberByDate);
    }


    const getProjectMemberSearch = (): ProjectMember => {
        const urlState = urlContext.getUrlState();
        return {
            projectActiveState: ActiveState.ACTIVE,
        }
    }

    const searchProjectMembers = (projectMemberSearch: ProjectMember): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(projectMemberSearch, currentProjectMemberSearch);
        matched = matched && projectMemberSearch?.id === currentProjectMemberSearch?.id;
        matched = matched && projectMemberSearch?.applicationProfileId === currentProjectMemberSearch?.applicationProfileId;
        matched = matched && projectMemberSearch?.projectId === currentProjectMemberSearch?.projectId;
        if (!matched) {
            projectMemberSearch.searchIndexStart = 0;
            projectMemberSearch.searchIndexStop = maxProjectMembersToFetchAtOnce;
            setCurrentProjectMemberSearch(projectMemberSearch);
            setProjectMembers(getProjectMembersForLoggedInUser());
        }
    }

    const getProjectMembers = (): Array<ProjectMember> => {
        return projectMembers.filter(projectMember => projectMember.projectActiveState !== ActiveState.INACTIVE);
    }

    const getProjectMembersForLoggedInUser = (): Array<ProjectMember> => {
        const applicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser();
        return applicationProfile ? getProjectMembers().filter(projectMember => projectMember.applicationProfileId === applicationProfile.id) : [];
    }

    const getProjectMember = (id: Guid | undefined) => {
        return id === undefined ? undefined : getProjectMembers().find(projectMember => projectMember.id === id);
    }

    const addTemporaryProjectMember = (projectMember: ProjectMember) => {
        setProjectMembers(mergeProjectMembers(projectMembers, [projectMember]));
    }

    const mutateProjectMember = (projectMember: ProjectMember): Promise<Guid | undefined> => {
        return new Promise((resolve, reject) => projectMemberMutationsContext.mutateProjectMember(projectMember, (documentId, variables) => {
            projectMember = { ...projectMember, ...variables };
            projectMember.id = documentId;
            if (guidIsNullOrEmpty(projectMember.applicationProfileId)) {
                projectMember.applicationProfileName = projectMember.temporaryApplicationProfileName;
                projectMember.applicationProfileEmail = projectMember.temporaryApplicationProfileEmail;
                projectMember.applicationProfilePhoneNumber = projectMember.temporaryApplicationProfilePhoneNumber;
                projectMember.applicationProfileHseCardNumber = projectMember.temporaryApplicationProfileHseCardNumber;
            }
            setProjectMembers(mergeProjectMembers(projectMembers, [projectMember]));
            resolve(documentId);
        }, (reason) => reject(reason)));
    }

    const inviteProjectMember = async (projectId: Guid, emails: string[]): Promise<void> => {
        const options = getInviteProjectMembersGraphqlMutationOptions(projectId, emails);
        await inviteProjectMemberMutation(options);
    }

    const getSelectedProjectMember = (noGlobalAccess?: boolean): ProjectMember | undefined => {
        noGlobalAccess = noGlobalAccess ?? false;
        let selectedProjectMember = getProjectMembersForLoggedInUser().find(projectMember => projectMember.projectId === selectedProjectId);
        if (!noGlobalAccess && userHasGlobalProjectAccess(authContext) && !selectedProjectMember && (selectedProjectId ?? '').trim().length > 0) {
            const selectedProject = projectContext.getProject(selectedProjectId);
            if (selectedProject) {
                selectedProjectMember = {
                    applicationProfileId: applicationProfileContext.getApplicationProfileForLoggedInUser()?.id,
                    projectId: selectedProject.id,
                    projectName: getGlobalProjectName(selectedProject),
                    memberAccess: MemberAccess.MEMBER,
                    memberState: MemberState.ACTIVE,
                    projectActiveState: selectedProject.state,
                    applicationProfileUserId: applicationProfileContext.getApplicationProfileForLoggedInUser()?.userId,
                }
            }
        }
        return selectedProjectMember;
    }

    const setSelectedProjectMember = (projectId: Guid | undefined, cleanUrlState: boolean | undefined = true): void => {
        setSelectedProjectId(projectId);
        replaceUrlState(projectId, cleanUrlState);
    }

    const hasProjectAccess = (roleType: RoleType, projectId?: Guid): boolean => {
        projectId = projectId ?? getSelectedProjectMember()?.projectId;
        return checkProjectAccess(roleType, authContext.accountRoles(), projectId);
    }

    const downloadProjectMemberReport = (companyLogo: string, 
                                         applicationProfile: ApplicationProfile,
                                         projectMember: ProjectMember,
                                         certificates: Certificate[], 
                                         informationVerifications: InformationVerification[],
                                         exportType?: projectMemberTemplateAlternatives): void => {
        exportType = exportType ?? 'projectMemberReport';
        queryTemplateEngineToProduceProjectMemberReport(
            companyLogo,
            applicationProfile,
            projectMember,
            certificates,
            informationVerifications,
            exportType,
            templateEngineQueriesContext,
            languageContext);
    }

    const downloadProjectMemberCheckedInEventsReport = (companyLogo: string, 
                                                        project: Project,
                                                        projectMembers: ProjectMember[],
                                                        projectMemberCheckedInEvents: ProjectMemberCheckedInEvent[],
                                                        exportType?: projectMemberCheckedInEventsTemplateAlternatives): void => {
        exportType = exportType ?? 'projectMemberCheckedInEvents';
        queryTemplateEngineToProduceProjectMemberCheckedInEventsReport(
            companyLogo,
            project,
            projectMembers,
            projectMemberCheckedInEvents,
            exportType,
            templateEngineQueriesContext,
            languageContext);
    }

    useEffect(() => {
        const applicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser();
        if (applicationProfile?.id) {
            projectMemberQueriesContext.queryProjectMembers({ applicationProfileId: applicationProfile.id });
        }
    }, [applicationProfileContext.getApplicationProfileForLoggedInUser()])

    useEffect(() => {
        const selectedProject = getSelectedProjectMember();
        if (selectedProject?.projectId && !('selectedProjectId' in urlState)) {
            replaceUrlState(selectedProject?.projectId);
        }
        else if (!selectedProject?.projectId && !('selectedProjectId' in urlState) && projectMembers.length > 0) {
            setSelectedProjectMember(projectMembers[0].projectId);
        }
    }, [urlContext.currentLocation, selectedProjectId, projectMembers])

    useEffect(() => {
        if (currentProjectMemberSearch) {
            projectMemberQueriesContext.queryProjectMembers(currentProjectMemberSearch);
        }
    }, [currentProjectMemberSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setProjectMembers([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setProjectMembers(mergeProjectMembers(projectMembers, projectMemberQueriesContext.fetchedProjectMembers));
        if (projectMemberQueriesContext.fetchedProjectMembers.length >= maxProjectMembersToFetchAtOnce && currentProjectMemberSearch) {
            currentProjectMemberSearch.searchIndexStart = (currentProjectMemberSearch.searchIndexStart ?? 0) + projectMemberQueriesContext.fetchedProjectMembers.length;
            currentProjectMemberSearch.searchIndexStop = currentProjectMemberSearch.searchIndexStart + maxProjectMembersToFetchAtOnce;
            setCurrentProjectMemberSearch({ ...currentProjectMemberSearch });
        }
    }, [projectMemberQueriesContext.fetchedProjectMembers]);

    useEffect(() => {
        setProjectMembers(mergeProjectMembers(projectMembers, projectMemberSubscriptionsContext.subscribedProjectMembers));
    }, [projectMemberSubscriptionsContext.subscribedProjectMembers]);

    const projectMemberContext = {
        getProjectMemberSearch,
        searchProjectMembers,
        getProjectMembers,
        getProjectMembersForLoggedInUser,
        getProjectMember,
        getSelectedProjectMember,
        setSelectedProjectMember,
        addTemporaryProjectMember,
        mutateProjectMember,
        hasProjectAccess,
        downloadProjectMemberReport,
        downloadProjectMemberCheckedInEventsReport,
        loadingProjectMembers: projectMemberQueriesContext.queryResponse.loading,
        getGlobalProjectName,
        inviteProjectMember,
    };

    return (
        <ProjectMemberContext.Provider value={projectMemberContext}>
            {children}
        </ProjectMemberContext.Provider>
    );
}

export const useProjectMemberContext = (): ProjectMemberContext => {
    return useContext(ProjectMemberContext);
}
