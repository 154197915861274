import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { Project } from "../../../contracts/contracts";
import { getProjectsGraphqlMutationOptions } from "../mutations/projectMutations";

export const QUERY_PROJECT: DocumentNode = gql(`
   query projects(
        $searchIndexStart: Int
        $searchIndexStop: Int
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $applicationProfileId: UUID
      	$name: String
        $address: ProjectAddressInput
        $projectDescription: String
        $projectState: ProjectState
        $hseScanningActivated: Boolean
        $parentProjectCompanyActivated: Boolean
        $phoneNumberFieldIsMandatoryOnApplicationProfile: Boolean
        $relativesFieldIsMandatoryOnApplicationProfile: Boolean
        $hseCardNumberFieldIsMandatoryOnApplicationProfile: Boolean
        $hseCertificatePictureUploadIsMandatory: Boolean
        $generalCertificatePictureUploadIsMandatory: Boolean
        $maxNumberOfRequestingMemberAccessTries: Int
        $minimumPercentageVerifiedInformations: Float
        $autoApproveProjectMembers: Boolean
        $enableCheckIn: Boolean
        $infoTechSettings: InfoTechSettingsInput
    ) {
        projects(
            searchIndexStart: $searchIndexStart
            searchIndexStop: $searchIndexStop
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            applicationProfileId: $applicationProfileId
            name: $name
            address: $address
            projectDescription: $projectDescription
            projectState: $projectState
            hseScanningActivated: $hseScanningActivated
            parentProjectCompanyActivated: $parentProjectCompanyActivated
            phoneNumberFieldIsMandatoryOnApplicationProfile: $phoneNumberFieldIsMandatoryOnApplicationProfile
            relativesFieldIsMandatoryOnApplicationProfile: $relativesFieldIsMandatoryOnApplicationProfile
            hseCardNumberFieldIsMandatoryOnApplicationProfile: $hseCardNumberFieldIsMandatoryOnApplicationProfile
            hseCertificatePictureUploadIsMandatory: $hseCertificatePictureUploadIsMandatory
            generalCertificatePictureUploadIsMandatory: $generalCertificatePictureUploadIsMandatory
            maxNumberOfRequestingMemberAccessTries: $maxNumberOfRequestingMemberAccessTries
            minimumPercentageVerifiedInformations: $minimumPercentageVerifiedInformations
            autoApproveProjectMembers: $autoApproveProjectMembers
            enableCheckIn: $enableCheckIn
            infoTechSettings: $infoTechSettings
        ) {
            id
            documentType
            stateTime
            state
            created
            applicationProfileId
            name
            address {
                street
                city
                state
                postcode
                country
                coordinate {
                    latitude
                    longitude
                }
            }
            projectDescription
            projectState
            hseScanningActivated
            parentProjectCompanyActivated
            phoneNumberFieldIsMandatoryOnApplicationProfile
            relativesFieldIsMandatoryOnApplicationProfile
            hseCardNumberFieldIsMandatoryOnApplicationProfile
            hseCertificatePictureUploadIsMandatory
            generalCertificatePictureUploadIsMandatory
            maxNumberOfRequestingMemberAccessTries
            minimumPercentageVerifiedInformations
            autoApproveProjectMembers
            enableCheckIn
            infoTechSettings {
                enabled
                infoTechApiKey
                infoTechProjectId
                infoTechProjectDescription
                infoTechAccessGroupId
                infoTechAccessGroupName
            }
        }
    }
`);

export const getProjectsGraphqlQueryOptions = (project: Partial<Project>): QueryHookOptions => {
    const options = getProjectsGraphqlMutationOptions(project)
    options.variables = options.variables ?? {};
    if (project.searchIndexStart !== undefined) options.variables['searchIndexStart'] = project.searchIndexStart;
    if (project.searchIndexStop !== undefined) options.variables['searchIndexStop'] = project.searchIndexStop;
    return options;
}
