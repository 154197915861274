import React, { createContext, useContext, useEffect, useState } from "react";
import { ProjectMember, DocumentType, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface ProjectMemberSubscriptionsContext {
    subscribedProjectMembers: Array<ProjectMember>,
}

const ProjectMemberSubscriptionsContext = createContext<ProjectMemberSubscriptionsContext>(null as unknown as ProjectMemberSubscriptionsContext);

export const ProjectMemberSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedProjectMembers, setSubscribedProjectMembers] = useState<Array<ProjectMember>>([]);

    const updateSubscribedProjectMembers = (stateDocuments: StateDocument[]) => {
        const subscribedProjectMembers = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.PROJECT_MEMBER && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as ProjectMember);
        setSubscribedProjectMembers(subscribedProjectMembers);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedProjectMembers([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedProjectMembers(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectMemberContext: ProjectMemberSubscriptionsContext = {
        subscribedProjectMembers,
    };

    return (
        <ProjectMemberSubscriptionsContext.Provider value={projectMemberContext}>
            {children}
        </ProjectMemberSubscriptionsContext.Provider>
    );
}

export const useProjectMemberSubscriptionsContext = (): ProjectMemberSubscriptionsContext => {
    return useContext(ProjectMemberSubscriptionsContext);
}
