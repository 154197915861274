import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ProjectCompany } from "../../../contracts/contracts";
import { getProjectCompaniesGraphqlMutationOptions } from "../mutations/projectCompanyMutations";

export const QUERY_PROJECT_COMPANY: DocumentNode = gql(`
   query projectCompanies(
        $searchIndexStart: Int
        $searchIndexStop: Int
        $id: UUID
        $state: ActiveState
        $created: DateTime
        $projectId: UUID
        $parentProjectCompanyId: UUID
        $projectCompanyGroup: String
      	$name: String
      	$organizationNumber: String
        $projectCompanyDescription: String
        $projectCompanyContactName: String
        $projectCompanyContactEmail: String
        $projectCompanyContactPhoneNumber: String
        $parentProjectCompanyName: String
        $parentProjectCompanyOrganizationNumber: String
        $projectCompanyLevel: Int
        $infoTechSettings: InfoTechSettingsInput
    ) {
        projectCompanies(
            searchIndexStart: $searchIndexStart
            searchIndexStop: $searchIndexStop
            id: $id
            state: $state
            created: $created
            projectId: $projectId
            parentProjectCompanyId: $parentProjectCompanyId
            projectCompanyGroup: $projectCompanyGroup
            name: $name
            organizationNumber: $organizationNumber
            projectCompanyDescription: $projectCompanyDescription
            projectCompanyContactName: $projectCompanyContactName
            projectCompanyContactEmail: $projectCompanyContactEmail
            projectCompanyContactPhoneNumber: $projectCompanyContactPhoneNumber
            parentProjectCompanyName: $parentProjectCompanyName
            parentProjectCompanyOrganizationNumber: $parentProjectCompanyOrganizationNumber
            projectCompanyLevel: $projectCompanyLevel
            infoTechSettings: $infoTechSettings
        ) {
            id
            documentType
            stateTime
            state
            created
            projectId
            parentProjectCompanyId
            projectCompanyGroup
            name
            organizationNumber
            projectCompanyDescription
            projectCompanyContactName
            projectCompanyContactEmail
            projectCompanyContactPhoneNumber
            parentProjectCompanyName
            parentProjectCompanyOrganizationNumber
            projectCompanyLevel
            infoTechSettings {
                enabled
                infoTechApiKey
                infoTechProjectId
                infoTechProjectDescription
                infoTechAccessGroupId
                infoTechAccessGroupName
            }
        }
    }
`);

export const getProjectCompaniesGraphqlQueryOptions = (projectCompany: Partial<ProjectCompany>): QueryHookOptions => {
    const options = getProjectCompaniesGraphqlMutationOptions(projectCompany)
    options.variables = options.variables ?? {};
    if (projectCompany.searchIndexStart !== undefined) options.variables['searchIndexStart'] = projectCompany.searchIndexStart;
    if (projectCompany.searchIndexStop !== undefined) options.variables['searchIndexStop'] = projectCompany.searchIndexStop;
    if (projectCompany.parentProjectCompanyName !== undefined && projectCompany.parentProjectCompanyName !== null) options.variables['parentProjectCompanyName'] = projectCompany.parentProjectCompanyName;
    if (projectCompany.parentProjectCompanyOrganizationNumber !== undefined && projectCompany.parentProjectCompanyOrganizationNumber !== null) options.variables['parentProjectCompanyOrganizationNumber'] = projectCompany.parentProjectCompanyOrganizationNumber;
    if (projectCompany.projectCompanyLevel !== undefined && projectCompany.projectCompanyLevel !== null) options.variables['projectCompanyLevel'] = projectCompany.projectCompanyLevel;
    return options;
}
