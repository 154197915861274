import React, { createContext, useContext, useEffect } from "react";
import queryString from 'query-string';
import { BrowserRouter } from "react-router-dom";
import { alphaNumericString } from "../../utils/stringTools";
import { localStorageIdpHintKey, localStorageRedirectToRegistryKey, localStorageTenantKey } from "../../utils/localStorageKeys";

export interface BrowserContext {
    getIdpHint: (useDefaultIdpHintFromLocalStorage?: boolean) => string;
    getRedirectToRegistry: () => boolean;
    getTenantName: (useDefaultTenantFromLocalStorage?: boolean) => string;
    getTenantRole: (useDefaultTenantFromLocalStorage?: boolean) => string;
    setTenantName: (tenant: string, idpHint?: string, register?: boolean) => void;
    isTenantNameAvailable: () => boolean;
    globalTenantName: string;
    getTenantBaseUrl: () => string;
}

const BrowserContext = createContext<BrowserContext>(null as unknown as BrowserContext);

export const BrowserContextProvider: React.FC<{}> = ({ children }) => {

    const baseUrl: string = '';
    const globalTenantName: string = process.env.REACT_APP_DEFAULT_TENANT ?? 'onsite';

    const getIdpHint = (useDefaultIdpHintFromLocalStorage?: boolean): string => {
        useDefaultIdpHintFromLocalStorage = useDefaultIdpHintFromLocalStorage ?? true;
        const defaultIdpHint = useDefaultIdpHintFromLocalStorage ? (localStorage.getItem(localStorageIdpHintKey) || '') : '';
        const urlPath = window.location.href;
        const parsedUrl = queryString.parseUrl(urlPath);
        let idpHint = parsedUrl.query[localStorageIdpHintKey] ?? defaultIdpHint;
        if (Array.isArray(idpHint)) {
            idpHint = idpHint.length > 0 ? idpHint[0] : defaultIdpHint; 
        }
        return idpHint.trim();
    }

    const getRedirectToRegistry = (): boolean => {
        const defaultRedirectToRegistry = "false";
        const urlPath = window.location.href;
        const parsedUrl = queryString.parseUrl(urlPath);
        let redirectToRegistry = parsedUrl.query[localStorageRedirectToRegistryKey] ?? defaultRedirectToRegistry;
        if (Array.isArray(redirectToRegistry)) {
            redirectToRegistry = redirectToRegistry.length > 0 ? redirectToRegistry[0] : defaultRedirectToRegistry; 
        }
        return redirectToRegistry.toLowerCase() === "true";
    }

    const getTenantName = (useDefaultTenantFromLocalStorage?: boolean): string => {
        useDefaultTenantFromLocalStorage = useDefaultTenantFromLocalStorage ?? true;
        const defaultTenant = useDefaultTenantFromLocalStorage ? (localStorage.getItem(localStorageTenantKey) || '') : '';
        let tenant = defaultTenant;
        const urlPath = window.location.pathname;
        if (urlPath.indexOf(baseUrl) >= 0) {
            tenant = urlPath.substring(baseUrl.length);
            let slashIndex = tenant.indexOf('/');
            if (slashIndex === 0 && tenant.length > 1) {
                tenant = tenant.slice(1);
            }
            slashIndex = tenant.indexOf('/');
            if (slashIndex > 0) {
                tenant = tenant.slice(0, slashIndex);
            }
            tenant = tenant.replace('/', '');
            if (tenant.length === 0) {
                tenant = defaultTenant;
            }
        }
        return alphaNumericString(tenant).toLowerCase();
    }

    const getTenantBaseUrlWithIdpHint = (tenant: string, idpHint: string, register?: boolean): string => {
        let url = `${baseUrl}/${tenant}`;
        let urlParamsSet = false;
        if (idpHint.length > 0) {
            url = `${url}?${localStorageIdpHintKey}=${idpHint}`;
            urlParamsSet = true;
        }
        if (register) {
            url = `${url}${urlParamsSet ? '&' : '?'}${localStorageRedirectToRegistryKey}=${register}`;
        }
        return url;
    }

    const setTenantName = (tenant: string, idpHint?: string, register?: boolean): void => {
        idpHint = (idpHint ?? '').trim();
        const url = getTenantBaseUrlWithIdpHint(tenant, idpHint, register);
        window.location.replace(url);
    }

    const isTenantNameAvailable = (): boolean => {
        const urlPath = window.location.pathname;
        return urlPath.startsWith(baseUrl) && getTenantName() !== '';
    }

    const getTenantBaseUrl = (): string => {
        return `${baseUrl}/${getTenantName()}`;
    }

    const getTenantRole = (useDefaultTenantFromLocalStorage?: boolean): string => {
        return `tenant-onsite-${getTenantName(useDefaultTenantFromLocalStorage)}`
    }

    // useEffect(() => {
    //     if (!isTenantNameAvailable()) {
    //         window.location.replace(getTenantBaseUrlWithIdpHint(getTenantName(), getIdpHint()));
    //     }
    // })

    const authProviderContextValue: BrowserContext = {
        getIdpHint,
        getRedirectToRegistry,
        getTenantName,
        getTenantRole,
        setTenantName,
        isTenantNameAvailable,
        globalTenantName,
        getTenantBaseUrl,
    }

    return <BrowserContext.Provider value={authProviderContextValue}>
        <BrowserRouter forceRefresh={false} basename={getTenantBaseUrl()}>
            {children}
        </BrowserRouter>
    </BrowserContext.Provider>
}


export const useBrowserContext = (): BrowserContext => {
    return useContext(BrowserContext);
}