import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, InformationVerification } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useLanguageContext } from "../language/LanguageContext";
import { useUrlContext } from "../url/urlContext";
import { InformationVerificationMutationsContextProvider, useInformationVerificationMutationsContext } from "./mutations/informationVerificationMutationsContext";
import { InformationVerificationQueriesContextContext, useInformationVerificationQueriesContext } from "./queries/informationVerificationQueriesContext";
import { InformationVerificationSubscriptionsContextProvider, useInformationVerificationSubscriptionsContext } from "./subscriptions/informationVerificationSubscriptionsContext";

export interface InformationVerificationContext {
    getInformationVerificationSearch: () => InformationVerification,
    searchInformationVerifications: (informationVerificationSearch: InformationVerification, forceLoad?: boolean) => void,
    getInformationVerifications: () => InformationVerification[],
    getInformationVerification: (id: Guid | undefined) => (InformationVerification | undefined),
    mutateInformationVerification: (informationVerification: InformationVerification) => Promise<Guid | undefined>,
    loadingInformationVerifications: boolean,
}

const InformationVerificationContext = createContext<InformationVerificationContext>(null as unknown as InformationVerificationContext);

export const sortInformationVerificationByDate = (a: InformationVerification, b: InformationVerification) => {
    if ((a.created ?? "") < (b.created ?? "")) { return -1; }
    if ((a.created ?? "") > (b.created ?? "")) { return 1; }
    return 0;
}

export enum InformationVerificationTabs {
    all = "all",
    details = "details",
}

export const InformationVerificationContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <InformationVerificationMutationsContextProvider>
            <InformationVerificationQueriesContextContext>
                <InformationVerificationSubscriptionsContextProvider>
                    <InformationVerificationSubContextProvider>
                        {children}
                    </InformationVerificationSubContextProvider>
                </InformationVerificationSubscriptionsContextProvider>
            </InformationVerificationQueriesContextContext>
        </InformationVerificationMutationsContextProvider>
    );
}

export const InformationVerificationSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();

    const informationVerificationMutationsContext = useInformationVerificationMutationsContext();
    const informationVerificationQueriesContext = useInformationVerificationQueriesContext();
    const informationVerificationSubscriptionsContext = useInformationVerificationSubscriptionsContext();

    const [currentInformationVerificationSearch, setCurrentInformationVerificationSearch] = useState<InformationVerification | undefined>(undefined);
    const [informationVerifications, setInformationVerifications] = useState<InformationVerification[]>([]);
    const maxInformationVerificationsToFetchAtOnce = 100;


    const mergeInformationVerifications = (oldInformationVerifications: Array<InformationVerification>, newInformationVerifications: Array<InformationVerification>): Array<InformationVerification> => {
        const updatedInformationVerifications = oldInformationVerifications.slice();
        if (!newInformationVerifications) {
            console.error(`Received undefined set of InformationVerifications: ${newInformationVerifications}`);
            return [];
        }
        newInformationVerifications.forEach(newInformationVerification => {
            newInformationVerification.created = new Date(newInformationVerification.created ?? 0);

            const index = updatedInformationVerifications.findIndex(informationVerification => informationVerification.id === newInformationVerification.id);
            if (index >= 0) {
                if (newInformationVerification.state === ActiveState.ACTIVE) {
                    updatedInformationVerifications[index] = newInformationVerification;
                }
                else {
                    updatedInformationVerifications.splice(index, 1);
                }
            } else {
                if (newInformationVerification.state === ActiveState.ACTIVE) {
                    updatedInformationVerifications.push(newInformationVerification);
                }
            }
        });
        return updatedInformationVerifications.sort(sortInformationVerificationByDate);
    }


    const getInformationVerificationSearch = (): InformationVerification => {
        const urlState = urlContext.getUrlState();
        return {
        }
    }

    const searchInformationVerifications = (informationVerificationSearch: InformationVerification, forceLoad = false): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(informationVerificationSearch, currentInformationVerificationSearch);
        matched = matched && informationVerificationSearch?.id === currentInformationVerificationSearch?.id;
        matched = matched && informationVerificationSearch?.applicationProfileId === currentInformationVerificationSearch?.applicationProfileId;
        matched = matched && informationVerificationSearch?.projectId === currentInformationVerificationSearch?.projectId;

        if (!matched || forceLoad) {
            informationVerificationSearch.searchIndexStart = 0;
            informationVerificationSearch.searchIndexStop = maxInformationVerificationsToFetchAtOnce;
            setCurrentInformationVerificationSearch(informationVerificationSearch);
            setInformationVerifications([]);
        }
    }

    const getInformationVerifications = (): Array<InformationVerification> => {
        return informationVerifications
    }

    const getInformationVerification = (id: Guid | undefined) => {
        return id === undefined ? undefined : informationVerifications.find(informationVerification => informationVerification.id === id);
    }

    const mutateInformationVerification = async (informationVerification: InformationVerification): Promise<Guid | undefined> => {
        return new Promise<Guid | undefined>((resolve, reject) => informationVerificationMutationsContext.mutateInformationVerification(informationVerification, (documentId, variables) => {
            informationVerification = { ...informationVerification, ...variables };
            informationVerification.id = documentId;
            setInformationVerifications(mergeInformationVerifications(informationVerifications, [informationVerification]));
            resolve(documentId);
        }, (errors) => {
            reject(errors);
        }))
    }

    useEffect(() => {
        if (currentInformationVerificationSearch) {
            informationVerificationQueriesContext.queryInformationVerifications(currentInformationVerificationSearch);
        }
    }, [currentInformationVerificationSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setInformationVerifications([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setInformationVerifications(mergeInformationVerifications(informationVerifications, informationVerificationQueriesContext.fetchedInformationVerifications));
        if (informationVerificationQueriesContext.fetchedInformationVerifications.length >= maxInformationVerificationsToFetchAtOnce && currentInformationVerificationSearch) {
            currentInformationVerificationSearch.searchIndexStart = (currentInformationVerificationSearch.searchIndexStart ?? 0) + informationVerificationQueriesContext.fetchedInformationVerifications.length;
            currentInformationVerificationSearch.searchIndexStop = currentInformationVerificationSearch.searchIndexStart + maxInformationVerificationsToFetchAtOnce;
            setCurrentInformationVerificationSearch({...currentInformationVerificationSearch});
        }
    }, [informationVerificationQueriesContext.fetchedInformationVerifications]);

    useEffect(() => {
        setInformationVerifications(mergeInformationVerifications(informationVerifications, informationVerificationSubscriptionsContext.subscribedInformationVerifications));
    }, [informationVerificationSubscriptionsContext.subscribedInformationVerifications]);

    const informationVerificationContext = {
        getInformationVerificationSearch,
        searchInformationVerifications,
        getInformationVerifications,
        getInformationVerification,
        mutateInformationVerification,
        loadingInformationVerifications: informationVerificationQueriesContext.queryResponse.loading,
    };

    return (
        <InformationVerificationContext.Provider value={informationVerificationContext}>
            {children}
        </InformationVerificationContext.Provider>
    );
}

export const useInformationVerificationContext = (): InformationVerificationContext => {
    return useContext(InformationVerificationContext);
}
