import * as React from 'react';

import { Project, MemberAccess } from '../../contracts/contracts';
import CardActions from "@mui/material/CardActions";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Grid, Card, CardMedia, CardContent, Typography, Button } from "@mui/material";
import { getStorageFileFullKey } from "../../utils/fileTools";
import { useStoredFilesByFileKey } from "../../hooks/useStoredFilesByFileKey";
import default_image_placeholder from "../../data/pictures/image_placeholder.png";
import LoadingWrapper from "../loadingComponents/loadingWrapper";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import { useState } from 'react';
import { useProjectContext } from '../../contexts/project/projectContext';

type props = {
	project: Project;
	onProjectDetails?: (project: Project) => void;
	onJoinProject: (project?: Project) => void;
}

const ProjectsCard: React.FC<props> = ({ project, onProjectDetails, onJoinProject }) => {
	const languageContext = useLanguageContext();
	const projectMemberContext = useProjectMemberContext();
	const projectContext = useProjectContext();
	const fileKey = getStorageFileFullKey(project?.id ?? '');

	const [base64Images, setBase64Images] = useState<string[]>([]);
	const [base64ImageProjectImages, base64ImageProjectImagesMetadata, imageLoading] = useStoredFilesByFileKey(fileKey, base64Images, setBase64Images);
	const projectMember = projectMemberContext.getProjectMembersForLoggedInUser().find(projectMember => projectMember.projectId === project?.id);

	let memberAccessTitle = languageContext.getMessage('joinProject');
	if (projectMember?.memberAccess === MemberAccess.PUBLIC) {
		memberAccessTitle = languageContext.getMessage('followingProject');
	}
	if (projectMember?.memberAccess === MemberAccess.MEMBER) {
		memberAccessTitle = languageContext.getMessage('member');
	}
	if (projectMember?.memberAccess === MemberAccess.REQUESTING_MEMBER_ACCESS) {
		memberAccessTitle = languageContext.getMessage('pendingApproval');
	}
	if (projectMember?.memberAccess === MemberAccess.DENIED_MEMBER_ACCESS) {
		memberAccessTitle = languageContext.getMessage('denied');
	}

	const projectTitle = projectContext.getProjectTitleWithProjectState(project);

	return (
		<Grid
			item key={project.id} xs={6} md={3} role="button"
			onClick={() => {
				if (!onProjectDetails) {
					return;
				}
				onProjectDetails(project);
			}}
		>
			<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', color: "text.secondary" }}>
				<LoadingWrapper loading={imageLoading}>
					<CardMedia
						sx={{ height: 140 }}
						component="img"
						alt={project.name}
						loading="lazy"
						image={(base64ImageProjectImages.length > 0 ? base64ImageProjectImages[0] : undefined) ?? default_image_placeholder}
					/>
				</LoadingWrapper>
				<CardContent sx={{ flexGrow: 1, p: 1 }}>
					<Typography variant="body2">
						{projectTitle}
					</Typography>
				</CardContent>
				<CardActions sx={{ px: 1, pt: 0, pb: 1, display: 'flex', justifyContent: 'space-between' }}>
					<Button
						size="small"
						sx={{ pl: 0, justifyContent: 'flex-start' }}
						onClick={(e) => {
							e.stopPropagation();
							onJoinProject(project);
						}}
					>
						{memberAccessTitle}
					</Button>
					<KeyboardArrowRightIcon />
				</CardActions>
			</Card>
		</Grid>
	);
}

export default ProjectsCard
