import React, { createContext, useContext, useEffect, useState } from "react";
import { CompanyInfo } from "../../../contracts/contracts";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_COMPANY_INFO, getCompanyInfoGraphqlQueryOptions } from "./generalIntegrationsQueries";
import { useAuthContext } from "../../auth/authContext";

export interface CompanyInfoQueriesContext {
  fetchedCompanyInfos: CompanyInfo[];
  loading: boolean;
  queryCompanyInfo: (companyInfoSearch: CompanyInfo) => void;
  appendCompanyInfosToCache: (companyInfos: CompanyInfo[]) => void;
  cachedCompanyInfos: CompanyInfo[];
}

const CompanyInfoQueriesContext = createContext<CompanyInfoQueriesContext>(null as unknown as CompanyInfoQueriesContext);

export const CompanyInfoQueriesContextProvider: React.FC<{}> = ({ children }) => {
  const authContext = useAuthContext();
  const [fetchedCompanyInfos, setFetchedCompanyInfos] = useState<CompanyInfo[]>([]);
  const [cachedCompanyInfos, setCachedCompanyInfos] = useState<CompanyInfo[]>([]);

  const [loadQuery, queryResponse] = useLazyQuery(QUERY_COMPANY_INFO);

  const queryCompanyInfo = (companyInfoSearch: CompanyInfo): void => {
    const queryOptions = getCompanyInfoGraphqlQueryOptions(companyInfoSearch);
    loadQuery(queryOptions);
  };

  const appendCompanyInfosToCache = (companyInfos: CompanyInfo[]): void => {
    const updatedCompanyInfosCache = cachedCompanyInfos.slice();
    companyInfos.forEach(companyInfo => {
        const index = updatedCompanyInfosCache.findIndex(cachedStorageFile => cachedStorageFile.organizationNumber === companyInfo.organizationNumber);
        if (index >= 0) {
            updatedCompanyInfosCache[index] = companyInfo;
        } else {
            updatedCompanyInfosCache.push(companyInfo);
        }
    });
    setCachedCompanyInfos(updatedCompanyInfosCache);
  }

  useEffect(() => {
    if (queryResponse.data && queryResponse.data.companyInfos) {
      let newFetchedCompanyInfos: CompanyInfo[] = queryResponse.data.companyInfos;
      newFetchedCompanyInfos = newFetchedCompanyInfos.filter((newFetchedCompanyInfo) => fetchedCompanyInfos.findIndex((fetchedCompanyInfo) => fetchedCompanyInfo.organizationNumber === newFetchedCompanyInfo.organizationNumber) < 0);
      setFetchedCompanyInfos(fetchedCompanyInfos.concat(newFetchedCompanyInfos));
    }
  }, [queryResponse.data]);

  useEffect(() => {
    if (queryResponse.error) {
      console.error(queryResponse.error);
    }
  }, [queryResponse.error]);

  useEffect(() => {
    if (!authContext.authenticated && !authContext.insecure) {
      setFetchedCompanyInfos([]);
    }
  }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

  const companyInfoQueriesContext: CompanyInfoQueriesContext = {
    fetchedCompanyInfos,
    loading: queryResponse.loading,
    queryCompanyInfo,
    appendCompanyInfosToCache,
    cachedCompanyInfos
  };

  return <CompanyInfoQueriesContext.Provider value={companyInfoQueriesContext}>{children}</CompanyInfoQueriesContext.Provider>;
};

export const useCompanyInfoQueriesContext = (): CompanyInfoQueriesContext => {
  return useContext(CompanyInfoQueriesContext);
};
