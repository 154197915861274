import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, Certificate } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useLanguageContext } from "../language/LanguageContext";
import { useUrlContext } from "../url/urlContext";
import { CertificateMutationsContextProvider, useCertificateMutationsContext } from "./mutations/certificateMutationsContext";
import { CertificateQueriesContextContext, useCertificateQueriesContext } from "./queries/certificateQueriesContext";
import { CertificateSubscriptionsContextProvider, useCertificateSubscriptionsContext } from "./subscriptions/certificateSubscriptionsContext";



export interface CertificateContext {
    getCertificateSearch: () => Certificate,
    searchCertificates: (certificateSearch: Certificate) => void,
    getCertificates: () => Certificate[],
    getCertificate: (id: Guid | undefined) => (Certificate | undefined),
    mutateCertificate: (certificate: Certificate) => Promise<Guid | undefined>,
    loadingCertificates: boolean,
}

const CertificateContext = createContext<CertificateContext>(null as unknown as CertificateContext);

export const sortCertificateByDate = (a: Certificate, b: Certificate) => {
    if ((a.created ?? "") < (b.created ?? "")) { return -1; }
    if ((a.created ?? "") > (b.created ?? "")) { return 1; }
    return 0;
}

export enum CertificateTabs {
    all = "all",
    details = "details",
}

export const CertificateContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <CertificateMutationsContextProvider>
            <CertificateQueriesContextContext>
                <CertificateSubscriptionsContextProvider>
                    <CertificateSubContextProvider>
                        {children}
                    </CertificateSubContextProvider>
                </CertificateSubscriptionsContextProvider>
            </CertificateQueriesContextContext>
        </CertificateMutationsContextProvider>
    );
}

export const CertificateSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();

    const certificateMutationsContext = useCertificateMutationsContext();
    const certificateQueriesContext = useCertificateQueriesContext();
    const certificateSubscriptionsContext = useCertificateSubscriptionsContext();

    const [currentCertificateSearch, setCurrentCertificateSearch] = useState<Certificate | undefined>(undefined);
    const [certificates, setCertificates] = useState<Certificate[]>([]);
    const maxCertificatesToFetchAtOnce = 100;


    const mergeCertificates = (oldCertificates: Array<Certificate>, newCertificates: Array<Certificate>): Array<Certificate> => {
        const updatedCertificates = oldCertificates.slice();
        if (!newCertificates) {
            console.error(`Received undefined set of Certificates: ${newCertificates}`);
            return [];
        }
        newCertificates.forEach(newCertificate => {
            newCertificate.created = new Date(newCertificate.created ?? 0);

            const index = updatedCertificates.findIndex(certificate => certificate.id === newCertificate.id);
            if (index >= 0) {
                if (newCertificate.state === ActiveState.ACTIVE) {
                    updatedCertificates[index] = newCertificate;
                }
                else {
                    updatedCertificates.splice(index, 1);
                }
            } else {
                if (newCertificate.state === ActiveState.ACTIVE) {
                    updatedCertificates.push(newCertificate);
                }
            }
        });
        return updatedCertificates.sort(sortCertificateByDate);
    }


    const getCertificateSearch = (): Certificate => {
        const urlState = urlContext.getUrlState();
        return {
            applicationProfileId: urlState.applicationProfileId ?? undefined,
        }
    }

    const searchCertificates = (certificateSearch: Certificate): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(certificateSearch, currentCertificateSearch);
        matched = matched && certificateSearch?.id === currentCertificateSearch?.id;
        matched = matched && certificateSearch?.applicationProfileId === currentCertificateSearch?.applicationProfileId;
        matched = matched && certificateSearch?.certificateType === currentCertificateSearch?.certificateType;
        if (!matched) {
            certificateSearch.searchIndexStart = 0;
            certificateSearch.searchIndexStop = maxCertificatesToFetchAtOnce;
            setCurrentCertificateSearch(certificateSearch);
            setCertificates([]);
        }
    }

    const getCertificates = (): Array<Certificate> => {
        return certificates
    }

    const getCertificate = (id: Guid | undefined) => {
        return id === undefined ? undefined : certificates.find(certificate => certificate.id === id);
    }

    const mutateCertificate = (certificate: Certificate): Promise<Guid | undefined> => {
        return new Promise<Guid | undefined>((resolve, reject) => certificateMutationsContext.mutateCertificate(certificate, (documentId, variables) => {
            certificate = { ...certificate, ...variables };
            certificate.id = documentId;
            setCertificates(mergeCertificates(certificates, [certificate]));
            resolve(documentId);
        }, (errors) => {
            reject(errors);
        }))
    }

    useEffect(() => {
        if (currentCertificateSearch) {
            certificateQueriesContext.queryCertificates(currentCertificateSearch);
        }
    }, [currentCertificateSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setCertificates([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setCertificates(mergeCertificates(certificates, certificateQueriesContext.fetchedCertificates));
        if (certificateQueriesContext.fetchedCertificates.length >= maxCertificatesToFetchAtOnce && currentCertificateSearch) {
            currentCertificateSearch.searchIndexStart = (currentCertificateSearch.searchIndexStart ?? 0) + certificateQueriesContext.fetchedCertificates.length;
            currentCertificateSearch.searchIndexStop = currentCertificateSearch.searchIndexStart + maxCertificatesToFetchAtOnce;
            setCurrentCertificateSearch({...currentCertificateSearch});
        }
    }, [certificateQueriesContext.fetchedCertificates]);

    useEffect(() => {
        setCertificates(mergeCertificates(certificates, certificateSubscriptionsContext.subscribedCertificates));
    }, [certificateSubscriptionsContext.subscribedCertificates]);

    const certificateContext = {
        getCertificateSearch,
        searchCertificates,
        getCertificates,
        getCertificate,
        mutateCertificate,
        loadingCertificates: certificateQueriesContext.queryResponse.loading,
    };

    return (
        <CertificateContext.Provider value={certificateContext}>
            {children}
        </CertificateContext.Provider>
    );
}

export const useCertificateContext = (): CertificateContext => {
    return useContext(CertificateContext);
}
