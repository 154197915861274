import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Box, Card, IconButton, ImageList, ImageListItem, Stack, Link, Tooltip, CircularProgress } from '@mui/material';
import LoadingWrapper from '../loadingComponents/loadingWrapper';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import default_image_placeholder from "../../data/pictures/image_placeholder.png";
import { useStoredFilesByFileKey } from "../../hooks/useStoredFilesByFileKey";
import { downloadBase64File, getStorageFileFullKey } from '../../utils/fileTools';
import { CertificateType } from '../../contracts/contracts';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useQueryHSECardInfo } from '../../hooks/useQueryHSECardInfo';
import { isPictureFile } from '../../utils/compressionTools';
import { getLimitedString } from '../../utils/stringTools';

type Props = {
    onDelete: (id: string) => void;
    onEdit: (id: string, base64Images: string[], base64Filenames: string[]) => void;
    certificateType: CertificateType;
    item: {
        id: string,
        isReadOnly: boolean;
        isProjectMemberCertificate: boolean;
        images: string[],
        imageFilenames: string[],
        title: string,
        overrideImages: string[] | undefined,
        overrideImageFilenames: string[] | undefined,
        placeHolderImages: string[],
        helperText: string | undefined,
        cardId?: string,
        cardNumber?: string,
        cardLink?: string,
        cardStatus?: boolean;
    };
    readonly?: boolean;
}

const ImageCard: React.FC<Props> = ({ onDelete, onEdit, certificateType, item, readonly = false }) => {

    const { id, title, images, imageFilenames, overrideImages, overrideImageFilenames, placeHolderImages, helperText } = item;

    const languageContext = useLanguageContext()

    const fileKey = getStorageFileFullKey(id);
    const [base64Images, setBase64Images] = useState<string[]>([]);
    const [base64ImagesFilenames, setBase64ImagesFilenames] = useState<string[]>([]);
    const [base64ImageSrces, base64ImageSrcesMetadata, imageLoading] = useStoredFilesByFileKey(fileKey, base64Images, setBase64Images, base64ImagesFilenames, setBase64ImagesFilenames);
    const [fetchedHSECardInfo, loadingHSECardInfo] = useQueryHSECardInfo(item.cardId);

    const onEditHandler = () => {
        if (typeof onEdit === 'function') {
            onEdit(id, base64Images, base64ImagesFilenames);
        }
    }

    const onDeleteHandler = () => {
        if (typeof onDelete === 'function') {
            onDelete(id)
        }
    }

    useEffect(() => {
        if (overrideImages) {
            setBase64Images(overrideImages);
        }
        if (overrideImageFilenames) {
            setBase64ImagesFilenames(overrideImageFilenames);
        }
    }, [overrideImages, overrideImageFilenames])

    const showDefaultMarkdownTitleOnly = images.length === 0 && base64ImageSrces.length === 0 && placeHolderImages.length === 0 && !imageLoading && title.trim().length > 0;
    const imageExists = images.length > 0 || base64ImageSrces.length > 0 || imageLoading;
    const showHelperTextAndHighlightAlert = helperText && !imageExists && placeHolderImages?.length > 0;
    const showHelperTextForHseCard = item.cardId && certificateType === CertificateType.HSE;

    const hseCardInfoStatus = item.cardId ? fetchedHSECardInfo.status : item.cardStatus;
    const isActiveCard = showHelperTextForHseCard && hseCardInfoStatus;
    const isUnactiveCard = showHelperTextForHseCard && !hseCardInfoStatus;

    const hseCardTooltip = isActiveCard ? languageContext.getMessage('cardIsActiveTooltip') : isUnactiveCard ? languageContext.getMessage('cardIsUnactiveTooltip') : null;

    const imagesToShow = images.length > 0 ? images :
        (base64ImageSrces.length > 0 ? base64ImageSrces :
            (placeHolderImages.length > 0 ? placeHolderImages :
                [default_image_placeholder]));

    const imageFilenamesToShow = imageFilenames.length > 0 ? imageFilenames :
        (base64ImageSrcesMetadata.length > 0 ? base64ImageSrcesMetadata : []);

    return (
        <Card sx={{ width: '100%' }} variant="outlined">
            <Box padding='5px 10px 5px 13px' display='flex' justifyContent='space-between' alignItems='center'>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5">
                        {title}
                    </Typography>
                    {showHelperTextAndHighlightAlert && !showHelperTextForHseCard ? (
                        <Typography variant="subtitle1" fontStyle={{ color: 'red' }}>
                            {helperText}
                        </Typography>
                    ) : showHelperTextForHseCard ? (
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography variant="subtitle1">
                                <Link href={item.cardLink} variant='inherit' target='__blank'>
                                    {languageContext.getMessage('cardNumber')}: {item.cardNumber}
                                </Link>
                            </Typography>
                            <Tooltip
                                title={hseCardTooltip}
                                placement='bottom'
                            >
                                {loadingHSECardInfo ? <CircularProgress size={18} /> : <CreditCardIcon fontSize='small' color={isActiveCard ? 'success' : 'error'} />}
                            </Tooltip>
                        </Stack>
                    ) : null}
                    {item.isProjectMemberCertificate && <>
                        <Typography variant="subtitle2" fontStyle={{ color: 'red' }}>
                            {languageContext.getMessage('uploadedByProjectAdministrator')}
                        </Typography>
                    </>}
                </Stack>
                {!readonly && (
                    <Stack direction="row" spacing={2}>
                        {!showHelperTextForHseCard ? (
                            <IconButton onClick={onEditHandler} color="primary" aria-label="edit picture" component="span">
                                <EditIcon />
                            </IconButton>
                        ) : (null)}
                        <IconButton onClick={onDeleteHandler} color="primary" aria-label="upload picture" component="span">
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                )}
            </Box>
            {!showDefaultMarkdownTitleOnly && <ImageList sx={{ m: 0, '& img': { height: 200 } }} >
                <ImageListItem cols={3}>
                    <LoadingWrapper loading={imageLoading}>
                        {imagesToShow.map((imageToShow, index) =>
                            isPictureFile(imageToShow) ?
                                <img key={`${imageToShow}-${index}`} src={imageToShow}
                                    alt={title} style={{
                                        height: 'auto',
                                        width: '100%',
                                        objectFit: 'cover'
                                    }} /> : <IconButton
                                        style={{
                                            height: 'auto',
                                            width: '100%',
                                            objectFit: 'cover'
                                        }}
                                        onClick={() => downloadBase64File(undefined, imageToShow ?? '', imageFilenamesToShow[index] ?? title ?? '')}>
                                    {getLimitedString(imageFilenamesToShow[index] ?? title ?? '', 100)}
                                    <FileDownloadIcon fontSize={'large'} />
                                </IconButton>)}
                    </LoadingWrapper>
                </ImageListItem>
            </ImageList>}
        </Card>
    );
}

export default ImageCard;