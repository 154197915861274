import { Fade, Grid, Menu, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { useAuthContext } from "../../contexts/auth/authContext";
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { getLanguageAlternatives, getLanguageIconFlag } from "../../utils/languageTools";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useApplicationProfileContext } from "../../contexts/applicationProfile/applicationProfileContext";
import LanguageDialog from "../languageComponents/LanguageDialog";


const ApplicationBottomNav: React.FC<{ className: string }> = (props) => {
    const projectMemberContext = useProjectMemberContext();
    const applicationProfileContext = useApplicationProfileContext();
    const menuContext = useMenuContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();

    const applicationProfileForLoggedInUser = applicationProfileContext.getApplicationProfileForLoggedInUser();
    const openLanguageSelectionDialog = (applicationProfileForLoggedInUser?.userId ?? '').length > 0 && (applicationProfileForLoggedInUser?.selectedApplicationLanguage ?? '').length === 0;

    const handleOnClickNav = (id: ApplicationRouteId) => {
        const applicationRoute = menuContext.getApplicationRouteById(id);
        menuContext.setSelectedApplicationRoute(id, applicationRoute.params ?? null);
    }

    const [applicationRouteId, setApplicationRouteId] = React.useState<ApplicationRouteId>(menuContext.selectedApplicationRoute.id);

    const getNavItem = (applicationRoute: ApplicationRoute, menuTitle: string): JSX.Element => {
        return <BottomNavigationAction
            key={'bottom_nav_' + menuTitle}
            label={menuTitle}
            value={applicationRoute.id}
            icon={applicationRoute.navIcon}
            onClick={() => {
                setApplicationRouteId(applicationRoute.id);
                handleOnClickNav(applicationRoute.id);
            }}
        />
    }

    useEffect(() => {
        if (menuContext.selectedApplicationRoute.id !== applicationRouteId) {
            setApplicationRouteId(menuContext.selectedApplicationRoute.id);
        }
    }, [applicationRouteId, menuContext.selectedApplicationRoute]);

    const bottomNavigationMenuItems: ApplicationRoute[] = [
        menuContext.getApplicationRouteById(ApplicationRouteId.Project),
        menuContext.getApplicationRouteById(ApplicationRouteId.Profile),
    ];


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const bottomNav = (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 3, }} elevation={3}>
            <BottomNavigation
                showLabels
                value={applicationRouteId}
            >
                {bottomNavigationMenuItems.filter(applicationRoute => applicationRoute && !applicationRoute.hidden && authContext.authorized(applicationRoute, projectMemberContext))
                    .map((applicationRoute, index) => {
                        const menuTitle = applicationRoute.menuTitle;
                        return getNavItem(applicationRoute, menuTitle ?? '')
                    }
                    )}
                <BottomNavigationAction
                    key={'bottom_nav_language'}
                    label={languageContext.getMessage('language')}
                    value={'bottom_nav_language'}
                    icon={getLanguageIconFlag(languageContext.getLanguage())}
                    onClick={handleClick}
                >
                </BottomNavigationAction>
            </BottomNavigation>
        </Paper>
    );
    return (
        <>
            <LanguageDialog 
                open={openLanguageSelectionDialog} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {getLanguageAlternatives(async language => {
                    await applicationProfileContext.setApplicationLanguage(language);
                    handleClose();
                })}
            </Menu>
            <Grid container direction='column' wrap='nowrap'>
                {bottomNav}
            </Grid>
        </>
    );
}

export default ApplicationBottomNav;