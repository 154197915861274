import { format as dateFnsFormat } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import React from 'react';
import { datesAreOnSameDay } from '../../utils/dateTools';

type props = {
    date: Date | undefined;
    format?: string;
    showMonth?: boolean;
    showDatetime?: boolean;
    allowEmptyDate?: boolean;
    ignoredEmptyDateReplacement?: string;
}

export const DateFormatString = (dateFormatProps: props): string => {
    let date = dateFormatProps.date;
    let format = dateFormatProps.format;
    let showMonth = dateFormatProps.showMonth ?? false;
    let showDatetime = dateFormatProps.showDatetime ?? false;
    let allowEmptyDate = dateFormatProps.allowEmptyDate ?? false;
    let ignoredEmptyDateReplacement = dateFormatProps.ignoredEmptyDateReplacement ?? '';

    if (showMonth && !format) {
        format = "MM/yyyy";
    }
    else if (showDatetime && !format) {
        format = "dd/MM/yyyy - HH:mm:ss";
        date = date !== undefined ? utcToZonedTime(date, 'Europe/Berlin') : date;
    }
    format = format ?? "dd/MM/yyyy";
    return date !== undefined && (!datesAreOnSameDay(date, new Date(0)) || allowEmptyDate) ? dateFnsFormat(date, format) : ignoredEmptyDateReplacement;
}

const DateFormat: React.FC<props> = (dateFormatProps) => {
    return (<>{DateFormatString(dateFormatProps)}</>)
}

export default DateFormat;