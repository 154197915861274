import React, { createContext, useContext, useEffect, useState } from "react";
import { Ticket, DocumentType } from "../../contracts/contracts";
import { useAuthContext } from "../auth/authContext";
import { Guid } from "../../utils/common-types";
import { TicketSubscriptionsContextProvider, useTicketSubscriptionsContext } from "./subscriptions/ticketSubscriptionsContext";
import { useStateDocumentQueriesContext } from "../stateDocument/queries/stateDocumentQueriesContext";
import { Dictionary } from "../../global-types";

export interface TicketContext {
    checkReceivedDocumentId: (documentId: Guid | undefined) => boolean;
    setDocumentIdToSubscribe: (documentId: Guid | undefined) => boolean;
    setDocumentTypesToWatch: (documentTypes: DocumentType[]) => void;
}

const TicketContext = createContext<TicketContext>(null as unknown as TicketContext);

export const TicketContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <TicketSubscriptionsContextProvider>
            <TicketSubContextProvider>
                {children}
            </TicketSubContextProvider>
        </TicketSubscriptionsContextProvider>
    );
}

export const TicketSubContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const ticketSubscriptionsContext = useTicketSubscriptionsContext();
    const stateDocumentQueriesContext = useStateDocumentQueriesContext();
    const [cachedDocumentIds, setCachedDocumentIds] = useState<Dictionary<number>>({});
    const [subscribedDocumentIds, setSubscribedDocumentIds] = useState<Dictionary<boolean>>({});
    const [watchedDocumentTypes, setWatchedDocumentTypes] = useState<Array<DocumentType>>([]);
    const subscribedDocumentIdCacheTimeoutMilliSec = 2*1000;

    const setDocumentTypesToWatch = (documentTypes: DocumentType[]): void => {
        const notWatchedDocumentTypes = documentTypes.filter(documentType => 
            watchedDocumentTypes.findIndex(watchedDocumentType => watchedDocumentType === documentType) < 0);
        setWatchedDocumentTypes(watchedDocumentTypes.concat(notWatchedDocumentTypes))
    }

    const checkIfDocumentIdIsCached = (documentId: Guid | undefined): boolean => {
        if (documentId && documentId in cachedDocumentIds && 
            (new Date().getTime() - cachedDocumentIds[documentId]) < subscribedDocumentIdCacheTimeoutMilliSec) {
            return true;
        }
        return false;
    }

    const documentIdIsSubscribed = (documentId: Guid | undefined): boolean => {
        if (documentId && documentId in subscribedDocumentIds && subscribedDocumentIds[documentId] === true) {
            return true;
        }
        return false;
    }

    const setDocumentIdToSubscribe = (documentId: Guid | undefined): boolean => {
        if (documentId && !checkIfDocumentIdIsCached(documentId)) {
            subscribedDocumentIds[documentId] = true;
            setSubscribedDocumentIds({...subscribedDocumentIds});
            return true;
        }
        return false;
    }

    const queryWatchedStateDocumentsWithTickets = (tickets: Ticket[]): void => {
        const defaultDocumentTypesToWatch: DocumentType[] = [DocumentType.PROJECT_MEMBER];
        const watchedTickets = tickets.filter(ticket => 
            ticket.parentDocumentType && 
            (defaultDocumentTypesToWatch.indexOf(ticket.parentDocumentType) >= 0 ||
            watchedDocumentTypes.indexOf(ticket.parentDocumentType) >= 0))
        stateDocumentQueriesContext.queryStateDocumentsWithTickets(watchedTickets);
    }

    const checkReceivedDocumentId = (documentId: Guid | undefined): boolean => {
        return !documentIdIsSubscribed(documentId);
    }

    useEffect(() => {
        stateDocumentQueriesContext.fetchedStateDocuments.forEach(document => {
            if (document.id) {
                cachedDocumentIds[document.id] = new Date().getTime();
                subscribedDocumentIds[document.id] = false;
            }
        })
        setCachedDocumentIds({...cachedDocumentIds});
        setSubscribedDocumentIds({...subscribedDocumentIds});
    }, [stateDocumentQueriesContext.fetchedStateDocuments]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        queryWatchedStateDocumentsWithTickets(ticketSubscriptionsContext.subscribedTickets);
    }, [ticketSubscriptionsContext.subscribedTickets]);

    const ticketContext: TicketContext = {
        checkReceivedDocumentId,
        setDocumentIdToSubscribe,
        setDocumentTypesToWatch,
    };

    return (
        <TicketContext.Provider value={ticketContext}>
            {children}
        </TicketContext.Provider>
    );
}

export const useTicketContext = (): TicketContext => {
    return useContext(TicketContext);
}
