import { LanguageContext } from "../contexts/language/interfaces";
import { ProjectCompany } from "../contracts/contracts";
import { guidIsNullOrEmpty } from "./guidTools";

export const getProjectCompanyCompanyLevelDescription = (projectCompany: ProjectCompany, languageContext: LanguageContext): string => {
    const projectCompanyLevel = projectCompany.projectCompanyLevel ?? 0;
    const projectCompanyGroup = (projectCompany.projectCompanyGroup ?? '').trim();
    if (projectCompanyLevel <= 0) {
        return guidIsNullOrEmpty(projectCompany.parentProjectCompanyId) && projectCompanyGroup.length === 0 ? languageContext.getMessage('mainContractorShort') : projectCompanyGroup;
    }
    let description = `${languageContext.getMessage('subContractorShort')} ${projectCompanyLevel}. ${languageContext.getMessage('level')}`
    if (projectCompanyGroup.length > 0) {
        description = `${description} ${projectCompanyGroup}`
    }
    if (projectCompany.parentProjectCompanyName) {
        description = `${description} (${projectCompany.parentProjectCompanyName})`
    }
    return description;
}