import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, ApplicationProfile } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { getLanguageFromLocalStorage, useLanguageContext } from "../language/LanguageContext";
import { LanguageType } from "../language/interfaces";
import { useUrlContext } from "../url/urlContext";
import { ApplicationProfileMutationsContextProvider, useApplicationProfileMutationsContext } from "./mutations/applicationProfileMutationsContext";
import { ApplicationProfileQueriesContextContext, useApplicationProfileQueriesContext } from "./queries/applicationProfileQueriesContext";
import { ApplicationProfileSubscriptionsContextProvider, useApplicationProfileSubscriptionsContext } from "./subscriptions/applicationProfileSubscriptionsContext";



export interface ApplicationProfileContext {
    getApplicationProfileSearch: () => ApplicationProfile,
    searchApplicationProfiles: (applicationProfileSearch: ApplicationProfile) => void,
    getApplicationProfiles: () => ApplicationProfile[],
    getApplicationProfile: (id?: Guid | undefined) => (ApplicationProfile | undefined),
    getApplicationProfileForLoggedInUser: () => (ApplicationProfile | undefined),
    mutateApplicationProfile: (applicationProfile: ApplicationProfile) => Promise<Guid | undefined>,
    setApplicationLanguage: (language: LanguageType) => Promise<Guid | undefined>,
    loadingApplicationProfiles: boolean,
}

const ApplicationProfileContext = createContext<ApplicationProfileContext>(null as unknown as ApplicationProfileContext);

export const sortApplicationProfileByDate = (a: ApplicationProfile, b: ApplicationProfile) => {
    if ((a.created ?? "") < (b.created ?? "")) { return -1; }
    if ((a.created ?? "") > (b.created ?? "")) { return 1; }
    return 0;
}

export enum ApplicationProfileTabs {
    all = "all",
    details = "details",
}

export const ApplicationProfileContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <ApplicationProfileMutationsContextProvider>
            <ApplicationProfileQueriesContextContext>
                <ApplicationProfileSubscriptionsContextProvider>
                    <ApplicationProfileSubContextProvider>
                        {children}
                    </ApplicationProfileSubContextProvider>
                </ApplicationProfileSubscriptionsContextProvider>
            </ApplicationProfileQueriesContextContext>
        </ApplicationProfileMutationsContextProvider>
    );
}

export const ApplicationProfileSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();

    const applicationProfileMutationsContext = useApplicationProfileMutationsContext();
    const applicationProfileQueriesContext = useApplicationProfileQueriesContext();
    const applicationProfileSubscriptionsContext = useApplicationProfileSubscriptionsContext();

    const [currentApplicationProfileSearch, setCurrentApplicationProfileSearch] = useState<ApplicationProfile | undefined>(undefined);
    const [applicationProfiles, setApplicationProfiles] = useState<ApplicationProfile[]>([]);
    const [loggedInApplicationProfileFetched, setLoggedInApplicationProfileFetched] = useState<boolean>(false);
    const [fetchingApplicationProfileData, setFetchingApplicationProfileData] = useState<any>({});
    const maxApplicationProfilesToFetchAtOnce = 100;

    const mergeApplicationProfiles = (oldApplicationProfiles: Array<ApplicationProfile>, newApplicationProfiles: Array<ApplicationProfile>): Array<ApplicationProfile> => {
        const updatedApplicationProfiles = oldApplicationProfiles.slice();
        if (!newApplicationProfiles) {
            console.error(`Received undefined set of ApplicationProfiles: ${newApplicationProfiles}`);
            return [];
        }
        newApplicationProfiles.forEach(newApplicationProfile => {
            newApplicationProfile.created = new Date(newApplicationProfile.created ?? 0);
            if (newApplicationProfile.userId && newApplicationProfile.userId === authContext.accountInfo?.id) {
                const languageFromLocalStorage = getLanguageFromLocalStorage();
                if (newApplicationProfile.selectedApplicationLanguage) {
                    languageContext.setLanguage(newApplicationProfile.selectedApplicationLanguage as LanguageType);
                }
                else if (languageFromLocalStorage) {
                    setApplicationLanguage(languageFromLocalStorage as LanguageType);
                }
            }

            const index = updatedApplicationProfiles.findIndex(applicationProfile => applicationProfile.id === newApplicationProfile.id);
            if (index >= 0) {
                if (newApplicationProfile.state === ActiveState.ACTIVE) {
                    updatedApplicationProfiles[index] = newApplicationProfile;
                }
                else {
                    updatedApplicationProfiles.splice(index, 1);
                }
            } else {
                if (newApplicationProfile.state === ActiveState.ACTIVE) {
                    updatedApplicationProfiles.push(newApplicationProfile);
                }
            }
        });
        return updatedApplicationProfiles.sort(sortApplicationProfileByDate);
    }


    const getApplicationProfileSearch = (): ApplicationProfile => {
        const urlState = urlContext.getUrlState();
        const search: ApplicationProfile = {
            id: urlState?.applicationProfileId,
        }
        if (!search.id) {
            search.userId = authContext.accountInfo?.id;
        }
        return search;
    }

    const searchApplicationProfiles = (applicationProfileSearch: ApplicationProfile): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(applicationProfileSearch, currentApplicationProfileSearch);
        matched = matched && applicationProfileSearch?.id === currentApplicationProfileSearch?.id;
        matched = matched && applicationProfileSearch?.userId === currentApplicationProfileSearch?.userId;
        if (!matched) {
            applicationProfileSearch.searchIndexStart = 0;
            applicationProfileSearch.searchIndexStop = maxApplicationProfilesToFetchAtOnce;
            setCurrentApplicationProfileSearch(applicationProfileSearch);
            const currentUserProfile = getApplicationProfileForLoggedInUser();
            setApplicationProfiles(currentUserProfile ? [currentUserProfile] : []);
        }
    }

    const getApplicationProfiles = (): Array<ApplicationProfile> => {
        return applicationProfiles;
    }

    const getApplicationProfile = (id?: Guid | undefined) => {
        return id === undefined ? undefined : applicationProfiles.find(applicationProfile => applicationProfile.id === id);
    }

    const getApplicationProfileByUserId = (userId?: Guid | undefined) => {
        return userId === undefined ? undefined : applicationProfiles.find(applicationProfile => applicationProfile.userId === userId);
    }

    const getApplicationProfileForLoggedInUser = () => {
        const userId = authContext.accountInfo?.id;
        return getApplicationProfileByUserId(userId);
    }

    const mutateApplicationProfile = (applicationProfile: ApplicationProfile): Promise<Guid | undefined> => {
        return new Promise<Guid | undefined>((resolve, reject) => applicationProfileMutationsContext.mutateApplicationProfile(applicationProfile, (documentId, variables) => {
            applicationProfile = { ...applicationProfile, ...variables };
            applicationProfile.id = documentId;
            setApplicationProfiles(mergeApplicationProfiles(applicationProfiles, [applicationProfile]));
            resolve(documentId);
        }, (errors) => {
            reject(errors);
        }))
    }

    const setApplicationLanguage = async (language: LanguageType): Promise<Guid | undefined> => {
        const applicationProfileForLoggedInUser = getApplicationProfileForLoggedInUser();
        if (applicationProfileForLoggedInUser?.userId) {
            applicationProfileForLoggedInUser.selectedApplicationLanguage = language;
            return applicationProfileContext.mutateApplicationProfile(applicationProfileForLoggedInUser);
        }
        languageContext.setLanguage(language);
    }

    useEffect(() => {
        const applicationProfileForLoggedInUser = getApplicationProfileForLoggedInUser();
        fetchingApplicationProfileData.applicationProfileForLoggedInUser = applicationProfileForLoggedInUser;
        if (loggedInApplicationProfileFetched) {
            return;
        }
        if (applicationProfileForLoggedInUser?.id) {
            setLoggedInApplicationProfileFetched(true);
            if (currentApplicationProfileSearch) {
                applicationProfileQueriesContext.queryApplicationProfiles(currentApplicationProfileSearch);
            }
        }
    }, [applicationProfiles])

    useEffect(() => {
        if (currentApplicationProfileSearch && loggedInApplicationProfileFetched) {
            applicationProfileQueriesContext.queryApplicationProfiles(currentApplicationProfileSearch);
        }
    }, [currentApplicationProfileSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setApplicationProfiles([]);
            return;
        }
    }, [authContext.authenticated, authContext.accountInfo]);

    useEffect(() => {
        authContext.onTokenRefreshSubject.subscribe(accessToken => {
            const timestamp: Date = fetchingApplicationProfileData.timestamp ?? new Date(0);
            const now = new Date();
            const diff = (now.getTime() - timestamp.getTime()) / 1000;
            const timeoutSeconds = 10;
            const isTimeout = diff > timeoutSeconds;
            if (!isTimeout) {
                return;
            }
            const accountInfo = authContext.getAccountInfo(accessToken);
            const applicationProfileForLoggedInUser = fetchingApplicationProfileData.applicationProfileForLoggedInUser;
            const applicationProfileDetailsHasChanged = accountInfo?.id && (
                !applicationProfileForLoggedInUser ||
                applicationProfileForLoggedInUser.name !== accountInfo?.name ||
                applicationProfileForLoggedInUser.email !== accountInfo?.email);
            if (applicationProfileDetailsHasChanged && !applicationProfileContext.loadingApplicationProfiles) {
                fetchingApplicationProfileData.timestamp = new Date();
                const applicationProfileQuery: ApplicationProfile = {
                    state: ActiveState.ACTIVE,
                    userId: accountInfo?.id,
                }

                const applicationProfileMutation: ApplicationProfile = {
                    ...applicationProfileQuery,
                    name: accountInfo?.name,
                    email: accountInfo?.email
                }
                mutateApplicationProfile(applicationProfileMutation);
                applicationProfileQueriesContext.queryApplicationProfiles(applicationProfileQuery);
            }
        });
    }, [])

    useEffect(() => {
        setApplicationProfiles(mergeApplicationProfiles(applicationProfiles, applicationProfileQueriesContext.fetchedApplicationProfiles));
        if (applicationProfileQueriesContext.fetchedApplicationProfiles.length >= maxApplicationProfilesToFetchAtOnce && currentApplicationProfileSearch) {
            currentApplicationProfileSearch.searchIndexStart = (currentApplicationProfileSearch.searchIndexStart ?? 0) + applicationProfileQueriesContext.fetchedApplicationProfiles.length;
            currentApplicationProfileSearch.searchIndexStop = currentApplicationProfileSearch.searchIndexStart + maxApplicationProfilesToFetchAtOnce;
            setCurrentApplicationProfileSearch({ ...currentApplicationProfileSearch });
        }
    }, [applicationProfileQueriesContext.fetchedApplicationProfiles]);

    useEffect(() => {
        setApplicationProfiles(mergeApplicationProfiles(applicationProfiles, applicationProfileSubscriptionsContext.subscribedApplicationProfiles));
    }, [applicationProfileSubscriptionsContext.subscribedApplicationProfiles]);

    const applicationProfileContext = {
        getApplicationProfileSearch,
        searchApplicationProfiles,
        getApplicationProfiles,
        getApplicationProfile,
        getApplicationProfileByUserId,
        getApplicationProfileForLoggedInUser,
        mutateApplicationProfile,
        setApplicationLanguage,
        loadingApplicationProfiles: applicationProfileQueriesContext.queryResponse.loading,
    };

    return (
        <ApplicationProfileContext.Provider value={applicationProfileContext}>
            {children}
        </ApplicationProfileContext.Provider>
    );
}

export const useApplicationProfileContext = (): ApplicationProfileContext => {
    return useContext(ApplicationProfileContext);
}
