import { LanguageMap } from "../interfaces";
import { MemberAccess } from "../../../contracts/contracts";

export const enMap: LanguageMap = {
    ok: 'OK',
    in: 'in',
    about: 'About',
    account: 'Account',
    accountType: 'Account Type',
    accountBudget: 'Account budget',
    accountDetails: 'Account details',
    accountGroup: 'Account group',
    accountInvoicedAmount: 'Invoiced amount: Account',
    accountList: 'Accounts',
    accountNumber: 'Account number',
    accountProcurement: 'Account procurement',
    accounts: 'Accounts',
    accountTransactionList: 'Transactions',
    action: 'Action',
    accessControl: 'Access Control',
    ACTIVE: 'Active',
    publish: 'Publish',
    publishToMembers: 'Publish to members',
    addAccount: 'Add Account',
    addInvoice: 'Add Invoice',
    addContract: 'Add Contract',
    adjustment: 'Adjustment',
    adjustments: 'Adjustments',
    accumulated: 'Accumulated',
    all: 'All',
    amount: 'Amount',
    assetFreeDays: 'Asset Free Days',
    assetCondition: 'Asset Condition',
    quantity: 'Quantity',
    qunatityShort: 'QTY',
    analytics: 'Analytics',
    analysis: 'Analysis',
    analyticsView: 'This is analytics',
    and: 'and',
    expectedDate: 'Expected Date',
    answeredDate: 'Answered Date',
    approved_plural: 'Approved',
    approved: 'Approved',
    approvedCost: 'Approved cost',
    ASSISTANT_PROJECT_MANAGER: 'Assistant project manager',
    accrual: 'Accrual',
    asset: 'Asset',
    assetType: 'Asset Type',
    assets: 'Assets',
    assetQuantity: 'Asset Qty',
    assetDetails: 'Asset Details',
    activation: 'Activation',
    accumulatedAccruedPosted: 'Accumulated Accrued Posted',
    averageProjectCost: 'Average Project Cost',
    receivedRegistered: 'Received - Registered',
    revisedForecastIsAboveAccountForecast: 'Revised forecast is above account forecast',
    revisedForecastIsAboveConstructionAdministrationCosts: 'Revised forecast is above construction administration budget',
    producedNotBilled: 'Produced - Not Billed',
    accumulatedProduced: 'Accumulated Produced Value',
    constructionCosts: 'Construction Costs',
    constructionStart: 'Construction Start',
    administrationCosts: 'Administration Costs',
    producedPercentageOfForecast: 'Produced % of Forecast',
    sumProducedValue: 'Sum Produced Value',
    surcharge: 'surcharge',
    balance: 'Balance',
    billed: 'Billed',
    budget: 'Budget',
    burndown: 'Burndown',
    categories: 'Categories',
    cancel: 'Cancel',
    change: 'Change',
    changeOrder: 'Change Order',
    changeOrderBilled: 'Cost',
    changeOrderBudget: 'Change order budget',
    changeOrderProcurement: 'Change order procurement',
    changeOrderShort: 'CO',
    changeOrders: 'Change Orders',
    quantityChangeOrders: 'Change Order Quantity',
    accrualAndOperations: 'Accrual And Operations',
    changes: 'Changes',
    clear: 'Clear',
    close: 'Close',
    position: 'Position',
    columnActorCount: 'Actors',
    columnName: 'Name',
    columnOwnerCount: 'Owners',
    columnStatus: 'Status',
    condition: 'Condition',
    comment: 'Comment',
    comments: 'Comments',
    completion: 'Completion',
    company: 'OnSite AS',
    companyRaw: 'Company',
    companiesRaw: 'Companies',
    newCompany: 'New Company',
    editCompany: 'Edit Company',
    noCompany: 'No Company',
    companySite: 'https://onsite.no/',
    workingFor: 'Working for',
    employer: 'Employer',
    organizationNumber: 'Organization Number',
    followingCompany: 'Following Company',
    followCompany: 'Follow Company',
    joinCompany: 'Join Company',
    companyDetails: 'Company Details',
    consequence: 'Consequence',
    booking: 'Booking',
    bookingType: 'Booking Type',
    bookingDetails: 'Booking Details',
    bookings: 'Bookings',
    contract: 'Contract',
    contractDetails: 'Contract Details',
    contractBudget: 'Contract Budget',
    contractor: 'Contractor',
    contractOrChange: 'Contract/Change',
    contractProcurement: 'Contract procurement',
    contracts: 'Contracts',
    contractCosts: 'Contract Costs',
    contractType: 'Contract Type',
    constructionAdministrationBudget: 'Construction Administration Budget',
    cost: 'Cost',
    costs: 'Costs',
    costRequirements: 'Cost Requirements',
    created: 'Created',
    crew: 'Crew',
    crewList: 'Crewlist',
    currentLanguage: 'English',
    confirm: 'Confirm',
    dark: 'Dark',
    date: 'date',
    days: 'Days',
    dailyStorage: 'Daily Storage',
    delete: 'Delete',
    deliveryDate: 'Delivery Date',
    description: 'Description',
    detailed: 'Detailed',
    details: 'Details',
    scannedDate: 'Scanned Date',
    voucherDate: 'Voucher Date',
    doYouWantToDeleteProject: 'Do you want to delete the project?',
    doYouWantToDeleteCompany: 'Do you want to delete the company?',
    doYouWantToDeleteAsset: 'Do you want to delete the asset?',
    doYouWantToDeleteContainer: 'Do you want to delete the container?',
    doYouWantToDeleteContract: 'Do you want to delete the contract?',
    doYouWantToDeleteProcurement: 'Do you want to delete the procurement?',
    doYouWantToDeleteDisposal: 'Do you want to delete the disposal?',
    doYouWantToDeleteBooking: 'Do you want to delete the booking?',
    doYouWantToDeleteElement: 'Do you want to delete the element?',
    doYouWantToSaveProject: 'Do you want to save the project?',
    doYouWantToSaveCompany: 'Do you want to save the company?',
    doYouWantToSaveAsset: 'Do you want to save the asset?',
    doYouWantToSaveContainer: 'Do you want to save the container?',
    doYouWantToSaveContract: 'Do you want to save the contract?',
    doYouWantToSaveProcurement: 'Do you want to save the procurement?',
    doYouWantToSaveDisposal: 'Do you want to save the disposal?',
    doYouWantToSaveBooking: 'Do you want to save the booking?',
    doYouWantToSaveElement: 'Do you want to save the element?',
    doYouWantToSaveSettings: 'Do you want to save the settings?',
    dueDate: 'Due Date',
    interimAccountedDate: 'Accounted date',
    interimAccountedAmount: 'Accounted amount',
    email: 'Email',
    edit: 'Edit',
    enableProgressConsequence: 'Enable progress consequence',
    energy: 'energy',
    externalName: 'External name',
    expectation: 'Expectation',
    enabled: 'Enabled',
    projects: 'Projects',
    finished: 'Finished',
    final: 'Final',
    finalForecast: 'Final Forecast',
    forecastReport: 'Forecast Report',
    forecast: 'Forecast',
    forecasts: 'Forecasts',
    changesInForecastLastMonth: 'Changes in forecast from last month',
    from: 'From',
    fromDate: 'From date',
    group: 'Group',
    general: 'General',
    global: 'Global',
    handling: 'Handling',
    hello: 'Hello',
    home: 'Home',
    homeView: 'This is home',
    HSE_MANAGER: 'HSE manager',
    id: 'Id',
    INACTIVE: 'inactive',
    info: 'Info',
    interim: 'Interim',
    incoming: 'Incoming',
    includeContractProcurementInForecast: "Use contract procurement in forecast?",
    warningContractProcurementIsLargerThenBudget: "Contract procurement is larger then contract budget, and will be used to estimate the forecast.",
    invoice: 'Invoice',
    invoiceComplete: 'Complete',
    invoicedAmount: 'Invoiced amount',
    invoicedAmountIsGreaterThanProcurment: 'Invoiced amount is greater than procurement',
    invoiceIsPartOfAnotherInvoice: 'Invoice is part of another invoice',
    invoiceIsSplitIntoMultipleInvoicesAndCanNotBeEdited: 'Invoice is split into multiple invoices and can not be edited',
    invoiceMissingComment: 'Incomplete',
    invoiceNumber: 'Invoice number',
    invoices: 'Invoices',
    importAccounts: 'Import accounts',
    ITB_MANAGER: 'ITB responsible',
    landlord: 'Landlord',
    landlordShort: 'Landlord',
    language: 'Language',
    languages: 'Languages',
    mainLanguage: 'Main language',
    last: 'Last',
    light: 'Light',
    list: 'List',
    listHeading: 'Case List',
    listView: 'This is list',
    inTotal: 'In total',
    login: 'Sign in',
    loginWith: 'Sign in with',
    loginWithEmail: 'Sign in with email',
    logout: 'Sign out',
    management: 'Management',
    max: 'Max',
    missingComment: 'Missing comment',
    month: 'Month',
    monthly: 'Monthly',
    monthlySalary: 'Monthly Salary Costs',
    monthlyPercentage: 'Monthly Percentage',
    aMonth: 'Month',
    name: 'Name',
    netAccountBudget: 'Net Account Budget',
    networkError: 'Network error: Unable to contact server',
    projectType: 'Project Type',
    project: 'Project',
    projectDetails: 'Project Details',
    projectDepot: 'Depot',
    projectDepotDetails: 'Depot Details',
    projectCustomerLocation: 'Customer Location',
    projectCustomerLocationDetails: 'Customer Location Details',
    new: 'New',
    newProject: 'New Project',
    newProjectDepot: 'New Depot',
    newProjectCustomerLocation: 'New Customer Location',
    newAsset: 'New Asset',
    newContract: 'New contract',
    newProcurement: 'New Procurement',
    newDisposal: 'New Disposal',
    newBooking: 'New booking',
    editProject: 'Edit Project',
    editProjectDepot: 'Edit Depot',
    editProjectCustomerLocation: 'Edit Customer Location',
    editAsset: 'Edit Asset',
    editContract: 'Edit contract',
    editProcurement: 'Edit Procurement',
    editDisposal: 'Edit Disposal',
    editBooking: 'Edit booking',
    none: 'None',
    showNonInterimAccountedInvoices: "Non processed invoices exists!",
    showInterimAccountedInvoices: "Show processed invoices",
    notApproved: 'Not approved',
    notProcessed: 'Not processed',
    notSelected: 'Not selected',
    notSpecified: 'Not specified',
    selectProject: 'Select project',
    selectTenant: 'Enter your organization',
    selectLanguage: 'Select language',
    wrongTenant: 'Organization does not exist!',
    reader: 'Reader',
    writer: 'Writer',
    admin: 'Admin',
    owner: 'Owner',
    normal: 'Normal',
    OPPORTUNITY: 'Opportunity',
    offHire: 'Offhire',
    opportunityAdjustments: 'Opportunity Adjustments',
    outgoing: 'Outgoing',
    overview: 'Overview',
    payments: 'Payments',
    pickupDate: 'Pickup Date',
    probability: 'Probability',
    PROBABILITY: 'Probability',
    procurement: 'Procurement',
    procurements: 'Procurements',
    productName: 'OnSite',
    profile: 'Profile',
    projectInformation: 'Project Information',
    projectList: 'Projects',
    projectStart: 'Project start',
    projectEnd: 'Project end',
    projectName: 'Project name',
    projectNumber: 'Project number',
    projectCode: 'Project code',
    projectCodes: 'Project codes',
    accountCode: 'Account code',
    accountCodes: 'Account codes',
    filename: 'Filename',
    property: 'Property',
    progressConsequence: 'Progress Consequence',
    per: 'per',
    percentage: 'Percentage',
    percentShare: 'Percent Share',
    receivedDate: 'Received Date',
    sentDate: 'Sent Date',
    remainingAccountBudget: 'Remaining account budget',
    remainingAmount: 'Remaining amount',
    remainingCost: 'Remaining billed',
    remainingContractBudget: 'Remaining contract budget',
    remainingContractProcurement: 'Remainig Contract procurement',
    remainingProcurement: 'Remaining procurement',
    repairCost: 'Repair Cost',
    reset: 'Reset',
    revised: 'Revised',
    revisedForecast: 'Revised Forecast',
    revisedAccountBudget: 'Revised Account Budget',
    RISK: 'Risk',
    riskAdjustments: 'Risk Adjustments',
    role: 'Role',
    salary: 'Salary',
    save: 'Save',
    saveInvoice: 'Save Invoice',
    settings: 'Settings',
    signedInAs: 'Signed in as:',
    signIn: 'Sign in',
    signOut: 'Sign out',
    security: 'Security',
    myAccount: 'Account',
    SITE_MANAGER: 'Site manager',
    splitInvoice: 'Split invoice',
    starting: 'Starting',
    state: 'State',
    level: 'Level',
    mainContractor: 'Main Contractor',
    mainContractorShort: 'Main Contractor',
    mainContractorCosts: 'Main Contractor Cost',
    subContractor: 'Subcontractor',
    subContractorShort: 'Subcontractor',
    subContractorCosts: 'Subcontractor Costs',
    subProjects: 'Sub projects',
    sum: 'Sum',
    sumChangeOrderCost: 'Cost: change order',
    sumChangeOrderInvoicedAmount: 'Invoiced amount: change order',
    sumContractBudget: 'Sum contract budget',
    sumContractInvoicedAmount: 'Invoiced amount: contract',
    sumContractProcurement: 'Sum contract procurement',
    sumChangeOrderRemainingCost: 'Remaining invoice amount: change order',
    sumRemainingContractBudget: 'Sum remaining contract budget',
    contractCostScope: 'Total contract procurement',
    sumExpenseForecasts: 'Sum expenses',
    sumContractCostScope: 'Sum contract cost scope',
    IncurredCosts: 'Incurred costs',
    simulating: 'Simulating',
    totalSurcharge: 'Total Surcharge',
    supplier: 'Supplier',
    theme: 'Theme',
    this: 'This',
    time: 'Time',
    title: 'Title',
    to: 'To',
    toDate: 'To date',
    today: 'Today',
    toggle: 'Toggle',
    totalCosts: 'Total costs',
    transactions: 'Transactions',
    type: 'Type',
    uploadImage: 'Upload Image',
    uploadContract: 'Upload Contract',
    downloadImage: 'Download Image',
    downloadContract: 'Download Contract',
    uncertainty: 'Uncertainty',
    certificate: 'Certificate',
    certificateInputHelperText: 'Write certificate title, or choose a title from the dropdown menu.',
    certificateListInputHelperText: 'Click the button below to add certificates of competence.',
    certificateListInputHelperTextHseCard: 'Click on the button to add HSE Card',
    unkown: 'Unkown',
    unprocessed: 'Incomplete',
    unforeseen: 'Unforeseen',
    selectEmployerCompanyHelperText: 'Start typing to find the company you are working for.',
    selectWorkingCompanyHelperText: 'Start typing to find the company your employer is working for.',
    yourEmployerWorksOnBehalfOf: 'Your employer perfoms work on behalf of',
    adjustmentsRisksAndUnforeseen: 'Unforeseen and Adjustments',
    up: 'Up',
    expenseItem: 'Expense Item',
    remainsToDistribute: 'Remains to distribute',
    turntime: 'Turntime',
    triangulation: 'Triangulation',
    newExpectedChange: 'New Expected Change',
    expectedChange: 'Expected Change',
    expectedPostedExpense: 'Expected posted expense',
    expectedAccumulatedPostedExpense: 'Expected accumulated posted expense',
    actuallyPostedExpense: 'Actually posted expense',
    actuallyAccumulatedPostedExpense: 'Actually accumulated posted expense',
    usedWithForecast: 'Used with forecast',
    useRemainingAmount: 'Use remaining amount',
    username: 'Username',
    value: 'Value',
    variance: 'Variance',
    week: 'Week',
    with: 'with',
    yes: 'Yes',
    yesterday: 'Yesterday',
    next: 'Next',
    back: 'Back',
    region: 'Region',
    country: 'Country',
    city: 'City',
    postcode: 'Postcode',
    area: 'Area',
    location: 'Location',
    address: 'Address',
    street: 'Street',
    contact: 'Contact',
    capabilities: 'Capabilities',
    code: 'Code',
    disposal: 'Disposal',
    disposals: 'Disposals',
    dropOffCharges: 'Drop Off Charges',
    lessorDetails: 'Lessor Details',
    contractReference: 'Contract Reference',
    reference: 'Reference',
    quota: 'Quota',
    onHireQuota: 'On Hire Quota',
    offHireQuota: 'Off Hire Quota',
    prefixSeries: 'Prefix Series',
    discountedResidual: 'Discounted Residual',
    isoCode: 'Iso Code',
    specifications: 'Specifications',
    onHireReference: 'On Hire Reference',
    perDiemRate: 'Per Diem Rate',
    damageProtectionPlan: 'Damage Protection Plan',
    containerNumber: 'Container Number',
    containerNumbers: 'Container Numbers',
    containerFlow: 'Container Flow',
    containerBookingFlow: 'Container Booking Flow',
    uploadContainerNumbers: 'Upload Container Numbers',
    downloadContainerNumbers: 'Download Container Numbers',
    fleetComposition: 'Fleet Composition',
    acceptance: 'Acceptance',
    csc: 'CSC',
    swap: 'SWAP',
    bookingNumber: 'Booking Number',
    origin: 'Origin',
    destination: 'Destination',
    customer: 'Customer',
    emptyMovement: 'Empty Movement',
    maintenanceAndRepair: 'Maintenance And Repair',
    container: 'Container',
    assign: 'Assign',
    release: 'Release',
    complete: 'Complete',
    cleanse: 'Cleanse',
    issueProcurement: 'Issue Procurement',
    issueDisposal: 'Issue Disposal',
    QUANTITIES: 'Quantities',
    PROJECT_CHIEF: 'Project Leader',
    PROJECT_MANAGER: 'Project Manager',
    PROJECT_MANAGER_ASSISTANT: 'Project Manager Assistant',
    CONSTRUCTION_MANAGER: 'Construction Manager',
    OPERATIONS_MANAGER: 'Operations Manager',
    PROJECT_SECRETARY: 'Project Secretary',
    ENVIRONMENTAL_MANAGER: 'Environmental Manager',
    HEALTH_SAFETY_AND_ENVIRONMENT_LEADER: 'HSE-leader',
    ENGINEERING_MANAGER: 'Engineering Manager',
    TECHNICAL_ENGINEERING_MANAGER: 'Technical Engineering Leader',
    INTEGRATED_TECHNICAL_BUILDING_INSTALLATIONS_RESPONSIBLE: 'ITB-responsible',
    CONTROLLER: 'Controller',
    PURCHASER: 'Purchaser',
    OTHER: 'Other',
    NEW_BOOKING:'New Booking',
    ASSIGN_ASSETS:'Assign Containers',
    ASSETS_ASSIGNED:'Containers Assigned',
    NO_ASSETS_ASSIGNED: 'No Containers Assigned',
    ASSETS_PARTLY_ASSIGNED:'Containers Partly Assigned',
    GATE_OUT:'Gate Out',
    BOOKING_COMPLETE:'Booking Complete',
    bookingSource: 'Booking Source',
    bookingTimeline: 'Booking Timeline',
    maxGrossWeight: 'Max Gross Weight',
    assetStatus: 'Container Status',
    ON_ASSIGNMENT: 'On Assignment',
    IN_DEPOT: 'In Depot',
    MAINTENANCE_AND_REPAIR:'Maintenance and Repair',
    availableDate: 'Next Available',
    currentLocation: 'Current Location',
    recentBookings: 'Recent Bookings',
    containerStatus: 'Container Status',
    DEPOT: 'Depot',
    TERMINAL: 'Terminal',
    SHIPPERS_POOL: 'Shippers Pool',
    TRIANGULATION: 'Triangulation',
    CUSTOMER_LOCATION: 'Customer Location',
    containers: 'Containers',
    containerDetails: 'Container Details',
    assignedContainers: 'Assigned Containers',
    newContainer: 'New Container',
    editContainer: 'Edit Container',
    newQuota: 'New Quota',
    PAPER_GRADE: 'Paper Grade',
    CARGO_WORTHY: 'Cargo Worthy',
    FOOD_GRADE: 'Food Grade',
    containerForecast: 'Container Forecast',
    connectedProjects: 'Connected Projects',
    coordinates: 'Coordinates',
    latitude: 'Latitude',
    longitude: 'Longitude',
    ASSIGNED: 'Assigned',
    UNCHECKED: 'Unchecked',
    IN_TRANSIT_FULL: 'In Transit Full',
    IN_TRANSIT_EMPTY: 'In Transit Empty',
    IN_REPAIR: 'In Repair',
    READY_FOR_CARGO: 'Ready For Cargo',
    currency: 'Currency',
    exchangeRate: 'Exchange Rate',
    mainCurrency: 'Main Currency',
    lifetimeCostGeneration: 'Lifetime Cost Generation',
    lifetimeValueGeneration: 'Lifetime Value Generation',
    myInfo: 'My Info',
    myProjects: 'My Projects',
    allProjects: 'All Projects',
    certificates: 'Certificates',
    diplomas: 'Diplomas',
    phoneNumber: 'Phone Number',
    relativePhoneNumber: 'Next Of Kin Phone Number',
    relativeName: 'Next Of Kin Name',
    managerPhoneNumber: 'Manager Phone Number',
    managerName: 'Manager Name',
    welcomeProject: 'Welcome to',
    registerInformation: 'Register information',
    safetyInstructions: 'Safety instructions',
    safetyAgreement: 'Safety agreement',
    safetyVideo: 'Safety video',
    videoUrl: 'Video Url',
    theVideoMustBeHostedOn: 'The video must be hosted on ',
    newSafetyInformation: 'New Safety Information',
    watchSafetyInformations: 'Watch informations',
    informationHasBeenSavedInfo: 'Your Information has been saved. The next step is to see the necessary security information to become a member of the project.',
    informationHasBeenSeenInfoPart: 'Congratulations! You have now gone through the necessary security information to become a member of the project.',
    informationHasBeenSeenInfoPartWithQuiz: 'Congratulations! You got {0} of {1} questions correct, and have now gone through the necessary security information to become a member of the project.',
    contactAdministrationToRegisterHmsCard: 'Contact the construction site administration to register your HMS card. \n\nSee you!',
    contactAdministrationForMoreInformation: 'Contact the project administration for further information.',
    youHadXOutOfQuestionsCorrect: 'You got {0} of {1} questions correct{2}',
    informationApproval: 'Approval',
    toTheProject: 'To the project',
    register: 'Register',
    search: 'Search',
    signedInAsProjectMember: 'You have signed in as a project member.',
    goThroughSteps: 'Hello! \n\nBefore becoming a member of {0}, you must register information about yourself, as well as review the necessary security instructions for the project.',
    goThroughStepsWithQuizInfo: 'Health, environment and safety are our highest priority! Therefore, all new project members must pass a quiz based on the safety instructions. Take your time and pay close attention to the information presented to you. You must have {0} out of {1} correct answers to pass the Quiz. \n\nGood luck!',
    hseCardNumberNotFound: 'HSE card number not found',
    hseCard: 'HSE Card',
    hseCardNumber: 'HSE Card Number',
    hseCardNotAdded: 'HSE Card not added',
    hseCardNotAddedDescription: "You have not uploaded any HSE card. If you got a confirmation of ordered HSE card, then press cancel and upload a picture of it. I hereby confirm that I will inform to the Site management that I do not have any HSE card right now.",
    mandatoryHseCardNotAddedDescription: "It is mandatory to upload a HSE card. If you got a confirmation of ordered HSE card, then press confirm and upload a picture of it.",
    certificateNotAdded: 'Certificate not added',
    certificateNotAddedDescription: 'You have not uploaded any cerificate(s). I hereby confirm that I will perform work that does not require any form of certification or qualification.',
    mandatoryCertificateNotAddedDescription: 'It is mandatory to upload your cerificate(s).',
    uploadPictureOfFrontHseCard: "Upload picture of the HSE card's front side.",
    uploadPictureOfBackHseCard: "Upload picture of the HSE card's back side.",
    uploadPictureOfFrontAndBackHseCard: 'Upload picture of the HSE cards front and rear side.',
    uploadPictureOfFrontCertificateCard: 'Upload picture of the certificates front side.',
    uploadPictureOfBackCertificateCard: 'Upload picture of the certificates rear side.',
    uploadPictureOfFrontAndBackCertificateCard: 'Upload picture of the certificates front and rear side.',
    joinProject: 'Join',
    followingProject: 'Following',
    followProject: 'Follow',
    unfollowProject: 'Unfollow',
    newsFeed: 'News Feed',
    feed: 'Feed',
    message: 'Message',
    messages: 'Messages',
    safetyInformation: 'Safety information',
    safetyInformationInMenu: 'Safety information',
    member: 'Member',
    membersRaw: 'Members',
    membersFound: 'members found',
    pendingApproval: 'Pending Approval',
    [MemberAccess.REQUESTING_MEMBER_ACCESS]: 'Request access',
    [MemberAccess.PUBLIC]: 'Public',
    [MemberAccess.MEMBER]: 'Member',
    approve: 'Approve',
    deny: 'Deny',
    denied: 'Denied',
    newNewsFeed: 'New Message',
    editNewsFeed: 'Edit Message',
    doYouWantToDeleteNewsFeed: 'Do you want to delete news feed?',
    doYouWantToSaveNewsFeed: 'Do you want to save news feed',
    doYouWantToDeleteProjectMember: "Do you want to cancel the project membership?",
    doYouWantToDeleteSafetyInformation: 'Do you want to delete the safety information?',
    doYouWantToDeleteHseCard: 'Do you really want to delete HSE card?',
    doYouWantToDeleteCertificate: 'Do you really want to delete certificate?',
    add: "Add",
    emptyInformationVerifications: "You don't have any diplomas yet!",
    newsFeedAccessLevel: 'Access Level',
    newsFeedAccessLevelPublic: 'Public',
    newsFeedAccessLevelPrivate: 'Private',
    inactive: 'Inactive',
    preview: 'Preview',
    hitEnter: 'Hit Enter',
    show: 'Show',
    hide: 'Hide',
    myself: 'Myself',
    notification: 'Notification',
    noSafetyInformationAvailable: 'There is no safety information yet for this project, try to come back later or ask administrator for help.',
    markdownSupported: 'Markdown Supported!',
    accessDenied: 'Access Denied',
    videoFailedPlayingWithErrorMessage: 'Video failed playing with error message',
    clickToUploadImage: 'Click here to upload picture',
    addAttachment: 'Add attachment',
    hseCardMissingTooltip: 'HSE card is missing',
    failedSafetyInformationQuizTooltip: 'Failed safety information',
    certificateMissingTooltip: 'Certificate is missing',
    manualUserTooltip: 'This user is manually created',
    addNewMember: 'New member',
    companyFieldRequired: 'Company field is required',
    nameFieldRequired: 'Name field is required',
    fileSizeError: 'File size must be less than 100 mb.',
    fileDeletedMessage: 'The file was successfully deleted',
    cardScanSuccessMessage: 'You have successfully scanned your HSE Card',
    cameraNotAvailable: 'Camera permission denied or not available',
    hseCardScanner: 'HSE Card scanner',
    failedToScan: 'Failed to scan?',
    cardNumber: 'Card number',
    cardIsActiveTooltip: 'HSE Card is active',
    cardIsUnactiveTooltip: 'HSE Card is unactive',
    enableHseCardScanner: 'enable HSE Card scanner',
    addHseCardImageMessage: 'Click on the button below to add HSE card.',
    instructionReadAttentively: 'Please read the instruction attentively.',
    hseCardNumberInputInstructionTitle: 'HSE Card Number instruction',
    hseCardNumberInputInstructionExplanation1: 'Locate the card number in the top left corner of the HSE card.',
    hseCardScanningInstructionTitle: 'HSE Card Scanning Instruction',
    scanningInstructionExplanation1: 'Take a photo of the QR Code on your HSE card or upload the picture of your QR code.',
    scanningInstructionExplanation2: 'Make sure to take the photo of the QR code ONLY. Alternatively select a photo of your QR code from your gallery.',
    scanningInstructionExplanation3: 'The photo of the QR code must be in good quality. Not blurry!',
    example: 'Example',
    startScanningProcess: 'Start scanning process',
    iHaveReadIntructions: 'I have read the instructions, and ready to upload my QR code.',
    advancedSettings: 'Advanced settings',
    phoneIsRequired: 'Phone Number field is required',
    relativesIsRequired: 'Relatives field is required',
    hseIsRequired: 'HSE Card Number field is required',
    hsePictureIsRequired: 'Mandatory uploading of the HMS card',
    certificatePictureIsRequired: 'Mandatory uploading of the certificate',
    enableParentCompany: 'Workers provide information about their employers contractor.',
    noHseCardUploaded: 'You have not uploaded the front/back side of your HSE card! Are you sure that you want to proceed?',
    noCertificateUploaded: 'You have not uploaded the front/back side of your certificate! Are you sure that you want to proceed?',
    doYouWantToProceed: 'Do you want to proceed?',
    clickHere: 'Click here',
    registerHere: 'Register here',
    toUpload: 'to upload',
    backSide: 'back side',
    frontSide: 'front side',
    ofHseCard: 'of your HSE card',
    ofCertificate: 'of your certificate',
    duplicateInformationApprovalWarning: 'Duplicate approvals are not supported.',
    newUser: 'New user',
    existingUser: 'Existing user',
    downloadUserProfileInfo: 'Download profile information',
    memberInvitedMessage: 'Member was invited',
    inviteMember: 'Invite member',
    emailFieldRequired: 'Email is required',
    invite: 'Invite',
    uploadedByProjectAdministrator: 'Uploaded by project administrator.',
    unableToScanQrCode: "Unable to scan QR code form provided photo. Try to provide better quality image of QR code, also make sure it's not blurry and QR code is big enough!",
    quiz: 'Quiz',
    quizSettings: 'Quiz settings',
    deniedAcccessToProject: 'You have been denied access to the project.',
    projectMemberFailedTheSafetyInformationQuestions: 'You got {0} out of {1} answers correct on the Quiz, and you must have {2} out of {3} correct answers to pass. Unfortunately, you have not passed the test to become a member of the project.',
    youHaveXTriesLeft: 'You have {0} attempts left.',
    youHaveUsedAllOfYourQuizTries: ', and you have used up all your attempts to pass the quiz.',
    tryAgain: 'Try again',
    maxQuizTries: 'Maximum number of trials',
    minQuizPercentage: 'Minimum percentage of correct answers',
    infoTech: 'InfoTech',
    apiKey: 'API key',
    accessGroup: 'Access group',
    registeredAccessGroup: 'Registered access group',
    autoApproveProjectMembers: 'Auto approve project members',
    deleteAttachedFile: 'Delete attached file',
    areYouSureYouWantToDeleteFile: 'Are you sure you want to delete the {0} file?',
    checkIn: 'Check in',
    checkOut: 'Check out',
    checkedIn: 'Checked in',
    checkedOut: 'Checked out',
    copiedToClipboard: 'Copied to clipboard',
    enableCheckIn: 'Enable check in',
    showPresence: 'Show presence',
    presence: 'Presence',
    invalid: 'Invalid',
    verifying: 'Verifying',
    notes: 'Notes',
    unknownCompany: 'Unknown company',
    hseCardIsRevokedOrInvalid: 'HSE card is revoked or invalid.',
};
