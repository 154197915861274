import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { ActiveState, DocumentType, ProjectCompany } from "../../contracts/contracts";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import PageContentLayout from "../../component/layouts/PageContentLayout";
import EditProjectCompanyDetails from "../../component/projectCompanyComponents/EditProjectCompanyDetails";
import { useRouteMatch } from "react-router-dom";
import { useUrlContext } from "../../contexts/url/urlContext";
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useProjectCompanyContext } from "../../contexts/projectCompany/projectCompanyContext";
import { Dictionary } from "../../global-types";

const ProjectCompanyDetailsView: React.FC<{}> = () => {
    const editedProjectCompany = useRef<ProjectCompany>({})
    const { params } = useRouteMatch<any>()
    const { companyId } = params

    const urlContext = useUrlContext();
    const urlState = urlContext.getUrlState();
    const projectId = urlState.selectedProjectId;

    const languageContext = useLanguageContext();
    const menuContext = useMenuContext();

    const projectCompanyContext = useProjectCompanyContext()
    const ticketContext = useTicketContext();

    const projectCompanySearch = projectCompanyContext.getProjectCompanySearch();
    projectCompanySearch.projectId = projectId;

    const projectCompany = projectCompanyContext.getProjectCompany(companyId)

    const projectCompanyLoading = projectCompanyContext.loadingProjectCompanies

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const isLoading = projectCompanyLoading

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([
            DocumentType.APPLICATION_PROFILE,
            DocumentType.PROJECT_COMPANY,
        ]);
        projectCompanyContext.searchProjectCompanies(projectCompanySearch);
    }, [urlContext.currentLocation])

    const onProjectCompanyChange = (changedProjectCompany: ProjectCompany) => {
        editedProjectCompany.current = { ...changedProjectCompany };
    }

    const updateUrl = (route: ApplicationRoute, urlState?: Dictionary<string | number | Date>) => {
        const urlQuery = urlState ? urlContext.buildUrlQuery(urlState) : '';

        urlContext.pushUrlQuery(urlQuery, route.route)
    }

    const onBack = () => {
        const route = menuContext.getApplicationRouteById(ApplicationRouteId.ProjectCompanies)
        updateUrl(route, { selectedProjectId: urlState.selectedProjectId })
    }

    const onSave = async () => {
        if (editedProjectCompany.current.id) {
            const mutatedProjectCompany: ProjectCompany = { ...editedProjectCompany.current }
            mutatedProjectCompany.state = mutatedProjectCompany.state ?? ActiveState.ACTIVE;
            setIsSaving(true);
            await projectCompanyContext.mutateProjectCompany(mutatedProjectCompany);
            setIsSaving(false);
        }
    }

    const BottomNavigation = (
        <>
            <Button variant='contained' onClick={onBack}>{languageContext.getMessage('back')}</Button>
            <Button variant='contained' onClick={async () => await onSave()}>{languageContext.getMessage('save')}</Button>
        </>
    )

    return (<>
        <PageContentLayout bottomNavigation={isLoading ? null : BottomNavigation}>
            <Typography variant="h5" style={{ marginTop: 10, marginBottom: 20 }}>
                {languageContext.getMessage('companyRaw')}
            </Typography>
            {
                isLoading || isSaving ? <Grid container justifyContent='center'><CircularProgress /></Grid> :
                    (
                        <EditProjectCompanyDetails company={projectCompany} onCompanyChange={onProjectCompanyChange} />
                    )
            }
        </PageContentLayout>
    </>);
}

export default ProjectCompanyDetailsView;
