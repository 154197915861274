import { Divider, ListItem, ListItemText, Paper, ListItemIcon, List } from "@mui/material";
import React from "react";
import ApplicationMenuList from "./ApplicationMenuList";
import { useWidthContext } from "../../contexts/WidthContext";
import Drawer from '@mui/material/Drawer';
import { useMenuContext } from "../../contexts/menu/menuContext";
import CloseIcon from '@mui/icons-material/Close';
import { useLanguageContext } from "../../contexts/language/LanguageContext";

const ApplicationMenuDesktop: React.FC = ( ) => {
	const isMobileScreen = useWidthContext().isMobileScreen();
	const languageContext = useLanguageContext();
	const menuContext = useMenuContext();

	const handleOnClickMenu = () => {
		menuContext.setFullMenuVisible(!menuContext.fullMenuVisible);
	}

	return !isMobileScreen ? (
			<Drawer
				anchor="left"
				open={menuContext.fullMenuVisible}
				onClose={handleOnClickMenu}
			>
				<Paper
					sx={{
						backgroundColor: '#455A64 !important',
						color: 'white !important',
						height: '100%',
						width: 200,
					}}
					elevation={2}
					square={true}
				>
					<List dense>
						<ListItem
							button
							onClick={handleOnClickMenu}
						>
							<ListItemIcon sx={{ color: 'white' }}><CloseIcon /></ListItemIcon>
							<ListItemText primary={languageContext.getMessage('close')} />
						</ListItem>
					</List>
					<Divider style={{ backgroundColor: '#B0BEC5' }}/>
					<ApplicationMenuList />
				</Paper>
			</Drawer>
	) : null;
}

export default ApplicationMenuDesktop;
