import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/react-hooks";
import { HSECardInfo } from "../contracts/contracts"
import { QUERY_HSE_CARD_INFO, getHSECardInfoQueryOptions } from "../contexts/generalIntegrations/queries/generalIntegrationsQueries";
import { useLanguageContext } from "../contexts/language/LanguageContext";

export const useQueryHSECardInfo = (cardId?: string, cardQRCode?: string, initialFetchedHSECardInfo?: HSECardInfo): [HSECardInfo, boolean] => {
    const languageContext = useLanguageContext();
    const [lastQueriedCardId, setLastQueriedCardId] = useState<string | undefined>(undefined);
    const [lastQueriedCardQRCode, setLastQueriedCardQRCode] = useState<string | undefined>(undefined);
    const [fetchedHSECardInfo, setFetchedHSECardInfo] = useState<HSECardInfo>({});
    const [queryHSECardInfo, queryHSECardInfoResponse] = useLazyQuery(QUERY_HSE_CARD_INFO);

    useEffect(() => {
        if (initialFetchedHSECardInfo) {
            setFetchedHSECardInfo({ ...initialFetchedHSECardInfo });
            setLastQueriedCardId(undefined);
            setLastQueriedCardQRCode(undefined);
        }
    }, [initialFetchedHSECardInfo])

    useEffect(() => {
        if (queryHSECardInfoResponse.loading) {
            return;
        }
        if ((cardId && cardId !== lastQueriedCardId) ||
            (cardQRCode && cardQRCode !== lastQueriedCardQRCode)) {
            setLastQueriedCardId(cardId);
            setLastQueriedCardQRCode(cardQRCode);
            const queryOptions = getHSECardInfoQueryOptions(cardId, cardQRCode);
            queryHSECardInfo(queryOptions);
        }
    }, [cardId, cardQRCode, queryHSECardInfoResponse.loading])

    useEffect(() => {
        if (queryHSECardInfoResponse.data && queryHSECardInfoResponse.data.hseCardInfo) {
            const newFetchedHSECardInfo: HSECardInfo = queryHSECardInfoResponse.data.hseCardInfo;
            if (!newFetchedHSECardInfo.cardNumber) {
                newFetchedHSECardInfo.cardNumber = undefined;
                newFetchedHSECardInfo.error = true;
                newFetchedHSECardInfo.errorInfo = languageContext.getMessage('hseCardNumberNotFound');
            }
            setFetchedHSECardInfo({ ...newFetchedHSECardInfo });
        }
    }, [queryHSECardInfoResponse.data]);

    useEffect(() => {
        if (queryHSECardInfoResponse.error) {
            const newFetchedHSECardInfo: HSECardInfo = { error: true, errorInfo: queryHSECardInfoResponse.error.message };
            setFetchedHSECardInfo({ ...newFetchedHSECardInfo });
        }
    }, [queryHSECardInfoResponse.error]);

    return [fetchedHSECardInfo, queryHSECardInfoResponse.loading];
}