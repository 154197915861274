import { Home, Info, PinDrop, Verified, Person, Group, Assignment, DesignServices, CorporateFare } from "@mui/icons-material";

import SettingsIcon from '@mui/icons-material/Settings';
import React, { ComponentType, createContext, ReactElement, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RoleType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import AboutView from "../../views/about/AboutView";
import ProjectsView from "../../views/projects/ProjectsView";
import ProfileView from "../../views/profile/ProfileView";
import HomeView from "../../views/HomeView";
import SettingsView from "../../views/settings/SettingsView";
import { LanguageContext, useLanguageContext } from "../language/LanguageContext";
import { useUrlContext } from "../url/urlContext";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import NewsFeedView from "../../views/newsFeed/newsFeedView";
import JoinView from "../../views/join/JoinView";
import JoinRegisterInformationView from "../../views/join/JoinRegisterInformationView";
import JoinSafetyInformationView from "../../views/join/JoinSafetyInformationView";
import CertificatesView from "../../views/profile/CertificatesView";
import ProjectCompaniesView from "../../views/projectCompanies/ProjectCompaniesView";
import ProjectMembersView from "../../views/projectMembers/projectMembersView";
import DiplomasView from "../../views/diplomas/DiplomasView";
import SafetyInformationsView from "../../views/safetyInformations/SafetyInformationsView";
import HseCardView from "../../views/profile/HseCardView";
import SafetyInformationDetailsView from "../../views/safetyInformations/SafetyInformationDetailsView";
import ProjectCompanyDetailsView from "../../views/projectCompanies/ProjectCompanyDetailsView";
import SafetyInformationDemoView from "../../views/safetyInformations/SafetyInformationDemoView";

export enum ApplicationRouteId {
    Home = "home",
    About = "about",
    Project = 'project',
    ProjectCompanies = 'companies',
    ProjectCompanyDetails = 'companyDetails',
    ProjectMembers = 'members',
    Informations = 'informations',
    Profile = 'profile',
    Certificates = 'Certificates',
    Settings = "settings",
    NewsFeed = "newsFeed",
    Join = "join",
    RegisterInformation = "registerInformation",
    Information = "information", // join safety information
    SafetyInformations = "safetyInformations", // safety informations list page
    SafetyInformationDetails = "safetyInformationDetail", // safety info detail page
    SafetyInformationPreview = "safetyInformationPreview", // safety info detail page
    Diplomas = "Diplomas", // diplomas
    HSECard = "HSECard",
}

export type ApplicationRoute = {
    id: ApplicationRouteId;
    route: string;
    menuTitle?: string;
    params?: Dictionary<string | number | Date>;
    component: ComponentType;
    icon?: ReactElement;
    accessRole?: RoleType;
    hidden?: boolean;
    navIcon?: any;
    hideFromNavigation?: boolean;
};

const getApplicationRoutes = (languageContext: LanguageContext): ApplicationRoute[] => {
    return [
        {
            id: ApplicationRouteId.Home,
            route: '/home',
            menuTitle: languageContext.getMessage('home'),
            component: HomeView,
            icon: <Home style={{ color: '#ECEFF1' }} />,
            hidden: true,
            navIcon: <Home style={{ color: '#90A4AE' }} />,
        },
        {
            id: ApplicationRouteId.HSECard,
            route: '/profile/hse-card',
            component: HseCardView,
            hidden: false,
            hideFromNavigation: true,
        },
        {
            id: ApplicationRouteId.Certificates,
            route: '/profile/certificates',
            component: CertificatesView,
            hidden: false,
            hideFromNavigation: true,
        },
        {
            id: ApplicationRouteId.Profile,
            route: '/profile',
            menuTitle: languageContext.getMessage('profile'),
            component: ProfileView,
            icon: <Person style={{ color: '#ECEFF1' }} />,
            hidden: false,
            navIcon: <Person style={{ color: '#90A4AE' }} />,
        },
        {
            id: ApplicationRouteId.RegisterInformation,
            route: '/join/:projectId/register',
            component: JoinRegisterInformationView,
            accessRole: RoleType.WRITER,
            hidden: false,
            hideFromNavigation: true,
        },
        {
            id: ApplicationRouteId.Information,
            route: '/join/:projectId/safety',
            component: JoinSafetyInformationView,
            hidden: false,
            hideFromNavigation: true,
        },
        {
            id: ApplicationRouteId.SafetyInformationPreview,
            route: '/safety-informations/:informationId/preview',
            menuTitle: languageContext.getMessage('safetyInformation'),
            component: SafetyInformationDemoView,
            icon: <Group style={{ color: '#ECEFF1' }} />,
            hidden: false,
            navIcon: <PinDrop style={{ color: '#90A4AE' }} />,
            hideFromNavigation: true,
        },
        {
            id: ApplicationRouteId.SafetyInformationDetails,
            route: '/safety-informations/:informationId',
            menuTitle: languageContext.getMessage('safetyInformation'),
            component: SafetyInformationDetailsView,
            icon: <Group style={{ color: '#ECEFF1' }} />,
            hidden: false,
            navIcon: <PinDrop style={{ color: '#90A4AE' }} />,
            hideFromNavigation: true,
        },
        {
            id: ApplicationRouteId.Join,
            route: '/join/:projectId',
            component: JoinView,
            hidden: false,
            hideFromNavigation: true,
        },
        {
            id: ApplicationRouteId.Diplomas,
            route: '/diplomas',
            menuTitle: languageContext.getMessage('diplomas'),
            component: DiplomasView,
            icon: <Verified style={{ color: '#ECEFF1' }} />,
            hidden: false,
            navIcon: <AssignmentTurnedInIcon style={{ color: '#90A4AE' }} />,
            hideFromNavigation: true,
        },
        {
            id: ApplicationRouteId.ProjectCompanyDetails,
            route: '/project-companies/:companyId',
            menuTitle: languageContext.getMessage('companiesRaw'),
            component: ProjectCompanyDetailsView,
            icon: <PinDrop style={{ color: '#ECEFF1' }} />,
            accessRole: RoleType.WRITER,
            hidden: false,
            navIcon: <PinDrop style={{ color: '#90A4AE' }} />,
            hideFromNavigation: true,
        },
        {
            id: ApplicationRouteId.Project,
            route: '/projects',
            menuTitle: languageContext.getMessage('projects'),
            component: ProjectsView,
            icon: <DesignServices style={{ color: '#ECEFF1' }} />,
            hidden: false,
            navIcon: <DesignServices style={{ color: '#90A4AE' }} />,
        },
        {
            id: ApplicationRouteId.NewsFeed,
            route: '/news-feed',
            menuTitle: languageContext.getMessage('newsFeed'),
            component: NewsFeedView,
            icon: <PinDrop style={{ color: '#ECEFF1' }} />,
            accessRole: RoleType.READER,
            hidden: false,
            navIcon: <PinDrop style={{ color: '#90A4AE' }} />,
        },
        {
            id: ApplicationRouteId.ProjectCompanies,
            route: '/project-companies',
            menuTitle: languageContext.getMessage('companiesRaw'),
            component: ProjectCompaniesView,
            icon: <CorporateFare style={{ color: '#ECEFF1' }} />,
            accessRole: RoleType.WRITER,
            hidden: false,
            navIcon: <CorporateFare style={{ color: '#90A4AE' }} />,
        },
        {
            id: ApplicationRouteId.ProjectMembers,
            route: '/projectMembers',
            menuTitle: languageContext.getMessage('membersRaw'),
            component: ProjectMembersView,
            icon: <Group style={{ color: '#ECEFF1' }} />,
            accessRole: RoleType.WRITER,
            hidden: false,
            navIcon: <PinDrop style={{ color: '#90A4AE' }} />,
        },
        {
            id: ApplicationRouteId.SafetyInformations,
            route: '/safety-informations',
            menuTitle: languageContext.getMessage('safetyInformationInMenu'),
            component: SafetyInformationsView,
            icon: <Assignment style={{ color: '#ECEFF1' }} />,
            accessRole: RoleType.WRITER,
            hidden: false,
            navIcon: <PinDrop style={{ color: '#90A4AE' }} />,
        },
        {
            id: ApplicationRouteId.Settings,
            route: '/settings',
            component: SettingsView,
            menuTitle: languageContext.getMessage('settings'),
            icon: <SettingsIcon style={{ color: '#ECEFF1' }} />,
            hidden: false,
            navIcon: <SettingsIcon style={{ color: '#90A4AE' }} />,
        },
        {
            id: ApplicationRouteId.About,
            route: '/about',
            menuTitle: `${languageContext.getMessage('info')}`,
            component: AboutView,
            icon: <Info style={{ color: '#ECEFF1' }} />,
            hidden: true,
            navIcon: <Info style={{ color: '#90A4AE' }} />,
        },
    ].filter(route => route.hidden !== true);
}

interface MenuContext {
    applicationRoutes: ApplicationRoute[];
    selectedApplicationRoute: ApplicationRoute,
    fullMenuVisible: boolean,
    getApplicationRouteById: (id: ApplicationRouteId) => ApplicationRoute,
    getApplicationRouteByRoute: (route: string) => ApplicationRoute,
    setFullMenuVisible: (newFullMenuVisible: boolean) => void,
    setSelectedApplicationRoute: (id: ApplicationRouteId, params?: Dictionary<string | number | Date | undefined> | null) => void,
    replaceSelectedApplicationRoute: (id: ApplicationRouteId, params: Dictionary<string | number | Date | undefined>) => void
}

const MenuContext = createContext<MenuContext>(null as unknown as MenuContext);

export const MenuProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const location = useLocation();
    const history = useHistory();
    const languageContext = useLanguageContext();

    const localStorageFullMenuVisibleSettingId = 'settings.fullMenuVisible';
    let defaultFullMenuVisible = (localStorage.getItem(localStorageFullMenuVisibleSettingId) ?? 'false') === 'true';
    const [fullMenuVisible, _setFullMenuVisible] = useState<boolean>(defaultFullMenuVisible);
    const applicationRoutes = getApplicationRoutes(languageContext);

    const setFullMenuVisible = (newFullMenuVisible: boolean): void => {
        _setFullMenuVisible(newFullMenuVisible);
        localStorage.setItem(localStorageFullMenuVisibleSettingId, newFullMenuVisible.toString());
    }

    const getApplicationRouteById = (id: ApplicationRouteId): ApplicationRoute => {
        let applicationRoute = applicationRoutes.find(applicationRoute => applicationRoute.id === id);
        if (applicationRoute) return applicationRoute;
        return applicationRoutes[0];
    }

    const getApplicationRouteByRoute = (route: string): ApplicationRoute => {
        let applicationRoute = applicationRoutes.find(applicationRoute => applicationRoute.route === route);
        if (applicationRoute) return applicationRoute;
        return applicationRoutes[0];
    }

    const initialApplicationRoute = getApplicationRouteByRoute(location.pathname);
    const [selectedApplicationRoute, _setSelectedApplicationRoute] = useState<ApplicationRoute>(initialApplicationRoute);

    const setSelectedApplicationRoute = (id: ApplicationRouteId, params?: Dictionary<string | number | Date | undefined> | null): void => {
        const next = getApplicationRouteById(id);

        _setSelectedApplicationRoute(next);

        if (!params) {
            history.push(next.route);
        } else {
            history.push({ pathname: next.route, search: urlContext.buildUrlQuery(params) });
        }
    }

    const replaceSelectedApplicationRoute = (id: ApplicationRouteId, params: Dictionary<string | number | Date | undefined>): void => {
        if (selectedApplicationRoute.id !== getApplicationRouteById(id).id) return;

        const changes = { search: urlContext.buildUrlQuery(params) };
        history.replace({ ...history.location, ...changes });
    }

    const value = {
        applicationRoutes,
        selectedApplicationRoute,
        fullMenuVisible,
        getApplicationRouteById,
        getApplicationRouteByRoute,
        setFullMenuVisible,
        setSelectedApplicationRoute,
        replaceSelectedApplicationRoute
    };

    return (
        <MenuContext.Provider value={value}>
            {children}
        </MenuContext.Provider>
    );
}

export const useMenuContext = (): MenuContext => {
    return useContext(MenuContext);
}
