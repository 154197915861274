import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React from "react";
import { useProjectCompanyContext } from "../../contexts/projectCompany/projectCompanyContext";
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type props = {
    selectedProjectCompanyGroup: string | undefined;
    onSelectedProjectCompanyGroupChange: (newSelectedProjectCompanyGroup: string | undefined) => void;
    projectCompanyGroupFilter?: (projectCompanyGroups: string[]) => string[];
    label?: React.ReactNode;
}

const SelectProjectCompanyGroup: React.FC<props> = ({
    selectedProjectCompanyGroup,
    onSelectedProjectCompanyGroupChange, 
    projectCompanyGroupFilter,
    label }) => {

    const languageContext = useLanguageContext();
    const projectCompanyContext = useProjectCompanyContext();

    let projectCompanyGroups = projectCompanyContext.getProjectCompanies().map(projectCompany => (projectCompany.projectCompanyGroup ?? '').trim()).filter(group => group.length > 0);
    if (projectCompanyGroupFilter) {
        projectCompanyGroups = projectCompanyGroupFilter(projectCompanyGroups);
    }

    const defaultProjectCompanyGroup = selectedProjectCompanyGroup ?? null;

    const handleChange = (event: any, newSelectedProjectCompanyGroup: string | null) => {
        onSelectedProjectCompanyGroupChange(newSelectedProjectCompanyGroup ?? undefined);
    }

    return <Autocomplete
        freeSolo={true}
        options={projectCompanyGroups}
        getOptionLabel={(projectCompanyGroup) => projectCompanyGroup}
        value={defaultProjectCompanyGroup ?? ''}
        onChange={handleChange}
        onInputChange={handleChange}
        renderInput={(params) => <TextField {...params}
            label={label ?? languageContext.getMessage('group')}
            variant="outlined" />}
    />
}

export default SelectProjectCompanyGroup;