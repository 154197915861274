import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, Analytics } from "../../../contracts/contracts";

export const QUERY_ANALYTICS: DocumentNode = gql(`
   query analytics(
        $searchIndexStart: Int
        $searchIndexStop: Int
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $projectId: UUID
      	$averageProjectCost: Float
    ) {
        analytics(
            searchIndexStart: $searchIndexStart
            searchIndexStop: $searchIndexStop
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            projectId: $projectId
            averageProjectCost: $averageProjectCost
        ) {
            id
            documentType
            stateTime
            state
            created
            projectId
            averageProjectCost
        }
    }
`);

export const getAnalyticsGraphqlQueryOptions = (analytics: Partial<Analytics>): QueryHookOptions => {
    analytics.state = analytics.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = options.variables ?? {};
    if (analytics.id !== undefined && analytics.id !== null) options.variables['id'] = analytics.id;
    if (analytics.state !== undefined && analytics.state !== null) options.variables['state'] = analytics.state;
    if (analytics.projectId !== undefined && analytics.projectId !== null) options.variables['projectId'] = analytics.projectId;
    if (analytics.searchIndexStart !== undefined) options.variables['searchIndexStart'] = analytics.searchIndexStart;
    if (analytics.searchIndexStop !== undefined) options.variables['searchIndexStop'] = analytics.searchIndexStop;
    if (analytics.averageProjectCost !== undefined) options.variables['averageProjectCost'] = analytics.averageProjectCost;
    return options;
}
