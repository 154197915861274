import type { MutationHookOptions } from "@apollo/client";
import type { DocumentNode } from "graphql";
import gql from "graphql-tag";

export const MUTATE_CLIENT_EXCEPTION: DocumentNode = gql(`
    mutation mutateClientException(
        $url: String!, 
        $exception: String!
    ) {
        mutateClientException(
            url: $url, 
            exception: $exception
        ) {
            url
            exception
        }
    }
`);

export const getClientExceptionGraphqlMutationOptions = (
  url: string,
  exception: string
): MutationHookOptions => {
  const options: MutationHookOptions = {};
  options.variables = { url: url, exception: exception ?? "" };
  return options;
};
