import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNewsFeedContext } from "../../contexts/newsFeed/newsFeedContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { ActiveState, NewsFeed, NewsFeedAccessLevel } from "../../contracts/contracts";
import ConfirmDeleteDialog from "../confirmComponents/ConfirmDeleteDialog";
import ConfirmSaveDialog from "../confirmComponents/ConfirmSaveDialog";
import { navigateToExternalUrl } from "../../utils/urlTools";

type props = {
  newsFeed?: NewsFeed;
  open: boolean;
  onClose: (mutatedNewsFeed?: NewsFeed) => Promise<void>;
}

const EditNewsFeed: React.FC<props> = ({
  newsFeed,
  open,
  onClose,
}) => {
  const languageContext = useLanguageContext();
  const newsFeedContext = useNewsFeedContext();

  const [editedNewsFeed, setEditedNewsFeed] = useState<NewsFeed>({});
  const handlingNewNewsFeed = editedNewsFeed.id === undefined;

  const saveNewsFeed = async () => {
    const mutatedNewsFeed: NewsFeed = { ...editedNewsFeed };
    mutatedNewsFeed.state = mutatedNewsFeed.state ?? ActiveState.ACTIVE;
    await newsFeedContext.mutateNewsFeed(mutatedNewsFeed);
    await onClose(mutatedNewsFeed);
  };

  const handleOnClickClose = () => {
    onClose(undefined);
  };

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const handleOnClickDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = async (deleteIsConfirmed: boolean) => {
    if (deleteIsConfirmed) {
      editedNewsFeed.state = ActiveState.INACTIVE;
      await saveNewsFeed();
    }
    setOpenConfirmDeleteDialog(false);
  }

  const [openConfirmSaveDialog, setOpenConfirmSaveDialog] = useState<boolean>(false);
  const handleOnClickSave = () => {
    setOpenConfirmSaveDialog(true);
  };

  const handleOnCloseConfirmSaveDialog = async (saveIsConfirmed: boolean) => {
    if (saveIsConfirmed) {
      editedNewsFeed.state = ActiveState.ACTIVE;
      await saveNewsFeed();
    }
    setOpenConfirmSaveDialog(false);
  }

  let title = handlingNewNewsFeed ? languageContext.getMessage('newNewsFeed') : languageContext.getMessage('editNewsFeed');

  useEffect(() => {
    if (newsFeed) {
      setEditedNewsFeed({ ...newsFeed, ...{ accessLevel: newsFeed.accessLevel ?? NewsFeedAccessLevel.PUBLIC } });
    }
  }, [newsFeed]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleOnClickClose}
        open={open}>
        <DialogTitle>
          {title}
          <IconButton onClick={handleOnClickClose} title={languageContext.getMessage('cancel')} sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth={true}>
                <InputLabel>{languageContext.getMessage('newsFeedAccessLevel')}</InputLabel>
                <Select
                  value={editedNewsFeed.accessLevel ?? NewsFeedAccessLevel.PUBLIC}
                  variant="standard"
                  onChange={(event) => {
                    setEditedNewsFeed({ ...editedNewsFeed, accessLevel: event.target.value as NewsFeedAccessLevel });
                  }}
                  fullWidth={true}
                  readOnly={false}
                >
                  <MenuItem value={NewsFeedAccessLevel.PUBLIC}>{languageContext.getMessage('newsFeedAccessLevelPublic')}</MenuItem>
                  <MenuItem value={NewsFeedAccessLevel.PRIVATE}>{languageContext.getMessage('newsFeedAccessLevelPrivate')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={languageContext.getMessage('title')}
                variant="standard"
                value={editedNewsFeed.title ?? ''}
                onChange={(event) => {
                  setEditedNewsFeed({ ...editedNewsFeed, title: event.target.value as string });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={languageContext.getMessage('message')}
                helperText={
                    <Link onClick={() => {
                        navigateToExternalUrl('https://www.markdownguide.org/basic-syntax/');
                    }}>
                        {languageContext.getMessage('markdownSupported')}
                    </Link>}
                variant="standard"
                multiline
                minRows={4}
                maxRows={20}
                value={editedNewsFeed.newsFeedDescription ?? ''}
                onChange={(event) => {
                  setEditedNewsFeed({ ...editedNewsFeed, newsFeedDescription: event.target.value as string });
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!handlingNewNewsFeed && <Button onClick={handleOnClickDelete} color="primary" variant="contained">
            {languageContext.getMessage('delete')}
          </Button>}
          <Button onClick={handleOnClickSave} color="primary" variant="contained">
            {languageContext.getMessage('save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog
        title={languageContext.getMessage('doYouWantToDeleteNewsFeed')}
        open={openConfirmDeleteDialog}
        onClose={handleOnCloseConfirmDeleteDialog}
      />
      <ConfirmSaveDialog
        title={languageContext.getMessage('doYouWantToSaveNewsFeed')}
        open={openConfirmSaveDialog}
        onClose={handleOnCloseConfirmSaveDialog}
      />
    </>
  );
}

export default EditNewsFeed
