import { Container, Link, Typography } from "@mui/material";
import React from "react";
import { useLanguageContext, LanguageContext } from "../../contexts/language/LanguageContext";

const companyCopyright = (languageContext: LanguageContext): JSX.Element => {
  return (
    <Typography variant="caption" color="textSecondary">
      {'Copyright © '}
      <Link color="inherit" href={languageContext.getMessage('companySite')}>
        {languageContext.getMessage('company')}
      </Link>{' '}
      {2022}
      {'. '}
    </Typography>
  );
}

const ApplicationAppFooter: React.FC<{}> = () => {
  const languageContext = useLanguageContext();

  return (
    <footer>
        <Container sx={{ textAlign: 'center' }}>
          {companyCopyright(languageContext)}
        </Container>
    </footer>
  );
}

export default ApplicationAppFooter;
