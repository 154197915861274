import { Button, Container, Grid } from '@mui/material'
import { withTheme } from '@mui/styles'
import React from 'react'

type Props = {
    children: any;
    bottomNavigation?: JSX.Element | string | null;
}

const PageContentLayout: React.FC<Props> = ({ children, bottomNavigation }) => {

    return (
        // <Container>
        <Grid container spacing={1} flexWrap='nowrap' flexDirection='column' height='100%' sx={{ overflow: 'hidden', m: 0, width: '100%', position: 'relative' }}>
            <Container sx={{ overflow: 'auto', padding: 0, height: '100%' }}>
                {/* <Grid item xs={12} sx={{ overflow: 'auto', padding: '0 !important' }}> */}
                    {children}
                {/* </Grid> */}
            </Container>

            <Container sx={{ marginTop: 1.5, display: 'flex', justifyContent: 'space-between', flexBasis: 'unset', padding: 0 }}>
                {bottomNavigation}
            </Container>
        </Grid>
        //</Container>
    )
}

export default withTheme(PageContentLayout)
