import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, Analytics } from "../../contracts/contracts";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useLanguageContext } from "../language/LanguageContext";
import { useUrlContext } from "../url/urlContext";
import { AnalyticsQueriesContextContext, useAnalyticsQueriesContext } from "./queries/analyticsQueriesContext";
import { AnalyticsSubscriptionsContextProvider, useAnalyticsSubscriptionsContext } from "./subscriptions/analyticsSubscriptionsContext";



export interface AnalyticsContext {
    getAnalyticsSearch: () => Analytics,
    searchAnalytics: (analyticsSearch: Analytics) => void,
    getAnalytics: () => Analytics,
    loadingAnalytics: boolean,
}

const AnalyticsContext = createContext<AnalyticsContext>(null as unknown as AnalyticsContext);

export const sortAnalyticsByDate = (a: Analytics, b: Analytics) => {
    if ((a.created ?? "") < (b.created ?? "")) { return -1; }
    if ((a.created ?? "") > (b.created ?? "")) { return 1; }
    return 0;
}

export enum AnalyticsTabs {
    all = "all",
    details = "details",
    assets = "assets",
}

export const AnalyticsContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <AnalyticsQueriesContextContext>
            <AnalyticsSubscriptionsContextProvider>
                <AnalyticsSubContextProvider>
                    {children}
                </AnalyticsSubContextProvider>
            </AnalyticsSubscriptionsContextProvider>
        </AnalyticsQueriesContextContext>
    );
}

export const AnalyticsSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();

    const analyticsQueriesContext = useAnalyticsQueriesContext();
    const analyticsSubscriptionsContext = useAnalyticsSubscriptionsContext();

    const [currentAnalyticsSearch, setCurrentAnalyticsSearch] = useState<Analytics | undefined>(undefined);
    const [analytics, setAnalytics] = useState<Analytics[]>([]);
    const maxAnalyticsToFetchAtOnce = 100;


    const mergeAnalytics = (oldAnalytics: Array<Analytics>, newAnalytics: Array<Analytics>): Array<Analytics> => {
        const updatedAnalytics = oldAnalytics.slice();
        if (!newAnalytics) {
            console.error(`Received undefined set of Analytics: ${newAnalytics}`);
            return [];
        }
        newAnalytics.forEach(newAnalytics => {
            newAnalytics.created = new Date(newAnalytics.created ?? 0);

            const index = updatedAnalytics.findIndex(analytics => analytics.id === newAnalytics.id);
            if (index >= 0) {
                if (newAnalytics.state === ActiveState.ACTIVE) {
                    updatedAnalytics[index] = newAnalytics;
                }
                else {
                    updatedAnalytics.splice(index, 1);
                }
            } else {
                if (newAnalytics.state === ActiveState.ACTIVE) {
                    updatedAnalytics.push(newAnalytics);
                }
            }
        });
        return updatedAnalytics.sort(sortAnalyticsByDate);
    }


    const getAnalyticsSearch = (): Analytics => {
        const urlState = urlContext.getUrlState();
        return {
        }
    }

    const searchAnalytics = (analyticsSearch: Analytics): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(analyticsSearch, currentAnalyticsSearch);
        matched = matched && analyticsSearch?.id === currentAnalyticsSearch?.id;
        if (!matched) {
            analyticsSearch.searchIndexStart = 0;
            analyticsSearch.searchIndexStop = maxAnalyticsToFetchAtOnce;
            setCurrentAnalyticsSearch(analyticsSearch);
            setAnalytics([]);
        }
    }

    const getAnalytics = (): Analytics => {
        return analytics.length > 0 ? analytics[0] : {};
    }

    useEffect(() => {
        if (currentAnalyticsSearch) {
            analyticsQueriesContext.queryAnalytics(currentAnalyticsSearch);
        }
    }, [currentAnalyticsSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setAnalytics([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setAnalytics(mergeAnalytics(analytics, analyticsQueriesContext.fetchedAnalytics));
        if (analyticsQueriesContext.fetchedAnalytics.length >= maxAnalyticsToFetchAtOnce && currentAnalyticsSearch) {
            currentAnalyticsSearch.searchIndexStart = (currentAnalyticsSearch.searchIndexStart ?? 0) + analyticsQueriesContext.fetchedAnalytics.length;
            currentAnalyticsSearch.searchIndexStop = currentAnalyticsSearch.searchIndexStart + maxAnalyticsToFetchAtOnce;
            setCurrentAnalyticsSearch({...currentAnalyticsSearch});
        }
    }, [analyticsQueriesContext.fetchedAnalytics]);

    useEffect(() => {
        setAnalytics(mergeAnalytics(analytics, analyticsSubscriptionsContext.subscribedAnalytics));
    }, [analyticsSubscriptionsContext.subscribedAnalytics]);

    const analyticsContext = {
        getAnalyticsSearch,
        searchAnalytics,
        getAnalytics,
        loadingAnalytics: analyticsQueriesContext.queryResponse.loading,
    };

    return (
        <AnalyticsContext.Provider value={analyticsContext}>
            {children}
        </AnalyticsContext.Provider>
    );
}

export const useAnalyticsContext = (): AnalyticsContext => {
    return useContext(AnalyticsContext);
}
