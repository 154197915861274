import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, Certificate } from "../../../contracts/contracts";

export const MUTATE_CERTIFICATE: DocumentNode = gql`
  mutation mutateCertificate(
    $id: UUID
    $state: ActiveState
    $applicationProfileId: UUID!
    $projectMemberId: UUID
    $certificateType: CertificateType
    $title: String
    $certificateDescription: String
    $numberOfCertificateFiles: Int
    $certificateStatus: CertificateStatus
    $certificateId: String
    $certificateNumber: String
    $certificateUrl: String
    $isActive: Boolean
    $isRevoked: Boolean
  ) {
    mutateCertificate(
      id: $id
      state: $state
      applicationProfileId: $applicationProfileId
      projectMemberId: $projectMemberId
      certificateType: $certificateType
      title: $title
      certificateDescription: $certificateDescription
      numberOfCertificateFiles: $numberOfCertificateFiles
      certificateStatus: $certificateStatus
      certificateId: $certificateId
      certificateNumber: $certificateNumber
      certificateUrl: $certificateUrl
      isActive: $isActive
      isRevoked: $isRevoked
    ) {
      tickets {
        id
        parentDocumentId
        parentDocumentType
        processTag
      }
    }
  }
`;

export const getCertificatesGraphqlMutationOptions = (certificate: Partial<Certificate>): QueryHookOptions => {
  certificate.state = certificate.state ?? ActiveState.ACTIVE;
  const options: QueryHookOptions = {};
  options.variables = {};
  if (certificate.id !== undefined && certificate.id !== null) options.variables["id"] = certificate.id;
  if (certificate.state !== undefined && certificate.state !== null) options.variables["state"] = certificate.state;
  if (certificate.applicationProfileId !== undefined && certificate.applicationProfileId !== null) options.variables["applicationProfileId"] = certificate.applicationProfileId;
  if (certificate.projectMemberId !== undefined && certificate.projectMemberId !== null) options.variables["projectMemberId"] = certificate.projectMemberId;
  if (certificate.certificateType !== undefined && certificate.certificateType !== null) options.variables["certificateType"] = certificate.certificateType;
  if (certificate.title !== undefined && certificate.title !== null) options.variables["title"] = certificate.title;
  if (certificate.certificateDescription !== undefined && certificate.certificateDescription !== null) options.variables["certificateDescription"] = certificate.certificateDescription;
  if (certificate.numberOfCertificateFiles !== undefined && certificate.numberOfCertificateFiles !== null) options.variables["numberOfCertificateFiles"] = certificate.numberOfCertificateFiles;
  if (certificate.certificateStatus !== undefined && certificate.certificateStatus !== null) options.variables["certificateStatus"] = certificate.certificateStatus;
  if (certificate.certificateId !== undefined && certificate.certificateId !== null) options.variables["certificateId"] = certificate.certificateId;
  if (certificate.certificateNumber !== undefined && certificate.certificateNumber !== null) options.variables["certificateNumber"] = certificate.certificateNumber;
  if (certificate.certificateUrl !== undefined && certificate.certificateUrl !== null) options.variables["certificateUrl"] = certificate.certificateUrl;
  if (certificate.isActive !== undefined && certificate.isActive !== null) options.variables["isActive"] = certificate.isActive;
  if (certificate.isRevoked !== undefined && certificate.isRevoked !== null) options.variables["isRevoked"] = certificate.isRevoked;
  return options;
};
