import React from "react";
import { Theme } from "@mui/material";
import { makeStyles, withTheme } from '@mui/styles';
import Vimeo, { VimeoProps } from '@u-wave/react-vimeo';

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

type props = VimeoProps & {
};

const ShowVimeoVideo: React.FC<props> = (props) => {
    const classes = useStyles();

    return <div className={classes.card}>
        <Vimeo
            {...props}
        />
    </div>
}

export default withTheme(ShowVimeoVideo);