import React, { createContext, useContext, useEffect, useState } from "react";
import { StateDocument, Ticket } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_STATE_DOCUMENT, getStateDocumentsGraphqlQueryOptionsWithTickets } from './stateDocumentQueries'
import { useAuthContext } from "../../auth/authContext";

export interface StateDocumentQueriesContext {
    fetchedStateDocuments: Array<StateDocument>,
    queryStateDocumentsWithTickets: (tickets: Ticket[]) => void,
}

const StateDocumentQueriesContext = createContext<StateDocumentQueriesContext>(null as unknown as StateDocumentQueriesContext);

export const StateDocumentQueriesContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedStateDocuments, setFetchedStateDocuments] = useState<Array<StateDocument>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_STATE_DOCUMENT);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);
    const [ticketBuffer, setTicketBuffer] = useState<Ticket[]>([]);

    const queryStateDocumentsWithTickets = (tickets: Ticket[]): void => {
        if (tickets.length === 0) {
            return;
        }
        setTicketBuffer(ticketBuffer.concat(tickets));
        return;
    }

    const queryStateDocuments = (): void => {
        if (isWaitingForResponse) {
            return;
        }
        if (ticketBuffer.length === 0) {
            return;
        }
        const queryOptions = getStateDocumentsGraphqlQueryOptionsWithTickets(ticketBuffer);
        setTicketBuffer([]);
        setIsWaitingForResponse(true);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            queryStateDocuments();
        }, 100);
        return () => clearTimeout(timer);
    });

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.stateDocuments) {
            const newFetchedStateDocuments: Array<StateDocument> = queryResponse.data.stateDocuments;
            setFetchedStateDocuments(newFetchedStateDocuments);
        }
        setIsWaitingForResponse(false);
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedStateDocuments([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const stateDocumentQueriesContext: StateDocumentQueriesContext = {
        fetchedStateDocuments,
        queryStateDocumentsWithTickets,
    };

    return (
        <StateDocumentQueriesContext.Provider value={stateDocumentQueriesContext}>
            {children}
        </StateDocumentQueriesContext.Provider>
    );
}

export const useStateDocumentQueriesContext = (): StateDocumentQueriesContext => {
    return useContext(StateDocumentQueriesContext);
}
