import React, { useCallback, useEffect, useMemo } from "react";
import {
	Autocomplete,
	CircularProgress,
	Container,
	Divider,
	Grid,
	List,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography
} from "@mui/material";
import { withTheme } from '@mui/styles';
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { NewsFeedTabs, useNewsFeedContext } from "../../contexts/newsFeed/newsFeedContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { DocumentType, NewsFeed, NewsFeedAccessLevel, RoleType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { Guid } from "../../utils/common-types";
import NewsFeedDetails from "./newsFeedDetailsView";
import NewsFeedTeaser from "../../component/newsFeed/newsFeedTeaser";
import { useProjectContext } from "../../contexts/project/projectContext";
import EditNewsFeed from "../../component/newsFeed/editNewsFeed";
import FabButton from "../../component/generalComponents/FabButton";
import AddIcon from '@mui/icons-material/Add';
import { useApplicationProfileContext } from "../../contexts/applicationProfile/applicationProfileContext";
import EditIcon from "@mui/icons-material/Edit";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";

const NewsFeedView: React.FC<{}> = () => {
	const urlContext = useUrlContext();
	const languageContext = useLanguageContext();
	const ticketContext = useTicketContext();
	const newsFeedContext = useNewsFeedContext();
	const projectContext = useProjectContext();
	const projectMemberContext = useProjectMemberContext();
	const applicationProfileContext = useApplicationProfileContext();

	const [search, setSearch] = React.useState<string | NewsFeed | null>(null);
	const [openNewNewsFeed, setOpenNewNewsFeed] = React.useState(false);
	const [newNewsFeed, setNewNewsFeed] = React.useState<NewsFeed>({})

	const newsFeedAccessLevel = urlContext.getUrlState().accessLevel ?? NewsFeedAccessLevel.PUBLIC;

	const newsFeedSearch = newsFeedContext.getNewsFeedSearch();
	const loading = newsFeedContext.loadingNewsFeeds;

	const urlState = urlContext.getUrlState();
	const tabIndex = urlState.tab ? urlState.tab as string : NewsFeedTabs.all;
	const selectedNewsFeedId = urlState.newsFeedId ? urlState.newsFeedId as Guid : null;
	const selectedNewsFeed = newsFeedContext.getNewsFeed(selectedNewsFeedId ?? '');
	const selectedProjectId = urlContext.getUrlState().selectedProjectId;

	const newsFeeds = newsFeedContext.getNewsFeeds().filter(newsFeed => newsFeed.accessLevel === newsFeedAccessLevel);

	const projectSearch = projectContext.getProjectSearch();
	const applicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser();

	const matchNewsFeedFilter = (searchMatch: string, newsFeed: NewsFeed): boolean => {
		return (newsFeed.title ?? '').toLowerCase().includes(searchMatch.toLowerCase()) || (newsFeed.newsFeedDescription ?? '').toLowerCase().includes(searchMatch.toLowerCase());
	}

	const filteredNewsFeeds = useMemo(() => {
		if (!search) return newsFeeds;
		if (typeof search === "string") return newsFeeds.filter(newsFeed => matchNewsFeedFilter(search, newsFeed));
		if (typeof search === "object") return newsFeeds.filter(newsFeed => newsFeed.id === search.id);
		return [];
	}, [newsFeedContext.getNewsFeeds(), search]);

	useEffect(() => {
		ticketContext.setDocumentTypesToWatch([
			DocumentType.NEWS_FEED,
			DocumentType.PROJECT]);

		projectContext.searchProjects(projectSearch);
		newsFeedContext.searchNewsFeeds({
			...newsFeedSearch,
			projectId: selectedProjectId,
			accessLevel: newsFeedAccessLevel
		});
	}, [urlContext.currentLocation]);

	const onSelectedNewsFeedDetailsChange = useCallback((newsFeed: NewsFeed | undefined) => {
		const newUrlState = {
			...urlState,
			...newsFeedSearch,
			...{ 'newsFeedId': newsFeed?.id },
			...{ 'tab': NewsFeedTabs.details }
		}
		const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date>);
		urlContext.pushUrlQuery(urlQuery);
	}, [urlContext, newsFeedSearch, urlState]);

	const onAccessLevelChange = (e: any, accessLevel: NewsFeedAccessLevel) => {
		const newUrlState = {
			...urlState,
			...newsFeedSearch,
			...{ 'accessLevel': accessLevel }
		}
		const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date>);
		urlContext.pushUrlQuery(urlQuery);
	}

	const hasWriteAccessToSelectedProject = selectedProjectId && projectMemberContext.hasProjectAccess(RoleType.WRITER, selectedProjectId);
	const actionsDisabled = !(selectedProjectId && applicationProfile?.id) || !hasWriteAccessToSelectedProject

	useEffect(() => {
		setNewNewsFeed(newsFeedItem => ({ ...newsFeedItem, applicationProfileId: applicationProfile?.id }))
	}, [applicationProfileContext])

	useEffect(() => {
		setNewNewsFeed(newsFeedItem => ({ ...newsFeedItem, projectId: selectedProjectId }))
	}, [selectedProjectId])

	useEffect(() => {
		if (newsFeedAccessLevel) {
			setNewNewsFeed(newsFeedItem => ({ ...newsFeedItem, accessLevel: newsFeedAccessLevel }))
		}
	}, [newsFeedAccessLevel])

	return (<>
		<EditNewsFeed newsFeed={tabIndex === NewsFeedTabs.all ? newNewsFeed : selectedNewsFeed} open={openNewNewsFeed}
			onClose={async () => setOpenNewNewsFeed(false)} />

		{!actionsDisabled && (
			<FabButton onClick={() => setOpenNewNewsFeed(true)} icon={tabIndex === NewsFeedTabs.all ? <AddIcon /> : <EditIcon />} label={languageContext.getMessage("add")} />
		)}
		<Container>
			<Grid container spacing={1} flexWrap='nowrap' flexDirection='column' flexBasis='100%'>
				{tabIndex === NewsFeedTabs.all &&
					<Grid item container xs={12} spacing={1}>
						<Grid item xs={12}>
							<Autocomplete
								sx={{ mb: 2 }}
								freeSolo
								onInputChange={(e, value) => setSearch(value)}
								onChange={(e, value) => setSearch(value)}
								getOptionLabel={newsFeed => {
									if (typeof newsFeed === 'string') {
										return newsFeed;
									}
									return newsFeed.title ?? '';
								}}
								options={newsFeeds}
								renderInput={(params) => loading ? <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 56 }}><CircularProgress /></Grid> : <TextField {...params} label={languageContext.getMessage("search")} />}
							/>
						</Grid>
						<Grid item xs={12} container sx={{ my: 1 }}>
							<ToggleButtonGroup
								color="primary"
								value={newsFeedAccessLevel}
								exclusive
								onChange={onAccessLevelChange}
								fullWidth
								sx={{ mb: 1 }}
							>
								<ToggleButton value={NewsFeedAccessLevel.PUBLIC}>{languageContext.getMessage('newsFeedAccessLevelPublic')}</ToggleButton>
								<ToggleButton value={NewsFeedAccessLevel.PRIVATE}>{languageContext.getMessage('newsFeedAccessLevelPrivate')}</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
						<Grid item xs={12}>
							<Typography textAlign="center" variant="h5">{languageContext.getMessage('messages')}</Typography>
							<List sx={{ bgcolor: 'background.paper', mt: 2, pt: 0 }}>
								{filteredNewsFeeds.map((newsFeed, index) => (
									<React.Fragment key={newsFeed.id}>
										<NewsFeedTeaser newsFeed={newsFeed} onTeaserClick={onSelectedNewsFeedDetailsChange} />
										{index !== filteredNewsFeeds.length - 1 ? <Divider /> : null}
									</React.Fragment>
								))}
							</List>
						</Grid>
					</Grid>
				}
				{tabIndex === NewsFeedTabs.details &&
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography textAlign="center" variant="h5">{languageContext.getMessage('message')}</Typography>
								<List sx={{ bgcolor: 'background.paper', mt: 2 }}>
									<NewsFeedDetails newsFeed={selectedNewsFeed} />
								</List>
							</Grid>
						</Grid>
					</Grid>
				}
			</Grid>
		</Container>
	</>
	);
}

export default withTheme(NewsFeedView);
