
import React, { createContext, useContext, useEffect, useState } from "react";
import { UserRole, User, DocumentType, ProjectMember } from "../../contracts/contracts";
import { useAuthContext } from "../auth/authContext";
import { UserRolesQueriesContextContext, useUserRolesQueriesContext } from "./queries/userRoleQueriesContext";
import { UserRolesMutationsContextProvider, useUserRolesMutationsContext } from "./mutations/userRoleMutationsContext";
import { getRoleType, getRoleTypeIndex } from "./userRoleTools";

export interface UserRoleContext {
    getUserRoles: (projectMember?: ProjectMember) => UserRole[],
    getUsers: () => User[],
    queryRoles: (userRoles?: UserRole[]) => void,
    queryUsers: (userRoles?: UserRole[]) => void,
    updateUserRole: (user: User) => Promise<void>,
    isLoadingRoles: boolean;
    isLoadingUsers: boolean;
}

const UserRoleContext = createContext<UserRoleContext>(null as unknown as UserRoleContext);

export const sortUsersByName = (a: User, b: User) => {
    if ((a.name ?? "") < (b.name ?? "")) { return -1; }
    if ((a.name ?? "") > (b.name ?? "")) { return 1; }
    return 0;
}

export const UserRoleContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <UserRolesMutationsContextProvider>
            <UserRolesQueriesContextContext>
                <UserRoleSubContextProvider>
                    {children}
                </UserRoleSubContextProvider>
            </UserRolesQueriesContextContext>
        </UserRolesMutationsContextProvider>
    );
}

export const UserRoleSubContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const userRolesQueriesContext = useUserRolesQueriesContext();
    const userRolesMutationContext = useUserRolesMutationsContext();

    const [users, setUsers] = useState<User[]>([]);
    const [roles, setRoles] = useState<UserRole[]>([]);
    const [lastRolesQuery, setLastRolesQuery] = useState<UserRole[] | undefined>([]);
    const [lastUsersQuery, setLastUsersQuery] = useState<UserRole[] | undefined>([]);

    const checkUserRolesMatch = (userRoles: UserRole[] | undefined, matchingUserRoles: UserRole[] | undefined): boolean => {
        if (!userRoles && !matchingUserRoles) {
            return true;
        }
        if ((userRoles && !matchingUserRoles) || (!userRoles && matchingUserRoles)) {
            return false;
        }
        userRoles = userRoles ?? [];
        matchingUserRoles = matchingUserRoles ?? [];
        if (userRoles.length !== matchingUserRoles.length) {
            return false;
        }
        const match = userRoles.findIndex((userRole, index) => (matchingUserRoles ?? [])[index]?.id !== userRole.id ||
                                                               (matchingUserRoles ?? [])[index]?.name !== userRole.name ||
                                                               (matchingUserRoles ?? [])[index]?.delete !== userRole.delete) < 0;
        return match;
    }

    const getUserRoles = (projectMember?: ProjectMember): UserRole[] => {
        let userRoles: UserRole[] = [];
        if (!projectMember) {
            userRoles = roles.filter(role => !role.name?.startsWith(DocumentType.PROJECT) ?? false);
        }
        else {
            userRoles = roles.filter(role => role.name?.startsWith(`${DocumentType.PROJECT}-${projectMember?.projectId}`) ?? false);
        }
        userRoles.filter(role => getRoleType(role) !== undefined);
        return userRoles.sort((a, b) => getRoleTypeIndex(getRoleType(a)) - getRoleTypeIndex(getRoleType(b)));
    }

    const getUsers = (): User[] => {
        return users.sort(sortUsersByName);
    }

    const queryRoles = (userRoles?: UserRole[]): void => {
        if (checkUserRolesMatch(userRoles, lastRolesQuery)) {
            return;
        }
        if (userRolesQueriesContext.queryRoles(userRoles)) {
            setLastRolesQuery(userRoles);
        }
    }

    const queryUsers = (userRoles?: UserRole[]): void => {
        if (checkUserRolesMatch(userRoles, lastUsersQuery)) {
            return;
        }
        if (userRolesQueriesContext.queryUsers(userRoles)) {
            setLastUsersQuery(userRoles);
        }
    }

    const updateUserRole = async (user: User): Promise<void> => {
        const updatedUser = await userRolesMutationContext.mutateUserRole(user);
        updatedUser.roles = updatedUser.roles ?? [];
        updatedUser.roles = updatedUser.roles.filter(role => role.delete !== true);
        const index = users.findIndex(existingUser => existingUser.id === updatedUser.id);
        if (index < 0) {
            users.push(updatedUser);
        }
        else {
            users[index] = updatedUser;
        }
        setUsers(users.slice());
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setUsers([]);
            setRoles([]);
            return;
        }
    }, [authContext.authenticated]);


    useEffect(() => {
        setRoles(userRolesQueriesContext.fetchedUserRoles);
    }, [userRolesQueriesContext.fetchedUserRoles]);

    useEffect(() => {
        setUsers(userRolesQueriesContext.fetchedUsers);
    }, [userRolesQueriesContext.fetchedUsers]);

    useEffect(() => {
        if (authContext.tokenIsReady || authContext.insecure) {

        }
    }, [authContext.tokenIsReady, authContext.insecure]);

    const userRoleContext: UserRoleContext = {
        getUserRoles,
        getUsers,
        queryRoles,
        queryUsers,
        updateUserRole,
        isLoadingRoles: userRolesQueriesContext.isLoadingRoles,
        isLoadingUsers: userRolesQueriesContext.isLoadingUsers,
    };

    return (
        <UserRoleContext.Provider value={userRoleContext}>
            {children}
        </UserRoleContext.Provider>
    );
}

export const useUserRoleContext = (): UserRoleContext => {
    return useContext(UserRoleContext);
}