import React from 'react';
import { LanguageType, useLanguageContext } from '../../contexts/language/LanguageContext';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getLanguageAlternatives } from '../../utils/languageTools';
import { useApplicationProfileContext } from '../../contexts/applicationProfile/applicationProfileContext';

type props = {
  open: boolean;
  onClose?: () => void;
}

const LanguageDialog: React.FC<props> = ({
  open,
  onClose }) => {

  const languageContext = useLanguageContext();  
  const applicationProfileContext = useApplicationProfileContext();

  const handleSelectLanguage = async (language: LanguageType): Promise<void> => {
    await applicationProfileContext.setApplicationLanguage(language);
    if (onClose) {
      onClose();
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={async () => await handleSelectLanguage(languageContext.getLanguage())}
      >
        <DialogTitle>
          {languageContext.getMessage('selectLanguage')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {getLanguageAlternatives(async language => {
              await handleSelectLanguage(language);
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LanguageDialog;