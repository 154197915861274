import React, { createContext, useContext, useEffect, useState } from "react";
import { Project, DocumentType, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface ProjectSubscriptionsContext {
    subscribedProjects: Array<Project>,
}

const ProjectSubscriptionsContext = createContext<ProjectSubscriptionsContext>(null as unknown as ProjectSubscriptionsContext);

export const ProjectSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedProjects, setSubscribedProjects] = useState<Array<Project>>([]);

    const updateSubscribedProjects = (stateDocuments: StateDocument[]) => {
        const subscribedProjects = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.PROJECT && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as Project);
        setSubscribedProjects(subscribedProjects);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedProjects([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedProjects(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: ProjectSubscriptionsContext = {
        subscribedProjects,
    };

    return (
        <ProjectSubscriptionsContext.Provider value={projectContext}>
            {children}
        </ProjectSubscriptionsContext.Provider>
    );
}

export const useProjectSubscriptionsContext = (): ProjectSubscriptionsContext => {
    return useContext(ProjectSubscriptionsContext);
}
