import React, { createContext, useContext, useEffect, useState } from "react";
import { InformationVerification, DocumentType, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface InformationVerificationSubscriptionsContext {
    subscribedInformationVerifications: Array<InformationVerification>,
}

const InformationVerificationSubscriptionsContext = createContext<InformationVerificationSubscriptionsContext>(null as unknown as InformationVerificationSubscriptionsContext);

export const InformationVerificationSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedInformationVerifications, setSubscribedInformationVerifications] = useState<Array<InformationVerification>>([]);

    const updateSubscribedInformationVerifications = (stateDocuments: StateDocument[]) => {
        const subscribedInformationVerifications = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.INFORMATION_VERIFICATION && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as InformationVerification);
        setSubscribedInformationVerifications(subscribedInformationVerifications);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedInformationVerifications([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedInformationVerifications(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const informationVerificationContext: InformationVerificationSubscriptionsContext = {
        subscribedInformationVerifications,
    };

    return (
        <InformationVerificationSubscriptionsContext.Provider value={informationVerificationContext}>
            {children}
        </InformationVerificationSubscriptionsContext.Provider>
    );
}

export const useInformationVerificationSubscriptionsContext = (): InformationVerificationSubscriptionsContext => {
    return useContext(InformationVerificationSubscriptionsContext);
}
