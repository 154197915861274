import React, { FC, useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';

// material UI staff    
import { Button, Step, StepLabel, Stepper, Typography, Grid, CircularProgress, stepLabelClasses, StepIcon, Avatar } from '@mui/material';

// contexts
import { useApplicationProfileContext } from '../../contexts/applicationProfile/applicationProfileContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useInformationContext } from '../../contexts/information/informationContext';
import { useTicketContext } from '../../contexts/ticket/ticketContext';
import { useUrlContext } from '../../contexts/url/urlContext';

import { ActiveState, DocumentType, Information, InformationAccess, InformationApprovalType, InformationVerification, MemberAccess, MemberState, ProjectMember } from '../../contracts/contracts';
import { Dictionary } from '../../global-types';

import PageContentLayout from '../../component/layouts/PageContentLayout';
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from '../../contexts/menu/menuContext';
import { useInformationVerificationContext } from '../../contexts/informationVerification/informationVerificationContext';
import SafetyInformation from '../../component/informationComponents/SafetyInformation';

import { styled } from '@mui/material/styles';
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import { green } from '@mui/material/colors';
import { useProjectContext } from '../../contexts/project/projectContext';
import { getMinimumNumberOfAgreedAndVerifiedQuizInformations, getNumberOfAgreedAndVerifiedQuizInformations } from '../../utils/informationTools';

const JoinSafetyInformationView: React.FC<{}> = () => {
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
    const [watchedInformations, setWatchedInformations] = useState<Information[]>([]);
    const [fileDownloadedInformations, setFileDownloadedInformations] = useState<Information[]>([]);
    const activeStepIndexInitialized = useRef<boolean>(false);
    const [areAnswersCorrect, setAreAnswersCorrect] = useState<boolean>(false);

    const { params } = useRouteMatch<any>()
    const { projectId } = params

    const urlContext = useUrlContext();
    const menuContext = useMenuContext()
    const languageContext = useLanguageContext();

    const ticketContext = useTicketContext();
    const applicationProfileContext = useApplicationProfileContext();
    const informationContext = useInformationContext();
    const informationVerificationContext = useInformationVerificationContext()
    const projectMemberContext = useProjectMemberContext();
    const projectContext = useProjectContext();

    const projectSearch = projectContext.getProjectSearch();
    const applicationProfileSearch = applicationProfileContext.getApplicationProfileSearch();
    const informationSearch = informationContext.getInformationSearch();
    informationSearch.projectId = projectId;

    const project = projectContext.getProject(projectId);
    const applicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser();
    const informations = informationContext.getProjectParentInformations(projectId).filter(information => information.informationAccess === InformationAccess.PUBLIC || information.informationAccess === InformationAccess.PUBLIC_FOR_MEMBERS);
    const informationVerifications = informationVerificationContext.getInformationVerifications().filter(infoVerification => infoVerification.projectId === projectId && infoVerification.applicationProfileId === applicationProfile?.id);

    const projectLoading = projectContext.loadingProjects;
    const applicationProfileLoading = applicationProfileContext.loadingApplicationProfiles
    const informationLoading = informationContext.loadingInformations
    const informationVerificationLoading = informationVerificationContext.loadingInformationVerifications
    const isLoading = projectLoading || applicationProfileLoading || informationLoading || informationVerificationLoading
    const [isSaving, setIsSaving] = useState(false);

    const getLastCompletedStep = (): number => {
        let lastCompletedStep = -1;
        for (let i = 0; i < informations.length; i++) {
            if (isStepCompleted(informations[i], i)) {
                lastCompletedStep = i;
            }
            else {
                break;
            }
        }
        return lastCompletedStep;
    }

    const informationApprovalsAreCheckedCorrectly = (information: Information | undefined): boolean => {
        let currentInformationVerification = informationVerifications.find(infoVerification => infoVerification.informationId === information?.id && infoVerification.applicationProfileId === applicationProfile?.id);
        let allInformationApprovalsAreChecked = true;
        information?.informationApprovals?.forEach((informationApproval, index) => {
            if (!allInformationApprovalsAreChecked) {
                return;
            }
            const approvalRequest = informationApproval.approval ?? InformationApprovalType.APPROVED;
            let approval = InformationApprovalType.NOT_APPROVED;
            if (currentInformationVerification?.informationApprovals && currentInformationVerification?.informationApprovals.length > index) {
                approval = currentInformationVerification.informationApprovals[index].approval ?? InformationApprovalType.NOT_APPROVED;
            }
            if (approvalRequest !== approval) {
                allInformationApprovalsAreChecked = false;
            }
        })
        return allInformationApprovalsAreChecked;
    }

    const isStepCompleted = (information: Information, index: number) => {
        let currentInformationVerification = informationVerifications.find(infoVerification => infoVerification.informationId === information?.id && infoVerification.applicationProfileId === applicationProfile?.id);
        return !!currentInformationVerification?.verified && (informationApprovalsAreCheckedCorrectly(information) || information?.informationApprovalsQuizActivated);
    }

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([DocumentType.APPLICATION_PROFILE, DocumentType.INFORMATION]);

        projectContext.searchProjects(projectSearch);
        applicationProfileContext.searchApplicationProfiles(applicationProfileSearch);
        informationContext.searchInformations(informationSearch);
    }, [urlContext.currentLocation])


    useEffect(() => {
        if (!activeStepIndexInitialized.current && informationVerifications?.length && informations?.length) {
            activeStepIndexInitialized.current = true;

            const lastCompletedStep = getLastCompletedStep();
            let nextStepToComplete = lastCompletedStep;
            if (nextStepToComplete === informations.length - 1) {
                nextStepToComplete = -1;
            }
            if (nextStepToComplete < informations.length - 1) {
                nextStepToComplete++;
            }

            if (activeStepIndex !== nextStepToComplete && nextStepToComplete < informations.length && nextStepToComplete >= 0) {
                setActiveStepIndex(nextStepToComplete);
            }
        }

    }, [informationVerifications, informations])

    useEffect(() => {
        if (projectId && applicationProfile?.id) {
            ticketContext.setDocumentTypesToWatch([DocumentType.INFORMATION_VERIFICATION]);

            const informationVerificationSearch = informationVerificationContext.getInformationVerificationSearch();
            informationVerificationSearch.projectId = projectId;
            informationVerificationSearch.applicationProfileId = applicationProfile.id

            informationVerificationContext.searchInformationVerifications(informationVerificationSearch)
        }
    }, [projectId, applicationProfile?.id])

    const updateUrl = (route: ApplicationRoute, urlState?: Dictionary<string | number | Date>) => {
        const urlQuery = urlState ? urlContext.buildUrlQuery(urlState) : '';

        urlContext.pushUrlQuery(urlQuery, route.route)
    }

    const saveProjectMemberAsRequestingAccess = async (savedInformationVerification: InformationVerification | undefined) => {
        if (applicationProfile?.id) {
            
            const allSavedInformationVerifications: InformationVerification[] = informationVerifications.slice();
            if (savedInformationVerification?.id) {
                const existingSavedInformationVerificationIndex = allSavedInformationVerifications.findIndex(informationVerification => informationVerification.id === savedInformationVerification.id)
                if (existingSavedInformationVerificationIndex < 0) {
                    allSavedInformationVerifications.push(savedInformationVerification);
                }
                else {
                    allSavedInformationVerifications[existingSavedInformationVerificationIndex] = {
                        ...allSavedInformationVerifications[existingSavedInformationVerificationIndex], 
                        ...savedInformationVerification
                    };
                }
            }
            const numberOfAgreedAndVerifiedQuizInformations = getNumberOfAgreedAndVerifiedQuizInformations(applicationProfile, allSavedInformationVerifications, informations);
            const minNumberOfAgreedAndVerifiedQuizInformations = getMinimumNumberOfAgreedAndVerifiedQuizInformations(project, informations);

            let projectMemberToSave: ProjectMember = projectMemberContext.getProjectMembersForLoggedInUser().find(projectMember => projectMember.projectId === projectId) ?? {};
            projectMemberToSave.applicationProfileId = applicationProfile.id;
            projectMemberToSave.projectId = projectId;
            projectMemberToSave.state = ActiveState.ACTIVE;
            projectMemberToSave.memberState = MemberState.ACTIVE;
            projectMemberToSave.memberAccess = projectMemberToSave.memberAccess ?? MemberAccess.PUBLIC;
            projectMemberToSave.noRequestingMemberAccessTries = (projectMemberToSave.noRequestingMemberAccessTries ?? 0) + 1;
            if (projectMemberToSave.memberAccess === MemberAccess.PUBLIC) {
                if (numberOfAgreedAndVerifiedQuizInformations >= minNumberOfAgreedAndVerifiedQuizInformations) {
                    projectMemberToSave.memberAccess = MemberAccess.REQUESTING_MEMBER_ACCESS;
                    projectMemberToSave.failedSafetyInformationQuiz = false;
                }
                else if (project?.maxNumberOfRequestingMemberAccessTries && project.maxNumberOfRequestingMemberAccessTries > 0 && 
                    projectMemberToSave.noRequestingMemberAccessTries >= project.maxNumberOfRequestingMemberAccessTries) {
                    projectMemberToSave.memberAccess = MemberAccess.DENIED_MEMBER_ACCESS;
                    projectMemberToSave.failedSafetyInformationQuiz = true;
                }
            }
            await projectMemberContext.mutateProjectMember(projectMemberToSave);
        }
    }

    const saveInformationVerification = async (): Promise<InformationVerification | undefined> => {
        const activeInfo = getActiveInformation()
        if (!!activeInfo?.id && !!applicationProfile) {

            const existingInformationVerification = informationVerifications.find(infoVerification => infoVerification.informationId === activeInfo.id && infoVerification.applicationProfileId === applicationProfile.id)

            let informationVerificationToSave: InformationVerification = {}
            if (existingInformationVerification) {
                informationVerificationToSave = {
                    ...existingInformationVerification,
                    verified: areAnswersCorrect,
                    agreed: true,
                    informationApprovals: activeInfo.informationApprovals,
                }

            } else {
                informationVerificationToSave = {
                    projectId: project?.id,
                    informationId: activeInfo.id,
                    applicationProfileId: applicationProfile.id,
                    verified: areAnswersCorrect,
                    agreed: true,
                    informationApprovals: activeInfo.informationApprovals,
                    state: ActiveState.ACTIVE,
                }
            }
            const savedInformationVerificationId = await informationVerificationContext.mutateInformationVerification(informationVerificationToSave);
            if (savedInformationVerificationId) {
                informationVerificationToSave.id = savedInformationVerificationId;
            }
            return informationVerificationToSave;
        }
    }

    const getActiveInformation = (): Information => {
        if (activeStepIndex >= informations.length) {
            return informations[informations.length-1];
        }

        return informations[activeStepIndex];
    }

    const onBack = () => {
        if (activeStepIndex === 0) {
            const route = menuContext.getApplicationRouteById(ApplicationRouteId.Join);
            const formattedRoute = { ...route, route: route.route.replace(':projectId', projectId) }

            updateUrl(formattedRoute);
            return;
        }

        setActiveStepIndex(activeStepIndex - 1)
    }

    const onNext = async () => {
        const newStep = activeStepIndex + 1;
        await onSelectedStep(newStep);
    }

    const onSelectedStep = async (newStep: number) => {
        setIsSaving(true);
        const savedInformationVerification = await saveInformationVerification();
        if (newStep >= informations.length) {
            await saveProjectMemberAsRequestingAccess(savedInformationVerification);
            const route = menuContext.getApplicationRouteById(ApplicationRouteId.Join);
            const formattedRoute = { ...route, route: route.route.replace(':projectId', projectId) }

            updateUrl(formattedRoute);
            return;
        }
        setActiveStepIndex(newStep);
        setIsSaving(false);
    }

    const currentInformation = getActiveInformation()

    const disableFileDownloadedVerification = true;
    const videoUrlExists = (currentInformation?.videoUrl?.trim() ?? '').length > 0;
    const informationFilenameExists = (currentInformation?.informationFilename ?? '').trim().length > 0;
    const isNextButtonDisabled = !project?.id || (informations.length > 0 && !isLoading && !isSaving &&
    ((!informationVerifications.find(infoVerification => infoVerification.informationId === currentInformation?.id)?.verified &&
            ((videoUrlExists && !watchedInformations.find(watchedInfo => watchedInfo.id === currentInformation.id)) || (!areAnswersCorrect && !(currentInformation?.informationApprovalsQuizActivated ?? false)) ||
                (!disableFileDownloadedVerification && informationFilenameExists && !fileDownloadedInformations.find(fileDownloadedInfo => fileDownloadedInfo.id === currentInformation.id))))));

    const BottomNavigation = (
        <>
            <Button variant='contained' onClick={onBack}>{languageContext.getMessage('back')}</Button>
            <Button variant='contained' onClick={async () => await onNext()} disabled={isNextButtonDisabled}>{languageContext.getMessage('next')}</Button>
        </>
    )

    const onVideoWatched = () => {
        const currentInformation = getActiveInformation()
        const isAlreadyInArray = watchedInformations.find(watchedInfo => watchedInfo.id === currentInformation.id)

        if (!isAlreadyInArray) {
            setWatchedInformations([
                ...watchedInformations,
                currentInformation
            ])
        }
    }

    const onFileWatched = () => {
        const currentInformation = getActiveInformation()
        const isAlreadyInArray = fileDownloadedInformations.find(fileDownloadedInfo => fileDownloadedInfo.id === currentInformation.id)

        if (!isAlreadyInArray) {
            setFileDownloadedInformations([
                ...fileDownloadedInformations,
                currentInformation
            ])
        }
    }

    
    const CustomStepIcon: FC<any> = (props) => {
        const { active, completed } = props;
    
        return (
        <StepIcon
            {...props}
            completed={completed}
            active={active}
            icon={
                completed && active ? (
                    <Avatar sx={{ width: 24, height: 24, bgcolor: green[900], fontSize: 12}}>{props.icon}</Avatar> 
                ) : completed ? (
                    <Avatar sx={{ width: 24, height: 24, bgcolor: green[500], fontSize: 12}}>{props.icon}</Avatar> 
                ) : props.icon
            }
        />
        );
    }

    return (
        <PageContentLayout bottomNavigation={(isLoading || isSaving) ? null : BottomNavigation}>
            {
                (isLoading || isSaving) ? <Grid container justifyContent='center'><CircularProgress /></Grid> :
                    (
                        <>
                            <StepperStyled activeStep={activeStepIndex} alternativeLabel sx={{ marginBottom: 2.5 }}>
                                {informations.map((info, index) => {
                                    return (
                                        <Step key={info.id} active={index === activeStepIndex}
                                            completed={isStepCompleted(info, index)}
                                            onClick={async () => {
                                                let lastCompletedStep = getLastCompletedStep();
                                                if (isStepCompleted(info, index) || lastCompletedStep + 1 >= index) {
                                                    await onSelectedStep(index);
                                                }
                                            }}>
                                            <CustomStepLabel StepIconComponent={CustomStepIcon}></CustomStepLabel>
                                        </Step>
                                    )
                                })}
                            </StepperStyled>

                            {informations.length ? (
                                <SafetyInformation
                                    key={getActiveInformation()?.id}
                                    information={getActiveInformation()}
                                    onVideoWatched={onVideoWatched}
                                    onFileWatched={onFileWatched}
                                    informationVerification={informationVerifications.find(infoVerification => infoVerification.informationId === getActiveInformation()?.id && infoVerification.applicationProfileId === applicationProfile?.id)}
                                    onInformationApproval={(areCorrect) => {
                                        setAreAnswersCorrect(areCorrect)
                                    }} />
                            ) : (
                                <Typography variant="h5">{languageContext.getMessage('noSafetyInformationAvailable')}</Typography>
                            )}
                        </>
                    )
            }
        </PageContentLayout>
    )
}

export default JoinSafetyInformationView

const StepperStyled = styled(Stepper)`
    overflow: auto;
    padding-bottom: 0.5rem;

`

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
    [`.${stepLabelClasses.active}`]: {
        color: `${theme.palette.primary.dark} !important`,
    },
    [`.${stepLabelClasses.completed}`]: {
        fill: `${theme.palette.success.light} !important`
    }
}))