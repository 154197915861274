import React, { createContext, useContext, useEffect, useState } from "react";
import { Ticket } from "../../../contracts/contracts";
import { useApolloClient, Observable } from '@apollo/react-hooks';
import { getTicketsGraphqlSubscriptionOptions } from "./ticketSubscriptions";
import { useAuthContext } from "../../auth/authContext";
import { FetchResult } from "apollo-boost";
import { useBrowserContext } from "../../browserContext/browserContext";

export interface TicketSubscriptionsContext {
    subscribedTickets: Array<Ticket>,
    subscribeTicket: (ticket: Ticket) => Observable<FetchResult<any, Record<string, any>, Record<string, any>>>,
}

const TicketSubscriptionsContext = createContext<TicketSubscriptionsContext>(null as unknown as TicketSubscriptionsContext);

export const TicketSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const browserContext = useBrowserContext();
    const authContext = useAuthContext();
    const apolloClient = useApolloClient();
    const [cachedSubscription, setCachedSubscription] = useState<any>({ticketSubscription: []});
    const [subscribedTickets, setSubscribedTickets] = useState<Array<Ticket>>([]);
    const [subscription, setSubscription] = useState<ZenObservable.Subscription | undefined>(undefined);
    const [createNewSubscriptionAccessToken, setCreateNewSubscriptionAccessToken] = useState<string>("");

    const subscribeTicket = (ticket: Ticket, accessToken: string | undefined = undefined): Observable<FetchResult<any, Record<string, any>, Record<string, any>>> => {
        accessToken = accessToken ?? authContext.jwtAccessToken();
        const subscriptionOptions = getTicketsGraphqlSubscriptionOptions(accessToken ?? "", ticket, browserContext.getTenantName());
        const observer = apolloClient.subscribe(subscriptionOptions);
        return observer;
    }

    const setupSubscription = (accessToken: string): void => {
        const updatedSubscription = subscribeTicket({}, accessToken).subscribe(({ data }) => {
            if (data && data.ticketSubscription) {
                cachedSubscription.ticketSubscription = cachedSubscription.ticketSubscription.concat(data.ticketSubscription as Array<Ticket>);
            }
        })
        if (subscription && !subscription.closed) {
            subscription.unsubscribe();
        }
        setSubscription(updatedSubscription);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (cachedSubscription.ticketSubscription.length > 0) {
                const tickets = cachedSubscription.ticketSubscription.slice()
                cachedSubscription.ticketSubscription = [];
                setSubscribedTickets(tickets);
            }
        }, 100);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            if (subscription && !subscription.closed) {
                subscription.unsubscribe();
            }
            setSubscribedTickets([]);
        }
        if (authContext.insecure) {
            setCreateNewSubscriptionAccessToken("dummy_token");
        }
    }, [authContext.authenticated, authContext.insecure]);

    useEffect(() => {
        authContext.onTokenRefreshSubject.subscribe(accessToken => {
            setCreateNewSubscriptionAccessToken(accessToken);
        });
    }, [])

    useEffect(() => {
        if (!authContext.insecure && createNewSubscriptionAccessToken.length === 0) {
            return;
        }
        setupSubscription(createNewSubscriptionAccessToken);
    }, [createNewSubscriptionAccessToken])

    const ticketContext: TicketSubscriptionsContext = {
        subscribedTickets,
        subscribeTicket,
    };

    return (
        <TicketSubscriptionsContext.Provider value={ticketContext}>
            {children}
        </TicketSubscriptionsContext.Provider>
    );
}

export const useTicketSubscriptionsContext = (): TicketSubscriptionsContext => {
    return useContext(TicketSubscriptionsContext);
}
