import React, { useRef, useState } from 'react';
import { ProjectMember, MemberAccess, ActiveState } from '../../contracts/contracts';
import { Link, SpeedDial, SpeedDialAction, TextField, styled } from "@mui/material";
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import ProjectMemberTeaser from "./projectMemberTeaser";
import { useApplicationProfileContext } from '../../contexts/applicationProfile/applicationProfileContext';
import ConfirmDeleteDialog from '../confirmComponents/ConfirmDeleteDialog';
import { useLanguageContext } from '../../contexts/language/LanguageContext';

import EditIcon from '@mui/icons-material/Edit';
import DeclineIcon from '@mui/icons-material/Close';
import ApproveIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { useProjectContext } from '../../contexts/project/projectContext';
import SaveDialog from '../generalComponents/SaveDialog';
import { navigateToExternalUrl } from '../../utils/urlTools';

type props = {
	projectMember?: ProjectMember,
	onTeaserClick?: (projectMember?: ProjectMember) => void;
}

const ProjectMemberPendingList: React.FC<props> = ({ projectMember, onTeaserClick }) => {
	const projectContext = useProjectContext();
	const projectMemberContext = useProjectMemberContext();
	const applicationProfileContext = useApplicationProfileContext();
	const languageContext = useLanguageContext();
	const applicationProfileForLoggedInUser = applicationProfileContext.getApplicationProfileForLoggedInUser();
	const [openEditProjectMemberNotesDialog, setOpenEditProjectMemberNotesDialog] = useState<boolean>(false);

	const [editedProjectMember, setEditedProjectMember] = useState<ProjectMember>({})

	const project = projectContext.getProject(projectMember?.projectId);

	const toggleCheckedInStatus = async () => {
		if (!projectMember?.id) {
			return;
		}
		projectMember.isCheckedIn = !projectMember.isCheckedIn;
		await projectMemberContext.mutateProjectMember(projectMember);
	}

	const changeMemberAccess = async (accessGranted: boolean, deleteMemberAccess?: boolean): Promise<void> => {
		if (!projectMember) {
			return;
		}
		if (deleteMemberAccess) {
			projectMember.state = ActiveState.INACTIVE;
			accessGranted = false;
		}
		projectMember.memberAccess = accessGranted ? MemberAccess.MEMBER : MemberAccess.DENIED_MEMBER_ACCESS;
		await projectMemberContext.mutateProjectMember(projectMember);
	}

	const showDeleteAccessButton = projectMember?.applicationProfileId !== applicationProfileForLoggedInUser?.id;
	const showGrantAccessButton = projectMember?.memberAccess !== MemberAccess.MEMBER && projectMember?.applicationProfileId !== applicationProfileForLoggedInUser?.id;
	const showDenyAccessButton = projectMember?.memberAccess !== MemberAccess.DENIED_MEMBER_ACCESS && projectMember?.applicationProfileId !== applicationProfileForLoggedInUser?.id;
	const showCheckInOutButton = projectMember?.memberAccess === MemberAccess.MEMBER && (project?.enableCheckIn ?? false);

	const showActionsButton = showDeleteAccessButton || showGrantAccessButton || showDenyAccessButton

	const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
	const handleOnClickDelete = () => {
		setOpenConfirmDeleteDialog(true);
	};

	const actions: { icon: React.ReactNode, name: string, isVisible: boolean, action: () => Promise<void> }[] = [
		{
			icon: <StickyNote2Icon />,
			name: languageContext.getMessage('notes'),
			isVisible: true,
			action: async () => {
				setOpenEditProjectMemberNotesDialog(true);
				setEditedProjectMember({ ...projectMember });
			},
		},
		{
			icon: projectMember?.isCheckedIn ? <LogoutIcon /> : <LoginIcon />,
			name: projectMember?.isCheckedIn ? languageContext.getMessage('checkOut') : languageContext.getMessage('checkIn'),
			isVisible: showCheckInOutButton,
			action: async () => await toggleCheckedInStatus(),
		},
		{
			icon: <DeclineIcon />,
			name: languageContext.getMessage('deny'),
			isVisible: showDenyAccessButton,
			action: async () => await changeMemberAccess(false),
		},
		{
			icon: <ApproveIcon />,
			name: languageContext.getMessage('approve'),
			isVisible: showGrantAccessButton,
			action: async () => await changeMemberAccess(true),
		},
		{
			icon: <DeleteIcon />,
			name: languageContext.getMessage('delete'),
			isVisible: showDeleteAccessButton,
			action: async () => handleOnClickDelete(),
		}
	]


	const handleOnCloseConfirmDeleteDialog = async (deleteIsConfirmed: boolean) => {
		if (deleteIsConfirmed) {
			changeMemberAccess(false, true);
		}
		setOpenConfirmDeleteDialog(false);
	}

	return (
		<>
			<SaveDialog
				title={languageContext.getMessage("notes")}
				open={openEditProjectMemberNotesDialog}
				onClose={() => {
					setOpenEditProjectMemberNotesDialog(false)
					setEditedProjectMember({});
				}}
				onSave={async () => {
					await projectMemberContext.mutateProjectMember(editedProjectMember);
					setOpenEditProjectMemberNotesDialog(false);
				}}
			>
				<TextField
					multiline
					minRows={4}
					maxRows={20}
					helperText={
						<Link onClick={() => {
							navigateToExternalUrl('https://www.markdownguide.org/basic-syntax/');
						}}>
							{languageContext.getMessage('markdownSupported')}
						</Link>}
					variant="standard"
					value={editedProjectMember.notes ?? ''}
					onChange={(event) => {
						setEditedProjectMember({ ...editedProjectMember, notes: event.target.value });
					}}
					fullWidth
				/>
			</SaveDialog>
			<ProjectMemberTeaser
				projectMember={projectMember}
				onTeaserClick={onTeaserClick}
				secondaryAction={
					showActionsButton && (
						<SpeedDialStyled
							ariaLabel="Edit member permission"
							sx={{ position: 'absolute', bottom: -28, right: 0 }}
							icon={<EditIcon />}
							direction='left'
						>
							{actions.map(({ action, name, icon, isVisible }, index) => isVisible && (
								<SpeedDialAction
									key={`${action}-${index}`}
									icon={icon}
									tooltipTitle={name}
									onClick={async () => await action()}
								/>
							))}
						</SpeedDialStyled>
					)
				}
			/>
			<ConfirmDeleteDialog
				title={languageContext.getMessage('doYouWantToDeleteProjectMember')}
				open={openConfirmDeleteDialog}
				onClose={handleOnCloseConfirmDeleteDialog}
			/>
		</>
	)
}

export default ProjectMemberPendingList;

const SpeedDialStyled = styled(SpeedDial)(({ theme }) => ({
	'.MuiSpeedDial-actions': {
		paddingRight: '36px'
	}
}));