import React, { createContext, useContext, useEffect, useState } from "react";
import { Analytics, DocumentType, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface AnalyticsSubscriptionsContext {
    subscribedAnalytics: Array<Analytics>,
}

const AnalyticsSubscriptionsContext = createContext<AnalyticsSubscriptionsContext>(null as unknown as AnalyticsSubscriptionsContext);

export const AnalyticsSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedAnalytics, setSubscribedAnalytics] = useState<Array<Analytics>>([]);

    const updateSubscribedAnalytics = (stateDocuments: StateDocument[]) => {
        const subscribedAnalytics = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.ANALYTICS && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as Analytics);
        setSubscribedAnalytics(subscribedAnalytics);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedAnalytics([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedAnalytics(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const projectContext: AnalyticsSubscriptionsContext = {
        subscribedAnalytics,
    };

    return (
        <AnalyticsSubscriptionsContext.Provider value={projectContext}>
            {children}
        </AnalyticsSubscriptionsContext.Provider>
    );
}

export const useAnalyticsSubscriptionsContext = (): AnalyticsSubscriptionsContext => {
    return useContext(AnalyticsSubscriptionsContext);
}
