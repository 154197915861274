import { ListItem, IconButton, ListItemText, ButtonGroup, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction, useEffect } from "react";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import React from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_STORAGE_FILES, getStorageFilesGraphqlQueryOptions } from "../../contexts/storageFile/queries/storageFileQueries";
import { StorageFile } from "../../contracts/contracts";
import { downloadBase64File } from "../../utils/fileTools";
import { Delete } from "@mui/icons-material";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { useStorageFileMutationsContext } from "../../contexts/storageFile/mutations/storageFileMutationsContext";
import { SnackbarComponent } from "../toastComponent/SnackbarComponent";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { stringFormat } from "../../utils/stringTools";

type props = {
    readonly file: StorageFile;
    readonly loadStorageFile: () => void;
    readonly setIsSuccessDeleted: Dispatch<SetStateAction<boolean>>;
}
const ProjectMemberAttachedFile: FC<props> = ({ file, loadStorageFile, setIsSuccessDeleted }) => {
    const languageContext = useLanguageContext();
    const storageFilesMutatuinContext = useStorageFileMutationsContext();
    const [loadInformationFileQuery, queryInformationFileResponse] = useLazyQuery(QUERY_STORAGE_FILES);
    const [fileToDownload, setFileToDownload] = React.useState<StorageFile[]>();

    const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = React.useState<boolean>(false);


    useEffect(() => {
        loadInformationFileQuery(getStorageFilesGraphqlQueryOptions({ key: file.key }));
    }, [])

    useEffect(() => {
        const storageFiles: StorageFile[] = queryInformationFileResponse.data?.storageFiles ?? [];
        setFileToDownload(storageFiles);
    }, [queryInformationFileResponse.data]);

    const onFileDonwloadHandler = (file?: StorageFile) => {
        if (file) {
            downloadBase64File(undefined, file.content as string, file.metaData as string);
        }
    }

    const onFileDeleteHandler = async (file?: StorageFile) => {
        if (file) {
            await storageFilesMutatuinContext.mutateStorageFile({ id: file.id, delete: true });
            loadStorageFile();
            setIsSuccessDeleted(true);
        }
    }

    const onDeleteButtonClick = async () => {
        setIsDeleteConfirmDialogOpen(true);
    }

    return (
        <ListItem secondaryAction={
            <ButtonGroup>
                <IconButton disabled={!fileToDownload?.length} edge="end" aria-label="delete" onClick={() => onFileDonwloadHandler(fileToDownload?.length ? fileToDownload[0] : undefined)}>
                    <FileDownloadIcon />
                </IconButton>
                <IconButton disabled={!fileToDownload?.length} edge="end" aria-label="delete" onClick={() => onDeleteButtonClick()}>
                    <Delete />
                </IconButton>
            </ButtonGroup>
        }>
            <ConfirmationDialog
                open={isDeleteConfirmDialogOpen}
                onClose={async () => setIsDeleteConfirmDialogOpen(false)}
                onConfirm={async () => await onFileDeleteHandler(fileToDownload?.length ? fileToDownload[0] : undefined)}
                title={languageContext.getMessage('deleteAttachedFile')}
                description={`${stringFormat(languageContext.getMessage('areYouSureYouWantToDeleteFile'), [file.metaData ?? ''])}`}
            />
            <AttachFileIcon />
            <ListItemText
              primary={<Typography variant='inherit' noWrap>{file.metaData}</Typography>}
            />
        </ListItem>
    );
}

export default ProjectMemberAttachedFile;
