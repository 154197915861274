import React, { useCallback, useEffect, useMemo, useState } from "react";

// Material UI staff
import {
	Autocomplete,
	Box,
	CircularProgress,
	Grid,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography
} from "@mui/material";
import { withTheme } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DesignServicesIcon from "@mui/icons-material/DesignServices";

// Contexts
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { ProjectTabs, useProjectContext } from "../../contexts/project/projectContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";

// types
import { ActiveState, DocumentType, NewsFeedAccessLevel, Project, ProjectMember, MemberAccess, RoleType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { Guid } from "../../utils/common-types";

// Views
import EditProject from "../../component/projectComponents/EditProject";
import { ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import ProjectDetailsView from "./ProjectDetailsView";
import ProjectsImageList from "../../component/projectComponents/ProjectsImageList";
import { useNewsFeedContext } from "../../contexts/newsFeed/newsFeedContext";
import FabButton from "../../component/generalComponents/FabButton";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { useApplicationProfileContext } from "../../contexts/applicationProfile/applicationProfileContext";
import ConfirmDeleteDialog from "../../component/confirmComponents/ConfirmDeleteDialog";
import { useAuthContext } from "../../contexts/auth/authContext";
import CheckedInOutDialog from "../../component/generalComponents/CheckedInOutDialog";

enum AssignedProjectsType {
	MY_PROJECTS = "MY_PROJECTS",
	ALL_PROJECTS = "ALL_PROJECTS"
}

const ProjectsView: React.FC<{}> = () => {
	const urlContext = useUrlContext();
	const menuContext = useMenuContext();
	const languageContext = useLanguageContext();

	const ticketContext = useTicketContext();
	const projectContext = useProjectContext();
	const newsFeedContext = useNewsFeedContext();
	const projectMemberContext = useProjectMemberContext();
	const applicationProfileContext = useApplicationProfileContext();
	const authContext = useAuthContext();

	const applicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser()
	const projectSearch = projectContext.getProjectSearch();

	const projects = projectContext.getProjects();

	const projectLoading = projectContext.loadingProjects;

	const urlState = urlContext.getUrlState();
	const tabIndex = urlState.tab ? urlState.tab as string : ProjectTabs.all;
	const selectedProjectId = urlState.projectId ? urlState.projectId as Guid : null;
	const assignedProjectType = urlState.assignedProjectType ? urlState.assignedProjectType as AssignedProjectsType : AssignedProjectsType.ALL_PROJECTS;
	const selectedProject = projectContext.getProject(selectedProjectId ?? '');
	const [search, setSearch] = React.useState<string | Project | null>(null);
	const [openNewProject, setOpenNewProject] = React.useState(false);
	const [newProject, setNewProject] = React.useState<Project>({});
	const [checkedInStatusHandled, setCheckedInStatusHandled] = React.useState<boolean>(false);
	const [openCheckInOutDialog, setOpenCheckInOutDialog] = useState<boolean>(false);


	const newsFeeds = newsFeedContext.getNewsFeeds();
	const projectMembersForLoggedInUser = projectMemberContext.getProjectMembersForLoggedInUser()

	const projectMemberForSelectedProject = projectMembersForLoggedInUser.find(projectMember => projectMember.projectId === selectedProject?.id);

	const projectsWithMemberShip = projects.filter(project => projectMembersForLoggedInUser.find(projectMember => projectMember.projectId === project.id));
	const projectsWithoutMemberShip = projects.filter(project => !projectMembersForLoggedInUser.find(projectMember => projectMember.projectId === project.id));
	const assignedProjects = assignedProjectType === AssignedProjectsType.MY_PROJECTS
		? projectsWithMemberShip
		: projectsWithMemberShip.concat(projectsWithoutMemberShip);

	const filteredProjects = useMemo(() => {

		if (!search) return assignedProjects;
		if (typeof search === "string") return assignedProjects.filter(project => projectContext.getProjectTitleWithProjectState(project).toLowerCase().includes(search.toLowerCase()));
		if (typeof search === "object") return assignedProjects.filter(project => project.id === search.id);
		return [];
	}, [projectContext.getProjects(), search, projectMembersForLoggedInUser, assignedProjectType]);

	useEffect(() => {
		ticketContext.setDocumentTypesToWatch([DocumentType.PROJECT, DocumentType.NEWS_FEED]);

		projectContext.searchProjects(projectSearch);
	}, [urlContext.currentLocation]);

	const updateUrlQuery = (newUrlState: Dictionary<string | number | Date>, locationOverride?: string) => {
		const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date>);
		urlContext.pushUrlQuery(urlQuery, locationOverride);
	}

	const onSelectedProjectDetailsChange = (project: Project | undefined) => {
		const newUrlState = {
			...urlState,
			...projectSearch,
			...{ 'projectId': project?.id },
			...{ 'tab': ProjectTabs.details }
		}
		updateUrlQuery(newUrlState)
	}

	const onAssignedProjectTypeChange = (e: any, newAssignedProjectType: AssignedProjectsType) => {
		const newUrlState = {
			...urlState,
			...projectSearch,
			...{ assignedProjectType: newAssignedProjectType },
		}
		updateUrlQuery(newUrlState)
	}

	const onJoinProject = useCallback(async (project: Project | undefined) => {
		if (project?.id) {
			if (projectMemberForSelectedProject) {
				const projectMemberToSave: ProjectMember = {
					...projectMemberForSelectedProject,
					projectId: project.id,
					state: ActiveState.ACTIVE,
					memberAccess: projectMemberForSelectedProject.memberAccess ?? MemberAccess.PUBLIC,
				}
				await projectMemberContext.mutateProjectMember(projectMemberToSave);
			}
			const route = menuContext.getApplicationRouteById(ApplicationRouteId.Join);
			const formattedRoute = { ...route, route: route.route.replace(':projectId', project.id) }
			updateUrlQuery({ ...urlState }, formattedRoute.route);
		}
	}, [urlState]);

	const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
	const handleOnCloseConfirmDeleteDialog = async (deleteIsConfirmed: boolean) => {
		if (deleteIsConfirmed) {
			const mutatedProjectMember = {
				...projectMemberForSelectedProject,
				state: ActiveState.INACTIVE
			}
			await projectMemberContext.mutateProjectMember(mutatedProjectMember);
		}
		setOpenConfirmDeleteDialog(false);
	}

	const onFollowProject = useCallback(async (project: Project | undefined, deleteProjectMemberAccess?: boolean) => {
		deleteProjectMemberAccess = deleteProjectMemberAccess ?? false;
		if (applicationProfile?.id && project?.id) {

			let mutatedProjectMember: ProjectMember
			if (projectMemberForSelectedProject) {
				mutatedProjectMember = {
					...projectMemberForSelectedProject,
					state: ActiveState.INACTIVE
				}
			} else {
				mutatedProjectMember = {
					applicationProfileId: applicationProfile.id,
					projectId: project.id,
					state: ActiveState.ACTIVE,
					memberAccess: MemberAccess.PUBLIC,
				}
			}
			if (mutatedProjectMember.memberAccess === MemberAccess.PUBLIC || deleteProjectMemberAccess) {
				await projectMemberContext.mutateProjectMember(mutatedProjectMember);
			}
			else {
				setOpenConfirmDeleteDialog(true);
			}
		}
	}, [urlState]);

	const onToggleCheckinProject = useCallback(async (project: Project | undefined, checkedInStatusOverride?: boolean | undefined, openCheckInOutDialogAfterMutation?: boolean | undefined) => {
		if (applicationProfile?.id && project?.id && projectMemberForSelectedProject?.id && projectMemberForSelectedProject.memberAccess === MemberAccess.MEMBER) {
			const checkedInStatus = checkedInStatusOverride ?? !(projectMemberForSelectedProject.isCheckedIn ?? false);
			projectMemberForSelectedProject.isCheckedIn = checkedInStatus;
			await projectMemberContext.mutateProjectMember(projectMemberForSelectedProject);
			if (openCheckInOutDialogAfterMutation) {
				setOpenCheckInOutDialog(true);
			}
		}
	}, [urlState]);

	useEffect(() => {
		if (selectedProject && projectMemberForSelectedProject) {
			newsFeedContext.searchNewsFeeds({
				...newsFeedContext.getNewsFeedSearch(),
				projectId: selectedProject.id,
				accessLevel: projectMemberForSelectedProject.memberAccess !== MemberAccess.MEMBER ? NewsFeedAccessLevel.PUBLIC : undefined,
			});
			if (!checkedInStatusHandled) {
				setCheckedInStatusHandled(true);
				const rawCheckedInStatus = urlState.checkedInStatus ? urlState.checkedInStatus as string | undefined : undefined;
				if (rawCheckedInStatus !== undefined) {
					const checkedInStatus = rawCheckedInStatus.toLowerCase().trim() === 'true';
					onToggleCheckinProject(selectedProject, checkedInStatus, true);
					urlContext.replaceUrlQuery(urlContext.buildUrlQuery({ ...urlState, checkedInStatus: undefined }));
				}
			}
		}
	}, [selectedProject, projectMemberForSelectedProject]);

	const currentUserHasActivationPrivileges = authContext.accountRoles().findIndex(role => role === RoleType.OWNER) >= 0;
	const hasWriteAccessToSelectedProject = selectedProject?.id && (projectMemberContext.hasProjectAccess(RoleType.WRITER, selectedProject.id) || currentUserHasActivationPrivileges);
	return <>
		<CheckedInOutDialog
			open={openCheckInOutDialog}
			onClose={() => setOpenCheckInOutDialog(false)}
			isCheckedIn={projectMemberForSelectedProject?.isCheckedIn} />
		<ConfirmDeleteDialog
			title={languageContext.getMessage('doYouWantToDeleteProjectMember')}
			open={openConfirmDeleteDialog}
			onClose={handleOnCloseConfirmDeleteDialog}
		/>
		<EditProject project={tabIndex === ProjectTabs.all ? newProject : selectedProject} open={openNewProject} onClose={() => setOpenNewProject(false)} />
		{applicationProfile?.id && (tabIndex === ProjectTabs.all || hasWriteAccessToSelectedProject) &&
			<FabButton
				onClick={() => { setOpenNewProject(true); setNewProject({}) }}
				icon={tabIndex === ProjectTabs.all ? <AddIcon /> : <EditIcon />}
				label={languageContext.getMessage("add")}
			/>
		}
		<Grid container spacing={1} flexWrap='nowrap' flexDirection='column'>
			{tabIndex === ProjectTabs.all &&
				<Grid item xs={12}>
					<Autocomplete
						sx={{ mb: 2 }}
						freeSolo
						onInputChange={(e, value) => setSearch(value)}
						onChange={(e, value) => setSearch(value)}
						getOptionLabel={project => {
							if (typeof project === 'string') {
								return project;
							}
							return projectContext.getProjectTitleWithProjectState(project);
						}}
						options={assignedProjects}
						renderInput={(params) => <TextField {...params} label={languageContext.getMessage("search")} />}
					/>
					<Grid item xs={12} container sx={{ my: 1 }}>
						<ToggleButtonGroup
							color="primary"
							value={assignedProjectType}
							exclusive
							onChange={onAssignedProjectTypeChange}
							fullWidth
							sx={{ mb: 1 }}
						>
							<ToggleButton value={AssignedProjectsType.MY_PROJECTS}>{languageContext.getMessage('myProjects')}</ToggleButton>
							<ToggleButton value={AssignedProjectsType.ALL_PROJECTS}>{languageContext.getMessage('allProjects')}</ToggleButton>
						</ToggleButtonGroup>

					</Grid>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<DesignServicesIcon />
						<Typography sx={{ ml: 1 }} variant="h5">{languageContext.getMessage('projects')}</Typography>
					</Box>
					{/* <Typography gutterBottom variant="h5">{languageContext.getMessage('projects')}</Typography> */}
					{projectLoading ? <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 56 }}><CircularProgress /></Grid> : (
						<ProjectsImageList
							projects={filteredProjects}
							onProjectDetails={onSelectedProjectDetailsChange}
							onJoinProject={onJoinProject}
						/>
					)}
				</Grid>
			}
			{tabIndex === ProjectTabs.details &&
				<Grid item xs={12}>
					<ProjectDetailsView
						project={selectedProject}
						projectMember={projectMemberForSelectedProject}
						onJoinProject={onJoinProject}
						onFollowProject={onFollowProject}
						onToggleCheckinProject={onToggleCheckinProject}
						newsFeeds={newsFeeds} />
				</Grid>
			}
		</Grid>
	</>
}

export default withTheme(ProjectsView);
