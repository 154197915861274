import { Button, Divider, Grid, Paper, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { KPIEnum } from '../../contracts/contracts';
import StatCard from '../generalComponents/StatCard';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useProjectContext } from '../../contexts/project/projectContext';
import { useAnalyticsContext } from '../../contexts/analytics/analyticsContext';
import { useWidthContext } from '../../contexts/WidthContext';
import ProjectMap from '../mapComponents/ProjectMap';
import { useSettingsContext } from '../../contexts/settings/settingsContext';
import ShowVimeoVideo from '../videoComponents/ShowVimeoVideo';
import { displayPercentage } from '../percentageComponents/PercentageFormat';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  paper: {
    padding: theme.spacing(2)
  },
  graphTitle: {
    fontSize: 32
  },
  divider: {
    marginBottom: '1em'
  },
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  listMark: {
    float: 'right',
    background: '#31d655CC',
    width: '10px',
    height: '100px'
  }
}));

type props = {
}

const Dashboard: React.FC<props> = ({ }) => {

  const classes = useStyles();
  const widtContext = useWidthContext();
  const languageContext = useLanguageContext();
  const analyticsContext = useAnalyticsContext();
  const projectContext = useProjectContext();
  const settingsContext = useSettingsContext();

  const analytics = analyticsContext.getAnalytics()

  const [videoUrl, setVideoUrl] = useState<string>('https://vimeo.com/269149571');
  const [pauseVideo, setPauseVideo] = useState<boolean>(false);
  const [videoFinished, setVideoFinished] = useState<boolean>(false);
  const [elapsedPercentVideo, setElapsedPercentVideo] = useState<number>(0);

  return (<>
    <Grid container direction="row" spacing={3}>

      <Grid item xs={12} lg={3}>
        <StatCard kpi={KPIEnum.OFFHIRE_FLAG} color='#ff9999' head='offHire' content=' Crew Units' subContent='about' data={7} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <StatCard kpi={KPIEnum.AVERAGE_FLEET_COST} color='#ffca89' head='averageProjectCost' content={settingsContext.defaultMainCurrencyCode} subContent='about' data={analytics.averageProjectCost ?? 0.0} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <StatCard kpi={KPIEnum.TURNTIMES} color='#bdf2a7' head='turntime' content='Full Crew' subContent='about' data={analytics.averageProjectCost ?? 0.0} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <StatCard kpi={KPIEnum.TRIANGULATION} color='#7bead6' head='triangulation' content='Something' subContent='about' data={'X'} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper} elevation={2} >
          <Grid item xs={12}>
            <ShowVimeoVideo
              video={videoUrl}
              autoplay={true}
              controls={false}
              showByline={false}
              paused={pauseVideo}
              start={3}
              onPlay={() => setPauseVideo(false)}
              onPause={() => setPauseVideo(true)}
              onTimeUpdate={event => setElapsedPercentVideo(event.percent)}
              onEnd={() => setVideoFinished(true)}
              onLoaded={() => setPauseVideo(false)} />
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={`Video id or url - Elapsed: ${displayPercentage(elapsedPercentVideo)} - Finished: ${videoFinished}`}
                variant="standard"
                value={videoUrl}
                onChange={event => setVideoUrl(event.target.value as string)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => setPauseVideo(!pauseVideo)} color="primary" variant="contained">
                {pauseVideo ? 'start' : 'pause'}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper} elevation={2} >
          <Typography gutterBottom variant="h5">{languageContext.getMessage('projects')}</Typography>
          <Divider className={classes.divider} />
          <ProjectMap projects={projectContext.getProjects()}></ProjectMap>
        </Paper>
      </Grid>

    </Grid>
  </>);
}

export default Dashboard