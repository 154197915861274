import React from "react";
import { CircularProgress, Grid, List, Pagination, Stack, Typography } from "@mui/material";
import { ProjectMember, ProjectMemberCheckedInEvent } from "../../contracts/contracts";
import ProjectMemberPendingList from "../../component/projectMemberComponents/projectMemberPendingList";
import ProjectMemberTeaser from "./projectMemberTeaser";
import { isAfter, isBefore } from "date-fns";
import { useLanguageContext } from "../../contexts/language/LanguageContext";

type props = {
	projectMembers: ProjectMember[];
	showProjectMemberCheckedInEvents?: boolean;
	projectMemberCheckedInEvents?: ProjectMemberCheckedInEvent[];
	loadingProjectMemberCheckedInEvents?: boolean;
	onTeaseClick?: (projectMember?: ProjectMember) => void;
	listTitle?: string;
	withAction?: boolean;
	shrinked?: boolean;
	membersPerPage?: number;
	startDate?: Date;
	endDate?: Date;
}

const ProjectMemberList: React.FC<props> = ({
	projectMembers,
	showProjectMemberCheckedInEvents,
	projectMemberCheckedInEvents,
	loadingProjectMemberCheckedInEvents,
	onTeaseClick,
	listTitle,
	withAction,
	shrinked,
	membersPerPage,
	startDate,
	endDate }) => {
	const ProjectListComponent = withAction ? ProjectMemberPendingList : ProjectMemberTeaser;
	membersPerPage = membersPerPage ?? 20;

	const languageContext = useLanguageContext();

	const [filteredProjectMembers, setFilteredProjectMembers] = React.useState<ProjectMember[]>([]);

	const overflowProps = shrinked ? {
		maxHeight: 300,
		overflow: 'auto',
	} : {}

	React.useEffect(() => {
		if (showProjectMemberCheckedInEvents) {
			if (loadingProjectMemberCheckedInEvents) {
				setFilteredProjectMembers([]);
			}
			else if (projectMemberCheckedInEvents !== undefined) {
				setFilteredProjectMembers(projectMembers.filter(projectMember => {
					projectMember.projectMemberCheckedInEvent = projectMemberCheckedInEvents.find(event => event.projectMemberId === projectMember.id);
					return projectMember.projectMemberCheckedInEvent !== undefined;
				}));
			}
			return;
		}
		if (startDate && !endDate) setFilteredProjectMembers(projectMembers.filter(member => isAfter(member.created as Date, startDate.setHours(0, 0, 0, 0))));
		if (!startDate && endDate) setFilteredProjectMembers(projectMembers.filter(member => isBefore(member.created as Date, endDate.setHours(23, 59, 59, 59))));
		if (startDate && endDate) setFilteredProjectMembers(projectMembers.filter(member => isAfter(member.created as Date, startDate.setHours(0, 0, 0, 0)) && isBefore(member.created as Date, endDate.setHours(23, 59, 59, 59))));
	}, [startDate, endDate, projectMembers, projectMemberCheckedInEvents, loadingProjectMemberCheckedInEvents])

	const [page, setPage] = React.useState(1);
	const maxPages = Math.ceil(projectMembers.length / membersPerPage);

	if (page > maxPages && maxPages > 0) {
		setPage(maxPages);
	}
	else if (page <= 0) {
		setPage(1);
	}

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
	};

	const useFilteredProjectMembers = startDate !== undefined || endDate !== undefined || projectMemberCheckedInEvents !== undefined;
	const filteredOrAllProjectMembers = useFilteredProjectMembers ? filteredProjectMembers : projectMembers;
	const pagedProjectMembers = page >= 1 ? filteredOrAllProjectMembers.slice((page - 1) * membersPerPage, ((page - 1) * membersPerPage) + membersPerPage) : [];

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Stack spacing={2}
					justifyContent="center"
					alignItems="center">
					{listTitle && (
						<Typography textAlign="center" variant="h5" sx={{ my: 2 }}>{listTitle}</Typography>)}

					<Stack spacing={2} direction="column">
						<Typography textAlign="center" variant="body2">
							<b>
								{showProjectMemberCheckedInEvents && loadingProjectMemberCheckedInEvents ? <CircularProgress size={20} /> : filteredOrAllProjectMembers.length}
							</b> {languageContext.getMessage('membersFound')}
						</Typography>
					</Stack>
					<List sx={{ bgcolor: 'background.paper', width: "100%", ...overflowProps }}>
						{pagedProjectMembers.map((projectMember, index) => (
							<React.Fragment key={projectMember.id}>
								<ProjectListComponent
									projectMember={projectMember}
									projectMemberCheckedInEvent={projectMember.projectMemberCheckedInEvent}
									onTeaserClick={onTeaseClick}
									disablePadding={true} />
							</React.Fragment>
						))}
					</List>
					{(maxPages ?? 0) > 1 &&
						<Pagination
							count={maxPages ?? 0}
							page={page ?? 0}
							onChange={handlePageChange}
							showFirstButton={false}
							showLastButton={false}
							siblingCount={0}
							boundaryCount={1} />}
				</Stack>
			</Grid>
		</Grid>
	);
}

export default ProjectMemberList;
