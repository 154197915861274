import React from 'react'
import { Button, Card, CircularProgress, Fade, Grid, IconButton, Menu, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useBrowserContext } from '../../contexts/browserContext/browserContext';
import { useGraphqlAuthSettingsContext } from '../../contexts/graphql/graphqlAuthSettingsContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import company_logo from '../../data/companyLogo.png'
import login_photo from '../../data/pictures/login_photo.jpg'
import vipps_photo from '../../data/pictures/vipps.svg'
import google_photo from '../../data/pictures/google_sign_in.svg'
import facebook_photo from '../../data/pictures/facebook.svg'
import { useWidthContext } from '../../contexts/WidthContext';
import { getLanguageAlternatives, getLanguageIconFlag } from '../../utils/languageTools';
import { useAuthContext } from '../../contexts/auth/authContext';


const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: '2em',
    position: 'absolute',
    display: 'flex',
    marginLeft: '42%',
    marginTop: '5%',
    opacity: 0.85,
  },
  cardMobile: {
    padding: '2em',
    position: 'absolute',
    display: 'flex',
    marginTop: '20%',
    opacity: 0.85,
    width: '100%',
  },
  button: {
    marginTop: '1em',
  },
  alignItemsAndJustifyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    overflow: 'hidden',
    alignItems: 'center',
  },
  image: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    minWidth: '100%',
    minHeight: '100%',
    width: '100%',
    height: '100%',
    zIndex: -1000,
    overflow: 'hidden',
    objectFit: 'cover',
  }
}));

type props = {
}

const DashboardSelectTenant: React.FC<props> = ({ }) => {

  const classes = useStyles();
  const browserContext = useBrowserContext();
  const languageContext = useLanguageContext();
  const widthContext = useWidthContext();
  const authContext = useAuthContext();
  const graphqlAuthSettingsContext = useGraphqlAuthSettingsContext();

  const handleOnClickOk = (idpHint?: string, register?: boolean): void => {
    browserContext.setTenantName(browserContext.globalTenantName, idpHint, register);
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isSelectAuthTypeOpen, setIsSelectAuthTypeOpen] = React.useState<boolean>(false);

  const open = Boolean(anchorEl);
  const handleClickSelectLanguage = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmailButtonClick = () => {
    setIsSelectAuthTypeOpen(true);
  };

  const handleEmailBackButtonClick = () => {
    setIsSelectAuthTypeOpen(false);
  };

  return (<>
    <div className={classes.container}>
      {(!authContext.authReady && browserContext.isTenantNameAvailable() && graphqlAuthSettingsContext.tenantRealmExists !== false) && <>
        <Stack direction="column" spacing={2}
          className={widthContext.isMobileScreen() ? classes.cardMobile : classes.card}
          justifyContent="center"
          alignItems="center">
          <Stack direction="row" spacing={1}>
            <img src={company_logo} alt={'onsite'} style={{ height: 50 }} />
            <Typography variant="h3" component="h3">OnSite</Typography>
          </Stack>
          <CircularProgress size={40} />
        </Stack>
      </>}

      {((!authContext.authReady && !browserContext.isTenantNameAvailable()) || graphqlAuthSettingsContext.tenantRealmExists === false) && <>
        <Card className={widthContext.isMobileScreen() ? classes.cardMobile : classes.card} elevation={4}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Stack direction="row" spacing={1}>
                <img src={company_logo} alt={'onsite'} style={{ height: 50 }} />
                <Typography variant="h3" component="h3">OnSite</Typography>
              </Stack>
            </Grid>
            { !isSelectAuthTypeOpen ? (
              <>
                <Grid item>
                  <Typography variant="h5" component="h5">{languageContext.getMessage('loginWith')}</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => handleOnClickOk('vipps')}
                    className={classes.button}
                    style={{
                      backgroundColor: '#ff5b24',
                      color: 'white',
                      width: 235,
                      height: 40,
                    }}
                    color="primary" variant="contained">
                    <img src={vipps_photo} alt={'vipps'} />
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={() => handleOnClickOk('facebook')}
                    className={classes.button}
                    style={{
                      backgroundColor: '#3d8cf4',
                      color: 'white',
                      width: 235,
                      height: 40,
                    }}
                    startIcon={<img src={facebook_photo} alt={'facebook'} style={{ height: 40, marginRight: 5 }} />}
                    color="primary" variant="contained">
                    {'Facebook'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={() => handleOnClickOk('google')}
                    className={classes.button}
                    style={{
                      backgroundColor: '#3367d6',
                      color: 'white',
                      width: 235,
                      height: 40,
                    }}
                    startIcon={<img src={google_photo} alt={'google'} style={{ height: 40, marginRight: 5 }} />}
                    color="primary" variant="contained">
                    {'Google'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={() => handleEmailButtonClick()}
                    className={classes.button}
                    style={{
                      width: 235,
                      height: 40,
                    }}
                    color="primary" variant="contained">
                    {languageContext.getMessage('email')}
                  </Button>
                </Grid>
              </>
            ) : (
              <Grid item>
                <Stack marginTop={3}>
                  <Typography variant="h5" component="h5">{`${languageContext.getMessage('newUser')}?`}</Typography>
                  <Button
                    style={{
                      width: 235,
                      height: 40,
                    }}
                    color="primary" variant="contained"
                    onClick={() => handleOnClickOk(undefined, true)}
                  >
                    {languageContext.getMessage('registerHere')}
                  </Button>
                </Stack>
                <Stack marginTop={3}>
                  <Typography variant="h5" component="h5">{`${languageContext.getMessage('existingUser')}?`}</Typography>
                  <Button
                    style={{
                      width: 235,
                      height: 40,
                    }}
                    color="primary" variant="contained"
                    onClick={() => handleOnClickOk()}
                  >
                    {languageContext.getMessage('clickHere')}
                  </Button>
                </Stack>
                <Stack>
                  <Button
                    style={{
                      width: 235,
                      height: 40,
                      marginTop: 50,
                    }}
                    color="secondary" variant="contained"
                    onClick={handleEmailBackButtonClick}
                  >
                    {languageContext.getMessage('back')}
                  </Button>
                </Stack>
              </Grid>
            )}
            <Grid item>
              <IconButton onClick={(event) => handleClickSelectLanguage(event)}>
                {getLanguageIconFlag(languageContext.getLanguage())}
              </IconButton>
            </Grid>
            {graphqlAuthSettingsContext.tenantRealmExists === false && <Grid item>
              <Typography variant="h5" component="h5">{languageContext.getMessage('wrongTenant')}</Typography>
            </Grid>}
          </Grid>
        </Card>
      </>}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {getLanguageAlternatives(async language => {
          languageContext.setLanguage(language);
          handleClose();
        })}
      </Menu>


      <img className={classes.image} src={login_photo} alt={'login'} />
    </div>
  </>);
}

export default DashboardSelectTenant