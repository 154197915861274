import React, { createContext, useContext, useEffect, useState } from "react";
import { ApplicationProfile } from "../../../contracts/contracts";
import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/react-hooks';
import { QUERY_APPLICATION_PROFILE, getApplicationProfilesGraphqlQueryOptions } from './applicationProfileQueries'
import { useAuthContext } from "../../auth/authContext";

export interface ApplicationProfileQueriesContext {
    fetchedApplicationProfiles: Array<ApplicationProfile>,
    queryApplicationProfiles: (applicationProfile: ApplicationProfile) => void,
    queryResponse: LazyQueryResult<any, OperationVariables>,
}

const ApplicationProfileQueriesContext = createContext<ApplicationProfileQueriesContext>(null as unknown as ApplicationProfileQueriesContext);

export const ApplicationProfileQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedApplicationProfiles, setFetchedApplicationProfiles] = useState<Array<ApplicationProfile>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_APPLICATION_PROFILE);

    const queryApplicationProfiles = (applicationProfile: ApplicationProfile): void => {
        const queryOptions = getApplicationProfilesGraphqlQueryOptions(applicationProfile);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.applicationProfiles) {
            const newFetchedApplicationProfiles: Array<ApplicationProfile> = queryResponse.data.applicationProfiles;
            setFetchedApplicationProfiles(newFetchedApplicationProfiles);
        }
    }, [queryResponse.data]);
    
    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedApplicationProfiles([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const applicationProfileQueriesContext: ApplicationProfileQueriesContext = {
        fetchedApplicationProfiles,
        queryApplicationProfiles,
        queryResponse,
    };

    return (
        <ApplicationProfileQueriesContext.Provider value={applicationProfileQueriesContext}>
            {children}
        </ApplicationProfileQueriesContext.Provider>
    );
}

export const useApplicationProfileQueriesContext = (): ApplicationProfileQueriesContext => {
    return useContext(ApplicationProfileQueriesContext);
}
