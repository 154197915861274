import React, { createContext, useContext, useEffect, useState } from "react";
import { StorageDocument, Ticket } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_STORAGE_DOCUMENT, getStorageDocumentsGraphqlQueryOptionsWithTickets } from './storageDocumentQueries'
import { useAuthContext } from "../../auth/authContext";

export interface StorageDocumentQueriesContext {
    fetchedStorageDocuments: Array<StorageDocument>,
    queryStorageDocumentsWithTickets: (tickets: Ticket[]) => void,
}

const StorageDocumentQueriesContext = createContext<StorageDocumentQueriesContext>(null as unknown as StorageDocumentQueriesContext);

export const StorageDocumentQueriesContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedStorageDocuments, setFetchedStorageDocuments] = useState<Array<StorageDocument>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_STORAGE_DOCUMENT);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);
    const [ticketBuffer, setTicketBuffer] = useState<Ticket[]>([]);

    const queryStorageDocumentsWithTickets = (tickets: Ticket[]): void => {
        if (tickets.length === 0) {
            return;
        }
        if (isWaitingForResponse) {
            setTicketBuffer(ticketBuffer.concat(tickets));
            return;
        }
        const queryOptions = getStorageDocumentsGraphqlQueryOptionsWithTickets(tickets);
        setTicketBuffer([]);
        setIsWaitingForResponse(true);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.storageDocuments) {
            const newFetchedStorageDocuments: Array<StorageDocument> = queryResponse.data.storageDocuments;
            setFetchedStorageDocuments(newFetchedStorageDocuments);
        }
        setIsWaitingForResponse(false);
    }, [queryResponse.data]);

    useEffect(() => {
        if (!isWaitingForResponse && ticketBuffer.length > 0) {
            queryStorageDocumentsWithTickets(ticketBuffer);
        }
    }, [isWaitingForResponse]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedStorageDocuments([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const storageDocumentQueriesContext: StorageDocumentQueriesContext = {
        fetchedStorageDocuments,
        queryStorageDocumentsWithTickets,
    };

    return (
        <StorageDocumentQueriesContext.Provider value={storageDocumentQueriesContext}>
            {children}
        </StorageDocumentQueriesContext.Provider>
    );
}

export const useStorageDocumentQueriesContext = (): StorageDocumentQueriesContext => {
    return useContext(StorageDocumentQueriesContext);
}
