import { MenuItem, Button, Menu, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useAuthContext } from '../../contexts/auth/authContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useProjectContext, userHasGlobalProjectAccess } from '../../contexts/project/projectContext';
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import { Project, ProjectMember } from '../../contracts/contracts';
import { Guid } from '../../utils/common-types';

const ProjectMemberSelector: React.FC<{}> = () => {

    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const projectContext = useProjectContext();
    const projectMemberContext = useProjectMemberContext();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOnClickProjectMember = (id: Guid | undefined) => {
        projectMemberContext.setSelectedProjectMember(id);
        handleCloseMenu();
    };

    const renderMenuItem = (projectMember: ProjectMember) => {
        return <MenuItem key={projectMember.id} onClick={() => handleOnClickProjectMember(projectMember.projectId)}>{projectMember.projectName}</MenuItem>
    }

    const renderMenuItemFromProject = (project: Project) => {
        return <MenuItem key={project.id} onClick={() => handleOnClickProjectMember(project.id)}>{projectMemberContext.getGlobalProjectName(project)}</MenuItem>
    }

    const projectMembersForLoggedInUser = projectMemberContext.getProjectMembersForLoggedInUser();
    let menuItems = projectMembersForLoggedInUser.map(renderMenuItem);
    if (userHasGlobalProjectAccess(authContext)) {
        const additionalProjects = projectContext.getProjects().filter(project => projectMembersForLoggedInUser.findIndex(projectMember => projectMember.projectId === project.id) < 0);
        menuItems = menuItems.concat(additionalProjects.map(renderMenuItemFromProject));
    }

    return (<>
        <Button onClick={handleClickMenu}>
            {projectMemberContext.getSelectedProjectMember()?.projectName || languageContext.getMessage('selectProject')}
            {projectMemberContext.loadingProjectMembers &&
                <CircularProgress size={15} style={{marginLeft: "10px"}} />
            }
        </Button>
        <Menu
            anchorEl={anchorEl}
            // getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            {menuItems}
        </Menu>
    </>
    );
}

export default ProjectMemberSelector;
