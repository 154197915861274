import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { NewsFeed, Project, ProjectMember, RoleType } from "../../contracts/contracts";
import ProjectDetailTopBar from "../../component/projectComponents/ProjectDetailTopBar";
import ProjectDetailContent from "../../component/projectComponents/ProjectDetailContent";
import { useUrlContext } from "../../contexts/url/urlContext";
import { checkProjectIdInProjectIdsToIgnore, getNewOnsiteProjectUrl } from "../../utils/projectTools";
import { useAuthContext } from "../../contexts/auth/authContext";
import { checkProjectAccess } from "../../contexts/userRole/userRoleTools";

type props = {
    project: Project | undefined;
    projectMember?: ProjectMember | undefined;
    onJoinProject: (project?: Project) => Promise<void>,
    onFollowProject: (project?: Project) => Promise<void>,
    onToggleCheckinProject: (project?: Project, checkedInStatusOverride?: boolean | undefined) => Promise<void>,
    newsFeeds?: NewsFeed[]
}

const ProjectDetailsView: React.FC<props> = ({
    project,
    projectMember,
    onJoinProject,
    onFollowProject,
    onToggleCheckinProject,
    newsFeeds,
}) => {

    const authContext = useAuthContext();
	const urlContext = useUrlContext();
	const urlState = urlContext.getUrlState();
	const projectId = urlState.projectId ? urlState.projectId as string : null;

    const ignoredProjectIdCheckHandled = useRef<string | undefined>(undefined);
    useEffect(() => {
        if (checkProjectAccess(RoleType.OWNER, authContext.accountRoles())) {
            return;
        }
        if (!projectId || projectId === ignoredProjectIdCheckHandled.current) {
            return;
        }
        if (checkProjectIdInProjectIdsToIgnore(projectId)) {
            window.location.href = getNewOnsiteProjectUrl(projectId);
        }
        ignoredProjectIdCheckHandled.current = projectId;
    }, [projectId])

    return (<>
        <Grid container item xs={12} spacing={1}>
            <ProjectDetailTopBar project={project} />
            <ProjectDetailContent
                project={project}
                projectMember={projectMember}
                onJoinProject={onJoinProject}
                onFollowProject={onFollowProject}
                onToggleCheckinProject={onToggleCheckinProject}
                newsFeeds={newsFeeds} />
        </Grid>
    </>);
}

export default ProjectDetailsView;
