import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, CircularProgress, Grid, Theme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useApplicationProfileContext } from "../../contexts/applicationProfile/applicationProfileContext";
import { ActiveState, ApplicationProfile, ProjectMember } from "../../contracts/contracts";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useAuthContext } from "../../contexts/auth/authContext";
import { useWidthContext } from "../../contexts/WidthContext";
import ConfirmDeleteDialog from "../../component/confirmComponents/ConfirmDeleteDialog";
import ConfirmSaveDialog from "../../component/confirmComponents/ConfirmSaveDialog";
import MyProfileInfo from "../../component/applicationProfileComponents/MyProfileInfo";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";
import { updateApplicationProfileAndUserDetails } from "../../utils/applicationProfileTools";
import { useProjectContext } from "../../contexts/project/projectContext";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '0em'
    },
    paper: {
        padding: theme.spacing(2)
    },
    divider: {
        marginBottom: '1em'
    },
}));

type props = {
    applicationProfile: ApplicationProfile | undefined;
    projectMember: ProjectMember | undefined;
    readonly?: boolean;
    loading?: boolean;
}

const ProfileDetailsView: React.FC<props> = ({
    applicationProfile,
    projectMember,
    readonly = false,
    loading
}) => {

    const classes = useStyles();
    const widthContext = useWidthContext();
    const authContext = useAuthContext();
    const userRoleContext = useUserRoleContext();
    const languageContext = useLanguageContext();
    const applicationProfileContext = useApplicationProfileContext();
    const projectMemberContext = useProjectMemberContext();
    const projectContext = useProjectContext();

    const editedProfile = useRef(applicationProfile || {})
    const editedProjectMember = useRef(projectMember || {})
    const handlingNewApplicationProfile = editedProfile.current.id === undefined;
    const [isSavingProfile, setIsSavingProfile] = useState<boolean>(false);
    const [savingIsDisabled, setSavingIsDisabled] = useState<boolean>(false);
    const project = projectContext.getProject(projectMember?.projectId);

    const saveApplicationProfile = async () => {
        setIsSavingProfile(true);
        updateApplicationProfileAndUserDetails(applicationProfile, 
                                               editedProfile.current, 
                                               applicationProfileContext, 
                                               userRoleContext, 
                                               authContext,
                                               () => setIsSavingProfile(false))
    };

    const saveProjectMember = async () => {
        const mutatedProjectMember: ProjectMember = { ...editedProjectMember.current };
        mutatedProjectMember.state = mutatedProjectMember.state ?? ActiveState.ACTIVE;
        setIsSavingProfile(true);
        await projectMemberContext.mutateProjectMember(mutatedProjectMember);
        setIsSavingProfile(false);
    };

    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);

    const handleOnCloseConfirmDeleteDialog = async (deleteIsConfirmed: boolean) => {
        if (deleteIsConfirmed) {
            editedProfile.current.state = ActiveState.INACTIVE;
            await saveApplicationProfile();
        }
        setOpenConfirmDeleteDialog(false);
    }

    const [openConfirmSaveDialog, setOpenConfirmSaveDialog] = useState<boolean>(false);

    const handleOnCloseConfirmSaveDialog = async (saveIsConfirmed: boolean) => {
        if (saveIsConfirmed) {
            if (projectMember) {
                editedProjectMember.current.state = ActiveState.ACTIVE;
                await saveProjectMember();
            }
            else {
                editedProfile.current.state = ActiveState.ACTIVE;
                await saveApplicationProfile();
            }
        }
        setOpenConfirmSaveDialog(false);
    }

    useEffect(() => {
        const currentUserId = authContext.accountInfo?.id;
        editedProfile.current = { ...applicationProfile, userId: handlingNewApplicationProfile ? currentUserId : applicationProfile?.userId }
    }, [applicationProfile]);

    const onProfileChange = useCallback((editedApplicationProfile: ApplicationProfile) => {
        editedProfile.current = editedApplicationProfile;
        const updatedSavingIsDisabled = !(editedProfile.current.email ?? '').includes('@') || (editedProfile.current.name ?? '').trim().length === 0;
        if (updatedSavingIsDisabled !== savingIsDisabled) {
            setSavingIsDisabled(updatedSavingIsDisabled);
        }
    }, [savingIsDisabled])

    const onProjectMemberChange = useCallback((newEditedProjectMember: ProjectMember) => {
        editedProjectMember.current = newEditedProjectMember;
    }, [])

    return loading ? <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 56 }}><CircularProgress /></Grid> : (
        <>
            <MyProfileInfo
                readonly={readonly}
                applicationProfile={applicationProfile}
                onProfileChange={onProfileChange}
                projectMember={projectMember}
                onProjectMemberChange={onProjectMemberChange} 
                project={project}/>
            <Grid container spacing={2}>
                {/* <Grid item  xs={9}></Grid> */}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    {(!readonly || projectMember?.id) && applicationProfile?.id && (
                        <Button onClick={async () => await handleOnCloseConfirmSaveDialog(true)}
                            disabled={isSavingProfile || savingIsDisabled}
                            color="primary"
                            variant="contained">
                            {languageContext.getMessage('save')}
                        </Button>
                    )}
                </Grid>
            </Grid>
            <ConfirmDeleteDialog
                title={languageContext.getMessage('doYouWantToDeleteProject')}
                open={openConfirmDeleteDialog}
                onClose={handleOnCloseConfirmDeleteDialog}
            />
            <ConfirmSaveDialog
                title={languageContext.getMessage('doYouWantToSaveProject')}
                open={openConfirmSaveDialog}
                onClose={handleOnCloseConfirmSaveDialog}
            />
        </>
    );
}

export default ProfileDetailsView;
