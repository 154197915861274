import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputLabel, Link, MenuItem, Select, Switch, TextField, Theme, ToggleButton, Typography, styled } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useProjectContext } from "../../contexts/project/projectContext";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { ActiveState, MemberAccess, MemberState, Project, ProjectState, RoleType } from "../../contracts/contracts";
import ConfirmDeleteDialog from "../confirmComponents/ConfirmDeleteDialog";
import ConfirmSaveDialog from "../confirmComponents/ConfirmSaveDialog";
import { useApplicationProfileContext } from "../../contexts/applicationProfile/applicationProfileContext";
import { navigateToExternalUrl } from "../../utils/urlTools";
import { useAuthContext } from "../../contexts/auth/authContext";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import NumberFormatField from "../generalComponents/NumberFormatField";
import PercentageInput from "../percentageComponents/PercentageInput";
import SearchInfoTechProjects from "../infoTechComponents/SearchInfoTechProjects";

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.error.main,
    marginRight: '0.25em',
  }
}));

type props = {
  project?: Project;
  open: boolean;
  onClose: (mutatedProject?: Project) => void;
}

const EditProject: React.FC<props> = ({
  project,
  open,
  onClose,
}) => {

  const classes = useStyles();
  const languageContext = useLanguageContext();
  const projectContext = useProjectContext();
  const projectMemberContext = useProjectMemberContext();
  const applicationProfileContext = useApplicationProfileContext();
  const authContext = useAuthContext();

  const [editedProject, setEditedProject] = useState<Project>({});
  const handlingNewProject = editedProject.id === undefined;
  const currentUserHasActivationPrivileges = authContext.accountRoles().findIndex(role => role === RoleType.OWNER) >= 0;

  const saveProject = async () => {
    let mutatedProject: Project | undefined = { ...editedProject };
    mutatedProject.state = mutatedProject.state ?? ActiveState.ACTIVE;
    mutatedProject.applicationProfileId = mutatedProject.applicationProfileId ?? applicationProfileContext.getApplicationProfileForLoggedInUser()?.id;
    mutatedProject.projectState = mutatedProject.projectState ?? ProjectState.REQUESTING_ACTIVATION;
    mutatedProject = await projectContext.mutateProject(mutatedProject);
    if (mutatedProject?.id && handlingNewProject &&
      projectMemberContext.getProjectMembersForLoggedInUser().findIndex(projectMember => projectMember.projectId === mutatedProject?.id) < 0) {
      projectMemberContext.addTemporaryProjectMember({
        state: mutatedProject.state,
        created: new Date(),
        applicationProfileId: mutatedProject.applicationProfileId,
        projectId: mutatedProject.id,
        projectName: mutatedProject.name,
        projectActiveState: mutatedProject.state,
        memberAccess: MemberAccess.MEMBER,
        memberState: MemberState.ACTIVE,
      })
      projectMemberContext.setSelectedProjectMember(mutatedProject.id);
    }
    onClose(mutatedProject);
  };

  const handleOnClickClose = () => {
    onClose(undefined);
  };

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const handleOnClickDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleOnCloseConfirmDeleteDialog = async (deleteIsConfirmed: boolean) => {
    if (deleteIsConfirmed) {
      editedProject.state = ActiveState.INACTIVE;
      await saveProject();
    }
    setOpenConfirmDeleteDialog(false);
  }

  const [openConfirmSaveDialog, setOpenConfirmSaveDialog] = useState<boolean>(false);
  const handleOnClickSave = () => {
    setOpenConfirmSaveDialog(true);
  };

  const handleOnCloseConfirmSaveDialog = async (saveIsConfirmed: boolean) => {
    if (saveIsConfirmed) {
      editedProject.state = ActiveState.ACTIVE;
      await saveProject();
    }
    setOpenConfirmSaveDialog(false);
  }

  let title = handlingNewProject ? languageContext.getMessage('newProject') : languageContext.getMessage('editProject');;

  useEffect(() => {
    setEditedProject({ ...project, address: { ...project?.address, coordinate: { ...project?.address?.coordinate } } });
  }, [project]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        onClose={handleOnClickClose}
        open={open}>
        <DialogTitle>
          {title}
          <IconButton onClick={handleOnClickClose} title={languageContext.getMessage('cancel')} sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {currentUserHasActivationPrivileges &&
              <Grid item xs={12}>
                <InputLabel>{languageContext.getMessage('activation')}</InputLabel>
                <Select
                  value={editedProject.projectState ?? ProjectState.REQUESTING_ACTIVATION}
                  label={languageContext.getMessage('activation')}
                  onChange={(event) => {
                    setEditedProject({ ...editedProject, projectState: event.target.value as ProjectState });
                  }}
                  variant="standard"
                  fullWidth
                >
                  {Object.keys(ProjectState).map((projectState) =>
                    <MenuItem value={projectState}>{projectState}</MenuItem>)}
                </Select>
              </Grid>}
            <Grid item xs={12}>
              <TextField
                label={languageContext.getMessage('name')}
                variant="standard"
                value={editedProject.name}
                onChange={(event) => {
                  setEditedProject({ ...editedProject, name: event.target.value as string });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                minRows={4}
                maxRows={20}
                label={languageContext.getMessage('description')}
                helperText={
                  <Link onClick={() => {
                    navigateToExternalUrl('https://www.markdownguide.org/basic-syntax/');
                  }}>
                    {languageContext.getMessage('markdownSupported')}
                  </Link>}
                variant="standard"
                value={editedProject.projectDescription}
                onChange={(event) => {
                  setEditedProject({ ...editedProject, projectDescription: event.target.value as string });
                }}
                fullWidth
              />
            </Grid>
            <Grid container style={{ marginTop: 12 }} item xs={12} spacing={1}>
              <Grid item xs={12}>
                <AccordionStyled>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{languageContext.getMessage('quizSettings')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <NumberFormatField
                          value={editedProject.maxNumberOfRequestingMemberAccessTries ?? 0}
                          label={languageContext.getMessage('maxQuizTries')}
                          allowNegative={false}
                          fixedDecimalScale={true}
                          decimalScale={0}
                          fullWidth={true}
                          onChange={(maxTries) => {
                            setEditedProject({ ...editedProject, maxNumberOfRequestingMemberAccessTries: maxTries })
                          }} />
                      </Grid>
                      <Grid item xs={12}>
                        <PercentageInput
                          percentage={editedProject.minimumPercentageVerifiedInformations ?? 0.0}
                          label={languageContext.getMessage('minQuizPercentage')}
                          fullWidth={true}
                          onChange={(minPercentage) => {
                            minPercentage = Math.min(1.0, minPercentage);
                            setEditedProject({ ...editedProject, minimumPercentageVerifiedInformations: minPercentage })
                          }} />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </AccordionStyled>
              </Grid>
              <Grid item xs={12}>
                <AccordionStyled>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{languageContext.getMessage('advancedSettings')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedProject.phoneNumberFieldIsMandatoryOnApplicationProfile}
                              onChange={(e) => {
                                setEditedProject({ ...editedProject, phoneNumberFieldIsMandatoryOnApplicationProfile: e.target.checked })
                              }} />
                          }
                          label={languageContext.getMessage('phoneIsRequired')} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedProject.relativesFieldIsMandatoryOnApplicationProfile}
                              onChange={(e) => {
                                setEditedProject({ ...editedProject, relativesFieldIsMandatoryOnApplicationProfile: e.target.checked })
                              }} />
                          }
                          label={languageContext.getMessage('relativesIsRequired')} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedProject.hseCardNumberFieldIsMandatoryOnApplicationProfile}
                              onChange={(e) => {
                                setEditedProject({ ...editedProject, hseCardNumberFieldIsMandatoryOnApplicationProfile: e.target.checked })
                              }} />
                          }
                          label={languageContext.getMessage('hseIsRequired')} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedProject.hseScanningActivated}
                              onChange={(e) => {
                                setEditedProject({ ...editedProject, hseScanningActivated: e.target.checked })
                              }} />
                          }
                          label={languageContext.getMessage('enableHseCardScanner')} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedProject.hseCertificatePictureUploadIsMandatory}
                              onChange={(e) => {
                                setEditedProject({ ...editedProject, hseCertificatePictureUploadIsMandatory: e.target.checked })
                              }} />
                          }
                          label={languageContext.getMessage('hsePictureIsRequired')} />
                      </Grid>
                      {/* <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={editedProject.generalCertificatePictureUploadIsMandatory}
                            onChange={(e) => {
                              setEditedProject({ ...editedProject, generalCertificatePictureUploadIsMandatory: e.target.checked })
                            }} />
                        }
                        label={languageContext.getMessage('certificatePictureIsRequired')} />
                    </Grid> */}
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedProject.parentProjectCompanyActivated}
                              onChange={(e) => {
                                setEditedProject({ ...editedProject, parentProjectCompanyActivated: e.target.checked })
                              }} />
                          }
                          label={languageContext.getMessage('enableParentCompany')} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedProject.autoApproveProjectMembers}
                              onChange={(e) => {
                                setEditedProject({ ...editedProject, autoApproveProjectMembers: e.target.checked })
                              }} />
                          }
                          label={languageContext.getMessage('autoApproveProjectMembers')} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedProject.enableCheckIn}
                              onChange={(e) => {
                                setEditedProject({ ...editedProject, enableCheckIn: e.target.checked })
                              }} />
                          }
                          label={languageContext.getMessage('enableCheckIn')} />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </AccordionStyled>
              </Grid>
              <Grid item xs={12}>
                <AccordionStyled>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{languageContext.getMessage('infoTech')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedProject.infoTechSettings?.enabled}
                              onChange={(e) => {
                                setEditedProject({
                                  ...editedProject, infoTechSettings: {
                                    ...editedProject.infoTechSettings,
                                    enabled: e.target.checked
                                  }
                                });
                              }} />
                          }
                          label={languageContext.getMessage('enabled')} />
                      </Grid>
                      {editedProject.infoTechSettings?.enabled && <>
                        <Grid item xs={12}>
                          <TextField
                            label={languageContext.getMessage('apiKey')}
                            variant="standard"
                            type="password"
                            value={editedProject.infoTechSettings?.infoTechApiKey}
                            onChange={(event) => {
                              setEditedProject({
                                ...editedProject, infoTechSettings: {
                                  ...editedProject.infoTechSettings,
                                  infoTechApiKey: event.target.value as string
                                }
                              });
                            }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SearchInfoTechProjects
                            infoTechApiKey={editedProject.infoTechSettings?.infoTechApiKey}
                            infoTechSettings={editedProject.infoTechSettings}
                            onSelectedInfoTechSettingsChange={(newSelectedInfoTechSettings) => {
                              setEditedProject({
                                ...editedProject,
                                infoTechSettings: newSelectedInfoTechSettings,
                              });
                            }}
                          />
                        </Grid>
                      </>}
                    </Grid>
                  </AccordionDetails>
                </AccordionStyled>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />} >
                  <Typography >{languageContext.getMessage('address')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        label={languageContext.getMessage('city')}
                        variant="standard"
                        value={editedProject.address?.city}
                        onChange={(event) => {
                          editedProject.address = editedProject.address ?? {};
                          editedProject.address.street = event.target.value as string;
                          setEditedProject({ ...editedProject });
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={languageContext.getMessage('postcode')}
                        variant="standard"
                        value={editedProject.address?.postcode}
                        onChange={(event) => {
                          editedProject.address = editedProject.address ?? {};
                          editedProject.address.postcode = event.target.value as string;
                          setEditedProject({ ...editedProject });
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={languageContext.getMessage('street')}
                        variant="standard"
                        value={editedProject.address?.street}
                        onChange={(event) => {
                          editedProject.address = editedProject.address ?? {};
                          editedProject.address.street = event.target.value as string;
                          setEditedProject({ ...editedProject });
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />} >
                  <Typography >{languageContext.getMessage('coordinates')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <NumberFormatField
                        label={languageContext.getMessage('latitude')}
                        value={editedProject.address?.coordinate?.latitude ?? 0.0}
                        allowNegative={true}
                        fixedDecimalScale={false}
                        decimalScale={7}
                        thousandSeparator={false}
                        decimalSeparator={'.'}
                        onChange={(latitude) => {
                          editedProject.address = editedProject.address ?? {};
                          editedProject.address.coordinate = editedProject.address.coordinate ?? {};
                          editedProject.address.coordinate.latitude = latitude;
                          setEditedProject({ ...editedProject })
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberFormatField
                        label={languageContext.getMessage('longitude')}
                        value={editedProject.address?.coordinate?.longitude ?? 0.0}
                        allowNegative={true}
                        fixedDecimalScale={false}
                        decimalScale={7}
                        thousandSeparator={false}
                        decimalSeparator={'.'}
                        onChange={(longitude) => {
                          editedProject.address = editedProject.address ?? {};
                          editedProject.address.coordinate = editedProject.address.coordinate ?? {};
                          editedProject.address.coordinate.longitude = longitude;
                          setEditedProject({ ...editedProject })
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          {!handlingNewProject && <Button onClick={handleOnClickDelete} color="primary" variant="contained">
            {languageContext.getMessage('delete')}
          </Button>}
          <Button onClick={handleOnClickSave} color="primary" variant="contained">
            {languageContext.getMessage('save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeleteDialog
        title={languageContext.getMessage('doYouWantToDeleteProject')}
        open={openConfirmDeleteDialog}
        onClose={handleOnCloseConfirmDeleteDialog}
      />
      <ConfirmSaveDialog
        title={languageContext.getMessage('doYouWantToSaveProject')}
        open={openConfirmSaveDialog}
        onClose={handleOnCloseConfirmSaveDialog}
      />
    </>
  );
}

const AccordionStyled = styled(Accordion)`
          width: 100%;
          `

export default EditProject