import React from 'react';
import { Avatar, Badge, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { getFirstCapitalizedLetter } from "../../utils/stringTools";
import { stringToColor } from "../../utils/colorTools";
import { SxProps } from "@mui/system";
import { Guid } from "../../utils/common-types";

import CreditCardIcon from '@mui/icons-material/CreditCard';
import QuizIcon from '@mui/icons-material/Quiz';
import GppBadIcon from '@mui/icons-material/GppBad';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import ReactMarkdown from 'react-markdown';

type props = {
	userName?: string,
	src?: string,
	sx?: SxProps,
	id?: Guid,
	missingCertificate?: boolean,
	missingHSECards?: boolean,
	failedSafetyInformationQuiz?: boolean,
	isManualUser?: boolean,
	isCheckedIn?: boolean,
	notes?: string,
	wasCheckinToday?: boolean,
	showCheckInStatus?: boolean,
}

const ApplicationProfileAvatar: React.FC<props> = ({
	userName,
	src,
	sx,
	id,
	missingCertificate,
	missingHSECards,
	failedSafetyInformationQuiz,
	isManualUser,
	isCheckedIn,
	notes,
	wasCheckinToday,
	showCheckInStatus
}) => {

	const languageContext = useLanguageContext()

	return (
		<>
			<Grid container sx={{ position: 'relative' }}>
				<Stack
					direction='row'
					sx={{ position: 'absolute', left: -10, bottom: -20, zIndex: 1 }}
				>
					{missingCertificate ? (
						<Tooltip
							title={languageContext.getMessage('certificateMissingTooltip')}
							placement='bottom'
						>
							<GppBadIcon fontSize='small' color='error' />
						</Tooltip>
					) : null}
					{missingHSECards ? (
						<Tooltip
							title={languageContext.getMessage('hseCardMissingTooltip')}
							placement='bottom'
						>
							<CreditCardIcon fontSize='small' color='error' />
						</Tooltip>
					) : null}
					{failedSafetyInformationQuiz ? (
						<Tooltip
							title={languageContext.getMessage('failedSafetyInformationQuizTooltip')}
							placement='bottom'
						>
							<QuizIcon fontSize='small' color='error' />
						</Tooltip>
					) : null}
					{isManualUser ? (
						<Tooltip
							title={languageContext.getMessage('manualUserTooltip')}
							placement='bottom'
						>
							<ManageAccountsIcon fontSize='small' color='error' />
						</Tooltip>
					) : null}
					{(notes ?? '').trim().length > 0 ? (
						<Tooltip
							title={<ReactMarkdown
								children={notes ?? ''}
								components={{
									p: ({ children, ...props }) => <Typography variant="caption">{children}</Typography>
								}}
							/>}
							placement='bottom'
						>
							<StickyNote2Icon fontSize='small' color='error' />
						</Tooltip>
					) : null}
				</Stack>
				<Avatar sx={{
					position: 'relative',
					border: showCheckInStatus ? '2px solid' : undefined,
					borderColor: showCheckInStatus ? (isCheckedIn ? 'success.main' : wasCheckinToday ? 'warning.main' : undefined) : undefined,
					bgcolor: src ? 'background.paper' : (id ? stringToColor(id) : null), ...sx
				}} alt={userName} src={src}>
					{getFirstCapitalizedLetter(userName)}
				</Avatar>
			</Grid>
		</>
	)
}

export default ApplicationProfileAvatar;
