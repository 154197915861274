import { useEffect, useState } from "react";
import { useStoredFilesByFileKey } from "../../hooks/useStoredFilesByFileKey";

type props = {
    fileKey: string | undefined,
    onFileLoaded?: (fileKey: string | undefined, finished: boolean) => void,
};

const FileLoader: React.FC<props> = ({
    fileKey,
    onFileLoaded, }) => {
    const [base64Images, setBase64Images] = useState<string[]>([]);
    const [imageSrces, loading] = useStoredFilesByFileKey(fileKey, base64Images, setBase64Images, undefined, undefined, true, (finished) => onFileLoaded && onFileLoaded(fileKey, finished));

    return <></>;
}

export default FileLoader;