import React, { useEffect, useMemo, useState, useRef } from "react";
import { Grid, TextField, Autocomplete, CircularProgress } from "@mui/material";
import { withTheme } from '@mui/styles';
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { useInformationContext } from "../../contexts/information/informationContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { Information, DocumentType, ActiveState, RoleType, InformationAccess } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import InformationsList from "../../component/informationComponents/InformationsList";

import AddIcon from '@mui/icons-material/Add';
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import FabButton from "../../component/generalComponents/FabButton";
import ConfirmDeleteDialog from "../../component/confirmComponents/ConfirmDeleteDialog";
import SaveDialog from "../../component/generalComponents/SaveDialog";
import EditInformationDetails from "../../component/informationComponents/EditInformationDetails";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { guidIsNullOrEmpty } from "../../utils/guidTools";
import { checkInformationIsValid } from "../../utils/informationTools";
import { Guid } from "../../utils/common-types";

const SafetyInformationsView: React.FC<{}> = () => {

    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false)
    const [openNewInformationDialog, setOpenNewInformationDialog] = useState(false)
    const [newInformationIsValid, setNewInformationIsValid] = useState<boolean>(true);
    const newInformationRef = useRef<Information>({})
    const informationToRemoveRef = useRef<Information>({})

    const urlContext = useUrlContext();
    const languageContext = useLanguageContext();
    const ticketContext = useTicketContext();
    const informationContext = useInformationContext();
    const menuContext = useMenuContext();
    const projectMemberContext = useProjectMemberContext();

    const urlState = urlContext.getUrlState();
    const projectId: Guid | undefined = urlState.selectedProjectId;

    const informationSearch = informationContext.getInformationSearch();
    informationSearch.projectId = projectId;

    const [search, setSearch] = React.useState<string | Information | null>(null);

    const informations = informationContext.getInformations().filter(information => guidIsNullOrEmpty(information.informationId));
    const loading = informationContext.loadingInformations;

    const filteredInformations = useMemo(() => {
        if (!search) return informations;
        if (typeof search === "string") return search.trim().length === 0 ? informations : informations.filter(information => information?.title?.toLowerCase().includes(search.toLowerCase()) || information?.information?.toLowerCase().includes(search.toLowerCase()));
        if (typeof search === "object") return informations.filter(information => information.id === search.id);
        return [];
    }, [search, informations]);

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([DocumentType.INFORMATION]);
        informationContext.searchInformations(informationSearch);
    }, [projectId]);

    const updateUrl = (route: ApplicationRoute, urlState?: Dictionary<string | number | Date>) => {
        const urlQuery = urlState ? urlContext.buildUrlQuery(urlState) : '';

        urlContext.pushUrlQuery(urlQuery, route.route)
    }

    const removeInformation = async (information: Information) => {
        const parentInformationId = informationContext.getParentInformationId(information);
        const relatedInformations = informationContext.getRelatedInformations(parentInformationId);
        const isParentInformation = informationContext.isParentInformation(information, parentInformationId);
        const informationsToDelete: Information[] = isParentInformation ? relatedInformations : (information ? [information] : []);
        informationsToDelete.forEach(async informationToDelete => {
            if (!informationToDelete?.id) {
                return;
            }
            informationToDelete.state = ActiveState.INACTIVE;
            await informationContext.mutateInformation(informationToDelete);
        });
    }

    const onCloseConfirmDeleteDialog = async (confirmDelete: boolean) => {
        if (confirmDelete && informationToRemoveRef.current) {
            await removeInformation(informationToRemoveRef.current)
        }

        setOpenConfirmDeleteDialog(false)
    }

    const onDeleteInformation = (information: Information) => {
        informationToRemoveRef.current = { ...information };
        setOpenConfirmDeleteDialog(true)
    }

    const onInformationDetailsClick = (information: Information | undefined) => {
        if (information?.id) {
            const route = menuContext.getApplicationRouteById(ApplicationRouteId.SafetyInformationDetails)
            const formattedRoute = { ...route, route: route.route.replace(':informationId', information?.id) }

            updateUrl(formattedRoute)
        }
    }

    const addNewAdditionalInformationLanguages = async (newInformation: Information, allParentInformationsForProject: Information[]): Promise<Information[]> => {
        let allNewInformationLanguages: Information[] = [newInformation];
        if (allParentInformationsForProject.length > 0) {
            const lastParentInformationForProject = allParentInformationsForProject[allParentInformationsForProject.length - 1];
            const lastParentInformationLanguagesForProject = informationContext.getRelatedInformations(lastParentInformationForProject.id);
            let handledLanguages: string[] = [(newInformation.language ?? '').trim()];
            for (let i = 0; i < lastParentInformationLanguagesForProject.length; i++) {
                const lastParentInformationLanguageForProject = lastParentInformationLanguagesForProject[i];
                const newLanguage = (lastParentInformationLanguageForProject.language ?? '').trim();
                if (handledLanguages.findIndex(handledLanguage => handledLanguage === newLanguage) < 0) {
                    handledLanguages = handledLanguages.concat(newLanguage);
                    const newInformationLanguage: Information = {
                        ...newInformation,
                        id: undefined,
                        informationId: newInformation.id,
                        language: newLanguage,
                        informationApprovals: newInformation.informationApprovals?.map(informationApproval => { return { ...informationApproval } }),
                        file: undefined
                    };
                    const newInformationLanguageId = await informationContext.mutateInformation(newInformationLanguage);
                    newInformationLanguage.id = newInformationLanguageId;
                    allNewInformationLanguages = allNewInformationLanguages.concat(newInformationLanguage);
                }
            }
        }
        return allNewInformationLanguages;
    }

    const addNewInformation = async () => {
        const newInformation = { ...newInformationRef.current };
        const allParentInformationsForProject = informationContext.getProjectParentInformations(newInformation.projectId);
        newInformation.state = ActiveState.ACTIVE;
        newInformation.orderPosition = newInformation.orderPosition ?? allParentInformationsForProject.length;
        newInformation.informationAccess = newInformation.informationAccess ?? InformationAccess.PRIVATE;
        newInformation.projectId = projectId;

        const documentId = await informationContext.mutateInformation(newInformation);
        if (documentId) {
            newInformation.id = documentId;
            if (newInformation.file && (newInformation.informationFilename ?? '').trim().length > 0) {
                informationContext.saveInformationFile(newInformation, newInformation.file);
            }
            await addNewAdditionalInformationLanguages(newInformation, allParentInformationsForProject);
        }

        setOpenNewInformationDialog(false)
    }

    const onOpenNewInformationDialog = () => {
        const allParentInformationsForProject = informationContext.getProjectParentInformations(projectId);
        if (allParentInformationsForProject.length > 0) {
            const lastParentInformationForProject = allParentInformationsForProject[allParentInformationsForProject.length - 1];
            newInformationRef.current = { language: lastParentInformationForProject.language };

        }
        setOpenNewInformationDialog(true);
    }

    const hasWriteAccessToSelectedProject = projectId && projectMemberContext.hasProjectAccess(RoleType.WRITER, projectId);
    return (<>
        <Grid container spacing={1} flexWrap='nowrap' flexDirection='column' flexBasis='100%' >
            <>
                <Grid item xs={12}>
                    <Autocomplete
                        sx={{ mb: 2 }}
                        freeSolo
                        onInputChange={(e, value) => setSearch(value)}
                        onChange={(e, value) => setSearch(value)}
                        getOptionLabel={option => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            return option.title ?? '';
                        }}
                        options={informations}
                        renderInput={(params) => loading ? <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 56 }}><CircularProgress /></Grid> : <TextField {...params} label={languageContext.getMessage("search")} />}
                    />
                    <InformationsList
                        informations={filteredInformations}
                        onInformationDetailsClick={onInformationDetailsClick}
                        onDeleteInformation={onDeleteInformation}
                        showDeleteButton={true}
                    />
                </Grid>

                {projectId && hasWriteAccessToSelectedProject && (
                    <FabButton
                        sx={{ zIndex: 5 }}
                        onClick={onOpenNewInformationDialog}
                        icon={<AddIcon />}
                        label={languageContext.getMessage("add")}
                    />
                )}

            </>
        </Grid>

        <ConfirmDeleteDialog
            title={languageContext.getMessage('doYouWantToDeleteSafetyInformation')}
            open={openConfirmDeleteDialog}
            onClose={async (confirmDelete) => await onCloseConfirmDeleteDialog(confirmDelete)}
        />

        <SaveDialog
            title={languageContext.getMessage('newSafetyInformation')}
            open={openNewInformationDialog}
            onClose={() => {
                setOpenNewInformationDialog(false)
                newInformationRef.current = {}
            }}
            onSave={async () => await addNewInformation()}
            saveIsDisabled={!newInformationIsValid}
        >
            <EditInformationDetails information={{ ...newInformationRef.current, projectId: projectId }} onInformationChange={(information) => {
                newInformationRef.current = { ...information };
                const informationIsValid = checkInformationIsValid(newInformationRef.current);
                setNewInformationIsValid(informationIsValid);
            }} />
        </SaveDialog>
    </>
    );
}

export default withTheme(SafetyInformationsView);
