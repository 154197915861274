import { Button, Dialog, Divider, Fade, FormControlLabel, FormGroup, Grid, List, Menu, MenuItem, Stack, Switch, SxProps, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import QrCodeIcon from '@mui/icons-material/QrCode';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import { NewsFeed, Project, MemberAccess, RoleType, ProjectMember } from '../../contracts/contracts';
import NewsFeedTeaser from "../newsFeed/newsFeedTeaser";
import NewsFeedDetailsView from "../../views/newsFeed/newsFeedDetailsView";
import QrCodeDialog from '../generalComponents/QrCodeDialog';
import { useUrlContext } from '../../contexts/url/urlContext';
import { getLimitedString } from '../../utils/stringTools';

export type ImageTile = {
	image: string;
	base64Image: string | undefined;
	title: string;
	cols: number;
	showUploadImageButton: boolean;
}

type props = {
	project?: Project | undefined;
	projectMember?: ProjectMember | undefined;
	onJoinProject: (project?: Project) => Promise<void>,
	onFollowProject: (project?: Project) => Promise<void>,
	onToggleCheckinProject: (project?: Project, checkedInStatusOverride?: boolean | undefined) => Promise<void>,
	newsFeeds?: NewsFeed[];
}

const ProjectDetailContent: React.FC<props> = ({ project, projectMember, onJoinProject, onFollowProject, onToggleCheckinProject, newsFeeds = [] }) => {

	const languageContext = useLanguageContext();
	const projectMemberContext = useProjectMemberContext();
	const urlContext = useUrlContext();

	const [selectedNewsFeed, setSelectedNewsFeed] = useState<undefined | NewsFeed>(undefined);
	const [openQrCodeDialog, setOpenQrCodeDialog] = useState<boolean>(false);
	const [qrCodeDialogValue, setQrCodeDialogValue] = useState<string>('');
	const [qrCodeDialogTitle, setQrCodeDialogTitle] = useState<string>('');

	const qrCodeProjectTitle = `### ${getLimitedString(project?.name ?? '', 50)}`;
	const qrCodeProjectWithCheckInTitle = `### ${languageContext.getMessage('checkIn')} \n ### ${getLimitedString(project?.name ?? '', 50)}`;
	const qrCodeProjectWithCheckOutTitle = `### ${languageContext.getMessage('checkOut')} \n ### ${getLimitedString(project?.name ?? '', 50)}`;

	const [anchorElOpenQrCodeSelection, setAnchorElOpenQrCodeSelection] = useState<null | HTMLElement>(null);
	const openQrCodeSelection = Boolean(anchorElOpenQrCodeSelection);

	const handleCloseQrCodeSelection = (openQrCode: boolean, checkedInStatusOverride?: boolean | undefined, title?: string | undefined) => {
		const qrCodeUrl = `${window.location.origin}${window.location.pathname}${urlContext.buildUrlQuery({
			...urlContext.getUrlState(),
			checkedInStatus: checkedInStatusOverride !== undefined ? checkedInStatusOverride.toString() : undefined,
		})}`;
		setQrCodeDialogValue(qrCodeUrl);
		setQrCodeDialogTitle(title ?? '');
		setOpenQrCodeDialog(openQrCode);
		setAnchorElOpenQrCodeSelection(null);
	};

	const handleOpenQrCodeSelection = (event: React.MouseEvent<HTMLElement>) => {
		if (project?.enableCheckIn && projectMemberContext.hasProjectAccess(RoleType.WRITER, project?.id)) {
			setAnchorElOpenQrCodeSelection(event.currentTarget);
		}
		else {
			handleCloseQrCodeSelection(true, undefined, qrCodeProjectTitle);
		}
	};

	const projectMemberLoading = projectMemberContext.loadingProjectMembers

	const isPublicAccess = !!(projectMember?.memberAccess === MemberAccess.PUBLIC)
	const isRequestAccess = !!(projectMember?.memberAccess === MemberAccess.REQUESTING_MEMBER_ACCESS)
	const isDeniedAccess = !!(projectMember?.memberAccess === MemberAccess.DENIED_MEMBER_ACCESS)
	const isMemberAccess = !!(projectMember?.memberAccess === MemberAccess.MEMBER)

	const showFollowButtom = true;

	const followButtonText = isPublicAccess || isMemberAccess || isRequestAccess || isDeniedAccess ? languageContext.getMessage('unfollowProject') : `${languageContext.getMessage('followProject')} +`

	const actionsDisabled = !project?.id || projectMemberLoading
	const checkInEnabled = (project?.enableCheckIn ?? false) && isMemberAccess;

	let joinButtonText = isMemberAccess
		? languageContext.getMessage('member')
		: isRequestAccess
			? languageContext.getMessage('pendingApproval')
			: languageContext.getMessage('joinProject')
	if (isDeniedAccess) {
		joinButtonText = languageContext.getMessage('denied');
	}

	const handleOnNewsFeedClick = useCallback((newsFeed?: NewsFeed) => {
		setSelectedNewsFeed(newsFeed)
	}, []);

	const [showFullProjectDescription, setShowFullProjectDescription] = useState<boolean>(false);
	const projectDescriptionStyle: SxProps<Theme> = showFullProjectDescription ? {
		overflow: 'visible'
	} : {
		maxHeight: 150,
		overflow: 'hidden'
	};

	return (<>
		<Dialog
			fullWidth={true}
			maxWidth={'xs'}
			onClose={() => setSelectedNewsFeed(undefined)}
			open={!!selectedNewsFeed}>
			<NewsFeedDetailsView newsFeed={selectedNewsFeed} />
		</Dialog>
		<Menu
			anchorEl={anchorElOpenQrCodeSelection}
			open={openQrCodeSelection}
			onClose={() => handleCloseQrCodeSelection(false)}
			TransitionComponent={Fade}
		>

			<MenuItem key={`language-project`} onClick={() => handleCloseQrCodeSelection(true, undefined, qrCodeProjectTitle)} value={'project'}>
				{languageContext.getMessage('project')}
			</MenuItem>
			<MenuItem key={`language-checkIn`} onClick={() => handleCloseQrCodeSelection(true, true, qrCodeProjectWithCheckInTitle)} value={'checkIn'}>
				{languageContext.getMessage('checkIn')}
			</MenuItem>
			<MenuItem key={`language-checkOut`} onClick={() => handleCloseQrCodeSelection(true, false, qrCodeProjectWithCheckOutTitle)} value={'checkOut'}>
				{languageContext.getMessage('checkOut')}
			</MenuItem>
		</Menu>
		<QrCodeDialog
			open={openQrCodeDialog}
			onClose={() => setOpenQrCodeDialog(false)}
			qrCode={qrCodeDialogValue}
			title={qrCodeDialogTitle} />
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Grid
					container
					direction="row"
					justifyContent={showFollowButtom ? "space-between" : "flex-end"}
					alignItems="center"
					flexWrap="nowrap"
					sx={{ mb: 2, mt: checkInEnabled ? 2 : 1 }}
				>
					{showFollowButtom &&
						<Button onClick={async () => await onFollowProject(project)} disabled={actionsDisabled} variant="outlined" size="small">
							{followButtonText}
						</Button>
					}
					<Stack direction="column" spacing={1}>
						<Button variant="contained" size={projectMember?.id ? "small" : "large"} disabled={actionsDisabled} onClick={async () => await onJoinProject(project)}>
							{joinButtonText}
						</Button>
						{checkInEnabled &&
							<FormGroup>
								<FormControlLabel control={<Switch
									color={projectMember?.isCheckedIn ? 'success' : undefined}
									size={"medium"}
									checked={projectMember?.isCheckedIn ?? false}
									onChange={async () => await onToggleCheckinProject(project)}
									disabled={actionsDisabled}
								/>} label={projectMember?.isCheckedIn ? languageContext.getMessage('checkOut') : languageContext.getMessage('checkIn')} />
							</FormGroup>}
					</Stack>
					<Button variant="outlined" size="small" onClick={(event) => handleOpenQrCodeSelection(event)}>
						<QrCodeIcon />
					</Button>
				</Grid>
				<Divider sx={{ mb: 2 }} />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<ReactMarkdown
							children={project?.projectDescription ?? ''}
							components={{
								p: ({ children, ...props }) => <Typography {...props} gutterBottom variant="body2" sx={projectDescriptionStyle}>{children}</Typography>
							}}
						/>
						{(project?.projectDescription ?? '').length > 0 &&
							<Button onClick={() => setShowFullProjectDescription(!showFullProjectDescription)}>
								{showFullProjectDescription ? languageContext.getMessage('hide') : languageContext.getMessage('show')}
							</Button>}
						{!!newsFeeds.length && !!projectMember &&
							<>
								<Typography textAlign="center"
									variant="h5">{languageContext.getMessage('messages')}</Typography>
								<List sx={{ bgcolor: 'background.paper', mt: 2, pt: 0 }}>
									{newsFeeds.map((newsFeed, index) => (
										<React.Fragment key={newsFeed.id}>
											<NewsFeedTeaser newsFeed={newsFeed} onTeaserClick={handleOnNewsFeedClick} />
											{index !== newsFeeds.length - 1 ? <Divider /> : null}
										</React.Fragment>
									))}
								</List>
							</>
						}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</>);
}

export default ProjectDetailContent
