import React, { createContext, useContext, useEffect, useState } from "react";
import { UserRole, User } from "../../../contracts/contracts";
import { useLazyQuery } from '@apollo/react-hooks';
import { useAuthContext } from "../../auth/authContext";
import { getUsersGraphqlQueryOptions, getRolesGraphqlQueryOptions, QUERY_ROLES, QUERY_USERS } from "./userRoleQueries";

export interface UserRolesQueriesContext {
    fetchedUserRoles: Array<UserRole>;
    fetchedUsers: Array<User>;
    queryRoles: (userRoles?: UserRole[]) => boolean;
    queryUsers: (userRoles?: UserRole[]) => boolean;
    isLoadingRoles: boolean;
    isLoadingUsers: boolean;
}

const UserRolesQueriesContext = createContext<UserRolesQueriesContext>(null as unknown as UserRolesQueriesContext);

export const UserRolesQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();

    const [fetchedUsers, setFetchedUsers] = useState<Array<User>>([]);
    const [fetchedUserRoles, setfetchedUserRoles] = useState<Array<UserRole>>([]);

    const [loadRolesQuery, queryRoleResponse] = useLazyQuery(QUERY_ROLES);
    const [loadUsersQuery, queryUsersResponse] = useLazyQuery(QUERY_USERS);

    const queryRoles = (userRoles?: UserRole[]): boolean => {
        if (!authContext.authSettings?.clientId) {
            return false;
        }
        const queryOptions = getRolesGraphqlQueryOptions(authContext.authSettings?.clientId, userRoles);
        loadRolesQuery(queryOptions);
        return true;
    }

    const queryUsers = (userRoles?: UserRole[]): boolean => {
        if (!authContext.authSettings?.clientId) {
            return false;
        }
        const queryOptions = getUsersGraphqlQueryOptions(authContext.authSettings?.clientId, userRoles);
        loadUsersQuery(queryOptions);
        return true;
    }

    useEffect(() => {
        if (queryRoleResponse.data && queryRoleResponse.data.roles) {
            const newFetchedRoles: Array<UserRole> = queryRoleResponse.data.roles;
            setfetchedUserRoles(newFetchedRoles);
        }
    }, [queryRoleResponse.data]);

    useEffect(() => {
        if (queryRoleResponse.error) {
            console.error(queryRoleResponse.error);
        }
    }, [queryRoleResponse.error]);

    useEffect(() => {
        if (queryUsersResponse.data && queryUsersResponse.data.users) {
            const newFetchedUsers: Array<User> = queryUsersResponse.data.users;
            setFetchedUsers(newFetchedUsers);
        }
    }, [queryUsersResponse.data]);

    useEffect(() => {
        if (queryUsersResponse.error) {
            console.error(queryUsersResponse.error);
        }
    }, [queryUsersResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedUsers([]);
            setfetchedUserRoles([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);
    

    const userRolesQueriesContext: UserRolesQueriesContext = {
        fetchedUserRoles,
        fetchedUsers,
        queryRoles,
        queryUsers,
        isLoadingRoles: queryRoleResponse.loading,
        isLoadingUsers: queryUsersResponse.loading,
    };

    return (
        <UserRolesQueriesContext.Provider value={userRolesQueriesContext}>
            {children}
        </UserRolesQueriesContext.Provider>
    );
}

export const useUserRolesQueriesContext = (): UserRolesQueriesContext => {
    return useContext(UserRolesQueriesContext);
}
