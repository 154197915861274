import React, { createContext, useContext } from "react";
import { useLanguageContext } from "./LanguageContext";


export type MaterialTableLanguageContext = {
    getLocalization: () => any;
};

const MaterialTableLanguageContext = createContext<MaterialTableLanguageContext>(null as unknown as MaterialTableLanguageContext);

export const MaterialTableLanguageContextProvider: React.FC<{}> = ({ children }) => {

    const { getLanguage } = useLanguageContext();

    const getLocalization = () => {
        
        if (getLanguage() === 'nb') {
            return getNorwegianState();
        }
        else if (getLanguage() === 'en') {
            return getEnglishState();
        }
        return {};
    }

    const value = {
        getLocalization
    };

    return (
        <MaterialTableLanguageContext.Provider value={value}>
            {children}
        </MaterialTableLanguageContext.Provider>
    );
}

export const useMaterialTableLanguageContext = (): MaterialTableLanguageContext => {
    return useContext(MaterialTableLanguageContext);
}


const getNorwegianState = () => {
    return {
        body: {
            emptyDataSourceMessage: 'Ingen data å vise',
            addTooltip: 'Legg til',
            deleteTooltip: 'Slett',
            editTooltip: 'Rediger',
            filterRow: {
                filterTooltip: 'Filter'
            },
            editRow: {
                deleteText: 'Er du sikker på at du vil slette rad?',
                cancelTooltip: 'Avbryt',
                saveTooltip: 'Lagre'
            }
        },
        grouping: {
            placeholder: 'Dra overskrifter...',
        },
        header: {
            actions: ''
        },
        pagination: {
            labelDisplayedRows: '{from}-{to} av {count}',
            labelRowsSelect: 'rader',
            labelRowsPerPage: 'Rader per side:',
            firstAriaLabel: 'Første side',
            firstTooltip: 'Første side',
            previousAriaLabel: 'Forrige side',
            previousTooltip: 'Forrige side',
            nextAriaLabel: 'Neste side',
            nextTooltip: 'Neste side',
            lastAriaLabel: 'Siste side',
            lastTooltip: 'Siste side'
        },
        toolbar: {
            addRemoveColumns: 'Legg til eller slett kolonner',
            nRowsSelected: '{0} rad(er) valgt',
            showColumnsTitle: 'Vis kolonner',
            showColumnsAriaLabel: 'Vis kolonner',
            exportTitle: 'Eksporter',
            exportAriaLabel: 'Eksporter',
            exportCSVName: 'Eksporter som Excel',
            exportPDFName: 'Eksporter som PDF',
            searchTooltip: 'Søk',
            searchPlaceholder: 'Søk',
        }
    };
}

const getEnglishState = () => {
    return {
        header: {
            actions: ''
        },
        toolbar: {
            exportCSVName: 'Export as Excel',
        }
    };
}