import { AlertColor, Snackbar, SnackbarOrigin, SxProps } from "@mui/material";
import { FC, ReactNode } from "react";
import { AlertComponent } from "./AlertComponent";

type props = {
    readonly open: boolean;
    readonly autoHideDuration?: number;
    readonly onClose: () => void;
    readonly severity?: AlertColor;
    readonly alertSx?: SxProps;
    readonly anchorOrigin?: SnackbarOrigin;
    readonly children: ReactNode;
}
export const SnackbarComponent: FC<props> = ({
    open,
    autoHideDuration = 5000,
    onClose,
    severity = "success",
    alertSx = {
        width: "100%",
    },
    anchorOrigin = {
        vertical: "top",
        horizontal: "center"
    },
    children
}) => {

    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={anchorOrigin}>
            <AlertComponent onClose={onClose} severity={severity} sx={alertSx}>
                {children}
            </AlertComponent>
        </Snackbar>
    )
}