import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, Settings } from "../../../contracts/contracts";
import { cleanObject } from "../../../utils/objectTools";


export const MUTATE_SETTINGS: DocumentNode = gql(`
    mutation mutateSettings(
        $id: UUID
        $state: ActiveState
        $projectId: UUID!
    ) {
        mutateSettings(
            id: $id
            state: $state
            projectId: $projectId
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`);

export const getSettingsGraphqlMutationOptions = (settings: Partial<Settings>): QueryHookOptions => {
    settings.state = settings.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (settings.id !== undefined && settings.id !== null) options.variables['id'] = settings.id;
    if (settings.state !== undefined && settings.state !== null) options.variables['state'] = settings.state;
    if (settings.projectId !== undefined && settings.projectId !== null) options.variables['projectId'] = settings.projectId;
    return options;
}