import React, { useCallback, useEffect, useMemo, useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Select, Input, MenuItem, IconButton, FormControl, Autocomplete, Grid, TextField, Pagination, Stack, CircularProgress, styled } from '@mui/material';
import { makeStyles, withTheme } from '@mui/styles';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { useUserRoleContext } from '../../contexts/userRole/userRoleContext';
import { Dictionary } from '../../global-types';
import { MemberAccess, ProjectMember, RoleType, User } from '../../contracts/contracts';
import { checkProjectAccess, getDominantRoleFromUser, getProjectIdFromProjectRole, getRoleDescription, getRoleType, notSpecified, updateUserRoles } from "../../contexts/userRole/userRoleTools";
import { useAuthContext } from "../../contexts/auth/authContext";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";

export interface AuthSettingsProps {
}

const AuthSettings: React.FC<AuthSettingsProps> = (props: AuthSettingsProps) => {

  const languageContext = useLanguageContext();
  const authContext = useAuthContext();
  const userRoleContext = useUserRoleContext();
  const projectMemberContext = useProjectMemberContext();

  let projectRoles = userRoleContext.getUserRoles(projectMemberContext.getSelectedProjectMember());

  const globalRoleMenuItems = Object.values(projectRoles).map(role => (
    <MenuItem key={role.id} value={role.id}>
      {getRoleDescription(role, languageContext)}
    </MenuItem>
  ))
  globalRoleMenuItems.push((
    <MenuItem key={notSpecified} value={notSpecified}>
      {languageContext.getMessage(notSpecified)}
    </MenuItem>
  ))

  const selectedProjectId = projectMemberContext.getSelectedProjectMember()?.projectId
  const projectMembers = projectMemberContext.getProjectMembers().filter(projectMember => projectMember.projectId === selectedProjectId && projectMember.memberAccess === MemberAccess.MEMBER);
  const allUsers = userRoleContext.getUsers().filter(user => projectMembers.findIndex(projectMember => projectMember.applicationProfileUserId === user.id) >= 0);

  const defaultSplitChar = ';;'
  const populateUserWithProprietarySearch = (user: User, matchingUserNames: string[], matchingUserEmails: string[]): User => {
    matchingUserNames.push(user.name ?? '');
    matchingUserEmails.push(user.email ?? '');
    user.proprietarySearch = `${defaultSplitChar}${(user.name ?? '').toLowerCase()}${defaultSplitChar}${(user.email ?? '').toLowerCase()}`;
    const dominantRoleFromUser = getDominantRoleFromUser(user, projectMemberContext);
    if (dominantRoleFromUser) {
      user.proprietarySearch = `${user.proprietarySearch}${defaultSplitChar}${getRoleDescription(dominantRoleFromUser, languageContext)}`;
    }
    return user;
  }

  const getUsers = (): [User[], string[]] => {
    if (userRoleContext.isLoadingUsers) {
      return [allUsers, []];
    }
    const userRoles: string[] = Object.values(projectRoles).map(role => getRoleDescription(role, languageContext));
    const matchingUserNames: string[] = [];
    const matchingUserEmails: string[] = [];
    const users = allUsers.map(user => populateUserWithProprietarySearch(user, matchingUserNames, matchingUserEmails));
    const searchValues = userRoles.concat(matchingUserNames).concat(matchingUserEmails);
    const uniqueSearchValues: string[] = [];
    searchValues.forEach(searchValue => {
      if (searchValue.trim().length > 0 && uniqueSearchValues.findIndex(uniqueSearchValue => searchValue === uniqueSearchValue) < 0) {
        uniqueSearchValues.push(searchValue);
      }
    });
    return [users, uniqueSearchValues];
  }

  const [search, setSearch] = React.useState<string | ProjectMember | null>(null);
  const [users, searchValues] = getUsers();

  const getFilteredUsers = (): User[] => {
    if (!search) return users;
    if (typeof search === "string") return search.trim().length === 0 ? users : users.filter(user => user?.proprietarySearch?.toLowerCase().includes(search.toLowerCase()));
    if (typeof search === "object") return users.filter(user => user.id === search.id);
    return [];
  }

  const filteredUsers = getFilteredUsers();

  const userHasOwnerAccess = checkProjectAccess(RoleType.OWNER, authContext.accountRoles(), selectedProjectId);
  const checkIfRoleIsPrivileged = (role: RoleType | undefined): boolean => {
    return role === RoleType.OWNER || role === RoleType.ADMIN;
  }

  if (!userHasOwnerAccess) {
    projectRoles = projectRoles.filter(role => !checkIfRoleIsPrivileged(getRoleType(role)));
  }

  const [editingAll, setEditingAll] = React.useState<boolean>(false);
  const [editingUsers, setEditingUsers] = React.useState<Dictionary<boolean>>({});
  const [updatingUserRoles, setUpdatingUserRoles] = React.useState<boolean>(false);

  const handleUpdateUser = async (user: User | undefined, handlingMultipleUsers?: boolean): Promise<void> => {
    handlingMultipleUsers = handlingMultipleUsers ?? false;
    if (user) {
      const userCopy = { ...user };
      if (!handlingMultipleUsers) {
        setUpdatingUserRoles(true);
      }
      userCopy.roles = (userCopy.roles ?? []).slice();
      userCopy.roles = userCopy.roles.filter(role => selectedProjectId && getProjectIdFromProjectRole(role) === selectedProjectId);
      if (!userHasOwnerAccess) {
        userCopy.roles = userCopy.roles.filter(role => !checkIfRoleIsPrivileged(getRoleType(role)));
      }
      await userRoleContext.updateUserRole(userCopy);
      if (!handlingMultipleUsers) {
        setUpdatingUserRoles(false);
      }
    }
  }

  const handleEditingAllButton = async () => {
    const userIds = Object.keys(editingUsers);
    setUpdatingUserRoles(true);
    for (let i = 0; i < userIds.length; i++) {
      if (editingAll && editingUsers[userIds[i]]) {
        const user = users.find(user => user.id === userIds[i]);
        await handleUpdateUser(user, true);
      }
      editingUsers[userIds[i]] = !editingAll;
    }
    setUpdatingUserRoles(false);
    setEditingUsers({ ...editingUsers });
    setEditingAll(!editingAll);
  }

  useEffect(() => {
    let editingUsersChanged = false;
    users.forEach(user => {
      if (!user.id) {
        return;
      }
      if (!(user.id in editingUsers)) {
        editingUsers[user.id] = false;
        editingUsersChanged = true;
      }
    })
    if (editingUsersChanged) {
      setEditingUsers({ ...editingUsers });
    }
  }, [editingUsers, users]);

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

  function Row(props: { rowUser: User }) {
    const { rowUser } = props;
    const classes = useRowStyles();

    if (!rowUser.id || !(rowUser.id in editingUsers)) {
      return <></>;
    }
    const editing = editingUsers[rowUser.id];

    const handleEditingButton = async () => {
      if (!rowUser.id) {
        return;
      }

      if (editing) {
        await handleUpdateUser(rowUser);
      }
      editingUsers[rowUser.id] = !editing;

      const defaultEditingState = editingUsers[rowUser.id];
      let allAreInSameEditState = true;
      Object.keys(editingUsers).forEach(userId => {
        if (editingUsers[userId] !== defaultEditingState) {
          allAreInSameEditState = false;
        }
      })
      if (allAreInSameEditState) {
        setEditingAll(defaultEditingState);
      }
      setEditingUsers({ ...editingUsers });
    };

    const userOwnsThisUserRow = authContext.accountInfo?.id === rowUser.id;
    const dominantRoleFromUser = getDominantRoleFromUser(rowUser, projectMemberContext);
    const projectRoleIsEditable = !userOwnsThisUserRow && (userHasOwnerAccess || (!userHasOwnerAccess && !checkIfRoleIsPrivileged(getRoleType(dominantRoleFromUser))));
    const userRowIsEditable = projectRoleIsEditable

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell align='left' width='10%'>
            <IconButton disabled={!userRowIsEditable || updatingUserRoles} aria-label="expand row" size="small" onClick={async () => await handleEditingButton()}>
              {(editing && userRowIsEditable) ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" width='30%'>
            {userOwnsThisUserRow ? `${rowUser.name} (${languageContext.getMessage('myself')})` : rowUser.name}
          </TableCell>
          <TableCell width='30%'>{rowUser.email}</TableCell>
          {(editing && projectRoleIsEditable) ?
            <TableCell width='30%'>
              <FormControl fullWidth>
                <Select
                  value={dominantRoleFromUser?.id ?? notSpecified}
                  input={<Input />}
                  onChange={(event) => {
                    if (event.target.value) {
                      updateUserRoles(rowUser, event.target.value as string, userRoleContext, projectMemberContext);
                      setEditingUsers({ ...editingUsers });
                    }
                  }}
                >
                  {globalRoleMenuItems}
                </Select>
              </FormControl>
            </TableCell>
            :
            <TableCell width='30%'>{getRoleDescription(dominantRoleFromUser, languageContext)}</TableCell>
          }
        </TableRow>
      </React.Fragment>
    );
  }

  const usersPerPage = 20;
  const [page, setPage] = useState(1);
  const maxPages = Math.ceil(filteredUsers.length / usersPerPage);

  if (page > maxPages && maxPages > 0) {
    setPage(maxPages);
  }
  else if (page <= 0) {
    setPage(1);
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const pagedFilteredUsers = page >= 1 ? filteredUsers.slice((page - 1) * usersPerPage, ((page - 1) * usersPerPage) + usersPerPage) : [];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete
          sx={{ mb: 2 }}
          freeSolo
          onInputChange={(e, value) => setSearch(value)}
          onChange={(e, value) => setSearch(value)}
          getOptionLabel={searchValue => searchValue ?? ''}
          options={searchValues}
          renderInput={(params) => <TextField {...params} label={languageContext.getMessage("search")} />}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2}
          justifyContent="center"
          alignItems="center">
          {userRoleContext.isLoadingUsers && <CircularProgress />}
          {!userRoleContext.isLoadingUsers && <TableContainer>
            <Table aria-label="collapsible table">
              <TableHeadStyled>
                <TableRow>
                  <TableCell>
                    <IconButton aria-label="expand row" size="small" disabled={updatingUserRoles} onClick={async () => await handleEditingAllButton()}>
                      {updatingUserRoles ? <CircularProgress size={25} /> : (editingAll ? <SaveIcon /> : <EditIcon />)}
                    </IconButton>
                  </TableCell>
                  <TableCell>{languageContext.getMessage('name')}</TableCell>
                  <TableCell >{languageContext.getMessage('email')}</TableCell>
                  <TableCell >{`${languageContext.getMessage('role')}`}</TableCell>
                </TableRow>
              </TableHeadStyled>
              <TableBody>
                {pagedFilteredUsers.map((user) => (
                  <Row key={user.id} rowUser={user} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
          {(maxPages ?? 0) > 1 &&
            <Pagination
              count={maxPages ?? 0}
              page={page ?? 0}
              onChange={handlePageChange}
              showFirstButton={false}
              showLastButton={false}
              siblingCount={0}
              boundaryCount={1} />}
        </Stack>
      </Grid>
    </Grid>);
}

const TableHeadStyled = styled(TableHead)`
  th {
    font-weight: 600;
  }
`

export default withTheme(AuthSettings)