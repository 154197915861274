import { ApplicationProfileContext } from "../contexts/applicationProfile/applicationProfileContext";
import { AuthContext } from "../contexts/auth/interfaces";
import { UserRoleContext } from "../contexts/userRole/userRoleContext";
import { ActiveState, ApplicationProfile, User } from "../contracts/contracts";

export const updateApplicationProfileAndUserDetails = async (originalApplicationProfile: ApplicationProfile | undefined, 
                                                             editedApplicationProfile: ApplicationProfile, 
                                                             applicationProfileContext: ApplicationProfileContext, 
                                                             userRoleContext: UserRoleContext, 
                                                             authContext: AuthContext, 
                                                             onFinished?: () => void): Promise<void> => {
    const mutatedApplicationProfile: ApplicationProfile = { ...editedApplicationProfile };
    mutatedApplicationProfile.state = mutatedApplicationProfile.state ?? ActiveState.ACTIVE;
    const applicationProfileId = await applicationProfileContext.mutateApplicationProfile(mutatedApplicationProfile);
    const userDetailsHasChanged = mutatedApplicationProfile.email !== originalApplicationProfile?.email || mutatedApplicationProfile.name !== originalApplicationProfile?.email;
    if (userDetailsHasChanged) {
        const user: User = {
            id: mutatedApplicationProfile.userId,
            email: mutatedApplicationProfile.email,
            name: mutatedApplicationProfile.name,
            updateUserIfExists: true,
        }
        await userRoleContext.updateUserRole(user);
    }
    const documentId = await applicationProfileContext.mutateApplicationProfile(mutatedApplicationProfile);
    if (documentId) {
        if (userDetailsHasChanged) {
            authContext.refreshToken();
        }
        if (onFinished) {
            onFinished();
        }
    }
}