import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "../auth/authContext";
import { AccountInfo } from "../auth/interfaces";

type UserState = {
    accountInfo: AccountInfo | undefined;
    avatarUrl: string;
}

interface UserContext {
    userState: UserState | undefined;
};

const UserContext = createContext<UserContext>(null as unknown as UserContext);

export const UserContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();

    const [userState, setUserState] = useState<UserState | undefined>(undefined)
    
    useEffect(() => {
        authContext.onTokenRefreshSubject.subscribe(accessToken => {
            const accountInfo = authContext.getAccountInfo(accessToken);
            setUserState({
                accountInfo: accountInfo,
                avatarUrl: ''
            });
        });
    }, [])

    const value = { 
        userState
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}

export const useUserContext = (): UserContext => {
    return useContext(UserContext);
}