import React, { useEffect, useRef, useState } from 'react';

// material ui staff
import { CircularProgress, Grid, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

// contexts
import { useLanguageContext } from '../../contexts/language/LanguageContext';


// views
import ConfirmDeleteDialog from '../confirmComponents/ConfirmDeleteDialog';
import ImageCard from '../common/ImageCard';
import AddImageDialog from '../common/AddImageDialog';
import { useUrlContext } from '../../contexts/url/urlContext';
import { useTicketContext } from '../../contexts/ticket/ticketContext';
import { useApplicationProfileContext } from '../../contexts/applicationProfile/applicationProfileContext';
import { useCertificateContext } from '../../contexts/certificate/certificateContext';
import { useStorageFileMutationsContext } from '../../contexts/storageFile/mutations/storageFileMutationsContext';
import { ActiveState, Certificate, CertificateType, DocumentType, RoleType } from '../../contracts/contracts';
import { getStorageFileFullKey, onUploadFiles, toBase64 } from '../../utils/fileTools';
import FabButton from '../generalComponents/FabButton';
import { useBrowserContext } from '../../contexts/browserContext/browserContext';
import { getDocumentRole } from '../../contexts/userRole/userRoleTools';
import { Guid } from '../../utils/common-types';
import { getCertificateHelperText, getCertificateListHelperText, getCertificatePlaceholderImage, getCertificateTitleOptions } from '../../utils/certificateTitleOptions';
import { useStorageFileContext } from '../../contexts/storageFile/storageFileContext';
import HseCardModal from '../hseCardComponents/HseCardModal';
import { SnackbarComponent } from '../toastComponent/SnackbarComponent';
import HseScannerInstruction from '../hseCardComponents/HseScannerInstruction';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import { guidIsNullOrEmpty } from '../../utils/guidTools';


type ItemType = {
    images: string[];
    title: string;
    id: string;
}

type Props = {
    dialogTitle: string;
    confirmRemoveDialogTitle: string;
    certificateType: CertificateType;
    certificatePictureSteps?: number;
    cardScannerEnabled?: boolean;
    placeholder?: {
        front: React.ReactNode | string;
        back: React.ReactNode | string;
    }
    frontSideRequired?: boolean;
    backSideRequired?: boolean;
    confirmationDialogContent?: {
        front?: {
            title: string;
            description: string;
            confirmButtonText: string;
            cancelButtonText: string;

        },
        back?: {
            title: string;
            description: string;
            confirmButtonText: string;
            cancelButtonText: string;
        }
    }
}

const CertificateListView: React.FC<Props> = ({
    dialogTitle,
    confirmRemoveDialogTitle,
    certificateType,
    certificatePictureSteps,
    cardScannerEnabled,
    placeholder,
    frontSideRequired,
    backSideRequired,
    confirmationDialogContent,
}) => {
    const numberOfCertificatePictureSteps = certificatePictureSteps ?? 2;

    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
    const [openAddImageDialog, setOpenAddImageDialog] = useState<boolean>(false);
    const [isSavingNewImage, setIsSavingNewImage] = useState<boolean>(false);
    const [certificatePictureStep, setCertificatePictureStep] = useState<number>(0);
    const itemIdToRemove = useRef<string | number>()
    const [certificateToEdit, setCertificateToEdit] = useState<Certificate | undefined>();
    const [base64ImagesToEdit, setBase64ImagesToEdit] = useState<(string | undefined)[]>([]);
    const [base64ImageFilenamesToEdit, setBase64ImageFilenamesToEdit] = useState<string[]>([]);
    const [showNoImageAddedDialog, setShowNoImageAddedDialog] = useState(false)
    const certificateTitleRef = useRef('')

    const urlContext = useUrlContext();
    const urlState = urlContext.getUrlState();
    const applicationProfileId = urlState?.applicationProfileId
    const projectMemberId = urlState?.projectMemberId
    const isOnProjectMember = !guidIsNullOrEmpty(urlState?.projectMemberId);
    cardScannerEnabled = cardScannerEnabled && !isOnProjectMember;

    const ticketContext = useTicketContext();
    const languageContext = useLanguageContext();
    const applicationProfileContext = useApplicationProfileContext();
    const certificateContext = useCertificateContext();
    const storageFileContext = useStorageFileContext();
    const storageFileMutationContext = useStorageFileMutationsContext();
    const browserContext = useBrowserContext();
    const projectMemberContext = useProjectMemberContext();

    const currentSelectedProjectMember = projectMemberContext.getSelectedProjectMember();
    const selectedProjectId = currentSelectedProjectMember?.projectId;
    const projectMemberSearch = projectMemberContext.getProjectMemberSearch();

    const applicationProfileSearch = applicationProfileContext.getApplicationProfileSearch();
    const projectMember = projectMemberContext.getProjectMember(projectMemberId)

    const applicationProfile = applicationProfileId
        ? applicationProfileContext.getApplicationProfile(applicationProfileId)
        : applicationProfileContext.getApplicationProfileForLoggedInUser();
    const certificates = certificateContext.getCertificates().filter(certificate => certificate.certificateType === certificateType &&
        certificate.applicationProfileId === applicationProfile?.id && 
        (guidIsNullOrEmpty(certificate.projectMemberId) || (certificate.projectMemberId === projectMemberId || certificate.projectMemberId === currentSelectedProjectMember?.id)));
    const certificatesIds = certificates.map(c => c.id).join('_') // this is for useEffect dependency to reduce re-rendering
    const currentApplicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser();

    const applicationProfilesLoading = applicationProfileContext.loadingApplicationProfiles;
    const certificatesLoading = certificateContext.loadingCertificates
    const isLoading = applicationProfilesLoading || certificatesLoading;

    const [certificateItemType, setCertificateItemType] = useState<CertificateType | undefined>();
    const [certificateItems, setCertificateItems] = useState<{
        id: string;
        isReadOnly: boolean;
        isProjectMemberCertificate: boolean;
        images: string[];
        imageFilenames: string[];
        title: string;
        overrideImages: string[] | undefined;
        overrideImageFilenames: string[] | undefined;
        placeHolderImages: string[];
        helperText: string | undefined;
        cardId?: string;
        cardNumber?: string;
        cardLink?: string;
        cardStatus?: boolean;
    }[]>([]);

    const [hseCardModalOpen, setHseCardModalOpen] = useState<boolean>(false);
    const [hseScannerInstructionsModalOpen, setHseScannerInstructionsModalOpen] = useState<boolean>(false);
    const handleHseScannerInstructionsOpen = () => {
        if (cardScannerEnabled) setHseScannerInstructionsModalOpen(true);
        else setOpenAddImageDialog(true);
    }
    const handleHseCardClose = () => {
        setHseCardModalOpen(false)
        setHseScannerInstructionsModalOpen(false)
    };

    const [hseCardNotification, setHseCardNotification] = useState<boolean>(false);
    const showNotification = () => setHseCardNotification(true);
    const closeNotification = () => setHseCardNotification(false);

    const isLoggedInApplicationProfile = currentApplicationProfile !== undefined && applicationProfile !== undefined &&
        currentApplicationProfile.id === applicationProfile.id;

    const hasProjectWriterAccess = projectMemberContext.hasProjectAccess(RoleType.WRITER, selectedProjectId);
    const isProjectWriter = applicationProfile !== undefined && projectMember !== undefined &&
        applicationProfile.id === projectMember?.applicationProfileId &&
        hasProjectWriterAccess;

    const isReadOnly = !isProjectWriter && !isLoggedInApplicationProfile;

    const updateCertificateItems = (overrideImages?: string[], overrideImageFilenames?: string[], editedCertificate?: Certificate) => {
        let copyCertificates = certificates.slice().filter(certificate => certificate.state === ActiveState.ACTIVE);
        const matchingIndex = copyCertificates.findIndex(certificate => certificate.id === editedCertificate?.id);
        if (editedCertificate?.id && matchingIndex < 0 && editedCertificate?.state === ActiveState.ACTIVE) {
            copyCertificates.push(editedCertificate);
        }
        else if (editedCertificate?.id && matchingIndex >= 0 && editedCertificate?.state === ActiveState.INACTIVE) {
            copyCertificates.splice(matchingIndex, 1);
        }

        const newCertificateItems = copyCertificates.map(certificate => {
            const isProjectMemberCertificate = !guidIsNullOrEmpty(certificate.projectMemberId);
            const noProjectMemberCertificateAccess = !isProjectMemberCertificate && !isLoggedInApplicationProfile;
            const readOnlyCertificate = isReadOnly || noProjectMemberCertificateAccess;
            const newCertificateItem = {
                id: certificate.id ?? '',
                isReadOnly: readOnlyCertificate,
                isProjectMemberCertificate: isProjectMemberCertificate,
                images: [] as string[],
                imageFilenames: [] as string[],
                title: certificate.title ?? '',
                overrideImages: editedCertificate?.id === certificate.id ? overrideImages : certificateItems.find(certificateItem => certificateItem.id === certificate.id)?.overrideImages,
                overrideImageFilenames: editedCertificate?.id === certificate.id ? overrideImageFilenames : certificateItems.find(certificateItem => certificateItem.id === certificate.id)?.overrideImageFilenames,
                placeHolderImages: [getCertificatePlaceholderImage(certificate, 'Front'), getCertificatePlaceholderImage(certificate, 'Back')],
                helperText: getCertificateHelperText(certificateType, 'FrontAndBack', languageContext),
                cardId: certificate.certificateId,
                cardNumber: certificate.certificateNumber,
                cardLink: certificate.certificateUrl,
                cardStatus: certificate.isActive,
            }

            return newCertificateItem;
        })

        setCertificateItems(newCertificateItems);
        setCertificateItemType(certificateType);
    }

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([
            DocumentType.APPLICATION_PROFILE,
            DocumentType.PROJECT_MEMBER,
            DocumentType.PROJECT]);

        if (projectMemberId && !projectMember && selectedProjectId && !projectMemberContext.loadingProjectMembers && hasProjectWriterAccess) {
            projectMemberContext.searchProjectMembers({ ...projectMemberSearch, id: projectMemberId, projectId: selectedProjectId });
        }
    }, [urlContext.currentLocation, selectedProjectId, projectMemberContext.loadingProjectMembers]);

    useEffect(() => {
        updateCertificateItems();
    }, [certificatesIds, certificateType])

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([DocumentType.APPLICATION_PROFILE]);
        applicationProfileContext.searchApplicationProfiles(applicationProfileSearch);
    }, [urlContext.currentLocation]);

    useEffect(() => {
        if (applicationProfile?.id) {
            ticketContext.setDocumentTypesToWatch([DocumentType.CERTIFICATE]);
            const certificateSearch = certificateContext.getCertificateSearch();
            certificateSearch.applicationProfileId = applicationProfile.id;
            certificateSearch.certificateType = certificateType;

            certificateContext.searchCertificates(certificateSearch);
        }
    }, [applicationProfile?.id, certificateType])

    useEffect(() => {
        if (openAddImageDialog) {
            setCertificatePictureStep(0);
        }
        else {
            setCertificatePictureStep(-1);
        }
    }, [openAddImageDialog])

    const saveCertificateImages = async (documentId: Guid, certificateToUpdate: Certificate, files: any[], filenames: string[]): Promise<void> => {
        const fileKey = getStorageFileFullKey(documentId);
        const certificatePictureEditRoles: string[] = [getDocumentRole(DocumentType.APPLICATION_PROFILE, applicationProfile?.id ?? '', RoleType.OWNER)];
        if (isProjectWriter) {
            certificatePictureEditRoles.push(getDocumentRole(DocumentType.PROJECT, projectMember?.projectId ?? '', RoleType.WRITER));
        }
        const certificatePictureReadRoles = [browserContext.getTenantRole()]
        setIsSavingNewImage(true);
        return onUploadFiles(storageFileContext, storageFileMutationContext, files, fileKey, certificatePictureEditRoles, certificatePictureReadRoles, (uploadedImages) => {
            if (uploadedImages.length > 0) {
                updateCertificateItems(uploadedImages, filenames, { ...certificateToUpdate, id: documentId });
            }
            setIsSavingNewImage(false);
        }, undefined, filenames).catch((error) => {
            console.log(error);
            setIsSavingNewImage(false)
        });
    }

    const confirmDialogContent = certificatePictureStep === 0 ? confirmationDialogContent?.front : confirmationDialogContent?.back

    const onSaveCertificate = async ({ file, filename, title, goBack, ignoreValidation }: { file: any, filename: string | undefined, title: string, goBack: boolean, ignoreValidation?: boolean }) => {
        let base64File: string | undefined = undefined;
        if (file && typeof file !== 'string') {
            base64File = await toBase64(file)
        }

        if (base64File) {
            base64ImagesToEdit[certificatePictureStep] = base64File;
            base64ImageFilenamesToEdit[certificatePictureStep] = filename ?? '';
        }

        certificateTitleRef.current = title

        // front side check
        if (!ignoreValidation && frontSideRequired && certificatePictureStep === 0 && (!base64ImagesToEdit[certificatePictureStep] && !file)) {
            setShowNoImageAddedDialog(true)
            return
        }

        // back side check
        if (!ignoreValidation && !goBack && backSideRequired && certificatePictureStep === 1 && (!base64ImagesToEdit[0] || (!base64ImagesToEdit[certificatePictureStep] && !file))) {
            setShowNoImageAddedDialog(true)
            return
        }

        const nextCertificatePictureStep = certificatePictureStep + (goBack ? -1 : 1);
        if (nextCertificatePictureStep < 0) {
            setOpenAddImageDialog(false);
            return;
        }

        if (nextCertificatePictureStep < numberOfCertificatePictureSteps) {
            setCertificateToEdit({ ...certificateToEdit, title: certificateTitleRef.current });
            setCertificatePictureStep(nextCertificatePictureStep >= 0 ? nextCertificatePictureStep : 0);
        }
        else {
            if (applicationProfile?.id) {
                const certificateToUpdate: Certificate = certificateToEdit?.id ? certificateToEdit : {
                    applicationProfileId: applicationProfile?.id,
                    projectMemberId: isProjectWriter && isOnProjectMember ? projectMember.id : undefined,
                    certificateType: certificateType,
                    title: certificateTitleRef.current,
                    state: ActiveState.ACTIVE,
                }
                certificateToUpdate.title = certificateTitleRef.current;

                const filesToUpload: string[] = [];
                const filenamesToUpload: string[] = [];
                base64ImagesToEdit.forEach((base64ImageToEdit, index) => {
                    if (base64ImageToEdit !== undefined) {
                        filesToUpload.push(base64ImageToEdit);
                        filenamesToUpload.push(base64ImageFilenamesToEdit[index] ?? '');
                    }
                })
                certificateToUpdate.numberOfCertificateFiles = filesToUpload.length;
                if (certificateToUpdate?.id) {
                    await certificateContext.mutateCertificate(certificateToUpdate);
                    await saveCertificateImages(certificateToUpdate.id, certificateToUpdate, filesToUpload, filenamesToUpload);
                }
                else {
                    if (certificateToUpdate.numberOfCertificateFiles > 0) {
                        const certificateId = await certificateContext.mutateCertificate(certificateToUpdate);
                        if (certificateId) {
                            await saveCertificateImages(certificateId, certificateToUpdate, filesToUpload, filenamesToUpload);
                        }
                    }
                }
                setOpenAddImageDialog(false);
            }
        }
    }

    const onRemoveCertificate = async ({ id }: { id: string }) => {
        const certificateToRemove = certificates.find(certificate => certificate.id === id);

        if (certificateToRemove) {
            certificateToRemove.state = ActiveState.INACTIVE;
            await certificateContext.mutateCertificate(certificateToRemove);
            updateCertificateItems(undefined, undefined, certificateToRemove);
        }

        setOpenConfirmDeleteDialog(false)
    }

    const onDeleteHandler = (id: number | string) => {
        itemIdToRemove.current = id
        setOpenConfirmDeleteDialog(true);
    }

    const onEditHandler = (id: number | string, base64Images: string[], base64Filenames: string[]) => {
        const matchingCertificate = certificates.find(certificate => certificate.id === id);
        if (matchingCertificate) {
            setCertificateToEdit(matchingCertificate);
            setBase64ImagesToEdit(base64Images.slice());
            setBase64ImageFilenamesToEdit(base64Filenames.slice());
            setOpenAddImageDialog(true);
        }
    }

    const onAddNewItemHandler = async (
        newItem: { file: any, 
        filename: string | undefined, 
        title: string, 
        goBack: boolean 
    }) => {
        await onSaveCertificate(newItem)
    }

    const onConfirmDialogCloseHandler = async (isDeleteConfirmed: boolean) => {
        if (isDeleteConfirmed) {
            const item = certificateItems.find(item => item.id === itemIdToRemove.current) as ItemType;

            onRemoveCertificate(item)
            return;
        }

        itemIdToRemove.current = '';
        setOpenConfirmDeleteDialog(false);
    }

    const dialogHelperText = getCertificateListHelperText(certificateType, languageContext, cardScannerEnabled);
    const AddIconByType = certificateItemType === CertificateType.HSE ? DocumentScannerIcon : AddIcon;

    const scannedCards = certificateItems.filter(cert => cert.cardNumber && cert.cardLink);

    return (
        <>
            <HseScannerInstruction open={hseScannerInstructionsModalOpen && !!cardScannerEnabled} onClose={() => {
                setHseScannerInstructionsModalOpen(false)
            }} openHseCardModal={() => setHseCardModalOpen(true)} />
            <HseCardModal
                open={hseCardModalOpen && !!cardScannerEnabled}
                close={handleHseCardClose}
                openAddImageDialog={() => setOpenAddImageDialog(true)}
                showNotification={showNotification}
            />
            <SnackbarComponent open={hseCardNotification} onClose={closeNotification}>{languageContext.getMessage('cardScanSuccessMessage')}</SnackbarComponent>
            {isLoading ? <Grid container justifyContent='center'><CircularProgress /></Grid> : (
                <>
                    {/* <List> */}
                    <Grid container spacing={2} sx={{ ml: '-7px' }}>
                        {!isReadOnly && dialogHelperText &&
                            <Grid item xs={12}>
                                <Typography sx={{ textAlign: 'center', fontWeight: 700, fontSize: '1.5rem' }}>
                                    {dialogHelperText}
                                </Typography>
                            </Grid>}
                        {certificateItems.map((item) => (
                            <Grid key={item.id} item xs={12} md={6}>
                                {/* <ListItem key={item.id}> */}
                                <ImageCard
                                    key={item.id}
                                    item={item}
                                    certificateType={certificateType}
                                    onDelete={onDeleteHandler}
                                    onEdit={onEditHandler}
                                    readonly={item.isReadOnly} />
                                {/* </ListItem> */}
                            </Grid>
                        ))}
                        {!isReadOnly &&
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center">
                                    <FabButton sx={{
                                        position: 'relative',
                                        marginBottom: 5,
                                        bottom: 0,
                                        right: 0,
                                        zIndex: 0,
                                        width: 150,
                                        height: 150,
                                        opacity: (scannedCards.length >= 1 && certificateItemType === CertificateType.HSE && cardScannerEnabled) ? 0.5 : 1,
                                        cursor: (scannedCards.length >= 1 && certificateItemType === CertificateType.HSE && cardScannerEnabled) ? "not-allowed" : "pointer"
                                    }} onClick={() => {
                                        setCertificateToEdit(undefined);
                                        setBase64ImagesToEdit([]);
                                        if (certificateType === CertificateType.HSE && (!scannedCards.length || !cardScannerEnabled)) handleHseScannerInstructionsOpen();
                                        if (certificateType === CertificateType.GENERAL) setOpenAddImageDialog(true);
                                    }} icon={<AddIconByType sx={{ fontSize: '4rem' }} />} label={languageContext.getMessage("add")} />
                                </Box>
                            </Grid>}
                    </Grid>
                    {/* </List> */}

                    {!isReadOnly && openAddImageDialog && (
                        <>
                            <AddImageDialog
                                open={openAddImageDialog}
                                onClose={() => setOpenAddImageDialog(false)}
                                onSave={onAddNewItemHandler}
                                title={dialogTitle}
                                readonly={isSavingNewImage}
                                imageStep={certificatePictureStep}
                                defaultImageTitle={certificateToEdit?.title || certificateTitleRef.current}
                                defaultImage={base64ImagesToEdit[certificatePictureStep]}
                                defaultImageFilename={base64ImageFilenamesToEdit[certificatePictureStep]}
                                imageTitleOptions={getCertificateTitleOptions(certificateItemType)}
                                saveButtonTitle={certificatePictureStep + 1 < numberOfCertificatePictureSteps ?
                                    languageContext.getMessage('next') : languageContext.getMessage('save')}
                                backButtonTitle={certificatePictureStep > 0 ?
                                    languageContext.getMessage('back') : languageContext.getMessage('cancel')}
                                helperText={getCertificateHelperText(certificateType, certificatePictureStep === 0 ? 'Front' : 'Back', languageContext)}
                                isSavingImages={isSavingNewImage}
                                placeholder={placeholder}
                            />



                        </>
                    )}


                    {!isReadOnly && openConfirmDeleteDialog && (
                        <ConfirmDeleteDialog
                            title={confirmRemoveDialogTitle}
                            open={openConfirmDeleteDialog}
                            onClose={onConfirmDialogCloseHandler}
                        />
                    )}
                </>
            )}
            {showNoImageAddedDialog && (
                <ConfirmationDialog
                    open={true}
                    onClose={async () => setShowNoImageAddedDialog(false)}
                    onConfirm={async () => {
                        if (certificatePictureStep === 0) {
                            setCertificatePictureStep(1)
                            setShowNoImageAddedDialog(false)
                            return
                        }

                        await onSaveCertificate({ file: null, filename: undefined, goBack: false, title: certificateTitleRef.current, ignoreValidation: true })

                    }}
                    title={confirmDialogContent?.title || ''}
                    description={confirmDialogContent?.description}
                    confirmButtonText={confirmDialogContent?.confirmButtonText} />
            )}
        </>
    );
}

export default CertificateListView;
