import React, { createContext } from "react";
import { KeycloakAuthContextProvider, useKeycloakAuthContext } from './providers/keycloak/keycloakAuthContext';
import { AuthContext, AuthProviderContext, AuthProvider } from './interfaces';

const CurrentAuthProviderContext = createContext<AuthProviderContext>(null as unknown as AuthProviderContext);

export const AuthContextProvider: React.FC<{}> = ({ children }) => {

    const currentAuthProvider = AuthProvider.KEYCLOAK;

    const getAuthProvider = (): AuthProvider => {
        return currentAuthProvider;
    };

    const authProviderContextValue: AuthProviderContext = {
        getAuthProvider,
    }

    const getAuthContextProvider = (children: React.ReactNode): JSX.Element => {
        return <KeycloakAuthContextProvider {...authProviderContextValue} children={children} />
    }
    
    return  <CurrentAuthProviderContext.Provider value={authProviderContextValue}>
                {getAuthContextProvider(children)}
            </CurrentAuthProviderContext.Provider>
}


export const useAuthContext = (): AuthContext => {
    const authContext = useKeycloakAuthContext();
    return authContext;
}