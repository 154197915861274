import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FC } from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";


type props = {
    open: boolean;
    onClose: () => Promise<void>;
    title: string;
    description?: string;
    onConfirm?: () => Promise<void>;
    cancelButtonText?: string;
    confirmButtonText?: string;
}
export const ConfirmationDialog: FC<props> = ({
    open,
    onClose,
    title,
    description,
    onConfirm,
    cancelButtonText,
    confirmButtonText,
}) => {
    const languageContext = useLanguageContext();

    const handleConfirm = async () => {
        if (onConfirm) {
            await onConfirm();
        }
        await onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={async () => await onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={async () => await onClose()}>{cancelButtonText || languageContext.getMessage("cancel")}</Button>
                {onConfirm && <Button onClick={async () => await handleConfirm()} autoFocus>
                    {confirmButtonText || languageContext.getMessage("confirm")}
                </Button>}
            </DialogActions>
        </Dialog>
    )
}