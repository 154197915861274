import React, { createContext, useContext, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client/react/hooks";
import {
  getClientExceptionGraphqlMutationOptions,
  MUTATE_CLIENT_EXCEPTION,
} from "./clientExceptionMutations";
import { useBrowserContext } from "../browserContext/browserContext";
import { checkIfTokenIsExpired, jwtAccessToken } from "../auth/authTools";

export interface ErrorHandlerContext {}

const ErrorHandlerContext = createContext<ErrorHandlerContext>(
  null as unknown as ErrorHandlerContext
);

type Props = {
  children?: React.ReactNode;
};

export const ErrorHandlerContextProvider: React.FC<Props> = ({ children }) => {
  const browserContext = useBrowserContext();
  const sendingClientExceptionRef = useRef<boolean>(false);
  const [clientExceptionMutation] = useMutation(MUTATE_CLIENT_EXCEPTION);

  const mutateClientException = (exception: string): void => {
    const accessToken = jwtAccessToken();
    const tokenIsExpired = checkIfTokenIsExpired(accessToken);
    if (
      sendingClientExceptionRef.current ||
      !exception ||
      !browserContext.isTenantNameAvailable() ||
      tokenIsExpired
    ) {
      return;
    }
    const url = window.location.href;
    const options = getClientExceptionGraphqlMutationOptions(url, exception);
    sendingClientExceptionRef.current = true;
    clientExceptionMutation(options)
      .catch((reason) => console.error("Failed to send client exception", reason))
      .finally(() => {
        sendingClientExceptionRef.current = false;
      });
  };

  const registerHandlers = (): void => {
    window.addEventListener("error", (event) => {
      console.log("error", event);
      mutateClientException(event.message);
    });

    window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
      console.log("event.reason.message", event);
      mutateClientException(event.reason.message);
    });
  };

  useEffect(() => {
    registerHandlers();
  }, []);

  const contextValue: ErrorHandlerContext = {};

  return (
    <ErrorHandlerContext.Provider value={contextValue}>{children}</ErrorHandlerContext.Provider>
  );
};

export const useErrorHandlerContext = (): ErrorHandlerContext => {
  return useContext(ErrorHandlerContext);
};
