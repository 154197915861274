import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';

// material UI staff    
import QrCodeIcon from '@mui/icons-material/QrCode';
import { dividerClasses, stepClasses, stepLabelClasses, Stepper, typographyClasses, Box, Typography, Button, Step, StepLabel, Divider, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

// contexts
import { useApplicationProfileContext } from '../../contexts/applicationProfile/applicationProfileContext';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { ProjectTabs, useProjectContext } from '../../contexts/project/projectContext';
import { useInformationContext } from '../../contexts/information/informationContext';
import { useTicketContext } from '../../contexts/ticket/ticketContext';
import { useUrlContext } from '../../contexts/url/urlContext';

import { DocumentType, ProjectMember, InformationAccess, MemberAccess, RoleType } from '../../contracts/contracts';
import { LanguageKey } from "../../contexts/language/interfaces";
import { Dictionary } from '../../global-types';

import PageContentLayout from '../../component/layouts/PageContentLayout';
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from '../../contexts/menu/menuContext';
import { useInformationVerificationContext } from '../../contexts/informationVerification/informationVerificationContext';
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import { guidIsNullOrEmpty } from '../../utils/guidTools';
import QrCodeDialog from '../../component/generalComponents/QrCodeDialog';
import { getNumberOfAgreedAndVerifiedQuizInformations, getMinimumNumberOfAgreedAndVerifiedQuizInformations, getQuizInformations } from '../../utils/informationTools';
import ReactMarkdown from 'react-markdown';
import { stringFormat } from '../../utils/stringTools';
import { checkProjectIdInProjectIdsToIgnore, getNewOnsiteProjectUrl } from '../../utils/projectTools';
import { checkProjectAccess } from '../../contexts/userRole/userRoleTools';
import { useAuthContext } from '../../contexts/auth/authContext';

enum JoinStepId {
    Register = "Register",
    Safety = "Safety"
}

type JoinStep = {
    languageKey: LanguageKey;
    id: JoinStepId,
}

const JoinSteps: JoinStep[] = [
    { languageKey: "registerInformation", id: JoinStepId.Register },
    { languageKey: "safetyInformation", id: JoinStepId.Safety },
]


const JoinView: React.FC<{}> = () => {

    const { params } = useRouteMatch<any>()
    const { projectId } = params

    const authContext = useAuthContext();
    const urlContext = useUrlContext();
    const menuContext = useMenuContext()
    const languageContext = useLanguageContext();

    const ticketContext = useTicketContext();
    const projectContext = useProjectContext();
    const applicationProfileContext = useApplicationProfileContext();
    const informationContext = useInformationContext();
    const informationVerificationContext = useInformationVerificationContext()
    const projectMemberContext = useProjectMemberContext()

    const [openQrCodeDialog, setOpenQrCodeDialog] = useState<boolean>(false);

    const applicationProfileSearch = applicationProfileContext.getApplicationProfileSearch();
    const projectSearch = projectContext.getProjectSearch();
    projectSearch.id = projectId
    const informationSearch = informationContext.getInformationSearch()
    informationSearch.projectId = projectId

    const applicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser();
    const project = projectContext.getProject(projectId);
    const informations = informationContext.getInformations().filter(safetyInfo => (safetyInfo.informationAccess === InformationAccess.PUBLIC || safetyInfo.informationAccess === InformationAccess.PUBLIC_FOR_MEMBERS) && safetyInfo.projectId === projectId && guidIsNullOrEmpty(safetyInfo.informationId));

    const informationVerifications = informationVerificationContext.getInformationVerifications().filter(infoVerification => infoVerification.projectId === projectId && infoVerification.applicationProfileId === applicationProfile?.id)
    const projectMember = projectMemberContext.getProjectMembers().find(projectMember => projectMember.projectId === projectId && projectMember.applicationProfileId === applicationProfile?.id)
    const projectMemberIsMemberOrHasRequestedAccess = projectMember?.memberAccess === MemberAccess.MEMBER || projectMember?.memberAccess === MemberAccess.REQUESTING_MEMBER_ACCESS;

    const projectMemberLoading = projectMemberContext.loadingProjectMembers
    const projectLoading = projectContext.loadingProjects;
    const applicationProfileLoading = applicationProfileContext.loadingApplicationProfiles
    const informationLoading = informationContext.loadingInformations
    const loadingInformationVerifications = informationVerificationContext.loadingInformationVerifications
    const isLoading = projectLoading || applicationProfileLoading || informationLoading || loadingInformationVerifications || projectMemberLoading

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([
            DocumentType.PROJECT, DocumentType.APPLICATION_PROFILE, DocumentType.INFORMATION, DocumentType.PROJECT_MEMBER]);

        projectContext.searchProjects(projectSearch);
        applicationProfileContext.searchApplicationProfiles(applicationProfileSearch);
        informationContext.searchInformations(informationSearch);
    }, [urlContext.currentLocation])

    useEffect(() => {
        if (applicationProfile?.id) {
            const projectMemberSearch = projectMemberContext.getProjectMemberSearch()
            projectMemberSearch.applicationProfileId = applicationProfile?.id

            projectMemberContext.searchProjectMembers(projectMemberSearch);
        }
    }, [applicationProfile?.id])

    useEffect(() => {
        if (projectId && applicationProfile?.id) {

            ticketContext.setDocumentTypesToWatch([
                DocumentType.INFORMATION_VERIFICATION
            ])

            const informationVerificationSearch = informationVerificationContext.getInformationVerificationSearch()
            informationVerificationSearch.projectId = projectId
            informationVerificationSearch.applicationProfileId = applicationProfile?.id
            informationVerificationContext.searchInformationVerifications(informationVerificationSearch, true)
        }
    }, [projectId, applicationProfile?.id])

    const ignoredProjectIdCheckHandled = useRef<string | undefined>(undefined);
    useEffect(() => {
        if (checkProjectAccess(RoleType.OWNER, authContext.accountRoles())) {
            return;
        }
        if (!projectId || projectId === ignoredProjectIdCheckHandled.current) {
            return;
        }
        if (checkProjectIdInProjectIdsToIgnore(projectId)) {
            window.location.href = getNewOnsiteProjectUrl(projectId);
        }
        ignoredProjectIdCheckHandled.current = projectId;
    }, [projectId])

    const updateUrl = (route: ApplicationRoute, urlState?: Dictionary<string | number | Date>) => {
        const urlQuery = urlState ? urlContext.buildUrlQuery(urlState) : '';

        urlContext.pushUrlQuery(urlQuery, route.route)
    }

    const activeStepIndex: number = getActiveStep(projectMember);

    const onBack = (): void => {
        const route = menuContext.getApplicationRouteById(ApplicationRouteId.Project);
        const urlState = {
            projectId,
            tab: ProjectTabs.details
        }

        updateUrl(route, urlState);
    }

    const onNext = (): void => {
        let route: ApplicationRoute
        let formattedRoute: ApplicationRoute

        const joinStep = JoinSteps[activeStepIndex]

        if (joinStep?.id === JoinStepId.Register) {
            route = menuContext.getApplicationRouteById(ApplicationRouteId.RegisterInformation);
            formattedRoute = { ...route, route: route.route.replace(':projectId', projectId) }
            updateUrl(formattedRoute);
        }
        else if (joinStep?.id === JoinStepId.Safety && projectMember?.memberAccess !== MemberAccess.DENIED_MEMBER_ACCESS) {
            route = menuContext.getApplicationRouteById(ApplicationRouteId.Information);
            formattedRoute = { ...route, route: route.route.replace(':projectId', projectId) }
            updateUrl(formattedRoute);
        }
        else {
            route = menuContext.getApplicationRouteById(ApplicationRouteId.Project);
            projectMemberContext.setSelectedProjectMember(projectId)
            updateUrl(route, { tab: 'details', projectId: projectId });
        }
    }

    const getStepContent = (currentStepIndex: number) => {
        const numberOfAgreedAndVerifiedQuizInformations = getNumberOfAgreedAndVerifiedQuizInformations(applicationProfile, informationVerifications, informations);
        const minNumberOfAgreedAndVerifiedQuizInformations = getMinimumNumberOfAgreedAndVerifiedQuizInformations(project, informations);
        const projectMemberFailedInformationAgreements = (projectMember?.noRequestingMemberAccessTries ?? 0) > 0 && numberOfAgreedAndVerifiedQuizInformations < minNumberOfAgreedAndVerifiedQuizInformations && !projectMemberIsMemberOrHasRequestedAccess;
        const maxNumberOfRequestingMemberAccessTriesActivated = (project?.maxNumberOfRequestingMemberAccessTries ?? 0) > 0;
        const numberOfProjectMemberRequestTriesLeft = (project?.maxNumberOfRequestingMemberAccessTries ?? 0) - (projectMember?.noRequestingMemberAccessTries ?? 0)
        const numberOfQuizInformations = getQuizInformations(informations).length;
        const quizActivated = numberOfQuizInformations > 0;

        const joinStep = JoinSteps[currentStepIndex]

        switch (joinStep?.id) {
            case JoinStepId.Register:
                return {
                    descriptionComponent: (
                        <>
                            <ReactMarkdown
                                children={stringFormat(languageContext.getMessage('goThroughSteps'), [project?.name ?? ''])}
                                components={{
                                    p: ({ children, ...props }) => <Typography>{children}</Typography>
                                }}
                            />
                            {quizActivated && <ReactMarkdown
                                children={stringFormat(languageContext.getMessage('goThroughStepsWithQuizInfo'), [minNumberOfAgreedAndVerifiedQuizInformations, numberOfQuizInformations])}
                                components={{
                                    p: ({ children, ...props }) => <Typography>{children}</Typography>
                                }}
                            />}
                        </>
                    ),
                    backButtonText: languageContext.getMessage('back'),
                    nextButtonText: languageContext.getMessage('register')
                }

            case JoinStepId.Safety:
                return {
                    descriptionComponent: (
                        <>
                            {(!quizActivated || (quizActivated && !projectMemberFailedInformationAgreements)) && <ReactMarkdown
                                children={languageContext.getMessage('informationHasBeenSavedInfo')}
                                components={{
                                    p: ({ children, ...props }) => <Typography>{children}</Typography>
                                }}
                            />}
                            {quizActivated && !projectMemberFailedInformationAgreements && <ReactMarkdown
                                children={stringFormat(languageContext.getMessage('goThroughStepsWithQuizInfo'), [
                                    minNumberOfAgreedAndVerifiedQuizInformations,
                                    numberOfQuizInformations])}
                                components={{
                                    p: ({ children, ...props }) => <Typography>{children}</Typography>
                                }}
                            />}
                            {quizActivated && projectMemberFailedInformationAgreements && <>
                                <ReactMarkdown
                                    children={stringFormat(languageContext.getMessage('projectMemberFailedTheSafetyInformationQuestions'), [
                                        numberOfAgreedAndVerifiedQuizInformations,
                                        numberOfQuizInformations,
                                        minNumberOfAgreedAndVerifiedQuizInformations,
                                        numberOfQuizInformations])}
                                    components={{
                                        p: ({ children, ...props }) => <Typography>{children}</Typography>
                                    }}
                                />
                                {maxNumberOfRequestingMemberAccessTriesActivated &&
                                    <Typography>
                                        {stringFormat(languageContext.getMessage('youHaveXTriesLeft'), [numberOfProjectMemberRequestTriesLeft])}
                                    </Typography>
                                }
                            </>}
                        </>
                    ),
                    backButtonText: languageContext.getMessage('back'),
                    nextButtonText: projectMember?.memberAccess === MemberAccess.DENIED_MEMBER_ACCESS ?
                        languageContext.getMessage('toTheProject') :
                        (projectMemberFailedInformationAgreements ? languageContext.getMessage('tryAgain') : languageContext.getMessage('next')),
                };

            default:
                return {
                    descriptionComponent: (
                        <>
                            <ReactMarkdown
                                children={projectMember?.memberAccess === MemberAccess.DENIED_MEMBER_ACCESS ?
                                    languageContext.getMessage('deniedAcccessToProject') :
                                    quizActivated ? stringFormat(languageContext.getMessage('informationHasBeenSeenInfoPartWithQuiz'), [
                                        numberOfAgreedAndVerifiedQuizInformations, 
                                        numberOfQuizInformations
                                    ]) : languageContext.getMessage('informationHasBeenSeenInfoPart')}
                                components={{
                                    p: ({ children, ...props }) => <Typography>{children}</Typography>
                                }}
                            />
                            {quizActivated && projectMemberFailedInformationAgreements && <>
                                <ReactMarkdown
                                    children={stringFormat(languageContext.getMessage('youHadXOutOfQuestionsCorrect'), [
                                        numberOfAgreedAndVerifiedQuizInformations, 
                                        numberOfQuizInformations,
                                        (maxNumberOfRequestingMemberAccessTriesActivated && numberOfProjectMemberRequestTriesLeft <= 0) ? 
                                        languageContext.getMessage('youHaveUsedAllOfYourQuizTries') : '.'
                                    ])}
                                    components={{
                                        p: ({ children, ...props }) => <Typography>{children}</Typography>
                                    }}
                                />
                            </>}
                            {<ReactMarkdown
                                children={projectMember?.memberAccess === MemberAccess.DENIED_MEMBER_ACCESS ?
                                    languageContext.getMessage('contactAdministrationForMoreInformation') :
                                    languageContext.getMessage('contactAdministrationToRegisterHmsCard')}
                                components={{
                                    p: ({ children, ...props }) => <Typography>{children}</Typography>
                                }}
                            />}
                        </>
                    ),
                    backButtonText: languageContext.getMessage('back'),
                    nextButtonText: languageContext.getMessage('toTheProject'),
                };
        }
    }

    const stepContent = getStepContent(activeStepIndex)

    const onStepClick = (step: JoinStep) => {
        let route: ApplicationRoute;
        let formattedRoute: ApplicationRoute;

        switch (step.id) {
            case JoinStepId.Register:
                route = menuContext.getApplicationRouteById(ApplicationRouteId.RegisterInformation);
                formattedRoute = { ...route, route: route.route.replace(':projectId', projectId) };
                updateUrl(formattedRoute);
                break;

            case JoinStepId.Safety:
                if ((projectMember?.organizationNumber ?? '').trim().length === 0) {
                    break;
                }
                route = menuContext.getApplicationRouteById(ApplicationRouteId.Information);
                formattedRoute = { ...route, route: route.route.replace(':projectId', projectId) };
                updateUrl(formattedRoute);
                break;

            default:
                break;
        }
    }

    const BottomNavigation = (
        <>
            <QrCodeDialog open={openQrCodeDialog} onClose={() => setOpenQrCodeDialog(false)} />
            <Button variant='contained' onClick={onBack}>{stepContent.backButtonText}</Button>
            <Button variant="contained" onClick={() => setOpenQrCodeDialog(true)}><QrCodeIcon /></Button>
            <Button variant='contained' onClick={onNext}>{stepContent.nextButtonText}</Button>
        </>
    )

    return (
        <PageContentLayout bottomNavigation={isLoading ? null : BottomNavigation}>
            {isLoading ? <Grid container justifyContent='center'><CircularProgress /></Grid> : (
                <>
                    <Typography variant="h5" style={{ marginTop: 10 }}>
                        {`${projectMemberIsMemberOrHasRequestedAccess ? languageContext.getMessage('welcomeProject') + ' ' : ''}${project?.name}`}
                    </Typography>

                    <RegisterDescriptionBox sx={{ marginTop: '20px', marginBottom: '20px', minHeight: '110px' }}>
                        {stepContent.descriptionComponent}
                    </RegisterDescriptionBox>

                    <Box>
                        <JoinStepper activeStep={activeStepIndex} orientation="vertical" connector={null}>
                            {
                                JoinSteps.map(step => (
                                    <Step key={step.id} id={step.id} onClick={() => onStepClick(step)}>
                                        <StepLabel>
                                            <Typography variant="caption">{languageContext.getMessage(step.languageKey)}</Typography>
                                            <Divider />
                                        </StepLabel>
                                    </Step>
                                ))
                            }
                        </JoinStepper>
                    </Box>
                </>
            )}

        </PageContentLayout>
    )
}

export default JoinView

const getActiveStep = (projectMember: ProjectMember | undefined): number => {
    const hasFilledRequiredInfo = !!projectMember?.organizationNumber;

    let joinStep: JoinStep;

    switch (true) {
        case hasFilledRequiredInfo && projectMember.memberAccess === MemberAccess.PUBLIC:
            joinStep = JoinSteps.find(step => step.id === JoinStepId.Safety) as JoinStep
            return JoinSteps.indexOf(joinStep);

        case hasFilledRequiredInfo:
            joinStep = JoinSteps.find(step => step.id === JoinStepId.Safety) as JoinStep
            return JoinSteps.indexOf(joinStep) + 1;

        default:
            joinStep = JoinSteps.find(step => step.id === JoinStepId.Register) as JoinStep
            return JoinSteps.indexOf(joinStep);
    }
}

const JoinStepper = styled(Stepper)(({ theme }) => ({
    [`.${stepLabelClasses.labelContainer}`]: {
        order: -1,
    },
    [`.${stepLabelClasses.label}`]: {
        display: 'flex',
        alignItems: 'center',
    },
    [`.${typographyClasses.root}`]: {
        fontSize: '1rem',
    },

    [`.${stepClasses.root}+.${stepClasses.root}`]: {
        marginTop: '10px',
    },

    [`.${dividerClasses.root}`]: {
        flexGrow: 1,
        marginLeft: 10,
        marginRight: 10,
    }
}))

const RegisterDescriptionBox = styled(Box)(({ theme }) => ({
    [`.${typographyClasses.root}`]: {
        fontSize: '0.8rem',

    },
    [`.${typographyClasses.root}+.${typographyClasses.root}`]: {
        marginTop: '10px',
    }
}))