import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { CertificateType } from "../../contracts/contracts";
import { useApplicationProfileContext } from "../../contexts/applicationProfile/applicationProfileContext";
import { HseCardScanner } from "./HseCardScanner";
import { useCertificateContext } from "../../contexts/certificate/certificateContext";
import LoadingWrapper from "../loadingComponents/loadingWrapper";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useQueryHSECardInfo } from "../../hooks/useQueryHSECardInfo";

type props = {
    open: boolean;
    close: () => void;
    openAddImageDialog: () => void;
    showNotification?: () => void;
}

const HseCardModal: FC<props> = ({ open, close, openAddImageDialog, showNotification }) => {
    const [isScanned, setScanned] = useState(false);
    const [scannedHSECardId, setScannedHSECardId] = useState<string | undefined>(undefined);
    const [scannedData, setScannedData] = useState<string>('');

    const languageContext = useLanguageContext()

    const [applicationProfileMutationLoading, setApplicationProfileMutationLoading] = useState(false);
    const [certificateMutationLoading, setCertificateMutationLoading] = useState(false);
    const [fetchedHSECardInfo, loadingHSECardInfo] = useQueryHSECardInfo(scannedHSECardId);
    
    const applicationProfileContext = useApplicationProfileContext(); 
    const currentApplicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser();

    const certificateContext = useCertificateContext();

    const onClose = () => {
        setScanned(false);
        close()
    }

    const handleScan = (data?: string) => {
        if (data && data.includes("https://www.hmskort.no/v/") && !scannedData && !isScanned && !applicationProfileMutationLoading && !certificateMutationLoading) {
            setScannedData(data);
            setScannedHSECardId(data.replace('https://www.hmskort.no/v/', ''));
        }
    };

    const onFailedScan = () => {
        onClose();
        openAddImageDialog();
    }

    const mutateApplicationProfileAsync = async () => {
        const documentId = await applicationProfileContext.mutateApplicationProfile({ 
            ...currentApplicationProfile, 
            organizationNumber: fetchedHSECardInfo.organizationNumber,
            hseCardNumber: fetchedHSECardInfo.cardNumber,
            applicationProfileCompanyName: fetchedHSECardInfo.companyName,
        })
        if (documentId) { 
            setApplicationProfileMutationLoading(false);
        }
    }

    const mutateCertificateAsync = async () => {
        const documentId = await certificateContext.mutateCertificate({
            applicationProfileId: currentApplicationProfile?.id,
            certificateType: CertificateType.HSE,
            certificateId: scannedHSECardId,
            certificateNumber: fetchedHSECardInfo.cardNumber,
            certificateStatus: fetchedHSECardInfo.status,
            isActive: fetchedHSECardInfo.isActive,
            isRevoked: fetchedHSECardInfo.isRevoked,
            certificateDescription: fetchedHSECardInfo.type,
            certificateUrl: scannedData
        })

        if (documentId) {
            setCertificateMutationLoading(false);
            setScannedData('');
        }
    }

    useEffect(() => {
        if (!isScanned && scannedData && fetchedHSECardInfo.cardNumber && !loadingHSECardInfo && currentApplicationProfile?.id && !applicationProfileMutationLoading && !certificateMutationLoading) {
            setApplicationProfileMutationLoading(true);
            setCertificateMutationLoading(true);
            mutateApplicationProfileAsync();
            mutateCertificateAsync();
            setScanned(true);
        }
    }, [isScanned, fetchedHSECardInfo, loadingHSECardInfo, applicationProfileMutationLoading, certificateMutationLoading, scannedData])

    useEffect(() => {
        if ((!applicationProfileMutationLoading || !certificateMutationLoading) && isScanned) {
            if (showNotification) showNotification();
            onClose();
            setApplicationProfileMutationLoading(false);
            setCertificateMutationLoading(false);
            setScannedData('');
        }
    }, [applicationProfileMutationLoading, certificateMutationLoading, isScanned])

    useEffect(() => {
        return () => {
            setScanned(false);
            setApplicationProfileMutationLoading(false);
            setCertificateMutationLoading(false);
            setScannedData('');
        }
    }, [])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle id="form-dialog-title">{languageContext.getMessage('hseCardScanner')}</DialogTitle>
            <DialogContent>
                <LoadingWrapper loading={applicationProfileMutationLoading || certificateMutationLoading}>
                    <HseCardScanner 
                        onResult={(result) => handleScan(result)}
                    />
                </LoadingWrapper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onFailedScan}>{languageContext.getMessage('failedToScan')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default HseCardModal;