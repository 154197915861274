import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { CompanyInfo } from "../../../contracts/contracts";

export const QUERY_COMPANY_INFO: DocumentNode = gql`
  query companyInfos($name: String, $organizationNumber: String) {
    companyInfos(name: $name, organizationNumber: $organizationNumber) {
      name
      organizationNumber
      notFound
    }
  }
`;

export const QUERY_HSE_CARD_INFO: DocumentNode = gql`
  query hseCardInfo($cardId: String, $cardQRCode: String) {
    hseCardInfo(cardId: $cardId, cardQRCode: $cardQRCode) {
      cardId
      companyName
      organizationNumber
      mainCompanyName
      mainOrganizationNumber
      cardNumber
      type
      status
      issueDate
      isActive
      isRevoked
      cardHolder {
        personId
        firstName
        middleName
        lastName
        fullName
      }
    }
  }
`;

export const QUERY_INFO_TECH_ACCESS_GROUPS: DocumentNode = gql`
  query infoTechAccessGroups(
      $infoTechApiKey: String!, 
      $infoTechProjectId: String!) {
    infoTechAccessGroups(
          infoTechApiKey: $infoTechApiKey, 
          infoTechProjectId: $infoTechProjectId) {
      accessGroupId
      accessGroupKind
      accessGroupName
    }
  }
`;

export const QUERY_INFO_TECH_PROJECTS: DocumentNode = gql`
  query infoTechProjects(
      $infoTechApiKey: String!) {
    infoTechProjects(
          infoTechApiKey: $infoTechApiKey) {
      projectId
      projectDescription
    }
  }
`;

export const getCompanyInfoGraphqlQueryOptions = (companyInfoSearch: CompanyInfo): QueryHookOptions => {
  const options: QueryHookOptions = {};
  options.variables = {};
  if (companyInfoSearch.name !== undefined) options.variables["name"] = companyInfoSearch.name;
  if (companyInfoSearch.organizationNumber !== undefined) options.variables["organizationNumber"] = companyInfoSearch.organizationNumber;
  return options;
};

export const getHSECardInfoQueryOptions = (cardId?: string, cardQRCode?: string): QueryHookOptions => {
  const options: QueryHookOptions = {};
  options.variables = {};
  if (cardId !== undefined) options.variables["cardId"] = cardId;
  if (cardQRCode !== undefined) options.variables["cardQRCode"] = cardQRCode;
  return options;
};

export const getInfoTechAccessGroupsQueryOptions = (infoTechApiKey: string, infoTechProjectId: string): QueryHookOptions => {
  const options: QueryHookOptions = {};
  options.variables = {
    "infoTechApiKey": infoTechApiKey,
    "infoTechProjectId": infoTechProjectId,
  };
  return options;
};

export const getInfoTechProjectsQueryOptions = (infoTechApiKey: string): QueryHookOptions => {
  const options: QueryHookOptions = {};
  options.variables = {
    "infoTechApiKey": infoTechApiKey,
  };
  return options;
};
