import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/react-hooks";
import { InfoTechAccessGroup } from "../contracts/contracts"
import { QUERY_INFO_TECH_ACCESS_GROUPS, getInfoTechAccessGroupsQueryOptions } from "../contexts/generalIntegrations/queries/generalIntegrationsQueries";

export const useQueryInfoTechAccessGroups = (infoTechApiKey?: string, infoTechProjectId?: string): [InfoTechAccessGroup[], boolean] => {
    const [lastQueriedProjectId, setLastQueriedProjectId] = useState<string | undefined>(undefined);
    const [fetchedInfoTechAccessGroups, setFetchedInfoTechAccessGroups] = useState<InfoTechAccessGroup[]>([]);
    const [queryInfoTechAccessGroups, queryInfoTechAccessGroupsResponse] = useLazyQuery(QUERY_INFO_TECH_ACCESS_GROUPS);

    useEffect(() => {
        if (queryInfoTechAccessGroupsResponse.loading) {
            return;
        }
        if (infoTechApiKey && infoTechProjectId && infoTechProjectId !== lastQueriedProjectId) {
            setLastQueriedProjectId(infoTechProjectId);
            const queryOptions = getInfoTechAccessGroupsQueryOptions(infoTechApiKey, infoTechProjectId);
            queryInfoTechAccessGroups(queryOptions);
        }
    }, [infoTechApiKey, infoTechProjectId, queryInfoTechAccessGroupsResponse.loading])

    useEffect(() => {
        if (queryInfoTechAccessGroupsResponse.data && queryInfoTechAccessGroupsResponse.data.infoTechAccessGroups) {
            const newFetchedInfoTechAccessGroups: InfoTechAccessGroup[] = queryInfoTechAccessGroupsResponse.data.infoTechAccessGroups;
            setFetchedInfoTechAccessGroups(newFetchedInfoTechAccessGroups.slice());
        }
    }, [queryInfoTechAccessGroupsResponse.data]);

    useEffect(() => {
        if (queryInfoTechAccessGroupsResponse.error) {
            console.error(queryInfoTechAccessGroupsResponse.error);
        }
    }, [queryInfoTechAccessGroupsResponse.error]);

    return [fetchedInfoTechAccessGroups, queryInfoTechAccessGroupsResponse.loading];
}