import React, { createContext, useContext, useEffect, useState } from "react";
import { ApplicationProfile, DocumentType, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface ApplicationProfileSubscriptionsContext {
    subscribedApplicationProfiles: Array<ApplicationProfile>,
}

const ApplicationProfileSubscriptionsContext = createContext<ApplicationProfileSubscriptionsContext>(null as unknown as ApplicationProfileSubscriptionsContext);

export const ApplicationProfileSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedApplicationProfiles, setSubscribedApplicationProfiles] = useState<Array<ApplicationProfile>>([]);

    const updateSubscribedApplicationProfiles = (stateDocuments: StateDocument[]) => {
        const subscribedApplicationProfiles = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.APPLICATION_PROFILE && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as ApplicationProfile);
        setSubscribedApplicationProfiles(subscribedApplicationProfiles);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedApplicationProfiles([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedApplicationProfiles(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const applicationProfileContext: ApplicationProfileSubscriptionsContext = {
        subscribedApplicationProfiles,
    };

    return (
        <ApplicationProfileSubscriptionsContext.Provider value={applicationProfileContext}>
            {children}
        </ApplicationProfileSubscriptionsContext.Provider>
    );
}

export const useApplicationProfileSubscriptionsContext = (): ApplicationProfileSubscriptionsContext => {
    return useContext(ApplicationProfileSubscriptionsContext);
}
