import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, Settings } from "../../contracts/contracts";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useUrlContext } from "../url/urlContext";
import { SettingsMutationsContextProvider, useSettingsMutationsContext } from "./mutations/settingsMutationsContext";
import { SettingsQueriesContextContext, useSettingsQueriesContext } from "./queries/settingsQueriesContext";
import { SettingsSubscriptionsContextProvider, useSettingsSubscriptionsContext } from "./subscriptions/settingsSubscriptionsContext";
import { Guid } from "../../utils/common-types";



export interface SettingsContext {
    getSettingsSearch: () => Settings,
    searchSettings: (settingsSearch: Settings) => void,
    settings: Settings[];
    getSettings: () => Settings,
    defaultMainCurrencyCode: string,
    mutateSettings: (settings: Settings) => Promise<Guid | undefined>,
    loadingSettings: boolean,
}

const SettingsContext = createContext<SettingsContext>(null as unknown as SettingsContext);

export const sortSettingsByDate = (a: Settings, b: Settings) => {
    if ((a.created ?? "") < (b.created ?? "")) { return -1; }
    if ((a.created ?? "") > (b.created ?? "")) { return 1; }
    return 0;
}

export enum SettingsTabs {
    all = "all",
    details = "details",
}

export const SettingsContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <SettingsMutationsContextProvider>
            <SettingsQueriesContextContext>
                <SettingsSubscriptionsContextProvider>
                    <SettingsSubContextProvider>
                        {children}
                    </SettingsSubContextProvider>
                </SettingsSubscriptionsContextProvider>
            </SettingsQueriesContextContext>
        </SettingsMutationsContextProvider>
    );
}

export const SettingsSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();

    const settingsMutationsContext = useSettingsMutationsContext();
    const settingsQueriesContext = useSettingsQueriesContext();
    const settingsSubscriptionsContext = useSettingsSubscriptionsContext();
    
    const [currentSettingsSearch, setCurrentSettingsSearch] = useState<Settings | undefined>(undefined);
    const [settings, setSettings] = useState<Settings[]>([]);
    const maxSettingsToFetchAtOnce = 100;

    const mergeSettings = (oldSettings: Array<Settings>, newSettings: Array<Settings>): Array<Settings> => {
        const updatedSettings = oldSettings.slice();
        if (!newSettings) {
            console.error(`Received undefined set of Settings: ${newSettings}`);
            return [];
        }
        newSettings.forEach(newSettings=> {
            newSettings.created = new Date(newSettings.created ?? 0);

            const index = updatedSettings.findIndex(settings => settings.id === newSettings.id);
            if (index >= 0) {
                if (newSettings.state === ActiveState.ACTIVE) {
                    updatedSettings[index] = newSettings;
                }
                else {
                    updatedSettings.splice(index, 1);
                }
            } else {
                if (newSettings.state === ActiveState.ACTIVE) {
                    updatedSettings.push(newSettings);
                }
            }
        });
        return updatedSettings.sort(sortSettingsByDate);
    }

    const getSettingsSearch = (): Settings => {
        const urlState = urlContext.getUrlState();
        return {
        }
    }

    const searchSettings = (settingsSearch: Settings): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(settingsSearch, currentSettingsSearch);
        matched = matched && settingsSearch?.id === currentSettingsSearch?.id;
        if (!matched) {
            settingsSearch.searchIndexStart = 0;
            settingsSearch.searchIndexStop = maxSettingsToFetchAtOnce;
            setCurrentSettingsSearch(settingsSearch);
            setSettings([]);
        }
    }

    const getSettings = (): Settings => {
        return settings.length > 0 ? settings[0] : {};
    }

    const mutateSettings = (newSettings: Settings) => {
        return new Promise<Guid | undefined>((resolve, reject) => settingsMutationsContext.mutateSettings(newSettings, (documentId, variables) => {
            newSettings = {...newSettings, ...variables};
            newSettings.id = documentId;
            setSettings(mergeSettings(settings, [newSettings]));
            resolve(documentId);
        }, (errors) => {
            reject(errors);
        }));
    }

    useEffect(() => {
        if (currentSettingsSearch) {
            settingsQueriesContext.querySettings(currentSettingsSearch);
        }
    }, [currentSettingsSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSettings([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setSettings(mergeSettings(settings, settingsQueriesContext.fetchedSettings));
        if (settingsQueriesContext.fetchedSettings.length >= maxSettingsToFetchAtOnce && currentSettingsSearch) {
            currentSettingsSearch.searchIndexStart = (currentSettingsSearch.searchIndexStart ?? 0) + settingsQueriesContext.fetchedSettings.length;
            currentSettingsSearch.searchIndexStop = currentSettingsSearch.searchIndexStart + maxSettingsToFetchAtOnce;
            setCurrentSettingsSearch({...currentSettingsSearch});
        }
    }, [settingsQueriesContext.fetchedSettings]);

    useEffect(() => {
        setSettings(mergeSettings(settings, settingsSubscriptionsContext.subscribedSettings));
    }, [settingsSubscriptionsContext.subscribedSettings]);

    const settingsContext = {
        getSettingsSearch,
        searchSettings,
        settings,
        getSettings,
        defaultMainCurrencyCode: 'EUR',
        mutateSettings,
        loadingSettings: settingsQueriesContext.queryResponse.loading,
    };

    return (
        <SettingsContext.Provider value={settingsContext}>
            {children}
        </SettingsContext.Provider>
    );
}

export const useSettingsContext = (): SettingsContext => {
    return useContext(SettingsContext);
}
