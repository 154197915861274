import { RoleType, User, UserRole, DocumentType } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { LanguageContext } from "../language/interfaces";
import { ProjectMemberContext } from "../projectMember/projectMemberContext";
import { UserRoleContext } from "./userRoleContext";

export const notSpecified = 'notSpecified'

export function getDocumentRole(documentType: DocumentType, documentId: Guid, roleType: RoleType): string {
  return `${documentType}-${documentId}-${roleType}`
}

export function checkProjectAccess(roleType: RoleType, accountRoles: string[], projectId?: Guid | undefined): boolean {
  return accountRoles.findIndex(role => {
    const roleProjectId = getProjectIdFromProjectRole({ name: role });
    const accountRoleType = getRoleType({ name: role });
    if (projectId && roleProjectId === projectId && accountRoleType && accountRoleType === roleType) {
      return true;
    }
    else if (!roleProjectId && accountRoleType && accountRoleType === roleType) {
      return true;
    }
    return false;
  }) >= 0;
}

export function checkRoleIsValidProjectRole(role: UserRole): boolean {
  if (!role.name || role.name.indexOf(`${DocumentType.PROJECT}-`) < 0) {
    return false;
  }
  return true;
}

export function getProjectIdFromProjectRole(role: UserRole): string | undefined {
  if (!role.name || !checkRoleIsValidProjectRole(role)) {
    return undefined;
  }
  let roleProjectId = role.name.slice(role.name.indexOf('-') + 1);
  roleProjectId = roleProjectId.slice(0, roleProjectId.lastIndexOf('-'));
  return roleProjectId;
}

export function getRoleType(role: UserRole | undefined): RoleType | undefined {
  if (!role?.name || (!checkRoleIsValidProjectRole(role) && role.name.indexOf(`-`) > 0)) {
    return undefined;
  }
  const roleType = role.name.slice(role.name.lastIndexOf('-') + 1);
  return Object.values(RoleType).find(roleTypeEnum => roleTypeEnum === roleType);
}

export function getRoleTypeIndex(roleType: RoleType | undefined): number {
  return Object.values(RoleType).findIndex(roleTypeEnum => roleTypeEnum === roleType);
}

export function getRoleDescription(role: UserRole | undefined, 
                                   languageContext: LanguageContext): string {
  if (!role) {
    return languageContext.getMessage(notSpecified);
  }
  const roleType = getRoleType(role);
  const roleTypeMessage = languageContext.getMessage(roleType as RoleType);
  return roleTypeMessage ?? '';
}

export function getRolesFromUser(user: User, projectMemberContext?: ProjectMemberContext): UserRole[] {
  const selectedProject = projectMemberContext ? projectMemberContext.getSelectedProjectMember() : undefined;
  const projectRoles = user.roles?.filter(role => {
    const roleProjectId = getProjectIdFromProjectRole(role);
    if (projectMemberContext === undefined && roleProjectId === undefined) {
      return true;
    }
    return projectMemberContext !== undefined && roleProjectId !== undefined && roleProjectId === selectedProject?.projectId;
  })
  return projectRoles ?? [];
}

export function getDominantRoleFromUser(user: User, projectMemberContext?: ProjectMemberContext): UserRole | undefined {
  const userRoles = getRolesFromUser(user, projectMemberContext).filter(role => role.delete !== true);
  let dominantRole: UserRole | undefined = undefined;
  const roleTypes = Object.values(RoleType);
  for (let i = 0; i < roleTypes.length; i++) {
    const newDominantRole = userRoles?.find(userRole => {
      const roleType = getRoleType(userRole);
      return roleType !== undefined && roleType === roleTypes[i];
    })
    if (newDominantRole !== undefined) {
      dominantRole = newDominantRole;
    }
  }
  return dominantRole;
}

export function updateUserRoles(user: User, roleId: string,
  userRoleContext: UserRoleContext,
  projectMemberContext?: ProjectMemberContext): void {
  user.roles = user.roles ?? [];
  let userRoles = getRolesFromUser(user, projectMemberContext);
  if (roleId === notSpecified) {
    userRoles.forEach(role => {
      role.delete = true;
    })
    return;
  }
  const roles = userRoleContext.getUserRoles(projectMemberContext ? (projectMemberContext.getSelectedProjectMember() ?? {}) : undefined);
  let newRole = roles.find(role => role.id === roleId);
  if (!newRole) {
    return;
  }
  let roleExistsOnUser = false;
  for (let i = 0; i < userRoles.length; i++) {
    if (userRoles[i].id === newRole) {
      userRoles[i].delete = false;
      roleExistsOnUser = true;
    }
  }
  if (!roleExistsOnUser) {
    user.roles.push({ ...newRole });
    userRoles = getRolesFromUser(user, projectMemberContext);
  }
  const roleTypes = Object.values(RoleType);
  const roleTypesToDelete: string[] = [];
  const roleType = getRoleType(newRole);
  for (let i = 0; i < roleTypes.length; i++) {
    if (roleType !== undefined && roleTypes[i] === roleType) {
      for (let j = i + 1; j < roleTypes.length; j++) {
        roleTypesToDelete.push(roleTypes[j]);
      }
      break;
    }
  }
  userRoles.forEach(role => {
    const userRoleType = getRoleType(role);
    if (userRoleType !== undefined && roleTypesToDelete.findIndex(roleTypeToDelete => roleTypeToDelete === userRoleType) > -1) {
      role.delete = true;
    }
  })
}