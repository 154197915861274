import React, { createContext, useContext, useEffect, useState } from "react";
import { ProjectMember } from "../../../contracts/contracts";
import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/react-hooks';
import { QUERY_PROJECT_MEMBER, getProjectMembersGraphqlQueryOptions } from './projectMemberQueries'
import { useAuthContext } from "../../auth/authContext";

export interface ProjectMemberQueriesContext {
    fetchedProjectMembers: Array<ProjectMember>,
    queryProjectMembers: (projectMember: ProjectMember) => void,
    queryResponse: LazyQueryResult<any, OperationVariables>,
}

const ProjectMemberQueriesContext = createContext<ProjectMemberQueriesContext>(null as unknown as ProjectMemberQueriesContext);

export const ProjectMemberQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedProjectMembers, setFetchedProjectMembers] = useState<Array<ProjectMember>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_PROJECT_MEMBER);

    const queryProjectMembers = (projectMember: ProjectMember): void => {
        const queryOptions = getProjectMembersGraphqlQueryOptions(projectMember);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.projectMembers) {
            const newFetchedProjectMembers: Array<ProjectMember> = queryResponse.data.projectMembers;
            setFetchedProjectMembers(newFetchedProjectMembers);
        }
    }, [queryResponse.data]);
    
    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedProjectMembers([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const projectMemberQueriesContext: ProjectMemberQueriesContext = {
        fetchedProjectMembers,
        queryProjectMembers,
        queryResponse,
    };

    return (
        <ProjectMemberQueriesContext.Provider value={projectMemberQueriesContext}>
            {children}
        </ProjectMemberQueriesContext.Provider>
    );
}

export const useProjectMemberQueriesContext = (): ProjectMemberQueriesContext => {
    return useContext(ProjectMemberQueriesContext);
}
