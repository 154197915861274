import { Grid, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useAuthContext } from "../../contexts/auth/authContext"
import { useLanguageContext } from "../../contexts/language/LanguageContext"
import { ApplicationProfile, Project, ProjectMember, RoleType } from "../../contracts/contracts"
import SearchCompanyInfo from "../companyInfoComponents/SearchCompanyInfo";
import SearchInfoTechAccessGroups, { getInfoTechSettingsRenderLabel } from "../infoTechComponents/SearchInfoTechAccessGroups";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext"
import HseCardNumberInputInstructions from "../hseCardComponents/HseCardNumberInputInstructions"
import { stringNotEmpty } from "../../utils/stringTools"

type Props = {
    applicationProfile: ApplicationProfile | undefined;
    onProfileChange?: (profile: ApplicationProfile) => void;
    projectMember?: ProjectMember | undefined;
    onProjectMemberChange?: (projectMember: ProjectMember) => void;
    readonly?: boolean;
    project?: Project | undefined;
}

const MyProfileInfo: React.FC<Props> = ({
    applicationProfile,
    onProfileChange,
    projectMember,
    onProjectMemberChange,
    readonly = false,
    project }) => {

    const languageContext = useLanguageContext();
    const authContext = useAuthContext();
    const projectMemberContext = useProjectMemberContext();

    const [editedApplicationProfile, setEditedApplicationProfile] = useState<ApplicationProfile>({});
    const [editedProjectMember, setEditedProjectMember] = useState<ProjectMember>({});
    const [openAsCheckingHSECardNumber, setOpenAsCheckingHSECardNumber] = useState<boolean>(false);
    const [hseCardNumberInputInstructionsHandled, setHseCardNumberInputInstructionsHandled] = useState<boolean>(false);
    const [hseCardNumberInputInstructionsModelOpen, setHseCardNumberInputInstructionsModelOpen] = useState<boolean>(false);
    const handlingNewApplicationProfile = editedApplicationProfile.id === undefined;
    const handlingNewProjectMember = editedProjectMember.id === undefined;
    const hseCardNumberNotSet = (project?.hseCardNumberFieldIsMandatoryOnApplicationProfile && (editedApplicationProfile.hseCardNumber ?? '').trim().length === 0) ?? false;
    const hseCardNumberSetAndNotVerified = (editedApplicationProfile.hseCardNumber ?? '').trim().length > 0 && !editedApplicationProfile.hseCardNumberVerified;
    const hseCardNumberNotSetOrNotVerified = hseCardNumberNotSet || !editedApplicationProfile.hseCardNumberVerified;

    useEffect(() => {
        const currentUserId = authContext.accountInfo?.id;
        setEditedApplicationProfile({ ...applicationProfile, userId: handlingNewApplicationProfile ? currentUserId : applicationProfile?.userId });
    }, [applicationProfile]);

    useEffect(() => {
        const currentApplicationProfileId = applicationProfile?.id;
        setEditedProjectMember({ ...projectMember, applicationProfileId: handlingNewProjectMember ? currentApplicationProfileId : projectMember?.applicationProfileId });
    }, [projectMember]);

    useEffect(() => {
        if (typeof onProfileChange === 'function') {
            onProfileChange(editedApplicationProfile);
        }
    }, [editedApplicationProfile])

    useEffect(() => {
        if (typeof onProjectMemberChange === 'function') {
            onProjectMemberChange(editedProjectMember);
        }
    }, [editedProjectMember])

    useEffect(() => {
        if (editedApplicationProfile.id &&
            hseCardNumberNotSetOrNotVerified &&
            !hseCardNumberInputInstructionsHandled) {
            if (hseCardNumberNotSet) {
                setHseCardNumberInputInstructionsModelOpen(true);
            }
            else {
                setOpenAsCheckingHSECardNumber(true);
            }
            setHseCardNumberInputInstructionsHandled(true);
        }
    }, [hseCardNumberInputInstructionsHandled, 
        hseCardNumberNotSetOrNotVerified, 
        project?.hseCardNumberFieldIsMandatoryOnApplicationProfile, 
        editedApplicationProfile.id, 
        hseCardNumberNotSet])

    const emailIsReadonly = true;
    return (
        <>
            <HseCardNumberInputInstructions
                open={hseCardNumberInputInstructionsModelOpen}
                openAsCheckingHSECardNumber={openAsCheckingHSECardNumber}
                onClose={(fetchedHSECardInfo) => {
                    if (fetchedHSECardInfo?.cardNumber !== undefined) {
                        const fetchedOrganizationNumberIsNotEmpty = stringNotEmpty(fetchedHSECardInfo.organizationNumber);
                        setEditedApplicationProfile({
                            ...editedApplicationProfile,
                            organizationNumber: fetchedOrganizationNumberIsNotEmpty ? fetchedHSECardInfo.organizationNumber : editedApplicationProfile.organizationNumber,
                            hseCardNumber: fetchedHSECardInfo.cardNumber,
                            applicationProfileCompanyName: fetchedOrganizationNumberIsNotEmpty ? fetchedHSECardInfo.companyName : editedApplicationProfile.applicationProfileCompanyName,
                            hseCardNumberVerified: fetchedHSECardInfo.cardNumber.trim().length > 0 && fetchedHSECardInfo.isActive && !fetchedHSECardInfo.isRevoked,
                        });
                    }
                    else if (fetchedHSECardInfo) {
                        setEditedApplicationProfile({
                            ...editedApplicationProfile,
                            hseCardNumberVerified: false,
                        });
                    }
                    if (openAsCheckingHSECardNumber) {
                        setOpenAsCheckingHSECardNumber(false);
                    }
                    else {
                        setHseCardNumberInputInstructionsModelOpen(false);
                    }
                }}
                hseCardNumber={editedApplicationProfile.hseCardNumber} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label={languageContext.getMessage('name')}
                        disabled={readonly}
                        variant="outlined"
                        error={!readonly && (editedApplicationProfile.name ?? '').trim().length === 0}
                        value={editedApplicationProfile.name ?? ''}
                        onChange={(event) => {
                            const newApplicationProfile = { ...editedApplicationProfile, name: event.target.value as string }
                            setEditedApplicationProfile(newApplicationProfile);
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={languageContext.getMessage('email')}
                        disabled={emailIsReadonly}
                        variant="outlined"
                        error={!emailIsReadonly && !(editedApplicationProfile.email ?? '').includes('@')}
                        value={editedApplicationProfile.email ?? ''}
                        onChange={(event) => {
                            const newApplicationProfile = { ...editedApplicationProfile, email: event.target.value as string }
                            setEditedApplicationProfile(newApplicationProfile);
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={languageContext.getMessage('phoneNumber')}
                        disabled={readonly}
                        variant="outlined"
                        value={editedApplicationProfile.phoneNumber ?? ''}
                        required={project?.phoneNumberFieldIsMandatoryOnApplicationProfile}
                        onChange={(event) => {
                            const newApplicationProfile = { ...editedApplicationProfile, phoneNumber: event.target.value as string }
                            setEditedApplicationProfile(newApplicationProfile);
                        }}
                        fullWidth
                        error={project?.phoneNumberFieldIsMandatoryOnApplicationProfile && (editedApplicationProfile.phoneNumber ?? '').trim().length === 0}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SearchCompanyInfo
                        disabled={readonly && !projectMember}
                        companyInfoSearch={{ organizationNumber: editedProjectMember?.organizationNumber ?? editedApplicationProfile.organizationNumber }}
                        onSelectedCompanyInfoChange={(companyInfo) => {
                            if (projectMember) {
                                const newProjectMember = { ...editedProjectMember, organizationNumber: companyInfo?.organizationNumber ?? '' }
                                setEditedProjectMember(newProjectMember);
                                return;
                            }
                            const newApplicationProfile = { ...editedApplicationProfile, organizationNumber: companyInfo?.organizationNumber ?? '' }
                            setEditedApplicationProfile(newApplicationProfile);
                        }}
                        textFieldProps={{
                            fullWidth: true,
                        }}
                        label={languageContext.getMessage('employer')}
                    />
                </Grid>
                {projectMember && project?.parentProjectCompanyActivated && <Grid item xs={12}>
                    <SearchCompanyInfo
                        disabled={readonly && !projectMember}
                        companyInfoSearch={{ organizationNumber: editedProjectMember?.parentOrganizationNumber }}
                        onSelectedCompanyInfoChange={(companyInfo) => {
                            if (projectMember) {
                                const newProjectMember = { ...editedProjectMember, parentOrganizationNumber: companyInfo?.organizationNumber ?? '' }
                                setEditedProjectMember(newProjectMember);
                                return;
                            }
                        }}
                        textFieldProps={{
                            fullWidth: true,
                        }}
                        label={languageContext.getMessage('yourEmployerWorksOnBehalfOf')}
                    />
                </Grid>}
                {projectMember && project?.infoTechSettings?.enabled && projectMemberContext.hasProjectAccess(RoleType.WRITER, project?.id) && <Grid item xs={12}>
                    <SearchInfoTechAccessGroups
                        infoTechApiKey={project?.infoTechSettings?.infoTechApiKey}
                        infoTechProjectId={project?.infoTechSettings?.infoTechProjectId}
                        infoTechSettings={editedProjectMember.infoTechSettings}
                        onSelectedInfoTechSettingsChange={(newSelectedInfoTechSettings) => {
                            setEditedProjectMember({
                                ...editedProjectMember,
                                infoTechSettings: newSelectedInfoTechSettings,
                            });
                        }}
                    />
                </Grid>}
                {projectMember && project?.infoTechSettings?.enabled && <Grid item xs={12}>
                    <TextField
                        label={languageContext.getMessage('registeredAccessGroup')}
                        variant='outlined'
                        disabled={true}
                        value={projectMember.lastRegisteredInfoTechSettings?.enabled ? getInfoTechSettingsRenderLabel({
                            accessGroupId: projectMember.lastRegisteredInfoTechSettings?.infoTechAccessGroupId,
                            accessGroupName: projectMember.lastRegisteredInfoTechSettings?.infoTechAccessGroupName,
                        }) : ''}
                        onChange={(event) => {
                            editedApplicationProfile.hseCardNumber = event.target.value as string;
                            const newApplicationProfile = { ...editedApplicationProfile }
                            setEditedApplicationProfile(newApplicationProfile);
                        }}
                        fullWidth
                    />
                </Grid>}
                <Grid item xs={12}>
                    <TextField
                        label={`${languageContext.getMessage('hseCardNumber')}${openAsCheckingHSECardNumber ? ' - ' + languageContext.getMessage('verifying') : hseCardNumberSetAndNotVerified ? ' - ' + languageContext.getMessage('invalid') : ''}`}
                        variant="outlined"
                        disabled={readonly || openAsCheckingHSECardNumber}
                        value={editedApplicationProfile.hseCardNumber ?? ''}
                        required={project?.hseCardNumberFieldIsMandatoryOnApplicationProfile}
                        onClick={() => {
                            setOpenAsCheckingHSECardNumber(false);
                            setHseCardNumberInputInstructionsModelOpen(true)
                        }}
                        onChange={() => {
                            setOpenAsCheckingHSECardNumber(false);
                            setHseCardNumberInputInstructionsModelOpen(true)
                        }}
                        fullWidth
                        error={hseCardNumberNotSetOrNotVerified && project?.hseCardNumberFieldIsMandatoryOnApplicationProfile}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={languageContext.getMessage('relativeName')}
                        variant="outlined"
                        disabled={readonly}
                        value={(editedApplicationProfile.relatives ?? []).length > 0 ? (editedApplicationProfile.relatives ?? [])[0].name : ''}
                        required={project?.relativesFieldIsMandatoryOnApplicationProfile}
                        onChange={(event) => {
                            editedApplicationProfile.relatives = editedApplicationProfile.relatives ?? [];
                            if (editedApplicationProfile.relatives.length === 0) {
                                editedApplicationProfile.relatives.push({})
                            }
                            editedApplicationProfile.relatives[0].name = event.target.value as string;
                            const newApplicationProfile = { ...editedApplicationProfile }
                            setEditedApplicationProfile(newApplicationProfile);
                        }}
                        fullWidth
                        error={project?.relativesFieldIsMandatoryOnApplicationProfile && ((editedApplicationProfile.relatives?.length ?? 0) === 0 || (editedApplicationProfile.relatives && (editedApplicationProfile.relatives[0].name?.trim().length ?? '') === 0))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={languageContext.getMessage('relativePhoneNumber')}
                        variant="outlined"
                        disabled={readonly}
                        value={(editedApplicationProfile.relatives ?? []).length > 0 ? (editedApplicationProfile.relatives ?? [])[0].phoneNumber : ''}
                        onChange={(event) => {
                            editedApplicationProfile.relatives = editedApplicationProfile.relatives ?? [];
                            if (editedApplicationProfile.relatives.length === 0) {
                                editedApplicationProfile.relatives.push({})
                            }
                            editedApplicationProfile.relatives[0].phoneNumber = event.target.value as string;
                            const newApplicationProfile = { ...editedApplicationProfile }
                            setEditedApplicationProfile(newApplicationProfile);
                        }}
                        required={project?.relativesFieldIsMandatoryOnApplicationProfile}
                        error={project?.relativesFieldIsMandatoryOnApplicationProfile && ((editedApplicationProfile.relatives?.length ?? 0) === 0 || (editedApplicationProfile.relatives && (editedApplicationProfile.relatives[0].phoneNumber?.trim().length ?? '') === 0))}
                        fullWidth
                    />
                </Grid>
                {/* <Grid item xs={12}>
                <ChipInput
                    label={languageContext.getMessage('language')}
                    helperText={languageContext.getMessage('hitEnter')}
                    variant="outlined"
                    disabled={readonly}
                    value={editedApplicationProfile.languages ?? []}
                    onAdd={(languageChip) => {
                        editedApplicationProfile.languages = editedApplicationProfile.languages ?? [];
                        editedApplicationProfile.languages.push(languageChip);
                        const newApplicationProfile = { ...editedApplicationProfile }
                        setEditedApplicationProfile(newApplicationProfile);
                    }}
                    onDelete={(languageChip, index) => {
                        editedApplicationProfile.languages = editedApplicationProfile.languages ?? [];
                        if (editedApplicationProfile.languages.length > index) {
                            editedApplicationProfile.languages.splice(index, 1);
                            const newApplicationProfile = { ...editedApplicationProfile }
                            setEditedApplicationProfile(newApplicationProfile);
                        }
                    }}
                    fullWidth
                />
            </Grid> */}
            </Grid>
        </>
    )
}

export default MyProfileInfo