import { CssBaseline } from '@mui/material';
import ReactDOM from 'react-dom';
import App from './App';
import { LanguageProvider } from './contexts/language/LanguageContext';
import { UserContextProvider } from './contexts/user/UserContext';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { MenuProvider } from './contexts/menu/menuContext';
import { TicketContextProvider } from './contexts/ticket/ticketContext';
import { DateFnsProvider } from './contexts/dateFns/DateFnsProvider';
import { UrlContextProvider } from './contexts/url/urlContext';
import { ThemeContextProvider } from './contexts/theme/ThemeContext';
import { MaterialTableLanguageContextProvider } from './contexts/language/MaterialTableLanguageContext';
import { AuthContextProvider } from './contexts/auth/authContext';
import { UserRoleContextProvider } from './contexts/userRole/userRoleContext';
import { GraphqlContextProvider } from './contexts/graphql/graphqlContext';
import { StateDocumentQueriesContextProvider } from './contexts/stateDocument/queries/stateDocumentQueriesContext';
import React from 'react';
import { WidthContextProvider } from './contexts/WidthContext';
import { BrowserContextProvider } from './contexts/browserContext/browserContext';
import { TemplateEngineQueriesContextProvider } from './contexts/templateEngine/queries/templateEngineQueriesContext';
import { StorageDocumentQueriesContextProvider } from './contexts/storageDocumentQueries/Queries/storageDocumentQueriesContext';
import { StorageFileContextProvider } from './contexts/storageFile/storageFileContext';
import { StateDocumentEventsQueriesContextProvider } from './contexts/StateDocumentEvents/queries/stateDocumentEventsQueriesContext';
import { ProjectContextProvider } from './contexts/project/projectContext';
import { NewsFeedContextProvider } from "./contexts/newsFeed/newsFeedContext";
import { AnalyticsContextProvider } from './contexts/analytics/analyticsContext';
import { SettingsContextProvider } from './contexts/settings/settingsContext';
import { ApplicationProfileContextProvider } from './contexts/applicationProfile/applicationProfileContext';
import { InformationContextProvider } from "./contexts/information/informationContext";
import { ProjectCompanyContextProvider } from './contexts/projectCompany/projectCompanyContext';
import { ProjectMemberContextProvider } from './contexts/projectMember/projectMemberContext';
import { InformationVerificationContextProvider } from './contexts/informationVerification/informationVerificationContext';
import { CertificateContextProvider } from './contexts/certificate/certificateContext';
import { CompanyInfoQueriesContextProvider } from './contexts/generalIntegrations/queries/companyInfoQueriesContext';
import { ErrorHandlerContextProvider } from './contexts/errorHandler/errorHandlerContext';
import { TracingHandlerContextProvider } from './contexts/tracingHandler/tracingHandlerContext';

ReactDOM.render(
    <>
        <BrowserContextProvider>
            <CssBaseline />
            <UrlContextProvider>
                <AuthContextProvider>
                    <UserContextProvider>
                        <GraphqlContextProvider>
                            <ErrorHandlerContextProvider>
                                <TracingHandlerContextProvider>
                                    <ThemeContextProvider>
                                        <WidthContextProvider>
                                            <LanguageProvider>
                                                <MaterialTableLanguageContextProvider>
                                                    <DateFnsProvider>
                                                        <TemplateEngineQueriesContextProvider>
                                                            <CompanyInfoQueriesContextProvider>
                                                                <StorageFileContextProvider>
                                                                    <StorageDocumentQueriesContextProvider>
                                                                        <StateDocumentQueriesContextProvider>
                                                                            <TicketContextProvider>
                                                                                <StateDocumentEventsQueriesContextProvider>
                                                                                    <UserRoleContextProvider>
                                                                                        <SettingsContextProvider>
                                                                                            <ApplicationProfileContextProvider>
                                                                                                <ProjectCompanyContextProvider>
                                                                                                    <ProjectContextProvider>
                                                                                                        <ProjectMemberContextProvider>
                                                                                                            <NewsFeedContextProvider>
                                                                                                                <CertificateContextProvider>
                                                                                                                    <InformationContextProvider>
                                                                                                                        <InformationVerificationContextProvider>
                                                                                                                            <AnalyticsContextProvider>
                                                                                                                                <MenuProvider>
                                                                                                                                    <App />
                                                                                                                                </MenuProvider>
                                                                                                                            </AnalyticsContextProvider>
                                                                                                                        </InformationVerificationContextProvider>
                                                                                                                    </InformationContextProvider>
                                                                                                                </CertificateContextProvider>
                                                                                                            </NewsFeedContextProvider>
                                                                                                        </ProjectMemberContextProvider>
                                                                                                    </ProjectContextProvider>
                                                                                                </ProjectCompanyContextProvider>
                                                                                            </ApplicationProfileContextProvider>
                                                                                        </SettingsContextProvider>
                                                                                    </UserRoleContextProvider>
                                                                                </StateDocumentEventsQueriesContextProvider>
                                                                            </TicketContextProvider>
                                                                        </StateDocumentQueriesContextProvider>
                                                                    </StorageDocumentQueriesContextProvider>
                                                                </StorageFileContextProvider>
                                                            </CompanyInfoQueriesContextProvider>
                                                        </TemplateEngineQueriesContextProvider>
                                                    </DateFnsProvider>
                                                </MaterialTableLanguageContextProvider>
                                            </LanguageProvider>
                                        </WidthContextProvider>
                                    </ThemeContextProvider>
                                </TracingHandlerContextProvider>
                            </ErrorHandlerContextProvider>
                        </GraphqlContextProvider>
                    </UserContextProvider>
                </AuthContextProvider>
            </UrlContextProvider>
        </BrowserContextProvider>
    </>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
