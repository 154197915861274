import React, { ReactNode } from "react";
import { useTheme } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import { SxProps } from "@mui/system";
import Zoom from '@mui/material/Zoom';

type props = {
	onClick: () => void,
	icon?: ReactNode,
	sx?: SxProps,
	label?: string,
}

const style: SxProps = {
	position: 'absolute',
	bottom: 80,
	right: 16,
	zIndex: 2,
};

const FabButton: React.FC<props> = ({ onClick, icon, label, sx }) => {
	const theme = useTheme();
	const transitionDuration = {
		enter: theme.transitions.duration.enteringScreen,
		exit: theme.transitions.duration.leavingScreen,
	};

	return (
		<Zoom
			in
			timeout={transitionDuration}
			style={{
				transitionDelay: `${transitionDuration.exit}ms`,
			}}
			unmountOnExit
		>
			<Fab sx={{ ...style, ...sx }} aria-label={label} color="primary" onClick={onClick}>
				{icon}
			</Fab>
		</Zoom>
	);
}

export default FabButton;
