import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, ProjectCompany } from "../../../contracts/contracts";
import { cleanObject } from "../../../utils/objectTools";


export const MUTATE_PROJECT_COMPANY: DocumentNode = gql(`
    mutation mutateProjectCompany(
        $id: UUID
        $state: ActiveState 	
        $projectId: UUID!
        $parentProjectCompanyId: UUID
        $projectCompanyGroup: String
        $name: String
      	$organizationNumber: String
        $projectCompanyDescription: String
        $projectCompanyContactName: String
        $projectCompanyContactEmail: String
        $projectCompanyContactPhoneNumber: String
        $infoTechSettings: InfoTechSettingsInput
    ) {
        mutateProjectCompany(
            id: $id
            state: $state
            projectId: $projectId
            parentProjectCompanyId: $parentProjectCompanyId
            projectCompanyGroup: $projectCompanyGroup
            name: $name
            organizationNumber: $organizationNumber
            projectCompanyDescription: $projectCompanyDescription
            projectCompanyContactName: $projectCompanyContactName
            projectCompanyContactEmail: $projectCompanyContactEmail
            projectCompanyContactPhoneNumber: $projectCompanyContactPhoneNumber
            infoTechSettings: $infoTechSettings
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`);

export const getProjectCompaniesGraphqlMutationOptions = (projectCompany: Partial<ProjectCompany>): QueryHookOptions => {
    projectCompany.state = projectCompany.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (projectCompany.id !== undefined && projectCompany.id !== null) options.variables['id'] = projectCompany.id;
    if (projectCompany.state !== undefined && projectCompany.state !== null) options.variables['state'] = projectCompany.state;
    if (projectCompany.projectId !== undefined && projectCompany.projectId !== null) options.variables['projectId'] = projectCompany.projectId;
    if (projectCompany.parentProjectCompanyId !== undefined && projectCompany.parentProjectCompanyId !== null) options.variables['parentProjectCompanyId'] = projectCompany.parentProjectCompanyId;
    if (projectCompany.projectCompanyGroup !== undefined && projectCompany.projectCompanyGroup !== null) options.variables['projectCompanyGroup'] = projectCompany.projectCompanyGroup;
    if (projectCompany.name !== undefined && projectCompany.name !== null) options.variables['name'] = projectCompany.name;
    if (projectCompany.organizationNumber !== undefined && projectCompany.organizationNumber !== null) options.variables['organizationNumber'] = projectCompany.organizationNumber;
    if (projectCompany.projectCompanyDescription !== undefined && projectCompany.projectCompanyDescription !== null) options.variables['projectCompanyDescription'] = projectCompany.projectCompanyDescription;
    if (projectCompany.projectCompanyContactName !== undefined && projectCompany.projectCompanyContactName !== null) options.variables['projectCompanyContactName'] = projectCompany.projectCompanyContactName;
    if (projectCompany.projectCompanyContactEmail !== undefined && projectCompany.projectCompanyContactEmail !== null) options.variables['projectCompanyContactEmail'] = projectCompany.projectCompanyContactEmail;
    if (projectCompany.projectCompanyContactPhoneNumber !== undefined && projectCompany.projectCompanyContactPhoneNumber !== null) options.variables['projectCompanyContactPhoneNumber'] = projectCompany.projectCompanyContactPhoneNumber;
    if (projectCompany.infoTechSettings !== undefined && projectCompany.infoTechSettings !== null) options.variables['infoTechSettings'] = cleanObject(projectCompany.infoTechSettings);
    return options;
}
