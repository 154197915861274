import TextField from '@mui/material/TextField';
import React from 'react';
import NumberFormat from 'react-number-format';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(
    function NumberFormatCustom(props, ref) {
      const { onChange, ...other } = props;
  
      return (
        <NumberFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
              onChange({
                  target: {
                      name: "percentageValue",
                      value: values.value,
                  },
              });
          }}
          thousandSeparator={'.'} 
          isNumericString={true}
          allowNegative={false}
          fixedDecimalScale={true}
          decimalScale={2}
          decimalSeparator={','}
          prefix={`${props.name} `}
        />
      );
    },
);

type props = {
    label: string;
    percentage: number;
    onChange: (percentage: number) => void;
    readOnly?: boolean;
    fullWidth?: boolean;
}

const PercentageInput: React.FC<props> = ({
    label,
    percentage,
    onChange,
    readOnly,
    fullWidth,
}) => {
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "percentageValue") {
            const value = event.target.value.replaceAll(',', '.')
            percentage = parseFloat(value);
            if (!isNaN(percentage)) {
                onChange(percentage / 100);
            }
        }
    };

    percentage = percentage * 100;
    const incorrectEntry = percentage > 100;
    return (<TextField
            label={label}
            fullWidth={fullWidth}
            value={percentage}
            variant="standard"
            onChange={event => {
                handleChange(event as React.ChangeEvent<HTMLInputElement>);
            }}
            name={'%'}
            InputProps={{
                inputComponent: NumberFormatCustom as any,
                readOnly: readOnly,
            }}
            error={incorrectEntry}
        />
    );
}

export default PercentageInput