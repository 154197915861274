import { withTheme } from '@mui/styles';
import React, { useEffect } from "react";
import Dashboard from "../component/dashboardComponents/Dashboard";
import { DocumentType } from "../contracts/contracts";
import { useTicketContext } from '../contexts/ticket/ticketContext';
import { useAnalyticsContext } from '../contexts/analytics/analyticsContext';
import { useUrlContext } from '../contexts/url/urlContext';
import { useProjectContext } from '../contexts/project/projectContext';

const HomeView: React.FC<{}> = () => {

  const urlContext = useUrlContext();
  const ticketContext = useTicketContext();
  const analyticsContext = useAnalyticsContext();
  const projectContext = useProjectContext();

  const analyticsSearch = analyticsContext.getAnalyticsSearch();
  const projectSearch = projectContext.getProjectSearch();

  useEffect(() => {
    ticketContext.setDocumentTypesToWatch([
      DocumentType.ANALYTICS,
      DocumentType.PROJECT]);
    analyticsContext.searchAnalytics(analyticsSearch);
    projectContext.searchProjects(projectSearch);
  }, [urlContext.currentLocation]);

  return (
    <Dashboard></Dashboard>
  );
}

export default withTheme(HomeView);
