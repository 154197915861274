import { AppBar, IconButton, Theme, Toolbar, Button, Grid } from "@mui/material";
import { makeStyles, withTheme } from '@mui/styles';
import { Menu } from "@mui/icons-material/";
import React, { useEffect, useRef } from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import UserMenu from "../menuComponents/UserMenu";
import { useMenuContext, ApplicationRouteId } from "../../contexts/menu/menuContext";
import companyLogo from '../../data/companyLogo.png';
import ProjectMemberSelector from "../projectMemberComponents/ProjectMemberSelector";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { DocumentType } from "../../contracts/contracts";
import { useApplicationProfileContext } from "../../contexts/applicationProfile/applicationProfileContext";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(2),
  },
  buttonList: {
    '& >*+*': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const ApplicationAppBar: React.FC<{}> = () => {
  const classes = useStyles();
  const urlContext = useUrlContext();
  const languageContext = useLanguageContext();
  const menuContext = useMenuContext();
  const ticketContext = useTicketContext();
  const projectMemberContext = useProjectMemberContext();
  const applicationProfileContext = useApplicationProfileContext();

  const projectMemberSearch = projectMemberContext.getProjectMemberSearch();
  const applicationProfileForLoggedInUser = applicationProfileContext.getApplicationProfileForLoggedInUser();

  const handleOnClickMenu = () => {
    menuContext.setFullMenuVisible(!menuContext.fullMenuVisible);
  }

  const handleOnClickTitleButton = () => {
    menuContext.setSelectedApplicationRoute(ApplicationRouteId.Profile);
  }

  const applicationProfileProjectMembersAreFetched = useRef(false);

  useEffect(() => {
    if (applicationProfileProjectMembersAreFetched.current) {
      return;
    }

    if (applicationProfileForLoggedInUser?.id) {
      ticketContext.setDocumentTypesToWatch([DocumentType.PROJECT_MEMBER]);
      projectMemberSearch.applicationProfileId = applicationProfileForLoggedInUser.id;
      projectMemberContext.searchProjectMembers(projectMemberSearch);
      applicationProfileProjectMembersAreFetched.current = true;
    }
  }, [urlContext.currentLocation, applicationProfileForLoggedInUser, ticketContext, projectMemberSearch, projectMemberContext]);

  return (
    <AppBar position="static" color="default" sx={{ zIndex: 3 }}>
      <Toolbar sx={{ minHeight: 65 }}>
        <IconButton className={classes.button} edge="start" color="inherit" aria-label="menu" onClick={handleOnClickMenu}>
          <Menu />
        </IconButton>

        <Grid container sx={{ flexGrow: 1 }} flexWrap="nowrap">
          <Button onClick={handleOnClickTitleButton}>
            <img src={companyLogo} style={{
              height: '20px',
              width: '20px',
              marginRight: '10px'
            }}
              alt={languageContext.getMessage('company')} />
          </Button>
          <ProjectMemberSelector></ProjectMemberSelector>
        </Grid>

        <div className={classes.buttonList}>
          <UserMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default withTheme(ApplicationAppBar);
