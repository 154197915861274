import { useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { StorageFile } from "../contracts/contracts";
import { parseStorageFileToBase64Files } from "../utils/fileTools";
import {
	getStorageFilesGraphqlQueryOptions,
	QUERY_STORAGE_FILES
} from "../contexts/storageFile/queries/storageFileQueries";
import { useStorageFileContext } from "../contexts/storageFile/storageFileContext";

type returnValue = [
	base64Images: string[],
	base64ImagesMetadata: string[],
	loading: boolean,
];

export function useStoredFilesByFileKey(fileKey: string | undefined,
	base64Images: string[],
	setBase64Images?: React.Dispatch<React.SetStateAction<string[]>>,
	base64ImagesMetadata?: string[],
	setBase64ImagesMetadata?: React.Dispatch<React.SetStateAction<string[]>>,
	loadImages?: boolean, 
	onFileLoaded?: (finished: boolean) => void): returnValue {
	loadImages = loadImages ?? true;
	const dashboardPhoto1StorageFile: StorageFile = { key: fileKey };

	const storageFileContext = useStorageFileContext();
	const [loadDashboardImagesQuery, queryDashboardImagesResponse] = useLazyQuery(QUERY_STORAGE_FILES);

	const handleFetchedImage = (fileKey: string | undefined, storageFiles: StorageFile[]) => {
		const [base64Images, base64ImagesMetadata] = parseStorageFileToBase64Files(storageFiles, fileKey);

		if (setBase64Images) {
			setBase64Images(base64Images);
		}
		if (setBase64ImagesMetadata) {
			setBase64ImagesMetadata(base64ImagesMetadata);
		}
		if (onFileLoaded) {
			onFileLoaded(true);
		}
	}

	useEffect(() => {
		if (queryDashboardImagesResponse.data) {
			const storageFiles: StorageFile[] = queryDashboardImagesResponse.data?.storageFiles ?? [];
			if (storageFiles.length === 0 && fileKey) {
				storageFiles.push({ key: fileKey });
			}
			storageFileContext.appendStorageFilesToCache(storageFiles);
			handleFetchedImage(dashboardPhoto1StorageFile.key, storageFiles);
		}
	}, [queryDashboardImagesResponse.data])

	useEffect(() => {
		if (fileKey && loadImages) {
			const cachedStorageFile = storageFileContext.getStorageFileFromCache(fileKey);
			if (cachedStorageFile) {
				handleFetchedImage(dashboardPhoto1StorageFile.key, [cachedStorageFile]);
			}
			else {
				loadDashboardImagesQuery(getStorageFilesGraphqlQueryOptions({ key: fileKey }))
			}
		}
	}, [fileKey, loadImages])

	const { loading } = queryDashboardImagesResponse;
	return [base64Images, base64ImagesMetadata ?? [], loading];
}
