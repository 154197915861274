import React, { createContext, useContext } from "react";
import { ProjectCompany, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getProjectCompaniesGraphqlMutationOptions, MUTATE_PROJECT_COMPANY } from "./projectCompanyMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface ProjectCompanyMutationsContext {
    mutateProjectCompany: (projectCompany: ProjectCompany, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void) => void;
}

const ProjectCompanyMutationsContext = createContext<ProjectCompanyMutationsContext>(null as unknown as ProjectCompanyMutationsContext);

export const ProjectCompanyMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [projectCompanyMutation] = useMutation(MUTATE_PROJECT_COMPANY);

    const handleProjectCompanyMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        if (result.errors || !result.data) {
            onError(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateProjectCompany.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
        if (tickets.length === 0 && 'id' in variables) {
            onTicketSubscribed(variables['id'], variables);
        }
        onError("No ticket or document id received");
    }

    const mutateProjectCompany = (projectCompany: ProjectCompany, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        const options = getProjectCompaniesGraphqlMutationOptions(projectCompany)
        projectCompanyMutation(options).then((result) => handleProjectCompanyMutationResult(result, options.variables, onTicketSubscribed, onError));
    }

    const projectCompanyMutationsContext: ProjectCompanyMutationsContext = {
        mutateProjectCompany
    };

    return (
        <ProjectCompanyMutationsContext.Provider value={projectCompanyMutationsContext}>
            {children}
        </ProjectCompanyMutationsContext.Provider>
    );
}

export const useProjectCompanyMutationsContext = (): ProjectCompanyMutationsContext => {
    return useContext(ProjectCompanyMutationsContext);
}
