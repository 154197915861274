import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Theme, Typography } from "@mui/material";
import { makeStyles, withTheme } from '@mui/styles';
import React, { useEffect } from "react";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { DocumentType, RoleType, UserRole } from "../../contracts/contracts";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GeneralSettings from "../../component/settingsComponents/GeneralSettings";
import AuthSettings from "../../component/settingsComponents/AuthSettings";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useSettingsContext } from '../../contexts/settings/settingsContext';
import { useUserRoleContext } from "../../contexts/userRole/userRoleContext";
import { checkProjectAccess, getDocumentRole } from "../../contexts/userRole/userRoleTools";
import { useAuthContext } from "../../contexts/auth/authContext";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import NotificationSettings from "../../component/settingsComponents/NotificationSettings";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: '1em'
  },
  titleSection: {
    margin: theme.spacing(3, 2),
  },
  dateRangePickerSection: {
    margin: theme.spacing(3, 2, 0),
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  paper: {
    padding: '1em'
  },
  divider: {
    marginBottom: '1em'
  },
}));

const SettingsView: React.FC<{}> = () => {

  const urlContext = useUrlContext();
  const languageContext = useLanguageContext();
  const classes = useStyles();
  const authContext = useAuthContext();
  const projectMemberContext = useProjectMemberContext();

  const ticketContext = useTicketContext();
  const settingsContext = useSettingsContext();
  const userRoles = useUserRoleContext();

  const selectedProjectId = projectMemberContext.getSelectedProjectMember()?.projectId;
  const projectRoles = Object.values(RoleType).map(roleType => getDocumentRole(DocumentType.PROJECT, selectedProjectId ?? '', roleType))
  const projectUserRoles = projectRoles.map(projectRole => {
    const projectUserRole: UserRole = {name: projectRole};
    return projectUserRole;
  })

  useEffect(() => {
    ticketContext.setDocumentTypesToWatch([
      DocumentType.SETTINGS]);
    const settingsSearch = settingsContext.getSettingsSearch();
    settingsContext.searchSettings(settingsSearch);
    if (selectedProjectId) {
      userRoles.queryRoles(projectUserRoles);
      userRoles.queryUsers(projectUserRoles);
      projectMemberContext.searchProjectMembers({ ...projectMemberContext.getProjectMemberSearch(), projectId: selectedProjectId });
    }
  }, [urlContext.currentLocation, selectedProjectId])

  const title = `${languageContext.getMessage('settings')}`

  const showAuthSettings = selectedProjectId && (checkProjectAccess(RoleType.OWNER, authContext.accountRoles(), selectedProjectId) || checkProjectAccess(RoleType.ADMIN, authContext.accountRoles(), selectedProjectId));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper className={classes.paper} elevation={2}>
          <Typography gutterBottom variant="h5">
            {title}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />} >
            <Typography >{languageContext.getMessage('general')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GeneralSettings></GeneralSettings>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />} >
            <Typography>{languageContext.getMessage('notification')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <NotificationSettings></NotificationSettings>
          </AccordionDetails>
        </Accordion>
        {showAuthSettings && 
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />} >
            <Typography>{languageContext.getMessage('accessControl')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AuthSettings></AuthSettings>
          </AccordionDetails>
        </Accordion>}
      </Grid>
    </Grid>
  );
}

export default withTheme(SettingsView);