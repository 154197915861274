import React, { createContext, useContext, useEffect, useState } from "react";
import { InformationVerification } from "../../../contracts/contracts";
import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/react-hooks';
import { QUERY_INFORMATION_VERIFICATION, getInformationVerificationsGraphqlQueryOptions } from './informationVerificationQueries'
import { useAuthContext } from "../../auth/authContext";

export interface InformationVerificationQueriesContext {
    fetchedInformationVerifications: Array<InformationVerification>,
    queryInformationVerifications: (informationVerification: InformationVerification) => void,
    queryResponse: LazyQueryResult<any, OperationVariables>,
}

const InformationVerificationQueriesContext = createContext<InformationVerificationQueriesContext>(null as unknown as InformationVerificationQueriesContext);

export const InformationVerificationQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedInformationVerifications, setFetchedInformationVerifications] = useState<Array<InformationVerification>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_INFORMATION_VERIFICATION);

    const queryInformationVerifications = (informationVerification: InformationVerification): void => {
        const queryOptions = getInformationVerificationsGraphqlQueryOptions(informationVerification);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.informationVerifications) {
            const newFetchedInformationVerifications: Array<InformationVerification> = queryResponse.data.informationVerifications;
            setFetchedInformationVerifications(newFetchedInformationVerifications);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedInformationVerifications([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const informationVerificationQueriesContext: InformationVerificationQueriesContext = {
        fetchedInformationVerifications,
        queryInformationVerifications,
        queryResponse,
    };

    return (
        <InformationVerificationQueriesContext.Provider value={informationVerificationQueriesContext}>
            {children}
        </InformationVerificationQueriesContext.Provider>
    );
}

export const useInformationVerificationQueriesContext = (): InformationVerificationQueriesContext => {
    return useContext(InformationVerificationQueriesContext);
}
