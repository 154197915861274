import { Theme } from '@mui/material';
import { makeStyles, withTheme } from '@mui/styles';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import ApplicationAppBar from './component/applicationComponents/ApplicationAppBar';
import ApplicationAppFooter from './component/applicationComponents/ApplicationAppFooter';
import ApplicationDrawer from './component/applicationComponents/ApplicationDrawer';
import { useMenuContext, ApplicationRoute, ApplicationRouteId } from './contexts/menu/menuContext';
import Background from './views/Background';
import AboutView from './views/about/AboutView';
import { useAuthContext } from './contexts/auth/authContext';
import { useBrowserContext } from './contexts/browserContext/browserContext';
import DashboardSelectTenant from './component/dashboardComponents/DashboardSelectTenant';
import ApplicationBottomNav from './component/applicationComponents/ApplicationBottomNav';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexShrink: 1,
    flexGrow: 1,
    overflowY: "auto",
  }
}));

const PrivateRoute = ({ Component, applicationRoute, ...rest }: {
  [x: string]: any;
  Component: any;
  applicationRoute: ApplicationRoute
}) => {
  return (
    <Route
      {...rest}
      render={(props) => !applicationRoute.hidden
        ? <Component {...props} />
        : <AboutView />}
    />
  )
}

const App: React.FC = () => {
  const classes = useStyles();
  const menuContext = useMenuContext();
  const browserContext = useBrowserContext();
  const authContext = useAuthContext();

  const defaultRoute = menuContext.getApplicationRouteById(ApplicationRouteId.Project);

  return (<>
    {!authContext.authenticated ?
      <DashboardSelectTenant />
    : null }
    {authContext.authenticated && browserContext.isTenantNameAvailable() ?
      <Background className={classes.root}>
        <ApplicationAppBar />
        <ApplicationBottomNav className={classes.content} />
        <ApplicationDrawer className={classes.content}>
          <Switch>
            {menuContext.applicationRoutes.map((applicationRoute) => {
              return <PrivateRoute
                key={applicationRoute.id}
                exact
                path={applicationRoute.route}
                Component={applicationRoute.component}
                applicationRoute={applicationRoute} />
            })}
            <Redirect path={'*'} to={defaultRoute.route} />
          </Switch>
        </ApplicationDrawer>
        <ApplicationAppFooter />
      </Background>
    : null }
  </>
  );
}

export default withTheme(App);
