import { ApplicationProfile, ProjectMember, Certificate, TemplateEngineProprietaryType, CertificateType, InformationVerification } from "../../contracts/contracts";
import { downloadBase64File } from "../../utils/fileTools";
import { capitalizeString } from "../../utils/stringTools";
import { LanguageContext } from "../language/interfaces";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

export type projectMemberTemplateAlternatives = 'projectMemberReport';

const projectMemberTemplates = {
    'projectMemberReport': 'onsite/template_project_member_report.docx',
}

export const queryTemplateEngineToProduceProjectMemberReport = (
    companyLogo: string,
    applicationProfile: ApplicationProfile,
    projectMember: ProjectMember,
    certificates: Certificate[],
    informationVerifications: InformationVerification[],
    exportType: projectMemberTemplateAlternatives,
    templateEngineQueriesContext: TemplateEngineQueriesContext,
    languageContext: LanguageContext): void => {
    const templateFilename: string = projectMemberTemplates[exportType];
    const templateVariables: object = getTemplateEngineProjectMemberReportVariables(companyLogo, applicationProfile, projectMember, certificates, informationVerifications, exportType, languageContext) ?? [];
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, templateVariables, applicationProfile.name ?? applicationProfile.email ?? '');
}

const getTemplateEngineProjectMemberReportVariables = (
    companyLogo: string,
    applicationProfile: ApplicationProfile,
    projectMember: ProjectMember,
    certificates: Certificate[],
    informationVerifications: InformationVerification[],
    exportType: projectMemberTemplateAlternatives,
    languageContext: LanguageContext): object => {
    const date: Date = new Date();
    const title: string = applicationProfile.name ?? applicationProfile.email ?? '';
    const monthWithDate: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long', day: '2-digit' }));
    const monthWithDateString: string = `${monthWithDate} ${date.getFullYear()}`;
    return getProjectMemberVariables(title, monthWithDateString, companyLogo, applicationProfile, projectMember, certificates, informationVerifications, exportType, languageContext);
}

const getProjectMemberVariables = (
    title: string,
    monthWithDateString: string,
    companyLogo: string,
    applicationProfile: ApplicationProfile,
    projectMember: ProjectMember,
    certificates: Certificate[],
    informationVerifications: InformationVerification[],
    exportType: projectMemberTemplateAlternatives, 
    languageContext: LanguageContext): object => {
    const projectMemberCopy: ProjectMember = {
        ...projectMember,
        organizationNumber: projectMember.organizationNumber ?? applicationProfile.organizationNumber,
        applicationProfileUserId: projectMember.applicationProfileUserId ?? applicationProfile.userId,
        applicationProfileName: projectMember.applicationProfileName ?? applicationProfile.name,
        applicationProfileEmail: projectMember.applicationProfileEmail ?? applicationProfile.email,
        projectCompanyName: projectMember.projectCompanyName ?? applicationProfile.applicationProfileCompanyName,
    }
    
    const imageDataBase64Tag = 'data:image/';
    certificates.forEach(certificate => {
        const imageCertificateFiles: string[] = [];
        certificate.certificateFiles?.forEach((certificateFile, index) => {
            if (certificateFile.startsWith(imageDataBase64Tag)) {
                imageCertificateFiles.push(certificateFile);
            }
            else {
                const certificateFilename = certificate.certificateFilenames && certificate.certificateFilenames.length > index ? certificate.certificateFilenames[index] : undefined;
                downloadBase64File(undefined, certificateFile, certificateFilename ?? certificate.certificateType ?? '');
            }
        })
        certificate.certificateFiles = imageCertificateFiles;
    })
    const hseCertificates = certificates.filter(certificate => certificate.certificateType === CertificateType.HSE);
    const generalCertificates = certificates.filter(certificate => certificate.certificateType === CertificateType.GENERAL);

    const moddedCompanyLogo = companyLogoToProprietaryTemplateType(companyLogo);
    
    const moddedApplicationProfile: object = {
        ...applicationProfile,
        applicationProfileImage: applicationProfileImageToProprietaryTemplateType(applicationProfile.applicationProfileImage ?? ''),
    }

    const listVariables = {
        'companyLogo': moddedCompanyLogo,
        'language': languageContext.getLanguageCollection(),
        'title': title,
        'date': monthWithDateString,
        'applicationProfile': moddedApplicationProfile,
        'projectMember': projectMemberCopy as any,
        'hseCertificates': hseCertificates.map(certificateToModdedCertificate),
        'generalCertificates': generalCertificates.map(certificateToModdedCertificate),
        'informationVerifications': informationVerifications.map(informationVerificationToModdedInformationVerification),
    }
    return listVariables;
}

const certificateToModdedCertificate = (certificate: Certificate): object => {
    const moddedCertificates: object = {
        ...certificate,
        certificateFiles: (certificate.certificateFiles ?? []).map(certificateFileToProprietaryTemplateType),
    }
    return moddedCertificates;
}

const informationVerificationToModdedInformationVerification = (informationVerification: InformationVerification): object => {
    const moddedCertificates: object = {
        ...informationVerification,
        informationVerificationFiles: (informationVerification.informationVerificationFiles ?? []).map(informationVerificationFileToProprietaryTemplateType),
    }
    return moddedCertificates;
}

const certificateFileToProprietaryTemplateType = (certificateFile: string): TemplateEngineProprietaryType => {
    return {
        proprietaryType: 'inlineImage',
        source: certificateFile,
        width: 70,
        height: 50,
    }
}

const applicationProfileImageToProprietaryTemplateType = (applicationProfileImage: string): TemplateEngineProprietaryType => {
    return {
        proprietaryType: 'inlineImage',
        source: applicationProfileImage,
        width: 10,
        height: 10,
    }
}

const companyLogoToProprietaryTemplateType = (companyLogo: string): TemplateEngineProprietaryType => {
    return {
        proprietaryType: 'inlineImage',
        source: companyLogo,
        width: 10,
        height: 10,
    }
}

const informationVerificationFileToProprietaryTemplateType = (informationVerificationFile: string): TemplateEngineProprietaryType => {
    return {
        proprietaryType: 'inlineImage',
        source: informationVerificationFile,
        width: 10,
        height: 10,
    }
}
