import { ApplicationRoute } from "../menu/menuContext";
import { Subject } from 'rxjs';
import { RoleType } from "../../contracts/contracts";
import { ProjectMemberContext } from "../projectMember/projectMemberContext";

export const localStorageUrlBeforeRedirectKey = 'url_before_redirect'
export const localStorageAccessTokenKey = 'access_token'
export const localStorageIdTokenKey = 'id_token'

export interface AccountInfo {
    id: string;
    email: string;
    name: string;
}

export enum AuthProvider {
    KEYCLOAK = 'keycloak',
}

export interface AuthSettings {
    url?: string,
    realm?: string,
    clientId?: string,
    accountSettingsUrl?: string,
    authOff?: boolean,
}

export interface AuthProviderContext {
    getAuthProvider: () => AuthProvider;
}

export interface AuthContext extends AuthProviderContext {
    insecure: boolean,
    authInitialized: boolean;
    authReady: boolean;
    setAuthSettings: (authSettings: AuthSettings) => void;
    authSettings: AuthSettings | undefined;
    login: () => void;
    logout: () => void;
    authenticated: boolean;
    authorized: (applicationRoute: ApplicationRoute, projectMemberContext: ProjectMemberContext) => boolean;
    tokenIsReady: boolean;
    onTokenRefreshSubject: Subject<string>;
    jwtAccessToken: () => string | undefined;
    jwtIdToken: () => string | undefined;
    getAccountInfo: (accessToken: string | undefined) => AccountInfo | undefined;
    accountInfo: AccountInfo | undefined;
    accountRoles: () => string[];
    refreshToken: (minValidity?: number) => void;
}