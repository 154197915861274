import * as React from 'react';

import { Information } from '../../contracts/contracts';
import { useLanguageContext } from '../../contexts/language/LanguageContext';
import { Divider, Typography, Autocomplete, TextField, Grid, Box } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import InformationListItem from './InformationListItem';
import AssignmentIcon from "@mui/icons-material/Assignment";

type props = {
    informations: Information[];
    onInformationDetailsClick?: (information: Information) => void;
    onDeleteInformation?: (information: Information) => void;
    showDeleteButton?: boolean;
}

const InformationsList: React.FC<props> = ({
    informations,
    onInformationDetailsClick,
    onDeleteInformation,
    showDeleteButton,
}) => {
    const languageContext = useLanguageContext();

    return (<>
        <Box sx={{ display: 'flex', alignItems: 'center'}}>
            <AssignmentIcon />
            <Typography sx={{ ml: 1 }} variant="h5">{languageContext.getMessage('safetyInformation')}</Typography>
       </Box>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Grid spacing={2} container>
            {informations.map(information => <InformationListItem key={information.id} information={information} onInformationDetailsClick={onInformationDetailsClick} onDeleteInformation={onDeleteInformation} showDeleteButton={showDeleteButton}/>)}
        </Grid> 
    </>);
}

export default InformationsList
