import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, ApplicationProfile } from "../../../contracts/contracts";
import { cleanObject } from "../../../utils/objectTools";


export const MUTATE_APPLICATION_PROFILE: DocumentNode = gql(`
    mutation mutateApplicationProfile(
        $id: UUID
        $state: ActiveState 	
        $userId: UUID!
        $name: String
        $email: String
      	$phoneNumber: String
        $selectedApplicationLanguage: String
        $relatives: [RelativeInfoInput]
        $languages: [String]
        $organizationNumber: String
        $hseCardNumber: String
    ) {
        mutateApplicationProfile(
            id: $id
            state: $state
            userId: $userId
            name: $name
            email: $email
            phoneNumber: $phoneNumber
            selectedApplicationLanguage: $selectedApplicationLanguage
            relatives: $relatives
            languages: $languages
            organizationNumber: $organizationNumber
            hseCardNumber: $hseCardNumber
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`);

export const getApplicationProfilesGraphqlMutationOptions = (applicationProfile: Partial<ApplicationProfile>): QueryHookOptions => {
    applicationProfile.state = applicationProfile.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (applicationProfile.id !== undefined && applicationProfile.id !== null) options.variables['id'] = applicationProfile.id;
    if (applicationProfile.state !== undefined && applicationProfile.state !== null) options.variables['state'] = applicationProfile.state;
    if (applicationProfile.userId !== undefined && applicationProfile.userId !== null) options.variables['userId'] = applicationProfile.userId;
    if (applicationProfile.name !== undefined && applicationProfile.name !== null) options.variables['name'] = applicationProfile.name;
    if (applicationProfile.email !== undefined && applicationProfile.email !== null) options.variables['email'] = applicationProfile.email;
    if (applicationProfile.phoneNumber !== undefined && applicationProfile.phoneNumber !== null) options.variables['phoneNumber'] = applicationProfile.phoneNumber;
    if (applicationProfile.selectedApplicationLanguage !== undefined && applicationProfile.selectedApplicationLanguage !== null) options.variables['selectedApplicationLanguage'] = applicationProfile.selectedApplicationLanguage;
    if (applicationProfile.relatives !== undefined && applicationProfile.relatives !== null) options.variables['relatives'] = cleanObject(applicationProfile.relatives);
    if (applicationProfile.languages !== undefined && applicationProfile.languages !== null) options.variables['languages'] = applicationProfile.languages;
    if (applicationProfile.organizationNumber !== undefined && applicationProfile.organizationNumber !== null) options.variables['organizationNumber'] = applicationProfile.organizationNumber;
    if (applicationProfile.hseCardNumber !== undefined && applicationProfile.hseCardNumber !== null) options.variables['hseCardNumber'] = applicationProfile.hseCardNumber;
    return options;
}
