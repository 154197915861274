import React, { createContext, useContext, useEffect, useState } from "react";
import { Certificate, DocumentType, StateDocument } from "../../../contracts/contracts";
import { useAuthContext } from "../../auth/authContext";
import { useStateDocumentQueriesContext } from "../../stateDocument/queries/stateDocumentQueriesContext";

export interface CertificateSubscriptionsContext {
    subscribedCertificates: Array<Certificate>,
}

const CertificateSubscriptionsContext = createContext<CertificateSubscriptionsContext>(null as unknown as CertificateSubscriptionsContext);

export const CertificateSubscriptionsContextProvider: React.FC<{}> = ({ children }) => {

    const stateDocumentQueryContext = useStateDocumentQueriesContext();
    const authContext = useAuthContext();
    const [subscribedCertificates, setSubscribedCertificates] = useState<Array<Certificate>>([]);

    const updateSubscribedCertificates = (stateDocuments: StateDocument[]) => {
        const subscribedCertificates = stateDocuments
            .filter(stateDocument => stateDocument.documentType === DocumentType.CERTIFICATE && stateDocument.content)
            .map(stateDocument => (JSON.parse(stateDocument.content ?? "")) as Certificate);
        setSubscribedCertificates(subscribedCertificates);
    }

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setSubscribedCertificates([]);
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        updateSubscribedCertificates(stateDocumentQueryContext.fetchedStateDocuments);
    }, [stateDocumentQueryContext.fetchedStateDocuments]);

    const certificateContext: CertificateSubscriptionsContext = {
        subscribedCertificates,
    };

    return (
        <CertificateSubscriptionsContext.Provider value={certificateContext}>
            {children}
        </CertificateSubscriptionsContext.Provider>
    );
}

export const useCertificateSubscriptionsContext = (): CertificateSubscriptionsContext => {
    return useContext(CertificateSubscriptionsContext);
}
