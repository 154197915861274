import { Divider, Grid, Theme, Typography } from '@mui/material';
import { withTheme, makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CancelOutlined, CheckCircleOutline, ErrorOutline, NotInterested, Loop, Equalizer, AssignmentLate, ChangeHistory } from '@mui/icons-material';
import React, { ReactElement } from 'react';
import { LanguageKey, useLanguageContext } from '../../contexts/language/LanguageContext';
import { ElementStatusEnum, KPIEnum } from '../../contracts/contracts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    alignItems: 'center',
    margin: 'auto',
    color: '#fffasd',
  },
  cardLogo: {
    margin: theme.spacing(1),
  },
  words: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(2),
    textAlign: 'right',
  },
  subWords: {
    textAlign: 'left',
    marginLeft: theme.spacing(3),
    marginBottom: '0px',
  },
}));

function getRenderedStatus(kpi: KPIEnum, color: string, styles: any): ReactElement {
  switch (kpi) {
    case KPIEnum.TURNTIMES:           return <Loop style={{ fontSize: 80, color: color }} />;
    case KPIEnum.AVERAGE_FLEET_COST:  return <Equalizer style={{ fontSize: 80, color: color }} />;
    case KPIEnum.OFFHIRE_FLAG:        return <AssignmentLate style={{ fontSize: 80, color: color }} />;
    case KPIEnum.TRIANGULATION:       return <ChangeHistory style={{ fontSize: 80, color: color }} />;
  }

  return <NotInterested />;
}

type props = {
  kpi: KPIEnum;
  color: string;
  head: LanguageKey;
  content: string;
  subContent: LanguageKey;
  data: any;
}

const StatCard: React.FC<props> = ({
  kpi, color, head, content, subContent, data }) => {

  const styles = useStyles();
  const languageContext = useLanguageContext();

  return (
    <Card className={styles.root}>
      <CardContent>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={2}>
            <div className={styles.cardLogo}>
              {getRenderedStatus(kpi, color, styles)}
            </div>
          </Grid>
          <Grid item xs={10}>
            <Typography className={styles.words} gutterBottom >
              {languageContext.getMessage(head)}
            </Typography>
            <Typography className={styles.words} gutterBottom variant="h4">
               {data} {content}
            </Typography>
          </Grid>
          <Grid item xs={12}><Divider variant="middle" /></Grid>
          <Grid item xs={12}>
          <Typography className={styles.subWords} gutterBottom>
              {languageContext.getMessage(subContent)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withTheme(StatCard);