import React from "react";
import ReactMarkdown from 'react-markdown'
import { NewsFeed } from "../../contracts/contracts";
import NewsFeedTeaser from "../../component/newsFeed/newsFeedTeaser";
import { ListItem, ListItemText } from "@mui/material";

type props = {
	newsFeed?: NewsFeed;
}

const NewsFeedDetailsView: React.FC<props> = ({ newsFeed, }) => {
	return (<>
		<NewsFeedTeaser newsFeed={newsFeed} />
		<ListItem>
			<ListItemText secondaryTypographyProps={{ sx: { color: 'inherit' } }} secondary={
				<ReactMarkdown>{newsFeed?.newsFeedDescription ?? ''}</ReactMarkdown>
			} />
		</ListItem>
	</>
	);
}

export default NewsFeedDetailsView;
