import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, ProjectCompany } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useLanguageContext } from "../language/LanguageContext";
import { useUrlContext } from "../url/urlContext";
import { ProjectCompanyMutationsContextProvider, useProjectCompanyMutationsContext } from "./mutations/projectCompanyMutationsContext";
import { ProjectCompanyQueriesContextContext, useProjectCompanyQueriesContext } from "./queries/projectCompanyQueriesContext";
import { ProjectCompanySubscriptionsContextProvider, useProjectCompanySubscriptionsContext } from "./subscriptions/projectCompanySubscriptionsContext";



export interface ProjectCompanyContext {
    getProjectCompanySearch: () => ProjectCompany,
    searchProjectCompanies: (projectCompanySearch: ProjectCompany) => void,
    getProjectCompanies: () => ProjectCompany[],
    getProjectCompany: (id: Guid | undefined) => (ProjectCompany | undefined),
    mutateProjectCompany: (projectCompany: ProjectCompany) => Promise<Guid | undefined>,
    loadingProjectCompanies: boolean,
}

const ProjectCompanyContext = createContext<ProjectCompanyContext>(null as unknown as ProjectCompanyContext);

export const sortProjectCompanyByDate = (a: ProjectCompany, b: ProjectCompany) => {
    if ((a.created ?? "") < (b.created ?? "")) { return -1; }
    if ((a.created ?? "") > (b.created ?? "")) { return 1; }
    return 0;
}

export enum ProjectCompanyTabs {
    all = "all",
    details = "details",
}

export const ProjectCompanyContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <ProjectCompanyMutationsContextProvider>
            <ProjectCompanyQueriesContextContext>
                <ProjectCompanySubscriptionsContextProvider>
                    <ProjectCompanySubContextProvider>
                        {children}
                    </ProjectCompanySubContextProvider>
                </ProjectCompanySubscriptionsContextProvider>
            </ProjectCompanyQueriesContextContext>
        </ProjectCompanyMutationsContextProvider>
    );
}

export const ProjectCompanySubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();

    const projectCompanyMutationsContext = useProjectCompanyMutationsContext();
    const projectCompanyQueriesContext = useProjectCompanyQueriesContext();
    const projectCompanySubscriptionsContext = useProjectCompanySubscriptionsContext();

    const [currentProjectCompanySearch, setCurrentProjectCompanySearch] = useState<ProjectCompany | undefined>(undefined);
    const [projectCompanies, setProjectCompanies] = useState<ProjectCompany[]>([]);
    const maxProjectCompaniesToFetchAtOnce = 100;


    const mergeProjectCompanies = (oldProjectCompanies: Array<ProjectCompany>, newProjectCompanies: Array<ProjectCompany>): Array<ProjectCompany> => {
        const updatedProjectCompanies = oldProjectCompanies.slice();
        if (!newProjectCompanies) {
            console.error(`Received undefined set of ProjectCompanies: ${newProjectCompanies}`);
            return [];
        }
        newProjectCompanies.forEach(newProjectCompany => {
            newProjectCompany.created = new Date(newProjectCompany.created ?? 0);
            newProjectCompany.projectCompanyLevel = newProjectCompany.projectCompanyLevel ?? 0;

            const index = updatedProjectCompanies.findIndex(projectCompany => projectCompany.id === newProjectCompany.id);
            if (index >= 0) {
                if (newProjectCompany.state === ActiveState.ACTIVE) {
                    updatedProjectCompanies[index] = newProjectCompany;
                }
                else {
                    updatedProjectCompanies.splice(index, 1);
                }
            } else {
                if (newProjectCompany.state === ActiveState.ACTIVE) {
                    updatedProjectCompanies.push(newProjectCompany);
                }
            }
        });
        return updatedProjectCompanies.sort(sortProjectCompanyByDate);
    }


    const getProjectCompanySearch = (): ProjectCompany => {
        const urlState = urlContext.getUrlState();
        return {
        }
    }

    const searchProjectCompanies = (projectCompanySearch: ProjectCompany): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(projectCompanySearch, currentProjectCompanySearch);
        matched = matched && projectCompanySearch?.id === currentProjectCompanySearch?.id;
        matched = matched && projectCompanySearch?.projectId === currentProjectCompanySearch?.projectId;
        if (!matched) {
            projectCompanySearch.searchIndexStart = 0;
            projectCompanySearch.searchIndexStop = maxProjectCompaniesToFetchAtOnce;
            setCurrentProjectCompanySearch(projectCompanySearch);
            setProjectCompanies([]);
        }
    }

    const getProjectCompanies = (): Array<ProjectCompany> => {
        return projectCompanies;
    }

    const getProjectCompany = (id: Guid | undefined) => {
        return id === undefined ? undefined : projectCompanies.find(projectCompany => projectCompany.id === id);
    }

    const mutateProjectCompany = (projectCompany: ProjectCompany): Promise<Guid | undefined> => {
        return new Promise((resolve, reject) => projectCompanyMutationsContext.mutateProjectCompany(projectCompany, (documentId, variables) => {
            projectCompany = { ...projectCompany, ...variables };
            projectCompany.id = documentId;
            setProjectCompanies(mergeProjectCompanies(projectCompanies, [projectCompany]));
            resolve(documentId);
        }, (reason) => reject(reason)));
    }

    useEffect(() => {
        if (currentProjectCompanySearch) {
            projectCompanyQueriesContext.queryProjectCompanies(currentProjectCompanySearch);
        }
    }, [currentProjectCompanySearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setProjectCompanies([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setProjectCompanies(mergeProjectCompanies(projectCompanies, projectCompanyQueriesContext.fetchedProjectCompanies));
        if (projectCompanyQueriesContext.fetchedProjectCompanies.length >= maxProjectCompaniesToFetchAtOnce && currentProjectCompanySearch) {
            currentProjectCompanySearch.searchIndexStart = (currentProjectCompanySearch.searchIndexStart ?? 0) + projectCompanyQueriesContext.fetchedProjectCompanies.length;
            currentProjectCompanySearch.searchIndexStop = currentProjectCompanySearch.searchIndexStart + maxProjectCompaniesToFetchAtOnce;
            setCurrentProjectCompanySearch({...currentProjectCompanySearch});
        }
    }, [projectCompanyQueriesContext.fetchedProjectCompanies]);

    useEffect(() => {
        setProjectCompanies(mergeProjectCompanies(projectCompanies, projectCompanySubscriptionsContext.subscribedProjectCompanies));
    }, [projectCompanySubscriptionsContext.subscribedProjectCompanies]);

    const projectCompanyContext = {
        getProjectCompanySearch,
        searchProjectCompanies,
        getProjectCompanies,
        getProjectCompany,
        mutateProjectCompany,
        loadingProjectCompanies: projectCompanyQueriesContext.queryResponse.loading,
    };

    return (
        <ProjectCompanyContext.Provider value={projectCompanyContext}>
            {children}
        </ProjectCompanyContext.Provider>
    );
}

export const useProjectCompanyContext = (): ProjectCompanyContext => {
    return useContext(ProjectCompanyContext);
}
