import React, { createContext, useContext, useEffect, useState } from "react";
import { Project } from "../../../contracts/contracts";
import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/react-hooks';
import { QUERY_PROJECT, getProjectsGraphqlQueryOptions } from './projectQueries'
import { useAuthContext } from "../../auth/authContext";

export interface ProjectQueriesContext {
    fetchedProjects: Array<Project>,
    queryProjects: (project: Project) => void,
    queryResponse: LazyQueryResult<any, OperationVariables>,
}

const ProjectQueriesContext = createContext<ProjectQueriesContext>(null as unknown as ProjectQueriesContext);

export const ProjectQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedProjects, setFetchedProjects] = useState<Array<Project>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_PROJECT);

    const queryProjects = (project: Project): void => {
        const queryOptions = getProjectsGraphqlQueryOptions(project);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.projects) {
            const newFetchedProjects: Array<Project> = queryResponse.data.projects;
            setFetchedProjects(newFetchedProjects);
        }
    }, [queryResponse.data]);
    
    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedProjects([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const projectQueriesContext: ProjectQueriesContext = {
        fetchedProjects,
        queryProjects,
        queryResponse,
    };

    return (
        <ProjectQueriesContext.Provider value={projectQueriesContext}>
            {children}
        </ProjectQueriesContext.Provider>
    );
}

export const useProjectQueriesContext = (): ProjectQueriesContext => {
    return useContext(ProjectQueriesContext);
}
