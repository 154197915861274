import React, { createContext, useContext } from "react";
import { Information, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getInformationsGraphqlMutationOptions, MUTATE_INFORMATION } from "./informationMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface InformationMutationsContext {
    mutateInformation: (information: Information, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void) => void;
}

const InformationMutationsContext = createContext<InformationMutationsContext>(null as unknown as InformationMutationsContext);

export const InformationMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [informationMutation] = useMutation(MUTATE_INFORMATION);

    const handleInformationMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        if (result.errors || !result.data) {
            onError(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateInformation.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
        if (tickets.length === 0 && 'id' in variables) {
            onTicketSubscribed(variables['id'], variables);
        }
        onError("No ticket or document id received");
    }

    const mutateInformation = (information: Information, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        const options = getInformationsGraphqlMutationOptions(information)
        informationMutation(options).then((result) => handleInformationMutationResult(result, options.variables, onTicketSubscribed, onError));
    }

    const informationMutationsContext: InformationMutationsContext = {
        mutateInformation
    };

    return (
        <InformationMutationsContext.Provider value={informationMutationsContext}>
            {children}
        </InformationMutationsContext.Provider>
    );
}

export const useInformationMutationsContext = (): InformationMutationsContext => {
    return useContext(InformationMutationsContext);
}
