import React from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReactMarkdown from 'react-markdown';
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type Props = {
    open: boolean;
    onClose: () => void;
    isCheckedIn: boolean | undefined;
}

const CheckedInOutDialog: React.FC<Props> = ({ open, onClose, isCheckedIn }) => {

    const languageContext = useLanguageContext();

    const onCloseHandler = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    }

    return (
        <>
            <Dialog
                fullWidth={false}
                maxWidth={'xs'}
                open={open}
                onClose={onCloseHandler}
            >
                <DialogTitle style={{ textAlign: "center" }}>
                    <ReactMarkdown>{isCheckedIn ? languageContext.getMessage('checkedIn') : languageContext.getMessage('checkedOut')}</ReactMarkdown>
                    <IconButton
                        style={{
                            width: 60,
                            height: 60,
                        }}
                        onClick={onCloseHandler}
                    >
                        {isCheckedIn ? <CheckCircleIcon
                            fontSize='large'
                            color='success' /> : <CheckCircleOutlineIcon
                            fontSize='large'
                            color='warning' />}
                    </IconButton>
                </DialogTitle>
            </Dialog>
        </>
    );
}

export default CheckedInOutDialog;