import React, { useState } from 'react';
import { ProjectMember, MemberAccess } from "../../contracts/contracts";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { Button, Card, CardContent, CardHeader, IconButton, Stack, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ApplicationProfileAvatar from "../applicationProfileComponents/applicationProfileAvatar";
import { getStorageFileFullKey } from "../../utils/fileTools";
import { useStoredFilesByFileKey } from "../../hooks/useStoredFilesByFileKey";

type props = {
    projectMember?: ProjectMember,
}

const ProjectMemberCard: React.FC<props> = ({ projectMember }) => {
    const languageContext = useLanguageContext();
    const fileKey = getStorageFileFullKey(projectMember?.applicationProfileId ?? '');

    const [base64Images, setBase64Images] = useState<string[]>([]);
    const imageSrces = useStoredFilesByFileKey(fileKey, base64Images, setBase64Images)[0];

    if (!projectMember) return null;

    return (<>
        <Card>
            <CardHeader
                avatar={
                    <ApplicationProfileAvatar id={projectMember?.applicationProfileId} userName={projectMember?.applicationProfileName} src={imageSrces.length > 0 ? imageSrces[0] : undefined} />
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                title={projectMember?.applicationProfileName}
                subheader={[]}
            />
            <CardContent>
            <Typography textAlign="left" sx={{ marginBottom: '1em' }} variant="body1">Some info about the user...</Typography>
                {projectMember.memberAccess === MemberAccess.REQUESTING_MEMBER_ACCESS &&
                    <>
                        <Typography textAlign="left" sx={{ marginBottom: '1em' }} variant="body1">{languageContext.getMessage('pendingApproval')}</Typography>
                        <Stack spacing={1} direction="row" >
                            <Button variant="outlined">{languageContext.getMessage('deny')}</Button>
                            <Button variant="outlined">{languageContext.getMessage('deny')}</Button>
                            <Button variant="outlined">{languageContext.getMessage('approve')}</Button>
                        </Stack>
                    </>
                }
            </CardContent>
        </Card>
    </>
    )
}

export default ProjectMemberCard;
