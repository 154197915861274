import React, { createContext, useContext, useEffect, useState } from "react";
import { Analytics } from "../../../contracts/contracts";
import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/react-hooks';
import { QUERY_ANALYTICS, getAnalyticsGraphqlQueryOptions } from './analyticsQueries'
import { useAuthContext } from "../../auth/authContext";

export interface AnalyticsQueriesContext {
    fetchedAnalytics: Array<Analytics>,
    queryAnalytics: (analytics: Analytics) => void,
    queryResponse: LazyQueryResult<any, OperationVariables>,
}

const AnalyticsQueriesContext = createContext<AnalyticsQueriesContext>(null as unknown as AnalyticsQueriesContext);

export const AnalyticsQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedAnalytics, setFetchedAnalytics] = useState<Array<Analytics>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_ANALYTICS);

    const queryAnalytics = (analytics: Analytics): void => {
        const queryOptions = getAnalyticsGraphqlQueryOptions(analytics);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.analytics) {
            const newFetchedAnalytics: Array<Analytics> = queryResponse.data.analytics;
            setFetchedAnalytics(newFetchedAnalytics);
        }
    }, [queryResponse.data]);
    
    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedAnalytics([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const analyticsQueriesContext: AnalyticsQueriesContext = {
        fetchedAnalytics,
        queryAnalytics,
        queryResponse,
    };

    return (
        <AnalyticsQueriesContext.Provider value={analyticsQueriesContext}>
            {children}
        </AnalyticsQueriesContext.Provider>
    );
}

export const useAnalyticsQueriesContext = (): AnalyticsQueriesContext => {
    return useContext(AnalyticsQueriesContext);
}
