import { ApplicationProfile, ProjectMember, DocumentType, Certificate, InformationVerification } from "../../contracts/contracts";
import { convertImageToBase64, getStorageFileFullKey, parseStorageFileToBase64Files } from "../../utils/fileTools";
import company_logo from '../../data/companyLogo.png'
import diploma_placeholder from '../../data/pictures/certificate.png'
import { useEffect, useState } from "react";
import { useStoredFilesByFileKey } from "../../hooks/useStoredFilesByFileKey";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { useCertificateContext } from "../../contexts/certificate/certificateContext";
import FileLoader from "../common/FileLoader";
import { useStorageFileContext } from "../../contexts/storageFile/storageFileContext";
import { useTemplateEngineQueriesContext } from "../../contexts/templateEngine/queries/templateEngineQueriesContext";
import { notUndefined } from "../../utils/randomTools";
import { useCompanyInfoLoader } from "../companyInfoComponents/useCompanyInfoLoader";
import { guidIsNullOrEmpty } from "../../utils/guidTools";
import { useInformationVerificationContext } from "../../contexts/informationVerification/informationVerificationContext";
import { useCompanyLogo } from "../../hooks/useCompanyLogo";

type returnValue = [
    onDownloadReport: () => void,
    downloadingReport: boolean,
];

export function useDownloadReport(
    applicationProfile: ApplicationProfile | undefined,
    projectMember: ProjectMember | undefined): returnValue {
    const projectMemberContext = useProjectMemberContext();
    const certificateContext = useCertificateContext();
    const informationVerificationContext = useInformationVerificationContext();
    const storageFileContext = useStorageFileContext();
    const templateEngineContext = useTemplateEngineQueriesContext();

    const selectedProjectId = projectMemberContext.getSelectedProjectMember()?.projectId;
    const certificates = certificateContext.getCertificates().filter(certificate => certificate.applicationProfileId === applicationProfile?.id);
    const informationVerifications = informationVerificationContext.getInformationVerifications().filter(informationVerification => informationVerification.projectId && informationVerification.projectId === selectedProjectId);

    const companyLogoInlineImage = useCompanyLogo();

    const [diplomaPlaceholderInlineImage, setDiplimaPlaceholderInlineImage] = useState<string>();
    useEffect(() => {
        if (diplomaPlaceholderInlineImage) {
            return;
        }
        convertImageToBase64(diploma_placeholder, (dataUrl) => {
            setDiplimaPlaceholderInlineImage(dataUrl);
        });
    }, []);

    const projectCompanyNameIsValid = (projectMember?.projectCompanyName ?? '').trim().length > 0;
    const [matchingApplicationProfileCompanyInfo] = useCompanyInfoLoader(applicationProfile?.organizationNumber);
    const [matchingProjectMemberCompanyInfo] = useCompanyInfoLoader(projectMember?.organizationNumber, projectCompanyNameIsValid);

    const downloadingReport = templateEngineContext.isWaitingForResponse ||
        certificateContext.loadingCertificates ||
        informationVerificationContext.loadingInformationVerifications ||
        !companyLogoInlineImage ||
        !diplomaPlaceholderInlineImage;
    const onDownloadReport = () => {
        if (downloadingReport) {
            return;
        }
        const applocationProfileImagefileKey = getStorageFileFullKey(applicationProfile?.id ?? '');
        const [applicationProfileImages, applicationProfileImagesMetadata] = parseStorageFileToBase64Files([storageFileContext.getStorageFileFromCache(applocationProfileImagefileKey) ?? {}], applocationProfileImagefileKey)
        const extendedApplicationProfile: ApplicationProfile = {
            ...applicationProfile,
            applicationProfileCompanyName: matchingApplicationProfileCompanyInfo?.name ?? '',
            applicationProfileImage: applicationProfileImages.length > 0 ? applicationProfileImages[0] : '',
        }
        const extendedProjectMember: ProjectMember = guidIsNullOrEmpty(projectMember?.id) ? {} : {
            ...projectMember,
            projectCompanyName: (projectCompanyNameIsValid ? projectMember?.projectCompanyName : matchingProjectMemberCompanyInfo?.name) ?? '',
        };
        const certificatesWithFiles = certificates.map(certificate => {
            const key = getStorageFileFullKey(certificate?.id ?? '');
            const [certificateFiles, certificateFilenames] = parseStorageFileToBase64Files([storageFileContext.getStorageFileFromCache(key) ?? {}], key);
            const certificateWithFile: Certificate = {
                ...certificate,
                certificateFiles: certificateFiles,
                certificateFilenames: certificateFilenames
            }
            return certificateWithFile;
        });
        const informationVerificationsWithFiles = informationVerifications.map(informationVerification => {
            const informationVerificationWithFiles: InformationVerification = {
                ...informationVerification,
                informationVerificationFiles: [diplomaPlaceholderInlineImage]
            }
            return informationVerificationWithFiles;
        })
        projectMemberContext.downloadProjectMemberReport(companyLogoInlineImage,
            extendedApplicationProfile,
            extendedProjectMember,
            certificatesWithFiles,
            informationVerificationsWithFiles);
    }

    return [onDownloadReport, downloadingReport];
}

type props = {
    applicationProfile: ApplicationProfile | undefined,
    projectMember: ProjectMember | undefined,
    onDownloadReady?: (downloadReady: boolean) => void;
};

const ProjectMemberReport: React.FC<props> = ({
    applicationProfile,
    projectMember,
    onDownloadReady }) => {
    const applicationProfileImagefileKey = getStorageFileFullKey(applicationProfile?.id ?? '');
    const ticketContext = useTicketContext();
    const storageFileContext = useStorageFileContext();
    const certificateContext = useCertificateContext();
    const informationVerificationContext = useInformationVerificationContext();
    const certificates = certificateContext.getCertificates().filter(certificate => certificate.applicationProfileId === applicationProfile?.id);

    const [downloadedFileKeys, setDownloadedFileKeys] = useState<string[]>([]);
    const [base64Images, setBase64Images] = useState<string[]>([]);
    const validFileIds = certificates.map(certificate => certificate.id).concat([applicationProfile?.id]).filter(notUndefined);

    const onFileLoaded = (fileKey: string | undefined, finished: boolean): void => {
        if (!fileKey || !onDownloadReady) {
            return;
        }
        const downloadedFinished = downloadedFileKeys.findIndex(key => key === fileKey) >= 0;

        if (finished && !downloadedFinished) {
            downloadedFileKeys.push(fileKey);
        }

        if (!certificateContext.loadingCertificates &&
            finished &&
            downloadedFileKeys.length >= validFileIds.length) {
            onDownloadReady(true);
        }
    }

    const [imageSrces, imageSrcesMetadata, loadingApplicationProfileImage] = useStoredFilesByFileKey(applicationProfileImagefileKey, base64Images, undefined, undefined, undefined, true, (finished) => onFileLoaded(applicationProfileImagefileKey, finished));

    useEffect(() => {
        if (applicationProfile?.id) {
            const existingFileKeys: string[] = [];
            validFileIds.forEach(validFileId => {
                if (storageFileContext.getStorageFileFromCache(getStorageFileFullKey(validFileId))) {
                    existingFileKeys.push(validFileId);
                }
            })
            setDownloadedFileKeys(existingFileKeys);
            const downloadReady = existingFileKeys.length >= validFileIds.length;
            if (onDownloadReady) {
                onDownloadReady(downloadReady);
            }
            ticketContext.setDocumentTypesToWatch([
                DocumentType.CERTIFICATE,
                DocumentType.INFORMATION_VERIFICATION
            ]);
            const certificateSearch = certificateContext.getCertificateSearch();
            const informationVerificationsSearch = informationVerificationContext.getInformationVerificationSearch();
            certificateSearch.applicationProfileId = applicationProfile.id;
            certificateContext.searchCertificates(certificateSearch);
            informationVerificationsSearch.applicationProfileId = applicationProfile.id;
            informationVerificationsSearch.projectId = projectMember?.projectId;
            informationVerificationContext.searchInformationVerifications(informationVerificationsSearch);
        }
    }, [applicationProfile?.id, certificateContext.getCertificates(), informationVerificationContext.getInformationVerifications()])

    return <>
        {certificates.map(certificate => <FileLoader key={certificate.id} fileKey={getStorageFileFullKey(certificate?.id ?? '')} onFileLoaded={onFileLoaded} />)
        }</>;
}

export default ProjectMemberReport;