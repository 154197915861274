import * as React from 'react';

import ReactMarkdown from 'react-markdown'
import { Information, RoleType, InformationAccess } from '../../contracts/contracts';
import CardActions from "@mui/material/CardActions";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import { Grid, Card, CardContent, Typography, IconButton } from "@mui/material";
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';

type props = {
	information: Information;
	showDeleteButton?: boolean;
	onInformationDetailsClick?: (information: Information) => void;
	onDeleteInformation?: (information: Information) => void;
}

const InformationListItem: React.FC<props> = ({ information, onInformationDetailsClick, onDeleteInformation, showDeleteButton = false }) => {
	const projectMemberContext = useProjectMemberContext();

	const onDeleteHandler = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();

		if (typeof onDeleteInformation === 'function') {
			onDeleteInformation(information)
		}
	}

	const hasWriteAccessToSelectedProject = information?.projectId && projectMemberContext.hasProjectAccess(RoleType.WRITER, information?.projectId);
	return (
		<Grid
			item key={information.id} xs={6} role="button"
			onClick={() => {
				if (!onInformationDetailsClick) {
					return;
				}
				onInformationDetailsClick(information);
			}}
		>
			<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', color: "text.secondary" }}>
				<CardContent sx={{ flexGrow: 1, p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Typography variant="body1" sx={{
						flexGrow: 1,
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap'
					}}>
						{information.title}
					</Typography>
					{(information.informationAccess ?? InformationAccess.PRIVATE) === InformationAccess.PRIVATE && (
						<LockIcon color={'primary'} />
					)}
					{showDeleteButton && hasWriteAccessToSelectedProject && (
						<IconButton onClick={onDeleteHandler} color="primary" aria-label="upload picture" component="span">
							<DeleteIcon />
						</IconButton>
					)}
				</CardContent>
				<CardContent sx={{ height: 80, flexGrow: 1, p: 1, }}>
					<ReactMarkdown
						children={information?.information ?? ''}
						components={{
							p: ({ children, ...props}) => <Typography variant="body2" sx={{
								height: '100%',
								overflow: 'hidden',
							}}>{children}</Typography>
						}}
					/>
				</CardContent>
				<CardActions sx={{ px: 1, pt: 0, pb: 1, paddingTop: '0px', display: 'flex', justifyContent: 'space-between' }} style={{ paddingTop: '0px' }}>
					<Typography variant="caption" sx={{
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap'
					}}>
						{information.videoUrl}
					</Typography>
					<KeyboardArrowRightIcon />
				</CardActions>
			</Card>
		</Grid>
	);
}

export default InformationListItem
