export function cleanObject(objectToClean: any, keysToRemove?: string[]): any {
    if (Array.isArray(objectToClean)) {
        objectToClean.forEach(subObjectToClean => {
            cleanObject(subObjectToClean, keysToRemove);
        });
        return objectToClean;
    }
    if (!isDict(objectToClean)) {
        return objectToClean;
    }
    keysToRemove = Object.keys(objectToClean).filter(key => key.startsWith('_') || (keysToRemove?.indexOf(key) ?? -1) >= 0)
    keysToRemove.forEach(key => {
        delete objectToClean[key];
    });
    Object.keys(objectToClean).forEach(key => {
        cleanObject(objectToClean[key], keysToRemove);
    });
    return objectToClean;
}

export function isDict(dict: any) {
    const dictStr = JSON.stringify(dict);
    if (dictStr !== undefined && dictStr !== null) {
        return dictStr.startsWith("{") && dictStr.endsWith("}")
    }
    return false;
}