import { MenuItem, Stack } from "@mui/material";
import React from "react";
import ReactCountryFlag from "react-country-flag"
import { LanguageType } from "../contexts/language/interfaces";

export const getLanguageAlternatives = (onClick?: (language: LanguageType) => Promise<void>): React.ReactNode => {
    const languages: LanguageType[] = [
        'nb',
        'en',
        'pl',
    ]

    return languages.map(language => <MenuItem key={`language-${language}`} onClick={onClick ? async () => await onClick(language) : undefined} value={language}>
            {getLanguageIconFlagWithTitle(language)}
        </MenuItem>)
}

export const getLanguageIconFlagWithTitle = (language: LanguageType): React.ReactNode => {
    return <Stack direction='row' spacing={1}>
        {getLanguageIconFlag(language)}
        <div>{getLanguageTitle(language)}</div>
    </Stack>
}

export const getLanguageIconFlag = (language: LanguageType, style?: React.CSSProperties | undefined): React.ReactNode => {
    const countryCode = getCountryCode(language);
    if (!countryCode) {
        return <></>
    }
    return <ReactCountryFlag
        countryCode={countryCode}
        svg
        style={style ?? {
            fontSize: '1.5em',
            lineHeight: '1.5em',
        }}
    />
}

export const getCountryCode = (language: LanguageType): string | undefined => {
    switch (language) {
        case 'nb': return 'no';
        case 'en': return 'gb';
        case 'pl': return 'pl';
        default: return undefined;
    }
}

export const getLanguageTitle = (language: LanguageType): string | undefined => {
    switch (language) {
        case 'nb': return 'Norsk';
        case 'en': return 'English';
        case 'pl': return 'Polskie';
        default: return undefined;
    }
}