import React, { createContext, useContext } from "react";
import { Certificate, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getCertificatesGraphqlMutationOptions, MUTATE_CERTIFICATE } from "./certificateMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface CertificateMutationsContext {
    mutateCertificate: (certificate: Certificate, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void) => void;
}

const CertificateMutationsContext = createContext<CertificateMutationsContext>(null as unknown as CertificateMutationsContext);

export const CertificateMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [certificateMutation] = useMutation(MUTATE_CERTIFICATE);

    const handleCertificateMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        if (result.errors || !result.data) {
            onError(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateCertificate.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
        if (tickets.length === 0 && 'id' in variables) {
            onTicketSubscribed(variables['id'], variables);
        }
    }

    const mutateCertificate = (certificate: Certificate, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        const options = getCertificatesGraphqlMutationOptions(certificate)
        certificateMutation(options).then((result) => handleCertificateMutationResult(result, options.variables, onTicketSubscribed, onError));
    }

    const certificateMutationsContext: CertificateMutationsContext = {
        mutateCertificate
    };

    return (
        <CertificateMutationsContext.Provider value={certificateMutationsContext}>
            {children}
        </CertificateMutationsContext.Provider>
    );
}

export const useCertificateMutationsContext = (): CertificateMutationsContext => {
    return useContext(CertificateMutationsContext);
}
