import { createTheme } from '@mui/material/styles';

const LightTheme = createTheme({
  palette: {
    primary: {
      main: '#607D8B',
      light: '#90A4AE',
      dark: '#37474F',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#FF9800',
      light: '#FFA726',
      dark: '#EF6C00',
      contrastText: '#ffffff'
    },
    background: {
      paper: '#FFFFFF',
      default: '#F2F2F2'
      // default: '#E5E6E7'
    },
    error: {
      main: '#ff4e50',
    },
    success: {
      main: '#31d655',
    },
    warning: {
      main: '#f9d62e',
    },
    info: {
      main: '#0086ad',
    },
  },
});

export default LightTheme;
