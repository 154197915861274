import { ApplicationProfile, Information, InformationApproval, InformationVerification, Project } from "../contracts/contracts";

export const checkIfDuplicateApprovalDescriptions = (information: Information | undefined, informationApproval: InformationApproval): boolean => {
    return (information?.informationApprovals ?? []).filter(existingInformationApproval => (informationApproval.approvalDescription?.trim() ?? '') === (existingInformationApproval.approvalDescription?.trim() ?? '')).length > 1;
}

export const checkInformationIsValid = (information: Information | undefined) => {
    let valid = true;
    valid = valid && !((information?.informationApprovals ?? []).some(informationApproval => checkIfDuplicateApprovalDescriptions(information, informationApproval)));
    return valid;
}

export const getNumberOfNotVerifiedInformations = (applicationProfile: ApplicationProfile | undefined, informationVerifications: InformationVerification[], informations: Information[]): number => {
    const numberOfNotVerifiedInformations = informations.filter(info => {
        const existingInfoVerification = informationVerifications.find(infoVerification => infoVerification.informationId === info.id && infoVerification.applicationProfileId === applicationProfile?.id)
        return existingInfoVerification && existingInfoVerification.agreed && !existingInfoVerification.verified;
    }).length;
    return numberOfNotVerifiedInformations;
}

export const getQuizInformations = (informations: Information[]): Information[] => {
    const quizInformations = informations.filter(info => info.informationApprovalsQuizActivated && (info.informationApprovals ?? []).length > 0);
    return quizInformations;
}

export const getNumberOfAgreedInformations = (applicationProfile: ApplicationProfile | undefined, informationVerifications: InformationVerification[], informations: Information[]): number => {
    const numberOfAgreedInformations = informations.filter(info => {
        const existingInfoVerification = informationVerifications.find(infoVerification => infoVerification.informationId === info.id && infoVerification.applicationProfileId === applicationProfile?.id)
        return existingInfoVerification && existingInfoVerification.agreed;
    }).length;
    return numberOfAgreedInformations;
}

export const getNumberOfAgreedAndVerifiedQuizInformations = (applicationProfile: ApplicationProfile | undefined, informationVerifications: InformationVerification[], informations: Information[]): number => {
    const quizInformations = getQuizInformations(informations);
    const numberOfAgreedAndVerifiedQuizInformations = quizInformations.filter(info => {
        const existingInfoVerification = informationVerifications.find(infoVerification => infoVerification.informationId === info.id && infoVerification.applicationProfileId === applicationProfile?.id)
        return existingInfoVerification && existingInfoVerification.agreed && existingInfoVerification.verified;
    }).length;
    return numberOfAgreedAndVerifiedQuizInformations;
}

export const getMinimumNumberOfAgreedAndVerifiedQuizInformations = (project: Project | undefined, informations: Information[]): number => {
    const numberOfQuizInformations = getQuizInformations(informations).length;
    if (!project?.minimumPercentageVerifiedInformations || project.minimumPercentageVerifiedInformations <= 0.0) {
        return numberOfQuizInformations;
    }
    const minimumPercentageVerifiedInformations = Math.min(1.0, project.minimumPercentageVerifiedInformations);
    const minNumberOfAgreedAndVerifiedInformations = Math.floor(numberOfQuizInformations * minimumPercentageVerifiedInformations);
    return minNumberOfAgreedAndVerifiedInformations;
}