import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, Project, ProjectState, RoleType } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { AuthContext } from "../auth/interfaces";
import { useLanguageContext } from "../language/LanguageContext";
import { useUrlContext } from "../url/urlContext";
import { checkProjectAccess } from "../userRole/userRoleTools";
import { ProjectMutationsContextProvider, useProjectMutationsContext } from "./mutations/projectMutationsContext";
import { ProjectQueriesContextContext, useProjectQueriesContext } from "./queries/projectQueriesContext";
import { ProjectSubscriptionsContextProvider, useProjectSubscriptionsContext } from "./subscriptions/projectSubscriptionsContext";



export interface ProjectContext {
    getProjectSearch: () => Project,
    searchProjects: (projectSearch: Project) => void,
    getProjects: () => Project[],
    getProject: (id: Guid | undefined) => (Project | undefined),
    mutateProject: (project: Project) => Promise<Project | undefined>,
    getProjectTitleWithProjectState: (project: Project | undefined) => string,
    loadingProjects: boolean,
}

export const userHasGlobalProjectAccess = (authContext: AuthContext): boolean => checkProjectAccess(RoleType.ADMIN, authContext.accountRoles());

const ProjectContext = createContext<ProjectContext>(null as unknown as ProjectContext);

export const sortProjectByName = (a: Project, b: Project) => {
    if ((a.name ?? "") < (b.name ?? "")) { return -1; }
    if ((a.name ?? "") > (b.name ?? "")) { return 1; }
    return 0;
}

export enum ProjectTabs {
    all = "all",
    details = "details",
    join="join",
    joinSafetyInformation="join-safety-information",
    joinRegister="join-register",
    joinInstruction="join-instruction",
    joinAgreement="join-agreement",
}

export const ProjectContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <ProjectMutationsContextProvider>
            <ProjectQueriesContextContext>
                <ProjectSubscriptionsContextProvider>
                    <ProjectSubContextProvider>
                        {children}
                    </ProjectSubContextProvider>
                </ProjectSubscriptionsContextProvider>
            </ProjectQueriesContextContext>
        </ProjectMutationsContextProvider>
    );
}

export const ProjectSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();

    const projectMutationsContext = useProjectMutationsContext();
    const projectQueriesContext = useProjectQueriesContext();
    const projectSubscriptionsContext = useProjectSubscriptionsContext();

    const [currentProjectSearch, setCurrentProjectSearch] = useState<Project | undefined>(undefined);
    const [projects, setProjects] = useState<Project[]>([]);
    const maxProjectsToFetchAtOnce = 100;

    const mergeProjects = (oldProjects: Array<Project>, newProjects: Array<Project>): Array<Project> => {
        const updatedProjects = oldProjects.slice();
        if (!newProjects) {
            console.error(`Received undefined set of Projects: ${newProjects}`);
            return [];
        }
        newProjects.forEach(newProject => {
            newProject.created = new Date(newProject.created ?? 0);

            const index = updatedProjects.findIndex(project => project.id === newProject.id);
            if (index >= 0) {
                if (newProject.state === ActiveState.ACTIVE) {
                    updatedProjects[index] = newProject;
                }
                else {
                    updatedProjects.splice(index, 1);
                }
            } else {
                if (newProject.state === ActiveState.ACTIVE) {
                    updatedProjects.push(newProject);
                }
            }
        });
        return updatedProjects.sort(sortProjectByName);
    }


    const getProjectSearch = (): Project => {
        const urlState = urlContext.getUrlState();
        return {
        }
    }

    const searchProjects = (projectSearch: Project): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(projectSearch, currentProjectSearch);
        matched = matched && projectSearch?.id === currentProjectSearch?.id;
        if (!matched) {
            projectSearch.searchIndexStart = 0;
            projectSearch.searchIndexStop = maxProjectsToFetchAtOnce;
            setCurrentProjectSearch(projectSearch);
            setProjects([]);
        }
    }

    const getProjects = (): Array<Project> => {
        return projects;
    }

    const getProject = (id: Guid | undefined) => {
        return id === undefined ? undefined : projects.find(project => project.id === id);
    }

    const mutateProject = (project: Project): Promise<Project | undefined> => {
        return new Promise((resolve, reject) => projectMutationsContext.mutateProject(project, (documentId, variables) => {
            project = { ...project, ...variables };
            project.id = documentId;
            setProjects(mergeProjects(projects, [project]));
            resolve(project)
        }, (reason) => reject(reason)));
    }

    const getProjectTitleWithProjectState = (project: Project | undefined) => {
        let projectTitle = project?.name ?? '';
        if (project?.projectState === ProjectState.ACTIVE) {
            return projectTitle;
        }
        else if (project?.projectState === ProjectState.REQUESTING_ACTIVATION) {
            projectTitle = `${projectTitle} - ${languageContext.getMessage('pendingApproval')}`
        }
        else if (project?.projectState === ProjectState.DENIED_ACTIVATION) {
            projectTitle = `${projectTitle} - ${languageContext.getMessage('denied')}`
        }
        else if (project?.projectState === ProjectState.INACTIVE) {
            projectTitle = `${projectTitle} - ${languageContext.getMessage('inactive')}`
        }
        return projectTitle;
    }

    useEffect(() => {
        if (currentProjectSearch) {
            projectQueriesContext.queryProjects(currentProjectSearch);
        }
    }, [currentProjectSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setProjects([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setProjects(mergeProjects(projects, projectQueriesContext.fetchedProjects));
        if (projectQueriesContext.fetchedProjects.length >= maxProjectsToFetchAtOnce && currentProjectSearch) {
            currentProjectSearch.searchIndexStart = (currentProjectSearch.searchIndexStart ?? 0) + projectQueriesContext.fetchedProjects.length;
            currentProjectSearch.searchIndexStop = currentProjectSearch.searchIndexStart + maxProjectsToFetchAtOnce;
            setCurrentProjectSearch({...currentProjectSearch});
        }
    }, [projectQueriesContext.fetchedProjects]);

    useEffect(() => {
        setProjects(mergeProjects(projects, projectSubscriptionsContext.subscribedProjects));
    }, [projectSubscriptionsContext.subscribedProjects]);

    const projectContext = {
        getProjectSearch,
        searchProjects,
        getProjects,
        getProject,
        mutateProject,
        getProjectTitleWithProjectState,
        loadingProjects: projectQueriesContext.queryResponse.loading,
    };

    return (
        <ProjectContext.Provider value={projectContext}>
            {children}
        </ProjectContext.Provider>
    );
}

export const useProjectContext = (): ProjectContext => {
    return useContext(ProjectContext);
}
