import React, { createContext, useContext, useEffect, useState } from "react";
import { Certificate } from "../../../contracts/contracts";
import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/react-hooks';
import { QUERY_CERTIFICATE, getCertificatesGraphqlQueryOptions } from './certificateQueries'
import { useAuthContext } from "../../auth/authContext";

export interface CertificateQueriesContext {
    fetchedCertificates: Array<Certificate>,
    queryCertificates: (certificate: Certificate) => void,
    queryResponse: LazyQueryResult<any, OperationVariables>,
}

const CertificateQueriesContext = createContext<CertificateQueriesContext>(null as unknown as CertificateQueriesContext);

export const CertificateQueriesContextContext: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [fetchedCertificates, setFetchedCertificates] = useState<Array<Certificate>>([]);
    const [loadQuery, queryResponse] = useLazyQuery(QUERY_CERTIFICATE);

    const queryCertificates = (certificate: Certificate): void => {
        const queryOptions = getCertificatesGraphqlQueryOptions(certificate);
        loadQuery(queryOptions);
    }

    useEffect(() => {
        if (queryResponse.data && queryResponse.data.certificates) {
            const newFetchedCertificates: Array<Certificate> = queryResponse.data.certificates;
            setFetchedCertificates(newFetchedCertificates);
        }
    }, [queryResponse.data]);

    useEffect(() => {
        if (queryResponse.error) {
            console.error(queryResponse.error);
        }
    }, [queryResponse.error]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setFetchedCertificates([]);
        }
    }, [authContext.authenticated, authContext.tokenIsReady, authContext.insecure]);

    const certificateQueriesContext: CertificateQueriesContext = {
        fetchedCertificates,
        queryCertificates,
        queryResponse,
    };

    return (
        <CertificateQueriesContext.Provider value={certificateQueriesContext}>
            {children}
        </CertificateQueriesContext.Provider>
    );
}

export const useCertificateQueriesContext = (): CertificateQueriesContext => {
    return useContext(CertificateQueriesContext);
}
