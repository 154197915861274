import React, { createContext, useContext } from "react";
import { User } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getUserRoleGraphqlMutationOptions, MUTATE_USER_ROLE } from "./userRoleMutations";
import { ExecutionResult } from "graphql";
import { useAuthContext } from "../../auth/authContext";

export interface UserRolesMutationsContext {
    mutateUserRole: (user: User) => Promise<User>;
}

const UserRolesMutationsContext = createContext<UserRolesMutationsContext>(null as unknown as UserRolesMutationsContext);

export const UserRolesMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const authContext = useAuthContext();
    const [userRoleMutation] = useMutation(MUTATE_USER_ROLE);

    const handleUserRoleMutationResult = (result: ExecutionResult): User => {
        if (result.errors || !result.data) {
            console.error(result.errors);
            return {};
        }
        const updatedUser: User = result.data.mutateUser.user;
        return updatedUser;
    }

    const mutateUserRole = (user: User): Promise<User> => {
        return userRoleMutation(getUserRoleGraphqlMutationOptions(user, authContext.authSettings?.clientId)).then(handleUserRoleMutationResult);
    }

    const userRolesMutationsContext: UserRolesMutationsContext = {
        mutateUserRole,
    };

    return (
        <UserRolesMutationsContext.Provider value={userRolesMutationsContext}>
            {children}
        </UserRolesMutationsContext.Provider>
    );
}

export const useUserRolesMutationsContext = (): UserRolesMutationsContext => {
    return useContext(UserRolesMutationsContext);
}
