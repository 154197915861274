import { debounce, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf'

// Fetch correct pdf worker from here, and put it in the public folder:
// https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js
pdfjs.GlobalWorkerOptions.workerSrc = `pdf.worker.min.js`;

type PDFViewerProps = {
    fileBase64: string
    width?: number
}

export const BasePDFViewer = (props: PDFViewerProps) => {
    const { fileBase64, width } = props

    const [pageCount, setPageCount] = useState(0)

    return fileBase64 ? (
        <Document file={fileBase64} onLoadSuccess={({ numPages }) => setPageCount(numPages)}>
            {Array.from(
                new Array(pageCount),
                (el, index) => (
                <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={width}
                />
                ),
            )}
        </Document>
    ) : null
}

export const PDFViewer = (props: Omit<PDFViewerProps, 'width'>) => {
    const pdfViewerRootRef = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState<number>()
    
    const updateCurrentWidth = () => {
        if (pdfViewerRootRef.current) {
            setWidth(pdfViewerRootRef.current.getBoundingClientRect().width)
        }
    }
    
    useEffect(() => {
        if(pdfViewerRootRef.current) {
            const width = pdfViewerRootRef.current.getBoundingClientRect().width
            setWidth(width)
        }

        const debouncedUpdate = debounce(updateCurrentWidth, 1000)

        window.addEventListener('resize', debouncedUpdate)
        return () => window.removeEventListener('resize', debouncedUpdate)
    }, [])


    return <PDFViewerRoot ref={pdfViewerRootRef}><BasePDFViewer {...props} width={width}/></PDFViewerRoot>
}

const PDFViewerRoot = styled('div')(() => ({
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
}))