import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MemberAccess, Project, ProjectMember, StorageFile } from "../../contracts/contracts";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { getEmptyGuid, getRandomGuid } from "../../utils/guidTools";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';

import { Guid } from "../../utils/common-types";
import { getStorageFileFullKey, onUploadFiles, toBase64 } from "../../utils/fileTools";
import { useStorageFileContext } from "../../contexts/storageFile/storageFileContext";
import { useStorageFileMutationsContext } from "../../contexts/storageFile/mutations/storageFileMutationsContext";
import { getDocumentRole } from "../../contexts/userRole/userRoleTools";
import { DocumentType } from "../../contracts/contracts";
import { RoleType } from "../../contracts/contracts";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_STORAGE_FILES, getStorageFilesGraphqlQueryOptions } from "../../contexts/storageFile/queries/storageFileQueries";
import ProjectMemberAttachedFile from "./ProjectMemberAttachedFile";
import { SnackbarComponent } from "../toastComponent/SnackbarComponent";
import { compressFile } from "../../utils/compressionTools";
import FileSelect from "../generalComponents/FileSelect";
import SearchCompanyView from "../companyInfoComponents/SearchCompanyView";

type props = {
    readonly open: boolean;
    readonly onClose: () => void;
    readonly projectMember?: ProjectMember;
    project: Project | undefined;
}

const ProjectMemberManualUserDialog: React.FC<props> = ({ open, onClose, projectMember, project }) => {
    const projectId = project?.id
    const newProjectMemberDefaultState: Partial<ProjectMember> = {
        applicationProfileId: getEmptyGuid(),
        projectId,
        memberAccess: MemberAccess.REQUESTING_MEMBER_ACCESS,
        temporaryApplicationProfileName: '',
        temporaryApplicationProfileEmail: '',
        temporaryApplicationProfilePhoneNumber: '',
        temporaryApplicationProfileHseCardNumber: ''
    }

    const [newProjectMember, setNewProjectMember] = React.useState<ProjectMember>(projectMember ?? newProjectMemberDefaultState);
    const [selectedFile, setSelectedFile] = React.useState<{ id: string, imagePreviewUrl?: string, file?: File, content?: string }[]>([]);
    const [readingFile, setReadingFile] = React.useState<boolean>(false);
    const [isSavingNewImages, setIsSavingNewImage] = React.useState<boolean>(false);
    const [sizeError, setSizeError] = React.useState<string>()
    const [isSuccessDeleted, setIsSuccessDeleted] = React.useState<boolean>(false);

    const projectMemberContext = useProjectMemberContext()
    const languageContext = useLanguageContext();
    const storageFileContext = useStorageFileContext();
    const storageFileMutationContext = useStorageFileMutationsContext();


    const [loadInformationFileQuery, queryInformationFileResponse] = useLazyQuery(QUERY_STORAGE_FILES);
    const [existingInformationFiles, setExistingInformationFiles] = React.useState<StorageFile[]>([]);

    const inputRef = useRef<HTMLLabelElement>(null);

    const [isCompanyFieldEmpty, setIsCompanyFieldEmpty] = useState(false);
    const [isParentCompanyFieldEmpty, setIsParentCompanyFieldEmpty] = useState(false);
    const [isNameFieldEmpty, setIsNameFieldEmpty] = useState(false);

    const loadStorageFile = useCallback(() => {
        const informationFileKey = getStorageFileFullKey(projectMember?.id ?? '');
        loadInformationFileQuery(getStorageFilesGraphqlQueryOptions({ searchKey: informationFileKey }));
    }, [projectMember])

    useEffect(() => {
        if (projectMember?.id) {
            loadStorageFile()
            setNewProjectMember(projectMember);
        } else {
            setNewProjectMember(newProjectMemberDefaultState);
        }

        return () => {
            setNewProjectMember(newProjectMemberDefaultState);
        }
    }, [projectMember])

    const handleCloseButtonClick = () => {
        setNewProjectMember(newProjectMemberDefaultState);
        setSelectedFile([]);
        setExistingInformationFiles([]);
        setReadingFile(false);
        setIsSavingNewImage(false);
        setIsCompanyFieldEmpty(false);
        setIsParentCompanyFieldEmpty(false);
        setIsNameFieldEmpty(false);
        onClose();
        setSizeError('');
    }

    useEffect(() => {
        const storageFiles: StorageFile[] = queryInformationFileResponse.data?.storageFiles ?? [];
        setExistingInformationFiles(storageFiles);
    }, [queryInformationFileResponse.data]);

    const saveFile = async (documentId: Guid, files: File, fileName?: string): Promise<void> => {
        const fileEditRoles = [
            getDocumentRole(DocumentType.PROJECT, projectId ?? '', RoleType.WRITER),
        ]

        const fileReadRoles = [
            getDocumentRole(DocumentType.PROJECT, projectId ?? '', RoleType.READER)
        ]

        setIsSavingNewImage(true);
        try {
            const fileKey = getStorageFileFullKey(documentId, getRandomGuid());
            await onUploadFiles(storageFileContext, storageFileMutationContext, [files], fileKey, fileEditRoles, fileReadRoles, () => {
                setIsSavingNewImage(false);
                handleCloseButtonClick();
            }, reason => { }, fileName)
            setIsSavingNewImage(false);
        } catch (error) {
            setIsSavingNewImage(false);
        }
    }

    const onSave = async () => {
        setIsCompanyFieldEmpty(false);
        setIsParentCompanyFieldEmpty(false);
        setIsNameFieldEmpty(false);

        if (!newProjectMember.organizationNumber && !newProjectMember.temporaryApplicationProfileName) {
            setIsCompanyFieldEmpty(true);
            setIsParentCompanyFieldEmpty(true);
            setIsNameFieldEmpty(true);
            return;
        }
        if (!newProjectMember.organizationNumber) {
            setIsCompanyFieldEmpty(true);
            return;
        }
        if (project?.parentProjectCompanyActivated && !newProjectMember.parentOrganizationNumber) {
            setIsParentCompanyFieldEmpty(true);
            return;
        }

        if (!newProjectMember.temporaryApplicationProfileName) {
            setIsNameFieldEmpty(true);
            return;
        }

        if (projectMember?.id) {
            await Promise.all(selectedFile.map(async (file) => {
                if (file.file && file.file.type.includes('image/') && file.file.type !== 'image/heic') {
                    await compressFile(file.file as File, async (compressedFile) => {
                        await saveFile(newProjectMember.id as string, compressedFile, file.file?.name);
                    })
                } else {
                    await saveFile(newProjectMember.id as string, file.file as File, file.file?.name);
                }
            }));
            await projectMemberContext.mutateProjectMember(newProjectMember);
            if (!selectedFile.length) {
                handleCloseButtonClick();
            }
        } else {
            const documentId = await projectMemberContext.mutateProjectMember(newProjectMember);
            if (selectedFile.length && documentId) {
                await Promise.all(selectedFile.map(async (file) => {
                    if (file.file && file.file.type.includes('image/') && file.file.type !== 'image/heic') {
                        await compressFile(file.file as File, async (compressedFile) => {
                            await saveFile(documentId as string, compressedFile, file.file?.name);
                        })
                    } else {
                        await saveFile(documentId as string, file.file as File, file.file?.name);
                    }
                }));
            }
            if (!selectedFile.length) {
                handleCloseButtonClick();
            }
        }
    };

    const onFileDeleteHandler = (id: string) => {
        setSelectedFile(_file => _file.filter(file => file.id !== id));
    }

    const onFileSelected = async (file: File) => {
        const fileContent = await toBase64(file);
        setSelectedFile((_file) => {
            return [..._file, {
                id: getRandomGuid(),
                file: file,
                content: fileContent
            }]
        });
    }

    return (
        <Dialog
            open={open}
            onClose={handleCloseButtonClick}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="xs"
        >
            <SnackbarComponent
                open={isSuccessDeleted}
                onClose={() => setIsSuccessDeleted(false)}
            >
                {languageContext.getMessage('fileDeletedMessage')}
            </SnackbarComponent>
            <DialogTitle id="form-dialog-title">{languageContext.getMessage("member")}</DialogTitle>
            <DialogContent>
                <SearchCompanyView
                    companyInfoSearch={{ organizationNumber: newProjectMember?.organizationNumber }}
                    parentCompanyInfoSearch={{ organizationNumber: newProjectMember?.parentOrganizationNumber }}
                    onSelectedCompanyInfoChange={(companyInfo) => {
                        setIsCompanyFieldEmpty(false);

                        if (newProjectMember) {
                            const _newProjectMember = { ...newProjectMember, organizationNumber: companyInfo?.organizationNumber ?? '' }
                            setNewProjectMember(_newProjectMember);
                            return;
                        }
                    }}
                    onSelectedParentCompanyInfoChange={(companyInfo) => {
                        setIsParentCompanyFieldEmpty(false);

                        if (newProjectMember) {
                            const _newProjectMember = { ...newProjectMember, parentOrganizationNumber: companyInfo?.organizationNumber ?? '' }
                            setNewProjectMember(_newProjectMember);
                            return;
                        }
                    }}
                    error={isCompanyFieldEmpty}
                    secondError={isParentCompanyFieldEmpty}
                    label={`${languageContext.getMessage('employer')} *`}
                    secondLabel={languageContext.getMessage('workingFor')}
                    textFieldProps={{
                        fullWidth: true,
                        sx: { marginTop: 2 },
                        helperText: isCompanyFieldEmpty ? languageContext.getMessage('companyFieldRequired') : undefined
                    }}
                    secondFieldProps={{
                        fullWidth: true,
                        helperText: isParentCompanyFieldEmpty ? languageContext.getMessage('companyFieldRequired') : undefined
                    }}
                    parentCompanyEnabled={project?.parentProjectCompanyActivated}
                />
                <TextField
                    margin="dense"
                    id="name"
                    label={languageContext.getMessage('name')}
                    value={newProjectMember.temporaryApplicationProfileName}
                    onChange={(event) => {
                        setIsNameFieldEmpty(false);
                        const _newProjectMember = { ...newProjectMember, temporaryApplicationProfileName: event.target.value as string };
                        setNewProjectMember(_newProjectMember);
                        return;
                    }}
                    required={true}
                    type="text"
                    error={isNameFieldEmpty}
                    helperText={isNameFieldEmpty ? languageContext.getMessage('nameFieldRequired') : ''}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="email"
                    label={languageContext.getMessage('email')}
                    value={newProjectMember.temporaryApplicationProfileEmail}
                    onChange={(event) => {
                        const _newProjectMember = { ...newProjectMember, temporaryApplicationProfileEmail: event.target.value as string };
                        setNewProjectMember(_newProjectMember);
                        return;
                    }}
                    type="email"
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="phone"
                    label={languageContext.getMessage('phoneNumber')}
                    value={newProjectMember.temporaryApplicationProfilePhoneNumber}
                    onChange={(event) => {
                        const _newProjectMember = { ...newProjectMember, temporaryApplicationProfilePhoneNumber: event.target.value as string };
                        setNewProjectMember(_newProjectMember);
                        return;
                    }}
                    type="text"
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="hse-card"
                    label={languageContext.getMessage('hseCardNumber')}
                    value={newProjectMember.temporaryApplicationProfileHseCardNumber}
                    onChange={(event) => {
                        const _newProjectMember = { ...newProjectMember, temporaryApplicationProfileHseCardNumber: event.target.value as string };
                        setNewProjectMember(_newProjectMember);
                        return;
                    }}
                    type="text"
                    fullWidth
                />
                <label ref={inputRef} htmlFor={`attachment-${newProjectMember.applicationProfileId}`}>
                    <Stack direction='row' alignItems='center' marginTop={2}>
                        <FileSelect
                            inputId={`attachment-${newProjectMember.applicationProfileId}`}
                            isAttach
                            buttonTitle={languageContext.getMessage('addAttachment')}
                            onSelectedFiles={file => onFileSelected(file[0])}
                        />
                        <Fab onClick={() => inputRef.current?.click()} size="small" color="primary" sx={{ marginRight: 2 }} aria-label="Add">
                            <AddIcon />
                        </Fab>
                        {languageContext.getMessage('addAttachment')}
                    </Stack>
                </label>
                <List sx={{ marginTop: 1 }}>
                    {existingInformationFiles.length ? existingInformationFiles.map(file => (
                        <ProjectMemberAttachedFile setIsSuccessDeleted={setIsSuccessDeleted} loadStorageFile={loadStorageFile} key={file.id} file={file} />
                    )) : null}

                </List>
                <List sx={{ marginTop: 1 }}>
                    {selectedFile.length ? selectedFile.map(file => (
                        <ListItem key={file.id} secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => onFileDeleteHandler(file.id)}>
                                <DeleteIcon />
                            </IconButton>
                        }>
                            <AttachFileIcon />
                            <ListItemText
                                primary={<Typography variant='inherit' noWrap>{file.file?.name}</Typography>}
                            />
                        </ListItem>
                    )) : null}
                </List>

                {sizeError ? (
                    <DialogContentText marginTop={2} color="error">
                        {sizeError}
                    </DialogContentText>
                ) : null}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseButtonClick} color="primary">
                    {languageContext.getMessage('cancel')}
                </Button>
                <Button disabled={readingFile || isSavingNewImages || !!sizeError} onClick={onSave} color="primary" variant="contained">
                    {languageContext.getMessage('save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProjectMemberManualUserDialog;
