import React, { useCallback, useEffect, useMemo } from "react";
import { Autocomplete, Button, CircularProgress, Container, Divider, FormControlLabel, FormGroup, Grid, Menu, MenuItem, Stack, Switch, TextField } from "@mui/material";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { ProjectMemberTabs, useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { DocumentType, ProjectMember, MemberAccess, RoleType } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { useProjectContext } from "../../contexts/project/projectContext";
import ProjectMemberCard from "../../component/projectMemberComponents/projectMemberCard";
import ProjectMemberList from "../../component/projectMemberComponents/projectMemberList";
import { ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import ProjectMemberManualUserDialog from "../../component/projectMemberComponents/ProjectMemberManualUserDialog";
import { guidIsNullOrEmpty } from "../../utils/guidTools";

import DateRangePicker from "../../component/dateComponents/DateRangePicker";
import DateRangeIcon from '@mui/icons-material/DateRange';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DownloadIcon from '@mui/icons-material/Download';
import ProjectMemberInviteUserDialog from "../../component/projectMemberComponents/ProjectMemberInviteUserDialog";
import { useQueryProjectMemberCheckedInEvents } from "../../hooks/useQueryProjectMemberCheckedInEvents";
import { useCompanyLogo } from "../../hooks/useCompanyLogo";
import { useTemplateEngineQueriesContext } from "../../contexts/templateEngine/queries/templateEngineQueriesContext";

const ProjectMembersView: React.FC<{}> = () => {
    const urlContext = useUrlContext();
    const languageContext = useLanguageContext();
    const ticketContext = useTicketContext();
    const projectMemberContext = useProjectMemberContext();
    const templateEngineQueriesContext = useTemplateEngineQueriesContext();
    const projectContext = useProjectContext();
    const menuContext = useMenuContext();

    const [search, setSearch] = React.useState<string | ProjectMember | null>(null);
    const projectMemberSearch = projectMemberContext.getProjectMemberSearch();
    const loading = projectMemberContext.loadingProjectMembers;

    const urlState = urlContext.getUrlState();
    const tabIndex = urlState.tab ? urlState.tab as string : ProjectMemberTabs.all;
    const selectedProjectMemberId = urlState.projectMemberId ? urlState.projectMemberId as Guid : null;
    const selectedProjectMember = projectMemberContext.getProjectMember(selectedProjectMemberId ?? '');
    const allProjectMembers = projectMemberContext.getProjectMembers();

    const [startTempDate, setStartTempDate] = React.useState<Date>();
    const [endTempDate, setEndTempDate] = React.useState<Date>();
    const [startDate, setStartDate] = React.useState<Date>();
    const [endDate, setEndDate] = React.useState<Date>();
    const [searchCheckedIn, setSearchCheckedIn] = React.useState<boolean>(false);

    const [isDateFilterShowed, setIsDateFilterShowed] = React.useState<boolean>(false);

    const [fetchedProjectMemberCheckedInEvents, loadingProjectMemberCheckedInEvents] = useQueryProjectMemberCheckedInEvents(searchCheckedIn, projectMemberContext.getSelectedProjectMember()?.projectId, startDate, endDate);
    const companyLogoInlineImage = useCompanyLogo();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openCreateUserMenu = Boolean(anchorEl);
    const handleClickCreateUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseCreateUserMenu = () => {
        setAnchorEl(null);
    };
    const [createManualUserDialog, setCreateManualUserDialog] = React.useState<boolean>(false);
    const [inviteUserDialog, setInviteUserDialog] = React.useState<boolean>(false);
    const [modalProjectMember, setModalProjectMember] = React.useState<ProjectMember>();
    const handleManualUserDialogOpen = () => setCreateManualUserDialog(true);
    const handleManualUserDialogClose = () => {
        setCreateManualUserDialog(false);
        setModalProjectMember(undefined);
        handleCloseCreateUserMenu();
    }
    const handleInviteUserDialogOpen = () => setInviteUserDialog(true);
    const handleInviteUserDialogClose = () => {
        setInviteUserDialog(false);
        handleCloseCreateUserMenu();
    }
    const handleManualUserDetailDialogOpen = (projectMember: ProjectMember) => {
        setModalProjectMember(projectMember)
        setCreateManualUserDialog(true);
    }

    const handleDateFilterShowedClick = () => {
        setIsDateFilterShowed(v => !v);
    }

    useEffect(() => {
        if (!isDateFilterShowed) {
            setStartTempDate(undefined);
            setEndTempDate(undefined);
            setStartDate(undefined);
            setEndDate(undefined);
        }
    }, [isDateFilterShowed])

    const defaultSplitChar = ';;'
    const populateProjectMemberWithProprietarySearch = (projectMember: ProjectMember, companyNames: string[], matchingUserNames: string[], matchingUserEmails: string[]): ProjectMember => {
        companyNames.push(projectMember.projectCompanyName ?? '');
        matchingUserNames.push(projectMember.applicationProfileName ?? '');
        matchingUserEmails.push(projectMember.applicationProfileEmail ?? '');
        projectMember.proprietarySearch = `${(projectMember.projectCompanyName ?? '').toLowerCase()}${defaultSplitChar}${(projectMember.applicationProfileName ?? '').toLowerCase()}${defaultSplitChar}${(projectMember.applicationProfileEmail ?? '').toLowerCase()}`;
        return projectMember;
    }

    const selectedProjectId = projectMemberContext.getSelectedProjectMember()?.projectId;
    const [projectMembers, searchValues] = useMemo(() => {
        const projectMembersForSelectedProject = allProjectMembers.filter(projectMember => projectMember.projectId === selectedProjectId && projectMember.memberAccess !== MemberAccess.PUBLIC);
        if (projectMemberContext.loadingProjectMembers) {
            return [projectMembersForSelectedProject, []];
        }
        const companyNames: string[] = [];
        const matchingUserNames: string[] = [];
        const matchingUserEmails: string[] = [];
        const projectMembers = projectMembersForSelectedProject.map(projectMember => populateProjectMemberWithProprietarySearch(projectMember, companyNames, matchingUserNames, matchingUserEmails));
        const searchValues = companyNames.concat(matchingUserNames).concat(matchingUserEmails);
        const uniqueSearchValues: string[] = [];
        searchValues.forEach(searchValue => {
            if (searchValue.trim().length > 0 && uniqueSearchValues.findIndex(uniqueSearchValue => searchValue === uniqueSearchValue) < 0) {
                uniqueSearchValues.push(searchValue);
            }
        });
        return [projectMembers, uniqueSearchValues];
    }, [allProjectMembers, selectedProjectId]);

    const filteredProjectMembers = useMemo(() => {
        if (!search) return projectMembers;
        if (typeof search === "string") return search.trim().length === 0 ? projectMembers : projectMembers.filter(projectMember => projectMember?.proprietarySearch?.toLowerCase().includes(search.toLowerCase()));
        if (typeof search === "object") return projectMembers.filter(projectMember => projectMember.id === search.id);
        return [];
    }, [projectMembers, search]);

    const projectSearch = projectContext.getProjectSearch();
    const project = projectContext.getProject(selectedProjectId)

    const handleDownloadCheckedInEventsClick = () => {
        projectMemberContext.downloadProjectMemberCheckedInEventsReport(companyLogoInlineImage ?? '', project ?? {}, filteredProjectMembers, fetchedProjectMemberCheckedInEvents);
    }

    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([
            DocumentType.PROJECT_MEMBER,
            DocumentType.PROJECT]);

        if (selectedProjectId && !projectMemberContext.loadingProjectMembers) {
            projectContext.searchProjects(projectSearch);
            projectMemberContext.searchProjectMembers({ ...projectMemberSearch, projectId: selectedProjectId });
        }
    }, [urlContext.currentLocation, selectedProjectId, projectMemberContext.loadingProjectMembers]);

    const onSelectedProjectMemberDetailsChange = useCallback((projectMember: ProjectMember | undefined) => {
        if (projectMember?.applicationProfileId && guidIsNullOrEmpty(projectMember.applicationProfileId)) {
            handleManualUserDetailDialogOpen(projectMember);
        } else if (projectMember?.applicationProfileId) {
            menuContext.setSelectedApplicationRoute(ApplicationRouteId.Profile, { applicationProfileId: projectMember.applicationProfileId, projectMemberId: projectMember.id })
        }
    }, [urlContext, modalProjectMember])

    const pendindApprovalProjectMembers = filteredProjectMembers.filter(member => member.memberAccess === MemberAccess.REQUESTING_MEMBER_ACCESS && (member.projectCompanyRelationVerified || guidIsNullOrEmpty(member.applicationProfileId)))

    const projectMemberWithAccess = filteredProjectMembers.filter(member => member.memberAccess === MemberAccess.MEMBER)

    const accessDeniedProjectMembers = filteredProjectMembers.filter(member => {
        const isAlreadyInPendingApproval = pendindApprovalProjectMembers.indexOf(member) > -1

        return !isAlreadyInPendingApproval && (member.memberAccess === MemberAccess.DENIED_MEMBER_ACCESS || (!member.projectCompanyRelationVerified && member.memberAccess !== MemberAccess.MEMBER))
    })

    return (<>
        <Container>
            <Grid container spacing={1} flexWrap='nowrap' flexDirection='column' flexBasis='100%'>
                {tabIndex === ProjectMemberTabs.all &&
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Autocomplete
                                sx={{ mb: 2 }}
                                freeSolo
                                onInputChange={(e, value) => setSearch(value)}
                                onChange={(e, value) => setSearch(value)}
                                getOptionLabel={searchValue => searchValue ?? ''}
                                options={searchValues}
                                renderInput={(params) => <TextField {...params} label={languageContext.getMessage("search")} />}
                            />
                        </Grid>
                        <Stack spacing={2} marginTop={4} direction="column" alignItems="center" justifyContent="center">
                            {isDateFilterShowed ? (
                                <DateRangePicker
                                    startDate={startTempDate}
                                    endDate={endTempDate}
                                    center={true}
                                    readOnly={project?.enableCheckIn && loadingProjectMemberCheckedInEvents}
                                    onChange={(_startDate, _endDate) => {
                                        setStartTempDate(_startDate);
                                        setEndTempDate(_endDate);
                                    }}
                                    onAccept={(_startDate, _endDate) => {
                                        setStartDate(_startDate);
                                        setEndDate(_endDate);
                                    }}
                                />
                            ) : null}

                            {projectMemberContext.hasProjectAccess(RoleType.WRITER) && project ? (
                                <>
                                    <Stack spacing={1} direction="row">
                                        <Button
                                            id="create-user-menu-button"
                                            aria-controls={openCreateUserMenu ? 'create-user-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openCreateUserMenu ? 'true' : undefined}
                                            onClick={handleClickCreateUserMenu}
                                            variant="outlined"
                                        >
                                            + {languageContext.getMessage('member')}
                                        </Button>
                                        <Menu
                                            id="create-user-menu"
                                            anchorEl={anchorEl}
                                            open={openCreateUserMenu}
                                            onClose={handleCloseCreateUserMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'create-user-menu-button',
                                            }}
                                        >
                                            <MenuItem onClick={handleManualUserDialogOpen}>{languageContext.getMessage('addNewMember')}</MenuItem>
                                            <MenuItem onClick={handleInviteUserDialogOpen}>{languageContext.getMessage('inviteMember')}</MenuItem>
                                        </Menu>
                                        {project.enableCheckIn && isDateFilterShowed && <>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Stack direction={'row'}>
                                                        <Switch
                                                            size={"medium"}
                                                            checked={searchCheckedIn}
                                                            onChange={() => {
                                                                const updatedSearchCheckedIn = !searchCheckedIn;
                                                                if (updatedSearchCheckedIn) {
                                                                    const updatedStartDate = startTempDate ?? new Date()
                                                                    const updatedEndDate = endTempDate ?? new Date()
                                                                    setStartTempDate(updatedStartDate);
                                                                    setEndTempDate(updatedEndDate);
                                                                    setStartDate(updatedStartDate);
                                                                    setEndDate(updatedEndDate);
                                                                }
                                                                setSearchCheckedIn(updatedSearchCheckedIn);
                                                            }}
                                                            disabled={loadingProjectMemberCheckedInEvents}
                                                        />
                                                        <Button
                                                            onClick={handleDownloadCheckedInEventsClick}
                                                            disabled={!searchCheckedIn || loadingProjectMemberCheckedInEvents || templateEngineQueriesContext.isWaitingForResponse}
                                                            variant="text">
                                                            <DownloadIcon />
                                                        </Button>
                                                    </Stack>}
                                                    label={languageContext.getMessage('presence')}
                                                    labelPlacement={'bottom'} />
                                            </FormGroup>
                                        </>}
                                        <Button onClick={handleDateFilterShowedClick} variant="text">
                                            {!isDateFilterShowed ? (
                                                <DateRangeIcon color="primary" />
                                            ) : (
                                                <EventBusyIcon color="primary" />
                                            )}
                                        </Button>
                                    </Stack>
                                    <ProjectMemberManualUserDialog
                                        open={createManualUserDialog}
                                        onClose={handleManualUserDialogClose}
                                        project={project}
                                        projectMember={modalProjectMember}
                                    />
                                    <ProjectMemberInviteUserDialog
                                        open={inviteUserDialog}
                                        onClose={handleInviteUserDialogClose}
                                        project={project}
                                    />
                                </>
                            ) : null}
                        </Stack>
                        {loading ? <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 56 }}><CircularProgress /></Grid> : (
                            <>
                                {!searchCheckedIn && <>
                                    <ProjectMemberList
                                        withAction={projectMemberContext.hasProjectAccess(RoleType.WRITER)}
                                        projectMembers={accessDeniedProjectMembers}
                                        listTitle={languageContext.getMessage('accessDenied')}
                                        onTeaseClick={onSelectedProjectMemberDetailsChange}
                                        shrinked={true}
                                        startDate={isDateFilterShowed ? startDate : undefined}
                                        endDate={isDateFilterShowed ? endDate : undefined}
                                    />
                                    <ProjectMemberList
                                        withAction={projectMemberContext.hasProjectAccess(RoleType.WRITER)}
                                        projectMembers={pendindApprovalProjectMembers}
                                        listTitle={languageContext.getMessage('pendingApproval')}
                                        onTeaseClick={onSelectedProjectMemberDetailsChange}
                                        startDate={isDateFilterShowed ? startDate : undefined}
                                        endDate={isDateFilterShowed ? endDate : undefined}
                                    />
                                    <Divider sx={{ mt: 4, mb: 2 }} />
                                    <ProjectMemberList
                                        withAction={projectMemberContext.hasProjectAccess(RoleType.WRITER)}
                                        projectMembers={projectMemberWithAccess}
                                        listTitle={languageContext.getMessage('membersRaw')}
                                        onTeaseClick={onSelectedProjectMemberDetailsChange}
                                        startDate={isDateFilterShowed ? startDate : undefined}
                                        endDate={isDateFilterShowed ? endDate : undefined}
                                    />
                                </>}
                                {searchCheckedIn && <>
                                    <ProjectMemberList
                                        withAction={false}
                                        projectMembers={filteredProjectMembers}
                                        showProjectMemberCheckedInEvents={searchCheckedIn}
                                        projectMemberCheckedInEvents={fetchedProjectMemberCheckedInEvents}
                                        loadingProjectMemberCheckedInEvents={loadingProjectMemberCheckedInEvents}
                                        listTitle={languageContext.getMessage('presence')}
                                        onTeaseClick={onSelectedProjectMemberDetailsChange}
                                        startDate={isDateFilterShowed ? startDate : undefined}
                                        endDate={isDateFilterShowed ? endDate : undefined}
                                    />
                                </>}
                                <Divider sx={{ mt: 4, mb: 2 }} />
                            </>
                        )}

                    </Grid>
                }
                {tabIndex === ProjectMemberTabs.details &&
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ProjectMemberCard projectMember={selectedProjectMember} />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Container >
    </>
    );
}

export default ProjectMembersView;
