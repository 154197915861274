import React from 'react';
import QRCode from "react-qr-code";
import { Dialog, DialogContent, DialogTitle, Button, Grid, DialogActions, Tooltip, Stack } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ImageIcon from '@mui/icons-material/Image';
import CheckIcon from '@mui/icons-material/Check';
import ReactMarkdown from 'react-markdown';
import html2canvas from 'html2canvas';
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type Props = {
    open: boolean;
    onClose: () => void;
    qrCode?: string;
    title?: string;
}

const QrCodeDialog: React.FC<Props> = ({ open, onClose, qrCode, title }) => {

    qrCode = qrCode ?? window.location.href;
    const qrCodeDialogId = 'qr-code-table-container';
    const languageContext = useLanguageContext();
    const [downloadingQrCodeImage, setDownloadingQrCodeImage] = React.useState<boolean>(false);

    const [openCopiedQrCodeTooltip, setOpenCopiedQrCodeTooltip] = React.useState(false);

    const onCloseHandler = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    }

    const copyPasteQrCode = () => {
        if (qrCode) {
            navigator.clipboard.writeText(qrCode);
            setOpenCopiedQrCodeTooltip(true);
        }
    }

    const downloadQrCodeImage = async (): Promise<void> => {
        const dialogTable = document.getElementById(qrCodeDialogId);
        if (!dialogTable) {
            return;
        }
        setDownloadingQrCodeImage(true);
        return new Promise<void>((resolve, reject) => html2canvas(dialogTable).then(function (canvas) {
            const link = document.createElement('a');
            const qrCodeImageTitle = title?.replaceAll('#', '').replaceAll('\n', '').replaceAll('  ', ' ').trim();
            const qrCodeImageFilename = `${qrCodeImageTitle ?? languageContext.getMessage('productName')}.png`
            console.log(qrCodeImageFilename);
            link.download = qrCodeImageFilename;
            link.href = canvas.toDataURL('image/png');
            link.click();
            setDownloadingQrCodeImage(false);
            resolve();
        }, (errors) => {
            reject(errors);
        }))
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'xs'}
                open={open}
                onClose={onCloseHandler}
            >
                <DialogTitle>
                    {title && <ReactMarkdown>{title}</ReactMarkdown>}
                </DialogTitle>

                <DialogContent>
                    <Grid container>
                        <div id={qrCodeDialogId}>
                            <QRCode value={qrCode} />
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Stack direction={'row'} spacing={2}>
                        <Button color="primary" variant="contained" disabled={downloadingQrCodeImage} onClick={async () => await downloadQrCodeImage()}>
                            <ImageIcon />
                        </Button>
                        <ClickAwayListener onClickAway={() => setOpenCopiedQrCodeTooltip(false)}>
                            <div>
                                <Tooltip
                                    placement="bottom"
                                    onClose={() => setOpenCopiedQrCodeTooltip(false)}
                                    open={openCopiedQrCodeTooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={languageContext.getMessage('copiedToClipboard')}
                                >
                                    <Button color="primary" variant="contained" onClick={() => copyPasteQrCode()}>
                                        <ContentCopyIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </ClickAwayListener>
                        <Button color="primary" variant="contained" onClick={onCloseHandler}>
                            <CheckIcon />
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default QrCodeDialog;