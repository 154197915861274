import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { User } from "../../../contracts/contracts";

export const MUTATE_USER_ROLE: DocumentNode = gql(`
mutation user(
    $clientId: String,
    $id: String!, 
    $email: String,
    $name: String,
    $roles: [RoleInput], 
    $scopes: [ScopeInput],
    $updateUserIfExists: Boolean) {
mutateUser(
    clientId: $clientId,
    id: $id, 
    email: $email,
    name: $name,
    roles: $roles, 
    scopes: $scopes,
    updateUserIfExists: $updateUserIfExists) {
        user {
            id
            name
            email
            roles {
                id
                name
                delete
            }
        }
    }
}
`);

export const getUserRoleGraphqlMutationOptions = (user: Partial<User>, clientId?: string): QueryHookOptions => {
    const options: QueryHookOptions = {}
    options.variables = {};
    if (clientId !== undefined) options.variables['clientId'] = clientId
    if (user.id !== undefined) options.variables['id'] = user.id
    if (user.email !== undefined) options.variables['email'] = user.email
    if (user.name !== undefined) options.variables['name'] = user.name
    if (user.updateUserIfExists !== undefined) options.variables['updateUserIfExists'] = user.updateUserIfExists
    if (user.roles !== undefined) options.variables['roles'] = user.roles.map(role => {
        const inputRole: any = {};
        if (role.id !== undefined) inputRole.id = role.id;
        if (role.name !== undefined) inputRole.name = role.name;
        if (role.delete !== undefined) inputRole.delete = role.delete;
        return inputRole;
    })

    return options;
}
