import { TemplateEngineProprietaryType, ProjectMemberCheckedInEvent, Project, ProjectMember } from "../../contracts/contracts";
import { datesAreOnSameDay, fillEmptyDays, getStartOfDay } from "../../utils/dateTools";
import { capitalizeString } from "../../utils/stringTools";
import { LanguageContext } from "../language/interfaces";
import { TemplateEngineQueriesContext } from "../templateEngine/queries/templateEngineQueriesContext";

export type projectMemberCheckedInEventsTemplateAlternatives = 'projectMemberCheckedInEvents';
const PROJECT_MENER_CHECKED_IN_EVENTS_TEMPLATE_SHEET_NAME = 'checked_in_events';

type ProjectMemberCheckedInEventsReportVariables = {
    projectMemberId: string;
    projectMemberName: string;
    projectMemberHseCardNumber: string;
    projectMemberEmail: string;
    projectMemberPhoneNumber: string;
    projectMemberCompany: string;
    dates: string[];
}

const projectMemberTemplates = {
    'projectMemberCheckedInEvents': 'onsite/template_project_member_checked_in_events.xlsx',
}

export const queryTemplateEngineToProduceProjectMemberCheckedInEventsReport = (
    companyLogo: string,
    project: Project,
    projectMembers: ProjectMember[],
    projectMemberCheckedInEvents: ProjectMemberCheckedInEvent[],
    exportType: projectMemberCheckedInEventsTemplateAlternatives,
    templateEngineQueriesContext: TemplateEngineQueriesContext,
    languageContext: LanguageContext): void => {
    const templateFilename: string = projectMemberTemplates[exportType];
    const templateVariables: object = getTemplateEngineProjectMemberCheckedInEventsReportVariables(companyLogo, project, projectMembers, projectMemberCheckedInEvents, exportType, languageContext) ?? [];
    templateEngineQueriesContext.queryTemplateEngine(templateFilename, templateVariables, project.name ?? '');
}

const getTemplateEngineProjectMemberCheckedInEventsReportVariables = (
    companyLogo: string,
    project: Project,
    projectMembers: ProjectMember[],
    projectMemberCheckedInEvents: ProjectMemberCheckedInEvent[],
    exportType: projectMemberCheckedInEventsTemplateAlternatives,
    languageContext: LanguageContext): object => {
    const date: Date = new Date();
    const title: string = `${languageContext.getMessage('presence')} ${project.name ?? ''}`;
    const monthWithDate: string = capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'long', day: '2-digit' }));
    const monthWithDateString: string = `${monthWithDate} ${date.getFullYear()}`;
    const uniqueDays = getDateRangeInDays(projectMemberCheckedInEvents);
    const years = getYearsRange(languageContext, uniqueDays);
    const reportVariables = sortProjectMemberCheckedInEvents(uniqueDays, projectMembers, projectMemberCheckedInEvents);
    const checkedInEventsVariables = getProjectMemberCheckedInEventsVariables(title, monthWithDateString, companyLogo, project, years, uniqueDays, reportVariables, exportType, languageContext);
    return {
        'tpl_name': PROJECT_MENER_CHECKED_IN_EVENTS_TEMPLATE_SHEET_NAME,
        'sheet_name': title,
        'ctx': checkedInEventsVariables,
    }
}

const getDateRangeInDays = (projectMemberCheckedInEvents: ProjectMemberCheckedInEvent[]): Date[] => {
    const dates: Date[] = projectMemberCheckedInEvents.map((checkedInEvent: ProjectMemberCheckedInEvent) => new Date(checkedInEvent.timestamp ?? 0));
    const uniqueDaysAsNumber: number[] = [];
    for (let i = 0; i < dates.length; i++) {
        const startDate = getStartOfDay(dates[i]);
        if (!uniqueDaysAsNumber.includes(startDate.getTime())) {
            uniqueDaysAsNumber.push(startDate.getTime());
        }
    }
    let uniqueDays = uniqueDaysAsNumber.map((dayAsNumber: number) => new Date(dayAsNumber));
    uniqueDays.sort((firstDate: Date, secondDate: Date) => firstDate.getTime() - secondDate.getTime());
    if (uniqueDays.length > 1) {
        uniqueDays = fillEmptyDays(uniqueDays[0], uniqueDays[uniqueDays.length - 1], uniqueDays);
    }
    return uniqueDays;
}

const getYearsRange = (languageContext: LanguageContext, uniqueDays: Date[]): string[] => {
    let currentYear: number = 0;
    const years: string[] = [];
    for (let i = 0; i < uniqueDays.length; i++) {
        const uniqueDay = uniqueDays[i];
        if (uniqueDay.getFullYear() > currentYear) {
            const yearDate = new Date(uniqueDay.getFullYear(), 0, 1);
            years.push(capitalizeString(yearDate.toLocaleString(languageContext.getLanguage(), { year: 'numeric' })));
            currentYear = yearDate.getFullYear();
        }
        else {
            years.push('');
        }
    }
    return years;
}

const sortProjectMemberCheckedInEvents = (uniqueDays: Date[], projectMembers: ProjectMember[], projectMemberCheckedInEvents: ProjectMemberCheckedInEvent[]): ProjectMemberCheckedInEventsReportVariables[] => {
    const reportVariables: ProjectMemberCheckedInEventsReportVariables[] = [];
    for (let i = 0; i < projectMembers.length; i++) {
        const projectMember = projectMembers[i];
        const projectMemberCheckedInEventsForProjectMember = projectMemberCheckedInEvents.filter((checkedInEvent: ProjectMemberCheckedInEvent) => checkedInEvent.projectMemberId === projectMember.id);
        const sortedCheckedInEventDates = sortCheckedInEvents(uniqueDays, projectMemberCheckedInEventsForProjectMember);
        reportVariables.push({
            projectMemberId: projectMember.id ?? '',
            projectMemberName: projectMember.applicationProfileName ?? '',
            projectMemberHseCardNumber: projectMember.applicationProfileHseCardNumber ?? '',
            projectMemberEmail: projectMember.applicationProfileEmail ?? '',
            projectMemberPhoneNumber: projectMember.applicationProfilePhoneNumber ?? '',
            projectMemberCompany: getProjectCompanyDescription(projectMember),
            dates: sortedCheckedInEventDates,
        });
    }
    return reportVariables;
}

const getProjectCompanyDescription = (projectMember: ProjectMember): string => {
    let projectCompanyDescription = projectMember.organizationNumber ?? '';
    if (projectMember.projectCompanyName && projectMember.projectCompanyName.trim().length > 0) {
        projectCompanyDescription = `${projectCompanyDescription} (${projectMember.projectCompanyName})`;
    }
    return projectCompanyDescription;
}

const sortCheckedInEvents = (uniqueDays: Date[], projectMemberCheckedInEventsForProjectMember: ProjectMemberCheckedInEvent[]): string[] => {
    const sortedCheckedInEventDates: string[] = [];
    for (let i = 0; i < uniqueDays.length; i++) {
        const date = uniqueDays[i];
        const checkedInEventExists = projectMemberCheckedInEventsForProjectMember.findIndex((checkedInEvent: ProjectMemberCheckedInEvent) => {
            const checkedInEventDate = getStartOfDay(new Date(checkedInEvent.timestamp ?? 0));
            return datesAreOnSameDay(date, checkedInEventDate);
        }) >= 0;
        if (checkedInEventExists) {
            sortedCheckedInEventDates.push('X');
        }
        else {
            sortedCheckedInEventDates.push('');
        }
    }
    return sortedCheckedInEventDates;

}

const getProjectMemberCheckedInEventsVariables = (
    title: string,
    monthWithDateString: string,
    companyLogo: string,
    project: Project,
    years: string[],
    uniqueDays: Date[],
    reportVariables: ProjectMemberCheckedInEventsReportVariables[],
    exportType: projectMemberCheckedInEventsTemplateAlternatives, 
    languageContext: LanguageContext): object => {
    
    const moddedCompanyLogo = companyLogoToProprietaryTemplateType(companyLogo);

    const listVariables = {
        'companyLogo': moddedCompanyLogo,
        'language': languageContext.getLanguageCollection(),
        'title': title,
        'years': years,
        'uniqueDays': uniqueDays.map((date: Date) => capitalizeString(date.toLocaleString(languageContext.getLanguage(), { month: 'short', day: '2-digit' }))),
        'projectMemberCheckedInEvents': reportVariables.map(reportVariable => checkedInEventsToModdedCheckedInEvents(reportVariable)),
    }
    return listVariables;
}

const checkedInEventsToModdedCheckedInEvents = (reportVariable: ProjectMemberCheckedInEventsReportVariables): object => {
    const moddedCheckedInEvent: object = {
        ...reportVariable,
    }
    return moddedCheckedInEvent;
}

const companyLogoToProprietaryTemplateType = (companyLogo: string): TemplateEngineProprietaryType => {
    return {
        proprietaryType: 'inlineImage',
        source: companyLogo,
        width: 10,
        height: 10,
    }
}
