import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { ActiveState, NewsFeed } from "../../../contracts/contracts";

export const MUTATE_NEWS_FEED: DocumentNode = gql(`
    mutation mutateNewsFeed(
        $id: UUID
        $state: ActiveState
        $applicationProfileId: UUID!
        $projectId: UUID!
        $title: String
        $newsFeedDescription: String
        $accessLevel: NewsFeedAccessLevel
    ) {
        mutateNewsFeed(
            id: $id
            state: $state
            applicationProfileId: $applicationProfileId
            projectId: $projectId
            title: $title
            newsFeedDescription: $newsFeedDescription
            accessLevel: $accessLevel
        ) {
            tickets {
                id
                parentDocumentId
                parentDocumentType
                processTag
            }
        }
    }
`);

export const getNewsFeedsGraphqlMutationOptions = (newsFeed: Partial<NewsFeed>): QueryHookOptions => {
    newsFeed.state = newsFeed.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (newsFeed.id !== undefined && newsFeed.id !== null) options.variables['id'] = newsFeed.id;
    if (newsFeed.state !== undefined && newsFeed.state !== null) options.variables['state'] = newsFeed.state;
    if (newsFeed.applicationProfileId !== undefined && newsFeed.applicationProfileId !== null) options.variables['applicationProfileId'] = newsFeed.applicationProfileId;
    if (newsFeed.projectId !== undefined && newsFeed.projectId !== null) options.variables['projectId'] = newsFeed.projectId;
    if (newsFeed.title !== undefined && newsFeed.title !== null) options.variables['title'] = newsFeed.title;
    if (newsFeed.newsFeedDescription !== undefined && newsFeed.newsFeedDescription !== null) options.variables['newsFeedDescription'] = newsFeed.newsFeedDescription;
    if (newsFeed.accessLevel !== undefined && newsFeed.accessLevel !== null) options.variables['accessLevel'] = newsFeed.accessLevel;
    return options;
}
