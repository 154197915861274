import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { StateDocument, ActiveState, Ticket, StateDocumentEvents } from "../../../contracts/contracts"; 

export const QUERY_STATE_DOCUMENT_EVENTS: DocumentNode = gql(`
    query stateDocumentEvents(
        $id: UUID
        $documentType: DocumentType
    ) {
    stateDocumentEvents(
        id: $id
        documentType: $documentType
    )  {
        id
        documentType
        changed
        changedBy
        events {
            id
            documentType
            stateTime
            state
            changed
            changedBy
            changes
            content
        }
    }
}
`);

export const getStateDocumentEventsGraphqlQueryOptions = (stateDocument: Partial<StateDocument>): QueryHookOptions => {
    stateDocument.state = stateDocument.state ?? ActiveState.ACTIVE;
    const options: QueryHookOptions = {};
    options.variables = {};
    if (stateDocument.id !== undefined) options.variables['id'] = stateDocument.id
    if (stateDocument.documentType !== undefined) options.variables['documentType'] = stateDocument.documentType

    return options;
}

export const getStateDocumentEventsGraphqlQueryOptionsWithTickets = (tickets: Partial<Ticket>[]): QueryHookOptions => {
    const options: QueryHookOptions = {};
    options.variables = {};
    options.variables['tickets'] = {'tickets': tickets.map(ticket => {
        return {
            'parentDocumentId': ticket.parentDocumentId,
            'parentDocumentType': ticket.parentDocumentType,
        }
    })}
    return options;
}
