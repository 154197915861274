import { Guid } from "../utils/common-types";

export type UserRole = {
  id?: Guid;
  name?: string;
  delete?: boolean;
};

export type User = {
  proprietarySearch?: string;
  id?: Guid;
  name?: string;
  email?: string;
  roles?: UserRole[];
  updateUserIfExists?: boolean;
};

export type StorageFile = {
  delete?: boolean;
  searchIndexStart?: number;
  searchIndexStop?: number;
  searchKey?: String;
  id?: Guid;
  key?: string;
  roles?: string[];
  readRoles?: string[];
  changed?: string;
  changedBy?: string;
  metaData?: string;
  content?: string;
};

export type TemplateEngineResult = {
  templatedResult?: string;
  templatedFileType?: string;
  outputFilename?: string;
};

export type ProprietaryType = "inlineImage";

export type TemplateEngineProprietaryType = {
  proprietaryType: ProprietaryType;
  source: string;
  width?: number;
  height?: number;
};

export type StateDocument = {
  id?: Guid;
  documentType?: DocumentType;
  state?: ActiveState;
  created?: Date;
  changed?: Date;
  changedBy?: string;
  stateTime?: Date;
  content?: string;
  changes?: string;
  events?: string;
};

export type StateDocumentEvents = {
  id?: Guid;
  documentType?: DocumentType;
  state?: ActiveState;
  created?: Date;
  changed?: Date;
  changedBy?: string;
  stateTime?: Date;
  content?: string;
  changes?: string;
  events?: object[];
};

export type StorageDocument = {
  id?: Guid;
  documentType?: DocumentType;
  state?: ActiveState;
  created?: Date;
  changed?: Date;
  changedBy?: string;
  stateTime?: Date;
  content?: string;
  changes?: string;
};

export type Ticket = {
  id?: Guid;
  parentDocumentId?: Guid;
  parentDocumentType?: DocumentType;
  processTag?: ProcessTag;
};

export type Settings = {
  searchIndexStart?: number;
  searchIndexStop?: number;
  id?: Guid;
  state?: ActiveState;
  created?: Date;
  projectId?: Guid;
};

export type Analytics = {
  searchIndexStart?: number;
  searchIndexStop?: number;
  id?: Guid;
  state?: ActiveState;
  created?: Date;
  projectId?: Guid;
  averageProjectCost?: number;
};

export type RelativeInfo = {
  name?: string;
  phoneNumber?: string;
};

export type ApplicationProfile = {
    searchIndexStart?: number;
    searchIndexStop?: number;
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    userId?: Guid;
    name?: string;
    email?: string;
    phoneNumber?: string;
    selectedApplicationLanguage?: string;
    relatives?: RelativeInfo[];
    languages?: string[];
    organizationNumber?: string;
    hseCardNumber?: string;
    applicationProfileCompanyName?: string;
    applicationProfileImage?: string;
    hseCardNumberVerified?: boolean;
}

export type ProjectMember = {
    proprietarySearch?: string;
    searchIndexStart?: number;
    searchIndexStop?: number;
    id?: Guid;
    state?: ActiveState;
    created?: Date;
    applicationProfileId?: Guid;
    projectId?: Guid;
    organizationNumber?: string;
    parentOrganizationNumber?: string;
    memberAccess?: MemberAccess;
    memberState?: MemberState;
    isCheckedIn?: boolean;
    lastCheckedIn?: Date;
    noCertificateHseCardConfirmed?: boolean;
    noCertificateGeneralCardConfirmed?: boolean;
    temporaryApplicationProfileName?: string;
    temporaryApplicationProfileEmail?: string;
    temporaryApplicationProfilePhoneNumber?: string;
    temporaryApplicationProfileHseCardNumber?: string;
    notificationSettings?: NotificationSettings;
    projectName?: string;
    projectState?: ProjectState;
    projectActiveState?: ActiveState;
    applicationProfileUserId?: Guid;
    applicationProfileName?: string;
    applicationProfileEmail?: string;
    applicationProfilePhoneNumber?: string;
    applicationProfileHseCardNumber?: string;
    certificateHseCardIsAvailable?: boolean;
    certificateGeneralCardIsAvailable?: boolean;
    projectCompanyRelationVerified?: boolean;
    projectCompanyId?: Guid;
    projectCompanyName?: string;
    parentProjectCompanyId?: Guid;
    parentProjectCompanyName?: string;
    noRequestingMemberAccessTries?: number;
    failedSafetyInformationQuiz?: boolean;
    infoTechSettings?: InfoTechSettings;
    lastRegisteredInfoTechSettings?: InfoTechSettings;
    notes?: string;
    projectMemberCheckedInEvent?: ProjectMemberCheckedInEvent;
}

export type ProjectMemberCheckedInEvent = {
  id?: Guid;
  projectMemberId?: Guid;
  applicationProfileId?: Guid;
  projectId?: Guid;
  timestamp?: Date;
  isCheckedIn?: boolean;
}

export type Project = {
  searchIndexStart?: number;
  searchIndexStop?: number;
  id?: Guid;
  state?: ActiveState;
  created?: Date;
  applicationProfileId?: Guid;
  name?: string;
  address?: ProjectAddress;
  projectDescription?: string;
  projectState?: ProjectState;
  hseScanningActivated?: boolean;
  parentProjectCompanyActivated?: boolean;
  phoneNumberFieldIsMandatoryOnApplicationProfile?: boolean;
  relativesFieldIsMandatoryOnApplicationProfile?: boolean;
  hseCardNumberFieldIsMandatoryOnApplicationProfile?: boolean;
  hseCertificatePictureUploadIsMandatory?: boolean;
  generalCertificatePictureUploadIsMandatory?: boolean;
  maxNumberOfRequestingMemberAccessTries?: number;
  minimumPercentageVerifiedInformations?: number;
  autoApproveProjectMembers?: boolean;
  enableCheckIn?: boolean;
  infoTechSettings?: InfoTechSettings;
};

export type ProjectCompany = {
  proprietarySearch?: string;
  searchIndexStart?: number;
  searchIndexStop?: number;
  id?: Guid;
  state?: ActiveState;
  created?: Date;
  projectId?: Guid;
  parentProjectCompanyId?: Guid;
  projectCompanyGroup?: string;
  name?: string;
  organizationNumber?: string;
  projectCompanyDescription?: string;
  projectCompanyContactName?: string;
  projectCompanyContactEmail?: string;
  projectCompanyContactPhoneNumber?: string;
  parentProjectCompanyName?: string;
  parentProjectCompanyOrganizationNumber?: string;
  projectCompanyLevel?: number;
  infoTechSettings?: InfoTechSettings;
};

export type NewsFeed = {
  searchIndexStart?: number;
  searchIndexStop?: number;
  id?: Guid;
  state?: ActiveState;
  created?: Date;
  applicationProfileId?: Guid;
  projectId?: Guid;
  title?: string;
  newsFeedDescription?: string;
  accessLevel?: NewsFeedAccessLevel;
  applicationProfileUserId?: Guid;
  applicationProfileName?: string;
  applicationProfileEmail?: string;
};

export type Certificate = {
  searchIndexStart?: number;
  searchIndexStop?: number;
  id?: Guid;
  state?: ActiveState;
  created?: Date;
  applicationProfileId?: Guid;
  projectMemberId?: Guid;
  certificateType?: CertificateType;
  certificateId?: string;
  certificateNumber?: string;
  certificateUrl?: string;
  certificateStatus?: CertificateStatus;
  isActive?: boolean;
  isRevoked?: boolean;
  title?: string;
  certificateDescription?: string;
  numberOfCertificateFiles?: number;
  certificateFiles?: string[];
  certificateFilenames?: string[];
};

export type Information = {
  searchIndexStart?: number;
  searchIndexStop?: number;
  id?: Guid;
  state?: ActiveState;
  created?: Date;
  projectId?: Guid;
  informationId?: Guid;
  informationAccess?: InformationAccess;
  orderPosition?: number;
  title?: string;
  information?: string;
  videoUrl?: string;
  informationFilename?: string;
  language?: string;
  informationApprovalsQuizActivated?: boolean;
  informationApprovals?: InformationApproval[];
  file?: File;
};

export type InformationVerification = {
  stateTime?: string;
  searchIndexStart?: number;
  searchIndexStop?: number;
  id?: Guid;
  state?: ActiveState;
  created?: Date;
  informationId?: Guid;
  applicationProfileId?: Guid;
  projectId?: Guid;
  informationTitle?: string;
  verified?: boolean;
  agreed?: boolean;
  informationApprovals?: InformationApproval[];
  informationVerificationFiles?: string[];
};

export type InformationApproval = {
  approval?: InformationApprovalType;
  approvalDescription?: string;
};

export type ProjectAddress = {
  street?: string;
  city?: string;
  state?: string;
  postcode?: string;
  country?: string;
  coordinate?: ProjectCoordinate;
};

export type ProjectCoordinate = {
  latitude?: number;
  longitude?: number;
};

export type CompanyInfo = {
  name?: string;
  organizationNumber?: string;
  notFound?: boolean;
};

export type NotificationSettings = {
  disableEmailNotificationFromNewsFeed?: boolean;
};

export type HSECardInfo = {
  cardId?: string;
  companyName?: string;
  organizationNumber?: string;
  mainCompanyName?: string;
  mainOrganizationNumber?: string;
  cardNumber?: string;
  type?: string;
  status?: CertificateStatus;
  issueDate?: Date;
  isActive?: boolean;
  isRevoked?: boolean;
  cardHolder?: CardHolder;
  error?: boolean;
  errorInfo?: string;
};

export type CardHolder = {
  personId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  fullName?: string;
};

export type InfoTechSettings = {
  enabled?: boolean;
  infoTechApiKey?: string;
  infoTechProjectId?: string;
  infoTechProjectDescription?: string;
  infoTechAccessGroupId?: string;
  infoTechAccessGroupName?: string;
  infoTechCardId?: string;
}

export type InfoTechAccessGroup = {
  accessGroupId?: string;
  accessGroupKind?: string;
  accessGroupName?: string;
};

export type InfoTechProject = {
  projectId?: string;
  projectDescription?: string;
};

export enum RoleType {
  READER = "reader",
  WRITER = "writer",
  ADMIN = "admin",
  OWNER = "owner",
}

export enum CurrencyEnum {
  NOK = "NOK",
  US_DOLLAR = "US_DOLLAR",
  UNDEFINED = "UNDEFINED",
}

export enum ActiveState {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum HealthStatus {
  HEALTHY = "HEALTHY",
  DEGRADED = "DEGRADED",
  UNHEALTHY = "UNHEALTHY",
}

export enum ElementStatusEnum {
  INFORMATION = "INFORMATION",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  CANCEL = "CANCEL",
  ALERT = "ALERT",
}

export enum DocumentType {
  SETTINGS = "SETTINGS",
  ANALYTICS = "ANALYTICS",
  PROJECT = "PROJECT",
  PROJECT_COMPANY = "PROJECT_COMPANY",
  APPLICATION_PROFILE = "APPLICATION_PROFILE",
  PROJECT_COMPANY_WORKER = "PROJECT_COMPANY_WORKER",
  PROJECT_MEMBER = "PROJECT_MEMBER",
  GENERAL = "GENERAL",
  NEWS_FEED = "NEWS_FEED",
  CERTIFICATE = "CERTIFICATE",
  INFORMATION = "INFORMATION",
  INFORMATION_VERIFICATION = "INFORMATION_VERIFICATION",
}

export enum ProcessTag {
  NORMAL = "NORMAL",
  CALCULATIONS = "CALCULATIONS",
  HEAVY_CALCULATIONS = "HEAVY_CALCULATIONS",
}

export enum KPIEnum {
  TURNTIMES = "TURNTIMES",
  OFFHIRE_FLAG = "OFFHIRE_FLAG",
  AVERAGE_FLEET_COST = "AVERAGE_FLEET_COST",
  TRIANGULATION = "TRIANGULATION",
}

export enum MemberAccess {
  PUBLIC = "PUBLIC",
  REQUESTING_MEMBER_ACCESS = "REQUESTING_MEMBER_ACCESS",
  DENIED_MEMBER_ACCESS = "DENIED_MEMBER_ACCESS",
  MEMBER = "MEMBER",
}

export enum MemberState {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ProjectState {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  REQUESTING_ACTIVATION = "REQUESTING_ACTIVATION",
  DENIED_ACTIVATION = "DENIED_ACTIVATION",
}

export enum InformationAccess {
  PUBLIC = "PUBLIC",
  PUBLIC_FOR_MEMBERS = "PUBLIC_FOR_MEMBERS",
  PRIVATE = "PRIVATE",
}

export enum CertificateType {
  GENERAL = "GENERAL",
  HSE = "HSE",
}

export enum NewsFeedAccessLevel {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum InformationApprovalType {
  APPROVED = "APPROVED",
  NOT_APPROVED = "NOT_APPROVED",
}

export enum CertificateStatus {
    UNKNOWN = 'UNKNOWN',
    CREATED = 'CREATED',
    AWAITING_VALIDATIONS = 'AWAITING_VALIDATIONS',
    AWAITING_PRODUCTION = 'AWAITING_PRODUCTION',
    PRODUCED = 'PRODUCED',
    CANCELLED = 'CANCELLED',
    REVOKED = 'REVOKED',
    EXPIRED = 'EXPIRED',
    FAILED = 'FAILED',
  }
  