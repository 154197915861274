import React, { useEffect, useState } from "react";
import { Theme, Grid, Button, Typography, Badge, IconButton, Skeleton, Container, Stack, CircularProgress, Tooltip } from "@mui/material";
import { makeStyles, withTheme } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/Download';
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { useUrlContext } from "../../contexts/url/urlContext";
import { useAuthContext } from "../../contexts/auth/authContext";
import { ProjectTabs } from "../../contexts/project/projectContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import { ApplicationProfile, DocumentType, RoleType } from "../../contracts/contracts";
import { Dictionary } from "../../global-types";
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import { ApplicationProfileTabs, useApplicationProfileContext } from "../../contexts/applicationProfile/applicationProfileContext";
import ProfileDetailsView from "./ProfileDetailsView";
import { useProjectCompanyContext } from "../../contexts/projectCompany/projectCompanyContext";
import ApplicationProfileAvatar from "../../component/applicationProfileComponents/applicationProfileAvatar";
import { getStorageFileFullKey, onUploadFiles } from "../../utils/fileTools";
import FileSelect from "../../component/generalComponents/FileSelect";
import { useStorageFileMutationsContext } from "../../contexts/storageFile/mutations/storageFileMutationsContext";
import { useStoredFilesByFileKey } from "../../hooks/useStoredFilesByFileKey";
import { checkProjectAccess, getDocumentRole } from "../../contexts/userRole/userRoleTools";
import { useBrowserContext } from "../../contexts/browserContext/browserContext";
import { useProjectMemberContext } from "../../contexts/projectMember/projectMemberContext";
import { compressFiles } from "../../utils/compressionTools";
import { useWidthContext } from "../../contexts/WidthContext";
import ProjectMemberReport, { useDownloadReport } from "../../component/projectMemberComponents/ProjectMemberReport";
import { useStorageFileContext } from "../../contexts/storageFile/storageFileContext";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        padding: '0em'
    },
    avatarContainer: {
        marginTop: '5%',
        marginBottom: '5%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
    },
    downloadButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
    },
    subTextContainer: {
        marginBottom: '5%',
        display: 'flex',
        justifyContent: 'center',
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
}));

const ProfileView: React.FC<{}> = () => {

    const classes = useStyles();
    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();
    const ticketContext = useTicketContext();
    const applicationProfileContext = useApplicationProfileContext();
    const menuContext = useMenuContext();
    const projectCompanyContext = useProjectCompanyContext();
    const projectMemberContext = useProjectMemberContext();
    const storageFileContext = useStorageFileContext();
    const storageMutationContext = useStorageFileMutationsContext();
    const browserContext = useBrowserContext();
    const widthContext = useWidthContext();

    const projectCompanySearch = projectCompanyContext.getProjectCompanySearch();
    const applicationProfileSearch = applicationProfileContext.getApplicationProfileSearch();

    const urlState = urlContext.getUrlState();
    const urlApplicationProfileId = urlState.applicationProfileId;
    const urlProjectMemberId = urlState.projectMemberId;

    const tabIndex = urlState.tab ? urlState.tab as string : ProjectTabs.all;
    const selectedApplicationProfile = applicationProfileContext.getApplicationProfileForLoggedInUser();
    const differentUserApplicationProfile = applicationProfileContext.getApplicationProfile(urlApplicationProfileId);
    const projectMember = projectMemberContext.getProjectMember(urlProjectMemberId);

    const isCurrentUserApplicationProfile = !urlApplicationProfileId || urlApplicationProfileId === selectedApplicationProfile?.id

    const applicationProfileToUse = isCurrentUserApplicationProfile ? selectedApplicationProfile : differentUserApplicationProfile

    const applicationProfileLoading = applicationProfileContext.loadingApplicationProfiles;

    const isLoading = applicationProfileLoading

    const projectMemberSearch = projectMemberContext.getProjectMemberSearch();
    const selectedProjectId = projectMemberContext.getSelectedProjectMember()?.projectId;
    const hasProjectWriteAccess = checkProjectAccess(RoleType.WRITER, authContext.accountRoles(), selectedProjectId);
    const isProfileReadOnly = !isCurrentUserApplicationProfile;


    useEffect(() => {
        ticketContext.setDocumentTypesToWatch([
            DocumentType.PROJECT_COMPANY,
            DocumentType.APPLICATION_PROFILE,
            DocumentType.PROJECT_MEMBER,
            DocumentType.PROJECT]);
        projectCompanyContext.searchProjectCompanies(projectCompanySearch);
        applicationProfileContext.searchApplicationProfiles(applicationProfileSearch);

        if (selectedProjectId && !projectMemberContext.loadingProjectMembers && hasProjectWriteAccess) {
            projectMemberContext.searchProjectMembers({ ...projectMemberSearch, projectId: selectedProjectId });
        }
    }, [urlContext.currentLocation, selectedProjectId, projectMemberContext.loadingProjectMembers]);

    // we need this in separate useEffect to be able to load current user profile and other user profile
    // otherwise one of applcationProfile query will be cancelled and won't be loaded at all
    useEffect(() => {
        if (selectedApplicationProfile?.id && urlApplicationProfileId) {
            ticketContext.setDocumentTypesToWatch([
                DocumentType.PROJECT_COMPANY,
                DocumentType.APPLICATION_PROFILE]);

            const applicationProfileSearchForDifferentUser: ApplicationProfile = {
                id: urlApplicationProfileId
            }
            applicationProfileContext.searchApplicationProfiles(applicationProfileSearchForDifferentUser);
        }
    }, [selectedApplicationProfile, urlApplicationProfileId])

    const updateUrlState = (
        newTabIndex: string): void => {
        const newUrlState = {
            ...urlState,
            ...applicationProfileSearch,
            ...{ 'tab': newTabIndex }
        }
        const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date>);
        urlContext.pushUrlQuery(urlQuery);
    }

    const handleTabIndexChange = (newTabIndex: string) => {
        updateUrlState(newTabIndex);
    };

    const hseCardRoute = menuContext.getApplicationRouteById(ApplicationRouteId.HSECard);
    const updateUrlStateToHseCard = (): void => {
        urlContext.pushUrlQuery('', hseCardRoute.route);
    }

    // TODO: make this function re-usable!
    const updateUrl = (route: ApplicationRoute, urlState?: Dictionary<string | number | Date>) => {
        const urlQuery = urlState ? urlContext.buildUrlQuery(urlState) : '';

        urlContext.pushUrlQuery(urlQuery, route.route)
    }

    const fileKey = getStorageFileFullKey(applicationProfileToUse?.id ?? '');

    const [base64Images, setBase64Images] = useState<string[]>([]);
    const imageSrces = useStoredFilesByFileKey(fileKey, base64Images, setBase64Images)[0];
    const profilePictureEditRoles = [getDocumentRole(DocumentType.APPLICATION_PROFILE, selectedApplicationProfile?.id ?? '', RoleType.OWNER)]
    const profilePictureReadRoles = [browserContext.getTenantRole()]

    const [onDownloadReport, downloadingReport] = useDownloadReport(applicationProfileToUse, projectMember);
    const [downloadReportReady, setDownloadReportReady] = useState<boolean>(false);
    const disableDownloadReportButton = downloadingReport || !downloadReportReady;

    return (<>
        <ProjectMemberReport applicationProfile={applicationProfileToUse} projectMember={projectMember} onDownloadReady={setDownloadReportReady} />
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className={classes.avatarContainer}>
                        <Stack direction="row" justifyContent="flex-end" className={classes.downloadButton}>
                            <Tooltip
                                title={languageContext.getMessage('downloadUserProfileInfo')}
                                placement='bottom'
                            >
                                <IconButton
                                    size={'large'}
                                    style={{
                                        marginBottom: widthContext.isMobileScreen() ? 0 : 10,
                                    }}
                                    disabled={disableDownloadReportButton}
                                    onClick={e => onDownloadReport()}>
                                    {disableDownloadReportButton ? <CircularProgress size={35} /> : <DownloadIcon fontSize={'large'} />}
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                !isProfileReadOnly && selectedApplicationProfile?.id && <IconButton sx={{ bgcolor: 'background.paper', '&:hover': { bgcolor: 'background.paper' } }}>
                                    <FileSelect
                                        inputId={`fileSelect_${applicationProfileToUse?.id}`}
                                        onSelectedFiles={newImageFiles => compressFiles(newImageFiles, compressedFiles => onUploadFiles(
                                            storageFileContext,
                                            storageMutationContext,
                                            compressedFiles,
                                            fileKey,
                                            profilePictureEditRoles,
                                            profilePictureReadRoles,
                                            tileImages => {
                                                if (tileImages.length > 0) {
                                                    setBase64Images(tileImages);
                                                }
                                            }))}
                                        buttonTitle={languageContext.getMessage('uploadImage')}
                                        fileAcceptance={'image/*'} />
                                </IconButton>
                            }
                        >
                            <ApplicationProfileAvatar
                                src={applicationProfileToUse?.id && imageSrces.length > 0 ? imageSrces[0] : undefined}
                                userName={applicationProfileToUse?.name}
                                sx={{ width: 200, height: 200, fontSize: 50 }}
                                id={applicationProfileToUse?.id}
                            />
                        </Badge>
                    </div>
                    {tabIndex === ApplicationProfileTabs.all && <>
                        <div className={classes.textContainer}>
                            <Stack direction={widthContext.isMobileScreen() ? 'column' : 'row'}>
                                <Typography gutterBottom variant="h4">
                                    {isLoading ? <Skeleton sx={{ width: 200 }} /> : applicationProfileToUse?.name ?? ''}
                                </Typography>
                            </Stack>
                        </div>
                    </>}
                </Grid>


                {tabIndex === ApplicationProfileTabs.all && <>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            style={{ marginTop: 5 }}
                            variant="contained"
                            onClick={e => handleTabIndexChange(ApplicationProfileTabs.details)}
                        >
                            {isProfileReadOnly ? languageContext.getMessage('info') : languageContext.getMessage('myInfo')}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            style={{ marginTop: 5 }}
                            variant="contained"
                            onClick={() => {
                                const route = menuContext.getApplicationRouteById(ApplicationRouteId.HSECard);
                                updateUrl(route, { ...urlState });
                            }}
                        >
                            {languageContext.getMessage('hseCard')}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            style={{ marginTop: 5 }}
                            variant="contained"
                            onClick={() => {
                                const route = menuContext.getApplicationRouteById(ApplicationRouteId.Certificates);
                                updateUrl(route, { ...urlState });
                            }}
                        >
                            {languageContext.getMessage('certificates')}
                        </Button>
                    </Grid>

                    {(!isProfileReadOnly || hasProjectWriteAccess) && (
                        <>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    style={{ marginTop: 5 }}
                                    variant="contained"
                                    onClick={() => {
                                        const route = menuContext.getApplicationRouteById(ApplicationRouteId.Diplomas);
                                        updateUrl(route, { ...urlState });
                                    }}
                                >
                                    {languageContext.getMessage('diplomas')}
                                </Button>
                            </Grid>
                        </>
                    )}
                </>}


                {tabIndex === ApplicationProfileTabs.details && <>
                    <ProfileDetailsView
                        loading={disableDownloadReportButton}
                        applicationProfile={applicationProfileToUse}
                        projectMember={projectMember}
                        readonly={isProfileReadOnly} />
                </>}

            </Grid>
        </Container>
    </>);
}

export default withTheme(ProfileView);
