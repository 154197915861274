import React, { useEffect, useMemo } from 'react';
import PageContentLayout from '../../component/layouts/PageContentLayout';
import { useApplicationProfileContext } from "../../contexts/applicationProfile/applicationProfileContext";
import { useInformationVerificationContext } from "../../contexts/informationVerification/informationVerificationContext";
import { DocumentType, InformationVerification } from "../../contracts/contracts";
import { useUrlContext } from "../../contexts/url/urlContext";
import { useTicketContext } from "../../contexts/ticket/ticketContext";
import {
	Autocomplete,
	CircularProgress, Divider,
	Grid,
	List,
	TextField,
	Typography
} from "@mui/material";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import InformationVerificationTeaser
	from "../../component/informationVerificationComponents/InformationVerificationTeaser";
import { ApplicationRoute, ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import { Dictionary } from '../../global-types';
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import { Guid } from '../../utils/common-types';

const DiplomasView: React.FC<{}> = () => {
	const applicationProfileContext = useApplicationProfileContext();
	const informationVerificationContext = useInformationVerificationContext();
	const ticketContext = useTicketContext();
	const urlContext = useUrlContext();
	const projectMemberContext = useProjectMemberContext();
	const languageContext = useLanguageContext();
	const currentApplicationProfileId: Guid = urlContext.getUrlState().applicationProfileId ?? applicationProfileContext.getApplicationProfileForLoggedInUser()?.id;
	const menuContext = useMenuContext();

	const [search, setSearch] = React.useState<string | InformationVerification | null>(null);

	const informationVerificationsSearch = informationVerificationContext.getInformationVerificationSearch();
    const selectedProjectId = projectMemberContext.getSelectedProjectMember()?.projectId;
	const informationVerifications = informationVerificationContext.getInformationVerifications().filter(informationVerification => informationVerification.projectId && informationVerification.projectId === selectedProjectId);

	const matchInformationVerificationFilter = (searchMatch: string, informationVerification: InformationVerification): boolean => {
		return (informationVerification.informationTitle ?? '').toLowerCase().includes(searchMatch.toLowerCase());
	}

	const filteredInformationVerifications = useMemo(() => {
		if (!search) return informationVerifications;
		if (typeof search === "string") return informationVerifications.filter(informationVerification => matchInformationVerificationFilter(search, informationVerification));
		if (typeof search === "object") return informationVerifications.filter(informationVerification => informationVerification.id === search.id);
		return [];
	}, [informationVerificationContext.getInformationVerifications(), search]);

	const updateUrl = (route: ApplicationRoute, urlState?: Dictionary<string | number | Date>) => {
		const urlQuery = urlState ? urlContext.buildUrlQuery(urlState) : '';
		urlContext.pushUrlQuery(urlQuery, route.route)
	}

	const handleOnTeaserClick = (informationVerification: InformationVerification) => {
		if (informationVerification.informationId) {
			const route = menuContext.getApplicationRouteById(ApplicationRouteId.SafetyInformationPreview);
			const formattedRoute = { ...route, route: route.route.replace(':informationId', informationVerification.informationId) };

			const urlState = {
				applicationProfileId: currentApplicationProfileId,
				informationVerificationId: informationVerification.id ?? '',
			}
			updateUrl(formattedRoute, urlState)
		}
	}

	useEffect(() => {
		ticketContext.setDocumentTypesToWatch([
			DocumentType.INFORMATION_VERIFICATION,
		]);
		if (currentApplicationProfileId) {
			informationVerificationContext.searchInformationVerifications({
				...informationVerificationsSearch,
				applicationProfileId: currentApplicationProfileId,
				projectId: selectedProjectId,
			});
		}
	}, [urlContext.currentLocation, selectedProjectId, currentApplicationProfileId])

	const loading = informationVerificationContext.loadingInformationVerifications;

	return (
		<PageContentLayout>
			<Grid item container xs={12} spacing={1}>
				<Grid item xs={12}>
					<Autocomplete
						sx={{ mb: 2 }}
						freeSolo
						onInputChange={(e, value) => setSearch(value)}
						onChange={(e, value) => setSearch(value)}
						getOptionLabel={informationVerification => {
							if (typeof informationVerification === 'string') {
								return informationVerification;
							}
							return informationVerification.informationTitle ?? '';
						}}
						options={informationVerifications}
						renderInput={(params) => loading ? <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 56 }}><CircularProgress /></Grid> : <TextField {...params} label={languageContext.getMessage("search")} />}
					/>
				</Grid>
				<Grid item xs={12}>
					<List sx={{ bgcolor: 'background.paper' }}>
						{filteredInformationVerifications.length ?
							filteredInformationVerifications.map((informationVerification, index) => (
								<React.Fragment key={informationVerification?.id ?? index}>
									{informationVerification?.agreed && informationVerification?.verified ?
										<>
											<InformationVerificationTeaser informationVerification={informationVerification} onClick={handleOnTeaserClick} />
											<>{index !== filteredInformationVerifications.length - 1 ? <Divider /> : null}</>
										</> :
										<Typography variant="body2">You should check Safety information first!</Typography>
									}
								</React.Fragment>
							)) : (
								<Typography variant="body2">{languageContext.getMessage('emptyInformationVerifications')}</Typography>
							)}
					</List>
				</Grid>
			</Grid>
		</PageContentLayout>
	);
}

export default DiplomasView;
