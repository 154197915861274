import React, { createContext, useContext, useEffect, useState } from "react";
import { ActiveState, NewsFeed } from "../../contracts/contracts";
import { Guid } from "../../utils/common-types";
import { checkIfBothPropertiesAreUndefined } from "../../utils/randomTools";
import { useAuthContext } from "../auth/authContext";
import { useLanguageContext } from "../language/LanguageContext";
import { useUrlContext } from "../url/urlContext";
import { NewsFeedMutationsContextProvider, useNewsFeedMutationsContext } from "./mutations/newsFeedMutationsContext";
import { NewsFeedQueriesContextContext, useNewsFeedQueriesContext } from "./queries/newsFeedQueriesContext";
import { NewsFeedSubscriptionsContextProvider, useNewsFeedSubscriptionsContext } from "./subscriptions/newsFeedSubscriptionsContext";



export interface NewsFeedContext {
    getNewsFeedSearch: () => NewsFeed,
    searchNewsFeeds: (newsFeedSearch: NewsFeed) => void,
    getNewsFeeds: () => NewsFeed[],
    getNewsFeed: (id: Guid | undefined) => (NewsFeed | undefined),
    mutateNewsFeed: (newsFeed: NewsFeed) => Promise<Guid | undefined>,
    loadingNewsFeeds: boolean,
}

const NewsFeedContext = createContext<NewsFeedContext>(null as unknown as NewsFeedContext);

export const sortNewsFeedByDate = (a: NewsFeed, b: NewsFeed) => {
    if ((a.created ?? "") < (b.created ?? "")) { return 1; }
    if ((a.created ?? "") > (b.created ?? "")) { return -1; }
    return 0;
}

export enum NewsFeedTabs {
    all = "all",
    details = "details",
}

export const NewsFeedContextProvider: React.FC<{}> = ({ children }) => {
    return (
        <NewsFeedMutationsContextProvider>
            <NewsFeedQueriesContextContext>
                <NewsFeedSubscriptionsContextProvider>
                    <NewsFeedSubContextProvider>
                        {children}
                    </NewsFeedSubContextProvider>
                </NewsFeedSubscriptionsContextProvider>
            </NewsFeedQueriesContextContext>
        </NewsFeedMutationsContextProvider>
    );
}

export const NewsFeedSubContextProvider: React.FC<{}> = ({ children }) => {

    const urlContext = useUrlContext();
    const authContext = useAuthContext();
    const languageContext = useLanguageContext();

    const newsFeedMutationsContext = useNewsFeedMutationsContext();
    const newsFeedQueriesContext = useNewsFeedQueriesContext();
    const newsFeedSubscriptionsContext = useNewsFeedSubscriptionsContext();

    const [currentNewsFeedSearch, setCurrentNewsFeedSearch] = useState<NewsFeed | undefined>(undefined);
    const [newsFeeds, setNewsFeeds] = useState<NewsFeed[]>([]);
    const maxNewsFeedsToFetchAtOnce = 100;


    const mergeNewsFeeds = (oldNewsFeeds: Array<NewsFeed>, newNewsFeeds: Array<NewsFeed>): Array<NewsFeed> => {
        const updatedNewsFeeds = oldNewsFeeds.slice();
        if (!newNewsFeeds) {
            console.error(`Received undefined set of NewsFeeds: ${newNewsFeeds}`);
            return [];
        }
        newNewsFeeds.forEach(newNewsFeed => {
            newNewsFeed.created = new Date(newNewsFeed.created ?? 0);

            const index = updatedNewsFeeds.findIndex(newsFeed => newsFeed.id === newNewsFeed.id);
            if (index >= 0) {
                if (newNewsFeed.state === ActiveState.ACTIVE) {
                    updatedNewsFeeds[index] = newNewsFeed;
                }
                else {
                    updatedNewsFeeds.splice(index, 1);
                }
            } else {
                if (newNewsFeed.state === ActiveState.ACTIVE) {
                    updatedNewsFeeds.push(newNewsFeed);
                }
            }
        });
        return updatedNewsFeeds.sort(sortNewsFeedByDate);
    }


    const getNewsFeedSearch = (): NewsFeed => {
        const urlState = urlContext.getUrlState();
        return {
            projectId: urlState.projectId ?? undefined,
        }
    }

    const searchNewsFeeds = (newsFeedSearch: NewsFeed): void => {
        let matched = true;
        matched = matched && checkIfBothPropertiesAreUndefined(newsFeedSearch, currentNewsFeedSearch);
        matched = matched && newsFeedSearch?.id === currentNewsFeedSearch?.id;
        matched = matched && newsFeedSearch?.projectId === currentNewsFeedSearch?.projectId;
        matched = matched && newsFeedSearch?.accessLevel === currentNewsFeedSearch?.accessLevel;
        if (!matched) {
            newsFeedSearch.searchIndexStart = 0;
            newsFeedSearch.searchIndexStop = maxNewsFeedsToFetchAtOnce;
            setCurrentNewsFeedSearch(newsFeedSearch);
            setNewsFeeds([]);
        }
    }

    const getNewsFeeds = (): Array<NewsFeed> => {
        return newsFeeds
    }

    const getNewsFeed = (id: Guid | undefined) => {
        return id === undefined ? undefined : newsFeeds.find(newsFeed => newsFeed.id === id);
    }

    const mutateNewsFeed = (newsFeed: NewsFeed): Promise<Guid | undefined> => {
        return new Promise((resolve, reject) => newsFeedMutationsContext.mutateNewsFeed(newsFeed, (documentId, variables) => {
            newsFeed = { ...newsFeed, ...variables };
            newsFeed.id = documentId;
            setNewsFeeds(mergeNewsFeeds(newsFeeds, [newsFeed]));
            resolve(documentId);
        }, (reason) => reject(reason)));
    }

    useEffect(() => {
        if (currentNewsFeedSearch) {
            newsFeedQueriesContext.queryNewsFeeds(currentNewsFeedSearch);
        }
    }, [currentNewsFeedSearch]);

    useEffect(() => {
        if (!authContext.authenticated && !authContext.insecure) {
            setNewsFeeds([]);
            return;
        }
    }, [authContext.authenticated]);

    useEffect(() => {
        setNewsFeeds(mergeNewsFeeds(newsFeeds, newsFeedQueriesContext.fetchedNewsFeeds));
        if (newsFeedQueriesContext.fetchedNewsFeeds.length >= maxNewsFeedsToFetchAtOnce && currentNewsFeedSearch) {
            currentNewsFeedSearch.searchIndexStart = (currentNewsFeedSearch.searchIndexStart ?? 0) + newsFeedQueriesContext.fetchedNewsFeeds.length;
            currentNewsFeedSearch.searchIndexStop = currentNewsFeedSearch.searchIndexStart + maxNewsFeedsToFetchAtOnce;
            setCurrentNewsFeedSearch({...currentNewsFeedSearch});
        }
    }, [newsFeedQueriesContext.fetchedNewsFeeds]);

    useEffect(() => {
        setNewsFeeds(mergeNewsFeeds(newsFeeds, newsFeedSubscriptionsContext.subscribedNewsFeeds));
    }, [newsFeedSubscriptionsContext.subscribedNewsFeeds]);

    const newsFeedContext = {
        getNewsFeedSearch,
        searchNewsFeeds,
        getNewsFeeds,
        getNewsFeed,
        mutateNewsFeed,
        loadingNewsFeeds: newsFeedQueriesContext.queryResponse.loading,
    };

    return (
        <NewsFeedContext.Provider value={newsFeedContext}>
            {children}
        </NewsFeedContext.Provider>
    );
}

export const useNewsFeedContext = (): NewsFeedContext => {
    return useContext(NewsFeedContext);
}
