import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/react-hooks";
import { InfoTechProject } from "../contracts/contracts"
import { QUERY_INFO_TECH_PROJECTS, getInfoTechProjectsQueryOptions } from "../contexts/generalIntegrations/queries/generalIntegrationsQueries";

export const useQueryInfoTechProjects = (infoTechApiKey?: string): [InfoTechProject[], boolean] => {
    const [lastQueriedApiKey, setLastQueriedApiKey] = useState<string | undefined>(undefined);
    const [fetchedInfoTechProjects, setFetchedInfoTechProjects] = useState<InfoTechProject[]>([]);
    const [queryInfoTechProjects, queryInfoTechProjectsResponse] = useLazyQuery(QUERY_INFO_TECH_PROJECTS);

    useEffect(() => {
        if (queryInfoTechProjectsResponse.loading) {
            return;
        }
        if (infoTechApiKey && infoTechApiKey !== lastQueriedApiKey) {
            setLastQueriedApiKey(infoTechApiKey);
            const queryOptions = getInfoTechProjectsQueryOptions(infoTechApiKey);
            queryInfoTechProjects(queryOptions);
        }
    }, [infoTechApiKey, queryInfoTechProjectsResponse.loading])

    useEffect(() => {
        if (queryInfoTechProjectsResponse.data && queryInfoTechProjectsResponse.data.infoTechProjects) {
            const newFetchedInfoTechProjects: InfoTechProject[] = queryInfoTechProjectsResponse.data.infoTechProjects;
            setFetchedInfoTechProjects(newFetchedInfoTechProjects.slice());
        }
    }, [queryInfoTechProjectsResponse.data]);

    useEffect(() => {
        if (queryInfoTechProjectsResponse.error) {
            console.error(queryInfoTechProjectsResponse.error);
        }
    }, [queryInfoTechProjectsResponse.error]);

    return [fetchedInfoTechProjects, queryInfoTechProjectsResponse.loading];
}