import L, { LatLngExpression } from "leaflet";
import 'leaflet/dist/leaflet.css'
import { MapContainer, Marker, TileLayer, Popup, Tooltip } from "react-leaflet";
import React from "react";
import { Project } from "../../contracts/contracts";
import { useUrlContext } from "../../contexts/url/urlContext";
import { ApplicationRouteId, useMenuContext } from "../../contexts/menu/menuContext";
import { Dictionary } from "../../global-types";
import { useLanguageContext } from "../../contexts/language/LanguageContext";


type props = {
    projects: Project[];
}

const ProjectMap: React.FC<props> = ({ projects }) => {
    // Default coordinates set to Oslo central station
    const position: LatLngExpression = [50.52, 34.34];
    const zoom: number = 2;
    const svgIcon = L.divIcon({
        html: `
        <svg version="1.1" id="Capa_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 492.452 492.452" style="enable-background:new 0 0 492.452 492.452; fill:#808080CC;" xml:space="preserve">
   <path id="XMLID_152_" d="M246.181,0C127.095,0,59.533,102.676,84.72,211.82c17.938,77.722,126.259,280.631,161.462,280.631
       c32.892,0,143.243-202.975,161.463-280.631C432.996,103.74,365.965,0,246.181,0z M246.232,224.97
       c-34.38,0-62.244-27.863-62.244-62.244c0-34.381,27.864-62.244,62.244-62.244c34.38,0,62.244,27.863,62.244,62.244
       C308.476,197.107,280.612,224.97,246.232,224.97z"/>
   </svg>
   `,
        className: "",
        iconSize: [26, 42],
        iconAnchor: [13, 0],
    });


    const urlContext = useUrlContext();
    const menuContext = useMenuContext();
    const languageContext = useLanguageContext();

    const projectRoute = menuContext.getApplicationRouteById(ApplicationRouteId.Project);
    const updateUrlStateToProjectDetails = (project: Project): void => {
        const newUrlState = {
            ...{ 'projectId': project.id },
            ...{ 'tab': 'details' }
        }
        const urlQuery = urlContext.buildUrlQuery(newUrlState as Dictionary<string | number | Date | undefined>);
        urlContext.pushUrlQuery(urlQuery, projectRoute.route);
    }

    const corner1 = L.latLng(-90, -200)
    const corner2 = L.latLng(90, 200)
    const bounds = L.latLngBounds(corner1, corner2)

    return (<>
        <MapContainer
            center={position}
            zoom={zoom}
            minZoom={2}
            zoomSnap={0.25}
            scrollWheelZoom={true}
            maxBoundsViscosity={1.0}
            maxBounds={bounds}
            style={{ height: 600 }}>
            <TileLayer
                attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {projects.map((project, index) => <React.Fragment key={index}>
                <Marker
                    icon={svgIcon}
                    eventHandlers={{
                        click: (e) => {
                            updateUrlStateToProjectDetails(project);
                        },
                    }}
                    position={[project.address?.coordinate?.latitude ?? 0.0, project.address?.coordinate?.longitude ?? 0.0]}
                    title={`${project.name}`}>
                    <Tooltip>
                        <strong>{project.name} {languageContext.getMessage('in')} {project.address?.city}</strong><br />
                        <p>{project.address?.street}</p>
                    </Tooltip>
                </Marker>
            </React.Fragment>
            )}

        </MapContainer>
    </>
    )
};

export default ProjectMap
