import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";
import { InformationVerification } from "../../../contracts/contracts";
import { getInformationVerificationsGraphqlMutationOptions } from "../mutations/informationVerificationMutations";

export const QUERY_INFORMATION_VERIFICATION: DocumentNode = gql(`
    query informationVerifications(
        $searchIndexStart: Int
        $searchIndexStop: Int
        $id: UUID
        $documentType: DocumentType
        $state: ActiveState
        $created: DateTime
        $informationId: UUID
        $applicationProfileId: UUID
        $projectId: UUID
        $informationTitle: String
        $verified: Boolean
        $agreed: Boolean
        $informationApprovals: [InformationApprovalInput]
    ) {
        informationVerifications(
            searchIndexStart: $searchIndexStart
            searchIndexStop: $searchIndexStop
            id: $id
            documentType: $documentType
            state: $state
            created: $created
            informationId: $informationId
            applicationProfileId: $applicationProfileId
            projectId: $projectId
            informationTitle: $informationTitle
            verified: $verified
            agreed: $agreed
            informationApprovals: $informationApprovals
        ) {
            id
            documentType
            stateTime
            state
            created
            informationId
            applicationProfileId
            projectId
            informationTitle
            verified
            agreed
            informationApprovals {
                approval
                approvalDescription
            }
        }
    }
`);

export const getInformationVerificationsGraphqlQueryOptions = (informationVerification: Partial<InformationVerification>): QueryHookOptions => {
    const options = getInformationVerificationsGraphqlMutationOptions(informationVerification)
    options.variables = options.variables ?? {};
    if (informationVerification.searchIndexStart !== undefined) options.variables['searchIndexStart'] = informationVerification.searchIndexStart;
    if (informationVerification.searchIndexStop !== undefined) options.variables['searchIndexStop'] = informationVerification.searchIndexStop;
    if (informationVerification.projectId !== undefined) options.variables['projectId'] = informationVerification.projectId;
    if (informationVerification.informationTitle !== undefined) options.variables['informationTitle'] = informationVerification.informationTitle;
    return options;
}
