import React, { createContext, useContext } from "react";
import { NewsFeed, Ticket } from "../../../contracts/contracts";
import { useMutation } from '@apollo/react-hooks';
import { getNewsFeedsGraphqlMutationOptions, MUTATE_NEWS_FEED } from "./newsFeedMutations";
import { ExecutionResult } from "graphql";
import { useTicketContext } from "../../ticket/ticketContext";
import { Guid } from "../../../utils/common-types";

export interface NewsFeedMutationsContext {
    mutateNewsFeed: (newsFeed: NewsFeed, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void) => void;
}

const NewsFeedMutationsContext = createContext<NewsFeedMutationsContext>(null as unknown as NewsFeedMutationsContext);

export const NewsFeedMutationsContextProvider: React.FC<{}> = ({ children }) => {

    const ticketContext = useTicketContext();
    const [newsFeedMutation] = useMutation(MUTATE_NEWS_FEED);

    const handleNewsFeedMutationResult = (result: ExecutionResult, variables: any, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        if (result.errors || !result.data) {
            onError(result.errors);
            return;
        }
        const tickets: Array<Ticket> = result.data.mutateNewsFeed.tickets;
        tickets.forEach(ticket => {
            if (ticket.id && ticket.parentDocumentId) {
                if (ticketContext.setDocumentIdToSubscribe(ticket.parentDocumentId)) {
                    onTicketSubscribed(ticket.parentDocumentId, variables);
                }
            }
        });
        if (tickets.length === 0 && 'id' in variables) {
            onTicketSubscribed(variables['id'], variables);
        }
        onError("No ticket or document id received");
    }

    const mutateNewsFeed = (newsFeed: NewsFeed, onTicketSubscribed: (documentId: Guid, variables: any) => void, onError: (reason: any) => void): void => {
        const options = getNewsFeedsGraphqlMutationOptions(newsFeed)
        newsFeedMutation(options).then((result) => handleNewsFeedMutationResult(result, options.variables, onTicketSubscribed, onError));
    }

    const newsFeedMutationsContext: NewsFeedMutationsContext = {
        mutateNewsFeed
    };

    return (
        <NewsFeedMutationsContext.Provider value={newsFeedMutationsContext}>
            {children}
        </NewsFeedMutationsContext.Provider>
    );
}

export const useNewsFeedMutationsContext = (): NewsFeedMutationsContext => {
    return useContext(NewsFeedMutationsContext);
}
