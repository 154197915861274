import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import enGBLocale from "date-fns/locale/en-GB";
import nbLocale from "date-fns/locale/nb";
import plLocale from "date-fns/locale/pl";
import React from "react";
import { useLanguageContext } from "../language/LanguageContext";

const DateFnslocaleMap = {
    en: enGBLocale,
    nb: nbLocale,
    pl: plLocale,
};

export const DateFnsProvider: React.FC<{}> = ({ children }) => {
    const languageContext = useLanguageContext()
    
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={DateFnslocaleMap[languageContext.getLanguage()]}>
            {children}
        </LocalizationProvider>
    );
}
