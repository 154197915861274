import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React from "react";
import { useProjectCompanyContext } from "../../contexts/projectCompany/projectCompanyContext";
import { ProjectCompany } from "../../contracts/contracts";
import { useLanguageContext } from '../../contexts/language/LanguageContext';

type props = {
    selectedProjectCompany: ProjectCompany | undefined;
    onSelectedProjectCompanyChange: (newSelectedProjectCompany: ProjectCompany | undefined) => void;
    projectCompanyFilter?: (projectCompanies: ProjectCompany[]) => ProjectCompany[];
    label?: React.ReactNode;
}

const SelectProjectCompany: React.FC<props> = ({
    selectedProjectCompany,
    onSelectedProjectCompanyChange, 
    projectCompanyFilter,
    label }) => {

    const languageContext = useLanguageContext();
    const projectCompanyContext = useProjectCompanyContext();

    let projectCompanies = projectCompanyContext.getProjectCompanies();
    if (projectCompanyFilter) {
        projectCompanies = projectCompanyFilter(projectCompanies);
    }

    const defaultProjectCompany = selectedProjectCompany ?? null;

    const handleChange = (event: any, newSelectedProjectCompany: ProjectCompany | null) => {
        onSelectedProjectCompanyChange(newSelectedProjectCompany ?? undefined);
    }

    return <Autocomplete
        options={projectCompanies}
        getOptionLabel={(projectCompany) => projectCompany.name ?? ''}
        value={defaultProjectCompany ?? null}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params}
            label={label ?? languageContext.getMessage('companyRaw')}
            variant="outlined" />}
    />
}

export default SelectProjectCompany;