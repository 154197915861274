import { FC, useEffect,useRef, useState } from "react";
import { Html5QrcodeScanType, Html5QrcodeScanner } from 'html5-qrcode'
import { styled } from '@mui/material/styles';
import { useLanguageContext } from "../../contexts/language/LanguageContext";


type props = {
    onResult: (result: string) => void;
    onError?: (error?: Error | null) => void;
    scanDelay?: number;
}

export const HseCardScanner: FC<props> = ({
    onResult, onError, scanDelay = 500
}) => {
    const languageContext = useLanguageContext();
    const qrScannerRef = useRef<Html5QrcodeScanner>()
    const [error, setError] = useState('')

    useEffect(() => {
        qrScannerRef.current = new Html5QrcodeScanner("qr-code-scanner", { fps: 10, qrbox: {height: 150, width: 150}, supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_FILE] }, undefined)


          qrScannerRef.current.render((decodedText, result) => {            
              onResult(decodedText)
            if (qrScannerRef.current) qrScannerRef.current.clear()
        }, () => {
            setError(languageContext.getMessage('unableToScanQrCode'))
        })

        return () => {
            if (qrScannerRef.current) qrScannerRef.current.clear()
        }
    }, []);

    return (
        <>
        <div id="qr-code-scanner"></div>
        {!!error && (
        <ScannerErrorMessage>
            {error}
        </ScannerErrorMessage>
            )}
        </>
    )
}

const ScannerErrorMessage = styled('div')`
    color: red;
`
