import React, { ReactNode, useState } from 'react';
import { CompanyInfo, ProjectMember, ProjectMemberCheckedInEvent } from "../../contracts/contracts";
import { ListItemButton, ListItemAvatar, ListItemText, ListItem, Typography, Stack, CircularProgress, Tooltip } from "@mui/material";
import ApplicationProfileAvatar from "../applicationProfileComponents/applicationProfileAvatar";
import { getStorageFileFullKey } from "../../utils/fileTools";
import { useStoredFilesByFileKey } from "../../hooks/useStoredFilesByFileKey";
import { useLanguageContext } from "../../contexts/language/LanguageContext";
import { DateFormatString } from '../dateComponents/DateFormat';
import { useProjectMemberContext } from '../../contexts/projectMember/projectMemberContext';
import { useCompanyInfoLoader } from '../companyInfoComponents/useCompanyInfoLoader';
import { getEmptyGuid } from '../../utils/guidTools';
import { useProjectContext } from '../../contexts/project/projectContext';
import { datesAreOnSameDay } from '../../utils/dateTools';
import ReactMarkdown from 'react-markdown';

type props = {
	projectMember?: ProjectMember,
	projectMemberCheckedInEvent?: ProjectMemberCheckedInEvent;
	onTeaserClick?: (projectMember?: ProjectMember) => void;
	secondaryAction?: ReactNode;
	disablePadding?: boolean,
}

const ProjectMemberTeaser: React.FC<props> = ({ projectMember, projectMemberCheckedInEvent, onTeaserClick, secondaryAction, disablePadding }) => {
	const languageContext = useLanguageContext();
	const projectMemberContext = useProjectMemberContext();
	const projectContext = useProjectContext();
	const fileKey = getStorageFileFullKey(projectMember?.applicationProfileId ?? '');
	const [base64Images, setBase64Images] = useState<string[]>([]);
	const imageSrces = useStoredFilesByFileKey(fileKey, base64Images, setBase64Images)[0];

	const project = projectContext.getProject(projectMember?.projectId);

	const projectCompanyNameIsValid = (projectMember?.projectCompanyName ?? '').trim().length > 0;
	const parentProjectCompanyNameIsValid = (projectMember?.parentProjectCompanyName ?? '').trim().length > 0;
	const [matchingProjectMemberCompanyInfo] = useCompanyInfoLoader(projectMember?.organizationNumber, projectCompanyNameIsValid);
	const [matchingSecondProjectMemberCompanyInfo] = useCompanyInfoLoader(projectMember?.parentOrganizationNumber, parentProjectCompanyNameIsValid);

	const getCompanyName = (projectCompanyName: string | undefined, matchingProjectCompanyInfo: CompanyInfo | undefined, defaultText: string): string => {
		const companyNameIsValid = (projectCompanyName ?? '').trim().length > 0;
		if (companyNameIsValid) {
			return projectCompanyName ?? '';
		}
		return matchingProjectCompanyInfo?.notFound ? `${matchingProjectCompanyInfo.organizationNumber} (${languageContext.getMessage('unknownCompany')})` : matchingProjectCompanyInfo?.name ?? defaultText;
	}

	const parentCompanyName = getCompanyName(projectMember?.parentProjectCompanyName, matchingSecondProjectMemberCompanyInfo, '');

	const isManualUser = projectMember?.applicationProfileId === getEmptyGuid()
	const projectMemberWasCheckedInToday = datesAreOnSameDay(new Date(), projectMember?.lastCheckedIn ?? new Date(0));

	const projectMemberName = projectMember?.applicationProfileName ?? (projectMemberContext.loadingProjectMembers ? <CircularProgress size={20} /> : languageContext.getMessage('unkown'));
	const showProjectMemberCheckedInEvent = projectMemberCheckedInEvent?.id !== undefined;

	const getProjectMemberDetails = (asTooltip: boolean) => <>
		<Stack direction={'column'} spacing={0}>
			{asTooltip && <Typography variant='subtitle1' noWrap>
				{projectMemberName}
			</Typography>}
			<Typography variant='caption' noWrap>
				{getCompanyName(projectMember?.projectCompanyName, matchingProjectMemberCompanyInfo, languageContext.getMessage('noCompany'))}
			</Typography>
			<Typography variant='caption' noWrap>
				{parentCompanyName ? `(${parentCompanyName})` : ''}
			</Typography>
			<Typography variant="caption" color={asTooltip ? undefined : "textSecondary"}>
				{DateFormatString({ date: showProjectMemberCheckedInEvent ? projectMemberCheckedInEvent.timestamp : projectMember?.created })}
			</Typography>
			{asTooltip && (projectMember?.notes ?? '').trim().length > 0 &&
				<ReactMarkdown
					children={projectMember?.notes ?? ''}
					components={{
						p: ({ children, ...props }) => <Typography variant="caption" color={asTooltip ? undefined : "textSecondary"}>{children}</Typography>
					}}
				/>}
		</Stack>
	</>

	return (
		<Tooltip
			title={getProjectMemberDetails(true)}
			placement='bottom'
		>
			<ListItem
				secondaryAction={secondaryAction}
				disablePadding={disablePadding}
				sx={{ paddingRight: 3 }}
			>
				<ListItemButton onClick={() => onTeaserClick ? onTeaserClick(projectMember) : null} >
					<ListItemAvatar>
						<ApplicationProfileAvatar
							userName={projectMember?.applicationProfileName}
							src={imageSrces.length > 0 ? imageSrces[0] : undefined}
							id={projectMember?.applicationProfileId}
							missingCertificate={!projectMember?.certificateGeneralCardIsAvailable}
							missingHSECards={!projectMember?.certificateHseCardIsAvailable}
							failedSafetyInformationQuiz={(project?.maxNumberOfRequestingMemberAccessTries ?? 0) > 0 && projectMember?.failedSafetyInformationQuiz}
							isManualUser={isManualUser}
							isCheckedIn={projectMember?.isCheckedIn}
							notes={projectMember?.notes}
							wasCheckinToday={projectMemberWasCheckedInToday}
							showCheckInStatus={project?.enableCheckIn ?? false}
						/>
					</ListItemAvatar>
					<ListItemText
						primary={projectMemberName}
						secondaryTypographyProps={{
							component: 'div',
						}}
						secondary={<>
							{getProjectMemberDetails(false)}
						</>} />
				</ListItemButton>
			</ListItem>
		</Tooltip>
	)
}

export default ProjectMemberTeaser;
